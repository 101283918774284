// libs
import { Dispatch } from 'redux';

// interfaces
import { IAction } from 'src/actions';

// helpers
import { reportOnlyErrorObject } from 'src/utils/reporting/report-errors';

declare const require: (id: string) => any;
// The 'is-promise' module returns a function and no object. This cannot be
// handled by the `import`-statement, so we have to use `require()` here.
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isPromise = require('is-promise');

const abortError = 'AbortError';
const filteredReportError = (error: any) => {
  // filter out fetch aborts wich we intentionly fire
  if (error && error.name && error.name === abortError) {
    return;
  }
  reportOnlyErrorObject(error);
};

export default function errorMiddleware() {
  return (next: Dispatch<IAction>) => (action: any) => {
    // filter promise-middleware _ERROR actions out from fetch aborts wich we intentionly fire
    if (action.payload && action.payload.name === abortError) {
      return;
    }
    // If not a promise, continue on
    if (!isPromise(action.payload)) {
      return next(action);
    }
    // Dispatch initial pending promise, but catch errors afterwards
    return next(action).catch(filteredReportError);
  };
}
