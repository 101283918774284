import { isArray, isObject } from 'lodash';

/*
 * Builds URLs, encodes stuff, removes undefined params:
 *
 *     build('/api', { param1: 'blor&gh ', iWannaBeIn: undefined })
 *     => '/api?param1=blo%2526rgh%2520'
 *
 */
export const build = (
  path: string,
  rawParams?: any,
): string => {
  if (!rawParams) {
    return path;
  }

  const paramsString = encodeURI(buildParamsString(rawParams));
  if (paramsString) {
    return `${path}?${paramsString}`;
  }

  return path;
};

export const buildParamsString = (rawParams: any): string => {
  const result = [];

  for (const key in rawParams) {
    if (rawParams.hasOwnProperty(key)) {
      const entry = handleParam(key, rawParams[key]);

      if (entry) {
        result.push(entry);
      }
    }
  }

  return (result && result.length) ? result.join('&') : '';
};

export const handleParam = (key: string, value: any): string | undefined => {
  if (isArray(value)) {
    if (!value.length) {
      return undefined;
    }
    return mapArray(key, value);
  } else if (isObject(value)) {
    return mapObject(key, value);
  }

  if (value !== undefined) {
    return `${key}=${value}`;
  }
  return undefined;
};

const mapObject = (key: string, value: any) => {
  const result = [];

  for (const childKey in value) {
    if (value.hasOwnProperty(childKey)) {
      const extKey = `${key}[${childKey}]`;
      if (childKey) {
        const querystringParam = handleParam(extKey, value[childKey]);
        if (querystringParam) {
          result.push(querystringParam);
        }
      }
    }
  }

  return result.length ? result.join('&') : undefined;
};

const mapArray = (key: string, params: any[]): string =>
  params.map((value): string | undefined => {
    const extKey = `${key}[]`;

    return handleParam(extKey, value);
  }).join('&');
