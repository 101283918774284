// interfaces
import {
  FetchFeedThunkAction,
  IEventfeedMergeableParams,
  IEventfeedRequestParams,
} from 'src/actions/feed/feed_interfaces';

// constants
import { FEED_TYPE_EVENT } from 'src/constants/feed';

// actions
import { fetchFeed } from 'src/actions/feed/feed';

// helpers
import {
  addBoundingBox,
  addCategories,
  addRanges,
  hasBoundingBox,
  hasActiveFilter,
} from 'src/actions/feed/feed_utils';
import api from 'src/api';

const fetchEventFeed = fetchFeed<IEventfeedRequestParams>(
  FEED_TYPE_EVENT,
  api.eventFeed.get,
);

export const fetchEventFeedPage = (page: number = 1, params: IEventfeedMergeableParams = {}): FetchFeedThunkAction => {
  return async(dispatch, getState): Promise<void> => {
    const state = getState();

    if (!hasBoundingBox(state) || !hasActiveFilter(state)) {
      return;
    }

    dispatch(fetchEventFeed({
      page,
      ...addBoundingBox(state),
      ...addCategories(state),
      ...addRanges(state, params),
    }));
  };
};
