// libs
import * as React from 'react';

// components
import SnackBar, { IProps } from 'src/components/snack_bar/snack_bar';

// helpers
import { useHideSnackBar } from 'src/actions/app-state/app-state_hooks';
import { useGetSnackBar } from 'src/reducers/app-state/app-state_hooks';

const GlobalSnackBar: React.FC<IProps> = () => {
  const snackBarState = useGetSnackBar();
  const hideSnackBar = useHideSnackBar();

  return snackBarState.message ? (
    <SnackBar
      {...snackBarState}
      onClose={hideSnackBar}
    />
  ) : null;
};

export default GlobalSnackBar;
