import GoogleTagManager, { ICustomDimensions } from 'src/utils/reporting/google-tag-manager';

export const START_ACTION = 'START';
export const FINISH_ACTION = 'FINISH';
export const CANCEL_ACTION = 'CANCEL';
export const FAIL_ACTION = 'FAIL';
const FAIL_RETRY_ACTION = 'FAIL_RETRY';
const SELECT_CALENDAR_DATE = 'SELECT_CALENDAR_DATE';
const SELECT_CALENDAR_HEUTE = 'SELECT_CALENDAR_HEUTE';
const SELECT_CALENDAR_MORGEN = 'SELECT_CALENDAR_MORGEN';
const SELECT_CALENDAR_WOCHENENDE = 'SELECT_CALENDAR_WOCHENENDE';
const OPEN_CALENDAR_RANGE = 'OPEN_CALENDAR_RANGE';
const CLOSE_CALENDAR_RANGE = 'CLOSE_CALENDAR_RANGE';

// PLEASE DO NOT FIX THIS FACEBOOOK TYPO, IT WILL DESTROY THE REPORTING
const FACEBOOOK = 'FACEBOOOK';

export const SHARE_EMAIL = 'SHARE_EMAIL';
export const SHARE_FACEBOOK = 'SHARE_FACEBOOK';
export const SHARE_LINK = 'SHARE_LINK';
export const SHARE_NATIVE = 'SHARE_NATIVE';
export const SHARE_TWITTER = 'SHARE_TWITTER';
export const SHARE_WHATSAPP = 'SHARE_WHATSAPP';

export const CHANGE_LOCATION = 'CHANGE_LOCATION';
export const LOCATE_ME = 'LOCATE_ME';
export const SELECT_POPULAR_PLACE = 'SELECT_POPULAR_PLACE';

type ValueOf<T> = T[keyof T];

export type ActionsOf<T extends IReporterMapRoot> = ValueOf<T['ACTIONS']>;

export type LocationTrackingLabel =
  typeof CHANGE_LOCATION | typeof LOCATE_ME | typeof SELECT_POPULAR_PLACE;

export type TrackingLabel =
  typeof START_ACTION | typeof FINISH_ACTION | typeof CANCEL_ACTION | typeof FAIL_ACTION;

export type TrackerType<L extends IReporterMapRoot> =
  (
    action: ActionsOf<L>,
    label: ValueOf<L['LABELS']>,
    callback?: () => any,
    customDimension?: ICustomDimensions,
  ) => void;

/*
 * This type is required for our tracking of notification settings.
 * We get the available notification settings from the API, so we do not know
 * which actions we need to track.
 * DO NOT USE THIS TYPE for anything else than the notification settings!
 */
export type DynamicActionTrackerType<L extends IReporterMapRoot> =
  (
    action: string,
    label: ValueOf<L['LABELS']>,
    callback?: () => any,
    customDimension?: ICustomDimensions,
  ) => void;

export interface IReporterMapRoot {
  ACTIONS: {
    [key: string]: string;
  };
  CATEGORY: string;
  LABELS: {
    [key: string]: string;
  };
}

const initTracker = <L extends IReporterMapRoot>(componentReporter: L): TrackerType<L> => {
  return (action, label, callback, customDimension) => {
    const cat = componentReporter.CATEGORY;
    // Some browser-extensions will block GTM, so the callback is never called.
    // To secure our functionality from these extensions, we set a timeout here,
    // which will call the callback nontheless, if it exists and wasn't called yet.
    let callbackFunc;
    if (callback) {
      callbackFunc = callIfCallback(callback);
    }

    customDimension && GoogleTagManager.pushCustomDimension(customDimension);
    GoogleTagManager.pushEventWithValue(cat, action, label, callbackFunc);
  };
};

const callIfCallback = (callback: () => void): () => void => {
  const timeout = window.setTimeout(() => {
    callback();
    callback = () => undefined;
  }, 1000);

  return () => {
    window.clearTimeout(timeout);
    callback();
  };
};

/* FEATURE REPORTERS */
export const AUTH = {
  ACTIONS: {
    SWITCH_FORM_FORGOT_PASSWORD: 'SWITCH_FORM_FORGOT_PASSWORD',
    SWITCH_FORM_LOGIN: 'SWITCH_FORM_LOGIN',
    SWITCH_FORM_REGISTRATION: 'SWITCH_FORM_REGISTRATION',
  },
  CATEGORY: 'auth',
  LABELS: {
    FINISH: FINISH_ACTION,
  },
} as const;

export const BLOG_PROFILE_ADMIN = {
  ACTIONS: {
    REQUEST: 'REQUEST',
  },
  CATEGORY: 'blog_profile_admin',
  LABELS: {
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
} as const;

export const COLLAPSED_BANNER = {
  ACTIONS: {
    SHOW: 'SHOW',
  },
  CATEGORY: 'bannerBlocker',
  LABELS: {
    START: START_ACTION,
  },
} as const;

export const COMMENTS = {
  ACTIONS: {
    DELETE: 'DELETE',
    LIKE: 'LIKE',
    UPDATE: 'UPDATE',
    WRITE: 'WRITE',
  },
  CATEGORY: 'comments',
  LABELS: {
    CANCEL: CANCEL_ACTION,
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
} as const;

export type CommentsTrackerObj = typeof COMMENTS;

export const CREATE_POST = {
  ACTIONS: {
    ADD_LOCATION: 'ADD_LOCATION',
    ADD_PHOTO: 'ADD_PHOTO',
    CAMPAIGN: 'CAMPAIGN',
    CAMPAIGN_WARNING: 'CAMPAIGN_WARNING',
    CAMPAIGN_WRITE: 'CAMPAIGN_WRITE',
    CLOSE_CALENDAR_RANGE,
    CROPPER: 'CROPPER',
    EVENT: 'EVENT',
    EVENT_ADD_CATEGORY: 'EVENT_ADD_CATEGORY',
    EVENT_ADD_TIME: 'EVENT_ADD_TIME',
    OPEN_CALENDAR_RANGE,
    PREVIEW: 'PREVIEW',
    PRIVATE_POST: 'PRIVATE_POST',
    PUBLIC_POST: 'PUBLIC_POST',
    SNAPSHOT: 'SNAPSHOT',
    SNAPSHOT_ADD_LOCATION: 'SNAPSHOT_ADD_LOCATION',
    WRITE_POST: 'WRITE_POST',
    WRITE_SURVEY: 'WRITE_SURVEY',
  },
  CATEGORY: 'create_post',
  LABELS: {
    CANCEL: CANCEL_ACTION,
    FAIL: FAIL_ACTION,
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
} as const;

export type PostCreateObjType = typeof CREATE_POST;

export interface ICreatePostTracker {
  tracker: TrackerType<PostCreateObjType>;
  trackingObj: PostCreateObjType;
}

export const CREATE_PROFILE = {
  ACTIONS: {
    ADDRESS: 'ADDRESS',
    AVATAR: 'AVATAR',
    CHANGE_LOCATION,
    CONTACT_NUMBER: 'CONTACT_NUMBER',
    CONTACT_PERSON: 'CONTACT_PERSON',
    CREATE_PROFILE: 'CREATE_PROFILE',
    IMAGE_UPLOAD: 'IMAGE_UPLOAD',
    INTERNET_SITE: 'INTERNET_SITE',
    LOCATE_ME,
    NAME: 'NAME',
    PROFILE_CATEGORY: 'PROFILE_CATEGORY',
    PROFILE_NAME: 'PROFILE_NAME',
  },
  CATEGORY: 'create-profile',
  LABELS: {
    CANCEL: CANCEL_ACTION,
    FAIL: FAIL_ACTION,
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
} as const;

export interface ICreateProfileTracker {
  tracker: TrackerType<typeof CREATE_PROFILE>;
  trackingObj: typeof CREATE_PROFILE;
}

export const CTA_COMPLETE_PROFILE = {
  ACTIONS: {
    CLOSE: 'CLOSE',
    CONTINUE_REGISTRATION: 'CONTINUE_REGISTRATION',
    DO_IT_LATER: 'DO_IT_LATER',
    OPEN: 'OPEN',
  },
  CATEGORY: 'cta_complete_profile',
  LABELS: {
    START: START_ACTION,
  },
} as const;

export const CTA_CONFIRM_EMAIL = {
  ACTIONS: {
    CLOSE: 'CLOSE',
    DO_IT_LATER: 'DO_IT_LATER',
    OPEN: 'OPEN',
  },
  CATEGORY: 'cta_confirm_email',
  LABELS: {
    START: START_ACTION,
  },
} as const;

export const CTA_NEW_USER = {
  ACTIONS: {
    CLOSE: 'CLOSE',
    DO_IT_LATER: 'DO_IT_LATER',
    LOGIN: 'LOGIN',
    OPEN: 'OPEN',
    REGISTER: 'REGISTER',
  },
  CATEGORY: 'cta_new_user_info',
  LABELS: {
    START: START_ACTION,
  },
} as const;

export const CTA_VERIFY = {
  ACTIONS: {
    CLOSE: 'CLOSE',
    CONTINUE_REGISTRATION: 'CONTINUE_REGISTRATION',
    DO_IT_LATER: 'DO_IT_LATER',
    OPEN: 'OPEN',
  },
  CATEGORY: 'cta_verify',
  LABELS: {
    START: START_ACTION,
  },
} as const;

export const EMAIL_NOT_RECEIVED = {
  ACTIONS: {
    SEND_EMAIL_AGAIN: 'OPEN',
  },
  CATEGORY: 'cta_email_not_received',
  LABELS: {
    CANCEL: CANCEL_ACTION,
    FAIL: FAIL_ACTION,
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
} as const;

export const EVENT_FEED_FILTER = {
  ACTIONS: {
    CLOSE_CALENDAR_RANGE,
    EVENT_ADD_TIME: 'EVENT_ADD_TIME',
    NAVIGATION: 'NAVIGATION',
    OPEN_CALENDAR_RANGE,
    SELECT_CALENDAR_DATE,
    SELECT_CALENDAR_HEUTE,
    SELECT_CALENDAR_MORGEN,
    SELECT_CALENDAR_WOCHENENDE,
  },
  CATEGORY: 'event_feed_filter',
  LABELS: {
    CANCEL: 'CANCEL',
    GO_BACK: 'GO_BACK',
    START: START_ACTION,
  },
} as const;

export type EventFeedFilterObjType = typeof EVENT_FEED_FILTER;

export interface IEventFeedFilterTracker {
  tracker: TrackerType<EventFeedFilterObjType>;
  trackingObj: EventFeedFilterObjType;
}

export const FOLLOW_BUTTON = {
  ACTIONS: {
    FOLLOW: 'FOLLOW',
    SUBSCRIPTION: 'SUBSCRIPTION',
    UNFOLLOW: 'UNFOLLOW',
  },
  CATEGORY: 'follow',
  LABELS: {
    START: START_ACTION,
  },
};

export const FORGOT_PASSWORD = {
  ACTIONS: {
    EMAIL: 'EMAIL',
    REGISTRATION: 'REGISTRATION',
  },
  CATEGORY: 'forgotPassword',
  LABELS: {
    CANCEL: CANCEL_ACTION,
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
} as const;

export const HEADER_DETAILS = {
  ACTIONS: {
    GRAY_LIST: 'GRAY_LIST',
    SHARE_EMAIL,
    SHARE_FACEBOOK,
    SHARE_LINK,
    SHARE_NATIVE,
    SHARE_TWITTER,
    SHARE_WHATSAPP,
  },
  CATEGORY: 'header-details',
  LABELS: {
    FINISH: FINISH_ACTION,
  },
} as const;

export type HeaderDetailsObjType = typeof HEADER_DETAILS;

export interface IHeaderDetailsTracker {
  tracker: TrackerType<HeaderDetailsObjType>;
  trackingObj: HeaderDetailsObjType;
}

export const HEADER_PROFILE = {
  ACTIONS: {
    BLACK_LIST: 'BLACK_LIST',
    GRAY_LIST: 'GRAY_LIST',
    REVERT_BLACK_LIST: 'REVERT_BLACK_LIST',
  },
  CATEGORY: 'header-profile',
  LABELS: {
    FINISH: FINISH_ACTION,
  },
} as const;

export type HeaderProfileObjType = typeof HEADER_PROFILE;

export interface IHeaderProfileTracker {
  tracker: TrackerType<HeaderProfileObjType>;
  trackingObj: HeaderProfileObjType;
}

export const LANDING_PAGE = {
  ACTIONS: {
    CHANGE_LOCATION,
    LOCATE_ME,
    SELECT_POPULAR_PLACE,
  },
  CATEGORY: 'landing_page',
  LABELS: {
    START: START_ACTION,
  },
};

export type LandingPageObjType = typeof LANDING_PAGE;

export interface ILandingPageTracker {
  tracker: TrackerType<LandingPageObjType>;
  trackingObj: LandingPageObjType;
}

export const LOGIN = {
  ACTIONS: {
    APPLE: 'APPLE',
    APPS: 'APPS',
    EMAIL: 'EMAIL',
    FACEBOOK: FACEBOOOK,
    FORM_LOGIN: 'FORM_LOGIN',
  },
  CATEGORY: 'login',
  LABELS: {
    FAIL: FAIL_ACTION,
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
};

export const LOCATION_PICKER_PAGE = {
  ACTIONS: {
    CHANGE_LOCATION,
    LOCATE_ME: 'LOCATE_ME',
    SELECT_POPULAR_PLACE: 'SELECT_POPULAR_PLACE',
  },
  CATEGORY: 'location_picker_page',
  LABELS: {
    START: START_ACTION,
  },
};

export type LocationPickerPageObjType = typeof LOCATION_PICKER_PAGE;

export interface LocationPickerPageTracker {
  tracker: TrackerType<LocationPickerPageObjType>;
  trackingObj: LocationPickerPageObjType;
}
export const MAIN_MENU = {
  ACTIONS: {
    OPEN: 'OPEN',
    SWITCH_PROFILE: 'SWITCH_PROFILE',
  },
  CATEGORY: 'main_menu',
  LABELS: {
    FINISH: FINISH_ACTION,
  },
} as const;

type ProfileSwitchObjType = typeof MAIN_MENU;

export interface IProfileSwitchTracker {
  tracker: TrackerType<ProfileSwitchObjType>;
  trackingObj: ProfileSwitchObjType;
}

export const MAP_CONTROLS = {
  ACTIONS: {
    CHANGE_LOCATION,
    LOCATE_ME,
    OPEN_LOCATION_PICKER: 'OPEN_LOCATION_PICKER',
    SELECT_POPULAR_PLACE,
    SHOW_POSTS_FROM_HERE: 'SHOW_POSTS_FROM_HERE',
    TAKE_ME_HOME: 'TAKE_ME_HOME',
  },
  CATEGORY: 'map_controls',
  LABELS: {
    START: START_ACTION,
  },
};

export type MapControlsObjType = typeof MAP_CONTROLS;

export interface MapControlsTracker {
  tracker: TrackerType<MapControlsObjType>;
  trackingObj: MapControlsObjType;
}

export const MAP_MARKERS = {
  ACTIONS: {
    OPEN: 'OPEN',
  },
  CATEGORY: 'map_markers',
  LABELS: {
    START: START_ACTION,
  },
};

export const MAP_TOGGLER = {
  ACTIONS: {
    CLOSE: 'CLOSE',
    OPEN: 'OPEN',
  },
  CATEGORY: 'map_toggler',
  LABELS: {
    START: START_ACTION,
  },
} as const;

export const NAVIGATION = {
  ACTIONS: {
    NAVIGATE_TO: 'NAVIGATION',
  },
  CATEGORY: 'navigation',
  LABELS: {
    START: START_ACTION,
  },
} as const;

// see comments above in definition of DynamicActionTrackerType
export const NOTIFICATION_SETTINGS = {
  ACTIONS: {},
  CATEGORY: 'notificationsettings',
  LABELS: {
    CANCEL: CANCEL_ACTION,
    FAIL: FAIL_ACTION,
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
};

export interface INotificationSettingsTracker {
  tracker: DynamicActionTrackerType<typeof NOTIFICATION_SETTINGS>;
  trackingObj: typeof NOTIFICATION_SETTINGS;
}

export const ONBOARDING = {
  ACTIONS: {
    ADDRESS: 'ADDRESS',
    AVATAR: 'AVATAR',
    CATEGORY_ON_NEIGHBOR: 'CATEGORY_ON_NEIGHBOR',
    CATEGORY_ON_PUBLIC: 'CATEGORY_ON_PUBLIC',
    CHANGE_LOCATION,
    COMPLETE_PROFILE_CREATION_APPLE: 'COMPLETE_PROFILE_CREATION_APPLE',
    COMPLETE_PROFILE_CREATION_FACEBOOK: 'COMPLETE_PROFILE_CREATION_FACEBOOK',
    COMPLETE_PROFILE_CREATION_MANUAL: 'COMPLETE_PROFILE_CREATION_MANUAL',
    CONTACT_NUMBER: 'CONTACT_NUMBER',
    CONTACT_PERSON: 'CONTACT_PERSON',
    IMAGE_UPLOAD: 'IMAGE_UPLOAD',
    INTERNET_SITE: 'INTERNET_SITE',
    LOCATE_ME,
    NAME: 'NAME',
    PROFILE_NAME: 'PROFILE_NAME',
    PUBLIC_PROFILE_SELECT: 'PUBLIC_PROFILE_SELECT',
    SEND_VERIFICATION_TOKEN: 'SEND_VERIFICATION_TOKEN',
    SEND_VERIFICATION_TOKEN_BOTTOM_BUTTON: 'SEND_VERIFICATION_TOKEN_BOTTOM_BUTTON',
    SHOW_ERROR_FEEDBACK: 'SHOW_ERROR_FEEDBACK',
    STEP_1: 'STEP1',
    STEP_2: 'STEP2',
    STEP_3: 'STEP3',
    SUCCESS_GOTO_FOLLOW: 'SUCCESS_GOTO_FOLLOW',
    SUCCESS_GOTO_GROUPS: 'SUCCESS_GOTO_GROUPS',
    SUCCESS_GOTO_NEIGHBORS: 'SUCCESS_GOTO_NEIGHBORS',
    SUCCESS_GOTO_PROFILE: 'SUCCESS_GOTO_PROFILE',
    VERIFICATION_CODE: 'VERIFICATION_CODE',
    VERIFICATION_POST: 'VERIFICATION_POST',
    VERIFICATION_POST_BOTTOM_BUTTON: 'VERIFICATION_POST_BOTTOM_BUTTON',
    VERIFICATION_SMS: 'VERIFICATION_SMS',
  },
  CATEGORY: 'onboarding',
  LABELS: {
    CANCEL: CANCEL_ACTION,
    FAIL: FAIL_ACTION,
    FAIL_RETRY: FAIL_RETRY_ACTION,
    FINISH: FINISH_ACTION,
    GO_ASSOCIATION: 'GO_ASSOCIATION',
    GO_AUTHORITY: 'GO_AUTHORITY',
    GO_BACK: 'GO_BACK',
    GO_BLOGGER: 'GO_BLOGGER',
    GO_BUSINESS: 'GO_BUSINESS',
    GO_PRESS: 'GO_PRESS',
    ON_NEIGHBOR: 'ON_NEIGHBOR',
    ON_PUBLIC: 'ON_PUBLIC',
    SKIP_STEP3: 'SKIP_STEP3',
    START: START_ACTION,
    TO_NEWSLETTER: 'TO_NEWSLETTER',
    TO_POST: 'TO_POST',
    TO_SMS: 'TO_SMS',
    TO_VERIFYCODE: 'TO_VERIFYCODE',
    TO_VERIFY_SMS_CODE: 'TO_VERIFY_SMS_CODE',
  },
} as const;

export interface IOnboardingTracker {
  tracker: TrackerType<typeof ONBOARDING>;
  trackingObj: typeof ONBOARDING;
}

export const PAGE_404 = {
  ACTIONS: {
    CHANGE_LOCATION,
    LOCATE_ME: 'LOCATE_ME',
    SELECT_POPULAR_PLACE: 'SELECT_POPULAR_PLACE',
  },
  CATEGORY: 'page_404',
  LABELS: {
    START: START_ACTION,
  },
};

export type Page404ObjType = typeof PAGE_404;

export interface IPage404Tracker {
  tracker: TrackerType<Page404ObjType>;
  trackingObj: Page404ObjType;
}

export const POST_DETAILS = {
  ACTIONS: {
    SHARE_EMAIL,
    SHARE_FACEBOOK,
    SHARE_LINK,
    SHARE_NATIVE,
    SHARE_TWITTER,
    SHARE_WHATSAPP,
  },
  CATEGORY: 'post-details',
  LABELS: {
    FINISH: FINISH_ACTION,
  },
} as const;

export type PostDetailsObjType = typeof POST_DETAILS;

export interface IPostDetailsTracker {
  tracker: TrackerType<PostDetailsObjType>;
  trackingObj: PostDetailsObjType;
}

export const POST_PREVIEW = {
  ACTIONS: {
    GRAY_LIST: 'GRAY_LIST',
    SHARE_EMAIL,
    SHARE_FACEBOOK,
    SHARE_LINK,
    SHARE_NATIVE,
    SHARE_TWITTER,
    SHARE_WHATSAPP,
  },
  CATEGORY: 'post-preview',
  LABELS: {
    FINISH: FINISH_ACTION,
  },
} as const;

export type PostPreviewObjType = typeof POST_PREVIEW;

export interface IPostPreviewTracker {
  tracker: TrackerType<PostPreviewObjType>;
  trackingObj: PostPreviewObjType;
}

export const PROFILE_DIRECTORY = {
  ACTIONS: {
    SEARCH: 'SEARCH',
  },
  CATEGORY: 'profile_directory',
  LABELS: {
    FINISH: FINISH_ACTION,
  },
} as const;

export const REGISTRATION = {
  ACTIONS: {
    APPLE: 'APPLE',
    CHANGE_LOCATION,
    EMAIL: 'EMAIL',
    EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
    FACEBOOK: FACEBOOOK,
  },
  CATEGORY: 'registration',
  LABELS: {
    FAIL: FAIL_ACTION,
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
} as const;

export const SETTINGS = {
  ACTIONS: {
    AVATAR: 'AVATAR',
    BIRTHDAY: 'BIRTHDAY',
    BLACK_LIST: 'BLACK_LIST',
    CHANGE_EMAIL: 'CHANGE_EMAIL',
    COMMENTS: 'COMMENTS',
    CONTACT_NUMBER: 'CONTACT_NUMBER',
    CONTACT_PERSON: 'CONTACT_PERSON',
    DAILY_EMAIL: 'DAILY_EMAIL',
    DESCRIPTION: 'DESCRIPTION',
    FOLLOWEE_LIST: 'FOLLOWEE_LIST',
    GRAY_LIST: 'GRAY_LIST',
    IMAGE_UPLOAD: 'IMAGE_UPLOAD',
    INTERNET_SITE: 'INTERNET_SITE',
    NAME: 'NAME',
    NEWSLETTER: 'NEWSLETTER',
    PROFILE_NAME: 'PROFILE_NAME',
    REACTIONS: 'REACTIONS',
    REMOVE_PROFILE: 'REMOVE_PROFILE',
    RESPONSIBILITY_AREA: 'RESPONSIBILITY_AREA',
    SITE_NOTICE: 'SITE_NOTICE',
    VIEW_COUNT_NOTIFY: 'VIEW_COUNT_NOTIFY',
  },
  CATEGORY: 'settings',
  LABELS: {
    CANCEL: CANCEL_ACTION,
    FAIL: FAIL_ACTION,
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
} as const;

export interface ISettingsTracker {
  tracker: TrackerType<typeof SETTINGS>;
  trackingObj: typeof SETTINGS;
}

export const TOPIC_CHAT = {
  ACTIONS: {
    BANNER: 'BANNER',
    CLOSE: 'CLOSE',
    JOIN: 'JOIN',
    OPEN: 'OPEN',
    SEND_MESSAGE: 'SEND_MESSAGE',
    SWITCH_TO_MESSAGES: 'SWITCH_TO_MESSAGES',
    SWITCH_TO_USERS: 'SWITCH_TO_USERS',
    TOGGLE: 'TOGGLE',
  },
  CATEGORY: 'topic-chat',
  LABELS: {
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
} as const;

export const UPDATE_POST = {
  ACTIONS: {
    ADD_PHOTO: 'ADD_PHOTO',
    CAMPAIGN_WARNING: 'CAMPAIGN_WARNING',
    CLOSE_CALENDAR_RANGE,
    EVENT: 'EVENT',
    EVENT_ADD_CATEGORY: 'EVENT_ADD_CATEGORY',
    EVENT_ADD_TIME: 'EVENT_ADD_TIME',
    NAVIGATION: 'NAVIGATION',
    OPEN_CALENDAR_RANGE,
    PRIVATE_POST: 'PRIVATE_POST',
    PUBLIC_POST: 'PUBLIC_POST',
    SNAPSHOT: 'SNAPSHOT',
    WRITE_POST: 'WRITE_POST',
    WRITE_SURVEY: 'WRITE_SURVEY',
  },
  CATEGORY: 'update_post',
  LABELS: {
    CANCEL: CANCEL_ACTION,
    FAIL: FAIL_ACTION,
    FINISH: FINISH_ACTION,
    START: START_ACTION,
  },
} as const;

export type PostUpdateObjType = typeof UPDATE_POST;

export interface IUpdatePostTracker {
  tracker: TrackerType<PostUpdateObjType>;
  trackingObj: PostUpdateObjType;
}

export default initTracker;
