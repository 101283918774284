import { IconSize } from 'src/components/forms/icon/icon';
import { ColorType } from 'src/utils/color';

export enum SegmentUserActionType {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  REMOVE = 'REMOVE'
}

export interface SegmentUserActionProps {
  name: string;
  color: ColorType;
  customSize: IconSize;
  isInverted: boolean;
  noHover: boolean;
  isSmall: boolean;
}
