// lib
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';

// components
import BackbuttonHeader from 'src/components/header/common/backbutton_header/backbutton_header';
import MetaTags from 'src/components/meta_tags/meta_tags';
import EventParticipatingFeed from 'src/containers/smart_components/feeds/event_participating_feed';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { FEED_TYPE_EVENT } from 'src/constants/feed';
import { TWO_COLUMNS } from 'src/constants/layout';
import { EVENT_PARTICIPATING_TITLE } from 'src/constants/meta_tags';
import { textResources } from 'src/lang/de';
import { UrlUtils } from 'src/utils/url/url';

// helpers
import { URLContext } from 'src/containers/url_provider/url_provider';
import { usePageTracking, useIsPostCreateOpen } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';

const EventParticipatingFeedPage: React.FC<RouteComponentProps> = ({ match, history }) => {
  const user = useSelectUser();

  const { getEventsfeedRoute } = useContext(URLContext);
  const isPostCreateOpen = useIsPostCreateOpen(match);

  usePageTracking({ user });

  const goBack = () => { UrlUtils.goBack(history, getEventsfeedRoute()); };

  return (
    <>
      <MetaTags
        title={EVENT_PARTICIPATING_TITLE}
      />
      <LayoutNoMap
        subHeader={
          <BackbuttonHeader
            title={textResources.eventfeed.myEvents}
            handleBackButton={goBack}
          />}
        activeNavigationItem={FEED_TYPE_EVENT}
        contentWidth={TWO_COLUMNS}
        withHeaderMargin
        pageTitle={<StandardPageTitle text={textResources.pageTitles.eventParticipatingFeed} />}
      >
        <EventParticipatingFeed postCreateOpen={isPostCreateOpen}/>
      </LayoutNoMap>
    </>
  );
};

export default EventParticipatingFeedPage;
