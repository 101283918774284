import { useMemo } from 'react';

import { useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { getToggle } from 'src/utils/toggle';

export interface IChatTopic {
  name: string;
  open: boolean;
  finished: boolean;
  targetedShapes: string[];
  bannerText: string;
  imageUrl?: string;
  title: {
    loggedOutClosed: string;
    loggedOutOpen: string;
    loggedInClosed: string;
    loggedInNotJoined: string;
    finished: string;
  };
  message: {
    loggedOutClosed: string;
    loggedOutOpen: string;
    loggedInClosed: string;
    loggedInNotJoined: string;
    finished: string;
  };
}

interface ITopticChatToggles {
  topics: IChatTopic[];
  isEnabled: boolean;
}

const { topics, isEnabled } = getToggle<ITopticChatToggles>('topic_chat', { isEnabled: false, topics: [] });

export const getTopiChatToggles = (): ITopticChatToggles => ({ isEnabled, topics });

export const useChatTopicTargetsShape = () => {
  const appLocationShape = useGetAppLocationShape();
  const chatTopicOpenInShape = useMemo(() =>
    isEnabled && appLocationShape &&
      topics.find(({ targetedShapes }) => targetedShapes.indexOf(appLocationShape.identifier) > -1)
  , [appLocationShape]);
  return chatTopicOpenInShape;
};
