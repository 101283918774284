import { useSelector } from 'react-redux';

import { IShapeSponsorPlacement, IShapeSponsor } from 'src/interfaces/shape_sponsors';

import { IRootState } from 'src/reducers/interface';
// helpers
import { useProfilesFromIds } from '../profiles_list/profiles_list_hooks';

export const useSelectShapeSponsorForDetailPagePresent = () => {
  return useSelector<IRootState, boolean>(({ shapeSponsors }) =>
    shapeSponsors.sponsors.some((sponsor) =>
      sponsor.placements.detailPage
    ));
};

export const useSelectShapeSponsors = (placements?: IShapeSponsorPlacement) => {
  const sponsors = useSelector<IRootState, IShapeSponsor[]>(({ shapeSponsors }) => shapeSponsors.sponsors);

  const filteredSponsors = placements
    ? sponsors.filter(sponsor =>
      Object.entries(placements).every(([key, value]) => sponsor.placements[key] === value))
    : sponsors;

  const bronzeIds = filteredSponsors.filter(sponsor => sponsor.level === 'bronze').map(sponsor => sponsor.sponsorId);
  const silverIds = filteredSponsors.filter(sponsor => sponsor.level === 'silver').map(sponsor => sponsor.sponsorId);

  const bronze = useProfilesFromIds(bronzeIds);
  const silver = useProfilesFromIds(silverIds);

  return {
    bronze,
    silver,
  };
};
