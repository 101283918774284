import { setLocationShapeIds } from 'src/actions/app-state/app-state';
import { setIsHome } from 'src/actions/user/user';
import {
  dispatchLocationToStore,
  dispatchUserIsHomeToStore,
  setLocationAndPositionHeadersFromStore,
} from 'src/api/concerns/position_headers';
import { AlterRequest } from 'src/api/middleware/interfaces';
import { getStore } from 'src/store/store-holder';

const alterRequest: AlterRequest = async(request: Request) => {
  const headers = setLocationAndPositionHeadersFromStore(getStore());
  for (const key of Object.keys(headers)) {
    request.headers.set(key, headers[key]);
  }

  return request;
};

const saveLocationToStore = async(response: Response): Promise<void> => {
  const store = getStore();
  dispatchLocationToStore(response, setLocationShapeIds, store);
  dispatchUserIsHomeToStore(response, setIsHome, store);
};

export default {
  alterRequest,
  saveLocationToStore,
};
