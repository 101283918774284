import React from 'react';

// helpers
import { textResources } from 'src/lang/de';

// constants
import { CLASS_PREFIX } from 'src/constants/';

import './group_empty_item.scss';

const cls = CLASS_PREFIX + 'group_empty_item';

interface IProps {
}

const GroupEmptyItem: React.FC<IProps> = () => {
  return (
    <p className={cls}>{textResources.groups.moreGroupsEmpty}</p>
  );
};

export default GroupEmptyItem;
