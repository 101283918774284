// lib
import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

// components
import BackbuttonHeader, { IProps } from 'src/components/header/common/backbutton_header/backbutton_header';

// interfaces & constants
import { FRONTEND_EVENT_PATH } from 'src/constants/urls';
import { IRootState } from 'src/reducers/interface';

// helpers
import { withRouterProps } from 'src/high_order_components/connect_route_props';

const mapStateToProps = ({ filters }: IRootState): IProps => ({
  title: filters.activeFilter.label,
});

interface IMapRouteToProps {
  handleBackButton?: () => void;
}

const mapRouteToProps = ({ history }: RouteComponentProps): IMapRouteToProps => ({
  handleBackButton: () => history.push(FRONTEND_EVENT_PATH),
});

export default flowRight(
  withRouterProps(mapRouteToProps),
  connect(mapStateToProps),
)(BackbuttonHeader);
