// libs
import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

// interfaces / constants
import { FRONTEND_NOT_FOUND_PATH } from 'src/constants/urls';

// components
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import ProfilesList from 'src/components/profiles_list/profiles_list';
import ProfileListWrapper from 'src/containers/smart_components/list_wrapper/profile_list_wrapper';

// helpers
import { useFetchProfile } from 'src/actions/profile/profile_hooks';
import {
  useGetFollowersNextPage, useCleanProfilesListEffect,
} from 'src/actions/profiles_list/profiles_list_hooks';
import { useGetProfileIdWithTypeFromUrlParams } from 'src/hooks_shared/use_url_params';
import { textResources } from 'src/lang/de';
import { useSelectProfile } from 'src/reducers/profiles/profiles_hooks';
import {
  useProfilesSecondaryList, useSecondaryProfiles,
} from 'src/reducers/profiles_list/profiles_list_hooks';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import './profile_followers.scss';

const labels = textResources.follow;
const cls = createBemBlock('profile_followers');
const noFollowersCls = cls('no-followers');

const ProfileFollowers: React.FC = () => {
  const profilesList = useSecondaryProfiles();
  const { isLoading } = useProfilesSecondaryList();

  const { profileId, profileType } = useGetProfileIdWithTypeFromUrlParams();
  // const getFollowers = useGetFollowers();
  const getFollowersNextPage = useGetFollowersNextPage();

  const fetchProfile = useFetchProfile();
  const profileDetails = useSelectProfile(profileId);

  const isExpectedProfile = profileDetails?.id === profileId;

  useEffect(() => {
    if (profileType && profileId) {
      fetchProfile(profileType, profileId);
    }
  }, [fetchProfile, profileId, profileType]);

  useCleanProfilesListEffect();

  if (!isExpectedProfile ||
      profileDetails?.meta.isOwn === true && profileDetails?.meta.isFollowable === true) {
    return (
      <LoadingSpinnerWrapper isLoading={isLoading}>
        <ProfileListWrapper
          isSecondary
          getNextPage={getFollowersNextPage}
          initialLoad={true}
        >
          {!isLoading && isExpectedProfile && !profilesList.length
            ? <div className={noFollowersCls}>
              <p>{labels.noFollowersTitle}</p>
              <p>{labels.noFollowersMessage}</p>
            </div>
            : <ProfilesList profiles={profilesList} />
          }
        </ProfileListWrapper>
      </LoadingSpinnerWrapper>
    );
  }

  return <Redirect to={FRONTEND_NOT_FOUND_PATH} />;
};

ProfileFollowers.displayName = 'ProfileFollowers';

export default ProfileFollowers;
