// libs
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// interfaces & constants
import { CLASS_PREFIX } from 'src/constants';
import { LOCATION_PICKER_DEFAULT_POPULAR_PLACES } from 'src/constants/location_picker';
import { ILocationShape } from 'src/interfaces/location';
import { IPopularPlacesToggle } from 'src/interfaces/popular_places_toggle';
import { textResources } from 'src/lang/de';

// components
import LocationPickerItemSegment from 'src/components/item_segment/layouts/location_picker/location_picker';

// helpers
import { $locationShape } from 'src/api/decoders/location_shape';
import { getToggle } from 'src/utils/toggle';
import { replaceLocationSlugFromPath } from 'src/utils/url/url';

import './popular_places.scss';

const cls: string = CLASS_PREFIX + 'location-picker__popular-places';

const popularPlacesFromToggle =
  getToggle<IPopularPlacesToggle>('popularPlaces_v2', LOCATION_PICKER_DEFAULT_POPULAR_PLACES)
    .places.map((place) => $locationShape(place));

interface IProps extends RouteComponentProps {
  callback?: () => void;
  getRedirectPath?: (locationSlug?: string) => string;
}

const PopularPlaces: React.FC<IProps> = ({ match, callback, getRedirectPath }) => (
  <div>
    <h2 className={`${cls}-title`}>{textResources.locationChanger.popularPlaces}</h2>
    {popularPlacesFromToggle.map((locationShape: ILocationShape, index: number) => {
      return (
        <LocationPickerItemSegment
          key={index}
          label={locationShape.name}
          navigation={{
            onClick: callback,
            url: getRedirectPath
              ? getRedirectPath(locationShape.slug)
              : replaceLocationSlugFromPath(match, locationShape.slug),
          }}
        />
      );
    })}
  </div>
);

export default withRouter(PopularPlaces);
