// libs
import classNames from 'classnames';
import React, { useContext } from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { PROFILES_SEARCH_QUERY_KEY } from 'src/constants/urls';
import { URLContext } from 'src/containers/url_provider/url_provider';
import { IKeyValue } from 'src/interfaces/';

// components
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import './tag.scss';

const cls = `${CLASS_PREFIX}tag`;

interface ITagProps {
  text: string;
  className?: string;
  isClickable?: boolean;
}

const Tag: React.FC<ITagProps> = ({ text, className, isClickable }) => {
  const classes: IKeyValue = {
    [cls]: true,
    [`${className}`]: className,
  };

  const { getProfilesDirectoryRoute } = useContext(URLContext);
  const uriEncodedTag = encodeURIComponent(text);
  const queryUrl = `${getProfilesDirectoryRoute()}?${PROFILES_SEARCH_QUERY_KEY}=${uriEncodedTag}`;

  if (isClickable) {
    return (
      <NavigationItem url={queryUrl} className={classNames(classes)}>
        {text}
      </NavigationItem>
    );
  }

  return (
    <span className={classNames(classes)}>
      {text}
    </span>
  );
};

Tag.displayName = 'Tag';

export default Tag;
