import React from 'react';

// components
import NavigationItem from 'src/components/navigation_item/navigation_item';

// constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import { textResources } from 'src/lang/de';

import './email_confirm_message.scss';

const cls = CLASS_PREFIX + 'email-confirm-message';
const labels = textResources.intermediateProfile.confirmEmail;
const { helpEmail, period } = textResources.shared;

const EmailConfirmMessage: React.FC<{ userEmailAddress?: string }> = ({ userEmailAddress }) => {
  return (
    <p>
      {labels.body1}
      <span className={cls + '__custom-text--bold'}>{userEmailAddress}</span>
      {labels.body2}
      <NavigationItem
        className={cls + '__custom-text--highlighted'}
        target={'_blank'}
        url={'mailto:' + helpEmail}> {helpEmail}
      </NavigationItem>
      {period}
    </p>
  );
};

export default EmailConfirmMessage;
