import * as Response from 'src/api/interfaces/responses/oglink';
import { ILinkPreview } from 'src/interfaces/post_ingredients';

export const $oglink = (json: Response.IOglink): ILinkPreview => ({
  author: json.author,
  description: json.description,
  displayUrl: json.original_url,
  id: json.identifier,
  imageUrl: json.image_urls && json.image_urls.square_small,
  title: json.title,
  url: json.url,
});
