// libs
import * as React from 'react';

// components
import BackButton from 'src/components/header/subheaders/shared_contents/backbutton/backbutton';
import Title from 'src/components/header/subheaders/shared_contents/title/title';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

import './backbutton_header.scss';

const cls = CLASS_PREFIX + 'backbutton_header';
const clsWrapper = `${cls}__wrapper`;

export interface IProps {
  handleBackButton?: () => void;
  title?: string | JSX.Element;
}

/**
 * details header
 */
const BackbuttonHeader: React.FC<IProps> = ({ handleBackButton, title, children }) => (
  <div className={cls}>
    <div className={clsWrapper}>
      <BackButton onClick={handleBackButton}/>
      <Title>{title}</Title>
      {children}
    </div>
  </div>
);

/* set displayname to not have a component without a name */
BackbuttonHeader.displayName = 'BackbuttonHeader';

export default BackbuttonHeader;
