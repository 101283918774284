// libs
import * as React from 'react';

// interfaces / constants
import { IBlurred } from 'src/components/blurred_content/blurred_content';
import { IIngredientSettings, IngredientType } from 'src/interfaces/post_ingredients';
import { IContentItemType, PostDisplayMode } from 'src/interfaces/posts';

// classes
import BodyMarkdown from 'src/components/post/shared/ingredients/body_markdown/body_markdown';
import BodyText from 'src/components/post/shared/ingredients/body_text/body_text';
import EventShortDate from 'src/components/post/shared/ingredients/event_short_date/event_short_date';
import EventStartEnd from 'src/components/post/shared/ingredients/event_start_end/event_start_end';
import Image from 'src/components/post/shared/ingredients/image/image';
import ImageSnapshot from 'src/components/post/shared/ingredients/image_snapshot/image_snapshot';
import ImageWithDate from 'src/components/post/shared/ingredients/image_with_date/image_with_date';
import InclusionReason from 'src/components/post/shared/ingredients/inclusion_reason/inclusion_reason';
import LinkPreview from 'src/components/post/shared/ingredients/link_preview/link_preview';
import Title from 'src/components/post/shared/ingredients/title/title';
import Video from 'src/components/post/shared/ingredients/video/video';
import EventParticipation from 'src/containers/event_participation';
import { EventLocationContainer } from './event_location/event_location_container';
import { MapFeaturedContainer } from './map/map_featured_container';

interface IProps {
  blurred?: IBlurred;
  postDetailsURL?: string;
  postId: string;
  postType?: IContentItemType;
  ingredients: IngredientType[];
  displayMode: PostDisplayMode;
}

interface IComponentMap {
  [key: string]: React.ReactNode;
}

type ISettingsMap = {
  [K in Ingredients]: IIngredientSettings
};

type Ingredients = keyof typeof ingredientsTypeToComponent;

// settingsMap - See lokalportal/public/static/ingredient-settings.json for details
const settingsMap: ISettingsMap = {
  bodyMarkdown: {
    admin: {
      basic_ingredient: { show: true },
    },
    banner: {
      basic_ingredient: { show: false },
    },
    compact: {
      basic_ingredient: { show: false },
    },
    detail: {
      basic_ingredient: { show: true },
    },
    preview: {
      basic_ingredient: { show: true },
    },
  },
  bodyText: {
    admin: {
      basic_ingredient: { show: true },
    },
    banner: {
      basic_ingredient: { show: false },
    },
    compact: {
      basic_ingredient: { show: false },
    },
    detail: {
      basic_ingredient: { show: true },
    },
    preview: {
      basic_ingredient: { show: true },
    },
  },
  eventLocation: {
    admin: {
      basic_ingredient: { show: true },
    },
    banner: {
      basic_ingredient: { show: true },
    },
    compact: {
      basic_ingredient: { show: false },
    },
    detail: {
      basic_ingredient: { show: true },
    },
    preview: {
      basic_ingredient: { show: true },
    },
  },
  eventShortTime: {
    admin: {
      basic_ingredient: { show: false },
    },
    banner: {
      basic_ingredient: { show: true },
    },
    compact: {
      basic_ingredient: { show: false },
    },
    detail: {
      basic_ingredient: { show: false },
    },
    preview: {
      basic_ingredient: { show: false },
    },
  },
  eventStartEnd: {
    admin: {
      basic_ingredient: { show: true },
    },
    banner: {
      basic_ingredient: { show: false },
    },
    compact: {
      basic_ingredient: { show: false },
    },
    detail: {
      basic_ingredient: { show: true },
    },
    preview: {
      basic_ingredient: { show: false },
    },
  },
  image: {
    admin: {
      basic_ingredient: { show: true, showGallery: true },
      media: { show: true, showGallery: true },
      media_candidate: { show: false, showGallery: false },
    },
    banner: {
      basic_ingredient: { show: true, showGallery: false },
      media: { show: false, showGallery: false },
      media_candidate: { show: false, showGallery: false },
    },
    compact: {
      basic_ingredient: { show: true, showGallery: true },
      media: { show: true, showGallery: true },
      media_candidate: { show: false, showGallery: false },
    },
    detail: {
      basic_ingredient: { show: true, showGallery: true },
      media: { show: true, showGallery: true },
      media_candidate: { show: false, showGallery: false },
    },
    preview: {
      basic_ingredient: { show: true, showGallery: true },
      media: { show: true, showGallery: true },
      media_candidate: { show: false, showGallery: false },
    },

  },
  imageSnapshot: {
    admin: {
      basic_ingredient: { show: true, showGallery: true },
      media: { show: true, showGallery: true },
      media_candidate: { show: false, showGallery: false },
    },
    banner: {
      basic_ingredient: { show: true, showGallery: false },
      media: { show: false, showGallery: false },
      media_candidate: { show: false, showGallery: false },
    },
    compact: {
      basic_ingredient: { show: true, showGallery: true },
      media: { show: true, showGallery: true },
      media_candidate: { show: false, showGallery: false },
    },
    detail: {
      basic_ingredient: { show: true, showGallery: false, showLightbox: true },
      media: { show: true, showGallery: true, showLightbox: true },
      media_candidate: { show: false, showGallery: false, showLightbox: true },
    },
    preview: {
      basic_ingredient: { show: true, showGallery: false },
      media: { show: true, showGallery: false },
      media_candidate: { show: false, showGallery: false },
    },
  },
  imageWithDate: {
    admin: {
      basic_ingredient: { show: true },
    },
    banner: {
      basic_ingredient: { show: true },
    },
    compact: {
      basic_ingredient: { show: true },
    },
    detail: {
      basic_ingredient: { show: true },
    },
    preview: {
      basic_ingredient: { show: true },
    },
  },
  inclusionReason: {
    admin: {
      basic_ingredient: { show: false },
    },
    banner: {
      basic_ingredient: { show: false },
    },
    compact: {
      basic_ingredient: { show: false },
    },
    detail: {
      basic_ingredient: { show: true },
    },
    preview: {
      basic_ingredient: { show: true },
    },
  },
  linkPreview: {
    admin: {
      basic_ingredient: { show: true },
    },
    banner: {
      basic_ingredient: { show: false },
    },
    compact: {
      basic_ingredient: { show: false },
    },
    detail: {
      basic_ingredient: { show: true },
    },
    preview: {
      basic_ingredient: { show: true },
    },
  },
  map: {
    admin: {
      basic_ingredient: { show: false },
    },
    banner: {
      basic_ingredient: { show: false },
    },
    compact: {
      basic_ingredient: { show: false },
    },
    detail: {
      basic_ingredient: { show: true },
      media_candidate: { show: true },
    },
    preview: {
      basic_ingredient: { show: false },
    },
  },
  participants: {
    admin: {
      basic_ingredient: { show: true },
    },
    banner: {
      basic_ingredient: { show: true },
    },
    compact: {
      basic_ingredient: { show: true },
    },
    detail: {
      basic_ingredient: { show: true },
    },
    preview: {
      basic_ingredient: { show: true },
    },
  },
  title: {
    admin: {
      basic_ingredient: { show: true },
    },
    banner: {
      basic_ingredient: { show: true },
    },
    compact: {
      basic_ingredient: { show: true },
    },
    detail: {
      basic_ingredient: { show: true },
    },
    preview: {
      basic_ingredient: { show: true },
    },
  },
  video: {
    admin: {
      basic_ingredient: { show: true, showGallery: true },
      media: { show: true, showGallery: true },
      media_candidate: { show: false, showGallery: false },
    },
    banner: {
      basic_ingredient: { show: false, showGallery: false },
      media: { show: false, showGallery: false },
      media_candidate: { show: false, showGallery: false },
    },
    compact: {
      basic_ingredient: { show: true, showGallery: true },
      media: { show: true, showGallery: true },
      media_candidate: { show: false, showGallery: false },
    },
    detail: {
      basic_ingredient: { show: true, showGallery: true },
      media: { show: true, showGallery: true },
      media_candidate: { show: true, showGallery: true },
    },
    preview: {
      basic_ingredient: { show: true, showGallery: true },
      media: { show: true, showGallery: true },
      media_candidate: { show: false, showGallery: false },
    },
  },
};

// mapping from string to Component
const ingredientsTypeToComponent: IComponentMap = {
  bodyMarkdown: BodyMarkdown,
  bodyText: BodyText,
  eventLocation: EventLocationContainer,
  eventShortTime: EventShortDate,
  eventStartEnd: EventStartEnd,
  image: Image,
  imageSnapshot: ImageSnapshot,
  imageWithDate: ImageWithDate,
  inclusionReason: InclusionReason,
  linkPreview: LinkPreview,
  map: MapFeaturedContainer,
  participants: EventParticipation,
  title: Title,
  video: Video,
};

const Ingredients: React.FC<IProps> = ({ blurred, postDetailsURL, ingredients, displayMode, postId, postType }) => {
  return (
    <div>
      {
        ingredients.map((ingredient, index: number) =>
          getIngredient(index, displayMode, ingredient, postId, postType, postDetailsURL, blurred),
        )
      }
    </div>
  );
};

const getIngredient = (
  index: number,
  displayMode: PostDisplayMode,
  ingredient: IngredientType,
  postId: string,
  postType?: IContentItemType,
  postDetailsURL?: string,
  blurred?: IBlurred,
): React.ReactNode => {
  const settings = settingsMap[ingredient.type][displayMode][ingredient.meta.purpose];
  const Component = ingredientsTypeToComponent[ingredient.type] as React.ComponentClass<any>;

  if (!Component || !settings || !settings.show) {
    return null;
  }

  return (
    <Component
      key={index}
      blurred={blurred}
      displayMode={displayMode}
      settings={settings}
      data={ingredient.data}
      postDetailsURL={postDetailsURL}
      postId={postId}
      postType={postType}
    />
  );
};

export default Ingredients;
