// lib
import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';

// components
import BackbuttonHeader from 'src/components/header/common/backbutton_header/backbutton_header';
import MetaTags from 'src/components/meta_tags/meta_tags';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import ShapeSponsorsList from 'src/containers/smart_components/shape_sponsors/shape_sponsors_list/shape_sponsors_list';

// constants & interfaces
import { SHAPE_SPONSORS_TITLE, SHAPE_SPONSORS_DESCRIPTION } from 'src/constants/meta_tags';

// helpers
import { textResources } from 'src/lang/de';
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { UrlUtils } from 'src/utils/url/url';
import { LayoutFeaturedContainer } from '../layout/layout_featured_container';

const ShapeSponsorsPage: React.FC<RouteComponentProps> = ({ history }) => {
  const user = useSelectUser();

  usePageTracking({ user });

  const handleBackButton = useCallback(() => {
    UrlUtils.goBack(history);
  }, [history]);

  return (
    <>
      <MetaTags
        title={SHAPE_SPONSORS_TITLE}
        og_title={SHAPE_SPONSORS_TITLE}
        description={SHAPE_SPONSORS_DESCRIPTION}
      />
      <LayoutFeaturedContainer
        pageTitle={<StandardPageTitle text={textResources.pageTitles.shapeSponsors} showLocation />}
        subHeader={<BackbuttonHeader title={textResources.shapeSponsors.title} handleBackButton={handleBackButton} />}
        withHeaderMargin>
        <ShapeSponsorsList />
      </LayoutFeaturedContainer>
    </>
  );
};

export default ShapeSponsorsPage;
