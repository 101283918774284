// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { REACTIONS_TIMEOUT } from 'src/constants/timeouts';
import { IReaction, IReactionsOwnProps, ReactionName } from 'src/interfaces/reactions';

// helpers
import { translateReactions } from 'src/utils/reactions';

import './reactions.scss';

export interface IReactionsProps extends IReactionsOwnProps {
  reactions: IReaction[];
  updateUrl: string;
  updateReaction: (reaction: ReactionName, updateUrl: string, oldReaction?: ReactionName) => void;
}

interface IReactionProps extends IReaction {
  active?: boolean;
  onClose?: () => void;
  update: (name: ReactionName) => void;
}

interface IReactionState {
  active: boolean;
}

const cls = CLASS_PREFIX + 'reaction';
const wrapperCls = CLASS_PREFIX + 'reactions';

class Reaction extends React.Component<IReactionProps, IReactionState> {
  private timeoutId: number;
  constructor(props: IReactionProps) {
    super(props);
    this.state = {
      active: false,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  public componentWillUnmount() {
    this.timeoutId && clearTimeout(this.timeoutId);
  }
  public render() {
    const { name, own } = this.props;
    const reactionClasses = this.getReactionClass();
    return (
      <div onClick={this.handleSelect} className={cls + '__wrapper'} >
        <div className={reactionClasses} />
        <p className={classNames(cls + '__caption', own ? cls + '__caption-bold' : null)}>
          {translateReactions(name)}
        </p>
      </div>
    );
  }
  private handleSelect() {
    const { update, onClose, name } = this.props;
    update(name);
    this.setState({ active: true }, () => {
      this.timeoutId = window.setTimeout(() => {
        this.setState({ active: false });
        onClose && onClose();
      }, REACTIONS_TIMEOUT);
    });
  }

  private getReactionClass(): string {
    const reactionClass = this.state.active ? `${cls} ${cls}--active` : cls;
    const reactionNameClass = `${cls}--${this.props.name}`;
    const reactionNameActiveClass = this.state.active ? `${reactionNameClass}--active` : reactionNameClass;
    return classNames(reactionClass, reactionNameActiveClass);
  }
}

const Reactions = (props: IReactionsProps) => {
  const { updateReaction, updateUrl, reactions, className, ...rest } = props;
  const update = (name: ReactionName) => {
    const prevSelectedReaction: IReaction | undefined =
      reactions.find((reaction: IReaction) => reaction.own === true);
    updateReaction(name, updateUrl, prevSelectedReaction ? prevSelectedReaction.name : undefined);
  };

  return (
    <div className={classNames(wrapperCls, className)}>
      {reactions.map((reaction, i) =>
        <Reaction key={i} update={update} {...reaction} {...rest} />)
      }
    </div>
  );
};

export default Reactions;
