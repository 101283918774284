// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import PostHeaderDetailsContainer from 'src/containers/header/subheader/post_header_details';
import PostDetailsContainer from 'src/containers/post/post_details_container';
import PageTitleDetailsPage from 'src/containers/smart_components/page_title/page_title_details_page';

// constants & interfaces
import { FEED_TYPE_DETAILS_PAGE } from 'src/constants/feed';
import {
  POST_ARTICLE_PUBLISHER,
  NOINDEX_FOLLOW_ROBOTS,
  DEFAULT_ROBOTS,
  POST_OG_TYPE,
  DETAILS_PAGE_TITLE,
} from 'src/constants/meta_tags';

// helpers
import { Redirect } from 'react-router-dom';
import ShapeSponsorsInfo from 'src/containers/smart_components/shape_sponsors/shape_sponsors_info/shape_sponsors_info';
import LayoutCustomSideBar from 'src/layout/layout_custom_side_bar';
import { usePageTracking } from 'src/pages/hooks/hooks';
import { usePostData, useHasError } from 'src/reducers/feed/feed_hooks';
import { useSelectProfile } from 'src/reducers/profiles/profiles_hooks';
import { useSelectShapeSponsorForDetailPagePresent } from 'src/reducers/shape_sponsors/shape_sponsor_hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { JsonLdSchemaHelper } from 'src/utils/json_ld_schema/json_ld_schema';
import { UrlUtils } from 'src/utils/url/url';
import { FRONTEND_NOT_FOUND_PATH } from '../constants/urls';

interface IProps extends RouteComponentProps<{ id: string }> {
  isEvent?: boolean;
}

const PostDetailsPage: React.FC<IProps> = ({ match, isEvent }) => {
  const user = useSelectUser();
  const post = usePostData(match.params.id);
  const author = useSelectProfile(post?.authorId);
  const hasError = useHasError(FEED_TYPE_DETAILS_PAGE);

  const jsonLd = post && (post.meta.schema === 'Event'
    ? JsonLdSchemaHelper.detailPageEvent(post)
    : author && JsonLdSchemaHelper.detailPagePost(post, author));

  const title = post && DETAILS_PAGE_TITLE(post.meta.title);
  usePageTracking({ author, pageTitle: title, post, user }, hasError);

  const sponsorsForDetailpagePresent = useSelectShapeSponsorForDetailPagePresent();

  if (post?.survey) {
    return <Redirect to={FRONTEND_NOT_FOUND_PATH} />;
  }

  return (
    <>
      <MetaTags
        article_modified_time={post?.updatedAt}
        article_published_time={post?.publishedAt}
        article_publisher={POST_ARTICLE_PUBLISHER}
        author={author?.name}
        canonical={UrlUtils.currentUrlWithHostAndPath()}
        date={post?.publishedAt}
        description={post?.meta.teaser}
        geo_placename={post?.city}
        geo_position={post && `${post.position.latitude}}${post.position.longitude}`}
        json_ld={jsonLd}
        last_modified={post?.updatedAt}
        og_image={post?.featuredImage}
        og_type={POST_OG_TYPE}
        og_url={UrlUtils.currentUrlWithHostAndPathAndFilteredParams()}
        title={title}
        robots={post?.meta.fenced ? NOINDEX_FOLLOW_ROBOTS : DEFAULT_ROBOTS}
      />
      <LayoutCustomSideBar
        pageTitle={<PageTitleDetailsPage isEvent={isEvent} />}
        subHeader={<PostHeaderDetailsContainer {...{ match }} />}
        sideBarContent={<ShapeSponsorsInfo placements={{ detailPage: true }} />}
        sideBarOnlyVisibleOnDesktop
        withSidebar={sponsorsForDetailpagePresent}
      >
        <PostDetailsContainer postType={isEvent ? 'event' : 'post'} match={match} />
      </LayoutCustomSideBar>
    </>
  );
};

export default PostDetailsPage;
