// lib
import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import BackbuttonHeader from 'src/components/header/common/backbutton_header/backbutton_header';
import MetaTags from 'src/components/meta_tags/meta_tags';
import PaginationFeed from 'src/containers/smart_components/feeds/pagination_feed';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { FEED_TYPE_GROUPS_RECENT } from 'src/constants/feed';
import { FRONTEND_NEWSFEED_PATH } from 'src/constants/urls';

// helpers
import {
  useFetchGroupsRecentFeedPage,
  useFetchGroupsRecentFeedNextPage,
} from 'src/actions/feed/groups_recent_feed/groups_recent_feed_hooks';
import { textResources } from 'src/lang/de';
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { UrlUtils } from 'src/utils/url/url';

const GroupsRecentFeedPage: React.FC = () => {
  const user = useSelectUser();

  usePageTracking({ user });

  const getGroupsRecentFeedNextPage = useFetchGroupsRecentFeedNextPage();
  const getGroupsRecentFeedPage = useFetchGroupsRecentFeedPage();
  const history = useHistory();
  const handleBackButton = () => {
    UrlUtils.goBack(history, FRONTEND_NEWSFEED_PATH);
  };

  return (
    <>
      <MetaTags
        title={textResources.metatags.titles.recentGroupsFeed}
      />
      <LayoutNoMap
        subHeader={<BackbuttonHeader
          title={textResources.newsfeed.highlights.allGroupPosts}
          handleBackButton={handleBackButton}
        />}
        withHeaderMargin
        pageTitle={<StandardPageTitle text={textResources.pageTitles.recentGroupsFeed} />}
      >
        <PaginationFeed
          feedType={FEED_TYPE_GROUPS_RECENT}
          getFirstPage={getGroupsRecentFeedPage}
          getNextPage={getGroupsRecentFeedNextPage}
        />
      </LayoutNoMap>
    </>
  );
};

export default GroupsRecentFeedPage;
