// libs
import produce from 'immer';

// interfaces / constants
import { IAction } from 'src/actions/';
import {
  POST_CREATE_REQUEST_ERROR,
  POST_CREATE_REQUEST_PENDING,
  POST_CREATE_REQUEST_SUCCESS,
  POST_UPDATE_REQUEST_ERROR,
  POST_UPDATE_REQUEST_PENDING,
  POST_UPDATE_REQUEST_SUCCESS,
} from 'src/actions/post/post';

// classes

// helpers

export interface IPost {
  hasError: boolean;
  isLoading: boolean;
}

export const INITIAL_STATE: IPost = {
  hasError: false,
  isLoading: false,
};

const postReducer = (state = INITIAL_STATE, action: IAction): IPost =>
  produce(state, (draft) => {
    switch (action.type) {
      case POST_CREATE_REQUEST_PENDING:
        draft.hasError = false;
        draft.isLoading = true;
        break;

      case POST_CREATE_REQUEST_SUCCESS:
        draft.hasError = false;
        draft.isLoading = false;
        break;

      case POST_CREATE_REQUEST_ERROR:
        draft.hasError = true;
        draft.isLoading = false;
        break;

      case POST_UPDATE_REQUEST_PENDING:
        draft.hasError = false;
        draft.isLoading = true;
        break;

      case POST_UPDATE_REQUEST_SUCCESS:
        draft.hasError = false;
        draft.isLoading = false;
        break;

      case POST_UPDATE_REQUEST_ERROR:
        draft.hasError = true;
        draft.isLoading = false;
        break;
    }
  });

export default postReducer;
