// libs
import { ThunkAction } from 'redux-thunk';

// interfaces
import { PromiseMiddlewareActions, IAction } from 'src/actions/';
import { IGet, RequestParams } from 'src/actions/feed/feed_interfaces';
import { IPaginationParams } from 'src/api';
import {
  FEED_TYPE_NEWS,
  FEED_TYPE_EVENT,
  FEED_TYPE_PROFILE,
  FEED_TYPE_EVENT_CATEGORIZED,
  FEED_TYPE_LOCAL,
  FEED_TYPE_EVENT_RELATED,
  FEED_TYPE_EVENT_PARTICIPATING,
  FEED_TYPE_DETAILS_PAGE,
  FEED_TYPE_GROUP,
} from 'src/constants/feed';
import { IFeedType, IFeedDecoderResults } from 'src/interfaces/feed';
import { IRootState } from 'src/reducers/interface';

export const FEED_NEXT_PAGE_REQUEST = 'FEED_NEXT_PAGE_REQUEST';
export const FEED_NEXT_PAGE_REQUEST_PENDING = 'FEED_NEXT_PAGE_REQUEST_PENDING';
export const FEED_NEXT_PAGE_REQUEST_SUCCESS = 'FEED_NEXT_PAGE_REQUEST_SUCCESS';
export const FEED_NEXT_PAGE_REQUEST_ERROR = 'FEED_NEXT_PAGE_REQUEST_ERROR';
export const FEED_SET_FEED_HASH = 'FEED_SET_FEED_HASH';
export const FEED_CLEAR_ALL = 'FEED_CLEAR_ALL';
export const FEED_OPEN_GROUP = 'FEED_OPEN_GROUP';

export const clearAllFeeds = () => ({
  meta: {
    storeKeys: [
      FEED_TYPE_NEWS,
      FEED_TYPE_EVENT,
      FEED_TYPE_PROFILE,
      FEED_TYPE_EVENT_CATEGORIZED,
      FEED_TYPE_LOCAL,
      FEED_TYPE_EVENT_RELATED,
      FEED_TYPE_EVENT_PARTICIPATING,
      FEED_TYPE_DETAILS_PAGE,
      FEED_TYPE_GROUP,
    ],
  },
  type: FEED_CLEAR_ALL,
} as const);

export const setFeedHash = (feedType: IFeedType, hash: string) => ({
  meta: {
    feedType,
  },
  payload: hash,
  type: FEED_SET_FEED_HASH,
} as const);

export const openGroup = (feedType: IFeedType, index: number) => ({
  meta: {
    feedType,
  },
  payload: index,
  type: FEED_OPEN_GROUP,
} as const);

const isInitialFeedRequest = ({ page = 1 }: IPaginationParams = {}) => page === 1;

const createHash = (requestParams: RequestParams) => JSON.stringify(requestParams);

const feedNextPageRequest = (feedType: IFeedType, promise: Promise<IFeedDecoderResults | null>) => ({
  meta: {
    feedType,
  },
  payload: promise,
  type: FEED_NEXT_PAGE_REQUEST,
} as const);

type FeedNextPageRequest = ReturnType<typeof feedNextPageRequest>;

export type FeedNextPageRequestAction = PromiseMiddlewareActions<
  typeof FEED_NEXT_PAGE_REQUEST_PENDING,
  typeof FEED_NEXT_PAGE_REQUEST_ERROR,
  typeof FEED_NEXT_PAGE_REQUEST_SUCCESS,
FeedNextPageRequest
>;

type FetchFeedThunk = ThunkAction<Promise<void> | undefined, IRootState, {}, IAction>;

export const fetchFeed = <RP extends RequestParams>(
  feedType: IFeedType,
  get: IGet<RP>,
  canScrollUp: boolean = true,
) => {
  return (requestParams: RP): FetchFeedThunk =>
    (dispatch, getState) => {
      const state = getState();
      if (state.feed.feeds[feedType].isLoading) {
        return;
      }

      // if it is an initial feed request - check if we can use the cache of that feed
      if (isInitialFeedRequest(requestParams as IPaginationParams)) {
        const newHash = createHash(requestParams);

        if (state.feed.feeds[feedType].feedHash === newHash) {
          return;
        }

        dispatch(setFeedHash(feedType, newHash));

        if (canScrollUp) {
          window.scrollTo(0, 0);
        }
      }

      const payload = get(requestParams);

      // as long as we use promise-middleware this will return a promise
      // which we need to fetch multiple feeds in sequence like for the categorized feed and the related feed
      // sadly this can't be nicely typed 😔
      const promiseAction = dispatch(feedNextPageRequest(feedType, payload)) as unknown as Promise<void>;

      return promiseAction;
    };
};

export type FeedAction =
  FeedNextPageRequestAction
  | ReturnType<typeof clearAllFeeds>
  | ReturnType<typeof openGroup>
  | ReturnType<typeof setFeedHash>
;
