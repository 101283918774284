// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';
import MetaTags from 'src/components/meta_tags/meta_tags';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { TWO_COLUMNS } from 'src/constants/layout';
import { SETTINGS_PAGE_TITLE } from 'src/constants/meta_tags';
import { textResources } from 'src/lang/de';

// helpers
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';

// webpack magic comment inside dynamic import to name outputed chunk see:
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
const Settings = React.lazy(() =>
  import(/* webpackChunkName: "settings-page" */ 'src/containers/settings_page'));

const SettingsPage: React.FC<RouteComponentProps> = () => {
  const user = useSelectUser();

  usePageTracking({ user });

  return (
    <>
      <MetaTags
        title={SETTINGS_PAGE_TITLE}
      />
      <LayoutNoMap
        contentWidth={TWO_COLUMNS}
        pageTitle={<StandardPageTitle text={textResources.pageTitles.settings} />}
      >
        <React.Suspense fallback={<LoadingSpinner shown />}>
          <Settings />
        </React.Suspense>
      </LayoutNoMap>
    </>
  );
};

export default SettingsPage;
