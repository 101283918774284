import { IImageUrls } from 'src/api/interfaces/responses/post_ingredients';
import { ReactionName } from 'src/api/interfaces/responses/reaction';
import { IResourceLink } from 'src/api/interfaces/responses/resource_link';
import { IIdentifiable, IPagination } from 'src/api/interfaces/responses/shared';
import {
  BOOKMARK,
  COMMENT,
  EVENT,
  EVENT_PARTICIPATION,
  FOLLOW,
  FOLLOWEE_EVENT,
  FOLLOWEE_POST,
  GROUP_ACCESS_CONFIRMATION,
  GROUP_POST,
  MESSAGE,
  POST,
  RECOMMENT,
} from 'src/constants/notifications';
import {
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  REPORTER_PROFILE_TYPE,
} from 'src/constants/profile';
import { FollowEventCodes } from 'src/interfaces/notification';

export interface INotificationsResponse {
  data: Notification[];
  meta: IPagination;
}

/******************************************************************************
 * shared/common notification interfaces
 */
export type Notification =
    IBookmarkNotification
    | ICommentNotification
    // | IFlagNotification                    // TODO: NYI by frontend
    // | IGroupAccessConfirmationNotification // TODO: NYI by frontend
    | IMessageNotification
    | IRecommentNotification
    // | IViewCountNotification               // TODO: NYI by frontend
    // we use this interface to handle all unknown notification event codes
    | IUnknownNotification
  ;

export type NotificationEventCode =
    typeof BOOKMARK
    | typeof COMMENT
    // | 'flag'                      // TODO: NYI by frontend
    // | 'group_access_confirmation' // TODO: NYI by frontend
    | typeof MESSAGE
    | typeof GROUP_POST
    | typeof RECOMMENT
    // | 'view_count'                // TODO: NYI by frontend
    | typeof FOLLOW
    | typeof FOLLOWEE_POST
    | typeof FOLLOWEE_EVENT
    | typeof EVENT_PARTICIPATION
    | typeof GROUP_ACCESS_CONFIRMATION
    // handle unknown notification event codes which the API may send
    | string
  ;

export type NotificationEvent =
    IBookmarkNotificationEvent
    | ICommentNotificationEvent
    // | IFlagNotificationEvent                    // TODO: NYI by frontend
    // | IGroupAccessConfirmationNotificationEvent // TODO: NYI by frontend
    | IMessageNotificationEvent
    | IEventParticipationNotificationEvent
    | IRecommentNotificationEvent
    // | IViewCountNotificationEvent               // TODO: NYI by frontend
    | IUnknownNotificationEvent
    | IGroupAccessConfirmationNotificationEvent
  ;

export interface INotificationEvent {
  code: NotificationEventCode;
}

export interface INotificationContext extends IIdentifiable {
  teaser: string | null;
}

export interface INotificationInitiator extends IIdentifiable {
  extension_identifier: string;
  image: IImageUrls;
  name: string | null;
}

export interface INotificationMeta {
  created_at: string;
  read_at: string | null;
}

export interface INotification extends IIdentifiable {
  body: string | null;
  context: INotificationContext | null;
  event: NotificationEvent;
  initiator: INotificationInitiator | null;
  meta: INotificationMeta;
  resource_link: IResourceLink | null;
  subject: string | null;
  type: 'notification';
}

/******************************************************************************
 * a "bookmark" notification
 */
export interface IBookmarkNotificationEvent extends INotificationEvent {
  bookmark_type: ReactionName;
  bookmarkable: IBookmarkable;
  code: typeof BOOKMARK;
}

export type BookmarkableType =
  typeof AUTHORITY_PROFILE_TYPE
  | typeof BLOG_PROFILE_TYPE
  | typeof COMMENT
  | typeof EVENT
  | typeof POST
  | typeof PRESS_PROFILE_TYPE
  | typeof REPORTER_PROFILE_TYPE;

interface IBookmarkable extends IIdentifiable {
  type: BookmarkableType;
}

export interface IBookmarkNotification extends INotification {
  event: IBookmarkNotificationEvent;
}

export const isBookmarkNotification = (notification: INotification): notification is IBookmarkNotification => {
  return notification.event.code === BOOKMARK;
};

/******************************************************************************
 * a "comment" notification
 */

export interface ICommentNotificationEvent extends INotificationEvent {
  code: typeof COMMENT;
}

export interface ICommentNotification extends INotification {
  context: ICommentNotificationContext;
  event: ICommentNotificationEvent;
}

interface ICommentNotificationContext extends INotificationContext {
  type: CommentableType;
}

export type CommentableType = 'event' | 'post';

export const isCommentNotification = (notification: INotification): notification is ICommentNotification => {
  return notification.event.code === COMMENT;
};

/******************************************************************************
 * a "recomment" notification
 */

export interface IRecommentNotificationEvent extends INotificationEvent {
  code: typeof RECOMMENT;
}

export interface IRecommentNotification extends INotification {
  context: ICommentNotificationContext;
  event: IRecommentNotificationEvent;
}

export const isRecommentNotification = (notification: INotification): notification is IRecommentNotification => {
  return notification.event.code === RECOMMENT;
};

/******************************************************************************
 * a "message" notification
 */
export interface IMessageNotificationEvent extends INotificationEvent {
  code: typeof MESSAGE;
}

export interface IMessageNotification extends INotification {
  event: IMessageNotificationEvent;
}

export const isMessageNotification = (notification: INotification): notification is IMessageNotification => {
  return notification.event.code === MESSAGE;
};

/******************************************************************************
 * "follow" notifications
 */
export interface IFollowNotificationEvent extends INotificationEvent {
  code: FollowEventCodes;
}

export interface IFollowNotification extends INotification {
  event: IFollowNotificationEvent;
}

const followNotificationEventCodes = [FOLLOW, FOLLOWEE_POST, FOLLOWEE_EVENT];

export const isFollowNotification = (notification: INotification): notification is IFollowNotification => {
  return followNotificationEventCodes.indexOf(notification.event.code) > -1;
};

/******************************************************************************
 * a "group" notification
 */
export interface IGroupNotificationEvent extends INotificationEvent {
  code: typeof GROUP_POST;
}

export interface IGroupNotification extends INotification {
  event: IGroupNotificationEvent;
  context: INotificationContext & {
    context: {
      teaser: string;
    };
  };
}

/******************************************************************************
 * a "group access confirmation" notification
 */

export const isGroupAccessConfirmationNotification =
  (notification: INotification): notification is IGroupAccessConfirmationNotification => {
    return notification.event.code === GROUP_ACCESS_CONFIRMATION;
  };

export interface IGroupAccessConfirmationNotificationEvent extends INotificationEvent {
  code: typeof GROUP_ACCESS_CONFIRMATION;
}

export interface IGroupAccessConfirmationNotification extends INotification {
  event: IGroupAccessConfirmationNotificationEvent;
}

export const isGroupNotification = (notification: INotification): notification is IGroupNotification => {
  return notification.event.code === GROUP_POST;
};

/******************************************************************************
 * a "event_participation" notification
 */
export interface IEventParticipationNotificationEvent extends INotificationEvent {
  code: typeof EVENT_PARTICIPATION;
}

export interface IEventParticipationNotification extends INotification {
  event: IEventParticipationNotificationEvent;
  context: INotificationContext & {
    context: {
      teaser: string;
    };
  };
}

export const isEventParticipationNotification =
  (notification: INotification): notification is IEventParticipationNotification => {
    return notification.event.code === EVENT_PARTICIPATION;
  };
/******************************************************************************
 * to handle unknown notifications
 */
export interface IUnknownNotificationEvent extends INotificationEvent {
  code: string;
}

export interface IUnknownNotification extends INotification {
  event: IUnknownNotificationEvent;
}

/******************************************************************************
 * notification unread counts related
 */

export type NotificationsUnreadCountsResponseKey = NotificationEventCode | 'total';

export type INotificationsUnseenCountsRecord = {
  readonly[K in NotificationsUnreadCountsResponseKey]?: number;
};

export interface INotificationsUnreadCountsData {
  [profileIdentifier: string]: INotificationsUnseenCountsRecord;
}

export interface INotificationsUnseenCountsResponse {
  data: INotificationsUnreadCountsData;
}
