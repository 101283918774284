import { IWindowJsEnv } from 'src/interfaces/';
import { textResources } from 'src/lang/de';
import { getConfigValue } from 'src/utils/config/config';
import { getPageHeaderTitle } from 'src/utils/page_title/page_title';

const _window = window as unknown as IWindowJsEnv;
const { defaultTag, descriptions, titles } = textResources.metatags;

/*
 DEFAULT
*/
export const DEFAULT_TITLE = defaultTag.title;
export const DEFAULT_DESCRIPTION = defaultTag.description;
export const DEFAULT_AUTHOR = defaultTag.author;
export const DEFAULT_COPYRIGHT = defaultTag.copyright;
export const DEFAULT_EMAIL = defaultTag.email;
export const DEFAULT_FB_PAGE_ID = '622869931148166';
export const DEFAULT_OG_IMAGE = _window.js_env.absoluteImagePaths.defaultOgImage;
export const DEFAULT_OG_LOCALE = 'de_DE';
export const DEFAULT_OG_SITE_NAME = defaultTag.siteName;
export const DEFAULT_OG_TYPE = 'website';
export const DEFAULT_OG_URL = getConfigValue('staticPages.urls.Homepage');
export const DEFAULT_PRERENDER_STATUS_CODE = '200';
export const DEFAULT_ROBOTS = 'index, follow';
export const NOINDEX_FOLLOW_ROBOTS = 'noindex, follow';
export const NOINDEX_NOFOLLOW_ROBOTS = 'noindex, nofollow';

/*
 FEEDS
*/

/* Newsfeed */
export const NEWSFEED_DESCRIPTION_FIRST_PAGE = descriptions.newsfeedFirstPage;
export const NEWSFEED_DESCRIPTION_ADDITIONAL_PAGE = descriptions.newsfeedAdditionalPage;
export const NEWSFEED_OG_IMAGE = _window.js_env.absoluteImagePaths.postsOgImage;
export const NEWSFEED_TITLE_FIRST_PAGE = (locationName: string) =>
  getPageHeaderTitle(titles.newsfeedFirstPage, titles.newsfeedFirstPageIn, locationName);
export const NEWSFEED_TITLE_ADDITIONAL_PAGE = (locationName: string, page: number, totalPages: number) =>
  getPageHeaderTitle(titles.newsfeedAdditionalPage, titles.newsfeedAdditionalPageIn, locationName, page, totalPages);

/* Events */
export const EVENTSFEED_DESCRIPTION = (locationName: string) => descriptions.eventsfeed(locationName);
export const EVENTSFEED_OG_IMAGE = _window.js_env.absoluteImagePaths.eventsOgImage;
export const EVENTSFEED_TITLE = (locationName: string) => titles.eventsfeed(locationName);

export const EVENTSFEED_CATEGORY_TITLE = (locationName: string, category: string) =>
  titles.categorizedEventsfeed(locationName, category);

export const EVENT_PARTICIPATING_TITLE = titles.eventParticipating;

export const EVENT_PARTICIPATIONS_TITLE = titles.eventParticipations;

/* Groups */
export const GROUP_OVERVIEW_TITLE = (locationName: string) => titles.groupOverview(locationName);
export const GROUP_OVERVIEW_DESCRIPTION = descriptions.groupOverview;
export const GROUP_FEED_TITLE = (groupName: string) => titles.groupFeed(groupName);
export const GROUP_MEMBERS_TITLE = (groupName: string) => titles.groupMembers(groupName);

/* Profiles list */
export const PROFILES_DIRECTORY_TITLE = (locationName: string) =>
  getPageHeaderTitle(titles.profilesDirectory, titles.profilesDirectoryIn, locationName);
export const PROFILES_DIRECTORY_DESCRIPTION = descriptions.profilesDirectory;

export const PROFILES_DIRECTORY_CATEGORY_TITLE_AUTHORITY_PROFILES = (locationShape: string) =>
  getPageHeaderTitle(
    titles.profilesDirectoryCategoryAuthorityProfiles,
    titles.profilesDirectoryCategoryAuthorityProfilesIn,
    locationShape
  );
export const PROFILES_DIRECTORY_CATEGORY_TITLE_PRESS_PROFILES = (locationShape: string) =>
  getPageHeaderTitle(
    titles.profilesDirectoryCategoryPressProfiles,
    titles.profilesDirectoryCategoryPressProfilesIn,
    locationShape
  );
export const PROFILES_DIRECTORY_CATEGORY_TITLE_PRIVATE_PROFILES = (locationShape: string) =>
  getPageHeaderTitle(
    titles.profilesDirectoryCategoryPrivateProfiles,
    titles.profilesDirectoryCategoryPrivateProfilesIn,
    locationShape
  );
export const PROFILES_DIRECTORY_CATEGORY_DESCRIPTION_AUTHORITY_PROFILES =
  descriptions.profilesDirectoryCategoryAuthorityProfiles;
export const PROFILES_DIRECTORY_CATEGORY_DESCRIPTION_PRESS_PROFILES =
  descriptions.profilesDirectoryCategoryPressProfiles;
export const PROFILES_DIRECTORY_CATEGORY_DESCRIPTION_PRIVATE_PROFILES =
  descriptions.profilesDirectoryCategoryPrivateProfiles;

/*
 OTHERS
*/

/* Details page */
export const DETAILS_PAGE_TITLE = (title: string) => titles.detailsPage(title);

/* Post */
export const POST_ARTICLE_PUBLISHER = 'https://www.facebook.com/lokalportal';
export const POST_DEFAULT_DESCRIPTION = DEFAULT_DESCRIPTION;
export const POST_OG_TYPE = 'article';

/* Profiles */
export const PROFILE_OG_TYPE = 'profile';
export const PROFILE_TITLE = (name: string) => titles.profile(name);
export const PROFILE_PRIVATE_TITLE = titles.privateProfile;

/* Profile followers */
export const PROFILE_FOLLOWERS_TITLE = titles.profileFollowers;

/* shape sponsors */
export const SHAPE_SPONSORS_TITLE = titles.shapeSponsors;
export const SHAPE_SPONSORS_DESCRIPTION = descriptions.shapeSponsors;

/* Pages with no description */
export const SETTINGS_PAGE_TITLE = titles.settings;
export const MAILBOX_PAGE_TITLE = titles.mailbox;
export const NOTIFICATIONS_TITLE = titles.notifications;
export const CREATE_PROFILE_TITLE = titles.createProfile;
export const LOGIN_TITLE = titles.login;
export const NOT_FOUND_TITLE = titles.notFound;
export const REGISTRATION_TITLE = titles.registration;
export const RESET_PASSWORD_TITLE = titles.resetPassword;
export const TOPIC_CHAT_TITLE = titles.topicChat;

export const PAYWALL_CLASS = '.lp_premium_content';
