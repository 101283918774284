// libs
import * as React from 'react';

// interfaces / constants
import { ICommentNotification } from 'src/interfaces/notification';

// classes / components
import Notification, { clsHighlightedText, clsText } from 'src/components/notifications/notification';

// helpers
import Translate, { textResources } from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';

// images
import * as overlayInactive from 'src/components/notifications/badges/Comment@2x.png';
import * as overlayActive from 'src/components/notifications/badges/CommentActive@2x.png';
import { RECOMMENT } from 'src/constants/notifications';

const labels = textResources.notification;

export interface IProps extends ICommentNotification {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const CommentNotification: React.FC<IProps> = ({
  body,
  commentableType,
  contextTeaser,
  createdAt,
  eventCode,
  initiator,
  isRead,
  onClick,
}) => {
  const commentable = Translate.notifications.commentCommentableType[commentableType];

  return (
    <Notification
      avatarImage={initiator?.image}
      badgeImageRead={overlayInactive}
      badgeImageNotRead={overlayActive}
      footer={DateFormatter.asTimeAgo(createdAt)}
      initiatorName={initiator?.name}
      isRead={isRead}
      onClick={onClick}
    >
      {eventCode === RECOMMENT ? <span className={clsText}>{body}</span>
        : <>
      <span className={clsText} >{` ${labels.notificationComment} ${commentable}`}</span>
      {contextTeaser && <strong className={clsHighlightedText} > „{contextTeaser}“</strong>}
      <span className={clsText} >{` ${labels.notificationCommented}`}</span>
      </>}
    </Notification>
  );
};

export default CommentNotification;
