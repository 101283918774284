// libs
import React, { useEffect, useMemo, useCallback } from 'react';

// interfaces / constants
import { FEED_TYPE_NEWS } from 'src/constants/feed';

// classes / components
import MasonryFeed from 'src/components/feed/layouts/masonry_feed/masonry_feed';
import PostCreateBanner from 'src/components/post/create/create_banner';
import FeedWrapper from 'src/containers/smart_components/list_wrapper/feed_wrapper';

// helpers
import { useHistory } from 'react-router-dom';
import { useMarkersInViewport, useOpenGroup } from 'src/actions/feed/feed_hooks';
import {
  useFetchNewsFeedNextPage,
  useFetchNewsFeedPage,
} from 'src/actions/feed/news_feed/news_feed_hooks';
import { PaginationFooter } from 'src/components/newsfeed/pagination_footer';
import { ShowNewPostsButton } from 'src/components/newsfeed/show_new_posts_button';
import PostCreate from 'src/containers/post/create';
import NewsFeedHighlights from 'src/containers/smart_components/news_feed_highlights/news_feed_highlights';
import ShapeSponsorsInfo from 'src/containers/smart_components/shape_sponsors/shape_sponsors_info/shape_sponsors_info';
import { textResources } from 'src/lang/de';
import { useGetBoundingBox } from 'src/reducers/app-state/app-state_hooks';
import {
  useSelectFeedGroups,
  useSelectFeedIds,
  useSelectFeedIsLoading,
  useSelectPosts,
  useSelectFeedHash,
} from 'src/reducers/feed/feed_hooks';
import {
  useSelectIsUserLoggedIn,
  useSelectPrivateTopicUrl,
  useSelectProfilePermissions,
} from 'src/reducers/user/user_hooks';

interface IProps {
  initialPage?: number;
  isPostCreateOpen?: boolean;
}

const labels = textResources.postCreate;

const useGetRenderPostCreate = (isPostCreateOpen: boolean) => {
  const isLoggedIn = useSelectIsUserLoggedIn();
  const canCreatePost = useSelectProfilePermissions()?.createPosts;
  const url = useSelectPrivateTopicUrl();

  return useCallback((): JSX.Element => {
    return (<React.Fragment key='postCreate'>
      <PostCreateBanner
        callToActionText={labels.createPostTitle}
        callToActionSubtitle={labels.createPostSubtitle}
        featureBlocked={!canCreatePost}
        tileSize
        forceWizard
      />
      <PostCreate
        open={canCreatePost && isPostCreateOpen}
        showsBanner={!isLoggedIn}
        featureBlocked={!canCreatePost}
        topicPostPath={url}
      />
    </React.Fragment>);
  }, [canCreatePost, isPostCreateOpen, url, isLoggedIn]);
};

const NewsFeed: React.FC<IProps> = ({ isPostCreateOpen = false, initialPage }) => {
  const boundingBox = useGetBoundingBox();
  const feedHash = useSelectFeedHash(FEED_TYPE_NEWS);
  const posts = useSelectPosts();
  const ids = useSelectFeedIds(FEED_TYPE_NEWS);
  const groups = useSelectFeedGroups(FEED_TYPE_NEWS);
  const isLoading = useSelectFeedIsLoading(FEED_TYPE_NEWS);

  const openGroup = useOpenGroup(FEED_TYPE_NEWS);
  const getFirstPage = useFetchNewsFeedPage();
  const getNextPage = useFetchNewsFeedNextPage();
  const renderPostCreate = useGetRenderPostCreate(isPostCreateOpen);
  useMarkersInViewport(ids, posts);

  const history = useHistory();

  useEffect(() => {
    getFirstPage(initialPage);
  }, [boundingBox, getFirstPage, feedHash, initialPage]);

  const extraElements = useMemo(() => [
    <ShapeSponsorsInfo key='shape_sponsors_info' />,
    <NewsFeedHighlights key='newsfeed_highlights' />,
  ], []);

  const resetFeed = useCallback(() => history.push('/kiel-ravensberg/aktuelles'), [history]);

  return (
    <>
      {(initialPage && initialPage > 1)
        ? <ShowNewPostsButton onClick={resetFeed} />
        : null}
      <FeedWrapper
        getNextPage={getNextPage}
        feedType={FEED_TYPE_NEWS}
      >
        <MasonryFeed
          groups={groups}
          posts={posts}
          renderPostCreate={renderPostCreate}
          isLoading={isLoading}
          openGroup={openGroup}
          extraElements={extraElements}
        />
      </FeedWrapper>
      <PaginationFooter
        feedType={FEED_TYPE_NEWS}
      />
    </>
  );
};

NewsFeed.displayName = 'NewsFeed';

export default NewsFeed;
