// libs
import * as React from 'react';
import { connect } from 'react-redux';

// components

import { MSGBOX_BUTTON_TYPE_PRIMARY } from 'src/components/message_box/message_box';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';

// interfaces / types
import { API } from 'src/constants/api';
import { ILopoCore } from 'src/interfaces/index';
import { IRootState } from 'src/reducers/interface';

// helpers
import { textResources } from 'src/lang/de';
import GoogleTagManager from 'src/utils/reporting/google-tag-manager';
import { UrlUtils } from 'src/utils/url/url';

const labels = textResources.reloadPage;

const reloadPage = () => {
  const lopoCore = (window as unknown as ILopoCore).lopo_core;

  GoogleTagManager.pushEventWithValuePromise(API, 'api_version_is_updated_and_reloaded_page',
    `
      'currentPath': ${UrlUtils.getCurrentUrlPath()},
      'currentRevision': ${lopoCore && lopoCore.ver},
      'oldRevision': ${lopoCore && lopoCore.oldVer},
    `,
  )
    .then(() => window.location.reload(true))
    .catch(() => window.location.reload(true));
};

class ReloadPageMessage extends React.PureComponent<IReloadPageProps> {
  public render() {
    const { isCacheOutdated } = this.props;
    return (
      <div>
        {isCacheOutdated &&
            <MessageBoxWithModal
              buttons={[
                {
                  label: labels.reload,
                  onClick: reloadPage,
                  type: MSGBOX_BUTTON_TYPE_PRIMARY,
                },
              ]}
              title={textResources.shared.newVersion}
            >
              <p>
                {labels.message}
              </p>
            </MessageBoxWithModal>
        }
      </div>
    );
  }
}

interface IMapStateToProps {
  isCacheOutdated: boolean;
}

type IReloadPageProps = IMapStateToProps;

const mapStateToProps = ({ appState }: IRootState): IMapStateToProps => ({
  isCacheOutdated: appState.isCacheOutdated,
});

export default connect(mapStateToProps)(ReloadPageMessage);
