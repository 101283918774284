// libs
import { useSelector } from 'react-redux';

// interfaces
import { IRootState } from 'src/reducers/interface';

// helpers
import { IProfileNotificationDataMap } from 'src/reducers/notifications/notifications';

export const useSelectProfileNotifications = () =>
  useSelector<IRootState, IProfileNotificationDataMap>(({ notifications }) => notifications.forProfile);
