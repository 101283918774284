// libs
import * as React from 'react';
import * as showdown from 'showdown';

// interfaces / constants
import { IBBCode, IBBCodes, IBodyMarkdown, IIngredient } from 'src/interfaces/post_ingredients';
import { mapDisplayModeToConst, POST_DISPLAY_MODE_DETAIL } from 'src/interfaces/posts';

// constants
import { CLASS_PREFIX } from 'src/constants/';

// classes
import BlurredContent from 'src/components/blurred_content/blurred_content';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// styles
import './body_markdown.scss';

export type IProps = IIngredient<IBodyMarkdown>;

export const cls = `${CLASS_PREFIX}body-markdown__`;

const escapeReplaceMap: { [key: string]: string } = {
  '&#10;': '\n',
  '&gt;': '>',
};
const escapeFilterEx = new RegExp(Object.keys(escapeReplaceMap).join('|'), 'g');
const showdownOptions: showdown.ConverterOptions = {
  noHeaderId: true,
  openLinksInNewWindow: true,
  simplifiedAutoLink: true,
  tables: true,
};

const showdownConverter = new showdown.Converter(showdownOptions);

export default class BodyMarkdown extends React.PureComponent<IProps, {}> {
  private bodyMarkdown: React.RefObject<HTMLDivElement> = React.createRef();
  private setIframeHeightFromItsContent: (event: MessageEvent) => void;
  private cls = '';
  private clsIframe = '';

  constructor(props: IProps) {
    super(props);
    this.cls = `${cls}${mapDisplayModeToConst[props.displayMode]}`;
    this.clsIframe = `${this.cls}__iframe`;
  }

  public render() {
    const {
      blurred,
      data: {
        bbcodes,
        fullContent,
        teaserContent,
      },
      displayMode,
      postDetailsURL,
    } = this.props;

    const htmlContent: string = displayMode === POST_DISPLAY_MODE_DETAIL
      ? this.parseContent(fullContent, bbcodes)
      : teaserContent;
    const bodyElement = <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;

    return (
      <NavigationItem target='_self' url={postDetailsURL} >
        <div className={this.cls} ref={this.bodyMarkdown}>
          {blurred ? (
            <BlurredContent blurred={blurred}>
              {bodyElement}
            </BlurredContent>
          ) : bodyElement}
        </div>
      </NavigationItem>
    );
  }

  public componentWillUnmount() {
    window.removeEventListener('message', this.setIframeHeightFromItsContent, false);
  }

  private parseContent(content: string, bbcodes: IBBCodes): string {
    const afterEscapeParse: string = this.parseEscapes(content);
    return this.parseMarkdownWithBBCodes(afterEscapeParse, bbcodes);
  }

  private parseEscapes(content: string): string {
    return content.replace(escapeFilterEx, (match: string): string => escapeReplaceMap[match]);
  }

  private parseMarkdownWithBBCodes(content: string, bbcodes: IBBCodes): string {
    const converted: string = showdownConverter.makeHtml(content);
    if (!bbcodes) {
      return converted;
    }

    return converted.replace(/{{[\w\d_-]+}}/g, (match: string) => this.replaceWithBBCodes(match, bbcodes));
  }

  private replaceWithBBCodes(match: string, bbcodes: IBBCodes): string {
    if (!bbcodes.hasOwnProperty(match)) {
      return match;
    }

    const bbCode: IBBCode = bbcodes[match];

    if (bbCode.errors) {
      return bbCode.errors.join(', ');
    }
    if (!bbCode.data || !bbCode.id) {
      return 'no data';
    }

    const sandboxUrl: string = this.getSandboxUrl(bbCode);
    const sandBox = `<iframe id='${bbCode.id}'
                             class='${this.clsIframe}'
                             scrolling='no'
                             src='${sandboxUrl}'>
                      </iframe>`;
    this.listenToSandboxHeight(bbCode);
    return sandBox;
  }

  private getSandboxUrl(bbObject: IBBCode): string {
    // we check it again just for typescript to be happy.
    if (!bbObject.data) {
      return '';
    }
    switch (bbObject.type) {
      case 'html':
        return bbObject.data.urls.resource;
      case 'facebook':
        return `/static/facbook-embed.html#${bbObject.data.url}`;
      case 'instagram':
        return `/static/instagram-embed.html#${bbObject.data.url}`;
      default:
        return 'not a recognized type';
    }
  }

  private listenToSandboxHeight(bbCode: IBBCode) {
    this.setIframeHeightFromItsContent = (event: MessageEvent) => {
      const height = event.data.height;
      const sandbox = document.getElementById(bbCode.id);
      if (
        isNaN(height) ||
        !sandbox ||
        bbCode.id !== event.data.id
      ) {
        // TODO - security alert
        return;
      }
      sandbox.setAttribute('height', height);
    };

    window.addEventListener('message', this.setIframeHeightFromItsContent, false);
  }
}
