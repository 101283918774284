// interfaces / constants
import { IAction } from 'src/actions/';
import api from 'src/api/index';
import { IFlagCreateData } from 'src/interfaces/flag';
import { IPost } from 'src/interfaces/posts';

const FLAG_CREATE_REQUEST = 'FLAG_CREATE_REQUEST';

export const createFlag = (flaggable: IPost, data: IFlagCreateData): IAction => ({
  payload: api.flagPost.create(flaggable.meta.urls.flag, data),
  type: FLAG_CREATE_REQUEST,
});
