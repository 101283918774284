import { IAttachmentUpdateAttribute } from 'src/api/interfaces/requests';
import { IAttachment, IImageObject } from 'src/interfaces/attachment';

const includes = (items: Array<string | undefined>, item: string): boolean => {
  return items.indexOf(item) >= 0;
};

export const toAttachmentsUpdate =
  (
    addedImgs?: IImageObject[],
    deletedImgIds?: string[],
    changedImages?: IImageObject[],
  ): IAttachmentUpdateAttribute[] | undefined => {
    let attachments: IAttachmentUpdateAttribute[] = [];

    if (addedImgs) {
      attachments = attachments.concat(addedImgs.map(({ src }) => ({ file: src })));
    }

    if (deletedImgIds) {
      attachments = attachments.concat(deletedImgIds.map((imageId: string) => {
        return {
          _destroy: true,
          id: imageId,
        };
      }));
    }

    if (changedImages) {
      attachments = attachments.concat(changedImages.map(({ id, src }) => ({ file: src, id })));
    }

    return attachments;
  };

export const getUpdatedImages = (prevAttachments: IAttachment[], updatedImages: IImageObject[]) => {
  const newIds = updatedImages.map(({ id }) => id).filter((id) => id !== undefined);

  const deletedImagesIds = prevAttachments.map(({ id }) => id).filter((id) => !includes(newIds, id));

  const addedImages = updatedImages.filter((image) => image.id === undefined);

  const prevImagesMap: {[key: string]: string } =
    prevAttachments.reduce((acc, { id, imageUrls }) => id ? { ...acc, [id]: imageUrls.original } : acc, {});

  const updatedImagesMap: {[key: string]: string } =
    updatedImages.reduce((acc, { id, src }) => id ? { ...acc, [id]: src } : acc, {});

  const changedImages =
    newIds.filter((id: string) => prevImagesMap[id] !== updatedImagesMap[id])
      .map((id: string) => ({ id, src: updatedImagesMap[id] }));

  return { addedImages, changedImages, deletedImagesIds };
};

export const getUpdatedCommentImages = (prevAttachments: IAttachment[], updatedImages: string[]) => {
  const prevImages = prevAttachments.map((attchment) => ({ file: attchment.imageUrls.original, id: attchment.id }));
  const prevImageFiles = prevAttachments.map((attchment) => (attchment.imageUrls.original));

  const deleted = prevImages.filter((attchment) => !includes(updatedImages, attchment.file));
  const deletedImagesIds: string[] = deleted.map((image) => image.id);
  const addedImages: string[] = updatedImages.filter((image) => !includes(prevImageFiles, image));
  return { addedImages, deletedImagesIds };
};

export const attachmentToImage = ({ id, imageUrls }: IAttachment): IImageObject => ({ id, src: imageUrls.original });

export const imageToString = ({ src }: IImageObject): string => src;

export const stringToImage = (src: string): IImageObject => ({ src });

export const urlToDataURL = (url: string) => {
  return new Promise<string>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  });
};
