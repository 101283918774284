// libs
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

// interfaces
import { IGetProfilesParams } from 'src/api';
import { Id } from 'src/interfaces';
import { IBoundingBox } from 'src/interfaces/location';
import { ItemType } from 'src/interfaces/posts';
import { ProfilesListProfileType } from 'src/interfaces/profile';
import { ProfilesListURI } from 'src/interfaces/profiles_list';

// helpers
import {
  clean,
  getProfilesList,
  getProfilesListCategorized, setHash, setSecondaryHash,
  getFollowersList,
  getReactedProfiles,
  getFolloweesList,
} from 'src/actions/profiles_list/profiles_list';
import { useProfilesList, useProfilesSecondaryList } from 'src/reducers/profiles_list/profiles_list_hooks';
import { UrlUtils } from 'src/utils/url/url';

export const useCleanProfilesListEffect = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      // we want to cleanup after component unmount
      dispatch(clean());
    };
  }, [dispatch]);
};

export const useAuthorTypeFromURL = (): ProfilesListProfileType | undefined => {
  const { category } = useParams();

  if (!category) {
    return;
  }

  return UrlUtils.mapUrlParamToAuthorType(category as ProfilesListURI);
};

const getProfilesHash = ({
  boundingBox,
  query,
  authorType,
  orderBy,
  orderDirection,
}: IGetProfilesParams) =>
  `#PROFILES#${JSON.stringify(boundingBox)}#${query}#${authorType}#${orderBy}#${orderDirection}`;

const getCategorizedProfilesHash = (boundingBox?: IBoundingBox) =>
  `#CATEGORIZED_PROFILES#${JSON.stringify(boundingBox)}`;

export const useGetProfiles = () => {
  const dispatch = useDispatch();
  const { hash } = useProfilesSecondaryList();
  const latestHash = useRef(hash);

  useEffect(() => {
    latestHash.current = hash;
  });

  return useCallback(
    (params: IGetProfilesParams) => {
      const page = params.page || 1;
      const newHash = getProfilesHash(params);
      const isHashChanged = latestHash.current !== newHash;

      if (isHashChanged) {
        dispatch(setSecondaryHash(newHash));
      }

      if (!params.boundingBox || (page === 1 && !isHashChanged)) {
        return;
      }

      dispatch(getProfilesList(params));
    },
    [dispatch],
  );
};

export const useGetProfilesNextPage = ({
  authorType,
  boundingBox,
  query,
  orderBy,
  orderDirection,
}: Omit<IGetProfilesParams, 'page'> = {}) => {
  const getProfiles = useGetProfiles();
  const { pagination: { currentPage, lastPage }, isLoading } = useProfilesSecondaryList();

  return useCallback(
    () => {
      if (isLoading || lastPage) {
        return;
      }

      getProfiles({
        authorType,
        boundingBox,
        orderBy,
        orderDirection,
        page: currentPage + 1,
        query,
      });
    },
    [authorType, boundingBox, currentPage, getProfiles, isLoading, lastPage, orderBy, orderDirection, query],
  );
};

export const useGetCategorizedProfiles = () => {
  const dispatch = useDispatch();
  const { hash } = useProfilesList();
  const latestHash = useRef(hash);

  useEffect(() => {
    latestHash.current = hash;
  });

  return useCallback(
    (boundingBox?: IBoundingBox) => {
      const newHash = getCategorizedProfilesHash(boundingBox);
      const isHashChanged = latestHash.current !== newHash;

      if (isHashChanged) {
        dispatch(setHash(newHash));
      }

      if (!boundingBox || !isHashChanged) {
        return Promise.resolve();
      }

      const action = getProfilesListCategorized({ boundingBox });
      dispatch(action);

      return action.payload;
    },
    [dispatch],
  );
};

export const useProfileFirstPages = (params: IGetProfilesParams) => {
  const getProfiles = useGetProfiles();
  const getCategorizedProfiles = useGetCategorizedProfiles();

  useEffect(() => {
    getCategorizedProfiles(params.boundingBox)
      .then(() => getProfiles(params));
  }, [getCategorizedProfiles, getProfiles, params]);
};

export const useGetFollowersNextPage = () => {
  const dispatch = useDispatch();
  const { pagination: { currentPage, lastPage } } = useProfilesSecondaryList();

  return useCallback(
    () => {
      if (lastPage) {
        return;
      }

      dispatch(getFollowersList(currentPage + 1));
    },
    [lastPage, dispatch, currentPage],
  );
};

export const useGetFolloweesListNextPage = () => {
  const dispatch = useDispatch();
  const { pagination: { currentPage, lastPage } } = useProfilesSecondaryList();

  return useCallback(
    () => {
      if (lastPage) {
        return;
      }

      dispatch(getFolloweesList(currentPage + 1));
    },
    [lastPage, dispatch, currentPage],
  );
};

export const useGetReactedProfiles = () => {
  const dispatch = useDispatch();
  const { hash } = useProfilesSecondaryList();
  const latestHash = useRef(hash);

  useEffect(() => {
    latestHash.current = hash;
  });

  return useCallback(
    (itemType: ItemType, postId: Id) => {
      const newHash = 'reacted-profiles' + itemType + postId;
      const isHashChanged = latestHash.current !== newHash;

      if (!isHashChanged) {
        return;
      }

      dispatch(setSecondaryHash(newHash));
      dispatch(getReactedProfiles(itemType, postId));
    },
    [dispatch],
  );
};
