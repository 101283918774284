// libs
import * as React from 'react';
import { Helmet } from 'react-helmet';

// constants
import {
  DEFAULT_COPYRIGHT,
  DEFAULT_FB_PAGE_ID,
  DEFAULT_OG_LOCALE,
  DEFAULT_EMAIL,
  DEFAULT_OG_SITE_NAME,
  DEFAULT_OG_TYPE,
  DEFAULT_PRERENDER_STATUS_CODE,
  DEFAULT_AUTHOR,
  DEFAULT_ROBOTS,
  DEFAULT_OG_IMAGE,
  DEFAULT_OG_URL,
  DEFAULT_DESCRIPTION,
  DEFAULT_TITLE,
} from 'src/constants/meta_tags';

// helpers
import { UrlUtils } from 'src/utils/url/url';

interface IProps {
  article_modified_time?: string;
  article_published_time?: string;
  article_publisher?: string;
  author?: string;
  canonical?: string;
  copyright?: string;
  date?: string;
  description?: string;
  email?: string;
  first_page?: string;
  geo_placename?: string;
  geo_position?: string;
  json_ld?: string;
  last_modified?: string;
  last_page?: string;
  next_page?: string;
  og_description?: string;
  og_image?: string;
  og_latitude?: string;
  og_longitude?: string;
  og_site_name?: string;
  og_title?: string;
  og_type?: string;
  og_url?: string;
  prerender_status_code?: string;
  prev_page?: string;
  profile_username?: string;
  robots?: string;
  stripParamsFromCanonicalUrl?: boolean;
  title?: string;
}

const MetaTags: React.FC<IProps> = ({
  article_modified_time,
  article_published_time,
  article_publisher,
  author = DEFAULT_AUTHOR,
  canonical,
  copyright = DEFAULT_COPYRIGHT,
  date,
  description = DEFAULT_DESCRIPTION,
  email = DEFAULT_EMAIL,
  first_page,
  geo_placename,
  geo_position,
  json_ld,
  last_modified,
  last_page,
  next_page,
  og_description,
  og_image = DEFAULT_OG_IMAGE,
  og_latitude,
  og_longitude,
  og_site_name = DEFAULT_OG_SITE_NAME,
  og_title,
  og_type = DEFAULT_OG_TYPE,
  og_url = DEFAULT_OG_URL,
  prerender_status_code = DEFAULT_PRERENDER_STATUS_CODE,
  prev_page,
  profile_username,
  robots = DEFAULT_ROBOTS,
  stripParamsFromCanonicalUrl = true,
  title = DEFAULT_TITLE,
}) => {
  return (
    <Helmet>
      <title>{title}</title>
      {canonical && stripParamsFromCanonicalUrl && <link rel='canonical' href={UrlUtils.canonicalUrl(canonical)} />}
      {canonical && !stripParamsFromCanonicalUrl && <link rel='canonical' href={canonical} />}
      <meta name='description' content={description} />
      <meta property='og:title' content={og_title || title} />
      <meta property='og:type' content={og_type} />
      <meta property='og:image' content={og_image} />
      <meta property='og:url' content={og_url} />
      <meta property='og:description' content={og_description || description} />
      <meta property='og:site_name' content={og_site_name} />
      <meta property='og:locale' content={DEFAULT_OG_LOCALE} />
      <meta property='fb:page_id' content={DEFAULT_FB_PAGE_ID} />
      <meta name='author' content={author} />
      <meta name='copyright' content={copyright} />
      <meta name='email' content={email} />
      <meta name='robots' content={robots} />
      <meta name='prerender-status-code' content={prerender_status_code} />
      {og_latitude && <meta property='og:latitude' content={og_latitude} />}
      {og_longitude && <meta property='og:longitude' content={og_longitude} />}
      {date && <meta name='date' content={date} />}
      {last_modified && <meta name='last-modified' content={last_modified} />}
      {geo_placename && <meta name='geo.placename' content={geo_placename} />}
      {geo_position && <meta name='geo.position' content={geo_position} />}
      {article_published_time && <meta property='article:published_time' content={article_published_time} />}
      {article_modified_time && <meta property='article:modified_time' content={article_modified_time} />}
      {article_publisher && <meta property='article:publisher' content={article_publisher} />}
      {profile_username && <meta property='profile:username' content={profile_username} />}
      {json_ld && <script type='application/ld+json'>{json_ld}</script>}
      {first_page && <link rel="first" href={first_page} />}
      {last_page && <link rel="last" href={last_page} />}
      {next_page && <link rel="next" href={next_page} />}
      {prev_page && <link rel="prev" href={prev_page} />}
    </Helmet>
  );
};

export default MetaTags;
