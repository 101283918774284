// libs
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// interfaces
import { IProps as ISnackBarProps } from 'src/components/snack_bar/snack_bar';
import { PopupMenu } from 'src/interfaces/app-state';
import { IBoundingBox, ILocationShape, IPosition } from 'src/interfaces/location';
import { IRootState } from 'src/reducers/interface';

export const useGetNavSource = () =>
  useSelector(({ appState }: IRootState) => appState.navSource);

export const useGetAppLocationShape = () =>
  useSelector<IRootState, ILocationShape | undefined>(({ appState }) => appState.locationShape);

export const useGetBoundingBox = () =>
  useSelector<IRootState, IBoundingBox | undefined>(({ appState }) => appState.boundingBox);

export const useGetAppPosition = () =>
  useSelector<IRootState, IPosition | undefined>(({ appState }) => appState.position);

export const useGetAppActivePopupMenu = () =>
  useSelector<IRootState, PopupMenu>(({ appState }) => appState.activePopupMenu);

export const useGetCurrentPostLocationShape = () => {
  const { id } = useParams();
  return useSelector<IRootState, ILocationShape | undefined>(({ feed }) => {
    return id ? feed.posts[id]?.locationShape : undefined;
  });
};

export const useGetSnackBar = () =>
  useSelector<IRootState, ISnackBarProps>(({ appState }) => appState.snackBar);
