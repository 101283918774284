// lib
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import { LandingPageType } from 'src/components/landing_page/landing_page';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';

// constants & interfaces
import { NOINDEX_NOFOLLOW_ROBOTS, NOT_FOUND_TITLE } from 'src/constants/meta_tags';
import LandingPage from 'src/containers/landing_page';

// helpers
import { URLContext } from 'src/containers/url_provider/url_provider';
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { getConfigValue } from 'src/utils/config/config';

const NotFoundPage: React.FC<RouteComponentProps> = () => {
  const user = useSelectUser();

  const { getNewsfeedRoute } = useContext(URLContext);

  usePageTracking({ user });
  const landingPageType: LandingPageType = getConfigValue('featureFlags.location.defaultAddress')
    ? LandingPageType.SLIM
    : LandingPageType.NORMAL;
  return (
    <>
      <MetaTags
        prerender_status_code='404'
        robots={NOINDEX_NOFOLLOW_ROBOTS}
        title={NOT_FOUND_TITLE}
      />
      <LandingPage type={landingPageType} page404 getDefaultRedirectPath={getNewsfeedRoute} />
    </>
  );
};

export default NotFoundPage;
