import { textResources } from 'src/lang/de';

type MoreOptionsType = 'BLACKLIST'
| 'DELETE'
| 'DELETE_CAMPAIGN'
| 'DELETE_COMMENT'
| 'DUPLICATE_EVENT'
| 'EDIT_COMMENT'
| 'FLAG'
| 'GRAYLIST'
| 'MESSAGE'
| 'SHARE'
| 'REVERT_BLACKLIST'
| 'UPDATE';

export interface IMoreOption {
  angleRight: boolean;
  icon: string;
  text: string;
  value: MoreOptionsType;
  disabled?: boolean;
  tooltip?: string;
};

const labels = textResources.item;

const defaultOption = {
  angleRight: true,
};

const defaultDeleteOption: IMoreOption = {
  ...defaultOption,
  icon: 'delete',
  text: labels.deletePost,
  value: 'DELETE',
};
const defaultUpdateOption: IMoreOption = {
  ...defaultOption,
  icon: 'pencil',
  text: labels.editPost,
  value: 'UPDATE',
};

export const MORE_OPTIONS: Record<MoreOptionsType, IMoreOption> = {
  BLACKLIST: {
    ...defaultOption,
    icon: 'ban',
    text: textResources.blacklist.profile,
    value: 'BLACKLIST',
  },
  DELETE: {
    ...defaultDeleteOption,
  },
  DELETE_CAMPAIGN: {
    ...defaultDeleteOption,
    disabled: true,
    tooltip: textResources.postCreate.campaignDeletePostTooltip,
    value: 'DELETE_CAMPAIGN',
  },
  DELETE_COMMENT: {
    ...defaultDeleteOption,
    text: textResources.comments.remove,
  },
  DUPLICATE_EVENT: {
    ...defaultOption,
    icon: 'copy',
    text: labels.duplicatePost,
    value: 'DUPLICATE_EVENT',
  },
  EDIT_COMMENT: {
    ...defaultUpdateOption,
    text: textResources.comments.edit,
  },
  FLAG: {
    ...defaultOption,
    icon: 'report',
    text: textResources.postCreate.flagPost,
    value: 'FLAG',
  },
  GRAYLIST: {
    ...defaultOption,
    icon: 'mute',
    text: textResources.graylist.mute,
    value: 'GRAYLIST',
  },
  MESSAGE: {
    ...defaultOption,
    icon: 'paper-plane',
    text: textResources.shared.writeMessage,
    value: 'MESSAGE',
  },
  REVERT_BLACKLIST: {
    ...defaultOption,
    icon: 'ban',
    text: textResources.blacklist.revertBlacklist,
    value: 'REVERT_BLACKLIST',
  },
  SHARE: {
    ...defaultOption,
    icon: 'share-thick',
    text: textResources.share.share,
    value: 'SHARE',
  },
  UPDATE: {
    ...defaultUpdateOption,
    text: labels.editPost,
  },
};
