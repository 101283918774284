// libs
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ThunkDispatch } from 'redux-thunk';

// interfaces / constants
import { IAction } from 'src/actions/';
import { IIngredient, IParticipants } from 'src/interfaces/post_ingredients';
import { IRootState } from 'src/reducers/interface';

// classes / components
import { createParticipation, deleteParticipation } from 'src/actions/event_participation/event_participation';
import EventParticipation from 'src/components/post/shared/ingredients/event_participation/event_participation';

// helpers
import { withRouterProps } from 'src/high_order_components/connect_route_props';

interface IMapStateToProps {
  loggedIn: boolean;
}

interface IDispatchFromProps {
  createParticipation: (url: string, id: string) => void;
  deleteParticipation: (url: string, id: string) => void;
}

export type IProps = IIngredient<IParticipants> & IMapStateToProps & IDispatchFromProps & IMapRouteToProps;

const mapStateToProps = (store: IRootState): IMapStateToProps => ({
  loggedIn: store.user.loggedIn,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, IAction>): IDispatchFromProps => ({
  createParticipation: (url, id) => dispatch(createParticipation(url, id)),
  deleteParticipation: (url, id) => dispatch(deleteParticipation(url, id)),
});

class EventParticipantsContainer extends React.Component<IProps, {}> {
  public render() {
    return (
      <EventParticipation {...this.props} />
    );
  }
}

interface IMapRouteToProps {
  path: string;
}

const mapRouteToProps = ({ location }: RouteComponentProps): IMapRouteToProps => ({
  path: location.pathname,
});

export default withRouterProps<IMapRouteToProps, IIngredient<IParticipants>>(mapRouteToProps)(
  connect<IMapStateToProps, IDispatchFromProps, IIngredient<IParticipants> & IMapRouteToProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(EventParticipantsContainer));
