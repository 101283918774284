// interfaces
import {
  FetchFeedThunkAction,
  IParticipatingFeedRequestParams,
} from 'src/actions/feed/feed_interfaces';

// constants
import { FEED_TYPE_EVENT_PARTICIPATING } from 'src/constants/feed';

// actions
import { fetchFeed } from 'src/actions/feed/feed';

// helpers
import api from 'src/api/';

const fetchParticipatingEventFeed = fetchFeed<IParticipatingFeedRequestParams>(
  FEED_TYPE_EVENT_PARTICIPATING,
  api.eventFeed.getParticipating,
);

export const fetchParticipatingEventFeedPage = (page: number = 1): FetchFeedThunkAction => {
  return async(dispatch): Promise<void> => {
    dispatch(fetchParticipatingEventFeed({
      page,
    }));
  };
};
