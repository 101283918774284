// libs
import classNames from 'classnames';
import * as React from 'react';

// classes
import Icon, { IconSize } from 'src/components/forms/icon/icon';
import TextInput from 'src/components/text_input/text_input';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IKeyValue } from 'src/interfaces/';

// helpers
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_DANGER, COLOR_TYPE_DEFAULT, COLOR_TYPE_GRAY_TINT_4 } from 'src/utils/color';
import './input_field.scss';

const INITIAL_ROW_COUNT = 1;

export interface IProps {
  autoComplete?: 'off' | 'current-password' | 'username' | 'new-password'| 'one-time-code' | 'url';
  autoFocus?: boolean;
  assistiveText?: string;
  className?: string;
  classNameInput?: string;
  defaultValue?: string;
  disabled?: boolean;
  errorText?: string;
  expandable?: boolean;
  form?: string;
  floatLabel?: boolean;
  label?: string;
  outlined?: boolean;
  required?: boolean;
  showEmoji?: boolean;
  iconSize?: IconSize;
  iconLeft?: string;
  iconRight?: string;
  min?: string;
  max?: string;
  maxLength?: number;
  maxHeight?: number;
  name: string;
  onBlur?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onFocus?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onInput?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onChange?: (value: string, name: string) => void;
  onRightIconClick?: (event: React.SyntheticEvent<HTMLElement>) => void;
  placeholder?: string;
  readOnly?: boolean;
  initialRows?: number; // textarea when rows is given
  type?: string; // HTML native input type e.g. 'number', 'text', 'email', 'url', 'tel'
  value?: string;
  valid?: boolean;
}

interface IStates {
  innerValue: string;
  isTouched: boolean;
  rows: number;
  focus: boolean;
}

const clsBlock: string = CLASS_PREFIX + 'input-field';
const clsBlockLabel: string = `${clsBlock}__label`;
const clsBlockBody: string = `${clsBlock}__body`;
const clsBlockInput: string = `${clsBlock}__input`;
const clsBlockIcon: string = `${clsBlock}__icon`;
const clsTextarea: string = `${clsBlock}__textarea`;

export default class InputField extends React.PureComponent<IProps, IStates> {
  public static defaultProps = {
    type: 'text',
  };
  private inputRef: React.RefObject<HTMLInputElement>;
  private textInputRef: React.RefObject<TextInput>;

  constructor(props: IProps) {
    super(props);
    this.inputRef = React.createRef();
    this.textInputRef = React.createRef();
    this.focusInput = this.focusInput.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onTextAreaChange = this.onTextAreaChange.bind(this);

    const { value, defaultValue } = props;
    let innerValue = value || defaultValue;

    if (innerValue === undefined) {
      innerValue = '';
    }

    this.state = {
      focus: false,
      innerValue,
      isTouched: false,
      rows: props.initialRows || INITIAL_ROW_COUNT,
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    const { value } = this.props;

    if ((prevProps.value !== value) && value !== undefined) {
      this.setState({ innerValue: value });
    }
  }

  public focusInput() {
    const { expandable, initialRows } = this.props;
    const isMultilineInput = this.isMultiLineInput(initialRows, expandable);
    if (isMultilineInput) {
      this.textInputRef.current && this.textInputRef.current.focus();
    } else {
      this.inputRef.current && this.inputRef.current.focus();
    }
  }

  public blurInput() {
    const { expandable, initialRows } = this.props;
    const isMultilineInput = this.isMultiLineInput(initialRows, expandable);
    if (isMultilineInput) {
      this.textInputRef.current && this.textInputRef.current.blur();
    } else {
      this.inputRef.current && this.inputRef.current.blur();
    }
  }

  public render() {
    const cls: IKeyValue = {};
    const {
      assistiveText,
      errorText,
      className,
      classNameInput,
      disabled,
      expandable,
      floatLabel,
      label,
      name,
      onRightIconClick,
      outlined,
      placeholder,
      iconSize,
      iconLeft,
      iconRight,
      required,
      initialRows,
      valid,
      showEmoji,
    } = this.props;
    const { focus, isTouched, innerValue } = this.state;
    const isEmpty: boolean = !innerValue.trim();
    const isValid = valid === undefined ? true : valid;
    const invalidState = !isValid ||
      (required && isEmpty && isTouched);

    const errorMessage = required && isEmpty ? textResources.shared.required : errorText;
    const showErrorMessage = errorMessage && invalidState;
    const showAssistiveMessage = (assistiveText && invalidState && !showErrorMessage) ||
      (assistiveText && !invalidState);

    cls[`${clsBlock}`] = true;
    cls[`${clsBlock}--invalid`] = invalidState;
    cls[`${clsBlock}--touched`] = isTouched;
    cls[`${clsBlock}--empty`] = isEmpty;
    cls[`${className}`] = className;
    const useFloatLabel: boolean = floatLabel === undefined ? true : floatLabel; // default true

    const clsLabel: IKeyValue = {
      [`${clsBlockLabel}`]: true,
      [`${clsBlockLabel}--icon-left`]: !!iconLeft,
      [`${clsBlockLabel}--disabled`]: disabled,
      [`${clsBlockLabel}--static-label`]: focus || !isEmpty || placeholder || !useFloatLabel,
    };
    const clsBody: IKeyValue = {
      [`${clsBlockBody}`]: true,
      [`${clsBlockBody}--not-empty`]: !isEmpty,
      [`${clsBlockBody}--invalid`]: invalidState,
      [`${clsBlockBody}--disabled`]: disabled,
      [`${clsBlockBody}--outlined`]: outlined,
    };
    const clsErrorIcon: IKeyValue = {
      [`${clsBlockIcon}-right`]: true,
      [`${clsBlockIcon}-right--expandable`]: expandable,
    };
    const clsBlockName = clsBlock + name;
    const isMultilineInput = this.isMultiLineInput(initialRows, expandable);

    return (
      <div className={classNames(cls)}>
        <div className={classNames(clsBody)} onClick={this.focusInput} >
          {iconLeft &&
            <div className={`${clsBlockIcon}-left`}>
              <Icon color={disabled ? COLOR_TYPE_GRAY_TINT_4 : COLOR_TYPE_DEFAULT} name={iconLeft} size={24}/>
            </div>
          }
          <div className={`${clsBlock}__inner-body`}>
            { isMultilineInput
              ? <TextInput
                {...this.props}
                onChange={this.onTextAreaChange}
                onBlur={this.onBlur}
                id={clsBlockName}
                className={classNameInput ? `${clsTextarea} ${classNameInput}` : clsTextarea}
                required={true}
                onFocus={this.onFocus}
                ref={this.textInputRef}
                invalidState={!!invalidState}
              />
              : this.renderInput(this.props)}
            <label className={classNames(clsLabel)} htmlFor={clsBlockName}>
              {label}
            </label>
          </div>
          {showErrorMessage && !showEmoji &&
            <div className={classNames(clsErrorIcon)}>
              <Icon
                color={disabled ? COLOR_TYPE_GRAY_TINT_4 : COLOR_TYPE_DANGER}
                name={'report-filled'}
                size={24}
              />
            </div>
          }
          {!invalidState && !showEmoji && iconRight &&
            <div className={`${clsBlockIcon}-right`} onClick={onRightIconClick}>
              <Icon
                color={disabled ? COLOR_TYPE_GRAY_TINT_4 : COLOR_TYPE_DEFAULT}
                name={iconRight}
                size={iconSize || 24}
              />
            </div>
          }
        </div>
        {showErrorMessage && <div className={`${clsBlock}__error-text`}>{errorMessage}</div>}
        {showAssistiveMessage && <div className={`${clsBlock}__assistive-text`}>{assistiveText}</div>}
      </div>
    );
  }

  private isMultiLineInput(initialRows?: number, expandable?: boolean): boolean | undefined {
    return !!(initialRows || expandable);
  }

  private renderInput(props: IProps) {
    const {
      autoComplete,
      autoFocus,
      classNameInput,
      defaultValue,
      disabled,
      form,
      iconLeft,
      iconRight,
      name,
      required,
      value,
      min,
      max,
      maxLength,
      placeholder,
      type,
      readOnly,
    } = props;

    const cls: IKeyValue = {};
    cls[`${clsBlockInput}`] = true;
    if (classNameInput) {
      cls[classNameInput] = true;
    }
    if (iconLeft) {
      cls[`${clsBlockInput}--icon-left`] = true;
    }
    if (iconRight) {
      cls[`${clsBlockInput}--icon-right`] = true;
    }

    return (
      <input
        type={type}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classNames(cls)}
        defaultValue={defaultValue}
        disabled={disabled}
        form={form}
        id={clsBlock + name}
        placeholder={placeholder}
        min={min}
        max={max}
        maxLength={maxLength}
        name={name}
        required={required}
        value={value}
        onBlur={this.onBlur}
        onChange={this.onInputChange}
        onFocus={this.onFocus}
        ref={this.inputRef}
        readOnly={readOnly}
      />
    );
  }

  private onFocus(event: React.SyntheticEvent<HTMLElement>) {
    this.setState({ focus: true });
    const { onFocus } = this.props;
    if (onFocus) {
      onFocus(event);
    }
  }

  private onBlur(event: React.SyntheticEvent<HTMLElement>) {
    this.setState({ focus: false, isTouched: true });
    const { onBlur } = this.props;
    if (onBlur) {
      onBlur(event);
    }
  }

  private onInputChange(event: React.SyntheticEvent<HTMLInputElement>) {
    const target = event.target as HTMLInputElement;
    this.setState({ innerValue: target.value, isTouched: true });
    const { onChange } = this.props;
    if (onChange) {
      onChange(target.value, target.name);
    }
  }

  private onTextAreaChange(value: string) {
    const { name } = this.props;
    this.setState({ innerValue: value, isTouched: true });
    const { onChange } = this.props;
    if (onChange) {
      onChange(value, name);
    }
  }
}
