// interfaces
import * as Response from 'src/api/interfaces/responses/notification';
import {
  bookmarkableTypes,
  commentableTypes,
  IBookmarkNotification,
  ICommentNotification,
  IMessageNotification,
  IFollowNotification,
  IGroupNotification,
  IGroupAccessConfirmationNotification,
  Notification,
  INotificationInitiator,
  IUnknownNotification,
  IEventParticipationNotification,
  IRecommentNotification,
} from 'src/interfaces/notification';
import { IPagination } from 'src/interfaces/pagination';

// decoders
import { $pagination } from 'src/api/decoders/pagination';
import { $resourceLink } from 'src/api/decoders/resource_link';
import { UNKNOWN } from 'src/constants/notifications';

export interface INotificationReducerPayload {
  notifications: Notification[];
  pagination: IPagination;
}

/******************************************************************************
 * notification decoders
 */
export const $notifications = (json: Response.INotificationsResponse): INotificationReducerPayload => ({
  notifications: json.data.map($notification),
  pagination: $pagination(json.meta),
});

const $notification = (json: Response.INotification): Notification => {
  if (Response.isBookmarkNotification(json)) {
    return $bookmarkNotification(json);
  }
  if (Response.isCommentNotification(json)) {
    return $commentNotification(json);
  }
  if (Response.isRecommentNotification(json)) {
    return $recommentNotification(json);
  }
  if (Response.isMessageNotification(json)) {
    return $messageNotification(json);
  }
  if (Response.isFollowNotification(json)) {
    return $followNotification(json);
  }
  if (Response.isGroupNotification(json)) {
    return $groupNotification(json);
  }
  if (Response.isEventParticipationNotification(json)) {
    return $eventParticipationNotification(json);
  }
  if (Response.isGroupAccessConfirmationNotification(json)) {
    return $groupAccessConfirmationNotification(json);
  }
  return $unknownNotification(json);
};

const $bookmarkNotification = (json: Response.IBookmarkNotification): IBookmarkNotification => ({
  ...$notificationCommon(json),
  bookmarkType: json.event.bookmark_type,
  bookmarkableType: mapBookmarkableType(json.event.bookmarkable.type),
  eventCode: json.event.code,
});

const mapBookmarkableType = (bookmarkableType: Response.BookmarkableType) => {
  return bookmarkableTypes[bookmarkableType];
};

const $commentNotification = (json: Response.ICommentNotification): ICommentNotification => ({
  ...$notificationCommon(json),
  commentableType: mapCommentableType(json.context.type),
  eventCode: json.event.code,
});

const mapCommentableType = (commentableType: Response.CommentableType) => {
  return commentableTypes[commentableType];
};

const $recommentNotification = (json: Response.IRecommentNotification): IRecommentNotification => ({
  ...$notificationCommon(json),
  commentableType: mapCommentableType(json.context.type),
  eventCode: json.event.code,
});

const $messageNotification = (json: Response.IMessageNotification): IMessageNotification => ({
  ...$notificationCommon(json),
  eventCode: json.event.code,
});

const $followNotification = (json: Response.IFollowNotification): IFollowNotification => ({
  ...$notificationCommon(json),
  eventCode: json.event.code,
});

const $groupNotification = (json: Response.IGroupNotification): IGroupNotification => ({
  ...$notificationCommon(json),
  groupName: json.context.context.teaser,
  eventCode: json.event.code,
});

const $groupAccessConfirmationNotification =
  (json: Response.IGroupAccessConfirmationNotification): IGroupAccessConfirmationNotification => ({
    ...$notificationCommon(json),
    eventCode: json.event.code,
  });

const $eventParticipationNotification =
  (json: Response.IEventParticipationNotification): IEventParticipationNotification => ({
    ...$notificationCommon(json),
    eventCode: json.event.code,
  });

const $unknownNotification = (json: Response.INotification): IUnknownNotification => ({
  ...$notificationCommon(json),
  eventCode: UNKNOWN,
});

type NotificationCommon = Pick<Notification, Exclude<keyof Notification, 'eventCode'>>;

const $notificationCommon = (json: Response.INotification): NotificationCommon => ({
  body: json.body || undefined,
  contextTeaser: json.context && json.context.teaser || undefined,
  createdAt: json.meta.created_at,
  id: json.identifier,
  initiator: json.initiator && $initiator(json.initiator) || undefined,
  isRead: !!json.meta.read_at,
  resourceLink: json.resource_link && $resourceLink(json.resource_link) || undefined,
});

const $initiator = (json: Response.INotificationInitiator): INotificationInitiator => ({
  image: json.image.square_small,
  name: json.name || undefined,
});

/******************************************************************************
 * notification unread counts related
 */

export interface IUnseenCountsReducerPayload {
  [profileIdentifier: string]: number | undefined;
}

export const $notificationsUnseenCounts =
  (json: Response.INotificationsUnseenCountsResponse): IUnseenCountsReducerPayload => {
    const result: IUnseenCountsReducerPayload = {};

    Object.keys(json.data).forEach((profileIdentifier) => {
      // we need explicit "not undefined" checks here, as we want to decode entries with "total: 0"
      if (json.data[profileIdentifier].total !== undefined) {
        result[profileIdentifier] = json.data[profileIdentifier].total!;
      }
    });

    return result;
  };
