// libs
import * as React from 'react';

// interfaces / constants
import { IGroupAccessConfirmationNotification } from 'src/interfaces/notification';

// classes / components
import Notification, { clsText } from 'src/components/notifications/notification';

// helpers
import DateFormatter from 'src/utils/date_formatter/date_formatter';

export interface IProps extends IGroupAccessConfirmationNotification {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const GroupAccessConfirmationNotification: React.FC<IProps> = ({
  body,
  createdAt,
  initiator,
  isRead,
  onClick,
}) =>
  (
    <Notification
      avatarImage={initiator && initiator.image}
      footer={DateFormatter.asTimeAgo(createdAt)}
      isRead={isRead}
      onClick={onClick}
      initiatorName={''}
    >
      <span className={clsText}>{body}</span>
    </Notification>
  );

export default GroupAccessConfirmationNotification;
