// interfaces
import {
  FetchFeedThunkAction,
  IEventfeedRequestParams,
} from 'src/actions/feed/feed_interfaces';

// constants
import { FEED_DEFAULT_PER_PAGE, FEED_TYPE_EVENT_RELATED } from 'src/constants/feed';

// actions
import { fetchFeed } from 'src/actions/feed/feed';

// helpers
import {
  addBoundingBox,
  addRelatedRanges,
  hasBoundingBox,
} from 'src/actions/feed/feed_utils';
import api from 'src/api/';

const fetchRelatedEventFeed = fetchFeed<IEventfeedRequestParams>(
  FEED_TYPE_EVENT_RELATED,
  api.eventFeed.get,
  false,
);

export const fetchRelatedEventFeedPage = (page: number = 1): FetchFeedThunkAction => {
  return async(dispatch, getState): Promise<void> => {
    const state = getState();

    if (!hasBoundingBox(state)) {
      return;
    }

    dispatch(fetchRelatedEventFeed({
      page,
      perPage: FEED_DEFAULT_PER_PAGE,
      ...addBoundingBox(state),
      ...addRelatedRanges(state),
      categories: [],
    }));
  };
};
