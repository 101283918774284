// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IIngredient, IVideo } from 'src/interfaces/post_ingredients';
import { mapDisplayModeToConst } from 'src/interfaces/posts';

// classes
import NavigationItem from 'src/components/navigation_item/navigation_item';

// styles
import './video.scss';

export type IProps = IIngredient<IVideo>;

export const cls = `${CLASS_PREFIX}video__`;

const youtubePrefix = 'https://www.youtube.com/embed/';

export default class Video extends React.PureComponent<IProps, {}> {
  private cls = '';

  constructor(props: IProps) {
    super(props);
    this.cls = `${cls}${mapDisplayModeToConst[props.displayMode]}`;
  }

  public render() {
    const { data: { id }, postDetailsURL } = this.props;

    return (
      <NavigationItem target='_self' url={postDetailsURL} >
        <div className={this.cls}>
          <div className={this.cls + '__wrapper'}>
            <iframe className={this.cls + '__frame'} src={youtubePrefix + id}/>
          </div>
        </div>
      </NavigationItem>
    );
  }
}
