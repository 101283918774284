export const NOTIFICATIONS_FETCH_INTERVAL = 30; // fetch notificatios and unread counts all ... seconds
export const NOTIFICATIONS_DEFAULT_PER_PAGE = 50; // fetch ... notificatios per request

// eventCodes
export const FOLLOW = 'follow';
export const FOLLOWEE_POST = 'followee_post';
export const FOLLOWEE_EVENT = 'followee_event';
export const BOOKMARK = 'bookmark';
export const COMMENT = 'comment';
export const RECOMMENT = 'recomment';
export const MESSAGE = 'message';
export const GROUP_POST = 'group_post';
export const EVENT = 'event';
export const EVENT_PARTICIPATION = 'event_participation';
export const POST = 'post';
export const UNKNOWN = 'unknown';
export const GROUP_ACCESS_CONFIRMATION = 'group_access_confirmation';
