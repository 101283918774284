// lib
import React from 'react';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import WizardButton, {
  WIZARD_BUTTON_TYPE_CANCEL,
} from 'src/components/wizard/wizard_button/wizard_button';
import WizardStep from 'src/components/wizard/wizard_step/wizard_step';
import AppLocationPicker from 'src/containers/smart_components/app_location_picker/app_location_picker';
import LayoutNoHeader from 'src/layout/layout_no_header';

// constants & interfaces
import { TWO_COLUMNS } from 'src/constants/layout';
import { NOINDEX_FOLLOW_ROBOTS } from 'src/constants/meta_tags';

// helpers
import { textResources } from 'src/lang/de';
import { usePageTracking } from 'src/pages/hooks/hooks';
import initTracker, { LOCATION_PICKER_PAGE } from 'src/utils/reporting/events_tracking/events_tracking';
import { UrlUtils } from 'src/utils/url/url';

const trackMapControls = initTracker(LOCATION_PICKER_PAGE);

export const LocationPickerPage: React.FC = () => {
  usePageTracking();
  // User might have to go back to the static landing page, which is not part of the React app
  // so it is not possible to use the WizardGoBackButton
  const goBack = () => {
    UrlUtils.setLocationHref('/');
  };

  return (
    <>
      <MetaTags
        title={textResources.metatags.titles.locationPicker}
        robots={NOINDEX_FOLLOW_ROBOTS}
      />
      <LayoutNoHeader
        contentWidth={TWO_COLUMNS}
      >
        <WizardStep
          layout='page'
          headerText={textResources.pageTitles.locationPicker}
          leftButton={<WizardButton textType={WIZARD_BUTTON_TYPE_CANCEL} onClick={goBack} />}
        >
          <AppLocationPicker tracking={{ tracker: trackMapControls, trackingObj: LOCATION_PICKER_PAGE }} />
        </WizardStep>
      </LayoutNoHeader>
    </>
  );
};

export default LocationPickerPage;
