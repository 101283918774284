// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import HeaderEventCategory from 'src/containers/header/subheader/header_event_category';
import EventCategoryFeed from 'src/containers/smart_components/feeds/event_feed';
import PageTitleEventCategory from 'src/containers/smart_components/page_title/page_title_event_category';

// constants & interfaces
import { FEED_TYPE_EVENT } from 'src/constants/feed';
import {
  EVENTSFEED_CATEGORY_TITLE,
  EVENTSFEED_DESCRIPTION,
  EVENTSFEED_OG_IMAGE,
} from 'src/constants/meta_tags';

// helpers
import { usePageTracking, useIsPostCreateOpen } from 'src/pages/hooks/hooks';
import { useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectActiveFilter } from 'src/reducers/filters/filters_hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { LayoutFeaturedContainer } from '../layout/layout_featured_container';

const EventCategoryFeedPage: React.FC<RouteComponentProps> = ({ match }) => {
  const locationShape = useGetAppLocationShape();
  const user = useSelectUser();

  const activeFilter = useSelectActiveFilter();

  const isPostCreateOpen = useIsPostCreateOpen(match);

  const title = (locationShape && activeFilter.label !== 'default')
    ? EVENTSFEED_CATEGORY_TITLE(locationShape.name, activeFilter.label)
    : undefined;

  const description = locationShape && EVENTSFEED_DESCRIPTION(locationShape.name);

  usePageTracking({ pageTitle: title, user });

  return (
    <>
      <MetaTags
        description={description}
        geo_placename={locationShape?.name}
        geo_position={locationShape && `${locationShape.latitude};${locationShape.longitude}`}
        og_image={EVENTSFEED_OG_IMAGE}
        og_url={document.location.toString()}
        title={title}
      />
      <LayoutFeaturedContainer
        subHeader={<HeaderEventCategory />}
        activeNavigationItem={FEED_TYPE_EVENT}
        pageTitle={<PageTitleEventCategory />}
        withHeaderMargin>
        <EventCategoryFeed isPostCreateOpen={isPostCreateOpen} />
      </LayoutFeaturedContainer>
    </>
  );
};

export default EventCategoryFeedPage;
