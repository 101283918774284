import AirbrakeClient from 'airbrake-js';
import { Store } from 'redux';

// interfaces
import { API_ERROR } from 'src/constants/api';
import { IWindowJsEnv } from 'src/interfaces/index';
import { IRootState } from 'src/reducers/interface';
import { getConfigValue } from 'src/utils/config/config';

import GoogleTagManager from 'src/utils/reporting/google-tag-manager';

const _window = window as unknown as IWindowJsEnv;

const EnvCheck = /production|staging/;
// Use the following lines to also allow error reporting from dev environment
// const DomainCheck = /^/;
// const EnvCheck = /production|staging|development/;

// Holds the store provided by setAirbrakeStore()
// Needed information for an errbit-report will be get from this store-reference.
let store: Store<IRootState> | undefined;

export const airbrakeClient = new AirbrakeClient({
  environment: _window.js_env.env,
  host: 'https://airbrake.io',
  ignoreWindowError: true, // We'll create our own global error-handler a few lines below.
  projectId: _window.js_env.errbitConfig.id,
  projectKey: _window.js_env.errbitConfig.key,
  revision: _window.js_env.gitRevision,
});

export function setAirbrakeStore(_store: Store<IRootState>) {
  store = _store;
}

// Filter unwanted errors by messages.
airbrakeClient.addFilter((notice) => {
  const error = notice.errors[0];
  switch (error.message) {
    // Network errors (since we cannot do anything to fix them).
    case 'Failed to fetch':
    case 'NetworkError when attempting to fetch resource.':
    case 'Network request failed':
    case 'Fehler bei "fetch"':
    case 'Der Vorgang konnte nicht abgeschlossen werden. Die Software hat einen Verbindungsabbruch verursacht':
    case 'Es besteht anscheinend keine Verbindung zum Internet.':
    case 'Die Netzwerkverbindung wurde unterbrochen.':
    case 'Zeitüberschreitung bei der Anforderung.':
    // Cross domain issue within connect.facebook.net
    case 'The operation is insecure.':
    // Fail of a fetch when a user navigates out of the page that tried to fetch
    case 'Abgebrochen':
    case 'cancelled':
    // Outdated browser errors:
    case "Failed to read the 'cssRules' property from 'CSSStyleSheet': Cannot access rules":
    // Facebook Bots:
    case 'Unexpected identifier':
    // The following messages that are (most likely) only thrown by bots.
    // https://gitlab.naymspace.de/lokalportal/lokalportal/issues/3655
    case 'Failed to execute \'postMessage\' on \'Window\': function (){var paramPrefix,i,templateArgs=arguments,' +
      'code=templateArgs[0],message="["+(module?module+":":"")+co...<omitted>...)} could not be cloned.':
    case 'Invalid LatLng object: (NaN, NaN)':
    // user aborted requests
    case 'The user aborted a request.':
    // canceled share
    case 'Abort due to cancellation of share.':
    case 'Share canceled':
    case 'Internal error: could not connect to Web Share interface.':
    case 'error on copying share link to clipboard':
      return null;
      // failed api calls becouse unsuported areas
      // not relevant for airbrake but maybe usefull for analytics
    case 'The requested area is not yet released':
      GoogleTagManager.pushEventWithValue(API_ERROR, 'api_call_on_not_released_area', JSON.stringify(error));
      return null;
    // google maps api errors
    case 'Could not load "util".':
      GoogleTagManager.pushEventWithValue(API_ERROR, 'could_not_load_util', JSON.stringify(error));
      return null;
    case 'User denied Geolocation':
    case 'User denied geolocation prompt':
      GoogleTagManager.pushEventWithValue(API_ERROR, 'User denied Geolocation', JSON.stringify(error));
      return null;
  }
  return notice;
});

// Adds user information if available
airbrakeClient.addFilter((notice) => {
  try {
    const userData = store && store.getState().user;
    const extensionId = userData && userData.selectedProfileId;
    const userEmail = userData && userData.email;

    notice.session.extension_id = extensionId;
    notice.session.email = userEmail;
  } catch (e) {
    throw e;
  }
  return notice;
});

// Abort airbrake notification when the error comes from a unrelated file
// e.g browser extensions
airbrakeClient.addFilter((notice) => {
  const error = notice.errors[0];
  // ignore firefox extensions
  if (error.backtrace[0]?.file?.includes('moz-extension://')) {
    return null;
  }
  return notice;
});

// Abort airbrake notification if context url is not set or
// if it does not match lokalportal.de or one of its subdomains.
airbrakeClient.addFilter((notice) => {
  if (!(notice.context && notice.context.url.startsWith(getConfigValue('urls.Homepage')))) {
    return null;
  }
  return notice;
});

// Abort airbrake notification if we're not on production or staging.
// Otherwise add the environment to the context and continue.
airbrakeClient.addFilter((notice) => {
  if (!EnvCheck.test(_window.js_env.env)) {
    return null;
  }
  if (notice.context) {
    notice.context.environment = _window.js_env.env;
  }
  return notice;
});

// Abort airbrake notification if context url is not set or
// if it does not match lokalportal.de or one of its subdomains.
airbrakeClient.addFilter((notice) => {
  notice.context.version = _window.js_env.gitRevision;

  return notice;
});

// Builds the url needed to visit an Airbrake-report.
export const buildAirbrakeUrl = (id: string): string => {
  return `https://lokalportal.airbrake.io/locate/${id}`;
};
