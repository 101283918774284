import classNames from 'classnames';
import * as React from 'react';

import NavigationItem, { INavigationProps } from 'src/components/navigation_item/navigation_item';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

import { itemSegmentCls } from './item_segment_base';

import './item_segment_link.scss';

export interface IProps extends INavigationProps {
  noGrow?: boolean;
  featureBlocked?: boolean;
}

const cls = itemSegmentCls + 'link';

/*
  This component should only be child of ItemSegmentBase
  and contain Segment Parts
 */

const ItemSegmentLink: React.FC<IProps> = (props) => {
  const classes = {
    [cls]: true,
    [cls + '--no-grow']: props.noGrow,
    [cls + '--no-pointer']: !props.onClick && !props.url,
  };

  let element: React.ReactElement;

  if (!props.url && !props.onClick) {
    element = (
      <div className={classNames(classes)}>
        <NavigationItem {...props}>
          {props.children}
        </NavigationItem>
      </div>
    );
  } else {
    element = (
      <NavigationItem className={classNames(classes)} {...props}>
        {props.children}
      </NavigationItem>
    );
  }

  if (props.featureBlocked) {
    return (
      <UserOnboardingCTA className={classNames(classes)} active={true} >
        {element}
      </UserOnboardingCTA>
    );
  }
  return element;
};

export default ItemSegmentLink;
