// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import { textResources } from 'src/lang/de';
import loadingGif from './loading_spinner.gif';
import './loading_spinner.scss';

export interface IProps {
  shown: boolean;
}

const cls: string = CLASS_PREFIX + 'loading-spinner';

const LoadingSpinner: React.FC<IProps> = ({ shown = false }) => {
  if (shown) {
    return (
      <div className={cls}>
        <img alt={textResources.imageAlt.loadingSpinner} className={cls + '__spinner'} src={loadingGif} />
      </div>
    );
  } else {
    return null;
  }
};

export default LoadingSpinner;
