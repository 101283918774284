// lib
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// components
import BackbuttonHeader from 'src/components/header/common/backbutton_header/backbutton_header';
import MetaTags from 'src/components/meta_tags/meta_tags';
import PageTitleFollowers from 'src/containers/smart_components/page_title/page_title_followers';
import ProfileFollowers from 'src/containers/smart_components/profile_followers/profile_followers';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { PROFILE_FOLLOWERS_TITLE, PROFILE_OG_TYPE } from 'src/constants/meta_tags';
import { textResources } from 'src/lang/de';

// helpers
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUrlParamProfile } from 'src/reducers/profiles/profiles_hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { isPrivateProfile } from 'src/utils/profile';
import { UrlUtils } from 'src/utils/url/url';

const ProfileFollowersPage: React.FC = () => {
  const history = useHistory();
  const user = useSelectUser();

  const profile = useSelectUrlParamProfile();

  const { locationShape, name, description, imageUrl, id, type } = profile || {};

  usePageTracking({ pageTitle: PROFILE_FOLLOWERS_TITLE, user });

  const handleBackButton = useCallback(() => {
    UrlUtils.goBack(history, id && type && UrlUtils.getProfileFrontendPath(id, type));
  }, [history, id, type]);

  return (
    <>
      {profile && isPrivateProfile(profile.type)
        // we currently do not show any meta tag information for PrivateProfiles
        ? <MetaTags
          title={PROFILE_FOLLOWERS_TITLE}
        />
        : <MetaTags
          author={name}
          canonical={UrlUtils.currentUrlWithHostAndPath()}
          description={description}
          geo_placename={locationShape?.name}
          geo_position={locationShape && `${locationShape.latitude};${locationShape.longitude}`}
          og_image={imageUrl}
          og_url={UrlUtils.currentUrlWithHostAndPath()}
          og_longitude={locationShape?.longitude.toString()}
          og_latitude={locationShape?.latitude.toString()}
          og_type={PROFILE_OG_TYPE}
          profile_username={name}
          title={PROFILE_FOLLOWERS_TITLE}
        />
      }
      <LayoutNoMap
        pageTitle={<PageTitleFollowers />}
        subHeader={<BackbuttonHeader title={textResources.pageTitles.followers} handleBackButton={handleBackButton} />}
        withHeaderMargin
      >
        <ProfileFollowers />
      </LayoutNoMap>
    </>
  );
};

export default ProfileFollowersPage;
