// lib
import React, { useContext } from 'react';
import { match, useParams, useHistory } from 'react-router-dom';

// components
import PostHeaderDetails from 'src/components/header/subheaders/pages/post_details/post_header_details';
import { URLContext } from 'src/containers/url_provider/url_provider';

// interfaces & constants
import { textResources } from 'src/lang/de';
import { HEADER_DETAILS, HeaderDetailsObjType } from 'src/utils/reporting/events_tracking/events_tracking';

// helpers
import { withTrackerProps, ITrackingProps } from 'src/high_order_components/with_tracker_props';
import { useIsPostUpdateOpen } from 'src/pages/hooks/hooks';
import { usePostData } from 'src/reducers/feed/feed_hooks';
import { UrlUtils } from 'src/utils/url/url';

interface IProps extends ITrackingProps<HeaderDetailsObjType> {
  match: match;
}

const PostHeaderDetailsContainer: React.FC<IProps> = (props) => {
  const { id: postId } = useParams();
  const history = useHistory();
  const post = usePostData(postId);
  const postUpdateOpen = useIsPostUpdateOpen(props.match);
  const title = post?.meta.title || textResources.shared.lokalportal;

  const { getEventsfeedRoute, getNewsfeedRoute } = useContext(URLContext);
  const goToFallback =
    UrlUtils.isInPostDetailPage(location.pathname) ? getNewsfeedRoute() : getEventsfeedRoute();
  const handleBackButton = () => {
    UrlUtils.goBack(history, goToFallback);
  };
  return (
    <PostHeaderDetails
      {...props}
      title={title}
      handleBackButton={handleBackButton}
      post={post}
      postUpdateOpen={postUpdateOpen}
    />);
};

export default withTrackerProps(HEADER_DETAILS)(PostHeaderDetailsContainer);
