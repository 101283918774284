// libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// interfaces / constants
import { IAction } from 'src/actions/index';
import { IRelatedContentState } from 'src/interfaces/related_content';
import { IRootState } from 'src/reducers/interface';

// classes / components
import RelatedContent from 'src/components/related_content/related_content';
import withLoadingSpinner from 'src/high_order_components/with_loading_spinner';

// helpers
import { getRelatedContent } from 'src/actions/related_content';

interface IRelatedContentOwnProps {
  path: string;
}

interface IRelatedContentDispatchProps {
  fetchRelatedContent: (path: string) => void;
}

type IRelatedContentProps = IRelatedContentOwnProps & IRelatedContentState & IRelatedContentDispatchProps;

const mapStateToProps = (state: IRootState): IRelatedContentState => ({
  hasError: state.relatedContent.hasError,
  isLoading: state.relatedContent.isLoading,
  postIds: state.relatedContent.postIds,
  posts: state.relatedContent.posts,
});

const mapDispatchToProps = (dispatch: Dispatch<IAction>): IRelatedContentDispatchProps => ({
  fetchRelatedContent: (path) => dispatch(getRelatedContent(path)),
});

class RelatedContentContainer extends React.Component<IRelatedContentProps, {}> {
  public componentDidMount() {
    const { fetchRelatedContent, path } = this.props;
    fetchRelatedContent(path);
  }

  public componentDidUpdate(prevProps: IRelatedContentProps) {
    const { fetchRelatedContent, path } = this.props;
    if (prevProps.path !== path) {
      fetchRelatedContent(path);
    }
  }

  public render() {
    const { postIds, posts } = this.props;

    return (
      <RelatedContent
        postIds={postIds}
        posts={posts}
      />
    );
  }
}

export default connect<IRelatedContentState, IRelatedContentDispatchProps, IRelatedContentOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(withLoadingSpinner(RelatedContentContainer));
