import {
  FEED_POST_TYPE_EVENT,
  FEED_POST_TYPE_PRIVATE_POST,
  FEED_POST_TYPE_PUBLIC_POST,
  FEED_POST_TYPE_SNAPSHOT,
  FEED_POST_TYPE_SURVEY,
} from 'src/constants/feed';
import { ITrackingType } from 'src/high_order_components/with_tracker_props';
import { IContentItemType } from 'src/interfaces/posts';
import { ProfileType } from 'src/interfaces/profile';
import { textResources } from 'src/lang/de';
import { getConfigValue } from 'src/utils/config/config';
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import { isPrivateProfile } from 'src/utils/profile';
import {
  TrackingLabel,
  PostCreateObjType,
  PostUpdateObjType,
} from 'src/utils/reporting/events_tracking/events_tracking';

const labels = textResources.postCreate;

export const getLabel = (profileType: ProfileType) => {
  return isPrivateProfile(profileType) ? labels.descriptionPlaceholder
    : labels.shareYourInformation;
};

export const postSubmitTracking = (
  postType: IContentItemType,
  trackingLabel: TrackingLabel,
  tracking: ITrackingType<PostCreateObjType> | ITrackingType<PostUpdateObjType>,
) => {
  const { tracker, trackingObj } = tracking;
  switch (postType) {
    case FEED_POST_TYPE_EVENT:
      tracker(trackingObj.ACTIONS.EVENT, trackingLabel);
      break;
    case FEED_POST_TYPE_PRIVATE_POST:
      tracker(trackingObj.ACTIONS.PRIVATE_POST, trackingLabel);
      break;
    case FEED_POST_TYPE_PUBLIC_POST:
      tracker(trackingObj.ACTIONS.PUBLIC_POST, trackingLabel);
      break;
    case FEED_POST_TYPE_SNAPSHOT:
      tracker(trackingObj.ACTIONS.SNAPSHOT, trackingLabel);
      break;
    case FEED_POST_TYPE_SURVEY:
      tracker(trackingObj.ACTIONS.WRITE_SURVEY, trackingLabel);
      break;
  }
};

export const getDateTimeFormat = (datetime: string) => {
  const showFullDatetime = getConfigValue<boolean>('featureFlags.feed.showFullDatetime');

  return showFullDatetime ? DateFormatter.calendarFromDayWithShortDate(datetime) : DateFormatter.asTimeAgo(datetime);
};
