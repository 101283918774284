// libs
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

// interfaces
import { IBoundingBox } from 'src/interfaces/location';

// helpers
import {
  setHash,
  getShapeSponsors,
} from 'src/actions/profiles_list/profiles_list';
import { useProfilesList } from 'src/reducers/profiles_list/profiles_list_hooks';

export const getShapeSponsorsHash = (boundingBox?: IBoundingBox) =>
  `#SHAPE_SPONSORS#${JSON.stringify(boundingBox)}`;

export const useGetShapeSponsors = () => {
  const dispatch = useDispatch();
  const { hash } = useProfilesList();
  const latestHash = useRef(hash);

  useEffect(() => {
    latestHash.current = hash;
  });

  return useCallback(
    (boundingBox?: IBoundingBox) => {
      const newHash = getShapeSponsorsHash(boundingBox);
      const isHashChanged = latestHash.current !== newHash;

      if (isHashChanged) {
        dispatch(setHash(newHash));
      }

      if (!boundingBox || !isHashChanged) {
        return;
      }

      const action = getShapeSponsors();
      dispatch(action);
    },
    [dispatch],
  );
};
