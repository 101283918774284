// interfaces / constants
import { IAction } from 'src/actions/';
import { Id } from 'src/interfaces';
import { ICommentPayload, ICommentsPayload, IDeletedCommentPayload } from 'src/interfaces/comments';

// classes / components
import api, { ICommentUpdate } from 'src/api/';

const COMMENT_CREATE_REQUEST = 'COMMENT_CREATE_REQUEST';
const COMMENT_DELETE = 'COMMENT_DELETE';
const COMMENTS_REQUEST = 'COMMENTS_REQUEST';
const COMMENT_UPDATE = 'COMMENT_UPDATE';

export const COMMENT_DELETE_SUCCESS = 'COMMENT_DELETE_SUCCESS';
export const COMMENT_DELETE_ERROR = 'COMMENT_DELETE_ERROR';
export const COMMENTS_REQUEST_SUCCESS = 'COMMENTS_REQUEST_SUCCESS';
export const COMMENT_CREATE_REQUEST_SUCCESS = 'COMMENT_CREATE_REQUEST_SUCCESS';
export const COMMENT_UPDATE_SUCCESS = 'COMMENT_UPDATE_SUCCESS';
export const COMMENT_UPDATE_ERROR = 'COMMENT_UPDATE_ERROR';

interface ICommentsCommon {
  meta: {
    postId: Id;
    commentId: Id;
  };
}

export interface ICommentsDeleteError extends ICommentsCommon {
  type: typeof COMMENT_DELETE_ERROR;
}

export interface ICommentsDeleteSuccess extends ICommentsCommon {
  payload: ICommentPayload | IDeletedCommentPayload;
  type: typeof COMMENT_DELETE_SUCCESS;
}

export const deleteComment = (commentId: string, postId: string, url: string): IAction => {
  return {
    meta: {
      commentId,
      postId,
    },
    payload: api.comment.delete(url),
    type: COMMENT_DELETE,
  };
};

export interface ICommentsUpdateError extends ICommentsCommon {
  type: typeof COMMENT_UPDATE_ERROR;
}

export interface ICommentsUpdateSuccess extends ICommentsCommon {
  payload: ICommentPayload;
  type: typeof COMMENT_UPDATE_SUCCESS;
}

export const updateComment = (commentId: string, postId: string, updateUrl: string, message: string,
                              addedImages: string[], deletedImagesIds: string[], linkPreviewId?: string): IAction => {
  const config: ICommentUpdate = {
    addedImages: addedImages.length > 0 ? addedImages : undefined,
    deletedImagesIds: deletedImagesIds.length > 0 ? deletedImagesIds : undefined,
    linkPreviewId,
    message,
    path: updateUrl,
  };
  return {
    meta: {
      commentId,
      postId,
    },
    payload: api.comment.update(config),
    type: COMMENT_UPDATE,
  };
};

export interface ICommentsCreateSuccess {
  meta: {
    id: Id;
  };
  payload: ICommentPayload;
  type: typeof COMMENT_CREATE_REQUEST_SUCCESS;
}

export const createComment = (message: string, id: string, url: string,
                              images?: string[], linkPreviewId?: string) => ({
  meta: {
    id,
  },
  payload: api.comment.create({ images, linkPreviewId, message, path: url }),
  type: COMMENT_CREATE_REQUEST,
});

export interface ICommentsFetchSuccess {
  meta: {
    id: Id;
  };
  payload: ICommentsPayload;
  type: typeof COMMENTS_REQUEST_SUCCESS;
}

export const fetchComments = (id: string, path: string): IAction => ({
  meta: {
    id,
  },
  payload: api.comment.list(path),
  type: COMMENTS_REQUEST,
});
