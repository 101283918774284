// libs
import classNames from 'classnames';
import * as React from 'react';
import { IconSize } from 'src/components/forms/icon/icon';

// interfaces / constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import { COLOR_TYPE_WHITE, ColorType } from 'src/utils/color';

// components
import RoundIcon from 'src/components/forms/round_icon/round_icon';

// helpers
import './segment_round_icon.scss';

interface IProps {
  name: string;
  color?: ColorType;
  backgroundColor?: ColorType;
  noHover?: boolean;
  isSmall?: boolean;
  isInverted?: boolean;
  hasMargin?: boolean;
  description?: string;
  customSize?: IconSize;
}

const cls = itemSegmentCls + 'round-icon';

const SegmentRoundIcon: React.FC<IProps> = ({
  name,
  color,
  backgroundColor,
  noHover,
  isSmall,
  isInverted,
  hasMargin,
  description,
  customSize,
}) => {
  const clsBlock = {
    [cls]: true,
    [cls + '--with-margin']: hasMargin,
    [`${cls}--no-hover`]: noHover,
  };

  return (
    <div className={classNames(clsBlock)}>
      {isInverted
        ? <div className={cls + '--inverted'}>
          <RoundIcon
            name={name}
            backgroundColor={backgroundColor || color}
            iconColor={COLOR_TYPE_WHITE}
            size={customSize || (isSmall ? 18 : 24)}
            backgroundSize={isSmall ? 32 : 48}
            description={description}
          />
        </div>
        : <div className={classNames(`${cls}--standard`, { [`${cls}--standard--no-hover`]: noHover },)}>
          <RoundIcon
            name={name}
            backgroundColor={backgroundColor}
            iconColor={color}
            size={customSize || (isSmall ? 18 : 24)}
            backgroundSize={isSmall ? 32 : 40}
            description={description}
          />
        </div>
      }
    </div>
  );
};

export default SegmentRoundIcon;
