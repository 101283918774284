// libs
import * as React from 'react';

// classes
import InputArea from 'src/components/forms/input_area/input_area';
import SelectField, { ISelectOption } from 'src/components/forms/select-field/select-field';
import WizardButton, {
  WIZARD_BUTTON_TYPE_CANCEL,
  WizardGoToButton,
} from 'src/components/wizard/wizard_button/wizard_button';
import WizardStep, {
  WIZARD_LAYOUT_MODAL,
} from 'src/components/wizard/wizard_step/wizard_step';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import {
  FLAG_REASON_ABUSIVE,
  FLAG_REASON_OTHER,
  FLAG_REASON_SPAM,
  FLAG_MINIMUM_QUERY_LENGTH,
} from 'src/constants/flag';
import { SUBMIT_PATH } from 'src/constants/route_constants';
import { FlagReason, IFlagCreateData } from 'src/interfaces/flag';

// helpers
import Translate, { textResources } from 'src/lang/de';
import { validateFlagMessage } from 'src/utils/validation';
import './write_flag.scss';

interface IProps {
  onEnd: () => void;
  setData: (data: IFlagCreateData) => void;
}

const cls = CLASS_PREFIX + 'write-flag';
const labels = textResources.createFlag;

export default class WriteFlag extends React.PureComponent<IProps, IFlagCreateData> {
  private reasons: ISelectOption[];

  public constructor(props: IProps) {
    super(props);
    this.onRightClick = this.onRightClick.bind(this);
    this.save = this.save.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.state = {
      message: '',
      reason: 'other',
    };

    this.reasons = [
      {
        text: Translate.createFlagReasons[FLAG_REASON_OTHER],
        value: FLAG_REASON_OTHER,
      },
      {
        text: Translate.createFlagReasons[FLAG_REASON_SPAM],
        value: FLAG_REASON_SPAM,
      },
      {
        text: Translate.createFlagReasons[FLAG_REASON_ABUSIVE],
        value: FLAG_REASON_ABUSIVE,
      },
    ];
  }

  public render() {
    const { message, reason } = this.state;
    const isValid = validateFlagMessage(message);
    const leftButton = <WizardButton onClick={this.props.onEnd} textType={WIZARD_BUTTON_TYPE_CANCEL} />;
    const rightButton = <WizardGoToButton path={SUBMIT_PATH} callback={this.onRightClick} isValid={isValid} />;

    return (
      <WizardStep
        headerText={labels.title}
        leftButton={leftButton}
        rightButton={rightButton}
        layout={WIZARD_LAYOUT_MODAL}
      >
        <div className={cls}>
          <p className={`${cls}__text`}>
            {labels.text}
          </p>
          <SelectField
            label={labels.reason}
            options={this.reasons}
            required={true}
            value={reason}
            onChange={this.handleReasonChange}
          />
          <InputArea
            assistiveText={textResources.shared.inputTooShort(FLAG_MINIMUM_QUERY_LENGTH)}
            errorText={textResources.shared.inputTooShort(FLAG_MINIMUM_QUERY_LENGTH)}
            value={message}
            name='message'
            required={true}
            onChange={this.handleMessageChange}
            placeholder={labels.messagePlaceholder}
            initialRows={4}
            expandable={false}
          />
        </div>
      </WizardStep>
    );
  }

  private handleReasonChange(reason: FlagReason) {
    this.setState({ reason });
  }

  private handleMessageChange(value: string) {
    this.setState({ message: value });
  }

  private onRightClick() {
    this.save();
  }

  private save() {
    const { setData } = this.props;
    const { message, reason } = this.state;
    if (message && reason) {
      setData({ message, reason });
    }
  }
}
