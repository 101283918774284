import * as Response from 'src/api/interfaces/responses/tags';
import { IProfileTag, ITag } from 'src/interfaces/tags';

export const $profileTags = (json: Response.IProfileTag[]): IProfileTag[] => {
  return json.map((tag) => ({
    name: tag.tag.name,
    profileTagId: tag.identifier,
    tagId: tag.tag.identifier,
  }));
};

export const $tagsList = (json: Response.ITagsList): ITag[] => {
  return json.data.map((tag) => ({
    name: tag.name,
    tagId: tag.identifiers.identifier,
  }));
};

export const $addTag = (json: Response.IAddTag): IProfileTag | undefined => {
  const tags = json.data.taggable.taggings;
  if (!tags) {
    return;
  }
  const lastTag = tags[tags.length - 1];
  return {
    name: lastTag.tag.name,
    profileTagId: lastTag.identifier,
    tagId: lastTag.tag.identifier,
  };
};
