import React from 'react';

// components
import FollowButton, { IProps } from 'src/containers/smart_components/follow_button/follow_button';

const ItemSegmentFollowButton: React.FC<IProps> = (props) => (
  <FollowButton {...props} />
);

export default ItemSegmentFollowButton;
