// libs
import * as React from 'react';

// helper
import { CLASS_PREFIX } from 'src/constants';
import './logo.scss';

// svg
import logoHorizontal from './logo-horizontal.svg';
import logoNoText from './logo-no-text.svg';
import logoVertical from './logo-vertical.svg';
import logoWhite from './logo-white.svg';

const LOGOS = {
  HORIZONTAL: logoHorizontal,
  NO_TEXT: logoNoText,
  VERTICAL: logoVertical,
  WHITE: logoWhite,
};

export type ILogos = typeof LOGOS;

interface IProps {
  type: keyof ILogos;
  altText?: string;
}

const cls = CLASS_PREFIX + 'logo';

const Logo = ({ type, altText }: IProps) => (
  <img className={cls} src={LOGOS[type]} alt={altText || ''} />
);

export default Logo;
