import { LatLng, LatLngBounds } from 'leaflet';

export const CENTER_OF_GERMANY_POSITION = {
  latitude: 51.163375,
  longitude: 10.447683333333,
};

export const MAP_TOKEN =
  'pk.eyJ1IjoibG9rYWxwb3J0YWwiLCJhIjoiY2ltNjRwYTdtMDAzeHh3a204NTZyNHJhbCJ9.8701BepMtE87iD0GxNv3uA';

export const MAP_PATH_OPTIONS = {
  color: '#989898', // stroke (border) color, $color-gray-tint-2
  fillColor: '#737373', // $color-gray-tint-1
  fillOpacity: 0.4,
  interactive: false, // stroke opacity
  opacity: 1, // stroke width
  weight: 1,
};

// bounding box which covers Germany
export const MAP_OPTION_MAX_BOUNDS = new LatLngBounds(new LatLng(47.27021, 5.86624), new LatLng(55.05814, 15.04205));
export const MAP_OPTION_ATTRIBUTION_CONTROL = false;
export const MAP_ZOOM_STEP_SIZE = 1;
export const MAP_OPTION_ZOOM_CONTROL = false;
export const MAP_OPTION_TILE_SIZE = 512;
export const MAP_OPTION_ZOOM_OFFSET = -1;

export const MAP_ATTRIBUTION =
  '<a href="https://www.openstreetmap.org/about/" rel="noopener" target="_blank">© OpenStreetMap</a>';

// minimum distance (between old and new southwest or northeast map bounding box corners)
// when dragging or zooming the map to show the "search this area" button
export const MAP_DISTANCE_THRESHOLD = 1000; // m

// the default Mapbox style which will be used if no Lopo Admin toggle
// with the name `mapStyle` is found or active
export const MAP_DEFAULT_MAPBOX_STYLE = 'lokalportal/cimrip1px011fd4koxeic0sbk';

// the default zoom levels which will be used if no Lopo Admin toggle
// with the name `mapZoom` is found or active
export const MAP_OPTION_MAX_ZOOM = 16;
export const MAP_OPTION_MIN_ZOOM = 12;

export const MAP_LAT_OFFSET = 0.002;

export const MAP_DEFAULT_ZOOM = 13;
