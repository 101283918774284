import { isEqual } from 'lodash';

import { ILocation } from 'src/containers/location/location_interfaces';
import { IBoundingBox, ILocationShape, IPosition } from 'src/interfaces/location';

const isShapeDifferent = (routeShape?: ILocationShape, stateShape?: ILocationShape): boolean => {
  if (routeShape && !stateShape) {
    return true;
  }

  return !!routeShape && !!stateShape && (routeShape.identifier !== stateShape.identifier);
};

const isPositionDifferent = (routePosition?: IPosition, statePosition?: IPosition): boolean => {
  return !!routePosition && !isEqual(routePosition, statePosition);
};

const isBoundingBoxDifferent = (routeBoundingBox?: IBoundingBox, stateBoundingBox?: IBoundingBox): boolean => {
  return !!routeBoundingBox && !isEqual(routeBoundingBox, stateBoundingBox);
};

export const isLocationDifferent = (routeLocation: ILocation = {}, stateLocation: ILocation = {}): boolean => {
  return isShapeDifferent(routeLocation.locationShape, stateLocation.locationShape) ||
    isPositionDifferent(routeLocation.position, stateLocation.position) ||
    isBoundingBoxDifferent(routeLocation.boundingBox, stateLocation.boundingBox);
};
