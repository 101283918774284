import classNames from 'classnames';
import * as React from 'react';

// constant
import { CLASS_PREFIX } from 'src/constants/';

// interfaces
import { IAutocompleteTypes } from 'src/api/google/interfaces/requests';
import { IErrorResponse } from 'src/api/interfaces/errors';
import { IKeyValue } from 'src/interfaces/';
import { IAddress } from 'src/interfaces/address';
import { IPosition } from 'src/interfaces/location';
import { IGooglePrediction } from 'src/interfaces/map';

// components
import ActionList from 'src/components/location_picker/actions/action_list';
import GoogleLocationSearch from 'src/components/location_picker/google/location_search';
import PopularPlaces from 'src/components/location_picker/popular/popular_places';
import SnackBar from 'src/components/snack_bar/snack_bar';

// helper
import api, { IGeoLookupParams, IPlaceLookupParam } from 'src/api/index';
import { ADDRESS_NOT_FOUND, AREA_NOT_RELEASED, INVALID_PARAMETERS } from 'src/constants/api_error_codes';
import Translate, { textResources } from 'src/lang/de';
import { createDateAddress, formatAddress } from 'src/utils/location';
import { getBrowserPosition } from 'src/utils/position';
import { reportOnlyErrorObject } from 'src/utils/reporting/report-errors';
import { toggleBodyScrolllock } from 'src/utils/scrolllock/scrolllock';
import './location_picker.scss';

const cls: string = CLASS_PREFIX + 'location-picker';
const labels = textResources.locationChanger;

interface IProps {
  validateAddress?: boolean;
  mapPosition?: IPosition;
  homeLocation?: IAddress;
  type?: IAutocompleteTypes;
  strictBounds?: boolean;
  close?: () => void;
  clear?: () => void;
  onLocateMe?: () => void;
  takeMeHome?: () => void;
  onChange?: (address: IAddress) => void;
  onPopularPlacesSelect?: () => void;
  showBackground?: boolean; // default: true
  showOnlyInputWhenNotFocused?: boolean; // default: false
  autoFocusSearchInput?: boolean; // default: true
  forceShowOnlyInput?: boolean; // default: false
  preFilledAddress? : IAddress;
  onFocus?: () => void;
  onQueryChange?: (query: string) => void;
  streetRequired?: boolean;
  searchTitle?: string;
  defaultAddress?: string;
  valid?: boolean;
  errorText?: string;
  label?: string;
  getRedirectPath?: (locationSlug?: string) => string;
  enableScroll?: boolean;
}

interface IState {
  validationMessage: string | null;
  isActionListVisible: boolean;
  query: string;
  autoSearch: boolean;
  showPopularPlaces: boolean;
}

class LocationPicker extends React.PureComponent<IProps, IState> {
  private inputRef: React.RefObject<GoogleLocationSearch>;
  private scrollRef = React.createRef<HTMLDivElement>();

  public constructor(props: IProps) {
    super(props);

    const {
      showOnlyInputWhenNotFocused = false,
    } = props;

    this.state = {
      autoSearch: true,
      isActionListVisible: !showOnlyInputWhenNotFocused,
      query: props.defaultAddress || '',
      showPopularPlaces: !showOnlyInputWhenNotFocused,
      validationMessage: null,
    };

    this.onSelectGooglePlace = this.onSelectGooglePlace.bind(this);
    this.onChangeQuery = this.onChangeQuery.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.searchHasResults = this.searchHasResults.bind(this);
    this.takeMeHome = this.takeMeHome.bind(this);
    this.takePrefilled = this.takePrefilled.bind(this);
    this.locateMe = this.locateMe.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);

    this.inputRef = React.createRef();
  }

  public componentDidMount() {
    this.props.enableScroll && toggleBodyScrolllock(true, this.scrollRef.current);
  }

  public componentWillUnmount() {
    this.props.enableScroll && toggleBodyScrolllock(false, this.scrollRef.current);
  }

  public render() {
    const {
      autoFocusSearchInput = true,
      clear,
      close,
      forceShowOnlyInput = false,
      mapPosition,
      onPopularPlacesSelect,
      preFilledAddress,
      homeLocation,
      label,
      searchTitle,
      showBackground = true,
      strictBounds,
      type,
      valid,
      errorText,
      getRedirectPath,
    } = this.props;

    const { autoSearch, validationMessage, isActionListVisible, query, showPopularPlaces } = this.state;
    const classes: IKeyValue = {};
    classes[cls] = true;
    classes[`${cls}--active`] = !forceShowOnlyInput && showPopularPlaces;
    classes[`${cls}--show-background`] = showBackground;

    return (
      <div ref={this.scrollRef} className={classNames(classes)}>
        {validationMessage && <SnackBar message={validationMessage} onClose={this.closeSnackBar} />}
        <div className={`${cls}__content`}>
          <GoogleLocationSearch
            autoFocus={autoFocusSearchInput}
            autoSearch={autoSearch}
            mapPosition={mapPosition}
            onChangeQuery={this.onChangeQuery}
            onSelect={this.onSelectGooglePlace}
            onFocus={this.onFocus}
            onHasResults={this.searchHasResults}
            clear={clear}
            close={close}
            label={label}
            type={type}
            query={query}
            searchTitle={searchTitle}
            strictBounds={strictBounds}
            ref={this.inputRef}
            valid={valid}
            errorText={errorText}
          />

          {!forceShowOnlyInput && isActionListVisible ? (
            <ActionList
              takeMeHome={this.takeMeHome}
              locateMe={this.locateMe}
              takePrefilled={this.takePrefilled}
              preFilledFormatedAddress={preFilledAddress && formatAddress(preFilledAddress)}
              showTakeMeHome={homeLocation !== undefined}
            />
          ) : null}

          {!forceShowOnlyInput && showPopularPlaces ? (
            <div className={`${cls}__popular-places`}>
              <PopularPlaces callback={onPopularPlacesSelect} getRedirectPath={getRedirectPath} />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  private closeSnackBar() {
    this.setState({ validationMessage: null });
  }
  private onSelectGooglePlace(prediction: IGooglePrediction) {
    const { validateAddress, streetRequired } = this.props;

    this.setState({
      isActionListVisible: false,
      query: prediction.description,
      showPopularPlaces: false,
    });
    if (validateAddress) {
      api.post.isAllowedToPostHere({ data: prediction.placeId })
        .then((address: IAddress) => this.clearErrorAndChangeAddress(address))
        .catch((error: IErrorResponse) => {
          this.inputRef.current && this.inputRef.current.focusInput();
          this.setValidationMessage(error);
        });
    } else {
      this.getValidAddressFromAPIByPlaceID(prediction.placeId, streetRequired || false)
        .then((address: IAddress[] | null) => address && this.clearErrorAndChangeAddress(address[0]))
        .catch((error: IErrorResponse) => {
          this.inputRef.current && this.inputRef.current.focusInput();
          this.setState({ validationMessage: this.getAddressLookUpErrorMessage(error) });
        });
    }
  }

  private getAddressLookUpErrorMessage(error: IErrorResponse): string {
    if (error.code === ADDRESS_NOT_FOUND) {
      reportOnlyErrorObject(error);
      return labels.addressNotFound;
    } else if (error.code === AREA_NOT_RELEASED) {
      return labels.areaNotReleased;
    } else if (error.code === INVALID_PARAMETERS) {
      reportOnlyErrorObject(error);
      return labels.invalidParameters;
    }
    reportOnlyErrorObject(error, { description: 'unexpected error code' });
    return textResources.shared.errorUnknown;
  }

  private searchHasResults(value: boolean) {
    this.setState({
      isActionListVisible: !value,
    });
  }

  private locateMe() {
    const { onLocateMe, validateAddress, streetRequired } = this.props;

    onLocateMe && onLocateMe();

    // TODO: show loading spinner to the user while fetching address from the browser
    getBrowserPosition((position: Position) => {
      const pos: IPosition = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };

      if (validateAddress) {
        api.post.isAllowedToPostHere({ data: pos })
          .then((address: IAddress) => this.clearErrorAndSetQueryAndChangeAddress(address))
          .catch((error: IErrorResponse) => {
            this.setValidationMessage(error);
          });
      } else {
        this.getValidAddressFromAPIByPosition(pos, streetRequired || true)
          .then((address: IAddress[] | null) => address && this.clearErrorAndSetQueryAndChangeAddress(address[0]))
          .catch((error: IErrorResponse) => {
            reportOnlyErrorObject(error, { description: 'failed to get getByPlaceId response' });
            this.setState({ validationMessage: this.getAddressLookUpErrorMessage(error) });
          });
      }
    });
  }

  private takeMeHome() {
    const { homeLocation, onChange, takeMeHome } = this.props;
    this.setState({ isActionListVisible: false, showPopularPlaces: false, validationMessage: null });

    if (takeMeHome !== undefined) {
      return takeMeHome();
    }

    if (homeLocation === undefined || onChange === undefined) {
      return;
    }

    onChange(homeLocation);
  }

  private takePrefilled() {
    const { preFilledAddress, onChange } = this.props;
    this.setState({ isActionListVisible: false, showPopularPlaces: false, validationMessage: null });
    onChange && preFilledAddress && onChange(preFilledAddress);
  }

  private getValidAddressFromAPIByPlaceID(placeID: string, streetRequired: boolean) {
    const params: IPlaceLookupParam = {
      onlyReleased: true,
      placeID,
      streetRequired,
    };
    return api.address.getAddressBy(params);
  }

  private getValidAddressFromAPIByPosition(position: IPosition, streetRequired: boolean) {
    const params: IGeoLookupParams = {
      onlyReleased: true,
      position,
      streetRequired,
    };
    return api.address.getAddressBy(params);
  }

  private onChangeQuery(query: string) {
    const { onQueryChange } = this.props;
    onQueryChange && onQueryChange(query);
    this.setState({ autoSearch: true, query });
  }

  private onFocus() {
    this.setState({ isActionListVisible: true, showPopularPlaces: true });

    const { onFocus } = this.props;
    if (onFocus !== undefined) {
      onFocus();
    }
  }

  private clearErrorAndChangeAddress(address: IAddress | null) {
    const { onChange } = this.props;

    this.setState({ validationMessage: null });

    if (address && onChange) {
      onChange(address);
    }
  }

  private clearErrorAndSetQueryAndChangeAddress(address: IAddress | null) {
    const { onChange } = this.props;

    this.setState({ validationMessage: null });

    if (address) {
      const streetWithDistrict = createDateAddress(address.street, address.cityWithDistrict);
      if (streetWithDistrict !== '') {
        this.setState({ query: streetWithDistrict });
      }
      if (onChange) {
        this.setState({ isActionListVisible: false, showPopularPlaces: false });
        onChange(address);
      }
    }
  }

  private setValidationMessage(error: IErrorResponse) {
    switch (error.code) {
      case 'address_not_found':
      case 'area_not_released':
      case 'invalid_parameters':
      case 'out_of_reach':
        return this.setState({ validationMessage: Translate.postCreateAddressValidationError[error.code] });
      default:
        return this.setState({ validationMessage: Translate.postCreateAddressValidationError.default });
    }
  }
}

export default LocationPicker;
