export const setIframeHeightFromItsContent = (height: number, id: string) => {
  const element = document.getElementById(id);
  if (
    Number.isNaN(height) ||
    !element
  ) {
    return;
  }
  element.setAttribute('height', `${height}`);
};

export const STRAWPOLL_EVENT_MESSAGES = ['strawpoll_resize'];
