export const FEED_DEFAULT_PER_PAGE = 15;
export const FEED_WITH_GROUPED_POSTS_DEFAULT_PER_PAGE = 20;
export const FEED_NUMBER_OF_POSTS_IN_VIEWPORT = 10;
export const SCROLL_THRESHOLD_TO_FETCH_NEXT_PAGE = 500;
export const EVENT_FEED_DEFAULT_PER_CATEGORY = 10;

export const FEED_TYPE_NEWS = 'NEWS';
export const FEED_TYPE_EVENT = 'EVENT';
export const FEED_TYPE_EVENT_CATEGORIZED = 'EVENT_CATEGORIZED';
export const FEED_TYPE_PROFILE = 'PROFILE';
export const FEED_TYPE_LOCAL = 'LOCAL';
export const FEED_TYPE_GROUP = 'GROUP';
export const NOTIFICATIONS = 'NOTIFICATIONS';
export const PROFILES_DIRECTORY = 'PROFILES_DIRECTORY';
export const FEED_TYPE_EVENT_RELATED = 'EVENT_RELATED';
export const FEED_TYPE_EVENT_PARTICIPATING = 'EVENT_PARTICIPATING';
export const FEED_TYPE_DETAILS_PAGE = 'FEED_TYPE_DETAILS_PAGE';
export const FEED_TYPE_GROUPS_RECENT = 'GROUPS_RECENT';
export const FEED_TYPE_FOLLOWEEES_RECENT = 'FOLLOWEEES_RECENT';

export const FEED_POST_TYPE_SNAPSHOT = 'snapshot';
export const FEED_POST_TYPE_POST = 'post';
export const FEED_POST_TYPE_SURVEY = 'survey';
export const FEED_POST_TYPE_EVENT = 'event';
export const FEED_POST_TYPE_PUBLIC_POST = 'publicPost';
export const FEED_POST_TYPE_PRIVATE_POST = 'privatePost';
export const FEED_POST_TYPE_SNAPSHOT_POST = 'snapshot';
export const GROUP_OVERVIEW = 'GROUP_OVERVIEW';

export const QUICK_DATE_FROM_TODAY = 'fromToday';
export const QUICK_DATE_FROM_TOMORROW = 'fromTomorrow';
export const QUICK_DATE_ON_WEEKEND = 'onWeekend';

export const FILTER_EVENT_DEFAULT = 'event_default';
