import React from 'react';

// component
import MenuItemSegment from 'src/components/item_segment/layouts/menu/menu';
import ToggleItemSegment from 'src/components/item_segment/layouts/toggle/toggle';

// interface
import { FollowParams } from 'src/api';

// helpers
import { useUpdateFollow } from 'src/actions/follow/follow_hooks';
import { textResources } from 'src/lang/de';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { COLOR_TYPE_BLACK } from 'src/utils/color';
import initTracker, { FOLLOW_BUTTON } from 'src/utils/reporting/events_tracking/events_tracking';

import './follow_popover_menu.scss';

interface IProps {
  hide: () => void;
  showCancelModal: (visible: boolean) => void;
  paramToAction: FollowParams;
  isNotifying: boolean;
}

const wrapperCls = 'follow_popover_menu';
const cls = createBemBlock(wrapperCls);

const label = textResources.follow;

const tracker = initTracker(FOLLOW_BUTTON);

const FollowButtonMenu: React.FC<IProps> = ({ showCancelModal, paramToAction, isNotifying, hide }) => {
  const updateFollow = useUpdateFollow();

  const toggleNotification = () => {
    tracker(FOLLOW_BUTTON.ACTIONS.SUBSCRIPTION, FOLLOW_BUTTON.LABELS.START);
    updateFollow({
      ...paramToAction,
      notification: !isNotifying,
    });
  };

  const notificationText = label[isNotifying ? 'turnOffNotifications' : 'turnOnNotifications'];
  return (
    <div className={cls()}>
      <MenuItemSegment
        label={label.unfollow}
        navigation={{ onClick: () => { showCancelModal(true); hide(); } }}
        icon={{ name: 'minus-circle' }}
      />
      <ToggleItemSegment
        label={notificationText}
        isChecked={isNotifying}
        onChange={toggleNotification}
        icon={{
          color: COLOR_TYPE_BLACK,
          name: 'bell-new',
        }}
      />
    </div>
  );
};

export default FollowButtonMenu;
