// libs
import { flowRight } from 'lodash';
import { connect } from 'react-redux';

// interfaces / constants
import { ICommentItem } from 'src/interfaces/comments';
import { IComments } from 'src/interfaces/posts';
import { SponsorLevel } from 'src/interfaces/profile';
import { IRootState } from 'src/reducers/interface';

// classes / components
import { Comments } from 'src/components/post/shared/comments/comments';
import withLoadingSpinner from 'src/high_order_components/with_loading_spinner';

// helpers
import { createComment, deleteComment, fetchComments, updateComment } from 'src/actions/comments';
import { updateCommentReaction } from 'src/actions/reaction';
import { ITrackingProps, withTrackerProps } from 'src/high_order_components/with_tracker_props';
import initTracker, { COMMENTS, CommentsTrackerObj } from 'src/utils/reporting/events_tracking/events_tracking';

interface IOwnProps extends IComments {
  disabled: boolean;
  id: string;
  commentable: boolean;
}

interface IMapStateToProps {
  comments: ICommentItem[];
  sponsorLevel?: SponsorLevel;
  userImageUrl: string;
}

interface IDispatchFromProps {
  deleteComment: (commentId: string, postId: string, url: string) => void;
  getComments: (id: string, url: string) => void;
  likeToggle: (postId: string, commentId: string, updateUrl: string) => void;
  submitComment: (message: string, id: string, url: string, images?: string[], linkPreviewId?: string) => void;
  updateComment: (
    commentId: string,
    postId: string,
    updateUrl: string,
    message: string,
    addedImages: string[],
    deletedImagesIds: string[],
    linkPreviewId?: string,
  ) => void;
}

export type IProps = IMapStateToProps & IDispatchFromProps & IOwnProps & ITrackingProps<CommentsTrackerObj>;

const tracker = initTracker(COMMENTS);

const mapStateToProps = (state: IRootState, ownProps: IOwnProps): IMapStateToProps => {
  const user = state.user.profiles[state.user.selectedProfileId];
  const sponsorLevel = state.profiles[`${user.type}#${user.id}`]?.profile.sponsorLevel;
  return {
    comments: state.comments[ownProps.id] || [],
    sponsorLevel,
    userImageUrl: user.avatar ? user.avatar.url : '',
  };
};

const mapDispatchToProps = {
  deleteComment: (commentId: string, postId: string, url: string) => {
    tracker(COMMENTS.ACTIONS.DELETE, COMMENTS.LABELS.FINISH);
    return deleteComment(commentId, postId, url);
  },
  getComments: (id: string, url: string) => fetchComments(id, url),
  likeToggle: (postId: string, commentId: string, updateUrl: string) => {
    tracker(COMMENTS.ACTIONS.LIKE, COMMENTS.LABELS.FINISH);
    return updateCommentReaction(postId, commentId, updateUrl);
  },
  submitComment: (message: string, id: string, url: string, images?: string[], linkPreviewId?: string) => {
    tracker(COMMENTS.ACTIONS.WRITE, COMMENTS.LABELS.FINISH);
    return createComment(message, id, url, images, linkPreviewId);
  },
  updateComment: (
    commentId: string,
    postId: string,
    updateUrl: string,
    message: string,
    addedImages: string[],
    deletedImagesIds: string[],
    linkPreviewId?: string,
  ) => {
    tracker(COMMENTS.ACTIONS.UPDATE, COMMENTS.LABELS.FINISH);
    return updateComment(commentId, postId, updateUrl, message, addedImages, deletedImagesIds, linkPreviewId);
  },
};

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withTrackerProps(COMMENTS),
  withLoadingSpinner,
)(Comments);
