// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import Button, { BtnVariant, IBtnType } from 'src/components/forms/button/button';
import FieldSet, { FIELDSET_TYPE_SMALL_SPACE } from 'src/components/forms/field_set/field_set';
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import { ColorType } from 'src/utils/color';
import * as adminSucessScreen from './img/admin_success_screen.svg';
import * as chat from './img/chat.svg';
import * as completeProfile from './img/complete_profile.svg';
import * as completeVerification from './img/complete_verification.svg';
import * as confirmMail from './img/confirm_mail.svg';
import * as feedbackSuccess from './img/feedback_success.svg';
import * as newUser from './img/global_cta.svg';
import * as groupCreate from './img/group_create.svg';
import * as lopo from './img/lopo_success.svg';
import * as paperPlain from './img/paper_plain.svg';
import * as password from './img/password.svg';
import * as postBox from './img/post_box.svg';
import * as report from './img/report.svg';
import * as spreadSuccess from './img/spread_success.svg';
import * as success from './img/success.svg';

// styles
import { textResources } from 'src/lang/de';
import './message_segment.scss';

const cls = CLASS_PREFIX + 'message-segment';

export type IconType = 'complete-profile'
| 'complete-verification'
| 'confirm-email'
| 'feedback-success'
| 'new-user'
| 'password'
| 'paper-plain'
| 'post-box'
| 'report'
| 'spread-success'
| 'success'
| 'chat'
| 'admin-success-screen'
| 'lopo'
| 'group-create'
;

type IIconTypes = {[key in IconType]: string};

interface IProps {
  headline?: string;
  headerIcon?: IconType;
  title?: string;
  message: React.ReactChild;
  buttons?: ICTAButton[];
}

export interface ICTAButton {
  color?: ColorType;
  label: string;
  onClick?: (event: React.SyntheticEvent<HTMLButtonElement>) => void;
  type?: IBtnType;
  variant?: BtnVariant;
}

export default class MessageSegment extends React.PureComponent<IProps, {}> {
  private headerIconMap: IIconTypes = {
    'admin-success-screen': adminSucessScreen,
    'chat': chat,
    'complete-profile': completeProfile,
    'complete-verification': completeVerification,
    'confirm-email': confirmMail,
    'feedback-success': feedbackSuccess,
    'group-create': groupCreate,
    'lopo': lopo,
    'new-user': newUser,
    'paper-plain': paperPlain,
    'password': password,
    'post-box': postBox,
    'report': report,
    'spread-success': spreadSuccess,
    'success': success,
  };
  public render() {
    const { buttons, headline, headerIcon, message, title } = this.props;
    const clsImg = cls + '__img-inner';
    const clsMessage = {
      [cls + '__message']: true,
      [cls + '__message--no-buttons']: !buttons,
    };
    return (
      <div className={cls}>
        {headerIcon &&
        <div className={cls + '__img'}>
          <img className={clsImg} src={this.headerIconMap[headerIcon]} alt={textResources.imageAlt.headerImage} />
        </div>
        }
        {headline && <h3 className={cls + '__headline'}> {headline} </h3>}
        {title && <h3 className={cls + '__title'}> {title} </h3>}
        <div className={classNames(clsMessage)}>
          {message}
        </div>
        {buttons &&
        <FieldSet type={FIELDSET_TYPE_SMALL_SPACE}>
          {buttons.map((button: ICTAButton, index: number) => (
            <Button
              color={button.color}
              fullWidth
              key={index}
              label={button.label}
              lowerCase
              onClick={button.onClick}
              type={button.type}
              variant={button.variant}
            />
          ))}
        </FieldSet>
        }
      </div>
    );
  }
}
