// libs
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { blacklistProfile, graylistProfile, fetchProfile } from 'src/actions/profile/profile';
import { Id } from 'src/interfaces';
import { ProfileType } from 'src/interfaces/profile';

export const useBlacklistProfile = () => {
  const dispatch = useDispatch();
  return useCallback((path: string) => {
    dispatch(blacklistProfile(path));
  }, [dispatch]);
};

export const useGraylistProfile = () => {
  const dispatch = useDispatch();
  return useCallback((path: string, id: Id, expiresOn: Date) => {
    dispatch(graylistProfile(path, id, expiresOn));
  }, [dispatch]);
};

export const useFetchProfile = () => {
  const dispatch = useDispatch();
  return useCallback((profileType: ProfileType, profileId: string) => {
    dispatch(fetchProfile(profileType, profileId));
  }, [dispatch]);
};
