import moment from 'moment';
import 'moment/locale/de';

import {
  QUICK_DATE_FROM_TODAY,
  QUICK_DATE_FROM_TOMORROW,
  QUICK_DATE_ON_WEEKEND,
} from 'src/constants/feed';
import Translate, { textResources } from 'src/lang/de';
import DateHelper from 'src/utils/date_helper/date';

export type ProcessableDate = string | Date;
type ICalendarWeeks = string[][];

const labels = textResources.dateFormat;

moment.locale('de');
moment.updateLocale('de', {
  relativeTime: {
    future: (relTime: string) => labels.future(relTime),
    past: (relTime: string) => labels.past(relTime),
    s: labels.justNow,
  },
});

export default class DateFormatter {
  public static addTimeToStartOfDay(
    number1: moment.DurationInputArg1,
    duration: moment.DurationInputArg2,
    number2?: moment.DurationInputArg1,
  ) {
    if (number2) {
      return moment().startOf('day').add(number1, duration).add(number2, 'hours').toISOString();
    }
    return moment().startOf('day').add(number1, duration).toISOString();
  }

  public static asCalendarMonth(date: ProcessableDate): string {
    return moment(this.toDate(date)).format('MMMM YYYY');
  }

  public static asDay(date: ProcessableDate): string {
    const formatString: string = 'DD';
    return moment(this.toDate(date)).format(formatString);
  }

  public static asDayMonthYear(date: ProcessableDate): string {
    const formatString: string = 'DD. MMMM YYYY';
    return moment(this.toDate(date)).format(formatString);
  }

  public static asDayMonthYearLong(date: ProcessableDate): string {
    const formatString: string = 'dddd, DD. MMM YYYY';
    return moment(this.toDate(date)).format(formatString);
  }

  public static asDayMonthYearShort(date: ProcessableDate): string {
    const formatString: string = 'DD.MM.YY';
    return moment(this.toDate(date)).format(formatString);
  }

  public static asMonth(date: ProcessableDate): string {
    const formatString: string = 'MMM';
    const monthDotRemoved = moment(this.toDate(date)).format(formatString).split('.')[0];
    return monthDotRemoved;
  }

  public static asMonthDay(date: ProcessableDate): string {
    const formatString: string = 'MMM DD.';
    return moment(this.toDate(date)).format(formatString);
  }

  public static asMonth2Digit(date: ProcessableDate): string {
    return moment(this.toDate(date)).format('M');
  }

  public static asTime(date: ProcessableDate): string {
    const formatString: string = 'HH:mm';
    return moment(this.toDate(date)).format(formatString);
  }

  public static asTimeAgo(date: ProcessableDate, withoutPrefix?: boolean): string {
    const processedDate: Date = DateFormatter.toDate(date);
    return moment(processedDate).fromNow(withoutPrefix);
  }

  public static asYear(date: ProcessableDate): string {
    return moment(this.toDate(date)).format('YYYY');
  }

  public static calendarFromDay(date: ProcessableDate) {
    return moment(date).calendar(undefined, {
      lastDay: `[${labels.yesterday}]`,
      lastWeek: `[${labels.last}] dddd`,
      nextDay: `[${labels.tomorrow}]`,
      nextWeek: `dddd`,
      sameDay: `[${labels.today}]`,
      sameElse: 'dddd, DD. MMM YYYY',
    });
  }

  public static calendarFromDayWithFullDate(date: ProcessableDate) {
    return moment(date).calendar(undefined, {
      lastDay: `[${labels.yesterday}], DD. MMM YYYY`,
      lastWeek: `[${labels.last}] dddd, DD. MMM YYYY`,
      nextDay: `[${labels.tomorrow}], DD. MMM YYYY`,
      nextWeek: `dddd, DD. MMM YYYY`,
      sameDay: `[${labels.today}], DD. MMM YYYY`,
      sameElse: 'dddd, DD. MMM YYYY',
    });
  }

  public static calendarFromDayWithShortDate(date: ProcessableDate) {
    return moment(date).calendar(undefined, {
      lastDay: `[${labels.yesterday}]`,
      lastWeek: 'dddd, DD.MM.YYYY',
      nextDay: `[${labels.tomorrow}]`,
      nextWeek: 'dddd, DD.MM.YYYY',
      sameDay: `[${labels.today}]`,
      sameElse: 'dddd, DD.MM.YYYY',
    });
  }

  public static dateInDatePickerFormat(date: ProcessableDate | moment.Moment) {
    return moment(date).format('YYYY-MM-DD');
  }

  public static dateToDDMMYYYY(date: ProcessableDate) {
    return moment(date).format('DD.MM.YYYY').toString();
  }

  // Used by the conversation-feature
  public static dateToShortcut(date: ProcessableDate): string {
    if (DateHelper.isToday(date)) {
      return labels.today;
    }

    if (DateHelper.isYesterday(date)) {
      return labels.yesterday;
    }

    if (DateHelper.isTomorrow(date)) {
      return labels.tomorrow;
    }

    return DateFormatter.asDayMonthYearLong(date);
  }

  public static endOfDay(date: ProcessableDate) {
    return moment(this.toDate(date)).endOf('day').toISOString(true);
  }

  public static getDatesFormatted(startDate: ProcessableDate, endDate?: ProcessableDate) {
    if (!endDate) {
      if (DateHelper.isYesterday(startDate)) {
        // 'Gestern, 26. September 2019'
        return labels.around(
          labels.yesterday,
          moment(startDate).format('DD. MMMM YYYY'),
        );
      }

      if (DateHelper.isToday(startDate)) {
        // 'Heute, 26. September 2019'
        return labels.around(
          labels.today,
          moment(startDate).format('DD. MMMM YYYY'),
        );
      }

      if (DateHelper.isTomorrow(startDate)) {
        // 'Morgen, 26. September 2019'
        return labels.around(
          labels.tomorrow,
          moment(startDate).format('DD. MMMM YYYY'),
        );
      }

      // 'Mo., 23. September 2019'
      return moment(startDate).format('dd., DD. MMMM YYYY');
    }

    if (moment(startDate).isSame(endDate, 'year')) {
      // '17. Sep. - 18. Sep. 2019'
      return labels.between(
        moment(startDate).format('DD. MMM'),
        moment(endDate).format('DD. MMM YYYY'),
      );
    }

    // '27. Sep. 2019 - 18. Jan. 2020'
    return labels.between(
      moment(startDate).format('DD. MMM YYYY'),
      moment(endDate).format('DD. MMM YYYY'),
    );
  }

  public static formatDateReadable(
    startDate: ProcessableDate,
    endDate?: ProcessableDate,
    dateFormat: string = 'DD.MM.',
  ) {
    if (endDate && startDate !== endDate) {
      if (DateHelper.isUpcomingWeekend(startDate) && DateHelper.isUpcomingWeekend(endDate)) {
        return Translate.datePickerQuickDate[QUICK_DATE_ON_WEEKEND];
      }

      const startDateFormatted = moment(startDate).format(dateFormat);
      const endDateFormatted = moment(endDate).format(dateFormat);

      return `${startDateFormatted} ${labels.to} ${endDateFormatted}`;
    }

    if (DateHelper.isToday(startDate)) {
      return Translate.datePickerQuickDate[QUICK_DATE_FROM_TODAY];
    }

    if (DateHelper.isTomorrow(startDate)) {
      return Translate.datePickerQuickDate[QUICK_DATE_FROM_TOMORROW];
    }

    return moment(startDate).format(dateFormat);
  }

  public static formatEventShortDate(startTime: ProcessableDate, endTime?: ProcessableDate): string {
    const startHour = `${moment(startTime).format('H:mm')} ${labels.clock}`;
    const endHour = `${moment(endTime).format('H:mm')} ${labels.clock}`;
    const startDate = this.numericalDateToWords(startTime);
    const endDate = this.numericalDateToWords(endTime);

    if (!endTime) {
      return `${startDate} ${labels.at} ${startHour}`;
    } else if (moment(startTime).isSame(endTime, 'day')) {
      return `${startDate} ${labels.at} ${startHour} - ${endHour}`;
    } else if (moment().isBetween(startTime, endTime)) {
      return labels.ongoing;
    } else {
      return `
        ${startDate} ${labels.at} ${startHour} -
        ${endDate} ${labels.at} ${endHour}`
      ;
    }
  }

  public static getEndTimeFromStartTime(startTime: string, duration: moment.Duration) {
    return moment(startTime).add(duration).toISOString();
  }

  public static getInYears(years: number) {
    return moment().add(years || 1, 'year').startOf('day').toISOString(true);
  }

  public static getMonths(): string[] {
    return moment.localeData('de').months();
  }

  public static getNextDay(date: ProcessableDate) {
    return moment(date).add(1, 'day').toISOString(true);
  }

  public static getNextHour(time: string) {
    return moment(time, 'HH:mm').add(1, 'hour').format('HH:mm');
  }

  public static getNextWeekendStart() {
    const weekdayCount = moment().isoWeekday();
    return moment().add(5 - weekdayCount, 'days').startOf('day').add(15, 'hours').toISOString(true);
  }

  public static getNextWeekendEnd() {
    const weekdayCount = moment().isoWeekday();
    return moment().add(7 - weekdayCount, 'days').endOf('day').toISOString(true);
  }

  public static getOneHourAgo(): ProcessableDate {
    return moment().subtract(1, 'hour').format();
  }

  public static getPreviousDay(date: ProcessableDate) {
    return moment(date).subtract(1, 'day').toISOString(true);
  }

  public static getToday() {
    return moment().startOf('day').toISOString(true);
  }

  public static getTomorrow() {
    return moment().add(1, 'day').startOf('day').toISOString(true);
  }

  public static getWeeksForDate(date: ProcessableDate): ICalendarWeeks {
    const startDay = moment(date).startOf('month').startOf('isoWeek');
    const endDay = moment(date).endOf('month').endOf('isoWeek');

    const allDays = [];
    for (const day = moment(startDay); day.isBefore(endDay); day.add(1, 'days')) {
      allDays.push(day.toISOString(true));
    }

    const byWeek = [];
    while (allDays.length > 0) {
      byWeek.push(allDays.splice(0, 7));
    }

    return byWeek;
  }

  public static getYesterday(): ProcessableDate {
    return moment().subtract(1, 'day').format();
  }

  public static nextMonth(date: ProcessableDate) {
    return moment(date).add(1, 'month').toISOString(true);
  }

  public static nextYear(date: ProcessableDate) {
    return moment(date).add(1, 'year').toISOString(true);
  }

  public static numericalDateToWords(date: any) {
    const { yesterday, tomorrow, today } = labels;

    if (DateHelper.isYesterday(date)) {
      return yesterday;
    } else if (DateHelper.isToday(date)) {
      return today;
    } else if (DateHelper.isTomorrow(date)) {
      return tomorrow;
    } else {
      return moment(date).format('ddd DD.MM.YYYY');
    }
  }

  public static parseDateString(date: ProcessableDate): string | undefined {
    if (!date) {
      return undefined;
    }

    const convertedDateGer = moment(date, 'DD.MM.YYYY', true);
    const convertedDate = moment(date, 'YYYY-MM-DD', true);

    const newDate = moment(convertedDate).isValid() ? convertedDate : convertedDateGer;

    if (!moment(newDate).isValid()) {
      return undefined;
    }

    return newDate.toISOString(true);
  }

  // These three (ParseFrom, ParseTo, parseFromTo) create the Event beginning/end format
  public static parseFrom(from: ProcessableDate): string {
    const formattedFrom: string = DateFormatter.calendarFromDay(from);
    const formattedHour: string = moment(this.toDate(from)).format('HH:mm');
    return `${formattedFrom} um ${formattedHour} Uhr`;
  }

  public static parseFromTo(startDate: ProcessableDate, endDate?: ProcessableDate): string[] {
    const formateTimeString = 'HH:mm';
    let startTime: string;
    let endTime: string;
    let firstLine: string;
    let secondLine: string;

    if (!endDate) {
      return [this.parseFrom(startDate)];
    }

    if (moment(startDate).isSame(endDate, 'day')) {
      startTime = moment(startDate).format(formateTimeString);
      endTime = moment(endDate).format(formateTimeString);
      firstLine = this.calendarFromDay(startDate);
      secondLine = `von ${startTime} bis ${endTime} Uhr`;
      return [firstLine, secondLine];
    }

    const startDay = this.calendarFromDayWithFullDate(startDate);
    const endDay = this.calendarFromDayWithFullDate(endDate);
    startTime = moment(startDate).format(formateTimeString);
    endTime = moment(endDate).format(formateTimeString);
    firstLine = `${startDay} von ${startTime} Uhr`;
    secondLine = `bis ${endDay} ${endTime} Uhr`;
    return [firstLine, secondLine];
  }

  public static parseTo(to: string): string {
    const formattedFrom: string = DateFormatter.calendarFromDay(to);
    const formattedHour: string = moment(this.toDate(to)).format('HH:mm');
    return `bis ${formattedFrom} ${formattedHour} Uhr`;
  }

  public static prevMonth(date: ProcessableDate) {
    return moment(date).subtract(1, 'month').toISOString(true);
  }

  public static prevYear(date: ProcessableDate) {
    return moment(date).subtract(1, 'year').toISOString(true);
  }
  // This function takes a number and a duration (minutes, hours, days, etc..)
  public static relativePastDate(number: moment.DurationInputArg1, duration: moment.DurationInputArg2): string {
    return moment().subtract(number, duration).toISOString(true);
  }

  public static setMonth(date: ProcessableDate, month: number) {
    return moment(date).month(month).toISOString(true);
  }

  public static setYear(date: ProcessableDate, year: number) {
    return moment(date).year(year).toISOString(true);
  }

  public static splitDateFromTime(date: ProcessableDate): string {
    return moment(date).toISOString().split('T')[0];
  }

  public static startOfDay(date: ProcessableDate): string {
    return moment(this.toDate(date)).startOf('day').toISOString(true);
  }

  // Used by the conversation-feature
  public static timeAgoConversationList(date: ProcessableDate): string {
    if (DateHelper.isToday(date)) {
      return DateFormatter.asTimeAgo(date);
    }

    if (DateHelper.isYesterday(date)) {
      return labels.yesterday;
    }

    return DateFormatter.asDayMonthYearShort(date);
  }

  // Used by the conversation-feature
  public static timeAgoConversationHistory(date: ProcessableDate): string {
    if (DateHelper.isToday(date)) {
      return labels.today;
    }

    if (DateHelper.isYesterday(date)) {
      return labels.yesterday;
    }

    return DateFormatter.asDayMonthYearLong(date);
  }

  public static toDate(date: ProcessableDate): Date {
    return typeof date === 'string' ? moment(date).toDate() : date;
  }

  public static toDateTimeISOString(date: ProcessableDate, time: string): string {
    if (typeof date !== 'string') {
      date = moment(date).toISOString().split('T')[0];
    }
    const dateTime = moment(date + ' ' + time);
    return dateTime.toISOString();
  }

  public static todayInDDMMYY() {
    const formatString: string = 'DD.MM.YY';
    const now = moment();
    return now.format(formatString);
  }

  public static todayInDatePickerFormat() {
    const formatString: string = 'YYYY-MM-DD';
    const now = moment();
    return now.format(formatString);
  }

  public static tomorrowInDDMMYY() {
    const formatString: string = 'DD.MM.YY';
    const tomorrow = moment().add(1, 'days');
    return tomorrow.format(formatString);
  }

  public static weekendInDDMM() {
    const formatString: string = 'DD.MM.';
    const weekdayCount = moment().isoWeekday();
    // the weekend begins friday 0:00h andends sunday 23:59
    const friday = moment().add(5 - weekdayCount, 'days').startOf('day');
    // Since moment().add changes the object, we format friday, before we change it.
    const fridayStr = friday.format(formatString);
    const sundayStr = friday.add(2, 'days').endOf('day').format(formatString);
    return `${fridayStr} - ${sundayStr}`;
  }

  public static durationToDays(duration: moment.DurationInputObject) {
    return moment.duration(duration).asDays();
  }

  public static diffInDays(date: ProcessableDate) {
    return -Math.ceil(moment().diff(date, 'days', true)) + 1;
  }
}
