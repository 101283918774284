import * as React from 'react';
import { GeoJSON } from 'react-leaflet';

import { MAP_PATH_OPTIONS } from 'src/constants/map';
import { IMapShape } from 'src/interfaces/map';

export default ({ id, data }: IMapShape): JSX.Element => (
  <GeoJSON
    key={id}
    data={data}
    style={MAP_PATH_OPTIONS}
  />
);
