import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router';
// helpers
import { URLContext, IURLContext } from 'src/containers/url_provider/url_provider';
// interfaces
import { Id } from 'src/interfaces';
import { ProfileType } from 'src/interfaces/profile';
import { UrlUtils } from 'src/utils/url/url';

type RouteGetters = Omit<IURLContext, 'navigateToShape' | 'navigateToPositionURL'>;

const createNavigationHook = <K extends keyof RouteGetters, F extends RouteGetters[K]>(key: K) => {
  return () => {
    const history = useHistory();
    const getRoute = useContext(URLContext)[key];

    return useCallback((...props: Parameters<F>) => {
      history.push(getRoute.apply(null, props));
    }, [getRoute, history]);
  };
};

export const useGoToNewsFeed = createNavigationHook('getNewsfeedRoute');
export const useGoToEventsFeed = createNavigationHook('getEventsfeedRoute');
export const useGoToProfilesDirectory = createNavigationHook('getProfilesDirectoryRoute');
export const useGoToResourceLink = createNavigationHook('getResourceLinkRoute');
export const useGoToConversationPage = () => {
  const history = useHistory();

  return useCallback((type: ProfileType, id: Id) => {
    history.push(UrlUtils.sendMessageConversationPage(type, id));
  }, [history]);
};
