// interfaces
import { IPagination as IPaginationResponse } from 'src/api/interfaces/responses/shared';
import { IPagination } from 'src/interfaces/pagination';

export const $pagination = (json: IPaginationResponse): IPagination => ({
  currentPage: json.current_page,
  lastPage: json.last_page,
  total: json.total,
  totalPages: json.total_pages,
});
