// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IDateRange } from 'src/interfaces/filters';

// classes
import Icon from 'src/components/forms/icon/icon';
import Select from 'src/components/forms/native_select/native_select';

// helpers
import { textResources } from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import DateHelper from 'src/utils/date_helper/date';
import './calendarpicker.scss';

const cls = CLASS_PREFIX + 'date-calendarpicker';
const labels = textResources.datePicker;
const hiddenLabels = textResources.screenReaderText;

interface IProps {
  range?: IDateRange;
  currentDate: string;
  calendar: string[][];
  onSelectDate: (selectedDate: string) => (e: React.MouseEvent<HTMLElement>) => void;
  onNextMonth: (e: React.MouseEvent<HTMLElement>) => void;
  onPrevMonth: (e: React.MouseEvent<HTMLElement>) => void;
  onNextYear: (e: React.MouseEvent<HTMLElement>) => void;
  onPrevYear: (e: React.MouseEvent<HTMLElement>) => void;
  onCalendarYearChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onCalendarMonthChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  maxYear?: number;
  minYear?: number;
}

const asYear = (date: string) => DateFormatter.asYear(date);
const asMonth = (date: string) => DateFormatter.asMonth2Digit(date);

const getDayStyles = (currentDate: string, range: IDateRange | undefined, dayDate: string) => {
  const styles = [`${cls}__day-inner`];
  let selectType = 'selected';

  if (range?.start && range.end) {
    selectType = 'range';
  }

  if (DateHelper.isSame(dayDate, range?.start)) {
    styles.push(`${cls}__day-inner--${selectType}-start`);
  }

  if (DateHelper.isSame(dayDate, range?.end)) {
    styles.push(`${cls}__day-inner--${selectType}-end`);
  }

  if (!DateHelper.isSameMonth(currentDate, dayDate)) {
    styles.push(`${cls}__day-inner--othermonth`);
  }

  if (range?.start && range.end && DateHelper.isInRange(dayDate, range.start, range.end)) {
    styles.push(`${cls}__day-inner--range`);
  }

  if (DateHelper.isToday(dayDate)) {
    styles.push(`${cls}__day-inner--today`);
  }

  return styles;
};

const MonthList = DateFormatter.getMonths()
  .map((monthName, i) => ({ label: monthName, value: `${i + 1}` }));

const YearList = (minYear: number, maxYear: number) =>
  [...Array(maxYear - minYear + 1).keys()]
    .map((_, i) => ({
      label: `${maxYear - i}`,
      value: `${maxYear - i}`,
    }));

const CalendarPicker: React.FC<IProps> = ({
  calendar,
  minYear = 2000,
  maxYear = parseInt(DateFormatter.asYear(DateFormatter.getInYears(2)), 0),
  onSelectDate,
  onNextMonth,
  onPrevMonth,
  onNextYear,
  onPrevYear,
  onCalendarMonthChange,
  onCalendarYearChange,
  currentDate,
  range,
}) => (
  <div className={`${cls}__wrapper`}>

    <div className={`${cls}__header`}>

      <div className={`${cls}__header-prev`} onClick={onPrevYear}>
        <Icon
          name={'arrow-left'}
          size={12}
          className={`${cls}__prevyearicon`}
        />
        <Icon
          name={'arrow-left'}
          size={12}
          className={`${cls}__prevyearicon`}
          description={hiddenLabels.previousYear}
        />
      </div>

      <div className={`${cls}__header-prev`} onClick={onPrevMonth}>
        <Icon
          name={'arrow-left'}
          size={12}
          className={`${cls}__prevmonthicon`}
          description={hiddenLabels.previousMonth}
        />
      </div>

      <div className={`${cls}__header-title`}>
        <Select
          className={`${cls}__header-select`}
          selected={asMonth(currentDate)}
          options={MonthList}
          onChange={onCalendarMonthChange}
          name='month'
        />
        <Select
          className={`${cls}__header-select`}
          selected={asYear(currentDate)}
          options={YearList(minYear, maxYear)}
          onChange={onCalendarYearChange}
          name='year'
        />
      </div>

      <div className={`${cls}__header-next`} onClick={onNextMonth}>
        <Icon
          name={'arrow-right'}
          size={12}
          className={`${cls}__nextmonthicon`}
          description={hiddenLabels.nextMonth}
        />
      </div>

      <div className={`${cls}__header-next`} onClick={onNextYear}>
        <Icon
          name={'arrow-right'}
          size={12}
          className={`${cls}__nextyearicon`}
        />
        <Icon
          name={'arrow-right'}
          size={12}
          className={`${cls}__nextyearicon`}
          description={hiddenLabels.nextYear}
        />
      </div>
    </div>

    <div className={`${cls}__weekdays`}>
      <div className={`${cls}__weekday`}>{labels.shortMonday}</div>
      <div className={`${cls}__weekday`}>{labels.shortTuesday}</div>
      <div className={`${cls}__weekday`}>{labels.shortWednesday}</div>
      <div className={`${cls}__weekday`}>{labels.shortThursday}</div>
      <div className={`${cls}__weekday`}>{labels.shortFriday}</div>
      <div className={`${cls}__weekday`}>{labels.shortSaturday}</div>
      <div className={`${cls}__weekday`}>{labels.shortSunday}</div>
    </div>

    <div className={`${cls}__calendar`}>
      {calendar.map((weeks, iweek) => (
        <div className={`${cls}__weeks`} key={iweek}>
          {weeks.map((day, iday) => (
            <div onClick={onSelectDate(day)} className={`${cls}__day`} key={iday}>
              <div className={classNames(getDayStyles(currentDate, range, day))}>
                <span>{DateFormatter.asDay(day)}</span>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>

  </div>
);

CalendarPicker.displayName = 'CalendarPicker';

export default CalendarPicker;
