import React, { lazy, Suspense, Component } from 'react';

import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';
import DeferUtilMeFetched from 'src/containers/defer_until_me_fetched';

import { IChatTopic } from 'src/components/chat_page/utils';

// webpack magic comment inside dynamic import to name outputed chunk see:
// https://webpack.js.org/guides/code-splitting/#dynamic-imports
const NeighborChat = lazy(() =>
  import(/* webpackChunkName: "neighbor-chat" */ 'src/containers/topic_chat/chat'));

interface IProps {
  currentTopic: IChatTopic;
}

interface IState {
  hasError: boolean;
}

class ChatWrapped extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { hasError: false };
    this.setHasError = this.setHasError.bind(this);
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public render() {
    if (this.state.hasError) {
      // TODO add redirect here
      return (
        null
      );
    }
    return (
      <Suspense fallback={<LoadingSpinner shown />}>
        <DeferUtilMeFetched>
          <NeighborChat
            setHasError={this.setHasError}
            currentTopic={this.props.currentTopic}
          />
        </DeferUtilMeFetched>
      </Suspense>
    );
  }

  private setHasError(hasError: boolean) {
    this.setState({ hasError });
  }
}

export default ChatWrapped;
