/**
 * 0 means free choice.
 * 2 means aspect ratio of 2:1, e.g. width 600px to height 300px.
 */
export const POST_CREATION_CROP_BOX_ASPECT_RATIO = 0;
export const POST_CREATION_CROP_BOX_STEP = 10; // px
export const POST_CREATION_MIN_CROP_BOX_SIZE = 50; // px
export const POST_CREATION_MAX_IMAGE_NUMBER = 10;
export const POST_CREATION_MAX_IMAGE_SIZE = 1366; // px
export const POST_CREATION_IMAGE_COMPRESSION_QUALITY = 0.8; // 0..1

export const POST_CREATION_CAMPAIGN_PLATFORM_FACEBOOK = 'Facebook';
export const POST_CREATION_CAMPAIGN_PLATFORM_GOOGLE = 'Google';
export const POST_CREATION_CAMPAIGN_PLATFORM_INSTAGRAM = 'Instagram';
export const POST_CREATION_CAMPAIGN_PLATFORM_LOKALPORTAL = 'Lokalportal';
export const POST_CREATION_CAMPAIGN_PLATFORM_NW_DIGITAL = 'NWdigital';
export const POST_CREATION_CAMPAIGN_PLATFORM_NW_PRINT = 'NWprint';

export const STEP_WRITE = 'write';
export const STEP_CROPPER = 'cropper';
