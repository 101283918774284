import { History } from 'history';
import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

// components
import Submit from 'src/components/flag/create/steps/submit/submit';
import WriteFlag from 'src/components/flag/create/steps/write/write_flag';

// interfaces / constants
import { SUBMIT_PATH, WRITE_PATH } from 'src/constants/route_constants';
import { IFlagCreateData } from 'src/interfaces/flag';

interface IProps {
  onSubmit: (data: IFlagCreateData) => void;
  onEnd: () => void;
  basePath: string;
  history: History;
  search: string;
}

interface IState {
  data?: IFlagCreateData;
}

export default class CreateFlagWizard extends React.Component<IProps, IState> {
  private historyCount: number = 0;
  constructor(props: IProps) {
    super(props);
    this.renderWrite = this.renderWrite.bind(this);
    this.renderSubmit = this.renderSubmit.bind(this);
    this.setData = this.setData.bind(this);
    this.state = {
      data: undefined,
    };
  }

  public componentWillUnmount() {
    // moves the user back to the index in history where component got mounted
    // if he navigated
    if (this.historyCount > 0) {
      this.props.history.go(-this.historyCount);
    }
  }

  public render() {
    return (
      <Switch>
        <Route path={this.props.basePath + SUBMIT_PATH} render={this.renderSubmit} />
        <Route path={this.props.basePath + WRITE_PATH} render={this.renderWrite} />
      </Switch>
    );
  }

  private setData(data: IFlagCreateData) {
    this.setState({ data });
  }

  private renderSubmit() {
    this.historyCount = 1;
    const { onEnd, onSubmit } = this.props;
    return <Submit data={this.state.data} onEnd={onEnd} onSubmit={onSubmit} />;
  }

  private renderWrite() {
    this.historyCount = 0;
    const { onEnd } = this.props;
    return <WriteFlag onEnd={onEnd} setData={this.setData} />;
  }
}
