// libs
// import classNames from 'classnames';
import * as React from 'react';

// classes
import InputField, { IProps as IInputFieldsProps } from 'src/components/forms/input_field/input_field';

// interfaces / constants
import { ILinkPreview } from 'src/interfaces/post_ingredients';

const INITIAL_ROW_COUNT = 4;

interface IProps extends IInputFieldsProps {
  onDetectLink?: (link: ILinkPreview | undefined) => void;
}

export default class InputArea extends React.PureComponent<IProps, {}> {
  public render() {
    const { initialRows, ...restProps } = this.props;
    return (
      <InputField initialRows={initialRows || INITIAL_ROW_COUNT} {...restProps}/>
    );
  }
}
