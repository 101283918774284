import { Store } from 'redux';

import { IRootState } from 'src/reducers/interface';

/*
  #4868 If a module needs store instance, it has to import store from store-holder
  instead of configure-store directly otherwise It may cause circular dependency.
 */
let _store: Store<IRootState>;
export const setStore = (store: Store<IRootState>) => {
  _store = store;
};

export const getStore = () => _store;
