// libs
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// constants/ interfaces
import { IActiveFilter } from 'src/interfaces/filters';

// helpers
import { setActiveFilter } from 'src/actions/filters/filters';

export const useSetActiveFilter = () => {
  const dispatch = useDispatch();
  return useCallback((filter: IActiveFilter) => {
    dispatch(setActiveFilter(filter));
  }, [dispatch]);
};
