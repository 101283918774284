// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';

import { SegmentTextLabelSize } from 'src/components/item_segment/parts/segment_text/model';
import './segment_text.scss';

interface IProps {
  label: string;
  labelSize: SegmentTextLabelSize;
  description?: string;
}

const cls = itemSegmentCls + 'text';

const SegmentText: React.FC<IProps> = ({ label, labelSize, description }) => {
  const labelCls = cls + '__label';
  const classes = {
    [labelCls]: true,
    [labelCls + '--small']: labelSize === 'small',
    [labelCls + '--big']: labelSize === 'big',
  };
  return (
    <p className={cls}>
      <span className={classNames(classes)}>{label}</span>
      {description && <span className={cls + '__description'}>{description}</span>}
    </p>
  );
};

export default SegmentText;
