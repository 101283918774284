// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import {
  QUICK_DATE_FROM_TODAY,
  QUICK_DATE_FROM_TOMORROW,
  QUICK_DATE_ON_WEEKEND,
} from 'src/constants/feed';
import { IDateRange, IQuickDate, QuickDateName } from 'src/interfaces/filters';
import { COLOR_TYPE_PRIMARY } from 'src/utils/color';

// classes
import Calendarpicker from 'src/components/date/picker/calendarpicker';
import Quickpicker from 'src/components/date/picker/quickpicker';
import Button, { BUTTON_TYPE_CONTAINED } from 'src/components/forms/button/button';
import InputDate from 'src/components/forms/input_date/input_date';
import { IEventFeedFilterTracker } from 'src/utils/reporting/events_tracking/events_tracking';

// helpers
import { textResources } from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import DateHelper from 'src/utils/date_helper/date';
import './picker.scss';

const labels = textResources.datePicker;
const postLabels = textResources.postCreate;

const defaultQuickPicker = (): IQuickDate[] => [
  { name: QUICK_DATE_FROM_TODAY, range: { end: undefined, start: DateFormatter.getToday() } },
  { name: QUICK_DATE_FROM_TOMORROW, range: { end: undefined, start: DateFormatter.getTomorrow() } },
  {
    name: QUICK_DATE_ON_WEEKEND,
    range: {
      end: DateFormatter.getNextWeekendEnd(),
      start: DateFormatter.getNextWeekendStart(),
    },
  },
];

type IQuickPickerActiveMap = {
  readonly[K in QuickDateName]: (startDate: string, endDate?: string) => boolean;
};

const quickPickerActiveMap: IQuickPickerActiveMap = {
  [QUICK_DATE_FROM_TODAY]: (startDate, endDate) => !endDate && DateHelper.isToday(startDate),
  [QUICK_DATE_FROM_TOMORROW]: (startDate, endDate) => !endDate && DateHelper.isTomorrow(startDate),
  [QUICK_DATE_ON_WEEKEND]: (startDate, endDate) => (startDate && endDate)
    ? DateHelper.isUpcomingWeekend(startDate) && DateHelper.isUpcomingWeekend(endDate)
    : false,
};

const getActiveQuickPicker = (dates: IQuickDate[], range: IDateRange) =>
  dates.reduce((prevIndex, quickDate, index) => {
    const isActive = quickDate.name && quickPickerActiveMap[quickDate.name]
      ? quickPickerActiveMap[quickDate.name]
      : () => false;

    const active = range.start && isActive(range.start, range.end);
    return active
      ? index
      : prevIndex;
  }, -1);

const cls = CLASS_PREFIX + 'date-picker';

interface IDatePickerProps {
  currentCalendarDate?: string;
  range?: IDateRange;
  title?: string;
  quickPick?: boolean;
  manualPick?: boolean;
  calendarPick?: boolean;
  onSelect?: (selectedDate: IDateRange) => void;
  onCancel?: (e: React.MouseEvent<HTMLElement>) => void;
  minYear?: number;
  maxYear?: number;
  maxDate?: string;
  minDate?: string;
  defaultValue?: string;
  withoutHeader?: boolean;
  tracking?: IEventFeedFilterTracker;
  hideResetButton?: boolean;
}

interface IDatePickerState {
  end: string | undefined;
  start: string | undefined;
  currentCalendarDate: string;
  isEndDatePickVisible: boolean;
}

const toDateInputFormat = (date: string) => DateFormatter.dateInDatePickerFormat(date);
const isBefore = (selectedDate: string, minDate: string | undefined) =>
  minDate ? DateHelper.isBefore(selectedDate, minDate) : true;

const isAfter = (selectedDate: string, maxDate: string | undefined) =>
  maxDate ? DateHelper.isAfter(selectedDate, maxDate) : true;

export default class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {
  constructor(props: IDatePickerProps) {
    super(props);
    const { range, currentCalendarDate, defaultValue } = props;

    this.handleQuickSelect = this.handleQuickSelect.bind(this);
    this.handleCalendarYearChange = this.handleCalendarYearChange.bind(this);
    this.handleCalendarMonthChange = this.handleCalendarMonthChange.bind(this);
    this.handleCalendarSelect = this.handleCalendarSelect.bind(this);
    this.handleCalendar = this.handleCalendar.bind(this);
    this.handleCancelButton = this.handleCancelButton.bind(this);
    this.handleResetButton = this.handleResetButton.bind(this);
    this.handleOkButton = this.handleOkButton.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleEndDate = this.handleEndDate.bind(this);
    this.toggleEndDatePickVisibility = this.toggleEndDatePickVisibility.bind(this);

    this.state = {
      currentCalendarDate: (range && range.start) || defaultValue || currentCalendarDate || DateFormatter.getToday(),
      end: range && range.end,
      isEndDatePickVisible: !!((range && range.end)),
      start: range && range.start,
    };
  }

  public render() {
    const { quickPick = true, manualPick, calendarPick, minYear, maxYear, withoutHeader } = this.props;
    const { currentCalendarDate, isEndDatePickVisible, start, end } = this.state;

    const calenderWeeks = DateFormatter.getWeeksForDate(currentCalendarDate);
    const endValue = end ? toDateInputFormat(end) : '';
    const startValue = start ? toDateInputFormat(start) : '';

    const quickDates = defaultQuickPicker();
    const activeQuickDate = getActiveQuickPicker(quickDates, { end, start });

    return (
      <div className={`${cls}__wrapper`}>

        {!withoutHeader && this.renderHeader()}

        {quickPick && <div className={`${cls}__quickwrapper`} >
          <Quickpicker dates={quickDates} activeIndex={activeQuickDate} onSelectDate={this.handleQuickSelect}/>
        </div>}

        { calendarPick && <div className={`${cls}__calendarwrapper`} >
          <Calendarpicker
            onNextMonth={this.handleCalendar(DateFormatter.nextMonth)}
            onPrevMonth={this.handleCalendar(DateFormatter.prevMonth)}
            onNextYear={this.handleCalendar(DateFormatter.nextYear)}
            onPrevYear={this.handleCalendar(DateFormatter.prevYear)}
            onCalendarYearChange={this.handleCalendarYearChange}
            onCalendarMonthChange={this.handleCalendarMonthChange}
            range={{ end, start }}
            currentDate={currentCalendarDate}
            calendar={calenderWeeks}
            onSelectDate={this.handleCalendarSelect}
            maxYear={maxYear}
            minYear={minYear}
          />
        </div>}

        { manualPick && (
          <div>
            <div className={`${cls}__info`}>
              {!isEndDatePickVisible && (
                <div>
                  {labels.selectEndDate}
                  <span
                    onClick={this.toggleEndDatePickVisibility}
                    className={`${cls}__infolink`}
                  >{postLabels.addEndDate}</span>
                </div>
              )}

              { isEndDatePickVisible && (
                <span onClick={this.toggleEndDatePickVisibility} className={`${cls}__infolink`}>
                  {postLabels.removeEndDate}
                </span>
              )}
            </div>

            { isEndDatePickVisible && (
              <div>
                <div className={`${cls}__custom`}>
                  <div className={`${cls}__start`}>
                    <InputDate
                      leftIcon={false}
                      label={postLabels.startDate}
                      name={'startInput'}
                      value={startValue}
                      onChange={this.handleStartDate}
                    />
                  </div>
                  <div className={`${cls}__end`}>
                    <InputDate
                      leftIcon={false}
                      label={postLabels.endDate}
                      name={'endInput'}
                      value={endValue}
                      onChange={this.handleEndDate}
                    />
                  </div>
                </div>

                <div className={`${cls}__okbuttonwrapper`}>
                  <Button
                    variant={BUTTON_TYPE_CONTAINED}
                    onClick={this.handleOkButton}
                    label={labels.buttonOK}
                    color={COLOR_TYPE_PRIMARY}
                    fullWidth={true}
                  />
                </div>
              </div>
            )}
          </div>
        )}

      </div>
    );
  }

  private renderHeader() {
    return (
      <div className={`${cls}__header`}>
        <div className={`${cls}__header--cancelbutton`} onClick={this.handleCancelButton}>
          {textResources.shared.cancel}
        </div>
        <h1 className={`${cls}__header--title`}>
          {this.props.title || postLabels.headerDate}
        </h1>
        <div className={classNames(
          `${cls}__header--resetbutton`,
          { [`${cls}__header--resetbutton--hidden`]: this.props.hideResetButton })} onClick={this.handleResetButton}>
          {labels.buttonReset}
        </div>
      </div>
    );
  }

  private toggleEndDatePickVisibility() {
    const { tracking } = this.props;
    setTimeout(() => {
      if (this.state.isEndDatePickVisible) {
        tracking && tracking.tracker(
          tracking.trackingObj.ACTIONS.CLOSE_CALENDAR_RANGE,
          tracking.trackingObj.LABELS.START
        );
        this.setState({
          end: undefined,
          isEndDatePickVisible: false,
        });
        return;
      }

      tracking && tracking.tracker(
        tracking.trackingObj.ACTIONS.OPEN_CALENDAR_RANGE,
        tracking.trackingObj.LABELS.START
      );
      this.setState({ isEndDatePickVisible: true });
    }, 0);
  }

  private handleResetButton() {
    const { defaultValue } = this.props;
    const resetRange = defaultValue
      ? { end: undefined, start: defaultValue } : defaultQuickPicker()
        .filter((preset) => preset.name === QUICK_DATE_FROM_TODAY)[0].range;
    const onDateSelect = this.handleQuickSelect({ range: resetRange });
    return onDateSelect();
  }

  private handleQuickSelect(selectedDate: IQuickDate) {
    return () => {
      if (this.props.tracking) {
        const { tracking: { tracker, trackingObj } } = this.props;
        if (selectedDate.name === QUICK_DATE_FROM_TODAY) {
          tracker(trackingObj.ACTIONS.SELECT_CALENDAR_HEUTE, trackingObj.LABELS.START);
        } else if (selectedDate.name === QUICK_DATE_FROM_TOMORROW) {
          tracker(trackingObj.ACTIONS.SELECT_CALENDAR_MORGEN, trackingObj.LABELS.START);
        } else if (selectedDate.name === QUICK_DATE_ON_WEEKEND) {
          tracker(trackingObj.ACTIONS.SELECT_CALENDAR_WOCHENENDE, trackingObj.LABELS.START);
        }
      }
      if (selectedDate.range) {
        this.setState({
          end: selectedDate.range.end,
          start: selectedDate.range.start,
        });
        this.props.onSelect && this.props.onSelect(selectedDate.range);
      }
    };
  }

  private handleCalendar(func: (date: string) => string): () => void {
    return () => this.setState({ currentCalendarDate: func(this.state.currentCalendarDate) });
  }

  private handleCalendarYearChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      currentCalendarDate: DateFormatter.setYear(this.state.currentCalendarDate, parseInt(e.target.value, 10)),
    });
  }

  private handleCalendarMonthChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.setState({
      currentCalendarDate: DateFormatter.setMonth(this.state.currentCalendarDate, parseInt(e.target.value, 10) - 1),
    });
  }

  private handleCalendarSelect(selectedDate: string) {
    return (): void => {
      const { minDate, maxDate, onSelect } = this.props;

      if (minDate && isBefore(selectedDate, minDate)) {
        return;
      }

      if (maxDate && isAfter(selectedDate, maxDate)) {
        return;
      }

      const select = () => {
        if (this.props.tracking) {
          const { tracking: { tracker, trackingObj } } = this.props;
          tracker(trackingObj.ACTIONS.SELECT_CALENDAR_DATE, trackingObj.LABELS.START);
        }
        onSelect && onSelect({ end: this.state.end, start: this.state.start });
      };

      const startDate = { start: selectedDate };
      const endDate = { end: selectedDate };
      const { end, isEndDatePickVisible, start } = this.state;

      if (!isEndDatePickVisible) {
        this.setState(startDate, select);
        return;
      }

      // on start click : toggle start
      if (DateHelper.isSame(selectedDate, start)) {
        this.setState({ start: undefined });
        return;
      }

      // on end click : toggle end
      if (DateHelper.isSame(selectedDate, end)) {
        this.setState({ end: undefined });
        return;
      }

      const shouldUpdateStartDate = (!start && isBefore(selectedDate, end)) || isBefore(selectedDate, start);
      const shouldUpdateEndDate = (start && !end && isAfter(selectedDate, start)) || isAfter(selectedDate, end);

      if (shouldUpdateStartDate) {
        this.setState(startDate);
      } else if (shouldUpdateEndDate) {
        this.setState(endDate);
      } else {
        this.setState({ end: undefined, start: selectedDate });
      }
    };
  }

  private handleCancelButton(e: React.MouseEvent<HTMLElement>) {
    const { onCancel } = this.props;
    onCancel && onCancel(e);
  }

  private handleOkButton() {
    const { onSelect, tracking } = this.props;
    if (!this.state.start) {
      return;
    }
    tracking && tracking.tracker(tracking.trackingObj.ACTIONS.SELECT_CALENDAR_DATE, tracking.trackingObj.LABELS.START);
    onSelect && onSelect({ end: this.state.end, start: this.state.start });
  }

  private handleStartDate(value: string | undefined) {
    if (value !== this.state.start) {
      this.setState({ start: value });
    }
  }

  private handleEndDate(value: string | undefined) {
    if (value !== this.state.end) {
      this.setState({ end: value });
    }
  }
}
