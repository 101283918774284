// lib
import * as React from 'react';

// components
import ItemSegmentBase, { BIG_ITEM_SEGMENT, itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink, { IProps as ILinkProps } from 'src/components/item_segment/base/item_segment_link';
import SegmentImage from 'src/components/item_segment/parts/segment_image/segment_image';
import SegmentRightArrow from 'src/components/item_segment/parts/segment_right_arrow/segment_right_arrow';
import SegmentTextPostPreview
  from 'src/components/item_segment/parts/segment_text_post_preview/segment_text_post_preview';

// constants
import { THUMBNAIL_SIZE_TYPE_MEDIUM } from 'src/components/thumbnail/thumbnail';
import { FRONTEND_NEWSFEED_PATH } from '../../../../constants/urls';
import { IPost } from '../../../../interfaces/posts';

interface IProps {
  title: string;
  imageSrc: string;
  inclusionReason: string;
  inclusionReasonType?: string;
  navigation: ILinkProps;
  post: IPost;
}

const cls = itemSegmentCls + 'highlight';

/*
  A Wrapper for ItemSegment for Highlight
*/
const HighlightItemSegment: React.FC<IProps> = ({
  imageSrc,
  title,
  navigation,
  inclusionReason,
  inclusionReasonType,
  post,
}) => {
  const url = post.survey ? FRONTEND_NEWSFEED_PATH : navigation.url;

  return (
    <ItemSegmentBase className={cls} height={BIG_ITEM_SEGMENT}>
      <ItemSegmentLink {...navigation} url={url}>
        <SegmentImage src={imageSrc} width={THUMBNAIL_SIZE_TYPE_MEDIUM} alt=''/>
        <SegmentTextPostPreview
          title={title}
          inclusionReason={inclusionReason}
          inclusionReasonType={inclusionReasonType}/>
        <SegmentRightArrow/>
      </ItemSegmentLink>
    </ItemSegmentBase>
  );
};

export default HighlightItemSegment;
