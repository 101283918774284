// libs
import classNames from 'classnames';
import * as React from 'react';

import Avatar from 'src/components/avatar/avatar';

import { THUMBNAIL_SIZE_TYPE_XSMALL } from 'src/components/thumbnail/thumbnail';
import { CLASS_PREFIX } from 'src/constants/';

import './main_menu_button.scss';

const cls: string = CLASS_PREFIX + 'menu-button';
const clsHeaderButton = `${CLASS_PREFIX}_header-button`;

interface IProps {
  onClick?: () => void;
  active?: boolean;
  image?: string;
}

/**
 * (header) menu button component
 */
const MenuButton: React.FC<IProps> = ({ active, image, ...props }) => {
  return (
    <div className={classNames([cls, clsHeaderButton])} {...props}>
      <Avatar size={THUMBNAIL_SIZE_TYPE_XSMALL} image={image} withBorder />
    </div>
  );
};

/* set displayname to not have a component without a name */
MenuButton.displayName = 'MenuButton';

export default MenuButton;
