/* Crawlers will get their page from the external prerender.io service.
 * We may want to change specific app behavior if the visitor is a crawler and not a human being.
 *
 * The prerender-bot's useragent is: (see https://prerender.io/documentation/faq)
 * `Mozilla/5.0 (Unknown; Linux x86_64) AppleWebKit/538.1 (KHTML, like Gecko) PhantomJS/2.1.1 Safari/538.1
 *  Prerender (+https://github.com/prerender/prerender)`
 *
 * So we check for 'Prerender'.
 */
export const isPrerenderIoAgent = (): boolean => window.navigator.userAgent.includes('Prerender');
