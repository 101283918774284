// libs
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// constants
import { FEED_TYPE_NEWS } from 'src/constants/feed';

// helpers
import { fetchNewsFeedPage, resetNewsfeed } from 'src/actions/feed/news_feed/news_feed';
import { fetchNextFeedPage } from 'src/actions/feed/next_page';
import { useSelectFeedIsLoading } from 'src/reducers/feed/feed_hooks';

export const useFetchNewsFeedPage = () => {
  const dispatch = useDispatch();
  return useCallback((page?: number) => {
    dispatch(fetchNewsFeedPage(page));
  }, [dispatch]);
};

export const useFetchNewsFeedNextPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchNextFeedPage(FEED_TYPE_NEWS, fetchNewsFeedPage));
  }, [dispatch]);
};

export const useResetNewsFeed = () => {
  const dispatch = useDispatch();
  const isLoading = useSelectFeedIsLoading(FEED_TYPE_NEWS);
  return useCallback(() => {
    if (!isLoading) {
      dispatch(resetNewsfeed());
    }
  }, [dispatch, isLoading]);
};
