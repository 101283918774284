import { NO_USER_PROFILE_TYPE } from 'src/constants/profile';

export const ANONYMOUS_USER_TYPE = NO_USER_PROFILE_TYPE;
export const ANONYMOUS_PROFILE_ID = ANONYMOUS_USER_TYPE;
// see http://momentjs.com/docs/#/manipulating/add/
export const GRAYLIST_PROFILE_DEFAULT_EXPIRATION = { days: 28 };
export const NO_PROFILE = 'noUserProfile';
export const INTERMEDIATE_PROFILE = 'intermediateProfile';
export const FULL_PROFILE = 'fullProfile';
export const PROFILE_STEP_PROFILE_COMPLETE = 'completeProfile';
export const PROFILE_STEP_EMAIL_CONFIRMED = 'confirmedProfile';
export const PROFILE_STEP_VERIFIED = 'verifiedProfile';
export const IDENTITY_PROVIDER_APPLE = 'apple';
export const IDENTITY_PROVIDER_FACEBOOK = 'facebook';
export type IdentityProviderType = typeof IDENTITY_PROVIDER_APPLE
  | typeof IDENTITY_PROVIDER_FACEBOOK;
