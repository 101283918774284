// libs
import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

// interfaces
import { IAction } from 'src/actions/index';
import {
  NO_PROFILE,
  PROFILE_STEP_EMAIL_CONFIRMED,
  PROFILE_STEP_PROFILE_COMPLETE,
  PROFILE_STEP_VERIFIED,
} from 'src/constants/user';
import { IRootState } from 'src/reducers/interface';

// helpers
import {
  showCompleteProfileCTA,
  showConfirmEmailCTA,
  showNewUserInfoCTA,
  showVerifyCTA,
} from 'src/actions/app-state/app-state';
import { isReadyToShowCTA } from 'src/actions/onboarding_cta/onboarding_cta_utils';
import { setUserAnnoyedAt } from 'src/utils/location_storage/location_storage';
import { getProfileStatus } from 'src/utils/profile';

export const callToAction = (setUserAnnoyedAt?: () => void): ThunkAction<void, IRootState, {}, IAction> => {
  return (dispatch: Dispatch<IAction>, getState: () => IRootState): void => {
    const state = getState();

    setUserAnnoyedAt && setUserAnnoyedAt();

    switch (getProfileStatus(state)) {
      case NO_PROFILE:
        dispatch(showNewUserInfoCTA());
        break;
      case PROFILE_STEP_PROFILE_COMPLETE:
        dispatch(showCompleteProfileCTA());
        break;
      case PROFILE_STEP_EMAIL_CONFIRMED:
        dispatch(showConfirmEmailCTA());
        break;
      case PROFILE_STEP_VERIFIED:
        dispatch(showVerifyCTA());
        break;
      default:
      // this case occurs if the user is a state2 profile. Don't do anything in this case.
    }
  };
};

// This action is used for CTAs which should only occur once in 12 hours.
export const callToActionWithFlag = (totalPagesViewed: number): ThunkAction<void, IRootState, {}, IAction> => {
  if (isReadyToShowCTA(totalPagesViewed)) {
    return callToAction(setUserAnnoyedAt);
  }

  // empty Thunk action
  return () => undefined;
};
