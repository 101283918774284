// libs
import { useSelector } from 'react-redux';

// interfaces
import { IErrorResponse } from 'src/api/interfaces/errors';
import { IGroup, IGroupMap, IGroupsData, IMoreGroupsData } from 'src/interfaces/group';
import { IRootState } from 'src/reducers/interface';
import { RemoteData } from './types/remote_data';

export const useGetGroupById = (id?: string) =>
  useSelector<IRootState, IGroup | undefined>(({ groups }) => id ? groups.groupMap[id] : undefined);

export const useGetGroupMap = () =>
  useSelector<IRootState, IGroupMap>(({ groups }) => groups.groupMap);

export const useGetJoinedGroupsRequest = () =>
  useSelector<IRootState, RemoteData<IGroupsData, IErrorResponse>>(({ groups }) => groups.joinedGroupsRequest);

export const useGetMoreGroupsRequest = () =>
  useSelector<IRootState, RemoteData<IMoreGroupsData, IErrorResponse>>(({ groups }) => groups.moreGroupsRequest);
