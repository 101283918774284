// libs
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

// interfaces / constants
import { FEED_TYPE_EVENT } from 'src/constants/feed';

// components
import BannerFeed from 'src/components/feed/layouts/banner_feed/banner_feed';
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import FeedWrapper from 'src/containers/smart_components/list_wrapper/feed_wrapper';

// helpers
import { useFetchCategories } from 'src/actions/categories/categories_hooks';
import { useFetchEventFeedPage, useFetchEventFeedNextPage } from 'src/actions/feed/events_feeds/events_feed_hooks';
import { useMarkersInViewport } from 'src/actions/feed/feed_hooks';
import { useSetActiveFilter } from 'src/actions/filters/filters_hooks';
import { useGoToEventsFeed } from 'src/hooks_shared/use_navigation';
import { IEventCategory } from 'src/interfaces/categories';
import { useGetBoundingBox } from 'src/reducers/app-state/app-state_hooks';
import { useSelectCategories } from 'src/reducers/categories/categories_hooks';
import {
  useSelectFeedGroups,
  useSelectFeedIds,
  useSelectFeedIsLoading,
  useSelectPosts,
  useSelectFeedHash,
} from 'src/reducers/feed/feed_hooks';
import { useSelectActiveFilter, useSelectDateFilter } from 'src/reducers/filters/filters_hooks';
import { useGetRenderEventCreate } from './hooks';

interface IProps {
  isPostCreateOpen?: boolean;
}

const EventFeed: React.FC<IProps> = ({ isPostCreateOpen = false }) => {
  const boundingBox = useGetBoundingBox();
  const feedHash = useSelectFeedHash(FEED_TYPE_EVENT);
  const posts = useSelectPosts();
  const ids = useSelectFeedIds(FEED_TYPE_EVENT);
  const groups = useSelectFeedGroups(FEED_TYPE_EVENT);
  const isLoading = useSelectFeedIsLoading(FEED_TYPE_EVENT);
  const dateFilterRange = useSelectDateFilter();
  const activeFilter = useSelectActiveFilter();
  const { filter } = useParams();
  const eventCategories = useSelectCategories();
  const navigateToEventsFeed = useGoToEventsFeed();

  let expectedCategory = useRef<IEventCategory | undefined>();

  const fetchCategories = useFetchCategories('Event');
  const setFilter = useSetActiveFilter();
  useMarkersInViewport(ids, posts);

  useEffect(() => {
    if (eventCategories.length === 1) {
      fetchCategories();
      return;
    }

    expectedCategory.current =
      eventCategories.find((category: IEventCategory) => category.slug.toLocaleLowerCase() === filter);

    if (!expectedCategory.current) {
      navigateToEventsFeed();
      return;
    }

    if (expectedCategory.current && expectedCategory.current?.id !== activeFilter.id) {
      setFilter({ id: expectedCategory.current.id, label: expectedCategory.current.name });
    }
  }, [activeFilter.id, eventCategories, fetchCategories, filter, navigateToEventsFeed, setFilter]);

  const getFirstPages = useFetchEventFeedPage();
  const getNextPage = useFetchEventFeedNextPage();
  const renderPostCreate = useGetRenderEventCreate(isPostCreateOpen);

  useEffect(() => {
    if (activeFilter.id === expectedCategory.current?.id) {
      getFirstPages();
    }
  }, [boundingBox, getFirstPages, feedHash, dateFilterRange, activeFilter, isLoading]);

  return (
    <LoadingSpinnerWrapper isLoading={isLoading} >
      <FeedWrapper
        getNextPage={getNextPage}
        feedType={FEED_TYPE_EVENT}
      >
        <BannerFeed
          groups={groups}
          posts={posts}
          renderPostCreate={renderPostCreate}
          isLoading={isLoading}
          showGroupLabels
        />
      </FeedWrapper>
    </LoadingSpinnerWrapper>
  );
};

EventFeed.displayName = 'EventFeed';

export default EventFeed;
