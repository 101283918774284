// interfaces / constants
import { IErrorResponse } from 'src/api/interfaces/errors';
import { NETWORK_ERROR } from 'src/constants/api_error_codes';

// actions
import { showSnackBar } from 'src/actions/app-state/app-state';

// helpers
import { textResources } from 'src/lang/de';
import { getStore } from 'src/store/store-holder';

export const formatApiErrorFullMessages = (error: IErrorResponse,
                                           separator: string = ', ',
                                           fallback: string = textResources.shared.errorUnknown): string =>
  error?.errors?.full_messages
    ? error.errors.full_messages.join(separator)
    : fallback;

export const handleNetworkError = (error: Error) => {
  if (error.message === NETWORK_ERROR) {
    getStore().dispatch(showSnackBar({
      message: textResources.shared.genericErrorLong,
    }));
  }

  throw error;
};
