// libs
import copy from 'clipboard-copy';
import * as React from 'react';

// interfaces / constants
import { ITrackingType } from 'src/high_order_components/with_tracker_props';
import {
  COLOR_TYPE_FACEBOOK,
  COLOR_TYPE_NOTIFICATION,
  COLOR_TYPE_TWITTER,
  COLOR_TYPE_WHATSAPP,
  ColorType,
} from 'src/utils/color';
import {
  PostDetailsObjType,
  PostPreviewObjType,
  HeaderDetailsObjType,
  SHARE_EMAIL,
  SHARE_FACEBOOK,
  SHARE_LINK,
  SHARE_NATIVE,
  SHARE_TWITTER,
  SHARE_WHATSAPP,
} from 'src/utils/reporting/events_tracking/events_tracking';

// components
import SnackBar from 'src/components/snack_bar/snack_bar';

// helpers
import { withUTMParams } from 'src/components/share/lib/utils';
import { textResources } from 'src/lang/de';
import { reportError } from 'src/utils/reporting/report-errors';

export interface IShareCompProps {
  shareLinks: IShareLink[];
  shareToClipboard: () => void;
  track: (action?: TrackingActionType) => () => void;
  className?: string;
}

export type AllowedShareTracking =
  ITrackingType<HeaderDetailsObjType> |
  ITrackingType<PostDetailsObjType> |
  ITrackingType<PostPreviewObjType>;

export interface IShareProps {
  url: string;
  utmSource: string;
  className?: string;
  greeting?: string;
  subject?: string;
  tracking?: AllowedShareTracking;
}

type TrackingActionType = typeof SHARE_EMAIL |
  typeof SHARE_FACEBOOK |
  typeof SHARE_LINK |
  typeof SHARE_NATIVE |
  typeof SHARE_TWITTER |
  typeof SHARE_WHATSAPP;

export interface IShareLink {
  caption: string;
  iconName: string;
  iconColor: ColorType;
  name: string;
  shareUrl: string;
  trackingAction?: TrackingActionType;
}

interface IProps extends IShareProps {
  component: React.ComponentType<IShareCompProps>;
}

interface IState {
  showSnackbar: boolean;
}

const lang = textResources.share;

// this is a smart component that provides share logic to the component it got passed as a prop
export default class Share extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.showSnackbar = this.showSnackbar.bind(this);
    this.hideSnackbar = this.hideSnackbar.bind(this);

    this.state = {
      showSnackbar: false,
    };
  }

  public render() {
    const { className, url, utmSource,
      component: PresentationalComponent, tracking } = this.props;
    const { showSnackbar } = this.state;

    const greeting = encodeURIComponent(this.props.greeting ?? lang.greeting);
    const subject = encodeURIComponent(this.props.subject ?? lang.subject);

    const absoluteUrl = window.location.protocol + '//' + window.location.hostname + url;

    const urlToShare = (name: string) =>
      withUTMParams(
        absoluteUrl,
        { medium: name, source: utmSource });

    const encodedURL = (name: string) => encodeURIComponent(urlToShare(name));

    const shareLinks: IShareLink[] = [
      { caption: lang.facebook,
        iconColor: COLOR_TYPE_FACEBOOK,
        iconName: 'facebook',
        name: 'facebook',
        shareUrl: `https://www.facebook.com/sharer/sharer.php?u=${encodedURL('facebook')}`,
        trackingAction: tracking && tracking.trackingObj.ACTIONS.SHARE_FACEBOOK,
      },
      { caption: lang.twitter,
        iconColor: COLOR_TYPE_TWITTER,
        iconName: 'twitter',
        name: 'twitter',
        shareUrl: `https://twitter.com/intent/tweet?text=${greeting}&url=${encodedURL('twitter')}`,
        trackingAction: tracking && tracking.trackingObj.ACTIONS.SHARE_TWITTER,
      },
      { caption: lang.whatsapp,
        iconColor: COLOR_TYPE_WHATSAPP,
        iconName: 'whatsapp',
        name: 'whatsapp',
        shareUrl: `whatsapp://send?text=${greeting}${encodedURL('whatsapp')}`,
        trackingAction: tracking && tracking.trackingObj.ACTIONS.SHARE_WHATSAPP,
      },
      { caption: lang.email,
        iconColor: COLOR_TYPE_NOTIFICATION,
        iconName: 'envelope-filled',
        name: 'email',
        shareUrl: `mailto:?subject=${subject}&body=${greeting}${encodedURL('email')}`,
        trackingAction: tracking && tracking.trackingObj.ACTIONS.SHARE_EMAIL,
      },
    ];

    const shareToClipboard = () => {
      const link = urlToShare('link');
      copy(link)
        .then(() => {
          if (tracking) {
            tracking.tracker(
              tracking.trackingObj.ACTIONS.SHARE_LINK,
              tracking.trackingObj.LABELS.FINISH,
              undefined,
              { shareUrl: absoluteUrl }
            );
          }
          this.showSnackbar();
        })
        .catch((error) => {
          reportError('error on copying share link to clipboard', {
            error,
            link,
          });
        });
    };

    const track = (action?: TrackingActionType) => () => {
      if (tracking && action) {
        tracking.tracker(action, tracking.trackingObj.LABELS.FINISH, undefined, { shareUrl: absoluteUrl });
      }
    };

    return (
      <>
        <PresentationalComponent
          shareLinks={shareLinks}
          shareToClipboard={shareToClipboard}
          track={track}
          className={className}
        />
        {showSnackbar &&
          <SnackBar onClose={this.hideSnackbar}>
            {lang.linkWasCopiedToClipboard}
          </SnackBar>
        }
      </>
    );
  }

  private showSnackbar() {
    this.setState({ showSnackbar: true });
  }

  private hideSnackbar() {
    this.setState({ showSnackbar: false });
  }
}
