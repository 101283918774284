// libs
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// interfaces / constants
import { IAction } from 'src/actions/';
import { IDateRange } from 'src/interfaces/filters';
import { IRootState } from 'src/reducers/interface';
import {
  EventFeedFilterObjType,
  EVENT_FEED_FILTER,
} from 'src/utils/reporting/events_tracking/events_tracking';

// components
import CalendarDatepicker from 'src/components/header/subheaders/pages/events_feed/calendar_datepicker';

// helpers
import { setDateRangeFilter } from 'src/actions/filters/filters';
import { withTrackerProps, ITrackingProps } from 'src/high_order_components/with_tracker_props';
import DateFormatter from 'src/utils/date_formatter/date_formatter';

interface IDispatchFromProps {
  onSelectDate: (range: IDateRange) => void;
}

interface IMapStateToProps {
  dateRange: IDateRange;
}

type IProps = IDispatchFromProps & IMapStateToProps & ITrackingProps<EventFeedFilterObjType>;

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  dateRange: state.filters.dateFilterRange,
});

const mapDispatchToProps = (dispatch: Dispatch<IAction>): IDispatchFromProps => ({
  onSelectDate: (range) => dispatch(setDateRangeFilter(maybeWeekend(range))),
});

const maybeWeekend = ({ end, start }: IDateRange): IDateRange => ({
  end: end && DateFormatter.endOfDay(end),
  start: start === DateFormatter.getNextWeekendStart() && end === DateFormatter.getNextWeekendEnd()
    ? start && DateFormatter.getNextWeekendStart()
    : start && DateFormatter.startOfDay(start),
});

const EventFeedCalendar: React.FC<IProps> = ({
  dateRange,
  onSelectDate,
  tracking,
}) => (
  <CalendarDatepicker range={dateRange} onChange={onSelectDate} tracking={tracking}/>
);

export default withTrackerProps<EventFeedFilterObjType, {}>(EVENT_FEED_FILTER)(
  connect<IMapStateToProps, IDispatchFromProps, ITrackingProps<EventFeedFilterObjType>>(
    mapStateToProps,
    mapDispatchToProps,
  )(EventFeedCalendar));
