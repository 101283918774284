// lib
import { History } from 'history';
import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

// components
import HeaderGroup from 'src/components/header/subheaders/pages/group/header_group';

// interfaces
import { IGroup } from 'src/interfaces/group';
import { IRootState } from 'src/reducers/interface';

// constants
import { FRONTEND_GROUP_OVERVIEW_PATH } from 'src/constants/urls';

// actions
import { fetchJoinedGroups } from 'src/actions/groups/groups';

// helpers
import { resetNewsfeed } from 'src/actions/feed/news_feed/news_feed';
import { withRouterProps } from 'src/high_order_components/connect_route_props';
import { UrlUtils } from 'src/utils/url/url';

interface IMapStateToProps {
  group?: IGroup;
}

interface IDispatchFromProps {
  fetchMyGroups: () => void;
  resetNewsfeed: () => void;
}

interface IMapRouteToProps {
  goToGroupsPage: (deletedGroupIdentifier?: string) => void;
  handleBackButton: () => void;
  history: History;
  id: string;
}

interface IOwnProps {
  showMemberCount?: boolean;
  showMoreButton?: boolean;
}

export type IProps = IMapStateToProps & IDispatchFromProps & IMapRouteToProps & IOwnProps;

const mapStateToProps = ({ groups }: IRootState, ownProps: IMapRouteToProps): IMapStateToProps => ({
  group: groups.groupMap[ownProps.id],
});

const mapDispatchToProps: IDispatchFromProps = {
  // We have a hook for this now useFetchJoinedGroups
  fetchMyGroups: fetchJoinedGroups,
  resetNewsfeed,
};

const mapRouteToProps = ({ history, match }: RouteComponentProps<{id: string}>): IMapRouteToProps => ({
  goToGroupsPage: () => history.push(FRONTEND_GROUP_OVERVIEW_PATH),
  handleBackButton: () => UrlUtils.goBack(history, FRONTEND_GROUP_OVERVIEW_PATH),
  history,
  id: match.params.id,
});

export default flowRight(
  withRouterProps<IMapRouteToProps, IOwnProps>(mapRouteToProps),
  connect(mapStateToProps, mapDispatchToProps),
)(HeaderGroup);
