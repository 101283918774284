import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// helpers
import {
  fetchFolloweesRecentFeedPage,
} from 'src/actions/feed/followees_recent_feed/followees_recent_feed';
import { fetchNextFeedPage } from 'src/actions/feed/next_page';
import { FEED_TYPE_FOLLOWEEES_RECENT } from 'src/constants/feed';

export const useFetchFolloweesRecentFeedPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchFolloweesRecentFeedPage());
  }, [dispatch]);
};

export const useFetchFolloweesRecentFeedNextPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchNextFeedPage(FEED_TYPE_FOLLOWEEES_RECENT, fetchFolloweesRecentFeedPage));
  }, [dispatch]);
};
