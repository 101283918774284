// libs
import * as React from 'react';

// interfaces / constants
import { IErrorResponse } from 'src/api/interfaces/errors';
import { CLASS_PREFIX } from 'src/constants/';
import { COMMENT_CREATION_MAX_IMAGE_NUMBER } from 'src/constants/comment';
import { POST_CREATION_MAX_IMAGE_NUMBER } from 'src/constants/post_creation';
import { ILinkPreview } from 'src/interfaces/post_ingredients';
import { SponsorLevel } from 'src/interfaces/profile';

// classes
import MediaInput, { ModeType } from 'src/components/media_input/media_input';
import TextInput from 'src/components/text_input/text_input';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { textResources } from 'src/lang/de';
import { reportError } from 'src/utils/reporting/report-errors';
import './comment_input.scss';

const cls = CLASS_PREFIX + 'comment-input';
const clsCancelEditText = `${cls}__cancel-edit-text`;
const clsCancelEditButton = `${cls}__cancel-edit-button`;
const labels = textResources.comments;

interface ICommentInputProps {
  cancel?: () => void;
  commentId?: string;
  disabled: boolean;
  images?: string[];
  message?: string;
  mode?: ModeType;
  oglink?: ILinkPreview;
  refProp?: (ref: TextInput) => void;
  sponsorLevel?: SponsorLevel;
  submit?: (str: string, images?: string[], oglinkId?: string) => void;
  update?: (commentId: string, updateUrl: string, str: string, images?: string[], oglinkId?: string) => void;
  updateUrl?: string;
  userImageUrl: string;
}

export default class CommentInput extends React.PureComponent<ICommentInputProps, {}> {
  public constructor(props: ICommentInputProps) {
    super(props);
    this.onUpdate = this.onUpdate.bind(this);
    this.onError = this.onError.bind(this);
  }

  public render() {
    const {
      cancel,
      disabled,
      images,
      message,
      mode,
      oglink,
      refProp,
      sponsorLevel,
      submit,
      userImageUrl,
    } = this.props;

    const cancelEditMessage = `${labels.editCancelText} ${labels.editCancelCTA}`;
    const cancelEdit = (
      <div className={clsCancelEditText}>{labels.editCancelText}&nbsp;
        <button className={clsCancelEditButton} onClick={cancel}>{labels.editCancelCTA}</button>
      </div>
    );

    return (
      <form className={cls} >
        <UserOnboardingCTA active={disabled} gtmSourceName='comment'>
          <MediaInput
            cancel={cancel}
            cancelEditMessage={cancelEditMessage}
            cancelEditComponent={cancelEdit}
            disabled={disabled}
            images={images}
            linkPreviewParams={oglink}
            maxImageNumber={COMMENT_CREATION_MAX_IMAGE_NUMBER}
            mediaMaxImagesError={textResources.postCreate.mediaMaxImagesError(POST_CREATION_MAX_IMAGE_NUMBER)}
            message={message}
            mode={mode}
            onError={this.onError}
            placeholder={labels.placeHolder_WEB}
            sponsorLevel={sponsorLevel}
            submit={submit}
            update={this.onUpdate}
            userImageUrl={userImageUrl}
            refProp={refProp}
          />
        </UserOnboardingCTA>
      </form>
    );
  }

  private onUpdate(message: string, images: string[], ogLinkId?: string): void {
    const { commentId, update, updateUrl } = this.props;
    if (commentId && update && updateUrl) {
      update(commentId, updateUrl, message, images, ogLinkId);
    }
  }

  private onError(error: IErrorResponse): void {
    reportError('Comment input error', error.errors);
  }
}
