import { IWindowJsEnv } from 'src/interfaces';
import { isReadyToAnnoyUser } from 'src/utils/location_storage/location_storage';

// constants
const _window = window as unknown as IWindowJsEnv;
const MAX_PAGE_COUNT_CTA = _window.js_env.userAnnoyer.newsfeedCalls;

export const isReadyToShowCTA = (totalPagesViewed: number): boolean => {
  return isReadyToAnnoyUser() && totalPagesViewed >= MAX_PAGE_COUNT_CTA;
};
