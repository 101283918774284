// TODO This is a temporary addition to gather information about the 'Illegal invocation'
//      error that seems to occur on iOS devies. Remove or refine the code below once
//      enough information has been collected.

import { reportError } from 'src/utils/reporting/report-errors';

// Apply the workaround only if the browser runs on iOS. See also:
// https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
(() => {
  if (
    navigator &&
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !(window as any).MSStream &&
    document.createEvent &&
    document.createEvent.bind
  ) {
    (() => {
      const sessionId: number = Math.floor((1 + Math.random()) * 0x10000);
      const isReported: { [key: string]: boolean } = {};
      const nativeDocumentCreateEvent = document.createEvent.bind(document);
      let result: any;
      let exception1: Error;
      let exception2: Error;
      document.createEvent = function createEventOverride(evtType: string) {
        // Try the native but old-fashined way to create events first
        try {
          result = nativeDocumentCreateEvent(evtType);
        } catch (e) {
          exception1 = e;
          // Try the modern way to create events second
          try {
            // Security check to ensure that `type` only consists of letters
            if (/^\w+$/.test(evtType)) {
              // eslint-disable-next-line no-new-func
              result = (new Function('return new ' + evtType + '(null);'))();
            } else {
              throw new Error('invalid event type format: ' + evtType);
            }
          } catch (e) {
            exception2 = e;
            // Just return `null` if anything else fails
            result = null;
          }
        }
        // Report error, if an exception exists, which has not been reported yet.
        if (!isReported[evtType] && exception2) {
          isReported[evtType] = true;
          reportError(
            'native version and workaround for document.createEvent() failed',
            { evtType, exception1, exception2, navigator, result, sessionId },
          );
        }
        return result;
      };
    })();
  }
})();
