// libs
import { flowRight } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

// interfaces / constants
import { FullProfileType } from 'src/interfaces/profile';
import { IRootState } from 'src/reducers/interface';
import { PostUpdateObjType, UPDATE_POST } from 'src/utils/reporting/events_tracking/events_tracking';

// classes / components
import PostUpdate,
{
  IMapRouteToProps,
  IOwnProps,
  IPostUpdateStoreState,
  IPostUpdateActions,
} from 'src/components/post/update/update';
import withLoadingSpinner from 'src/high_order_components/with_loading_spinner';

// helpers
import { fetchCategories } from 'src/actions/categories/categories';
import { updateEvent, updatePost } from 'src/actions/post/post';
import { withRouterProps } from 'src/high_order_components/connect_route_props';
import { withTrackerProps } from 'src/high_order_components/with_tracker_props';

const dispatchToProps = {
  fetchCategories: () => fetchCategories('Event'),
  updateEvent,
  updatePost,
};

const mapStateToProps = (state: IRootState): IPostUpdateStoreState => {
  const selectedProfileId = state.user.selectedProfileId;
  const profile = state.user.profiles[selectedProfileId];
  return ({
    eventCategories: state.categories,
    hasError: state.post.hasError,
    isLoading: state.post.isLoading,
    loggedInProfileAddress: profile.address,
    loggedInProfileLongIdentifier: profile.longIdentifier,
    loggedInProfilePermissions: profile.permissions,
    profileType: (profile.type as FullProfileType),
  });
};

const mapRouteToProps = ({ match, history, location }: RouteComponentProps): IMapRouteToProps => (
  {
    history,
    matchUrl: match.url,
    path: location.pathname,
    search: location.search,
    startPath: location.state && location.state.startPath,
  });

export default flowRight(
  withRouterProps<IMapRouteToProps, IOwnProps>(mapRouteToProps),
  connect<IPostUpdateStoreState, IPostUpdateActions, IOwnProps & IMapRouteToProps>(
    mapStateToProps,
    dispatchToProps,
  ),
  withTrackerProps<PostUpdateObjType, IOwnProps>(UPDATE_POST),
  withLoadingSpinner,
)(PostUpdate);
