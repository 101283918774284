// libs
import classNames from 'classnames';
import React, { useState, useRef } from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// components
import FloatingButton, { IProps as IFloatingButtonProps } from 'src/components/floating_button/floating_button';
import Option, { IOption } from 'src/components/floating_button_menu/option/option';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import { useClickOutside } from 'src/hooks_shared/use_click_outside';
import { textResources } from 'src/lang/de';

import './floating_button_menu.scss';

const cls = CLASS_PREFIX + 'floating-button-menu';
const optionWrapperCls = cls + '__option-wrapper';

interface IProps extends Omit<IFloatingButtonProps, 'onClick' | 'ref'> {
  expandedImage: string;
  options: IOption[];
  onClick?: () => void;
  initiallyExpanded?: boolean;
  featureBlocked?: boolean;
}

const FloatingButtonMenu: React.FC<IProps> = ({
  image,
  expandedImage,
  title,
  remAboveBottom,
  options,
  onClick,
  initiallyExpanded,
  featureBlocked,
}) => {
  const [expanded, setExpanded] = useState(!!initiallyExpanded);

  const buttonRef = useRef<HTMLDivElement>(null);
  const collapse = () => {
    setExpanded(false);
  };

  useClickOutside(buttonRef, collapse);

  const hasMultipleOptions = options.length > 1;

  const handleClick = () => {
    if (hasMultipleOptions) {
      setExpanded(!expanded);
    }
    onClick && onClick();
  };
  const optionWrapperClsBlock = classNames({
    [optionWrapperCls]: true,
    [optionWrapperCls + '--expanded']: expanded,
    [optionWrapperCls + '--above-banner']: remAboveBottom === 4,
  });

  const buttonJSX = (
    <>
      <FloatingButton
        remAboveBottom={remAboveBottom}
        image={expanded ? expandedImage : image}
        onClick={handleClick}
        title={expanded ? textResources.shared.cancel : title}
        ref={buttonRef}
        featureBlocked={featureBlocked}
      />
      <div className={optionWrapperClsBlock}>
        {hasMultipleOptions &&
          options.map((option, i) => (
            <Option key={i} {...option} shown={expanded}/>
          ))}
      </div>
    </>
  );

  if (options.length === 0 || hasMultipleOptions) {
    return (
      <div className={cls}>
        {buttonJSX}
      </div>
    );
  }
  return (
    <NavigationItem target='_self' url={options[0].url} className={cls}>
      {buttonJSX}
    </NavigationItem>

  );
};

export default FloatingButtonMenu;
