// libs
import { History } from 'history';
import * as React from 'react';

// interfaces / constants
import api,
{ IEventUpdateData, IEventUpdateParam, IPostUpdateData, IPostUpdateParam } from 'src/api/index';
import { IErrorResponse } from 'src/api/interfaces/errors';
import { FEED_POST_TYPE_EVENT } from 'src/constants/feed';
import { IAddress } from 'src/interfaces/address';
import { IImageObject } from 'src/interfaces/attachment';
import { IEventCategory } from 'src/interfaces/categories';
import { ILinkPreview } from 'src/interfaces/post_ingredients';
import { IEventEditView, IPost, IPostEditView } from 'src/interfaces/posts';
import { FullProfileType } from 'src/interfaces/profile';
import { IPermissions } from 'src/interfaces/user';
import { IEventData } from 'src/post-creation-wizard/wizards/common/interfaces';
import { IUpdatePostTracker, TrackingLabel } from 'src/utils/reporting/events_tracking/events_tracking';

// classes
import Modal from 'src/components/modal/modal';

// helpers
import { getLabel, postSubmitTracking } from 'src/components/post/utils';
import { attachmentToImage, getUpdatedImages } from 'src/utils/attachment/attachment';
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import { reportError, reportOnlyErrorObject } from 'src/utils/reporting/report-errors';

const PostUpdateWizard = React.lazy(
  () => import(/* webpackChunkName: "post-creation-wizard" */'src/post-creation-wizard/wizards/update/update_wizard'));

type IPostUpdateProps = IOwnProps & IPostUpdateStoreState & IPostUpdateActions & IMapRouteToProps;

export interface IMapRouteToProps {
  matchUrl: string;
  history: History;
  path: string;
  search: string;
  startPath?: string;
}

export interface IOwnProps {
  post: IPost;
  onClose: () => void;
  tracking: IUpdatePostTracker;
}

export interface IPostUpdateStoreState {
  eventCategories: IEventCategory[];
  hasError: boolean;
  isLoading: boolean;
  loggedInProfileAddress?: IAddress;
  loggedInProfileLongIdentifier: string;
  loggedInProfilePermissions?: IPermissions;
  profileType: FullProfileType;
}

export interface IPostUpdateActions {
  updatePost: (param: IPostUpdateParam, history: History, track: (label: TrackingLabel) => void) => void;
  updateEvent: (param: IEventUpdateParam, history: History, track: (label: TrackingLabel) => void) => void;
  fetchCategories: () => void;
}

interface IState {
  editView?: IEventEditView | IPostEditView;
  body: string;
  eventData: IEventData;
  title: string;
  savedImages: IImageObject[];
  titleImageIndex: number;
  draftedImages: IImageObject[][];
  linkPreviewParams?: ILinkPreview;
}

export default class PostUpdate extends React.PureComponent<IPostUpdateProps, IState> {
  constructor(props: IPostUpdateProps) {
    super(props);
    this.fetchEventEditView = this.fetchEventEditView.bind(this);
    this.fetchPostEditView = this.fetchPostEditView.bind(this);
    this.hideWizard = this.hideWizard.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.updatePost = this.updatePost.bind(this);
    this.setBody = this.setBody.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.setTitleImageIndex = this.setTitleImageIndex.bind(this);
    this.setDraftedImages = this.setDraftedImages.bind(this);
    this.setSavedImages = this.setSavedImages.bind(this);
    this.updateEventData = this.updateEventData.bind(this);
    this.cleanUpImages = this.cleanUpImages.bind(this);
    this.getPostUpdateData = this.getPostUpdateData.bind(this);
    this.getEventUpdateData = this.getEventUpdateData.bind(this);
    this.setLinkPreviewParams = this.setLinkPreviewParams.bind(this);
    this.submitTracking = this.submitTracking.bind(this);

    this.state = {
      body: '',
      draftedImages: [],
      editView: undefined,
      eventData: {
        category: undefined,
        endDate: undefined,
        endTime: undefined,
        startDate: undefined,
        startTime: undefined,
      },
      linkPreviewParams: undefined,
      savedImages: [],
      title: '',
      titleImageIndex: 0,
    };
  }

  public componentDidMount() {
    const { post } = this.props;
    if (post.meta.contentItemType === FEED_POST_TYPE_EVENT) {
      this.fetchEventEditView();
    } else {
      this.fetchPostEditView();
    }
  }

  public render() {
    const { editView } = this.state;
    const {
      eventCategories,
      fetchCategories,
      isLoading,
      hasError,
      loggedInProfileLongIdentifier,
      post,
      history,
      matchUrl,
      search,
      startPath,
      path,
      profileType,
      tracking,
    } = this.props;

    if (!editView) { return null; }

    const { body, title, savedImages, draftedImages, eventData,
      titleImageIndex, linkPreviewParams } = this.state;

    const params = {
      address: this.props.post.address,
      advertised: this.props.post.meta.advertised,
      basePath: matchUrl,
      body,
      cleanUpImages: this.cleanUpImages,
      currentPath: path,
      draftedImages,
      eventCategories,
      eventData,
      fetchCategories,
      getEventUpdateData: this.getEventUpdateData,
      getPostUpdateData: this.getPostUpdateData,
      hasError,
      history,
      isLoading,
      label: getLabel(profileType),
      linkPreviewParams,
      loggedInProfileLongIdentifier,
      onCancel: this.hideWizard,
      onEnd: this.hideWizard,
      postId: post.id,
      postType: this.props.post.meta.contentItemType,
      profileType,
      savedImages,
      search,
      setBody: this.setBody,
      setDraftedImages: this.setDraftedImages,
      setLinkPreviewParams: this.setLinkPreviewParams,
      setSavedImages: this.setSavedImages,
      setTitle: this.setTitle,
      setTitleImageIndex: this.setTitleImageIndex,
      startPath,
      title,
      titleImageIndex,
      tracking,
      updateEvent: this.updateEvent,
      updateEventData: this.updateEventData,
      updatePath: post.meta.urls.update,
      updatePost: this.updatePost,
    };
    return (
      <Modal onClose={this.hideWizard}>
        <PostUpdateWizard
          {...params}
        />
      </Modal>
    );
  }

  private setLinkPreviewParams(linkPreviewParams: ILinkPreview) {
    this.setState({ linkPreviewParams });
  }

  private cleanUpImages() {
    this.setState({ draftedImages: [] });
  }

  private setSavedImages(savedImages: IImageObject[]) {
    this.setState({ savedImages });
  }

  private setDraftedImages(draftedImages: IImageObject[][]) {
    this.setState({ draftedImages });
  }

  private setBody(body: string) {
    this.setState({ body });
  }

  private setTitle(title: string) {
    this.setState({ title });
  }

  private updateEventData(newEventData: IEventData) {
    this.setState({ eventData: { ...this.state.eventData, ...newEventData } });
  }

  private setTitleImageIndex(index: number) {
    this.setState({ titleImageIndex: index });
  }

  private fetchPostEditView() {
    const { post } = this.props;
    api.post.edit(post.meta.urls.edit).then((editView: IPostEditView) => {
      const imageUrls = editView.attachments.map(attachmentToImage);
      this.setState({
        body: editView.body,
        editView,
        linkPreviewParams: editView.linkPreview,
        savedImages: imageUrls,
        title: editView.title,
      });
    }).catch((reason: IErrorResponse) => {
      reportOnlyErrorObject(reason, { description: 'failed to get fetchPostEditView' });
    });
  }

  private fetchEventEditView() {
    const { post } = this.props;

    api.event.edit(post.meta.urls.edit).then((editView: IEventEditView) => {
      const imageUrls = editView.attachments.map(attachmentToImage);
      const startDate = DateFormatter.splitDateFromTime(editView.startTime);
      const startTime = DateFormatter.asTime(editView.startTime);
      const endDate = editView.endTime && DateFormatter.splitDateFromTime(editView.endTime);
      const endTime = editView.endTime && DateFormatter.asTime(editView.endTime);
      this.setState({
        body: editView.body,
        editView,
        eventData: {
          category: editView.categories[0].id,
          endDate,
          endTime,
          startDate,
          startTime,
        },
        linkPreviewParams: editView.linkPreview,
        savedImages: imageUrls,
        title: editView.title,
      });
    }).catch((reason: IErrorResponse) => {
      reportOnlyErrorObject(reason, { description: 'failed to get fetchEventEditView' });
    });
  }

  private hideWizard() {
    const { onClose } = this.props;
    onClose();
  }

  private getPostUpdateData(): IPostUpdateData {
    const { editView, title, body, savedImages, titleImageIndex, linkPreviewParams } = this.state;
    const old = editView as IPostEditView;
    const oglinkId = linkPreviewParams && linkPreviewParams.id ? linkPreviewParams.id : undefined;
    const updatedOglink = oglinkId || null;
    const oglinkValidCondition1 = old.linkPreview && old.linkPreview.id !== oglinkId;
    const oglinkValidCondition2 = !old.linkPreview && oglinkId !== undefined;

    // reorder the images array to have the title image first
    let imageUrls = savedImages;
    if (!!imageUrls.length && titleImageIndex > 0 && titleImageIndex < imageUrls.length) {
      imageUrls = [imageUrls[titleImageIndex], ...imageUrls.filter((_val, i) => i !== titleImageIndex)];
    }

    const param = {
      body: body !== old.body ? body : undefined,
      linkPreviewId: oglinkValidCondition1 || oglinkValidCondition2 ? updatedOglink : undefined,
      title: title !== old.title ? title : undefined,
      ...getUpdatedImages(old.attachments, imageUrls),
    };
    return param;
  }

  private getEventUpdateData(): IEventUpdateData | undefined {
    const { category: eventCategory, endDate: eventEndDate, endTime: eventEndTime,
      startDate: eventStartDate, startTime: eventStartTime } = this.state.eventData;
    const { editView } = this.state;

    const old = editView as IEventEditView;

    if (!eventStartDate || !eventStartTime || !eventCategory) {
      reportError(`Event update - missing param`, { eventCategory, eventStartDate, eventStartTime });
      return undefined;
    }

    const categoryId = eventCategory;
    const startTime = DateFormatter.toDateTimeISOString(eventStartDate, eventStartTime);
    let endTime;
    if (eventEndDate && eventEndTime) {
      endTime = DateFormatter.toDateTimeISOString(eventEndDate, eventEndTime);
    }

    const updatedEndTime = endTime;
    const endTimeValidCondition1 = old.endTime && old.endTime !== endTime;
    const endTimeValidCondition2 = !old.endTime && endTime !== undefined;
    const commonParam = this.getPostUpdateData();

    return {
      ...commonParam,
      categoryId: categoryId !== old.categories[0].id ? categoryId : undefined,
      endTime: endTime !== endTimeValidCondition1 || endTimeValidCondition2 ? updatedEndTime : undefined,
      startTime: startTime !== old.startTime ? startTime : undefined,
    };
  }

  private updatePost() {
    const { post, history } = this.props;
    this.props.updatePost(
      { data: this.getPostUpdateData(), path: post.meta.urls.update, postId: post.id },
      history,
      this.submitTracking
    );
  }

  private updateEvent() {
    const { post, history } = this.props;
    const data = this.getEventUpdateData();
    data && this.props.updateEvent(
      { data, path: post.meta.urls.update, postId: post.id },
      history,
      this.submitTracking
    );
  }

  private submitTracking(label: TrackingLabel) {
    const { post, tracking } = this.props;
    const postType = post.meta.contentItemType;
    postSubmitTracking(postType, label, tracking);
  }
}
