// libs
import * as React from 'react';

// interfaces / constants
import { ActivableNavigationItems } from 'src/components/header/navigation/header_navigation';

// components
import { TWO_COLUMNS } from 'src/constants/layout';
import LayoutBase from 'src/layout/base/layout_base';

// helpers

interface IProps {
  subHeader?: JSX.Element;
  activeNavigationItem?: ActivableNavigationItems;
  pageTitle: JSX.Element;
  sideBarContent: JSX.Element;
  sideBarOnlyVisibleOnDesktop?: boolean;
  withHeaderMargin?: boolean;
  withSidebar?: boolean;
}

const LayoutCustomSideBar: React.FC<IProps> = ({
  activeNavigationItem,
  subHeader,
  children,
  pageTitle,
  sideBarContent,
  sideBarOnlyVisibleOnDesktop = false,
  withHeaderMargin = false,
  withSidebar = true,
}) => {
  return (
    <LayoutBase
      subHeader={subHeader}
      activeNavigationItem={activeNavigationItem}
      enableMapToggler={false}
      isMapShown={false}
      showBanner={false}
      pageTitle={pageTitle}
      withHeaderMargin={withHeaderMargin}
      sideBarContent={sideBarContent}
      sideBarOnlyOnDesktop={sideBarOnlyVisibleOnDesktop}
      contentWidth={TWO_COLUMNS}
      withSidebar={withSidebar}
      showStaticPagesFooter={false}
    >
      {children}
    </LayoutBase>
  );
};

export default LayoutCustomSideBar;
