// lib
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import Notifications from 'src/containers/notifications';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { NOTIFICATIONS } from 'src/constants/feed';
import { TWO_COLUMNS } from 'src/constants/layout';
import { NOTIFICATIONS_TITLE } from 'src/constants/meta_tags';
import { textResources } from 'src/lang/de';

// helpers
import { URLContext } from 'src/containers/url_provider/url_provider';
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';

const NotificationsPage: React.FC<RouteComponentProps> = () => {
  const user = useSelectUser();

  const { getResourceLinkRoute } = useContext(URLContext);

  usePageTracking({ user });

  return (
    <>
      <MetaTags
        title={NOTIFICATIONS_TITLE}
      />
      <LayoutNoMap
        activeNavigationItem={NOTIFICATIONS}
        contentWidth={TWO_COLUMNS}
        pageTitle={<StandardPageTitle text={textResources.pageTitles.notifications} />}
      >
        <Notifications getResourceLinkRoute={getResourceLinkRoute} />
      </LayoutNoMap>
    </>
  );
};

export default NotificationsPage;
