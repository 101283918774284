import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { IFeedType } from 'src/interfaces/feed';
import { useFeedPaginationInfo } from 'src/reducers/feed/feed_hooks';
import { useGetQueryParam } from './use_url_search_param';

export const useFeedWithPagination =
  (feedType: IFeedType): {page: number; totalPages: number; getPageUrl: (page: number) => string} => {
    const pageParam = useGetQueryParam('page');
    const page = pageParam ? parseInt(pageParam, 10) : 1;

    const location = useLocation();

    const { totalPages } = useFeedPaginationInfo(feedType);

    const getPageUrl = useCallback((page: number) => {
      const search = new URLSearchParams(location.search);
      if (page <= 1) {
        search.delete('page');
      } else {
        search.set('page', `${page}`);
      }

      const searchString = search.toString();
      return searchString ? location.pathname + '?' + searchString : location.pathname;
    }, [location]);

    return { getPageUrl, page, totalPages };
  };
