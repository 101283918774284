// libs
import React, { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

// interfaces / constants
import { FEED_POST_TYPE_POST, FEED_POST_TYPE_EVENT } from 'src/constants/feed';

// components
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import ProfilesList from 'src/components/profiles_list/profiles_list';

// helpers
import {
  useGetReactedProfiles,
} from 'src/actions/profiles_list/profiles_list_hooks';
import {
  useProfilesSecondaryList, useSecondaryProfiles,
} from 'src/reducers/profiles_list/profiles_list_hooks';
import { UrlUtils } from 'src/utils/url/url';

const ReactedProfilesList: React.FC = () => {
  const profilesList = useSecondaryProfiles();
  const { isLoading } = useProfilesSecondaryList();
  const postId = useParams<{ id?: string }>().id;

  const { pathname } = useLocation();

  const itemType = UrlUtils.isInPostDetailPage(pathname) ? FEED_POST_TYPE_POST : FEED_POST_TYPE_EVENT;

  const getFollowers = useGetReactedProfiles();

  useEffect(() => {
    if (postId) {
      getFollowers(itemType, postId);
    }
  }, [getFollowers, postId, itemType]);

  return (
    <LoadingSpinnerWrapper isLoading={isLoading}>
      {profilesList.length > 0 &&
      <ProfilesList
        profiles={profilesList}
      />
      }
    </LoadingSpinnerWrapper>
  );
};

ReactedProfilesList.displayName = 'ReactedProfilesList';

export default ReactedProfilesList;
