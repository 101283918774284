// libs
import * as React from 'react';

// components
import LoadingSpinner, { IProps } from 'src/components/loading_spinner/loading_spinner';
import Overlay from 'src/components/overlay/overlay';

const LoadingSpinnerFullscreen: React.FC<IProps> = ({ shown }) => {
  return (
    <Overlay>
      <LoadingSpinner shown={shown} />
    </Overlay>
  );
};

export default LoadingSpinnerFullscreen;
