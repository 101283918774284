// libs
import classNames from 'classnames';
import * as React from 'react';

// components

// helpers
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import './content_placeholder.scss';

const cls = createBemBlock('content-placeholder');

interface IProps {
  className: string;
}

const ContentPlaceholder: React.FC<IProps> = ({ className }) => (
  <div className={classNames(cls(), className)}></div>
);

export default ContentPlaceholder;
