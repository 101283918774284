// libs
import React, { useContext } from 'react';
import { Redirect } from 'react-router';

// interfaces
import {
  Notification,
} from 'src/interfaces/notification';

// constants
import { CLASS_PREFIX } from 'src/constants/';
import { URLContext } from 'src/containers/url_provider/url_provider';

// components
import BookmarkNotification from 'src/components/notifications/bookmark/bookmark_notification';
import CommentNotification from 'src/components/notifications/comment/comment_notification';
import EventParticipationNotification from 'src/components/notifications/event_participation/event_participation_notification';
import FollowNotification from 'src/components/notifications/follow/follow_notification';
import GroupNotification from 'src/components/notifications/group/group_notification';
import GroupAccessConfirmationNotification from 'src/components/notifications/group_access_confirmation/group_access_confirmation';
import MessageNotification from 'src/components/notifications/message/message_notification';
import UnknownNotification from 'src/components/notifications/unknown/unknown_notification';

// helpers
import {
  MESSAGE,
  BOOKMARK,
  COMMENT,
  GROUP_POST,
  GROUP_ACCESS_CONFIRMATION,
  FOLLOW,
  FOLLOWEE_POST,
  FOLLOWEE_EVENT,
  UNKNOWN,
  EVENT_PARTICIPATION,
  RECOMMENT,
} from 'src/constants/notifications';
import { useScrollListener } from 'src/hooks_shared/use_event_listener';
import { textResources } from 'src/lang/de';
import './notifications.scss';

const cls = `${CLASS_PREFIX}notifications`;
const clsEmptyNotifications = `${cls}__empty`;
const clsList = `${cls}__list`;

interface IProps {
  callToAction: () => void;
  // do we have permissions to read; if not, reroute to Newsfeed and show CTA
  canReadNotifications: boolean;
  // array of notifications we want to render
  notifications: Notification[];
  // callback when we click on a Notification
  onNotificationClick: (notification: Notification) => () => void;
  // the count of unread notifications
  unreadNotificationsCount: number;
  // callback when we click on a NotificationButton
  fetchMoreNotifications: () => void;
}

const renderNotification = (notification: Notification,
                            index: number,
                            onClick: (e: React.MouseEvent<HTMLElement>) => void) => {
  switch (notification.eventCode) {
    case MESSAGE:
      return <MessageNotification {...notification} key={index} onClick={onClick} />;
    case BOOKMARK:
      return (<BookmarkNotification {...notification} key={index} onClick={onClick} />);
    case COMMENT:
    case RECOMMENT:
      return (<CommentNotification {...notification} key={index} onClick={onClick} />);
    case GROUP_POST:
      return (<GroupNotification {...notification} key={index} onClick={onClick} />);
    case GROUP_ACCESS_CONFIRMATION:
      return (<GroupAccessConfirmationNotification {...notification} key={index} onClick={onClick} />);
    case FOLLOW:
    case FOLLOWEE_POST:
    case FOLLOWEE_EVENT:
      return (<FollowNotification {...notification} key={index} onClick={onClick} />);
    case EVENT_PARTICIPATION:
      return (<EventParticipationNotification {...notification} key={index} onClick={onClick} />);
    case UNKNOWN:
      return (<UnknownNotification {...notification} key={index} onClick={onClick} />);
  }
};

const Notifications: React.FC<IProps> = ({
  callToAction,
  canReadNotifications,
  fetchMoreNotifications,
  notifications,
  onNotificationClick,
}) => {
  const listener = () => {
    fetchMoreNotifications();
  };

  useScrollListener(listener, { passive: true });

  const { getNewsfeedRoute } = useContext(URLContext);

  if (!canReadNotifications) {
    callToAction();
    return <Redirect to={getNewsfeedRoute()} />;
  }
  return (
    <div className={cls}>
      {notifications.length
        ? (
          <ul className={clsList}>
            {notifications.map((item, index) => (
              renderNotification(item, index, onNotificationClick(item))
            ))}
          </ul>
        )
        : (<p className={clsEmptyNotifications}>{textResources.notification.noneYet}</p>)
      }
    </div>
  );
};

export default Notifications;
