// libs
import React, { useEffect } from 'react';

// components
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import ProfilesList from 'src/components/profiles_list/profiles_list';

// interfaces / constants

// helpers
import { useGetShapeSponsors } from 'src/actions/shape_sponsors/shape_sponsors_hooks';
import { useGetBoundingBox } from 'src/reducers/app-state/app-state_hooks';
import {
  useProfilesList,
} from 'src/reducers/profiles_list/profiles_list_hooks';
import { useSelectShapeSponsors } from 'src/reducers/shape_sponsors/shape_sponsor_hooks';

export const ShapeSponsorsList: React.FC = () => {
  const { isLoading } = useProfilesList();
  const boundingBox = useGetBoundingBox();
  const { bronze: bronzeProfiles = [], silver: silverProfiles = [] } = useSelectShapeSponsors();

  const getLocalPartners = useGetShapeSponsors();

  useEffect(() => {
    getLocalPartners(boundingBox);
  }, [boundingBox, getLocalPartners]);

  return (
    <LoadingSpinnerWrapper isLoading={isLoading}>
      <ProfilesList profiles={[...silverProfiles, ...bronzeProfiles]} />
    </LoadingSpinnerWrapper>
  );
};

export default ShapeSponsorsList;
