// lib
import React from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';

// components
import GroupOverview from 'src/components/groups/group_overview/group_overview';
import MetaTags from 'src/components/meta_tags/meta_tags';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { GROUP_OVERVIEW } from 'src/constants/feed';
import { GROUP_OVERVIEW_TITLE, GROUP_OVERVIEW_DESCRIPTION } from 'src/constants/meta_tags';
import { textResources } from 'src/lang/de';

// helpers
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';

const GroupsOverviewPage: React.FC<RouteComponentProps> = () => {
  const user = useSelectUser();
  const appLocationShape = useGetAppLocationShape();

  const title = appLocationShape && GROUP_OVERVIEW_TITLE(appLocationShape.name);

  usePageTracking({ pageTitle: title, user });

  return (
    <>
      <MetaTags
        title={title}
        description={GROUP_OVERVIEW_DESCRIPTION}
      />
      <LayoutNoMap
        activeNavigationItem={GROUP_OVERVIEW}
        pageTitle={<StandardPageTitle text={textResources.pageTitles.groupOverview} />}
        withHeaderMargin
      >
        {appLocationShape
          ? <GroupOverview />
          : <Redirect to='/' />
        }
      </LayoutNoMap>
    </>
  );
};

export default GroupsOverviewPage;
