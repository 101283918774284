// libs
import * as H from 'history';
import { flowRight } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

// interfaces
import { IAction } from 'src/actions/';
import { IWindowJsEnv } from 'src/interfaces/';
import { IPost } from 'src/interfaces/posts';
import { IRootState } from 'src/reducers/interface';

// constants
import { CLASS_PREFIX } from 'src/constants';
import { FEED_TYPE_DETAILS_PAGE } from 'src/constants/feed';
import { FRONTEND_EVENT_PARTICIPANTS_PATH, FRONTEND_NOT_FOUND_PATH } from 'src/constants/urls';
import { PostDetailsObjType, POST_DETAILS } from 'src/utils/reporting/events_tracking/events_tracking';
// classes / components
import PostDetails from 'src/components/post/detail/post_detail';
import withLoadingSpinner from 'src/high_order_components/with_loading_spinner';

// helpers
import { changeAppLocation, closeTopbarPopupMenu } from 'src/actions/app-state/app-state';
import { callToActionWithFlag as flaggedCTA } from 'src/actions/onboarding_cta/onboarding_cta';
import { getPost, ILocationState } from 'src/actions/post/post';
import api from 'src/api/index';
import { withRouterProps } from 'src/high_order_components/connect_route_props';
import { withTrackerProps, ITrackingProps } from 'src/high_order_components/with_tracker_props';
import { ILocationShape, IPosition } from 'src/interfaces/location';
import { textResources } from 'src/lang/de';
import { reportError } from 'src/utils/reporting/report-errors';
import { parseQueryString, UrlUtils } from 'src/utils/url/url';
import { isPrerenderIoAgent } from 'src/utils/user_agent/user_agent';
import { WithSurveyProps } from '../../plugins/survey/hoc/model';
import withSurvey from '../../plugins/survey/hoc/withSurvey';

type IOwnProps = IContainerProps & IMapRouteToProps;

interface IMapStateToProps {
  isLoading: boolean;
  loginRequestPending?: boolean;
  post: IPost | undefined;
  hasError: boolean;
  canReadPremiumContent: boolean;
  loggedIn: boolean;
}

interface IDispatchFromProps {
  callToActionWithFlag: () => void;
  closePopups: () => void;
  fetchPost: (previewToken?: string) => void;
  centerMap: (locationShape: ILocationShape, position: IPosition) => void;
}

type IProps = IOwnProps & IMapStateToProps & IDispatchFromProps & ITrackingProps<PostDetailsObjType> & WithSurveyProps;

const _window = window as unknown as IWindowJsEnv;
const PREMIUM_CONTENT_TIMEOUT = _window.js_env.userAnnoyer.timerOnDetailPage * 1000;

const cls = CLASS_PREFIX + 'post__detail-container';

const mapStateToProps = ({ user, feed }: IRootState, ownProps: IOwnProps): IMapStateToProps => {
  const currentFeed = feed.feeds[FEED_TYPE_DETAILS_PAGE];

  return {
    canReadPremiumContent: !!user.profiles[user.selectedProfileId].permissions?.canReadPremiumContent,
    hasError: currentFeed.hasError,
    isLoading: currentFeed.isLoading,
    loggedIn: user.loggedIn,
    loginRequestPending: user.loginRequestPending,
    post: feed.posts[ownProps.id],
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, IAction>, ownProps: IOwnProps) => ({
  callToActionWithFlag: () => dispatch(flaggedCTA(999999)),
  centerMap: (locationShape: ILocationShape, position: IPosition) =>
    dispatch(changeAppLocation(locationShape, position, undefined, true, locationShape.boundingBox)),
  closePopups: () => dispatch(closeTopbarPopupMenu()),
  fetchPost: (previewToken?: string) => {
    dispatch(getPost(UrlUtils.apiShowPath(ownProps.id, ownProps.postType),
      ownProps.id, ownProps.history, previewToken));
  },
});
class PostDetailsContainer extends React.PureComponent<IProps> {
  private timeoutId: number;
  private isDeepLinking: boolean;

  constructor(props: IProps) {
    super(props);
    this.isDeepLinking = false;
    this.addViewCount = this.addViewCount.bind(this);
  }

  public componentDidMount() {
    const { post, closePopups, previewToken, fetchPost } = this.props;
    this.scrollTop();

    // Don't show userAnnoyer on premiumContent, see #4784.
    if (this.shouldActivateCTA(post)) {
      this.timeoutId = this.setTimeout();
    }
    closePopups();

    if (post) {
      this.centerMap();
      return;
    }

    fetchPost(previewToken);
    this.isDeepLinking = true;
  }

  public componentDidUpdate(prevProps: IProps) {
    const { id, isLoading, fetchPost, loginRequestPending, post, isSurveyEnabled } = this.props;
    if (isSurveyEnabled && this.props.post?.survey) {
      this.props.history.push(FRONTEND_NOT_FOUND_PATH);
    }
    if (prevProps.id !== id) {
      this.scrollTop();
    }

    if (!isLoading && !this.props.hasError && !this.props.post && !loginRequestPending) {
      fetchPost(this.props.previewToken);
      return;
    }

    // In Deeplinks we only get the post on DidUpdate
    if (!prevProps.post && this.shouldActivateCTA(post)) {
      this.timeoutId = this.setTimeout();
    }

    this.centerMap();
  }

  public componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  public isGoingToParticipantsList(): boolean {
    return this.props.history.location.pathname.includes(FRONTEND_EVENT_PARTICIPANTS_PATH);
  }

  public render() {
    const {
      isLoading,
      post,
      tracking,
    } = this.props;

    if (!post) {
      if (isLoading) {
        return null;
      }

      return (
        <div className={cls}>
          <h1>{textResources.shared.pageNotFound}</h1>
        </div>
      );
    }

    return (
      <PostDetails
        addViewCount={this.addViewCount}
        post={post}
        history={this.props.history}
        canReadPremiumContent={this.props.canReadPremiumContent}
        tracking={tracking}
      />
    );
  }

  private scrollTop() {
    (window as any).scrollTo(0, 0);
  }

  private centerMap() {
    const { post, isLoading, centerMap, loggedIn } = this.props;
    if (isLoading || !post) {
      return;
    }

    if (post.position.latitude) {
      const { latitude, longitude } = post.position;
      if (this.isDeepLinking && !loggedIn) {
        centerMap(post.locationShape, { latitude, longitude });
      }
      return;
    }
    reportError('Post details page received a post with no position:', post);
  }

  private async addViewCount() {
    if (isPrerenderIoAgent()) {
      return;
    }

    const { post } = this.props;
    if (post) {
      api.post.addView(post.meta.urls.addView)
        .catch((error) => reportError('AddView for this post failed:', { error, post }));
    }
  }

  private setTimeout(): number {
    const { callToActionWithFlag } = this.props;
    return window.setTimeout(callToActionWithFlag, PREMIUM_CONTENT_TIMEOUT);
  }

  private shouldActivateCTA(post?: IPost): boolean {
    // On premium and fenced post, we won't trigger a CTA after certain seconds. See #4784
    return !!post && !post.meta.premiumContent && !post.meta.fenced;
  }
}

interface IMapRouteToProps {
  history: H.History;
  id: string;
  previewToken?: string;
  path: string;
  locationState: ILocationState;
}

interface IContainerProps {
  postType: 'event' | 'post' | 'survey';
}

const mapRouteToProps = ({ match, history, location }: RouteComponentProps<{id: string}>): IMapRouteToProps => (
  {
    history,
    id: match.params.id,
    locationState: location.state,
    path: location.pathname,
    previewToken: parseQueryString(location.search, [
      'pt',
    ]).pt,
  });

export default flowRight(
  withRouterProps<IMapRouteToProps, IContainerProps>(mapRouteToProps),
  connect<IMapStateToProps, IDispatchFromProps, IOwnProps>(mapStateToProps, mapDispatchToProps),
  withTrackerProps(POST_DETAILS),
  withLoadingSpinner,
  withSurvey
)(PostDetailsContainer);
