// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import Registration from 'src/components/registration_page/registration_page';
import LayoutNoHeader from 'src/layout/layout_no_header';

// constants & interfaces
import { TWO_COLUMNS } from 'src/constants/layout';
import { REGISTRATION_TITLE } from 'src/constants/meta_tags';

// helpers
import { usePageTracking } from 'src/pages/hooks/hooks';
import { AuthModalType } from '../interfaces/app-state';

interface IOwnProps {
  layout: AuthModalType;
}

const RegistrationPage: React.FC<RouteComponentProps & IOwnProps> = ({ layout }) => {
  usePageTracking();

  return (
    <>
      <MetaTags
        title={REGISTRATION_TITLE}
      />
      <LayoutNoHeader contentWidth={TWO_COLUMNS}>
        <Registration layout={layout} />
      </LayoutNoHeader>
    </>
  );
};

export default RegistrationPage;
