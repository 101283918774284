import { invert } from 'lodash';

import {
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  PRIVATE_PROFILE_TYPE,
} from 'src/constants/profile';
import {
  PROFILE_LIST_AUTHORITY_URI,
  PROFILE_LIST_BLOG_URI,
  PROFILE_LIST_PRESS_URI,
  PROFILE_LIST_PRIVATE_URI,
} from 'src/constants/urls';

export const PROFILES_LIST_PER_PAGE = 20;

export const PROFILE_LIST_AUTHOR_TYPE_TO_URIS = {
  [AUTHORITY_PROFILE_TYPE]: PROFILE_LIST_AUTHORITY_URI,
  [BLOG_PROFILE_TYPE]: PROFILE_LIST_BLOG_URI,
  [PRESS_PROFILE_TYPE]: PROFILE_LIST_PRESS_URI,
  [PRIVATE_PROFILE_TYPE]: PROFILE_LIST_PRIVATE_URI,
} as const;

export const PROFILE_LIST_URIS_TO_AUTHOR_TYPE = invert(PROFILE_LIST_AUTHOR_TYPE_TO_URIS);
