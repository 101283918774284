import React, { FunctionComponent } from 'react';
import { FeatureFlag } from '../feature_flag';
import { navigationFeatureFlags } from './constants';
import { NavigationFeatureFlags } from './model';

interface IProps {
  flag?: NavigationFeatureFlags;
}
export const NavigationFeatureFlag: FunctionComponent<IProps> = ({ flag, children }) =>
  <FeatureFlag active={flag ? navigationFeatureFlags.get(flag) : true}>{children}</FeatureFlag>;
