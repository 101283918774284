// libs
import { History } from 'history';
import { ThunkAction } from 'redux-thunk';

// interfaces / constants
import { IAction, PromiseMiddlewareActions } from 'src/actions/';
import api, { IEventCreate, IEventUpdateParam, IPostCreate, IPostUpdateParam } from 'src/api/index';
import { IErrorResponse } from 'src/api/interfaces/errors';
import { RECORD_NOT_FOUND, GROUP_POST } from 'src/constants/api_error_codes';
import {
  API_EVENT_POST_PATH,
  API_POST_PATH,
  FRONTEND_NOT_FOUND_PATH,
} from 'src/constants/urls';
import {
  IContentItemType,
  IEventCreateData,
  IPost,
  IPostCreateData,
  IPostResponse,
  IPostSurveyCreateData,
} from 'src/interfaces/posts';
import { IRootState } from 'src/reducers/interface';

// helpers
import { Id } from 'src/interfaces';
import { TrackingLabel, FINISH_ACTION, FAIL_ACTION } from 'src/utils/reporting/events_tracking/events_tracking';
import { reportError } from 'src/utils/reporting/report-errors';
import { UrlUtils } from 'src/utils/url/url';

export const POST_REQUEST = 'POST_REQUEST';
export const POST_REQUEST_PENDING = 'POST_REQUEST_PENDING';
export const POST_REQUEST_SUCCESS = 'POST_REQUEST_SUCCESS';
export const POST_REQUEST_ERROR = 'POST_REQUEST_ERROR';
export const POST_CREATE_REQUEST = 'POST_CREATE_REQUEST';
export const POST_CREATE_REQUEST_PENDING = 'POST_CREATE_REQUEST_PENDING';
export const POST_CREATE_REQUEST_SUCCESS = 'POST_CREATE_REQUEST_SUCCESS';
export const POST_CREATE_REQUEST_ERROR = 'POST_CREATE_REQUEST_ERROR';
export const POST_DESTROY_REQUEST = 'POST_DESTROY_REQUEST';
export const POST_DESTROY_REQUEST_PENDING = 'POST_DESTROY_REQUEST_PENDING';
export const POST_DESTROY_REQUEST_ERROR = 'POST_DESTROY_REQUEST_ERROR';
export const POST_DESTROY_REQUEST_SUCCESS = 'POST_DESTROY_REQUEST_SUCCESS';
export const POST_UPDATE_REQUEST = 'POST_UPDATE_REQUEST';
export const POST_UPDATE_REQUEST_PENDING = 'POST_UPDATE_REQUEST_PENDING';
export const POST_UPDATE_REQUEST_SUCCESS = 'POST_UPDATE_REQUEST_SUCCESS';
export const POST_UPDATE_REQUEST_ERROR = 'POST_UPDATE_REQUEST_ERROR';

const getPostFromAPI = (apiUrl: string, expectedId: string, history: History, previewToken?: string) => {
  return api.post.get(apiUrl, { previewToken }).then((postResponse: IPostResponse) => {
    const { post } = postResponse;
    if (post.id !== expectedId) {
      history.replace(post.urls.frontend);
    }
    return postResponse;
  });
};

export interface ILocationState {
  shouldClearFeed: boolean;
}

interface IPostRequestAction {
  payload: Promise<IPostResponse>;
  type: typeof POST_REQUEST;
}

type PostRequestActions = PromiseMiddlewareActions<typeof POST_REQUEST_PENDING,
  typeof POST_REQUEST_ERROR,
  typeof POST_REQUEST_SUCCESS,
IPostRequestAction>;

export const getPost =
  (apiUrl: string,
   expectedId: string,
   history: History,
   previewToken?: string): ThunkAction<void, IRootState, {}, IPostRequestAction> => {
    /*
      From Issue #2760:
      When trying to open a deep-link, but the author changed the title of a post,
      the identifier provided by the back-end will change, too.
      So we have to give an expectedId for the post we want to fetch,
      because the component has no way to tell which post to show, if the ids don't match.
      If this expectedId does NOT match the post.id, we want to go to the frontend-url of this post,
      so we can avoid using the outdated id in our store.
    */
    return (dispatch) =>
      dispatch({
        payload: getPostFromAPI(apiUrl, expectedId, history, previewToken).catch((error: IErrorResponse) => {
          if (error.code === RECORD_NOT_FOUND) {
            history.push(FRONTEND_NOT_FOUND_PATH);
          } else if (error.code === GROUP_POST && error.data?.group) {
            history.push(UrlUtils.groupPage(error.data.group.identifier));
          }
          throw error;
        }),
        type: POST_REQUEST,
      });
  };

export type Track = (label: TrackingLabel) => void

const create = (param: IPostCreate, history: History, track: Track, redirectToUrl?: string, callback?: () => void) => {
  return {
    payload: api.post.create(param).then((response: IPostResponse) => {
      track(FINISH_ACTION);
      const locationState: ILocationState = { shouldClearFeed: true };
      const redirectTo = redirectToUrl || response.post.urls.frontend;
      callback && callback();
      setTimeout(() => history.push(redirectTo, locationState), 500);
      return response;
    }).catch((error) => {
      track(FAIL_ACTION);
      throw error;
    }),
    type: POST_CREATE_REQUEST,
  } as const;
};

const createEvent = (config: IEventCreate, history: History, track: Track) => {
  return {
    payload: api.event.create(config).then((response: IPostResponse) => {
      track(FINISH_ACTION);
      const locationState: ILocationState = { shouldClearFeed: true };
      setTimeout(() => history.push(response.post.urls.frontend, locationState), 500);
      return response;
    }).catch((error) => {
      track(FAIL_ACTION);
      throw error;
    }),
    type: POST_CREATE_REQUEST,
  } as const;
};

type PostCreateActions = PromiseMiddlewareActions<typeof POST_CREATE_REQUEST_PENDING,
  typeof POST_CREATE_REQUEST_ERROR,
  typeof POST_CREATE_REQUEST_SUCCESS,
ReturnType<typeof create> | ReturnType<typeof createEvent>>;

export const createPostWithCampaign =
  (data: IPostCreateData, callback: (contentItem: IPost) => void): ThunkAction<void, IRootState, {}, IAction> => {
    return (dispatch) => {
      const campaignAmount = data.campaignAmount || '0';
      const config: IPostCreate = {
        data: {
          body: data.body,
          campaignAmount,
          contentItemType: data.contentItemType,
          googlePlaceId: data.googlePlaceId,
          images: data.images,
          title: data.title,
        },
        path: API_POST_PATH,
      };
      return dispatch({
        payload: api.post.create(config).then((contentItem: IPostResponse) => {
          callback(contentItem.post);
          // TODO - take the url target of campaign when it will be added to the api
          return new Promise<Object>(function(resolve, reject) {
            resolve(contentItem);
            reject(new Error('Unable to create campaign'));
          });
        }),
        type: POST_CREATE_REQUEST,
      });
    };
  };

export const createPost = (
  data: IPostCreateData | IPostSurveyCreateData,
  postType: IContentItemType,
  history: History,
  track: Track,
  path?: string,
  redirectToUrl?: string,
  callback?: () => void
): ThunkAction<void, IRootState, {}, IAction> => {
  return (dispatch) => {
    const getPostCreateUrl = (): string => {
      switch (postType) {
        case 'privatePost':
          if (!path) {
            reportError('atempted to create private Post without providing a privatePostTopic url');
            return '';
          }
          return path;
        case 'survey':
          return path || API_POST_PATH;
        case 'publicPost':
        default:
          return API_POST_PATH;
      }
    };

    const config = {
      data,
      path: getPostCreateUrl(),
    };

    return dispatch(create(config, history, track, redirectToUrl, callback));
  };
};

export const createEventPost =
  (data: IEventCreateData, history: History, track: Track): ThunkAction<void, IRootState, {}, IAction> => {
    return (dispatch) => {
      const config = {
        data,
        path: API_EVENT_POST_PATH,
      };

      return dispatch(createEvent(config, history, track));
    };
  };

export const deletePost = (post: IPost, goToNewsfeed?: () => void) => ({
  meta: {
    postId: post.id,
  },
  payload: api.post.destroy(post.meta.urls.update).then(() => {
    const urlPath = UrlUtils.getCurrentUrlPath();
    UrlUtils.isInDetailPage(urlPath) && goToNewsfeed && goToNewsfeed();
  }),
  type: POST_DESTROY_REQUEST,
} as const);

const redirectTo = (url: string, history: History) => {
  const locationState: ILocationState = { shouldClearFeed: true };
  setTimeout(() => history.replace(url, locationState), 500);
};

type PostDeleteActions = PromiseMiddlewareActions<typeof POST_DESTROY_REQUEST_PENDING,
  typeof POST_DESTROY_REQUEST_ERROR,
  typeof POST_DESTROY_REQUEST_SUCCESS,
ReturnType<typeof deletePost>>;

export interface IPostUpdateSuccess {
  meta: {
    postId: Id;
  };
  payload: IPostResponse;
  type: typeof POST_UPDATE_REQUEST_SUCCESS;
}

export const updatePost = (param: IPostUpdateParam, history: History, track: Track) => {
  return {
    meta: {
      postId: param.postId,
    },
    payload: api.post.update(param).then((response: IPostResponse) => {
      track(FINISH_ACTION);
      redirectTo(response.post.urls.frontend, history);
      return response;
    }).catch((error) => {
      track(FAIL_ACTION);
      throw error;
    }),
    type: POST_UPDATE_REQUEST,
  } as const;
};

export const updateEvent = (param: IEventUpdateParam, history: History, track: Track) => {
  return {
    meta: {
      postId: param.postId,
    },
    payload: api.event.update(param).then((response: IPostResponse) => {
      track(FINISH_ACTION);
      redirectTo(response.post.urls.frontend, history);
      return response;
    }).catch((error) => {
      track(FAIL_ACTION);
      throw error;
    }),
    type: POST_UPDATE_REQUEST,
  } as const;
};

type PostUpdateActions = PromiseMiddlewareActions<typeof POST_UPDATE_REQUEST_PENDING,
  typeof POST_UPDATE_REQUEST_ERROR,
  typeof POST_UPDATE_REQUEST_SUCCESS,
ReturnType<typeof updatePost> | ReturnType<typeof updateEvent>>;

export type PostAction =
  PostRequestActions
  | PostCreateActions
  | PostUpdateActions
  | PostDeleteActions
  ;
