import React from 'react';
import { useFeedWithPagination } from 'src/hooks_shared/use_feed_pagination';
import { IFeedType } from 'src/interfaces/feed';
import { textResources } from 'src/lang/de';

import styles from './pagination_footer.module.scss';

interface IProps {
  feedType: IFeedType;
}

export const PaginationFooter: React.FC<IProps> = React.memo(({
  feedType,
}) => {
  const { page, totalPages, getPageUrl } = useFeedWithPagination(feedType);

  if (!totalPages) return null;

  return (
    <div className={styles.container}>
      {page > 1
        ? <a
          href={getPageUrl(1)}
          title={textResources.shared.paginationButtonTitle(1)}
          className={styles.otherPages}
        >
          1
        </a>
        : null
      }
      {page > 3 ? '…' : null}
      {page > 2
        ? <a
          href={getPageUrl(page - 1)}
          title={textResources.shared.paginationButtonTitle(page - 1)}
          className={styles.otherPages}
        >
          {page - 1}
        </a>
        : null}
      <span className={styles.currentPage}>
        {page}
      </span>
      {page + 1 < totalPages
        ? <a
          href={getPageUrl(page + 1)}
          title={textResources.shared.paginationButtonTitle(page + 1)}
          className={styles.otherPages}
        >
          {page + 1}
        </a>
        : null}
      {page + 2 < totalPages ? '…' : null}
      {page < totalPages
        ? <a
          href={getPageUrl(totalPages)}
          title={textResources.shared.paginationButtonTitle(totalPages)}
          className={styles.otherPages}
        >
          {totalPages}
        </a>
        : null}
    </div>
  );
});
