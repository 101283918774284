// libs
import React, { useCallback, useEffect, useState } from 'react';
// interfaces / constants
import { GetProfilesOrderBy } from 'src/api';
import { CLASS_PREFIX } from 'src/constants';
// components
import SelectField, { ISelectOption } from 'src/components/forms/select-field/select-field';
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import ProfilesList from 'src/components/profiles_list/profiles_list';
import ProfileListWrapper from 'src/containers/smart_components/list_wrapper/profile_list_wrapper';

// helpers
import { useMarkersInViewport } from 'src/actions/feed/feed_hooks';
import {
  useAuthorTypeFromURL,
  useGetProfiles,
  useGetProfilesNextPage,
} from 'src/actions/profiles_list/profiles_list_hooks';
import { textResources } from 'src/lang/de';
import { useGetBoundingBox } from 'src/reducers/app-state/app-state_hooks';
import {
  useProfilesSecondaryList, useSelectSecondaryProfileIds, useProfilesFromIds,
} from 'src/reducers/profiles_list/profiles_list_hooks';

import 'src/containers/smart_components/profiles_directory/profiles_directory.scss';
import { useSelectProfiles } from 'src/reducers/profiles/profiles_hooks';

const cls = CLASS_PREFIX + 'profiles-directory';
const labels = textResources.profileList;

interface IOrderByOption extends ISelectOption {
  value: GetProfilesOrderBy;
}
const orderByOptions: IOrderByOption[] = [
  {
    text: labels.orderByAccessionDate_WEB,
    value: 'created_at',
  },
  {
    text: labels.orderByName,
    value: 'name',
  },
];

interface IProps {}

const ProfilesDirectoryCategory: React.FC<IProps> = () => {
  const record = useSelectProfiles();
  const ids = useSelectSecondaryProfileIds();
  useMarkersInViewport(ids, record);

  const profilesList = useProfilesFromIds(ids);
  const { isLoading } = useProfilesSecondaryList();
  const boundingBox = useGetBoundingBox();
  const authorType = useAuthorTypeFromURL();
  const onChange = useCallback((val) => setOrderBy(val as GetProfilesOrderBy), []);

  const [orderBy, setOrderBy] = useState(orderByOptions[0].value);
  const orderDirection = orderBy === 'name' ? 'asc' : 'desc';

  const getProfiles = useGetProfiles();
  const getProfilesNextPage = useGetProfilesNextPage({
    authorType,
    boundingBox,
    orderBy,
    orderDirection,
  });

  useEffect(() => {
    getProfiles({
      authorType,
      boundingBox,
      orderBy,
      orderDirection,
    });
  }, [authorType, boundingBox, getProfiles, orderBy, orderDirection]);

  return (
    <LoadingSpinnerWrapper isLoading={isLoading}>
      <ProfileListWrapper
        getNextPage={getProfilesNextPage}
        initialLoad={false}
      >
        <div className={cls}>
          {!profilesList.length ? !isLoading && <p className={cls + '__empty'}>
            {textResources.shared.noLocalResultFound}
          </p>
            : <>
            <SelectField
              label={labels.orderBy}
              options={orderByOptions}
              value={orderBy}
              onChange={onChange}
            />
            <ProfilesList profiles={profilesList} />
        </>}
        </div>
      </ProfileListWrapper>
    </LoadingSpinnerWrapper>
  );
};

ProfilesDirectoryCategory.displayName = 'ProfilesDirectoryCategory';

export default ProfilesDirectoryCategory;
