// libs
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// interfaces / constants
import { IAction } from 'src/actions/';
import { POPUP_TYPE_MAP, POPUP_TYPE_NONE } from 'src/interfaces/app-state';
import { IRootState } from 'src/reducers/interface';

// components
import MapToggler from 'src/components/header/main/toggler/map_toggler/map_toggler';
import initTracker, {
  MAP_TOGGLER,
} from 'src/utils/reporting/events_tracking/events_tracking';

// helpers
import { toggleTopbarPopupMenu } from 'src/actions/app-state/app-state';

const trackMapToggler = initTracker(MAP_TOGGLER);

interface IStateToProps {
  isMapShown: boolean;
}

const mapStateToProps = (state: IRootState): IStateToProps => ({
  isMapShown: state.appState.activePopupMenu === POPUP_TYPE_MAP,
});

interface IDispatchFromProps {
  hideMap: () => void;
  showMap: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch<IAction>): IDispatchFromProps => ({
  hideMap: () => {
    // when converting to FC use hook useHideMap in actions/app-state/app-state_hooks.ts
    dispatch(toggleTopbarPopupMenu(POPUP_TYPE_NONE));
    trackMapToggler(MAP_TOGGLER.ACTIONS.CLOSE, MAP_TOGGLER.LABELS.START);
  },
  showMap: () => {
    dispatch(toggleTopbarPopupMenu(POPUP_TYPE_MAP));
    trackMapToggler(MAP_TOGGLER.ACTIONS.OPEN, MAP_TOGGLER.LABELS.START);
  },
});

export type IMapTogglerProps = IStateToProps & IDispatchFromProps;

export default connect<IStateToProps, IDispatchFromProps>(
  mapStateToProps,
  mapDispatchToProps,
)(MapToggler);
