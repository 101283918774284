// libs
import * as React from 'react';

// components
import ContentPlaceholder from 'src/components/content_placeholder/content_placeholder';
import Icon from 'src/components/forms/icon/icon';

// helpers
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import './shape_sponsors_placeholder.scss';

const cls = createBemBlock('shape-sponsors-placeholder');
const silverAvatar = cls('silver-avatar');
const bronzeAvatar = cls('bronze-avatar');

const ShapeSponsorsPlaceholder: React.FC = () => (
  <div className={cls()}>
    <div className={cls('silver-partners')}>
      <ContentPlaceholder className={silverAvatar} />
      <ContentPlaceholder className={silverAvatar} />
      <ContentPlaceholder className={silverAvatar} />
      <ContentPlaceholder className={silverAvatar} />
      <ContentPlaceholder className={silverAvatar} />
    </div>
    <div className={cls('bronze-partners')}>
      <ContentPlaceholder className={bronzeAvatar} />
      <ContentPlaceholder className={bronzeAvatar} />
      <ContentPlaceholder className={bronzeAvatar} />
      <ContentPlaceholder className={bronzeAvatar} />
      <ContentPlaceholder className={bronzeAvatar} />
      <ContentPlaceholder className={bronzeAvatar} />
      <Icon className={cls('plus-icon')} name='plus-dashed-border' size={40} />
    </div>
  </div>
);

export default ShapeSponsorsPlaceholder;
