import { snakeCase } from 'lodash';
import React, { useEffect } from 'react';

// components
import MenuItemSegment from 'src/components/item_segment/layouts/menu/menu';
import Popover from 'src/components/popover/popover';
import { reportError } from 'src/utils/reporting/report-errors';

// interfaces & constants
import { CLASS_PREFIX } from 'src/constants/';
import { textResources } from 'src/lang/de';

// helpers
import {
  IEvent,
  makeGoogleCalendarUrl,
  makeICSCalendarUrl,
} from 'src/utils/add_to_calender';

import './add_to_calender.scss';

const cls = CLASS_PREFIX + 'add-to-calender';
const { google, appleCalender, outlook, text } = textResources.addToCalender;

interface IProps {
  event?: IEvent;
}

const AddToCalender: React.FC<IProps> = ({ event }) => {
  useEffect(() => {
    if (event === undefined) {
      reportError('AddToCalender was rendered with an event of undefined it will not show itself');
    }
  }, [event]);
  if (event === undefined) {
    return null;
  }
  return (
    <Popover
      position={'top'}
      align={'relative'}
      trigger={<button className={cls + '__button'}>{text}</button>}
    >
      <div className={cls}>
        <MenuItemSegment
          label={appleCalender}
          navigation={{
            download: snakeCase(event.name),
            target: '_blank',
            url: makeICSCalendarUrl(event),
          }}
        />
        <MenuItemSegment
          label={google}
          navigation={{
            target: '_blank',
            url: makeGoogleCalendarUrl(event),
          }}
        />
        {/* inspired by other platforms (e.g eventbrite) that also use ics format for outlook */}
        <MenuItemSegment
          label={outlook}
          navigation={{
            download: snakeCase(event.name),
            target: '_blank',
            url: makeICSCalendarUrl(event, { urlAtEndOfDescription: true }),
          }}
        />
      </div>
    </Popover>
  );
};

export default AddToCalender;
