import { IUser } from '../../../interfaces/user';
import { getStore } from '../../../store/configure-store';
import { getConfigValue } from '../../../utils/config/config';

export const getSurveyConfig = () => {
  const store = getStore();
  const user = store.getState().user;
  return {
    isEnabled: canCreateSurvey(user),
  };
};

export const canCreateSurvey = (user: IUser) => {
  const isSurveyEnabled = getConfigValue<boolean>('plugins.surveys.enabled');
  const permittedUserTypes = getConfigValue<string[]>('plugins.surveys.canCreate');
  const hasAccessToSurveys = permittedUserTypes
    ? permittedUserTypes.some(userType => user.selectedProfileId.startsWith(userType))
    : false;
  return !!(isSurveyEnabled && hasAccessToSurveys);
};
