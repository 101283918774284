// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import './blurred_content.scss';

const cls = `${CLASS_PREFIX}blurred-content`;

export type IBlurred = 'full' | 'gradient';

interface IProps {
  children?: any;
  blurred?: IBlurred;
}

const BlurredContent: React.FC<IProps> = ({ children, blurred = 'full' }) => {
  const clsBlur = cls + (blurred === 'gradient' ? '--gradient' : '--full');
  return (
    <div className={clsBlur}>
      {children}
    </div>
  );
};

export default BlurredContent;
