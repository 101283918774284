// interfaces
import { FetchFeedThunkAction } from 'src/actions/feed/feed_interfaces';
// constants
import { FEED_TYPE_GROUPS_RECENT } from 'src/constants/feed';
// actions
import { fetchFeed } from 'src/actions/feed/feed';
// helpers
import api, { IPaginationParams } from 'src/api/';

const fetchGroupsRecentFeed = fetchFeed<IPaginationParams>(
  FEED_TYPE_GROUPS_RECENT,
  api.group.recentFeed,
);

export const fetchGroupsRecentFeedPage = (page: number = 1): FetchFeedThunkAction => {
  return async(dispatch): Promise<void> => {
    dispatch(fetchGroupsRecentFeed({
      page,
    }));
  };
};
