// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IKeyValue } from 'src/interfaces/';

// helpers
import { ColorType } from 'src/utils/color';
import './thumbnail.scss';

export const THUMBNAIL_SIZE_TYPE_XXXLARGE = 'XXXLARGE';
export const THUMBNAIL_SIZE_TYPE_XXLARGE = 'XXLARGE';
export const THUMBNAIL_SIZE_TYPE_XLARGE = 'XLARGE';
export const THUMBNAIL_SIZE_TYPE_LARGE = 'LARGE';
export const THUMBNAIL_SIZE_TYPE_MEDIUM = 'MEDIUM';
export const THUMBNAIL_SIZE_TYPE_SMALL = 'SMALL';
export const THUMBNAIL_SIZE_TYPE_XSMALL = 'XSMALL';
export const THUMBNAIL_SIZE_TYPE_XXSMALL = 'XXSMALL';

export type ThumbnailSizeType = typeof THUMBNAIL_SIZE_TYPE_XXXLARGE
  | typeof THUMBNAIL_SIZE_TYPE_XXLARGE
  | typeof THUMBNAIL_SIZE_TYPE_XLARGE
  | typeof THUMBNAIL_SIZE_TYPE_LARGE
  | typeof THUMBNAIL_SIZE_TYPE_MEDIUM
  | typeof THUMBNAIL_SIZE_TYPE_SMALL
  | typeof THUMBNAIL_SIZE_TYPE_XSMALL
  | typeof THUMBNAIL_SIZE_TYPE_XXSMALL;

interface IProps {
  withBorder?: boolean;
  className?: string;
  clickable?: boolean;
  index?: number;
  imageUrl?: string;
  label?: string;
  onClick?: (index: number) => void;
  round?: boolean;
  size?: ThumbnailSizeType;
  borderColor?: ColorType;
}

const clsBlock: string = CLASS_PREFIX + 'thumbnail';

export default class Thumbnail extends React.PureComponent<IProps, {}> {
  constructor(props: IProps) {
    super(props);
    this.handleOnClick = this.handleOnClick.bind(this);
  }

  public render() {
    const { withBorder, children, className, clickable, imageUrl, label, round, size, borderColor } = this.props;
    const cls: IKeyValue = {};
    cls[clsBlock] = true;
    cls[`${clsBlock}--clickable`] = clickable;
    if (round) {
      cls[`${clsBlock}--round`] = round;
    }
    if (withBorder) {
      cls[`${clsBlock}--border`] = withBorder;
      cls[`${clsBlock}--border--${borderColor}`] = borderColor;
    }
    if (className) {
      cls[className] = true;
    }
    if (!imageUrl) {
      cls[`${clsBlock}--no-image`] = true;
    }
    if (size) {
      cls[`${clsBlock}--${size}`] = true;
    } else {
      cls[`${clsBlock}--${THUMBNAIL_SIZE_TYPE_MEDIUM}`] = true;
    }

    return (
      <div
        className={classNames(cls)}
        style={{ backgroundImage: `url("${imageUrl}")` }}
        onClick={this.handleOnClick}
      >
        {label}
        {children}
      </div>
    );
  }

  private handleOnClick() {
    const { index, onClick } = this.props;
    if (onClick && index !== undefined) {
      onClick(index);
    }
  }
}
