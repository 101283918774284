// libs
import * as React from 'react';

// classes
import LoadingSpinner from './loading_spinner';

interface IProps {
  isLoading: boolean;
}

const LoadingSpinnerWrapper: React.FC<IProps> = ({ isLoading, children }) => (
      <>
        {children}
        <LoadingSpinner shown={isLoading} />
      </>
);

export default LoadingSpinnerWrapper;
