// libs
import * as H from 'history';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

// classes / components
import AuthUI, { PasswordResetParams } from 'src/components/authentication/auth_ui';

// actions
import { logIn } from 'src/actions/user/user';

// interfaces / types
import { IAction } from 'src/actions/';
import { AuthModalType } from 'src/interfaces/app-state';
import { IPosition } from 'src/interfaces/location';
import { IRootState } from 'src/reducers/interface';

// helpers
import { SettingsStatusError, SettingsStatusSuccess } from 'src/constants/settings';
import { withRouterProps } from 'src/high_order_components/connect_route_props';
import { parseQueryString } from 'src/utils/url/url';

interface IOwnProps {
  googlePlaceId?: string;
  layout: AuthModalType;
  email?: string;
  onClose?: () => void;
  hasCancelButton?: boolean;
  hasTabs?: boolean;
  hasStaticLinks?: boolean;
  headerTitle?: string;
  pushError?: (message: string) => void;
}

interface IMapStateToProps {
  loggedIn: boolean;
  position?: IPosition;
}

interface IDispatchFromProps {
  logIn: () => void;
}

export type IAuthUIProps = IMapStateToProps & IDispatchFromProps & IOwnProps & IMapRouteToProps;

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  loggedIn: state.user.loggedIn,
  position: state.appState.position,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, IAction>): IDispatchFromProps => ({
  logIn: () => dispatch(logIn()),
});

interface IMapRouteToProps {
  history: H.History;
  passwordResetParams: PasswordResetParams;
}

const mapRouteToProps = ({ history, location }: RouteComponentProps): IMapRouteToProps => {
  const parsedQuery = parseQueryString<string, {
    error_code: string;
    status: SettingsStatusError & SettingsStatusSuccess;
    reset_password_token: string;
  }>(location.search, [
    'reset_password_token', 'status', 'error_code',
  ]);
  const token = parsedQuery.reset_password_token;
  const statusCode = parsedQuery.status;
  const errorCode = parsedQuery.error_code;
  return { history, passwordResetParams: { errorCode, statusCode, token } };
};

const AuthUIContainer = withRouterProps<IMapRouteToProps, IOwnProps>(mapRouteToProps)(
  connect<IMapStateToProps, IDispatchFromProps, IOwnProps & IMapRouteToProps>(
    mapStateToProps, mapDispatchToProps)(AuthUI));

export default AuthUIContainer;
