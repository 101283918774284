// libs
import produce from 'immer';

// interfaces / constants
import {
  COMMENT_CREATE_REQUEST_SUCCESS,
  COMMENT_DELETE_ERROR,
  COMMENT_DELETE_SUCCESS,
  COMMENT_UPDATE_ERROR,
  COMMENT_UPDATE_SUCCESS,
  COMMENTS_REQUEST_SUCCESS,
  ICommentsCreateSuccess,
  ICommentsDeleteError,
  ICommentsUpdateError,
  ICommentsFetchSuccess,
  ICommentsDeleteSuccess,
  ICommentsUpdateSuccess,

} from 'src/actions/comments';
import {
  IUpdateCommentReactionError,
  IUpdateCommentReactionPending,
  IUpdateCommentReactionSuccess,
  COMMENT_UPDATE_REACTION_ERROR,
  COMMENT_UPDATE_REACTION_PENDING,
  COMMENT_UPDATE_REACTION_SUCCESS,
} from 'src/actions/reaction';
import { ICommentsWrapper } from 'src/interfaces/comments';

export const INITIAL_STATE: ICommentsWrapper = {};

type Actions =
  ICommentsCreateSuccess
  | ICommentsDeleteError
  | ICommentsUpdateError
  | ICommentsFetchSuccess
  | ICommentsDeleteSuccess
  | ICommentsUpdateSuccess
  | IUpdateCommentReactionError
  | IUpdateCommentReactionPending
  | IUpdateCommentReactionSuccess;

const commentsReducer = (state = INITIAL_STATE, action: Actions): ICommentsWrapper =>
  produce(state, (draft) => {
    switch (action.type) {
      case COMMENTS_REQUEST_SUCCESS:
        draft[action.meta.id] = action.payload.comments;
        break;

      case COMMENT_CREATE_REQUEST_SUCCESS:
        draft[action.meta.id] = (draft[action.meta.id] || []).concat([action.payload.comment]);
        break;

      case COMMENT_UPDATE_REACTION_PENDING:
      case COMMENT_UPDATE_REACTION_ERROR:
        draft[action.meta.postId] = (draft[action.meta.postId] || []).map((comment) => {
          if (
            comment.id !== action.meta.commentId ||
            comment.reactions === null ||
            comment.reactions.reactions.length === 0
          ) {
            return comment;
          }

          if (comment.reactions.reactions[0].own) {
            comment.reactions.reactions[0].number -= 1;
            comment.reactions.reactions[0].own = false;
          } else {
            comment.reactions.reactions[0].number += 1;
            comment.reactions.reactions[0].own = true;
          }

          return comment;
        });
        break;

      case COMMENT_UPDATE_REACTION_SUCCESS:
        draft[action.meta.postId] = (draft[action.meta.postId] || []).map((comment) => {
          if (comment.id !== action.meta.commentId || comment.reactions === null) {
            return comment;
          }
          comment.reactions = { ...action.payload };
          return comment;
        });
        break;

      case COMMENT_DELETE_ERROR:
      case COMMENT_UPDATE_ERROR:
        draft[action.meta.postId] = (draft[action.meta.postId] || []).map((comment) => {
          if (comment.id !== action.meta.commentId) {
            return comment;
          }

          return { ...comment, hasError: true };
        });
        break;

      case COMMENT_DELETE_SUCCESS:
      case COMMENT_UPDATE_SUCCESS:
        draft[action.meta.postId] = (draft[action.meta.postId] || []).map((comment) => {
          if (comment.id !== action.payload.comment.id) {
            return comment;
          }

          return action.payload.comment;
        });
        break;
    }
  });

export default commentsReducer;
