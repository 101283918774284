// libs
import React, { PropsWithChildren, ReactNode } from 'react';
// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { GenericIProfile } from 'src/interfaces/profile';

import 'src/components/profiles_list/profiles_list.scss';
import ProfileListItem from './profile_list_item';

interface IProps<T extends GenericIProfile> {
  profiles: T[];
  children?: (profile: T) => ReactNode;
}

const cls: string = CLASS_PREFIX + 'profiles-list';

const ProfilesList: <T extends GenericIProfile>({ profiles, children }: PropsWithChildren<IProps<T>>) =>
JSX.Element = ({ profiles, children }) => {
  return (
    <div className={cls}>
      <div className={cls + '__profiles-wrapper'}>
        {profiles.map(profile => children ? children(profile) : <ProfileListItem key={profile.id} profile={profile} />)}
      </div>
    </div>
  );
};

export default ProfilesList;
