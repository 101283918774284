import * as Response from 'src/api/interfaces/responses/post';
import {
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  PRESS_PROFILE_TYPE, PRIVATE_PROFILE_TYPE,
} from 'src/constants/profile';
import { IIngredientMarker, IMarker } from 'src/interfaces/marker';
import { IPostResponse } from 'src/interfaces/posts';
import { ProfileType, IProfile } from 'src/interfaces/profile';

import {
  COLOR_TYPE_DEFAULT,
  COLOR_TYPE_PRIMARY,
  COLOR_TYPE_SECONDARY1,
  COLOR_TYPE_SECONDARY2,
  COLOR_TYPE_SECONDARY3,
  ColorType,
} from 'src/utils/color';
import { UrlUtils } from 'src/utils/url/url';

const getColorFromAuthorType = (author: ProfileType): ColorType => {
  switch (author) {
    case AUTHORITY_PROFILE_TYPE:
      return COLOR_TYPE_SECONDARY2;

    case BLOG_PROFILE_TYPE:
      return COLOR_TYPE_SECONDARY3;

    case PRESS_PROFILE_TYPE:
      return COLOR_TYPE_SECONDARY1;

    case PRIVATE_PROFILE_TYPE:
      return COLOR_TYPE_PRIMARY;

    default:
      return COLOR_TYPE_DEFAULT;
  }
};

export const $postMarkers = ({ post, profile }: IPostResponse, markers: Response.IMarker[]): IMarker[] => {
  return markers.map((marker, index) => ({
    color: getColorFromAuthorType(profile.type),
    id: `${post.id}#${index}`,
    image: profile.imageUrl,
    position: {
      latitude: marker.latitude,
      longitude: marker.longitude,
    },
    url: post.urls.frontend,
  }));
};

export const $profileMarkers = (profile: IProfile, markers: Response.IMarker[] = []): IMarker[] => {
  return markers.map((marker, index) => ({
    color: getColorFromAuthorType(profile.type),
    id: `${profile.id}#${index}`,
    image: profile.imageUrl,
    position: {
      latitude: marker.latitude,
      longitude: marker.longitude,
    },
    url: UrlUtils.getProfileFrontendPath(profile.id, profile.type),
  }));
};

export const $ingredientMarker = (json: Response.IMarker): IIngredientMarker => ({
  address: json.address_string,
  position: {
    latitude: json.latitude,
    longitude: json.longitude,
  },
});
