// helpers
import { useClickListener } from 'src/hooks_shared/use_event_listener';

export const useClickOutside =
  (ref: React.RefObject<HTMLDivElement>, callBack: () => void) => {
    const handleOutsideClick = (e: Event) => {
      if (!ref || !ref.current) {
        return;
      }

      if (ref.current.contains(e.target as Node)) {
        return;
      }

      callBack();
    };
    useClickListener(handleOutsideClick);
  };
