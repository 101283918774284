// defaults
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';

// interfaces
import { IAction } from 'src/actions';
import { POPUP_TYPE_MAP, POPUP_TYPE_NONE, PopupMenu } from 'src/interfaces/app-state';
import { IBoundingBox, IPosition } from 'src/interfaces/location';
import { IUserProfile } from 'src/interfaces/user';
import { IRootState } from 'src/reducers/interface';

// components
import MapControls from 'src/components/map/map_controls/map_controls';
import { URLConsumer } from 'src/containers/url_provider/url_provider';

// helpers
import { toggleTopbarPopupMenu } from 'src/actions/app-state/app-state';

interface IOwnProps extends RouteComponentProps {
  backToPrevious: () => void;
  mapPosition?: IPosition;
  mapBoundingBox?: IBoundingBox;
  showSearchAreaButton: boolean;
  isLocked: boolean;
  zoomActions: {
    zoomIn?: () => void;
    zoomOut?: () => void;
  };
}

interface IStateFromProps {
  activePopupMenu: PopupMenu;
  isMapShown: boolean;
  profile: IUserProfile;
}

export type IMapControlsProps = IOwnProps & IStateFromProps & IDispatchFromProps;

const mapStateToProps = (state: IRootState): IStateFromProps => ({
  activePopupMenu: state.appState.activePopupMenu,
  isMapShown: state.appState.activePopupMenu === POPUP_TYPE_MAP,
  profile: state.user.profiles[state.user.selectedProfileId],
});

interface IDispatchFromProps {
  hideMap: () => void;
  showMap: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch<IAction>): IDispatchFromProps => ({
  // when converting to FC use hook useHideMap in actions/app-state/app-state_hooks.ts
  hideMap: () => dispatch(toggleTopbarPopupMenu(POPUP_TYPE_NONE)),
  showMap: () => dispatch(toggleTopbarPopupMenu(POPUP_TYPE_MAP)),
});

class MapControlsContainer extends React.Component<IMapControlsProps, {}> {
  public render(): JSX.Element {
    return (
      <URLConsumer>
        {({ navigateToShape, navigateToPositionURL }) =>
          <MapControls
            {...this.props}
            navigateToShape={navigateToShape}
            navigateToPositionURL={navigateToPositionURL}
          />
        }
      </URLConsumer>
    );
  }
}

export default withRouter(connect<IStateFromProps, IDispatchFromProps, IOwnProps & RouteComponentProps>(
  mapStateToProps,
  mapDispatchToProps,
)(MapControlsContainer));
