import {
  ABORT,
  alterAuthRequestHeaders,
  checkAuthenticationState,
  CONTINUE,
  RETRY,
} from 'src/api/concerns/authentication';

export {
  ABORT,
  CONTINUE,
  RETRY,
};

export default {
  alterAuthRequestHeaders,
  checkAuthenticationState,
};
