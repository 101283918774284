// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IKeyValue } from 'src/interfaces/';

// classes / components
import InputField from 'src/components/forms/input_field/input_field';
import SelectFieldItemSegment from 'src/components/item_segment/layouts/select_field/select_field';
import Popover from 'src/components/popover/popover';

// helpers
import './select-field.scss';

export interface ISelectOption {
  value: string;
  text: string;
}

interface IProps {
  assistiveText?: string;
  disabled?: boolean;
  label?: string;
  required?: boolean;
  onChange?: (value: string, index: number) => void;
  value?: string;
  options: ISelectOption[];
  errorText?: string;
  placeholder?: string;
}
interface IState {
  isOpen: boolean;
  selectedIndex: number;
}

const clsBlock: string = CLASS_PREFIX + 'select-field';
export default class SelectField extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isOpen: false,
      selectedIndex: -1,
    };
    this.onShow = this.onShow.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  public componentDidMount() {
    const { value, options } = this.props;
    if (value) {
      const index = options.findIndex((option: ISelectOption) => option.value === value);
      this.setState({ selectedIndex: index });
    }
  }

  public render() {
    const cls: IKeyValue = {};
    const {
      assistiveText, disabled, errorText, options, onChange, placeholder, label, required,
    } = this.props;
    const { isOpen, selectedIndex } = this.state;
    if (options.length < 1) {
      return null;
    }
    const selectedValue = selectedIndex >= 0 ? options[selectedIndex].text : '';
    cls[clsBlock] = true;

    const trigger = (
      <InputField
        assistiveText={assistiveText}
        value={selectedValue}
        iconRight={isOpen ? 'arrow-up' : 'arrow-down'}
        iconSize={16}
        disabled={disabled}
        label={label}
        required={required}
        errorText={errorText}
        placeholder={placeholder}
        name='trigger'
        readOnly
      />
    );

    const handleItemOnClick = (index: number) => () => {
      this.setState({ isOpen: false, selectedIndex: index });
      onChange && onChange(options[index].value, index);
    };

    const renderPopover = () => (
      <Popover
        className={clsBlock + '__popover'}
        position='bottom'
        align='relative'
        layout='plain'
        trigger={trigger}
        onShow={this.onShow}
        onHide={this.onHide}
      >
        {({ hide, callAll }) => (
          <ul>
            {this.props.options.map((option: ISelectOption, index: number) => (
              <li key={index} className={clsBlock + '__list-item'}>
                <SelectFieldItemSegment
                  label={option.text}
                  navigation={{ onClick: callAll(handleItemOnClick(index), hide) }}
                />
              </li>
            ))}
          </ul>
        )}
      </Popover>
    );

    return (
      <div className={classNames(cls)}>
        {disabled ? trigger : renderPopover()}
      </div>
    );
  }

  private onShow() {
    const { disabled } = this.props;
    if (disabled) { return; }
    this.setState({ isOpen: !this.state.isOpen });
  }

  private onHide() {
    this.setState({ isOpen: false });
  }
}
