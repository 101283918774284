import * as React from 'react';

// components
import {
  DefaultButton,
  EMailField,
  FacebookButton,
  PasswordField,
  PrimaryButton,
} from 'src/components/authentication/auth_form_elements';
import FieldSet, { FIELDSET_TYPE_SMALL_SPACE } from 'src/components/forms/field_set/field_set';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// constants & interfaces
import { IAuthErrorResponse } from 'src/api/interfaces/errors';
import { IAuthProps } from 'src/components/authentication/auth_ui';
import { AUTH_UI_CLASS } from 'src/constants/';
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_PRIMARY_2 } from 'src/utils/color';

// components
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';

// helpers
import { AuthenticationHelper, loginWithFacebook } from 'src/utils/authentication/authentication';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import initTracker, { LOGIN } from 'src/utils/reporting/events_tracking/events_tracking';
import { isNotEmpty, validateEmail } from 'src/utils/validation';

const cls = createBemBlock(AUTH_UI_CLASS);

const labels = textResources.authentication;

const loginTracker = initTracker(LOGIN);

interface IState {
  password: string;
  validEmail: boolean;
  validPassword: boolean;
  isLoading: boolean;
}

class Login extends React.Component<IAuthProps, IState> {
  constructor(props: IAuthProps) {
    super(props);
    this.state = {
      isLoading: false,
      password: '',
      validEmail: true,
      validPassword: true,
    };
    this.submit = this.submit.bind(this);
    this.loginWithFacebook = this.loginWithFacebook.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  public componentDidUpdate(prevProps: IAuthProps) {
    const { onClose } = prevProps;
    // This could cause an infinite update-loop, if onClose contains a setState() call.
    this.props.loggedIn && onClose && onClose();
  }

  public render() {
    const { isLoading } = this.state;

    return isLoading ? this.renderSpinner() : this.renderBody();
  }

  public renderSpinner() {
    return (
      <div className={cls('loading')}>
        <LoadingSpinner shown />
      </div>
    );
  }

  public renderBody() {
    const { email, goToForgotPassword, goToRegister } = this.props;
    const { password, validEmail, validPassword } = this.state;

    return (
      <form>
        <FieldSet>
          <EMailField name='email'
            onChange={this.changeEmail}
            value={email}
            valid={validEmail}
            autoComplete='username'
          />
          <PasswordField name='password'
            onChange={this.changePassword}
            value={password}
            autoComplete='current-password'
            valid={validPassword}
          />
          <NavigationItem onClick={goToForgotPassword} target='_self' className={cls('forgot-password')}>
            {labels.passwordForgot}
          </NavigationItem>
        </FieldSet>
        <FieldSet type={FIELDSET_TYPE_SMALL_SPACE}>
          <PrimaryButton onClick={this.submit} label={textResources.shared.login} color={COLOR_TYPE_PRIMARY_2} />
          <FacebookButton onClick={this.loginWithFacebook} label={labels.loginWithFacebook} />
          <DefaultButton onClick={goToRegister} label={labels.wantRegister} />
        </FieldSet>
      </form>
    );
  }

  private pushError(msg: string) {
    const { pushError } = this.props;
    pushError && pushError(msg);
  }

  private changePassword(value: string) {
    this.setState({ password: value, validPassword: true });
  }

  private changeEmail(value: string) {
    const { changeEmail } = this.props;
    this.setState({ validEmail: true });
    changeEmail && changeEmail(value);
  }

  private submit(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    const { email = '' } = this.props;
    const { password } = this.state;

    const validEmail = validateEmail(email);
    const validPassword = isNotEmpty(password);

    loginTracker(LOGIN.ACTIONS.EMAIL, LOGIN.LABELS.START);
    if (validEmail && validPassword) {
      this.setState({ isLoading: true });
      AuthenticationHelper.login({ email, password })
        .then(() => {
          loginTracker(LOGIN.ACTIONS.EMAIL, LOGIN.LABELS.FINISH);
          // TODO: Is this needed?
          this.props.logIn && this.props.logIn();
        })
        .catch((response: IAuthErrorResponse) => {
          loginTracker(LOGIN.ACTIONS.EMAIL, LOGIN.LABELS.FAIL);
          this.setState({ isLoading: false });
          if (response.errors[0].code === 'invalid_grant') {
            this.pushError(labels.errorLogin);
            this.setState({ password: '' });
          } else {
            this.pushError(textResources.shared.errorUnknown);
          }
        });
    } else {
      this.setState({ validEmail, validPassword });
    }
  }

  private loginWithFacebook(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    loginWithFacebook();
  }
}

export default Login;
