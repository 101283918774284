import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// interfaces / constants
import { FEED_TYPE_GROUPS_RECENT } from 'src/constants/feed';

// helpers
import {
  fetchGroupsRecentFeedPage,
} from 'src/actions/feed/groups_recent_feed/groups_recent_feed';
import { fetchNextFeedPage } from 'src/actions/feed/next_page';

export const useFetchGroupsRecentFeedPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchGroupsRecentFeedPage());
  }, [dispatch]);
};

export const useFetchGroupsRecentFeedNextPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchNextFeedPage(FEED_TYPE_GROUPS_RECENT, fetchGroupsRecentFeedPage));
  }, [dispatch]);
};
