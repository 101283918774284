import produce from 'immer';

import {
  Actions,
  PROFILES_LIST_CLEAN,
  PROFILES_LIST_CLEAR_ALL,
  PROFILES_LIST_GET_PROFILES_PENDING,
  PROFILES_LIST_GET_PROFILES_SUCCESS,
  PROFILES_LIST_GET_PROFILES_ERROR,
  PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_PENDING,
  PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_SUCCESS,
  PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_ERROR,
  PROFILES_LIST_SET_HASH,
  IGetProfilesListSuccessAction,
  IGetProfilesListSecondaryListSuccessAction,
  PROFILES_LIST_SET_SECONDARY_HASH,
  PROFILES_LIST_GET_SPONSORS_SUCCESS,
  IGetSponsorsListSuccessAction,
  PROFILES_LIST_GET_SPONSORS_ERROR,
  PROFILES_LIST_GET_SPONSORS_PENDING,
} from 'src/actions/profiles_list/profiles_list';
import { IProfilesList, IProfilesListBase } from 'src/interfaces/profiles_list';
import { INITIAL_PAGINATION_INFO } from 'src/reducers/feed/feed';

const INITIAL_STATE_BASE: IProfilesListBase = {
  groups: [],
  hash: '',
  isLoading: false,
  pagination: INITIAL_PAGINATION_INFO,
};

export const INITIAL_STATE: IProfilesList = {
  groups: [],
  hash: '',
  isLoading: false,
  pagination: INITIAL_PAGINATION_INFO,
  secondaryList: INITIAL_STATE_BASE,
};

const clean = (draft: IProfilesList) => {
  draft.groups = [];
  draft.hash = '';
  draft.isLoading = false;
  draft.pagination = INITIAL_PAGINATION_INFO;
};

const clearSecondaryList = (draft: IProfilesList) => {
  draft.secondaryList = INITIAL_STATE_BASE;
};

const updateCommon = (
  draft: IProfilesListBase,
  action: IGetProfilesListSuccessAction | IGetProfilesListSecondaryListSuccessAction | IGetSponsorsListSuccessAction,
) => {
  draft.isLoading = false;

  draft.pagination = action.payload?.pagination || INITIAL_STATE.pagination;

  if (action.payload?.pagination?.currentPage === 1) {
    draft.groups = action.payload.groups || [];
  } else if (action.payload?.groups) {
    draft.groups = draft.groups.concat(action.payload.groups);
  }
};

const profilesListReducer = (state = INITIAL_STATE, action: Actions): IProfilesList =>
  produce(state, (draft) => {
    switch (action.type) {
      case PROFILES_LIST_CLEAN:
        clean(draft);
        clearSecondaryList(draft);
        break;

      case PROFILES_LIST_SET_HASH:
        clean(draft);
        clearSecondaryList(draft);
        draft.hash = action.payload;
        break;

      case PROFILES_LIST_SET_SECONDARY_HASH:
        clearSecondaryList(draft);
        draft.secondaryList.hash = action.payload;
        break;

      case PROFILES_LIST_CLEAR_ALL:
        clean(draft);
        clearSecondaryList(draft);
        break;

      case PROFILES_LIST_GET_SPONSORS_PENDING:
      case PROFILES_LIST_GET_PROFILES_PENDING:
        draft.isLoading = true;
        break;
      case PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_PENDING:
        if (!state.secondaryList.isLoading) {
          draft.secondaryList.isLoading = true;
        }
        break;

      case PROFILES_LIST_GET_SPONSORS_ERROR:
      case PROFILES_LIST_GET_PROFILES_ERROR:
        draft.isLoading = false;
        break;
      case PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_ERROR:
        draft.secondaryList.isLoading = false;
        break;

      case PROFILES_LIST_GET_SPONSORS_SUCCESS:
      case PROFILES_LIST_GET_PROFILES_SUCCESS:
        updateCommon(draft, action);
        break;

      case PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_SUCCESS:
        updateCommon(draft.secondaryList, action);
        break;
    }
  });

export default profilesListReducer;
