// libs
import classNames from 'classnames';
import * as React from 'react';

// components
import Logo, { ILogos } from 'src/components/logo/logo';

// interfaces & constants
import { CLASS_PREFIX } from 'src/constants/';
import { PageTitlePossibleTags } from 'src/containers/smart_components/page_title/page_title';
import { getConfigValue } from 'src/utils/config/config';

// helpers
import { FeatureFlag } from '../../../feature_flag/feature_flag';
import { PageTitleContent } from './page_title_content';

import './page_title.scss';

const cls = `${CLASS_PREFIX}page-title`;

interface IProps {
  customTag?: PageTitlePossibleTags;
  isHome?: boolean;
  location?: string;
  showLocation?: boolean;
  text: string;
}

const PageTitle: React.FC<IProps> = (props) => {
  const customLogoType = getConfigValue<keyof ILogos>('featureFlags.header.logoType');
  return <FeatureFlag
    active={getConfigValue('featureFlags.header.replaceWithLogo')}
    fallback={<PageTitleContent {...props} cls={cls} />}>
    <span className={classNames(`${cls}__logo`, customLogoType && `${cls}__logo--${customLogoType}`)}>
      <Logo type={customLogoType || 'NO_TEXT'} />
    </span>
  </FeatureFlag>;
};

export default PageTitle;
