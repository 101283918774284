export interface GroupListProfileActionGroups {
  pending: GroupListProfileAvailableActions;
  current: GroupListProfileAvailableActions;
}

export type GroupListProfileAvailableActions =
  Partial<Record<GroupListProfileAvailableAction, GroupListProfileActionCallbacks>>

export interface GroupListProfileActionCallbacks {
  success: GroupListProfileCallback;
  error: GroupListProfileCallback;
}
export type GroupListProfileCallback = (username: string) => void;

export enum GroupListProfileAvailableAction {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
  REMOVE = 'REMOVE',
}
