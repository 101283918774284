// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import { textResources } from 'src/lang/de';
import './badge.scss';

const labels = textResources.screenReaderText;

type BadgeColor = 'yellow' | 'red';
type BadgeSize = 'small' | 'medium' | 'large';
export type BadgeType = 'notification' | 'message';

export interface IProps {
  className?: string;
  color?: BadgeColor;
  size?: BadgeSize;
  type: BadgeType;
  value?: number;
}

const cls: string = CLASS_PREFIX + 'badge';

const Badge: React.FC<IProps> = ({
  className = '',
  color = 'yellow',
  size = 'medium',
  type,
  value,
}) => {
  if (value === 0) {
    return null;
  }

  const classes = [
    `${cls}--${color}`,
    `${cls}--${size}`,
    className && className,
  ];

  return (
    <>
      <i aria-hidden='true' className={classNames(cls, classes)} title={value + labels.new + labels[type]}>
        {size !== 'small' && <span>{value}</span>}
      </i>
      <span className={cls + '__icon-text'}>{value + labels.new + labels[type]}</span>
    </>
  );
};

export default Badge;
