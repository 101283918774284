// libs
import classNames from 'classnames';
import { throttle } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useHistory, useRouteMatch } from 'react-router-dom';

// interfaces / constants
import { IContentItemType } from 'src/interfaces/posts';

// components
import PostCreateItemSegment from 'src/components/item_segment/layouts/post_create/post_create';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { useResizeListener } from 'src/hooks_shared/use_event_listener';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { COLOR_TYPE_PRIMARY, COLOR_TYPE_SECONDARY6, COLOR_TYPE_PRIMARY_2 } from 'src/utils/color';
import { readColumnCountFromDOM } from 'src/utils/column_count';
import { UrlUtils } from 'src/utils/url/url';

import { useSurvey } from '../../../plugins/survey/hook/useSurvey';
import * as circlePlusPrimary from './circle_plus_primary.svg';
import * as circlePlusSecondary from './circle_plus_secondary.svg';
import './create_banner.scss';

type DefaultIconType = typeof COLOR_TYPE_PRIMARY | typeof COLOR_TYPE_PRIMARY_2 | typeof COLOR_TYPE_SECONDARY6;

export interface IOwnProps {
  callToActionText: string;
  callToActionSubtitle?: string;
  defaultIconType?: DefaultIconType;
  featureBlocked: boolean;
  postType?: IContentItemType;
  tileSize?: boolean;
  forceWizard?: boolean;
}

export interface IeStoreState {
  avatarImageUrl?: string;
}

type IProps = IeStoreState & IOwnProps;

const cls = createBemBlock('create-banner');

const CreateBanner: React.FC<IProps> = ({
  callToActionText,
  callToActionSubtitle,
  defaultIconType = 'primary',
  children,
  featureBlocked,
  forceWizard,
  postType,
  tileSize,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const user = useSelectUser();
  const match = useRouteMatch();
  const [columnCount, setColumnCount] = useState(() => readColumnCountFromDOM());
  const listener = useCallback(
    throttle(() => {
      setColumnCount(readColumnCountFromDOM());
    }, 100),
    [],
  );

  const { isEnabled: isSurveyEnabled } = useSurvey();

  useEffect(() => {
    setColumnCount(readColumnCountFromDOM());
  }, []);

  useResizeListener(listener, { passive: true });

  const profile = user.profiles[user.selectedProfileId];
  const avatarImageUrl = profile && profile.avatar && profile.avatar.url;
  const defaultIcon = defaultIconType === 'primary' ? circlePlusPrimary : circlePlusSecondary;
  const matchUrl = match!.url;

  const showWizard = useCallback(() => {
    history.push(UrlUtils.getPostCreateURL(matchUrl, search, !!forceWizard && isSurveyEnabled, postType));
  }, [forceWizard, history, isSurveyEnabled, matchUrl, postType, search]);

  return (
    <div className={classNames({
      [cls('ctas')]: true,
      [cls('ctas', 'tile-size')]: !!tileSize,
    })}>
      {columnCount > 1 &&
        <UserOnboardingCTA active={featureBlocked}>
          <PostCreateItemSegment
            navigation={{ onClick: showWizard }}
            avatar={{ imageUrl: avatarImageUrl || defaultIcon }}
            label={callToActionText}
            description={callToActionSubtitle}
          />
        </UserOnboardingCTA>
      }
      {children}
    </div>
  );
};

export default CreateBanner;
