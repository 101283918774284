import React, { useCallback, useState } from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IDateRange } from 'src/interfaces/filters';
import DateFormatter from 'src/utils/date_formatter/date_formatter';

// components
import DatePicker from 'src/components/date/picker/picker';
import Icon from 'src/components/forms/icon/icon';
import Modal from 'src/components/modal/modal';
import {
  IEventFeedFilterTracker,
} from 'src/utils/reporting/events_tracking/events_tracking';

// helpers
import { textResources } from 'src/lang/de';
import './calendar_datepicker.scss';

const cls = `${CLASS_PREFIX}calendar-datepicker`;
const clsWrapper = `${cls}__wrapper`;
const clsButton = `${cls}__button`;
const clsPicker = `${cls}__picker`;
const clsText = `${cls}__text`;
const labels = textResources.screenReaderText;

interface IProps {
  range: IDateRange;
  onChange: (range: IDateRange) => void;
  minDate?: string;
  tracking?: IEventFeedFilterTracker;
  quickPick?: boolean;
  manualPick?: boolean;
}

const CalendarDatepicker: React.FC<IProps> = ({
  range,
  onChange,
  minDate,
  quickPick = true,
  manualPick = true,
  tracking,
}) => {
  const [show, setShown] = useState<boolean>(false);

  const showModal = useCallback(() => {
    setShown(true);
  }, [setShown]);

  const hideModal = useCallback(() => {
    setShown(false);
  }, [setShown]);

  const selectDate = useCallback((range: IDateRange) => {
    onChange(range);
    setShown(false);
  }, [onChange, setShown]);

  const selectPreviousDay = useCallback(() => {
    onChange({
      start: DateFormatter.getPreviousDay(range.start || DateFormatter.getToday()),
    });
  }, [onChange, range]);

  const selectNextDay = useCallback(() => {
    onChange({
      start: DateFormatter.getNextDay(range.start || DateFormatter.getToday()),
    });
  }, [onChange, range]);

  const dateString = DateFormatter.getDatesFormatted(
    range.start || DateFormatter.getToday(),
    range.end,
  );

  return (
      <>
        <div className={clsWrapper}>
          <button className={clsButton} onClick={selectPreviousDay}>
            <Icon name={'arrow-left'} description={labels.previousDay} />
          </button>
          <button className={clsPicker} onClick={showModal}>
            <Icon name={'calendar'} description={labels.openCalendar}/>
            <span className={clsText}>
              {dateString}
            </span>
          </button>
          <button className={clsButton} onClick={selectNextDay}>
            <Icon name={'arrow-right'} description={labels.nextDay}/>
          </button>
        </div>
        {show && (
          <Modal onClose={hideModal}>
            <DatePicker
              minDate={minDate}
              range={range}
              onSelect={selectDate}
              onCancel={hideModal}
              tracking={tracking}
              quickPick={quickPick}
              calendarPick
              manualPick={manualPick}
            />
          </Modal>
        )}
      </>
  );
};

export default CalendarDatepicker;
