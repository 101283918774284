// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// components
import Reactions, { IReactionsProps } from 'src/components/post/shared/reactions/reactions';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { textResources } from 'src/lang/de';

import './post_detail_reaction_wrapper.scss';

const cls = CLASS_PREFIX + 'post__detail__reactions';
const clsWrapper = cls + '-wrapper';

class PostDetailReactionWrapper extends React.Component<IReactionsProps> {
  public render() {
    const { canBookmark } = this.props;
    return (
      <>
        {this.renderHeadline()}
        <UserOnboardingCTA active={!canBookmark} gtmSourceName='canBookmark'>
          <div className={clsWrapper}>
            <Reactions {...this.props} />
          </div>
        </UserOnboardingCTA>
      </>
    );
  }
  private checkCandlePost(): boolean {
    return this.props.reactions.length === 1 && this.props.reactions[0].name === 'candle';
  }

  private renderHeadline() {
    const renderTitle = !this.checkCandlePost();
    const headline = (
      <h2 className={cls + '__headline'}>
        {textResources.reactions.title}
      </h2>
    );
    const spacer = <div className={cls + '__spacer'} />;
    return (renderTitle ? headline : spacer);
  }
}

export default PostDetailReactionWrapper;
