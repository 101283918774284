// libs
import { Location } from 'history';
import { useEffect, useRef } from 'react';
import { useLocation, useRouteMatch, match as IMatch } from 'react-router';

// constants
import { UPDATE_POST } from 'src/constants/urls';

// helpers
import GoogleTagManager, { IPageNavData } from 'src/utils/reporting/google-tag-manager';
import { getCreatePathFrom } from 'src/utils/url/url';

const locationToString = ({ pathname, search, hash, state }: Location) => {
  const extendedSearch = new URLSearchParams(search);

  if (state?.sourceComponent) {
    extendedSearch.append('source-component', state.sourceComponent);
  }

  return pathname + hash + (extendedSearch.toString().length > 0 ? '?' + extendedSearch.toString() : '');
};

export const checkIsDataReadyToReport = ({ user, ...restData }: IPageNavData): boolean => {
  if (user && user.loginRequestPending) {
    return false;
  }
  const values = Object.values(restData);
  if (values.length === 0) {
    return true;
  }
  return values.every((value) => !!value);
};

export const usePageTracking = (dataToReport: IPageNavData = {}, canReportImmediately?: boolean) => {
  const location = useLocation();
  const fullPath = locationToString(location);
  const pageViewReported = useRef(false);

  const latestData = useRef(dataToReport);

  const isReadyToReport = checkIsDataReadyToReport(dataToReport) || canReportImmediately;

  useEffect(() => {
    latestData.current = dataToReport;
  });

  useEffect(() => {
    if (isReadyToReport && !pageViewReported.current) {
      pageViewReported.current = true;
      GoogleTagManager.pushPageNavigation(fullPath, latestData.current);
    }
  }, [fullPath, isReadyToReport]);

  // report page-view if page unmounts or when fullPath changes but no
  // page-view was reported for the previus fullPath
  useEffect(() => () => {
    if (!pageViewReported.current) {
      GoogleTagManager.pushPageNavigation(fullPath, latestData.current);
    }
    pageViewReported.current = false;
  }, [fullPath]);
};

export const useIsPostCreateOpen = (match: IMatch) => {
  return !!useRouteMatch(getCreatePathFrom(match.path));
};

export const useIsPostUpdateOpen = (match: IMatch) => {
  return !!useRouteMatch(match.path + UPDATE_POST);
};
