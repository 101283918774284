import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getUnseenCounts } from 'src/actions/notification/notification';

export const useFetchNotificationUnseenCounts = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(getUnseenCounts());
  }, [dispatch]);
};
