import { isString } from 'lodash';

import { REGEXP_URL, REGEXP_EMAIL_GLOBAL } from 'src/utils/pattern/pattern';
import { addSchemaIfNotPresent } from 'src/utils/url/url';

// Used for parsing user created messages and generating links from it:
export const makeHTMLLinksFromText = (text: string): string => {
  if (isString(text)) {
    return text.replace(REGEXP_URL, (url) => {
      return `<a href=${addSchemaIfNotPresent(url)} target='_blank' rel='nofollow noopener'>${url}</a>`;
    }).replace(REGEXP_EMAIL_GLOBAL, (email) => {
      return `<a href=mailto:${email} target='_blank' rel='nofollow noopener'>${email}</a>`;
    });
  } else {
    return text;
  }
};
