// libs
import { useLPChatCounts } from '@lokalportal/lopo-chat-client';
import { template } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';

// components
import { Redirect } from 'react-router-dom';
import Chat from 'src/components/chat_page/chat/chat_wrapped';
import Button, { BUTTON_TYPE_CONTAINED, BUTTON_TYPE_TEXT } from 'src/components/forms/button/button';
import FieldSet, { FIELDSET_TYPE_SMALL_SPACE } from 'src/components/forms/field_set/field_set';
import MessageSegment from 'src/components/message_segment/message_segment';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { FRONTEND_NOT_FOUND_PATH, FRONTEND_NEWSFEED_PATH, FRONTEND_SETTINGS_PATH } from 'src/constants/urls';
import { COLOR_TYPE_PRIMARY, COLOR_TYPE_PRIMARY_2 } from 'src/utils/color';

// helpers
import { showRegistrationModal, showLoginModal } from 'src/actions/app-state/app-state';
import { textResources } from 'src/lang/de';
import { useSelectCurrentProfile, useSelectIsUserLoggedIn } from 'src/reducers/user/user_hooks';
import { isIntermediateProfile } from 'src/utils/profile';
import { getToggle } from 'src/utils/toggle';
import { getTopiChatToggles, IChatTopic } from './utils';

import './chat_page.scss';

const cls: string = CLASS_PREFIX + 'chat_page';
const clsHeaderImage = cls + '__header-image';

interface IInfoProps {
  currentTopic: IChatTopic;
}
const { topics, isEnabled } = getTopiChatToggles();
// TODO remove and get store data
const { serverUrl } =
  getToggle('topic_chat', { serverUrl: 'http://localhost:8080' });

const HeaderImage: React.FC<{src: string}> = ({ src }) => {
  return (
    <div className={clsHeaderImage}>
      <div className={clsHeaderImage + '-inner'}>
        <img src={src} alt={textResources.imageAlt.headerImage} />
      </div>
    </div>);
};
const LoggedOutInfo: React.FC<IInfoProps> = ({ currentTopic: { open, name, title, message, imageUrl } }) => {
  const dispatch = useDispatch();
  const onLogin = () => {
    dispatch(showLoginModal());
  };
  const onRegistration = () => {
    dispatch(showRegistrationModal());
  };
  const { numberOfUsers, numberOfNewMessages } = useLPChatCounts({
    roomId: name,
    url: serverUrl,
  });

  const messageCompiled = template(
    open ? message.loggedOutOpen : message.loggedOutClosed
  )({ messageCount: numberOfNewMessages, userCount: numberOfUsers });

  return (
    <div className={cls}>
      {imageUrl && <HeaderImage src={imageUrl} />}
      <MessageSegment
        headerIcon={imageUrl ? undefined : 'chat'}
        title={open ? title.loggedOutOpen : title.loggedInClosed}
        message={messageCompiled}
      />
      <FieldSet type={FIELDSET_TYPE_SMALL_SPACE}>
        <Button
          color={COLOR_TYPE_PRIMARY}
          fullWidth
          label={textResources.shared.register}
          lowerCase
          onClick={onRegistration}
          variant={BUTTON_TYPE_CONTAINED}
        />
        <Button
          color={COLOR_TYPE_PRIMARY_2}
          fullWidth
          label={textResources.shared.login}
          lowerCase
          onClick={onLogin}
          variant={BUTTON_TYPE_CONTAINED}
        />
      </FieldSet>
    </div>
  );
};

const ClosedInfo: React.FC<IInfoProps> = ({ currentTopic: { title, message, finished, imageUrl } }) => {
  return (
    <div className={cls}>
      {imageUrl && <HeaderImage src={imageUrl} />}
      <MessageSegment
        headerIcon={imageUrl ? undefined : 'chat'}
        title={finished ? title.finished : title.loggedInClosed}
        message={finished ? message.finished : message.loggedInClosed}
      />
    </div>
  );
};

const ChatPage: React.FC = () => {
  const history = useHistory();
  const isLoggedIn = useSelectIsUserLoggedIn();
  const selectedProfile = useSelectCurrentProfile();
  const { roomId } = useParams();

  const currentTopic = roomId && topics.find(({ name }) => name === roomId);
  const goToSettings = () => history.push(FRONTEND_SETTINGS_PATH);
  const goToNewsfeed = () => history.push(FRONTEND_NEWSFEED_PATH);

  if (isIntermediateProfile(selectedProfile.type)) {
    const { body, title } = textResources.intermediateProfile.completeProfile;
    return (
      <div className={cls}>
        <MessageSegment
          headerIcon={'complete-profile'}
          title={title}
          message={body}
        />
        <FieldSet type={FIELDSET_TYPE_SMALL_SPACE}>
          <Button
            color={COLOR_TYPE_PRIMARY}
            fullWidth
            label={title}
            lowerCase
            onClick={goToSettings}
            variant={BUTTON_TYPE_CONTAINED}
          />
          <Button
            fullWidth
            label={textResources.onboardingSettings.doItLater}
            lowerCase
            onClick={goToNewsfeed}
            variant={BUTTON_TYPE_TEXT}
          />
        </FieldSet>
      </div>);
  }

  if (!isEnabled || !currentTopic) {
    return <Redirect to={FRONTEND_NOT_FOUND_PATH} />;
  }

  if (!isLoggedIn) {
    return <LoggedOutInfo currentTopic={currentTopic} />;
  }

  if (!currentTopic.open) {
    return (
      <ClosedInfo currentTopic={currentTopic} />
    );
  }

  return (
    <Chat currentTopic={currentTopic} />
  );
};

export default ChatPage;
