import * as Response from 'src/api/interfaces/responses/resource_link';
import { IResourceLink } from 'src/interfaces/resource_links';

export const $resourceLink = (json: Response.IResourceLink): IResourceLink => ({
  params: {
    authorType: json.params.author_type,
    category: json.params.category,
    id: json.params.identifier,
  },
  path: json.path,
});
