import { flatten } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
// interfaces
import { Id } from 'src/interfaces';

import { IPagination } from 'src/interfaces/pagination';
import { IGroupListProfile, IProfile } from 'src/interfaces/profile';

import { IProfilesList, IProfilesListBase } from 'src/interfaces/profiles_list';
import { IRootState } from 'src/reducers/interface';
// helpers
import { useSelectProfiles } from 'src/reducers/profiles/profiles_hooks';
import { notUndefined } from 'src/utils';
import { ICategorizedProfiles, IGroupUsersGrouped } from './model';

export const useProfilesList = () =>
  useSelector<IRootState, IProfilesList>(({ profilesList }) => profilesList);

export const useSelectProfilesListIsLoading = () =>
  useSelector<IRootState, boolean>(({ profilesList }) => profilesList.isLoading);

export const useProfilesSecondaryList = () =>
  useSelector<IRootState, IProfilesListBase>(({ profilesList }) => profilesList.secondaryList);

export const useSelectProfilesIds = (): Id[] => {
  const { groups } = useProfilesList();

  return useMemo(() => flatten(groups.map(({ ids }) => ids)), [groups]);
};

export const useSelectSecondaryProfileIds = (): Id[] => {
  const { groups } = useProfilesSecondaryList();

  return useMemo(() => flatten(groups.map(({ ids }) => ids)), [groups]);
};

export const useSelectAllIds = (): Id[] => {
  const ids = useSelectProfilesIds();
  const secondaryIds = useSelectSecondaryProfileIds();
  return useMemo(() => ids.concat(secondaryIds), [ids, secondaryIds]);
};

export const useSecondaryProfiles = (): IProfile[] => {
  const profiles = useSelectProfiles();
  const secondaryIds = useSelectSecondaryProfileIds();

  return useMemo(() => {
    return secondaryIds.map(id => profiles[id]).filter(notUndefined);
  }, [profiles, secondaryIds]);
};

export const useProfilesFromIds = (groups: Id[]): IProfile[] => {
  const profiles = useSelectProfiles();

  return useMemo(() => {
    return groups.map(id => profiles[id]).filter(notUndefined);
  }, [profiles, groups]);
};

export const useFolloweeProfilesFromIds = (groups: Id[]): IProfile[] => {
  const profiles = useProfilesFromIds(groups);

  return useMemo(() => {
    return profiles.filter(profile => !!profile.meta.isFollowed);
  }, [profiles]);
};

export const useCategorizedProfiles = (): ICategorizedProfiles[] => {
  const { groups } = useProfilesList();
  const record = useSelectProfiles();
  const { pathname } = useLocation();

  return useMemo(() => {
    return groups.map(group => {
      return {
        profiles: group.ids.map(id => record[id]).filter(notUndefined),
        title: group.label || '',
        url: `${pathname}${group.slug}`,
      };
    });
  }, [record, groups, pathname]);
};

export const useProfilePaginationInfo = (isSecondary: boolean) =>
  useSelector<IRootState, IPagination>(({ profilesList }) =>
    isSecondary ? profilesList.secondaryList.pagination : profilesList.pagination);

export const useProfilesFromIdsGroupedByConfirmation = (ids: Id[]): IGroupUsersGrouped => {
  const profiles = useProfilesFromIds(ids) as IGroupListProfile[];
  return profiles.reduce<IGroupUsersGrouped>((acc, profile) => {
    if (profile.isConfirmedParticipant) {
      acc.confirmed.push(profile);
    } else {
      acc.unconfirmed.push(profile);
    }
    return acc;
  }, {
    confirmed: [],
    unconfirmed: [],
  });
};
