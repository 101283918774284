import * as React from 'react';
import Image, { IProps } from 'src/components/post/shared/ingredients/image/image';

export default class ImageSnapshot extends React.PureComponent<IProps, {}> {
  public render() {
    const { settings: { showLightbox }, data: { imageUrlsSquareMedium, originalImages } } = this.props;
    if (!imageUrlsSquareMedium || !imageUrlsSquareMedium.length) {
      return null;
    }

    const data = {
      fixedAspectRatio: false,
      imageUrls: imageUrlsSquareMedium,
      originalImages,
    };

    if (showLightbox) {
      return (
        <Image
          {...this.props}
          data={data}
        />
      );
    }

    return (
      <Image {...this.props} data={data} />
    );
  }
}
