// libs
import * as React from 'react';

import initTracker,
{ IReporterMapRoot,
  TrackerType } from 'src/utils/reporting/events_tracking/events_tracking';

export interface ITrackingType<T extends IReporterMapRoot> {
  tracker: TrackerType<T>;
  trackingObj: T;
};

export interface ITrackingProps<T extends IReporterMapRoot> {
  tracking: ITrackingType<T>;
}

export const withTrackerProps = <T extends IReporterMapRoot, P extends object>(trackingObj: T) => {
  const tracker = {
    tracker: initTracker(trackingObj),
    trackingObj,
  };
  return (Comp: React.ComponentType<ITrackingProps<T> & P>) =>
    (props: P) => <Comp tracking={tracker} {...props} />;
};
