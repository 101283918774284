import React, { FunctionComponent, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getConfigValue } from 'src/utils/config/config';
import { StoredLocationHelper } from 'src/utils/location_storage/location_storage';
import { setBoundingBox } from '../actions/app-state/app-state';
import { FeatureFlag } from '../components/feature_flag/feature_flag';
import { TWO_COLUMNS } from '../constants/layout';
import { useGetAppLocationShape } from '../reducers/app-state/app-state_hooks';

import LayoutMap, { LayoutMapContainerProps } from './layout_map_container';
import LayoutNoMap, { LayoutNoMapProps } from './layout_no_map';

type IProps = LayoutMapContainerProps & LayoutNoMapProps;

export const LayoutFeaturedContainer: FunctionComponent<IProps> = ({ children, ...props }) => {
  const dispatch = useDispatch();
  const locationShape = useGetAppLocationShape();
  const isMapEnabled = getConfigValue<boolean>('featureFlags.map.enabled');
  useEffect(() => {
    // Temporary workaround to pass current coordinates to the API
    if (!isMapEnabled) {
      const lastLocation = StoredLocationHelper.getLastLocation();
      if (lastLocation) {
        dispatch(setBoundingBox(lastLocation.boundingBox));
      } else if (locationShape) {
        dispatch(setBoundingBox(locationShape.boundingBox));
      } else {
        // eslint-disable-next-line no-console
        console.error('Mising location');
      }
    }
  }, [dispatch, isMapEnabled, locationShape]);

  return <FeatureFlag active={isMapEnabled} fallback={
    <LayoutNoMap {...props} contentWidth={TWO_COLUMNS}>
      {children}
    </LayoutNoMap>
  }>
    <LayoutMap {...props}>
      {children}
    </LayoutMap>
  </FeatureFlag>;
};
