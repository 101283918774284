// libs
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

// interfaces / constants
import { IAction } from 'src/actions/';
import PostCreate,
{
  IMapRouteToProps,
  IOwnProps,
  IPostCreateActions,
  IPostCreateStoreState,
} from 'src/components/post/create/create';
import { Id } from 'src/interfaces';
import {
  IContentItemType,
  IEventCreateData,
  IPostCreateData,
  IPost,
  IPostSurveyCreateData,
} from 'src/interfaces/posts';
import { IVerifiedUserProfile } from 'src/interfaces/user';
import { IRootState } from 'src/reducers/interface';
import { CREATE_POST } from 'src/utils/reporting/events_tracking/events_tracking';

// components
import withLoadingSpinner from 'src/high_order_components/with_loading_spinner';

// helpers
import { resetCampaign, createCampaign } from 'src/actions/campaign';
import { fetchCategories } from 'src/actions/categories/categories';
import { createEventPost, createPost, createPostWithCampaign, deletePost, Track } from 'src/actions/post/post';
import { withRouterProps } from 'src/high_order_components/connect_route_props';
import { withTrackerProps } from 'src/high_order_components/with_tracker_props';

const mapStateToProps = (state: IRootState, ownProps: IMapRouteToProps): IPostCreateStoreState => {
  const selectedProfileId = state.user.selectedProfileId;
  // The post create feature cannot have an intermediate profile, or not logged In one.
  const profile = state.user.profiles[selectedProfileId] as IVerifiedUserProfile;

  return {
    createdPostIdentifier: state.campaign.createdPostIdentifier,
    eventCategories: state.categories,
    hasError: state.post.hasError || state.campaign.hasError,
    isCampaignLoading: state.campaign.isLoading,
    isCreatePostLoading: state.post.isLoading,
    loggedInProfileAddress: profile.address,
    loggedInProfileLongIdentifier: profile.longIdentifier,
    loggedInProfilePermissions: profile.permissions,
    prefilledEditURL: ownProps.prefilledPostId ? state.feed.posts[ownProps.prefilledPostId]?.meta.urls.edit : undefined,
    profileType: profile.type,
  };
};

const mapDispatchToProps =
  (dispatch: ThunkDispatch<IRootState, {}, IAction>, ownProps: IOwnProps & IMapRouteToProps): IPostCreateActions => ({
    createCampaign: (campaignCreationUrl: string, amount: string, postIdentifier: string) =>
      dispatch(createCampaign(campaignCreationUrl, amount, postIdentifier)),
    createEventPost:
      (params: IEventCreateData, track: Track) =>
        dispatch(createEventPost(params, ownProps.history, track)),
    createPost:
      (params: IPostCreateData, type: IContentItemType, track: Track, path?: string, redirectToUrl?: string) =>
        dispatch(createPost(params, type, ownProps.history, track, path, redirectToUrl)),
    createPostWithCampaign: (params: IPostCreateData, callback: (contentItem: IPost) => void) =>
      dispatch(createPostWithCampaign(params, callback)),
    createSurvey:
      (
        params: IPostSurveyCreateData,
        type: IContentItemType,
        track: Track,
        path?: string,
        redirectToUrl?: string,
        callback?: () => void
      ) =>
        dispatch(createPost(params, type, ownProps.history, track, path, redirectToUrl, callback)),
    deletePost: (post: IPost) => dispatch(deletePost(post)),
    fetchCategories: () => dispatch(fetchCategories('Event')),
    resetCampaign: () => dispatch(resetCampaign()),
  });

const mapRouteToProps = ({ match, history, location }: RouteComponentProps<{ id: Id }>): IMapRouteToProps => (
  {
    history,
    matchUrl: match.url,
    path: location.pathname,
    prefilledPostId: match.params.id,
    search: location.search,
  });

export default withRouterProps<IMapRouteToProps, IOwnProps>(mapRouteToProps)(
  connect<IPostCreateStoreState, IPostCreateActions, IOwnProps & IMapRouteToProps>(
    mapStateToProps,
    mapDispatchToProps
  )(
    withTrackerProps<
      typeof CREATE_POST, IOwnProps & IMapRouteToProps & IPostCreateActions & IPostCreateStoreState
    >(CREATE_POST)(withLoadingSpinner(PostCreate))
  )
);
