import React from 'react';

// classes
import ChatBanner from 'src/components/chat_page/chat_banner/chat_banner';
import CollapsedBanner from 'src/containers/collapsed_banner';

// helpers

import { useChatTopicTargetsShape } from 'src/components/chat_page/utils';
import useScrollInvoker from 'src/hooks_shared/use_scroll_invoker';
import { useSelectIsUserLoggedIn } from 'src/reducers/user/user_hooks';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
// css
import './list_wrapper.scss';

const cls = createBemBlock('list-wrapper');

export interface IProps {
  getNextPage: (path?: string) => void;
  children: JSX.Element | JSX.Element[];
  initialLoad?: Boolean;
}

export const ListWrapper: React.FC<IProps> = ({ children, getNextPage, initialLoad }) => {
  const isUserLoggedIn = useSelectIsUserLoggedIn();
  useScrollInvoker(getNextPage, initialLoad);

  // part of topic-chat POC test
  // checks if there is currently a chatTopic running for the current Shape
  const chatTopicForCurrentShape = useChatTopicTargetsShape();

  return (
    <div className={cls()}>
      {children}
      {!isUserLoggedIn && <CollapsedBanner/>}
      {isUserLoggedIn && chatTopicForCurrentShape && chatTopicForCurrentShape.open &&
        <ChatBanner
          bannerText={chatTopicForCurrentShape.bannerText}
          topicName={chatTopicForCurrentShape.name}
        />}
    </div>
  );
};

export default ListWrapper;
