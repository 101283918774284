// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import {
  DATE_ERROR_INVALID_DATE,
  DATE_ERROR_TOO_EARLY,
  DATE_ERROR_TOO_LATE,
  DATE_ERROR_TOO_SHORT,
} from 'src/constants/form_input_validation';
import { DateValidationError } from 'src/interfaces/form_input_validation';

// classes
import InputField, { IProps as IInputProps } from 'src/components/forms/input_field/input_field';

// helpers
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import DateHelper from 'src/utils/date_helper/date';
import './input_date.scss';

const dateMask = (dateString?: string) => {
  if (!dateString) {
    return '';
  }

  // add dots while typing
  const match3 = dateString.match(/^(\d{2})(\d{1})$/);
  const match5 = dateString.match(/^(\d{2}).(\d{2})(\d{1})$/);

  if (match3) {
    dateString = match3[1] + '.' + match3[2];
  } else if (match5) {
    dateString = match5[1] + '.' + match5[2] + '.' + match5[3];
  }

  // handle copied date '21121980'
  const matchNoDots = dateString.match(/^(\d{2})(\d{2})(\d{4})$/);
  if (matchNoDots) {
    dateString = matchNoDots[1] + '.' + matchNoDots[2] + '.' + matchNoDots[3];
  }

  // handle copied date '1980-12-21'
  const matchISO = dateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (matchISO) {
    dateString = matchISO[3] + '.' + matchISO[2] + '.' + matchISO[1];
  }

  // if its a vlid input return it
  if (
    dateString.match(/^\d{1,2}$/) ||
    dateString.match(/^\d{2}\.$/) ||
    dateString.match(/^\d{2}\.\d{1,2}$/) ||
    dateString.match(/^\d{2}\.\d{2}\.$/) ||
    dateString.match(/^\d{2}\.\d{2}\.\d{1,4}$/)
  ) {
    return dateString;
  }

  // when not valid remove last char
  return dateString = dateString.substring(0, dateString.length - 1);
};

const getDateErrors = (date?: string, minDate?: string, maxDate?: string): DateValidationError | undefined => {
  if (!date || date === '') {
    return undefined;
  }

  if (date && date.length < 10) {
    return DATE_ERROR_TOO_SHORT;
  }

  const isoDate = parseDateString(date);

  if (!isoDate) {
    return DATE_ERROR_INVALID_DATE;
  }

  if (minDate && isBefore(isoDate, minDate)) {
    return DATE_ERROR_TOO_EARLY;
  }

  if (maxDate && isAfter(isoDate, maxDate)) {
    return DATE_ERROR_TOO_LATE;
  }

  return undefined;
};

const parseDateString = (date: string) => DateFormatter.parseDateString(date);

const isBefore = (start: string | undefined, end: string | undefined) =>
  (start && end)
    ? DateHelper.isBefore(start, end)
    : true;

const isAfter = (start: string | undefined, end: string | undefined) =>
  (start && end)
    ? DateHelper.isAfter(start, end)
    : true;

const cls: string = CLASS_PREFIX + 'input-date';

export interface IProps extends IInputProps {
  onClick?: () => void;
  onChange?: (value: string) => void;
  title?: string;
  leftIcon?: boolean;
}

interface IState {
  date: string | undefined;
  dateString: string;
}

export default class InputDate extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.handleReset = this.handleReset.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.getNewState = this.getNewState.bind(this);

    this.state = {
      date: props.value,
      dateString: dateMask(props.value),
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.value !== this.props.value) {
      const inputDate = this.props.value && parseDateString(this.props.value);
      if (inputDate) {
        this.updateState(this.props.value);
      }
    }
  }

  public render() {
    const { className, leftIcon = true, ...props } = this.props;

    let iconLeft;

    if (leftIcon === true) {
      iconLeft = 'calendar';
    }

    const DateInput = (
      <InputField
        {...props}
        className={classNames([`${cls}`, className])}
        value={this.state.dateString}
        iconLeft={iconLeft}
        onRightIconClick={this.handleReset}
        onChange={this.handleChange}
      />
    );

    return DateInput;
  }

  private getNewState(value?: string) {
    const { min, max } = this.props;
    const dateString = dateMask(value);

    const date = !getDateErrors(dateString, min, max)
      ? parseDateString(dateString)
      : undefined;

    return {
      date,
      dateString,
    };
  }

  private updateState(date?: string) {
    const { onChange } = this.props;
    const newState = this.getNewState(date);

    const isoDate = DateFormatter.parseDateString(newState.dateString);
    const returnDate = isoDate && DateFormatter.dateInDatePickerFormat(isoDate);

    if ((this.state.date !== newState.date) || (this.state.dateString !== newState.dateString)) {
      this.setState(
        newState,
        () => onChange && onChange(returnDate || ''),
      );
    }
  }

  private handleChange(value: string) {
    this.updateState(value);
  }

  private handleReset() {
    this.updateState('');
  }
}
