// libs
import React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { FRONTEND_CHAT_PATH } from 'src/constants/urls';

// components
import Icon from 'src/components/forms/icon/icon';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import initTracker, { TOPIC_CHAT } from 'src/utils/reporting/events_tracking/events_tracking';

// styles
import './chat_banner.scss';

const cls = CLASS_PREFIX + 'chat-banner';
const trigger = cls + '__trigger';

interface IProps {
  bannerText: string;
  topicName: string;
}

const tracker = initTracker(TOPIC_CHAT);

const ChatBanner: React.FC<IProps> = ({ bannerText, topicName }) => {
  const onClick = () => {
    tracker(TOPIC_CHAT.ACTIONS.BANNER, TOPIC_CHAT.LABELS.START);
  };
  return (
    <NavigationItem
      url={`${FRONTEND_CHAT_PATH}/${topicName}`}
      className={cls}
      onClick={onClick}
    >
      <div className={trigger}>
        <div className={trigger + '__logo--small'}>
          <Icon name={'paper-plane-filled'} size={24} />
        </div>
        <div className={trigger + '__label--small'}>
          {bannerText}
        </div>
      </div>
      <div className={trigger + '__arrow'}>
        <Icon name={'arrow-right'} size={18} />
      </div>
    </NavigationItem>
  );
};

export default ChatBanner;
