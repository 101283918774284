// lib
import React from 'react';
import { RouteComponentProps, useParams } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import HeaderGroup from 'src/containers/header/subheader/header_group';
import GroupFeed from 'src/containers/smart_components/feeds/group_feed';
import PageTitleGroupFeed from 'src/containers/smart_components/page_title/page_title_group_feed';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { GROUP_OVERVIEW } from 'src/constants/feed';
import { GROUP_FEED_TITLE } from 'src/constants/meta_tags';

// helpers
import { usePageTracking, useIsPostCreateOpen } from 'src/pages/hooks/hooks';
import { useGetGroupById } from 'src/reducers/groups/groups_hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';

const GroupFeedPage: React.FC<RouteComponentProps> = ({ match }) => {
  const user = useSelectUser();
  const { id } = useParams();
  const group = useGetGroupById(id);

  const isPostCreateOpen = useIsPostCreateOpen(match);

  const title = group && GROUP_FEED_TITLE(group.name);
  const description = group && group.description;

  usePageTracking({ pageTitle: title, user });

  return (
    <>
      <MetaTags
        description={description}
        title={title}
      />
      <LayoutNoMap
        activeNavigationItem={GROUP_OVERVIEW}
        subHeader={<HeaderGroup showMoreButton />}
        withHeaderMargin
        pageTitle={<PageTitleGroupFeed />}
      >
        <GroupFeed postCreateOpen={isPostCreateOpen} />
      </LayoutNoMap>
    </>
  );
};

export default GroupFeedPage;
