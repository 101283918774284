// libs
import React, { useEffect } from 'react';

// interfaces / constants
import { FEED_TYPE_EVENT, FEED_TYPE_EVENT_PARTICIPATING } from 'src/constants/feed';

// classes / components
import BannerFeed from 'src/components/feed/layouts/banner_feed/banner_feed';
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import FeedWrapper from 'src/containers/smart_components/list_wrapper/feed_wrapper';

// helpers
import {
  useFetchParticipatingEventFeedPage,
  useFetchParticipatingEventFeedNextPage,
} from 'src/actions/feed/events_feeds/events_feed_hooks';
import { useGetRenderEventCreate } from 'src/containers/smart_components/feeds/hooks';
import {
  useSelectFeedGroups,
  useSelectFeedIsLoading,
  useSelectPosts,
  useSelectFeedHash,
} from 'src/reducers/feed/feed_hooks';

interface IProps {
  postCreateOpen: boolean;
}

const ParticipatingEventFeed: React.FC<IProps> = ({ postCreateOpen }) => {
  const feedHash = useSelectFeedHash(FEED_TYPE_EVENT_PARTICIPATING);
  const groups = useSelectFeedGroups(FEED_TYPE_EVENT_PARTICIPATING);
  const isLoading = useSelectFeedIsLoading(FEED_TYPE_EVENT_PARTICIPATING);
  const posts = useSelectPosts();
  const getEventsFeedPage = useFetchParticipatingEventFeedPage();
  const getEventsFeedNextPage = useFetchParticipatingEventFeedNextPage();
  const renderPostCreate = useGetRenderEventCreate(postCreateOpen);

  useEffect(() => {
    getEventsFeedPage();
  }, [getEventsFeedPage, feedHash]);

  return (
    <LoadingSpinnerWrapper isLoading={isLoading}>
      <FeedWrapper
        getNextPage={getEventsFeedNextPage}
        feedType={FEED_TYPE_EVENT}
      >
        <BannerFeed
          groups={groups}
          posts={posts}
          renderPostCreate={renderPostCreate}
          isLoading={isLoading}
          showGroupLabels
        />
      </FeedWrapper>
    </LoadingSpinnerWrapper>
  );
};

ParticipatingEventFeed.displayName = 'ParticipatingEventFeed';

export default ParticipatingEventFeed;
