// libs
import * as React from 'react';

// interfaces / constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';

// classes
import Avatar, { IAvatarBadge } from 'src/components/avatar/avatar';

// helpers
import './segment_avatar.scss';

export interface IProps {
  imageUrl?: string;
  badge?: IAvatarBadge;
}

const cls: string = itemSegmentCls + 'avatar';

const SegmentAvatar: React.FC<IProps> = ({ imageUrl, badge }) => (
  <div className={cls}>
    <Avatar image={imageUrl} badge={badge} />
  </div>
);

export default SegmentAvatar;
