// libs
import * as blobUtil from 'blob-util';
import loadImage from 'blueimp-load-image';

// constants
import {
  POST_CREATION_IMAGE_COMPRESSION_QUALITY,
  POST_CREATION_MAX_IMAGE_SIZE,
} from 'src/constants/post_creation';

export type OutputFormat = 'jpeg' | 'png';

interface CompressOptions {
  quality?: number;
  maxWidth?: number;
  maxHeight?: number;
  outputFormat?: OutputFormat;
}

const defaultCompressOptions: CompressOptions = {
  outputFormat: 'jpeg',
  quality: 0.8,
};

function compress(source: Blob, options?: CompressOptions): Promise<Blob> {
  const _options = Object.assign({}, defaultCompressOptions, options);

  return new Promise((resolve, reject) => {
    if (_options.maxWidth !== undefined && _options.maxWidth <= 0) {
      reject('options.maxWidth must be greater than zero');
      throw new Error('options.maxWidth must be greater than zero');
    }
    if (_options.maxHeight !== undefined && _options.maxHeight <= 0) {
      reject('options.maxHeight must be greater than zero');
      throw new Error('options.maxHeight must be greater than zero');
    }
    if (_options.quality && (_options.quality < 0 || _options.quality > 1)) {
      reject('options.quality must be between 0 and 1');
      throw new Error('options.quality must be between 0 and 1');
    }

    const callback = (canvas: HTMLCanvasElement) => {
      const type = `image/${_options.outputFormat}`;
      blobUtil.canvasToBlob(canvas, type, _options.quality).then((blob) => {
        resolve(blob);
      });
    };
    const loadImageOptions = {
      canvas: true,
      maxHeight: _options.maxHeight,
      maxWidth: _options.maxWidth,
      orientation: true,
    };

    loadImage(source, callback, loadImageOptions);
  });
}

export const compressImage = (image: Blob): Promise<Blob> =>
  // see https://github.com/fastmonkeys/ahdin for available options
  compress(image, {
    maxHeight: POST_CREATION_MAX_IMAGE_SIZE,
    maxWidth: POST_CREATION_MAX_IMAGE_SIZE,
    outputFormat: 'jpeg',
    quality: POST_CREATION_IMAGE_COMPRESSION_QUALITY,
  });
