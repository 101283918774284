// lib
import React from 'react';

// components
import PageTitle from 'src/components/header/main/page_title/page_title';

// helpers
import { useSelectUrlParamProfile } from 'src/reducers/profiles/profiles_hooks';

const PageTitleFollowers: React.FC = () => {
  const profileName = useSelectUrlParamProfile()?.name;

  return (
    <PageTitle
      text={profileName || ''}
    />
  );
};

export default PageTitleFollowers;
