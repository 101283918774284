// libs
import * as React from 'react';

// components
import ContentPlaceholder from 'src/components/content_placeholder/content_placeholder';
import Icon from 'src/components/forms/icon/icon';

// helpers
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import './post_banner.scss';

const cls = createBemBlock('post-banner-placeholder');

const PostBannerPlaceholder: React.FC = () => (
  <div className={cls()}>
    <ContentPlaceholder className={cls('image')} />
    <div className={cls('block-content')}>
      <ContentPlaceholder className={cls('date')} />
      <ContentPlaceholder className={cls('title')} />
      <ContentPlaceholder className={cls('title')} />
      <div className={cls('location')}>
        <Icon className={cls('icon')} name='pin-standard' />
        <ContentPlaceholder className={cls('location-text')} />
      </div>
      <div className={cls('participant')}>
        <Icon className={cls('icon')} name='participant-icon' />
        <ContentPlaceholder className={cls('participant-text')} />
      </div>
    </div>
  </div>
);

export default PostBannerPlaceholder;
