// libs
import * as React from 'react';

// components
import MenuItemSegment from 'src/components/item_segment/layouts/menu/menu';
import Share, {
  IShareProps,
  IShareCompProps,
} from 'src/components/share/lib/share';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { textResources } from 'src/lang/de';

import './share_item_list_small.scss';

const cls: string = CLASS_PREFIX + 'share-list-small';

const ShareItemListComponent: React.FC<IShareCompProps> = ({ shareLinks, shareToClipboard }) => {
  return (
    <div className={cls}>
      {shareLinks.map((shareLink) =>
        <MenuItemSegment
          key={shareLink.name}
          label={shareLink.caption}
          navigation={{ target: '_blank', url: shareLink.shareUrl }}
        />)}
      <MenuItemSegment
        key='link'
        navigation={{ onClick: shareToClipboard }}
        label={textResources.share.link}
      />
    </div>
  );
};

const ShareItemListSmall = (props: IShareProps) => (
  <Share
    {...props}
    component={ShareItemListComponent}
  />
);

export default ShareItemListSmall;
