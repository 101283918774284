export enum NavigationFeatureFlags {
  NEWS = 'NEWS',
  EVENT = 'EVENT',
  GROUP = 'GROUP',
  USER_DIRECTORY = 'USER_DIRECTORY',
  NOTIFICATIONS = 'NOTIFICATIONS',
  CREATE_PROFILE = 'CREATE_PROFILE',
  ADD_SNAPSHOT_LOCATION = 'ADD_SNAPSHOT_LOCATION',
  ADD_EVENT_LOCATION = 'ADD_EVENT_LOCATION',
  SURVEYS = 'SURVEYS',
}
