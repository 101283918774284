import { WebSocketProvider } from '@lokalportal/frontends-shared';
import React from 'react';
import { WEBSOCKET_URL } from 'src/constants/urls';
import { useSelectCurrentProfile } from 'src/reducers/user/user_hooks';
import { AuthenticationHelper } from 'src/utils/authentication/authentication';
import { reportError } from 'src/utils/reporting/report-errors';

const logError = (error: any) => {
  reportError(error, { component: 'Mailbox' });
};

export const WebSocketProviderWrapper: React.FC<{children: React.ReactElement}> =
  ({ children }) => {
    const currentProfile = useSelectCurrentProfile();
    const ownProfileGid =
      currentProfile.type === 'no_user_profile' ||
      currentProfile.type === 'intermediate_profile'
        ? undefined
        : currentProfile.gid;

    return (
      <WebSocketProvider
        webSocketUrl={`${WEBSOCKET_URL}?access_token=${AuthenticationHelper.getOAuth2Credentials()?.accessToken}`}
        currentProfileGid={ownProfileGid}
        logError={logError}
      >
        {children}
      </WebSocketProvider>
    );
  };
