// libs
import React from 'react';

// components
import AddToCalendar from 'src/components/add_to_calender/add_to_calender';

// helpers
import { usePostData } from 'src/reducers/feed/feed_hooks';
import { IEvent } from 'src/utils/add_to_calender';

interface IProps {
  startsAt: string;
  endsAt?: string;
  postId: string;
}

const AddToCalenderContainer: React.FC<IProps> = ({ startsAt, endsAt, postId }) => {
  const post = usePostData(postId);
  const event: IEvent | undefined = post && {
    details: post.meta.teaser,
    endsAt,
    location: post.address,
    name: post.meta.title,
    startsAt,
    url: window.location.origin + post.urls.frontend,
  };
  return (
    <AddToCalendar event={event} />
  );
};

export default AddToCalenderContainer;
