import React, { useEffect } from 'react';
import { useFetchNotificationUnseenCounts } from 'src/actions/notification/notification_hooks';
import { NOTIFICATIONS_FETCH_INTERVAL } from 'src/constants/notifications';
import { useSelectProfilePermissions } from 'src/reducers/user/user_hooks';

const UnseenNotificationsPolling: React.FC = () => {
  const selectedProfilePermissions = useSelectProfilePermissions();

  const fetchNotificationUnseenCounts = useFetchNotificationUnseenCounts();

  useEffect(() => {
    let interval: number;
    if (selectedProfilePermissions && selectedProfilePermissions.readNotifications) {
      fetchNotificationUnseenCounts();

      interval = window.setInterval(
        fetchNotificationUnseenCounts,
        NOTIFICATIONS_FETCH_INTERVAL * 1000,
      );
    }
    return () => {
      window.clearInterval(interval);
    };
  }, [fetchNotificationUnseenCounts, selectedProfilePermissions]);

  return null;
};

export default UnseenNotificationsPolling;
