// libs
import * as React from 'react';

// interfaces / constants
import { IErrorResponse } from 'src/api/interfaces/errors';
import { CLASS_PREFIX } from 'src/constants/';
import { ILinkPreview } from 'src/interfaces/post_ingredients';
import { POST_DISPLAY_MODE_PREVIEW } from 'src/interfaces/posts';
import { SponsorLevel } from 'src/interfaces/profile';

// classes
import Avatar from 'src/components/avatar/avatar';
import Button, { BUTTON_TYPE_ROUNDMID } from 'src/components/forms/button/button';
import Icon from 'src/components/forms/icon/icon';
import LinkPreview,
{
  IPropsData as ILinkpreviewProps,
} from 'src/components/post/shared/ingredients/link_preview/link_preview';
import TextInput from 'src/components/text_input/text_input';
import { THUMBNAIL_SIZE_TYPE_SMALL, THUMBNAIL_SIZE_TYPE_XLARGE } from 'src/components/thumbnail/thumbnail';
import UploadedImage from 'src/components/uploader/uploaded_image';
import Uploader from 'src/components/uploader/uploader';

// helpers
import { textResources } from 'src/lang/de';
import { getSponsorBadge } from 'src/utils/badges';
import { COLOR_TYPE_DEFAULT, COLOR_TYPE_PRIMARY } from 'src/utils/color';
import './media_input.scss';

const cls = CLASS_PREFIX + 'media-input';
const clsButtonSeparator = `${cls}__button-separator`;
const clsCancelEditDesktop = `${cls}__cancel-edit--desktop`;
const clsMobileWrapper = `${cls}__mobile-wrapper`;
const labels = textResources.comments;

const MODE_TYPE_CREATE = 'create';
export const MODE_TYPE_EDIT = 'edit';

export type ModeType = 'create' | 'edit';

const MAX_IMAGE_NUMBER_DEFAULT = 10;

interface IProps {
  cancel?: () => void;
  cancelEditMessage?: string;
  cancelEditComponent?: JSX.Element;
  disabled: boolean;
  images?: string[];
  linkPreviewParams?: ILinkPreview;
  maxImageNumber?: number;
  mediaMaxImagesError: string;
  message?: string;
  mode?: ModeType;
  onError?: (error: IErrorResponse) => void;
  placeholder?: string;
  refProp?: (ref: TextInput) => void;
  sponsorLevel?: SponsorLevel;
  submit?: (str: string, images?: string[], oglinkId?: string) => void;
  update?: (message: string, images?: string[], oglinkId?: string) => void;
  userImageUrl: string;
}

interface IState {
  errorMessage: string | null;
  images: string[];
  linkPreviewParams?: ILinkPreview;
  message: string;
  mode: ModeType;
  originalImages: string[];
  originalLinkPreviewParams?: ILinkPreview;
  originalMessage: string;
}

export default class MediaInput extends React.PureComponent<IProps, IState> {
  public constructor(props: IProps) {
    super(props);
    const { images, message, mode, linkPreviewParams } = props;
    this.textInputChange = this.textInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.onDetectLink = this.onDetectLink.bind(this);
    this.onImageAdded = this.onImageAdded.bind(this);
    this.onImageDeleted = this.onImageDeleted.bind(this);
    this.onImageUploadError = this.onImageUploadError.bind(this);
    this.onRemoveLinkPreview = this.onRemoveLinkPreview.bind(this);
    this.renderCancelEditOnMobile = this.renderCancelEditOnMobile.bind(this);
    this.renderCancelEditOnDesktop = this.renderCancelEditOnDesktop.bind(this);
    this.renderImage = this.renderImage.bind(this);
    this.renderLinkPreview = this.renderLinkPreview.bind(this);
    this.renderSubmitButton = this.renderSubmitButton.bind(this);
    this.renderUpdateButton = this.renderUpdateButton.bind(this);
    this.renderUploadButton = this.renderUploadButton.bind(this);
    this.state = {
      errorMessage: null,
      images: images || [],
      linkPreviewParams,
      message: message || '',
      mode: mode || MODE_TYPE_CREATE,
      originalImages: images || [],
      originalLinkPreviewParams: linkPreviewParams,
      originalMessage: message || '',
    };
  }

  public componentDidUpdate(prevProps: IProps) {
    const { images, message, mode } = this.props;
    if ((prevProps.images !== images) && images) {
      this.setState({ images });
    }
    if ((prevProps.message !== message) && message) {
      this.setState({ message });
    }
    if ((prevProps.mode !== mode) && mode) {
      this.setState({ mode });
    }
  }

  public render() {
    const { disabled, placeholder, refProp, sponsorLevel, userImageUrl } = this.props;
    const { errorMessage, images, message, mode, linkPreviewParams } = this.state;
    return (
      <div className={cls} >
        {mode === MODE_TYPE_EDIT && this.renderCancelEditOnMobile()}
        {(images.length > 0 || linkPreviewParams) && (
          <div className={clsMobileWrapper}>
            {images.length > 0 && (
              <div className={cls + '__images--mobile'}>
                <div className={cls + '__images'}> {images.map(this.renderImage)} </div>
              </div>
            )}
            {linkPreviewParams && (
              <div className={cls + '__link-preview--mobile'}>
                {this.renderLinkPreview()}
              </div>
            )}
          </div>
        )}
        {errorMessage && <div className={`${cls}__error-message`}> {errorMessage} </div>}

        <div className={cls + '__wrapper'}>
          <div className={cls + '__avatar'}>
            <Avatar
              image={userImageUrl}
              size={THUMBNAIL_SIZE_TYPE_SMALL}
              withBorder
              badge={sponsorLevel && getSponsorBadge(sponsorLevel)}
            />
          </div>
          <div className={cls + '__form-wrapper'}>
            <div className={cls + '__form'}>
              <div className={cls + '__input-container'}>
                <TextInput
                  className={cls + '__textarea'}
                  disabled={disabled}
                  expandable={true}
                  initialRows={1}
                  maxHeight={60}
                  name='media-input'
                  onChange={this.textInputChange}
                  placeholder={placeholder}
                  value={message}
                  onDetectLink={this.onDetectLink}
                  showEmoji={true}
                  ref={refProp}
                />
              </div>
              {this.renderUploadButton()}
              <div className={clsButtonSeparator} />
              {mode === MODE_TYPE_CREATE && this.renderSubmitButton()}
              {mode === MODE_TYPE_EDIT && this.renderUpdateButton()}
            </div>
            {images.length > 0 && (
              <div className={cls + '__images--desktop'}>
                <div className={cls + '__images'}> {images.map(this.renderImage)} </div>
              </div>
            )}
            {linkPreviewParams && (
              <div className={cls + '__link-preview--desktop'}>
                {this.renderLinkPreview()}
              </div>
            )}
            {mode === MODE_TYPE_EDIT && this.renderCancelEditOnDesktop()}
          </div>
        </div>
      </div>
    );
  }

  private renderLinkPreview() {
    const { linkPreviewParams } = this.state;
    if (!linkPreviewParams) {
      return null;
    }
    const { author, description, displayUrl, imageUrl, title, url }: ILinkPreview = linkPreviewParams;
    const linkPreviewData: ILinkpreviewProps = {
      author: author || displayUrl,
      description,
      imageUrl,
      onRemove: this.onRemoveLinkPreview,
      showRemoveIcon: true,
      title,
      url,
    };
    return (
      <LinkPreview
        data={linkPreviewData}
        displayMode={POST_DISPLAY_MODE_PREVIEW}
        settings={{ show: true }}
        postId={''}
      />
    );
  }

  private onRemoveLinkPreview() {
    this.state.linkPreviewParams && this.setState({ linkPreviewParams: undefined });
  }

  private onDetectLink(link: ILinkPreview | undefined): void {
    this.setState({ linkPreviewParams: link });
  }

  private renderCancelEditOnMobile(): JSX.Element {
    const { cancelEditMessage } = this.props;
    return (
      <button className={`${cls}__cancel-edit--mobile`} onClick={this.props.cancel}>
        {cancelEditMessage}
        <div className={`${cls}__cancel-edit--mobile-close`}>
          <Icon aria-hidden='true' name={`thin-x`} />
        </div>
      </button>
    );
  }

  private renderCancelEditOnDesktop(): JSX.Element {
    const { cancelEditComponent } = this.props;

    return (
      <div className={clsCancelEditDesktop}>
        {cancelEditComponent}
      </div>
    );
  }

  private renderSubmitButton(): JSX.Element {
    const { disabled } = this.props;
    const { message } = this.state;
    return (
      <span>
        <Button
          className={cls + '__submit'}
          disabled={disabled || message.trim() === ''}
          color={COLOR_TYPE_PRIMARY}
          icon={'paper-plane-filled'}
          type='button'
          variant={BUTTON_TYPE_ROUNDMID}
          onClick={this.handleSubmit}
          title={labels.submit}
        />
      </span>
    );
  }

  private renderUploadButton(): JSX.Element {
    const { disabled } = this.props;
    const { images } = this.state;
    return (
      <Uploader
        activeClassName={`${cls}__uploader--active`}
        rejectClassName={`${cls}__uploader--reject`}
        onError={this.onImageUploadError}
        onImageAdded={this.onImageAdded}
        {...images}
      >
        <Button
          className={cls + '__camera'}
          disabled={disabled}
          color={COLOR_TYPE_DEFAULT}
          icon={'camera-filled'}
          type='button'
          variant={BUTTON_TYPE_ROUNDMID}
          title={labels.attachImage}
        />
      </Uploader>
    );
  }

  private renderUpdateButton(): JSX.Element {
    const { disabled } = this.props;
    const {
      images,
      linkPreviewParams,
      message,
      originalImages,
      originalLinkPreviewParams,
      originalMessage,
    } = this.state;

    const commentChanged =
      message !== originalMessage ||
      images !== originalImages ||
      linkPreviewParams !== originalLinkPreviewParams;

    return (
      <Button
        className={cls + '__update'}
        disabled={disabled || message.trim() === '' || !commentChanged}
        color={COLOR_TYPE_PRIMARY}
        icon={'check'}
        variant={BUTTON_TYPE_ROUNDMID}
        onClick={this.handleUpdate}
        title={labels.update}
      />
    );
  }

  private renderImage(image: string, index: number) {
    return (
      <UploadedImage
        onDelete={this.onImageDeleted}
        url={image}
        key={index}
        index={index}
        size={THUMBNAIL_SIZE_TYPE_XLARGE}
      />
    );
  }

  private checkImageNumber() {
    const { images } = this.state;
    const maxImageNumber = this.props.maxImageNumber || MAX_IMAGE_NUMBER_DEFAULT;
    const { mediaMaxImagesError } = this.props;
    if (images && images.length > maxImageNumber) {
      const newImages = images.slice(0, maxImageNumber);
      this.setState({ errorMessage: mediaMaxImagesError, images: newImages });
    } else {
      this.setState({ errorMessage: null });
    }
  }

  private handleUpdate(): void {
    const { update } = this.props;
    const { images, message, mode, linkPreviewParams } = this.state;
    const oglinkId = linkPreviewParams && linkPreviewParams.id;
    if (mode === MODE_TYPE_EDIT && update) {
      update(message, images.length > 0 ? images : undefined, oglinkId);
    }
    this.setState({
      errorMessage: null,
      images: [],
      linkPreviewParams: undefined,
      message: '',
      mode: MODE_TYPE_CREATE,
    });
  }

  private handleSubmit(): void {
    const { submit } = this.props;
    const { images, message, mode, linkPreviewParams } = this.state;
    const oglinkId = linkPreviewParams && linkPreviewParams.id;
    if (mode === MODE_TYPE_CREATE && submit) {
      submit(message, images.length ? images : undefined, oglinkId);
    }
    this.setState({
      errorMessage: null,
      images: [],
      linkPreviewParams: undefined,
      message: '',
      mode: MODE_TYPE_CREATE,
    });
  }

  private onImageAdded(addedImages: string[]) {
    const { images } = this.state;
    const newState = { images: (images || []).concat(addedImages) };
    this.setState({ ...newState, errorMessage: null });
    this.checkImageNumber();
  }

  private onImageDeleted(index: number) {
    const { images } = this.state;
    if (images && images.length) {
      const newState = { images: images.slice(0, index).concat(images.slice(index + 1)) };
      this.setState(newState);
    }
    this.checkImageNumber();
  }

  private onImageUploadError(error: IErrorResponse) {
    const { onError } = this.props;
    this.setState({ errorMessage: textResources.shared.imageAddError });
    onError && onError(error);
  }

  private textInputChange(value: string) {
    this.setState({ message: value });
  }
}
