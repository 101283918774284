// api
import api, { IRegistrationFixedParams, IRegistrationParams } from 'src/api/';

// constants
import {
  OAUTH2_APPLE_REGISTRATION_URL,
  OAUTH2_FACEBOOK_REGISTRATION_URL,
  REDIRECT_URL_REGISTRATION_APPLE,
  REDIRECT_URL_REGISTRATION_FACEBOOK,
} from 'src/constants/urls';

// helpers
import { IErrorResponse } from 'src/api/interfaces/errors';
import { UrlUtils } from 'src/utils/url/url';

export class RegistrationHelper {
  // sends a signup request.
  public static register(params: IRegistrationParams, googlePlaceId: string): Promise<{} | null> {
    return new Promise((resolve, reject) => {
      const redirectUrl = UrlUtils.emailConfirmationRedirectUrl();
      const fixedParams: IRegistrationFixedParams = {
        ...params,
        ageCheckAccepted: true,
        googlePlaceId,
        privacyConditionsAccepted: true,
        redirectUrl,
        termsOfUseAccepted: true,
      };
      // send the registration request
      api.me.registration(fixedParams)
        .then(() => {
          // registration successful, resolve promise
          return resolve();
        })
        .catch((error: IErrorResponse) => {
          // registration was not successful, reject with error
          return reject(error);
        });
    });
  }

  // sends a signup request via Facebook
  public static registerWithFacebook(googlePlaceId: string): Promise<{} | null> {
    return new Promise((resolve) => {
      this.redirectToFacebookRegistration(googlePlaceId);

      // this resolve will never happen, but let's make TypeScript happy
      return resolve();
    });
  }

  public static registerWithFacebookByPlaceIdAndEmail(googlePlaceId: string, email: string): void {
    this.redirectToFacebookRegistration(googlePlaceId, email);
  }

  private static redirectToFacebookRegistration(googlePlaceId: string, email?: string) {
    const redirectUrl = UrlUtils.getCurrentUrlOrigin() +
                        encodeURIComponent(REDIRECT_URL_REGISTRATION_FACEBOOK(googlePlaceId));
    UrlUtils.setLocationHref(OAUTH2_FACEBOOK_REGISTRATION_URL(googlePlaceId, redirectUrl, email));
  }

  // sends a signup request via "Sign in with Apple"
  public static registerWithApple(googlePlaceId: string): Promise<{} | null> {
    return new Promise((resolve) => {
      const redirectUrl = UrlUtils.getCurrentUrlOrigin() +
                          encodeURIComponent(REDIRECT_URL_REGISTRATION_APPLE(googlePlaceId));
      UrlUtils.setLocationHref(OAUTH2_APPLE_REGISTRATION_URL(googlePlaceId, redirectUrl));

      // this resolve will never happen, but let's make TypeScript happy
      return resolve();
    });
  }
}
