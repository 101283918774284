// lib
import * as React from 'react';

// constants
import { AUTH_UI_CLASS } from 'src/constants/';
import { PASSWORD_MIN_QUERY_LENGTH } from 'src/constants/form_input_validation';
import {
  FRONTEND_DATA_AGB_PATH,
  FRONTEND_DATA_PROTECTION_PATH,
} from 'src/constants/urls';

// components
import Button, {
  IBtnType,
  BtnVariant,
  BUTTON_TYPE_CONTAINED,
  BUTTON_TYPE_TEXT,
} from 'src/components/forms/button/button';
import InputField, { IProps as IInputProps } from 'src/components/forms/input_field/input_field';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import { textResources } from 'src/lang/de';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import {
  COLOR_TYPE_DEFAULT,
  COLOR_TYPE_APPLE,
  COLOR_TYPE_PRIMARY,
  ColorType,
} from 'src/utils/color';

const labels = textResources.authentication;
const cls = createBemBlock(AUTH_UI_CLASS);

export const EMailField = (props: IInputProps) => (
  <InputField
    label={textResources.shared.email}
    outlined
    errorText={props.errorText || textResources.shared.errorEmail}
    type='email'
    required
    {...props}
  />
);

export const PasswordField = (props: IInputProps) => (
  <InputField
    label={props.label || textResources.shared.password}
    outlined
    type='password'
    required
    {...props}
  />
);

export const CreatePasswordField = (props: IInputProps) => (
  <PasswordField
    autoComplete='new-password'
    errorText={textResources.shared.inputTooShort(PASSWORD_MIN_QUERY_LENGTH)}
    assistiveText={textResources.shared.inputTooShort(PASSWORD_MIN_QUERY_LENGTH)}
    {...props}
  />
);

export const RepeatedPasswordField = (props: IInputProps) => (
  <PasswordField
    autoComplete='new-password'
    errorText={labels.errorRepeatPassword}
    {...props}
  />
);

interface IBtnProps {
  label: string;
  onClick?: (e: React.SyntheticEvent<HTMLElement>) => void;
  color?: ColorType;
  type?: IBtnType;
}

interface IAuthBtnProps extends IBtnProps {
  icon?: string;
  variant?: BtnVariant;
}

export const DefaultButton = (props: IBtnProps) =>
  <AuthButton label={props.label} onClick={props.onClick} />;

export const PrimaryButton = (props: IBtnProps) =>
  <AuthButton label={props.label} onClick={props.onClick} color={props.color || COLOR_TYPE_PRIMARY} type='submit' />;

/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-ignore
export const FacebookButton = (props: IBtnProps) => {
  return <></>;
  /**
   * Temporarily disabled, because facebook app is not configured properly
   * return <AuthButton label={props.label} onClick={props.onClick} color={COLOR_TYPE_FACEBOOK} />;
   */
};
/* eslint-enable @typescript-eslint/no-unused-vars */

export const AppleButton = (props: IBtnProps) =>
  <AuthButton label={props.label} onClick={props.onClick} color={COLOR_TYPE_APPLE} icon='apple' />;

export const TextButton = (props: IBtnProps) =>
  <AuthButton label={props.label} onClick={props.onClick} variant={BUTTON_TYPE_TEXT} />;

const AuthButton = (props: IAuthBtnProps) => (
  <Button
    variant={props.variant || BUTTON_TYPE_CONTAINED}
    color={props.color || COLOR_TYPE_DEFAULT}
    label={props.label}
    onClick={props.onClick}
    type={props.type}
    icon={props.icon}
    fullWidth
    lowerCase
  />
);

export const AGB = () => (
  <p className={cls('confirm-agb')}>
    {labels.agb1}
    <NavigationItem target='_blank' url={FRONTEND_DATA_AGB_PATH}>{labels.agb2}</NavigationItem>
    {labels.agb3}
    <NavigationItem target='_blank' url={FRONTEND_DATA_PROTECTION_PATH}>{labels.agb4}</NavigationItem>
    {labels.agb5}
  </p>);
