// libs
import React, { useEffect } from 'react';

// interfaces / constants
import { IFeedType } from 'src/interfaces/feed';

// classes / components
import MasonryFeed from 'src/components/feed/layouts/masonry_feed/masonry_feed';
import FeedWrapper from 'src/containers/smart_components/list_wrapper/feed_wrapper';

// helpers
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import {
  useSelectFeedGroups,
  useSelectFeedIsLoading,
  useSelectPosts,
} from 'src/reducers/feed/feed_hooks';

interface IProps {
  feedType: IFeedType;
  getFirstPage: () => void;
  getNextPage: (path?: string) => void;
}

const PaginationFeed: React.FC<IProps> = ({ feedType, getFirstPage, getNextPage }) => {
  const posts = useSelectPosts();
  const groups = useSelectFeedGroups(feedType);
  const isLoading = useSelectFeedIsLoading(feedType);

  useEffect(() => {
    getFirstPage();
  }, [feedType, getFirstPage, isLoading]);

  return (
    <LoadingSpinnerWrapper isLoading={isLoading}>
      <FeedWrapper
        getNextPage={getNextPage}
        feedType={feedType}
      >
        <MasonryFeed
          groups={groups}
          posts={posts}
          isLoading={isLoading}
        />
      </FeedWrapper>
    </LoadingSpinnerWrapper>
  );
};

export default PaginationFeed;
