export const GTM_CATEGORY_ONBOARDING = 'onboarding';
export const GTM_CATEGORY_FEATURE_BLOCKER = 'featureBlocker';
export const GTM_CATEGORY_BANNERBLOCKER = 'bannerBlocker';

export const GTM_ACTION_PUBLIC_PROFILE_SELECT = 'publicProfileSelect';
export const GTM_ACTION_BANNER_BLOCKER = 'show';
export const GTM_ACTION_FEATURE_BLOCKER = 'featureBlocker';
export const GTM_ACTION_STEP1 = 'step1';
export const GTM_ACTION_STEP2 = 'step2';
export const GTM_ACTION_STEP3 = 'step3';

export const GTM_LABEL_START = 'start';
export const GTM_LABEL_GO_BACK = 'goBack';
export const GTM_LABEL_GO_BLOGGER = 'goBlogger';
export const GTM_LABEL_GO_ASSOCIATIOIN = 'goAssociation';
export const GTM_LABEL_GO_BUSINESS = 'goBusiness';
export const GTM_LABEL_GO_AUTHORITY = 'goAuthority';
export const GTM_LABEL_GO_PRESS = 'goPress';
export const GTM_LABEL_ON_PUBLIC = 'onPublic';
export const GTM_LABEL_ON_NEIGHBOUR = 'onNeighbour';
export const GTM_LABEL_SKIP_STEP3 = 'skipStep3';
export const GTM_LABEL_TO_NEWSLETTER = 'toNewsletter';
export const GTM_LABEL_TO_SMS = 'toSMS';
export const GTM_LABEL_TO_VERIFYCODE = 'toVerifyCode';
export const GTM_LABEL_TO_VERIFY_SMS_CODE = 'toVerifySMSCode';

export const GTM_CUSTOM_EVENT = 'lopo_custom_event';
