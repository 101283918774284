import { debounce } from 'lodash';
import * as React from 'react';

// helpers
import googleApi, { IAutocompleteParams } from 'src/api/google/';
import { textResources } from 'src/lang/de';

// interfaces
import { IAutocompleteTypes } from 'src/api/google/interfaces/requests';
import { IPosition } from 'src/interfaces/location';
import { IGooglePrediction } from 'src/interfaces/map';

// constants
import { CLASS_PREFIX } from 'src/constants';
import {
  LOCATION_PICKER_INPUT_DEBOUNCE,
  LOCATION_PICKER_MIN_QUERY_LENGTH,
} from 'src/constants/location_picker';

// components
import InputField from 'src/components/forms/input_field/input_field';
import LocationPickerItemSegment from 'src/components/item_segment/layouts/location_picker/location_picker';

// assets
import './location_search.scss';
import * as poweredByGoogle from './powered-by-google.svg';

interface IProps {
  autoFocus?: boolean; // default: true
  autoSearch: boolean;
  onChangeQuery: (query: string) => void;
  onFocus: () => void;
  onSelect: (location: IGooglePrediction) => void;
  mapPosition?: IPosition;
  onHasResults: (value: boolean) => void;
  clear?: () => void;
  close?: () => void;
  type?: IAutocompleteTypes;
  strictBounds?: boolean;
  radius?: number;
  query: string;
  searchTitle?: string;
  errorText?: string;
  label?: string;
  valid?: boolean;
}

interface IState {
  autoSearch: boolean;
  query: string;
  predictions: IGooglePrediction[];
  showResult: boolean;
  isResponse: boolean;
}

const cls: string = CLASS_PREFIX + 'location-picker';
const resultsListClass: string = `${cls}__results`;
const pickerHeaderClass: string = `${cls}__header`;
const labels = textResources.locationChanger;

export default class GoogleLocationSearch extends React.PureComponent<IProps, IState> {
  private inputRef: React.RefObject<InputField>;

  public constructor(props: IProps) {
    super(props);

    this.state = {
      autoSearch: true,
      isResponse: false,
      predictions: [],
      query: '',
      showResult: false,
    };

    this.handlePickLocation = this.handlePickLocation.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleOnFocus = this.handleOnFocus.bind(this);
    this.resetValueOrClose = this.resetValueOrClose.bind(this);
    this.resetLocations = this.resetLocations.bind(this);
    this.onClose = this.onClose.bind(this);
    this.searchLocations = debounce(this.searchLocations.bind(this), LOCATION_PICKER_INPUT_DEBOUNCE);

    this.renderResult = this.renderResult.bind(this);
    this.renderSearchResults = this.renderSearchResults.bind(this);
    this.renderLocationSelect = this.renderLocationSelect.bind(this);
    this.focusInput = this.focusInput.bind(this);

    this.inputRef = React.createRef();
  }

  public componentDidMount() {
    const { autoSearch, query } = this.props;
    this.setState({ autoSearch, query });
  }

  public componentDidUpdate(prevProps: IProps) {
    const { autoSearch, query } = this.props;
    if (prevProps.autoSearch !== autoSearch) {
      this.setState({ autoSearch });
    }
    if (prevProps.query !== query) {
      this.setState({ query });
    }
  }

  public focusInput() {
    this.inputRef.current && this.inputRef.current.focusInput();
  }

  public render() {
    return (
      <>
        {this.renderLocationSelect()}
        {this.renderResult()}
      </>
    );
  }

  private renderResult() {
    const { autoSearch, query, predictions, isResponse, showResult } = this.state;
    if (!autoSearch || !query.length || !isResponse) {
      return null;
    }

    const resultsListClassState = showResult ? resultsListClass : `${resultsListClass}--hide`;
    return (
      <span className={resultsListClassState}>
        <div className={pickerHeaderClass} >
          <img className={pickerHeaderClass + '__powered-by-google'}
            alt={textResources.imageAlt.poweredByGoogle}
            src={poweredByGoogle}
          />
          <h2 className={pickerHeaderClass + '__title'}>{labels.searchResults}</h2>
        </div>
        {this.renderSearchResults(predictions)}
      </span>
    );
  }

  private renderSearchResults(predictions: IGooglePrediction[]) {
    return (
      predictions.length
        ? (
          <>
            {predictions.map((location: IGooglePrediction, index: number) => (
              <LocationPickerItemSegment
                key={index}
                label={location.description}
                navigation={{ onClick: this.handlePickLocation(location) }}
              />
            ))}
          </>
        )
        : <p className={`${cls}__noLocationFound`}>{textResources.shared.noResultFound}</p>
    );
  }

  private renderLocationSelect() {
    const { autoFocus = true, valid = true, errorText, label } = this.props;
    const { query } = this.state;
    const minimumSearchQueryHint = textResources.shared.inputTooShort(LOCATION_PICKER_MIN_QUERY_LENGTH);
    const assistiveText = !this.isQueryLongEnough(query) && query.length !== 0
      ? minimumSearchQueryHint
      : '';

    return (
      <div className={`${cls}__search`}>
        <InputField
          assistiveText={assistiveText}
          autoComplete={'off'}
          label={label || labels.inputPlaceholder}
          name='location_search'
          iconLeft={'nav-pin-filled'}
          iconRight={'x-circle'}
          autoFocus={autoFocus}
          outlined
          type='search'
          onRightIconClick={this.resetValueOrClose}
          onChange={this.handleInputChange}
          onFocus={this.handleOnFocus}
          value={query}
          ref={this.inputRef}
          valid={valid}
          errorText={errorText}
        />
      </div>
    );
  }

  private handleOnFocus() {
    const { onFocus } = this.props;
    onFocus && onFocus();
  }

  private handlePickLocation(prediction: IGooglePrediction) {
    return () => {
      this.props.onSelect(prediction);
      this.setState({ showResult: false });
    };
  }

  private handleInputChange(query: string) {
    const { onChangeQuery } = this.props;
    this.setState({ autoSearch: true, query, showResult: true });
    onChangeQuery && onChangeQuery(query);

    return this.isQueryLongEnough(query)
      ? this.searchLocations(query)
      : this.resetLocations();
  }

  private searchLocations(query: string) {
    const { strictBounds, mapPosition, radius, type } = this.props;
    const params: IAutocompleteParams = {
      isStrict: strictBounds,
      position: mapPosition,
      query,
      radius,
      type,
    };

    const onHasResults = this.props.onHasResults;

    return googleApi.autocomplete.list(
      params,
      (predictions: null | IGooglePrediction[]) => {
        predictions = predictions || [];

        this.setState({ isResponse: true, predictions });
        if (predictions.length) {
          onHasResults(true);
        }
      },
    );
  }

  private resetLocations() {
    this.props.onHasResults(false);

    this.setState({
      isResponse: false,
      predictions: [],
    });
  }

  private resetValueOrClose() {
    this.state.query.length ? this.onClear() : this.onClose();
  }

  private isQueryLongEnough(query: string) {
    return (query.length >= LOCATION_PICKER_MIN_QUERY_LENGTH);
  }

  private onClose() {
    const { close } = this.props;
    close && close();
  }

  private onClear() {
    this.props.onChangeQuery('');
    this.inputRef.current && this.inputRef.current.focusInput();
    const { clear } = this.props;
    this.setState({ query: '' });
    clear && clear();
  }
}
