// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import {
  IImageIngredientSettings,
  IImageWithDate,
  IIngredient,
} from 'src/interfaces/post_ingredients';
import { mapDisplayModeToConst,
  POST_DISPLAY_MODE_BANNER,
} from 'src/interfaces/posts';

// classes
import NavigationItem from 'src/components/navigation_item/navigation_item';
import Image from 'src/components/post/shared/ingredients/image/image';

// helpers
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import DateHelper from 'src/utils/date_helper/date';

// styles
import './image_with_date.scss';

type IProps = IIngredient<IImageWithDate, IImageIngredientSettings>;

const clsPrefix = `${CLASS_PREFIX}image-with-date__`;

interface IDateProps {
  cls: string;
  date: string;
}

const ShortDate: React.FC<IDateProps> = ({ cls, date }) => {
  const textDate: string = DateFormatter.calendarFromDay(date);
  return (
    <div className={`${cls}__short-date`}>
      {textDate}
    </div>
  );
};

const Date: React.FC<IDateProps> = ({ cls, date }) => {
  const month: string = DateFormatter.asMonth(date);
  const day: string = DateFormatter.asDay(date);
  return (
    <div className={`${cls}__date`}>
      <div className={`${cls}__day`}>{day}</div>
      <div className={`${cls}__month`}>{month}</div>
    </div>
  );
};

const ImageWithDate: React.FC<IProps> = React.memo(({
  data: { date, ...restProps },
  displayMode,
  postDetailsURL,
  postId,
  settings,
}) => {
  const cls = `${clsPrefix}${mapDisplayModeToConst[displayMode]}`;

  if (displayMode === POST_DISPLAY_MODE_BANNER) {
    return (
      <NavigationItem target='_self' url={postDetailsURL} >
        <div className={cls}>
          <Image
            data={{ ...restProps, asPicture: true }}
            displayMode={displayMode}
            settings={settings}
            postId={postId}
          />
        </div>
      </NavigationItem>
    );
  } else {
    const renderShortDate = DateHelper.isTodayOrTomorrow(date);
    settings.showGallery = true;
    return (
      <NavigationItem target='_self' url={postDetailsURL} >
        <div className={cls}>
          <div className={cls}>
            <Image data={restProps} displayMode={displayMode} settings={settings} postId={postId} />
          </div>
          {renderShortDate ? <ShortDate cls={cls} date={date} /> : <Date cls={cls} date={date} />}
        </div>
      </NavigationItem>
    );
  }
});

export default ImageWithDate;
