// libs
import * as React from 'react';

import Icon from 'src/components/forms/icon/icon';

import { CLASS_PREFIX } from 'src/constants/';

import { textResources } from 'src/lang/de';
import './backbutton.scss';

const cls: string = CLASS_PREFIX + 'back-button';

interface IProps {
  onClick?: () => void;
}

/**
 * (header) back button component
 */
const BackButton: React.FC<IProps> = (props) => (
  <div className={cls} {...props}>
    <Icon name={'arrow-left'} description={textResources.shared.back}/>
  </div>
);

/* set displayname to not have a component without a name */
BackButton.displayName = 'BackButton';

export default BackButton;
