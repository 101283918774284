// libs
import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// interfaces / constants
import { ILocationShape, IPosition, IBoundingBox } from 'src/interfaces/location';
import { IRootState } from 'src/reducers/interface';

// components
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';

// helpers
import { changeAppLocation, fetchLocationSlug } from 'src/actions/app-state/app-state';
import {
  useRouteStateLocation,
} from 'src/containers/location/use_route_state_location_hook';
import { replaceLocationSlugFromPath } from 'src/utils/url/url';

interface IStateToProps {
  locationShape?: ILocationShape;
  userLocationShape?: ILocationShape;
  boundingBox?: IBoundingBox;
  position?: IPosition;
}

const mapStateToProps = ({ appState, user }: IRootState): IStateToProps => {
  const selectedProfile = user && user.profiles[user.selectedProfileId];

  return {
    boundingBox: appState.boundingBox,
    locationShape: appState.locationShape,
    position: appState.position,
    userLocationShape:
      selectedProfile.address && selectedProfile.address.locationShape,
  };
};

const mapDispatchToProps = {
  changeLocation: changeAppLocation,
  updateLocationSlug: fetchLocationSlug,
};

type IDispatchToProps = typeof mapDispatchToProps;

type LocationShapeRouteProps = RouteComponentProps<{ locationSlug: string}>;

export type IProps = IStateToProps & IDispatchToProps & LocationShapeRouteProps;

export const LocationSlugHandler: React.FC<IProps> = ({
  match, locationShape, updateLocationSlug, boundingBox, position, children, history, location, changeLocation,
  userLocationShape,
}) => {
  const storeLocationSlug = locationShape?.slug;
  const routeLocationSlug = match.params.locationSlug;
  const userLocationSlug = userLocationShape?.slug;

  const skipLocationSlugUpdate = useRef(false);

  useEffect(() => {
    skipLocationSlugUpdate.current = false;
  });

  // possible scenarios:
  // 1. a new locationSlug and no locationShape in history.location.state
  // -> check for userLocationShape to save an extra fetch of locationShape
  // 1.1 the new locationSlug is the same we currently have in the store
  // -> exit (nothing to change here)
  // 1.2 userLocation is the one we want to change to
  // -> changeLocation(userLocationShape)
  // 1.3 we don't know this LocationShape
  // -> updateLocationSlug(routeLocationSlug);
  // 2. same locationSlug different position or boundingBox (with state)
  // -> take the routeState locationShape, position and boundingBox

  useEffect(() => {
    // 1
    if (!location.state?.locationShape) {
      // 1.1
      if (storeLocationSlug && storeLocationSlug === routeLocationSlug) {
        return;
      }

      // 1.2
      if (userLocationShape && userLocationSlug === routeLocationSlug) {
        skipLocationSlugUpdate.current = true;
        changeLocation(userLocationShape);
        return;
      }

      // 1.3
      updateLocationSlug(routeLocationSlug);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeLocationSlug]);

  // 2.
  useRouteStateLocation({
    routeLocation: location.state,
    stateLocation: { boundingBox, locationShape, position },
  });

  // to update url when somewhere in the app the storeLocation was changed via actions
  // or if someone pushed a wrong url where the
  // location.state.locationShape.slug is not matching the one in the url
  //
  useEffect(() => {
    if (!skipLocationSlugUpdate.current && storeLocationSlug && (storeLocationSlug !== routeLocationSlug)) {
      history.push(replaceLocationSlugFromPath(match, storeLocationSlug));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeLocationSlug]);

  return (
    <>
      {!locationShape ? <LoadingSpinner shown={true}/> : children}
    </>
  );
};

export default withRouter(connect<IStateToProps, IDispatchToProps, LocationShapeRouteProps>(
  mapStateToProps,
  mapDispatchToProps,
)(LocationSlugHandler));
