import React, { FunctionComponent } from 'react';
import { GenericIProfile } from 'src/interfaces/profile';
import { getSponsorBadge } from 'src/utils/badges';
import { UrlUtils } from 'src/utils/url/url';
import ProfilesListItemSegment from '../item_segment/layouts/profiles_list/profiles_list';

interface IProps {
  profile: GenericIProfile;
}
const ProfileListItem: FunctionComponent<IProps> = ({ profile }: IProps) => {
  const { id, sponsorLevel, imageUrl, name, description, type } = profile;
  return (
    <ProfilesListItemSegment
      key={id}
      avatar={{ badge: sponsorLevel && getSponsorBadge(sponsorLevel), imageUrl }}
      label={name}
      description={description}
      navigation={{ url: UrlUtils.getProfileFrontendPath(id, type) }}
      action={{ follow: { followableId: id } }}
    />
  );
};

export default ProfileListItem;
