// libs
import * as React from 'react';

// components
import NavigationItem from 'src/components/navigation_item/navigation_item';

// interfaces / constants
import * as MAP from 'src/constants/map';
import { IMapStyleToggle } from 'src/interfaces/';

// helpers
import { textResources } from 'src/lang/de';
import { getToggle } from 'src/utils/toggle';
import { isPrerenderIoAgent } from 'src/utils/user_agent/user_agent';

interface IProps {
  position: any;
  zoom: number;
  markerColor: string;
  address?: string;
}

const defaultMapStyle: IMapStyleToggle = {
  styleURL: MAP.MAP_DEFAULT_MAPBOX_STYLE,
};

const mapStyleToggle = getToggle<IMapStyleToggle>('mapStyle', defaultMapStyle);

interface IGetImageURLParams {
  longitude: string;
  latitude: string;
  zoom: string | number;
  markerColor: string;
}

const getImageUrl = ({ latitude, longitude, zoom, markerColor }: IGetImageURLParams) => {
  // this function creates an URL to retrieve a map-image with the marker from the mapbox static API
  // see https://docs.mapbox.com/api/maps/#static for how this URL can be structured
  return encodeURI(
    `https://api.mapbox.com/styles/v1/${mapStyleToggle.styleURL}/static/pin-l+${markerColor}(${longitude},` +
    `${latitude})/${longitude},${latitude},${zoom},0,0/336x112?logo=false&access_token=${MAP.MAP_TOKEN}`);
};

const googleMapsSearchUrl = (address: string) =>
  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

const StaticMap: React.FC<IProps> =
  ({ address, position: { longitude, latitude }, zoom, markerColor }) => {
    // Don't show the map for prerender as it causes > 100k requests which is quite costly
    if (isPrerenderIoAgent()) {
      return null;
    }

    const imageUrl = getImageUrl({ latitude, longitude, markerColor, zoom });
    return address
      ? (
        <NavigationItem target='_blank' url={googleMapsSearchUrl(address)} >
          <img alt={textResources.imageAlt.staticMap} src={imageUrl} />
        </NavigationItem>
      )
      : <img alt={textResources.imageAlt.staticMap} src={imageUrl} />;
  };

export default StaticMap;
