// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import ProfilesDirectoryCategorized
  from 'src/containers/smart_components/profiles_directory/categorized/profiles_directory_categorized';

// constants & interfaces
import { PROFILES_DIRECTORY } from 'src/constants/feed';
import {
  DEFAULT_ROBOTS,
  NOINDEX_FOLLOW_ROBOTS,
  PROFILES_DIRECTORY_DESCRIPTION,
  PROFILES_DIRECTORY_TITLE,
} from 'src/constants/meta_tags';
import { textResources } from 'src/lang/de';

// helpers
import { LOCATION_TYPE_SHAPE } from 'src/constants/location_type';
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { getPageTitle } from 'src/utils/page_title/page_title';
import { LayoutFeaturedContainer } from '../layout/layout_featured_container';

const ProfilesDirectoryCategorizedPage: React.FC<RouteComponentProps> = () => {
  const user = useSelectUser();
  const locationShape = useGetAppLocationShape();

  const title = locationShape && PROFILES_DIRECTORY_TITLE(locationShape.name);
  const description = locationShape && PROFILES_DIRECTORY_DESCRIPTION(locationShape.name);

  usePageTracking({ pageTitle: title, user });

  return (
    <>
      <MetaTags
        description={description}
        title={title}
        robots={locationShape?.type === LOCATION_TYPE_SHAPE ? NOINDEX_FOLLOW_ROBOTS : DEFAULT_ROBOTS}
      />
      <LayoutFeaturedContainer activeNavigationItem={PROFILES_DIRECTORY}
        pageTitle={
          <StandardPageTitle
            text={getPageTitle(textResources.pageTitles.profileSearchSimple, textResources.pageTitles.profileSearch)}
            showLocation
          />
        }
        withHeaderMargin>
        <ProfilesDirectoryCategorized />
      </LayoutFeaturedContainer>
    </>
  );
};

export default ProfilesDirectoryCategorizedPage;
