import { IAttachment } from 'src/api/interfaces/responses/attachment';
import { IOglink } from 'src/api/interfaces/responses/oglink';
import { IProfile } from 'src/api/interfaces/responses/profile';
import { IReactions } from 'src/api/interfaces/responses/reaction';
import { IIdentifiable, IPagination } from 'src/api/interfaces/responses/shared';

type CommentDeletor
  = 'author'
  | 'commentable_author'
  | 'administrator'
  | 'unknown'
  | 'other';

export interface ICommentCreateResponse {
  data: IComment;
}

export type ICommentItem = IComment | IDeletedComment;

export interface ICommentsResponse {
  data: ICommentItem[];
  meta: IPagination;
}

export interface IComment extends IIdentifiable {
  attachments: IAttachment[];
  author: IProfile;
  created_at: string;
  deleted_at: string | null;
  message: string;
  meta: {
    can_bookmark: boolean;
    can_delete: boolean;
    can_flag: boolean;
    can_update: boolean;
    is_own: boolean;
  };
  oglink?: IOglink;
  reactions: IReactions;
  updated_at: string;
  urls: {
    author: string;
    commentable: string;
    flag: string;
    resource: string;
  };
}

export interface IDeletedComment extends IIdentifiable {
  created_at: string;
  deleted_at: string | null;
  updated_at: string;
  meta: {
    can_bookmark: boolean;
    can_delete: boolean;
    can_flag: boolean;
    can_update: boolean;
    deleted_by: CommentDeletor;
    is_own: boolean;
  };
  urls: {
    commentable: string;
    resource: string;
  };
}

export const isDeletedComment = (comment: ICommentItem): comment is IDeletedComment => {
  return !!comment.deleted_at;
};
