import classNames from 'classnames';
import { sumBy } from 'lodash';
import React, { useMemo, useCallback } from 'react';

// components
import NavigationItem from 'src/components/navigation_item/navigation_item';

// interfaces & constants
import { FRONTEND_POST_REACTIONS_PATH } from 'src/constants/urls';
import { Id } from 'src/interfaces';
import { PostDisplayMode } from 'src/interfaces/posts';
import { ReactionName } from 'src/interfaces/reactions';

// helper
import { textResources } from 'src/lang/de';
import { useSelectReactions } from 'src/reducers/feed/feed_hooks';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';

import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';
import './reactions_overview.scss';

interface IProps {
  postId: Id;
  displayMode: PostDisplayMode;
  basePath: string;
  disabled: boolean;
}

interface IReactionIcon {
  name: ReactionName;
  iconCls: (name?: ReactionName) => string;
}

const ReactionIcon: React.FC<IReactionIcon> = ({ name, iconCls }) =>
  <div className={classNames(iconCls(), iconCls(name))} />;

const ReactionOverview: React.FC<IProps> = ({ postId, displayMode, basePath, disabled }) => {
  const cls = useCallback(createBemBlock(`reactions-overview__${displayMode}`), [displayMode]);
  const iconCls = useCallback((modifier?: string) => cls('icon', modifier), [cls]);

  const { reactions = [], lastReactedProfileName } = useSelectReactions(postId) || {};
  const count = useMemo(() => sumBy(reactions, 'number'), [reactions]);
  const reactionIcons = useMemo(() =>
    // we reverse for the css stacking of the icons
    reactions.slice().reverse().map(({ name, number }) =>
      number > 0 && <ReactionIcon key={name} name={name} iconCls={iconCls} />)
  , [iconCls, reactions]);

  if (count === 0 || !lastReactedProfileName) {
    return null;
  }

  return (
    <UserOnboardingCTA
      active={disabled}
      gtmSourceName='reactions-overview'
      className={cls('feature-blocker')}
    >
      <NavigationItem className={cls()} url={basePath + FRONTEND_POST_REACTIONS_PATH}>
        <div className={cls('icons')} >
          {reactionIcons}
        </div>
        <p>{textResources.reactions.countInfo(lastReactedProfileName, count)}</p>
      </NavigationItem>
    </UserOnboardingCTA >
  );
};

export default ReactionOverview;
