// libs
import { History } from 'history';
import { omit } from 'lodash';
import * as React from 'react';

// interfaces / constants
import { Track } from 'src/actions/post/post';
import {
  MSGBOX_BUTTON_TYPE_CANCEL,
  MSGBOX_BUTTON_TYPE_DANGER,
} from 'src/components/message_box/message_box';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';
import { CLASS_PREFIX } from 'src/constants/';
import {
  FEED_POST_TYPE_SNAPSHOT_POST,
  FEED_POST_TYPE_EVENT,
} from 'src/constants/feed';
import { ITrackingProps } from 'src/high_order_components/with_tracker_props';
import { Id } from 'src/interfaces';
import { IAddress } from 'src/interfaces/address';
import { IImageObject } from 'src/interfaces/attachment';
import { IEventCategory } from 'src/interfaces/categories';
import { ILinkPreview } from 'src/interfaces/post_ingredients';
import {
  IContentItemType,
  IEventCreateData,
  IPostCreateData,
  IPost,
  IEventEditView,
  IPostSurveyCreateData,
} from 'src/interfaces/posts';
import { FullProfileType } from 'src/interfaces/profile';
import { IPermissions } from 'src/interfaces/user';
import { textResources } from 'src/lang/de';
import { IEventData } from 'src/post-creation-wizard/wizards/common/interfaces';
import { getConfigValue } from 'src/utils/config/config';
import {
  PostCreateObjType,
  TrackingLabel, FINISH_ACTION } from 'src/utils/reporting/events_tracking/events_tracking';

// components
import Modal from 'src/components/modal/modal';
import Uploader from 'src/components/uploader/uploader';
import FloatingPostCreate from 'src/containers/smart_components/floating_post_create/floating_post_create';

// helpers
import api from 'src/api/index';
import { getLabel, postSubmitTracking } from 'src/components/post/utils';
import { attachmentToImage, imageToString, urlToDataURL } from 'src/utils/attachment/attachment';
import { COLOR_TYPE_PRIMARY, COLOR_TYPE_SECONDARY6, COLOR_TYPE_PRIMARY_2 } from 'src/utils/color';
import { readColumnCountFromDOM } from 'src/utils/column_count';
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import { reportError } from 'src/utils/reporting/report-errors';
import { createWritePath, getCreatePathFrom, UrlUtils } from 'src/utils/url/url';

import { FRONTEND_NEWSFEED_PATH } from '../../../constants/urls';
import { ISurveyParams } from '../../../interfaces/survey';
import './create.scss';
const PostCreationWizard = React.lazy(() =>
  import(/* webpackChunkName: "post-creation-wizard" */'src/post-creation-wizard/wizards/create/create_wizard'));

const cls = CLASS_PREFIX + 'post-create';
const labels = textResources.postCreate;

type DefaultIconType = typeof COLOR_TYPE_PRIMARY
  | typeof COLOR_TYPE_PRIMARY_2
  | typeof COLOR_TYPE_SECONDARY6;

export interface IOwnProps {
  postType?: IContentItemType;
  defaultIconType?: DefaultIconType;
  groupId?: string;
  groupName?: string;
  open?: boolean;
  showsBanner: boolean;
  featureBlocked: boolean;
  topicPostPath?: string;
  onClose?: () => void;
}

export interface IMapRouteToProps {
  history: History;
  path: string;
  matchUrl: string;
  search: string;
  prefilledPostId?: Id;
}

type IPostCreateProps =
  IPostCreateStoreState
  & IPostCreateActions
  & IOwnProps
  & IMapRouteToProps
  & ITrackingProps<PostCreateObjType>;

export interface IPostCreateStoreState {
  createdPostIdentifier: string;
  eventCategories: IEventCategory[];
  hasError: boolean;
  isCampaignLoading: boolean;
  isCreatePostLoading: boolean;
  loggedInProfileAddress: IAddress;
  loggedInProfileLongIdentifier: string;
  loggedInProfilePermissions: IPermissions;
  profileType: FullProfileType;
  prefilledEditURL?: string;
}

export interface IPostCreateActions {
  createCampaign: (campaignCreationUrl: string, amount: string, postIdentifier: string) => void;
  createEventPost: (params: IEventCreateData, track: Track) => void;
  createPost: (
    params: IPostCreateData,
    type: IContentItemType,
    track: Track,
    path?: string,
    redirectToUrl?: string
  ) => void;
  createSurvey: (
    params: IPostSurveyCreateData,
    type: IContentItemType,
    track: Track,
    path?: string,
    redirectToUrl?: string,
    callback?: () => void
  ) => void;
  createPostWithCampaign: (params: IPostCreateData, callback: (content: IPost) => void) => void;
  deletePost: (post: IPost) => void;
  fetchCategories: () => void;
  resetCampaign: () => void;
}

export interface ICampaignData {
  lokalportal?: boolean;
  facebook?: boolean;
  instagram?: boolean;
  google?: boolean;
  nwDigital?: boolean;
  nwPrint?: boolean;
  amount?: string;
}

interface IState {
  address?: IAddress;
  preFilledAddress?: IAddress;
  body: string;
  eventData: IEventData;
  post?: IPost;
  postType?: IContentItemType;
  showMessageBox: boolean;
  title: string;
  savedImages: IImageObject[];
  linkPreviewId?: string;
  linkPreviewParams?: ILinkPreview;
  campaign: ICampaignData;
  titleImageIndex: number;
  firstStep?: string;
  draftedImages: IImageObject[][];
  columnCount: number;
  commentable: boolean;
  surveyParams?: ISurveyParams;
}

const initialState = {
  address: getConfigValue<IAddress>('featureFlags.location.defaultAddress') || undefined,
  body: '',
  campaign: {
  },
  columnCount: 0,
  commentable: true,
  draftedImages: [],
  eventData: {
    category: undefined,
    endDate: undefined,
    endTime: undefined,
    startDate: undefined,
    startTime: undefined,
  },
  firstStep: undefined,
  post: undefined,
  postType: undefined,
  preFilledAddress: undefined,
  savedImages: [],
  showMessageBox: false,
  title: '',
  titleImageIndex: 0,
};

class PostCreate extends React.Component<IPostCreateProps, IState> {
  private uploaderRef = React.createRef<Uploader>();
  constructor(props: IPostCreateProps) {
    super(props);
    this.cancel = this.cancel.bind(this);
    this.cleanUpImages = this.cleanUpImages.bind(this);
    this.createEvent = this.createEvent.bind(this);
    this.createPost = this.createPost.bind(this);
    this.closeMessageBox = this.closeMessageBox.bind(this);
    this.hideWizard = this.hideWizard.bind(this);
    this.onModalClose = this.onModalClose.bind(this);
    this.setAddress = this.setAddress.bind(this);
    this.setBody = this.setBody.bind(this);
    this.setCampaign = this.setCampaign.bind(this);
    this.setCommentable = this.setCommentable.bind(this);
    this.setDraftedImages = this.setDraftedImages.bind(this);
    this.setFirstStep = this.setFirstStep.bind(this);
    this.setPostType = this.setPostType.bind(this);
    this.setSavedImages = this.setSavedImages.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.setSurveyDetails = this.setSurveyDetails.bind(this);
    this.setTitleImageIndex = this.setTitleImageIndex.bind(this);
    this.showMessageBox = this.showMessageBox.bind(this);
    this.updateEvent = this.updateEvent.bind(this);
    this.setLinkPreviewParams = this.setLinkPreviewParams.bind(this);
    this.onSnapshotAdded = this.onSnapshotAdded.bind(this);
    this.openFilePicker = this.openFilePicker.bind(this);
    this.fetchEventEditView = this.fetchEventEditView.bind(this);
    this.state = {
      ...initialState,
      columnCount: readColumnCountFromDOM(),
    };
  }

  public componentDidMount() {
    this.fetchEventEditView();
  }

  public render() {
    const {
      createCampaign,
      createdPostIdentifier,
      defaultIconType = 'primary',
      eventCategories,
      loggedInProfileAddress,
      loggedInProfileLongIdentifier,
      loggedInProfilePermissions,
      deletePost,
      topicPostPath,
      history,
      matchUrl,
      hasError,
      groupName,
      isCampaignLoading,
      isCreatePostLoading,
      open,
      resetCampaign,
      search,
      profileType,
      tracking,
      fetchCategories,
      showsBanner,
      featureBlocked,
    } = this.props;

    const { savedImages, campaign, address, showMessageBox, post, postType, title, body, eventData, linkPreviewParams,
      titleImageIndex, firstStep, draftedImages, preFilledAddress, commentable, surveyParams } = this.state;

    const params = {
      address,
      basePath: getCreatePathFrom(matchUrl),
      body,
      campaign,
      cleanUpImages: this.cleanUpImages,
      commentable,
      createCampaign,
      createEvent: this.createEvent,
      createPost: this.createPost,
      createdPostIdentifier,
      deletePost,
      draftedImages,
      eventCategories,
      eventData,
      fetchCategories,
      firstStep,
      groupName,
      hasError,
      history,
      isCampaignLoading,
      isCreatePostLoading,
      label: getLabel(profileType),
      linkPreviewParams,
      loggedInProfileAddress,
      loggedInProfileLongIdentifier,
      loggedInProfilePermissions,
      onCancel: this.showMessageBox,
      post,
      postType,
      preFilledAddress,
      profileType,
      resetCampaign,
      savedImages,
      search,
      setAddress: this.setAddress,
      setBody: this.setBody,
      setCampaign: this.setCampaign,
      setCommentable: this.setCommentable,
      setDraftedImages: this.setDraftedImages,
      setFirstStep: this.setFirstStep,
      setLinkPreviewParams: this.setLinkPreviewParams,
      setPostType: this.setPostType,
      setSavedImages: this.setSavedImages,
      setSurveyDetails: this.setSurveyDetails,
      setTitle: this.setTitle,
      setTitleImageIndex: this.setTitleImageIndex,
      surveyParams,
      title,
      titleImageIndex,
      topicPostPath,
      tracking,
      updateEvent: this.updateEvent,
    };

    return (
      <>
        {open && <Modal onClose={this.onModalClose}>
          <React.Suspense fallback={null}>
            <PostCreationWizard
              className={cls + '__post-creation-wizard'}
              onEnd={this.hideWizard}
              {...params}
            />
          </React.Suspense>
        </Modal>}
        {showMessageBox &&
            <MessageBoxWithModal
              buttons={[
                {
                  label: labels.closeWindow,
                  onClick: this.cancel,
                  type: MSGBOX_BUTTON_TYPE_DANGER,
                },
                {
                  label: labels.continueWritingPost,
                  onClick: this.closeMessageBox,
                  type: MSGBOX_BUTTON_TYPE_CANCEL,
                },
              ]}
              onClose={this.closeMessageBox}
              title={labels.cancelTitle}
            >
              <p>
                {labels.cancelMessage}
              </p>
            </MessageBoxWithModal>
        }
          <Uploader
            ref={this.uploaderRef}
            onImageAdded={this.onSnapshotAdded}
            maxImageNumber={1}
            multiple={false} />
            <FloatingPostCreate
              postTypes={this.props.postType}
              iconColor={defaultIconType}
              openFilePicker={this.openFilePicker}
              remAboveBottom={showsBanner ? 4 : undefined}
              featureBlocked={featureBlocked}
            />
      </>
    );
  }

  private setLinkPreviewParams(linkPreviewParams: ILinkPreview) {
    this.setState({ linkPreviewParams });
  }

  private setDraftedImages(draftedImages: IImageObject[][]) {
    this.setState({ draftedImages });
  }

  private setFirstStep(id: string) {
    this.setState({ firstStep: id });
  }

  private updateEvent(newEventData: IEventData) {
    this.setState({ eventData: { ...this.state.eventData, ...newEventData } });
  }

  private setTitleImageIndex(index: number) {
    this.setState({ titleImageIndex: index });
  }

  private setSavedImages(savedImages: IImageObject[]) {
    this.setState({ savedImages });
  }

  private cleanUpImages() {
    this.setState({ draftedImages: [] });
  }

  private setAddress(address: IAddress) {
    this.setState({ address });
  }

  private setPostType(postType?: IContentItemType) {
    const { tracking } = this.props;
    if (postType) {
      postSubmitTracking(postType, tracking.trackingObj.LABELS.START, tracking);
    }
    this.setState({ postType });
  }

  private setBody(body: string) {
    this.setState({ body });
  }

  private setTitle(title: string) {
    this.setState({ title });
  }

  private setSurveyDetails(surveyParams: ISurveyParams) {
    this.setState({ surveyParams: surveyParams });
  }

  private setCampaign(campaign: ICampaignData) {
    this.setState({ campaign });
  }

  private setCommentable(commentable: boolean) {
    this.setState({ commentable });
  }

  private onModalClose() {
    const { createdPostIdentifier } = this.props;
    if (createdPostIdentifier) {
      this.cancel();
      return;
    }
    this.showMessageBox();
  }

  private hideWizard() {
    const { history, matchUrl, search } = this.props;
    history.push(getCreatePathFrom(matchUrl) + search);
  }

  private showMessageBox() {
    this.setState({ showMessageBox: true });
  }

  private closeMessageBox() {
    this.setState({ showMessageBox: false });
  }

  private cancel() {
    const { matchUrl, history, search, onClose } = this.props;
    this.setState(omit(initialState, 'columnCount'));
    onClose && onClose();
    history.replace(getCreatePathFrom(matchUrl) + search);
  }

  private createPost(additionalParams?: ISurveyParams) {
    const { title, body, savedImages, postType, campaign, address, linkPreviewParams, titleImageIndex, commentable } =
      this.state;
    const { createPostWithCampaign, createCampaign, createPost, createSurvey, groupId, tracking } = this.props;
    const params: IPostCreateData = {
      body,
      campaignAmount: campaign.amount,
      commentable,
      contentItemType: postType,
      googlePlaceId: address && address.googlePlaceId,
      images: this.reorderImages(savedImages, titleImageIndex).map(imageToString),
      linkPreviewId: linkPreviewParams && linkPreviewParams.id,
      title,
    };
    if (campaign.amount) {
      createPostWithCampaign(params, (post: IPost) => {
        postSubmitTracking(post.meta.contentItemType, FINISH_ACTION, tracking);
        this.setState({ post });
        campaign.amount && createCampaign(post.urls.api, campaign.amount, post.id);
      });
    } else {
      if (!postType) {
        return;
      }
      if (postType === 'survey') {
        createSurvey({
          ...params,
          expires_at: additionalParams!.expires_at,
          surveyDetails: additionalParams!.surveyDetails,
          survey: true,
          commentable: false,
          title: additionalParams!.surveyDetails.title,
          body: 'BODY',
        },
        postType,
        (label: TrackingLabel) => {
          postSubmitTracking(postType, label, tracking);
        },
        groupId ? this.props.topicPostPath : undefined,
        groupId ? UrlUtils.groupPage(groupId) : FRONTEND_NEWSFEED_PATH,
        () => this.setPostType(undefined)
        );
      } else {
        createPost(
          params,
          postType,
          (label: TrackingLabel) => {
            postSubmitTracking(postType, label, tracking);
          },
          this.props.topicPostPath,
        );
      }
    }
  }

  private createEvent() {
    const { title, body, savedImages, eventData, address, linkPreviewParams, titleImageIndex } = this.state;

    if (eventData.startTime && eventData.startDate && (address && address.googlePlaceId)) {
      const categoryId = eventData.category!;
      const startTime = DateFormatter.toDateTimeISOString(eventData.startDate, eventData.startTime);
      const endTime = (eventData.endDate && eventData.endTime)
        ? DateFormatter.toDateTimeISOString(eventData.endDate, eventData.endTime) : undefined;
      this.props.createEventPost(
        {
          body,
          categoryId,
          endTime,
          googlePlaceId: address.googlePlaceId,
          images: this.reorderImages(savedImages, titleImageIndex).map(imageToString),
          linkPreviewId: linkPreviewParams && linkPreviewParams.id,
          startTime,
          title,
        },
        (label: TrackingLabel) => {
          postSubmitTracking(FEED_POST_TYPE_EVENT, label, this.props.tracking);
        },
      );
    } else {
      reportError('attempt to create event without startTime or startDate or googlePlaceId',
        { props: this.props, state: this.state });
    }
  }

  // reorder the images array to have the title image first
  private reorderImages(images: IImageObject[], titleImageIndex?: number): IImageObject[] {
    if (!!images.length && !!titleImageIndex && titleImageIndex > 0 && titleImageIndex < images.length) {
      return [images[titleImageIndex], ...images.filter((_val, i) => i !== titleImageIndex)];
    }

    return images;
  }

  private openFilePicker() {
    this.uploaderRef.current && this.uploaderRef.current.open();
  }
  private onSnapshotAdded(addedImages: string[]) {
    const { tracking: { tracker, trackingObj }, history, matchUrl } = this.props;
    this.setDraftedImages([[{ src: addedImages[0] }]]);
    tracker(trackingObj.ACTIONS.SNAPSHOT, trackingObj.LABELS.START);
    this.setPostType(FEED_POST_TYPE_SNAPSHOT_POST);
    history.push(createWritePath(matchUrl, FEED_POST_TYPE_SNAPSHOT_POST));
  }

  private fetchEventEditView() {
    const { prefilledEditURL } = this.props;
    if (prefilledEditURL) {
      api.event.edit(prefilledEditURL).then((editView: IEventEditView) => {
        const imageUrls = editView.attachments.map(attachmentToImage);
        const startTime = DateFormatter.asTime(editView.startTime);
        const endTime = editView.endTime && DateFormatter.asTime(editView.endTime);
        this.setState({
          body: editView.body,
          eventData: {
            category: editView.categories[0].id,
            endTime,
            startTime,
          },
          linkPreviewParams: editView.linkPreview,
          preFilledAddress: editView.address,
          title: editView.title,
        });

        // It's seperated from above to solve problem of safari browser. (invisible body text when duplicating)
        this.setState({
          savedImages: imageUrls,
        });

        if (imageUrls.length > 0) {
          imageUrls.forEach(async(imageUrl) => {
            imageUrl.src = await urlToDataURL(imageUrl.src);
          });
          this.setState({
            savedImages: imageUrls,
          });
        }
      });
    }
  }
}

export default PostCreate;
