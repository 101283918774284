import * as Response from 'src/api/interfaces/responses/location_shape';
import { IBoundingBox as IResponseBoundingBox } from 'src/api/interfaces/responses/shared';
import { IBoundingBox, ILocationShape } from 'src/interfaces/location';

export const $locationShape = (json: Response.Location): ILocationShape => ({
  boundingBox: $boundingBox(json.bounding_box),
  identifier: json.identifier,
  latitude: json.latitude,
  longitude: json.longitude,
  name: json.name,
  released: json.released,
  slug: json.slug,
  type: json.type,
});

export const $locationShapeResponse = ({ data }: Response.ILocationShapeResponse): ILocationShape => {
  if (data.type === 'cluster') {
    return {
      ...$locationShape(data),
      identifier: data.location_shapes[0].identifier,
    };
  }
  return $locationShape(data);
};

const $boundingBox = (json: IResponseBoundingBox): IBoundingBox => ({
  neLatitude: json.ne_latitude,
  neLongitude: json.ne_longitude,
  swLatitude: json.sw_latitude,
  swLongitude: json.sw_longitude,
});
