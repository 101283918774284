import config from 'frontend-configs/config.json';

type InputObject = Record<string, string | object>;

export const getConfigValue = <Output = string>(
  path: string,
  source: InputObject = config
): Output | undefined => {
  if (path === '') {
    return undefined;
  }

  const parts = path.split('.');

  if (parts.length === 1) {
    return source[parts[0]] as unknown as Output;
  } else {
    return getConfigValue(parts.slice(1).join('.'), source[parts[0]] as InputObject);
  }
};
