
// interfaces
import { ReactionName } from 'src/interfaces/reactions';

// helper
import { textResources } from 'src/lang/de';

export const translateReactions = (name: ReactionName): string => {
  const { haha, interesting, like, ohNo, wow, condolences } = textResources.reactions;
  switch (name) {
    case 'haha':
      return haha;
    case 'oh_no':
      return ohNo;
    case 'old_heart':
      return like;
    case 'like':
      return like;
    case 'interesting':
      return interesting;
    case 'wow':
      return wow;
    case 'candle':
      return condolences;
    default:
      return '';
  }
};
