// libs
import React from 'react';
import { CLASS_PREFIX } from 'src/constants';

// interfaces/constants
import { FRONTEND_EVENT_PATH, FRONTEND_EVENT_PARTICIPATING_PATH } from 'src/constants/urls';

// classes/components
import Icon from 'src/components/forms/icon/icon';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import { textResources } from 'src/lang/de';
import './my_events.scss';

const cls = CLASS_PREFIX + 'my-events';

const MyEvents: React.FC = () => {
  return (
    <NavigationItem
      className={cls}
      target='_self'
      url={`${FRONTEND_EVENT_PATH}/${FRONTEND_EVENT_PARTICIPATING_PATH}`}
    >
      <span className={cls + '--text'}>{textResources.eventfeed.myEvents}</span>
      <Icon name='star-filled' />
    </NavigationItem>
  );
};

export default MyEvents;
