// interfaces / constants
import { IAction } from 'src/actions/';
import {
  INotificationReducerPayload,
  IUnseenCountsReducerPayload,
} from 'src/api/decoders/notification';
import { NOTIFICATIONS_DEFAULT_PER_PAGE } from 'src/constants/notifications';

import api from 'src/api/';

export const NOTIFICATIONS_FETCH_NOTIFICATIONS = 'NOTIFICATIONS_FETCH_NOTIFICATIONS';
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_PENDING = 'NOTIFICATIONS_FETCH_NOTIFICATIONS_PENDING';
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_ERROR = 'NOTIFICATIONS_FETCH_NOTIFICATIONS_ERROR';
export const NOTIFICATIONS_FETCH_NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_FETCH_NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_GET_UNSEEN_COUNTS = 'NOTIFICATIONS_GET_UNSEEN_COUNTS';
export const NOTIFICATIONS_GET_UNSEEN_COUNTS_SUCCESS = 'NOTIFICATIONS_GET_UNSEEN_COUNTS_SUCCESS';
export const NOTIFICATIONS_MARK_READ = 'NOTIFICATIONS_MARK_READ';
export const NOTIFICATIONS_MARK_READ_SUCCESS = 'NOTIFICATIONS_MARK_READ_SUCCESS';
export const NOTIFICATIONS_MARK_SEEN = 'NOTIFICATIONS_MARK_SEEN';
export const NOTIFICATIONS_MARK_SEEN_SUCCESS = 'NOTIFICATIONS_MARK_SEEN_SUCCESS';

export interface IFetchNotificationsActionPending {
  type: typeof NOTIFICATIONS_FETCH_NOTIFICATIONS_PENDING;
}

export interface IFetchNotificationsActionError {
  type: typeof NOTIFICATIONS_FETCH_NOTIFICATIONS_ERROR;
}

export interface IFetchNotificationsActionSuccess {
  meta: {
    prepend?: boolean;
    profileIdentifier: string;
  };
  payload: INotificationReducerPayload;
  type: typeof NOTIFICATIONS_FETCH_NOTIFICATIONS_SUCCESS;
}

export interface IGetUnseenCountsActionSuccess {
  payload: IUnseenCountsReducerPayload;
  type: typeof NOTIFICATIONS_GET_UNSEEN_COUNTS_SUCCESS;
}

export interface IMarkNotificationReadActionSuccess {
  meta?: {
    notificationId: string;
    profileIdentifier: string;
  };
  type: typeof NOTIFICATIONS_MARK_READ_SUCCESS;
}

export interface IMarkNotificationsSeenActionSuccess {
  meta: {
    profileIdentifier: string;
    notificationIds: string[];
  };
  type: typeof NOTIFICATIONS_MARK_SEEN_SUCCESS;
}

export const fetchNotifications = (selectedProfileId: string, padding: number, page?: number): IAction => ({
  meta: {
    profileIdentifier: selectedProfileId,
  },
  payload: api.notification.fetch({ padding, page, perPage: NOTIFICATIONS_DEFAULT_PER_PAGE }),
  type: NOTIFICATIONS_FETCH_NOTIFICATIONS,
});

export const fetchNewNotifications = (selectedProfileId: string, count: number): IAction => ({
  meta: {
    prepend: true,
    profileIdentifier: selectedProfileId,
  },
  payload: api.notification.fetch({ page: 1, perPage: count }),
  type: NOTIFICATIONS_FETCH_NOTIFICATIONS,
});

export const getUnseenCounts = (): IAction => ({
  payload: api.notification.getUnseenCounts(),
  type: NOTIFICATIONS_GET_UNSEEN_COUNTS,
});

export const markNotificationsSeen = (notificationIds: string[], selectedProfileId: string): IAction => ({
  meta: {
    notificationIds,
    profileIdentifier: selectedProfileId,
  },
  payload: api.notification.setTrait({
    notificationIds,
    trait: 'seen',
  }),
  type: NOTIFICATIONS_MARK_SEEN,
});

export const markNotificationRead = (notificationId: string, selectedProfileId: string): IAction => ({
  meta: {
    notificationId,
    profileIdentifier: selectedProfileId,
  },
  payload: api.notification.setTrait({ notificationIds: [notificationId], trait: 'read' }),
  type: NOTIFICATIONS_MARK_READ,
});
