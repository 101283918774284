import React from 'react';

import MapMarker from 'src/components/map/marker/map_marker';
import { IMarker } from 'src/interfaces/marker';

interface IProps {
  markers: IMarker[];
}

const MapMarkerCollection: React.FC<IProps> = ({ markers }) => {
  return (
    <div>
      {markers.map(marker => {
        return (
          <MapMarker
            color={marker.color}
            key={marker.id}
            id={marker.id}
            image={marker.image}
            position={marker.position}
            url={marker.url}
          />);
      })}
    </div>
  );
};

export default MapMarkerCollection;
