// libs
import * as React from 'react';

// components
import ContentPlaceholder from 'src/components/content_placeholder/content_placeholder';

// helpers
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import './group_header_title.scss';

const cls = createBemBlock('group-header-title-placeholder');

const GroupHeaderTitle: React.FC = () => (
  <div className={cls()}>
    <ContentPlaceholder className={cls('title')} />
  </div>
);

export default GroupHeaderTitle;
