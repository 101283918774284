import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink from 'src/components/item_segment/base/item_segment_link';
import { IUserListActionConfirmation } from 'src/components/item_segment/layouts/profiles_list/model';
import SegmentRoundIcon from 'src/components/item_segment/parts/segment_round_icon/segment_round_icon';
import { getConfig } from 'src/components/item_segment/parts/segment_user_action_button/constants';
import { SegmentUserActionType } from 'src/components/item_segment/parts/segment_user_action_button/model';
import MessageBoxModal from 'src/components/message_box/message_box_with_modal';
import './segment_user_action_button.scss';

const cls = itemSegmentCls + 'user-action';

interface IProps {
  action: () => void;
  type: SegmentUserActionType;
  confirmation?: IUserListActionConfirmation;
}
const SegmentUserActionButton: FunctionComponent<IProps> = ({ action, type, confirmation }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const config = getConfig(type);

  const onClick = useCallback(() => {
    if (confirmation) {
      setShowConfirmationModal(true);
    } else {
      action();
    }
  }, [action, confirmation]);

  const messageBoxModalProps = useMemo(() => {
    if (confirmation) {
      return {
        ...confirmation,
        buttons: confirmation.buttons?.map(button => ({
          ...button,
          onClick: () => {
            button.onClick && button.onClick();
            setShowConfirmationModal(false);
          },
        })),
      };
    } else {
      return null;
    }
  }, [confirmation]);

  if (config) {
    return <>
      { showConfirmationModal &&
        messageBoxModalProps &&
        <MessageBoxModal {...messageBoxModalProps}>{messageBoxModalProps.children}</MessageBoxModal>
      }
      <div className={cls}>
        <ItemSegmentLink onClick={onClick}>
          <SegmentRoundIcon {...config} />
        </ItemSegmentLink>
      </div>
    </>;
  }
  return null;
};

export default SegmentUserActionButton;
