// libs
import * as React from 'react';

// interfaces / constants
import { FOLLOW, FOLLOWEE_POST, FOLLOWEE_EVENT } from 'src/constants/notifications';
import { IFollowNotification, FollowEventCodes } from 'src/interfaces/notification';

// classes / components
import Notification, { clsText, clsHighlightedText } from 'src/components/notifications/notification';

// helpers
import { textResources } from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';

// images
import * as overlayInactive from 'src/components/notifications/badges/following@2x.png';
import * as overlayActive from 'src/components/notifications/badges/following_active@2x.png';

const labels = textResources.notification;

export interface IProps extends IFollowNotification {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const getTranslation = (eventCode: FollowEventCodes, hasContexTeaser: boolean): string => {
  let translation;
  switch (eventCode) {
    case FOLLOWEE_EVENT:
      translation = labels.followeeEvent(hasContexTeaser);
      break;
    case FOLLOWEE_POST:
      translation = labels.followeePost(hasContexTeaser);
      break;
    case FOLLOW:
      translation = labels.newFollower;
      break;
  }
  // needs empty space between initiatorName and this text
  return ` ${translation}`;
};

const FollowNotification: React.FC<IProps> = ({
  createdAt,
  initiator,
  isRead,
  onClick,
  eventCode,
  contextTeaser,
}) => {
  return (
    <Notification
      avatarImage={initiator?.image}
      badgeImageNotRead={overlayActive}
      badgeImageRead={overlayInactive}
      footer={DateFormatter.asTimeAgo(createdAt)}
      initiatorName={initiator?.name}
      isRead={isRead}
      onClick={onClick}
    >
      <span className={clsText}>{getTranslation(eventCode, !!contextTeaser)}</span>
      {contextTeaser && <strong className={clsHighlightedText}>{`„${contextTeaser}“`}</strong>}
    </Notification>
  );
};

export default FollowNotification;
