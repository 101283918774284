// libs
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// interfaces / constants
import { ILocationShape, IPosition, IBoundingBox } from 'src/interfaces/location';
import { IRootState } from 'src/reducers/interface';

// components
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';

// helpers
import { changeAppLocation } from 'src/actions/app-state/app-state';
import {
  useRouteStateLocation,
} from 'src/containers/location/use_route_state_location_hook';
import { StoredLocationHelper } from 'src/utils/location_storage/location_storage';

interface IStateToProps {
  userLocationShape?: ILocationShape;
  appStateLocationShape?: ILocationShape;
  loggedIn: boolean;
  hasToken: boolean;
  boundingBox?: IBoundingBox;
  position?: IPosition;
}

const mapStateToProps = ({ user, appState }: IRootState): IStateToProps => ({
  appStateLocationShape: appState.locationShape,
  boundingBox: appState.boundingBox,
  hasToken: user.hasToken,
  loggedIn: user.loggedIn,
  position: appState.position,
  userLocationShape:
    user.profiles[user.selectedProfileId].address &&
      user.profiles[user.selectedProfileId].address!.locationShape,
});

const mapDispatchToProps = {
  changeLocation: changeAppLocation,
};

type IDispatchToProps = typeof mapDispatchToProps;

export type IProps = IStateToProps & IDispatchToProps & RouteComponentProps;

export const LocationHandler: React.FC<IProps> = ({
  location,
  position,
  boundingBox,
  appStateLocationShape,
  hasToken,
  userLocationShape,
  changeLocation,
  children,
}) => {
  // since StoredLocationHelper.getLastLocation() might be expensive and it should not
  // run on each render only once
  const restoredLocation = useMemo(() => StoredLocationHelper.getLastLocation(), []);
  const canRecoverLocation = hasToken || restoredLocation;

  // sets initial location on non-location-slug-routes
  useEffect(() => {
    if (appStateLocationShape || (hasToken && !userLocationShape)) {
      return;
    }

    const newLocationShape = userLocationShape || restoredLocation;

    if (newLocationShape) {
      changeLocation(
        newLocationShape,
      );
    }
  // only case we want to rerun the effect to not cause multiple `changeAppLocations`
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocationShape]);

  // handle Location changes via route-state
  useRouteStateLocation({
    routeLocation: location.state,
    stateLocation: { boundingBox, locationShape: appStateLocationShape, position },
  });

  if (canRecoverLocation && !appStateLocationShape) {
    return <LoadingSpinner shown />;
  }

  return (
    <>
      {children}
    </>);
};

export default withRouter(connect<IStateToProps, IDispatchToProps, RouteComponentProps>(
  mapStateToProps,
  mapDispatchToProps,
)(LocationHandler));
