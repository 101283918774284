// libs
import * as React from 'react';

// interfaces / constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';

// helpers
import { getConfigValue } from '../../../../utils/config/config';
import './segment_text_post_preview.scss';

interface IProps {
  title: string;
  inclusionReason: string;
  inclusionReasonType?: string;
}

const cls = itemSegmentCls + 'text-post-preview';

const InclusionReason: React.FC<Omit<IProps, 'title'>> = ({ inclusionReason, inclusionReasonType }) => {
  const showInclusionReason = getConfigValue<string[]>('featureFlags.showInclusionReason');
  if (showInclusionReason && inclusionReasonType) {
    if (showInclusionReason.includes(inclusionReasonType)) {
      return <p className={cls + '__inclusion-reason'}>{inclusionReason}</p>;
    } else {
      return null;
    }
  }
  return <p className={cls + '__inclusion-reason'}>{inclusionReason}</p>;
};

const SegmentTextPostPreview: React.FC<IProps> = ({ title, inclusionReason, inclusionReasonType }) =>
  (
    <div className={cls}>
      <InclusionReason inclusionReason={inclusionReason} inclusionReasonType={inclusionReasonType}/>
      <h3 className={cls + '__title'}>{title}</h3>
    </div>
  );

export default SegmentTextPostPreview;
