import React, { FunctionComponent } from 'react';
import { TopLevelRoute } from '../../../containers/routes';
import NotFoundPage from '../../../pages/not_found';
import { FeatureFlag } from '../feature_flag';
import { navigationFeatureFlags } from '../navigation_feature_flag/constants';
import { NavigationFeatureFlags } from '../navigation_feature_flag/model';

interface IProps {
  flag?: NavigationFeatureFlags;
}
export const RouteFeatureFlag: FunctionComponent<IProps> = ({ flag, children }) =>
  <FeatureFlag
    active={flag ? navigationFeatureFlags.get(flag) : true}
    fallback={<TopLevelRoute component={NotFoundPage} />}>
    {children}
  </FeatureFlag>;
