import { IAttachment as ResponseAttachment } from 'src/api/interfaces/responses/attachment';
import { IAttachment } from 'src/interfaces/attachment';

export const $attachments = (json: ResponseAttachment[]): IAttachment[] => json.map($attachment);

export const $attachment = (json: ResponseAttachment): IAttachment => ({
  createdAt: json.created_at,
  id: json.identifier,
  imageUrls: {
    original: json.image_urls.original,
    squareMedium: json.image_urls.square_medium,
    squareSmall: json.image_urls.square_small,
    wideMedium: json.image_urls.wide_medium,
  },
});
