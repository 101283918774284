// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// classes
import Button, { BUTTON_TYPE_ROUNDMICRO } from 'src/components/forms/button/button';
import Thumbnail, { ThumbnailSizeType } from 'src/components/thumbnail/thumbnail';

// helpers
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_DANGER } from 'src/utils/color';
import Icon from '../forms/icon/icon';
import './uploaded_image.scss';

const cls = CLASS_PREFIX + 'uploaded_image';
const titlePicture = textResources.screenReaderText.titlePicture;

interface IProps {
  onDelete?: (index: number) => void;
  onEdit?: (index: number) => void;
  index: number;
  isTitleImage?: boolean;
  size?: ThumbnailSizeType;
  url: string;
}

export default class UploadedImage extends React.PureComponent<IProps, {}> {
  constructor(props: IProps) {
    super(props);
    this.deleteImage = this.deleteImage.bind(this);
    this.editImage = this.editImage.bind(this);
  }

  public render() {
    const { isTitleImage, onEdit, size, url } = this.props;
    return (
      <div className={`${cls}__container`}>
        <Thumbnail
          imageUrl={url}
          size={size}
          className={`${cls}__thumbnail`}
        >
          {/* Disable edit image for updated post until titleImage functionality is fixed #4249 (!4796) */}
          {onEdit &&
            <button onClick={this.editImage} className={`${cls}__edit`}>
              <Icon aria-hidden='true' name={'pencil-filled'} />
              {textResources.shared.edit}
            </button>
          }
          {isTitleImage &&
            <div className={`${cls}__title-image`}>
              <Icon aria-hidden='true' name={'flag-filled'} description={titlePicture} />
            </div>
          }
        </Thumbnail>
        <div className={`${cls}__delete`}>
          <Button
            onClick={this.deleteImage}
            color={COLOR_TYPE_DANGER}
            icon={'thin-x'}
            type={'button'}
            variant={BUTTON_TYPE_ROUNDMICRO}
            title={textResources.postCreate.mediaDeleteButton}
          />
        </div>
      </div>
    );
  }

  private deleteImage() {
    const { index, onDelete } = this.props;
    onDelete && onDelete(index);
  }

  private editImage() {
    const { index, onEdit } = this.props;
    if (onEdit) {
      onEdit(index);
    }
  }
}
