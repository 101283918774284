import {
  SegmentUserActionProps,
  SegmentUserActionType,
} from 'src/components/item_segment/parts/segment_user_action_button/model';

const SegmentUserActionConfig =
  new Map<SegmentUserActionType, Pick<SegmentUserActionProps, 'color' | 'isInverted' | 'name'>>([
    [SegmentUserActionType.ACCEPT, {
      color: 'primary',
      isInverted: true,
      name: 'check',
    }],
    [SegmentUserActionType.REJECT, {
      color: 'gray-tint-1',
      isInverted: false,
      name: 'thin-x',
    }],
    [SegmentUserActionType.REMOVE, {
      color: 'gray-tint-1',
      isInverted: false,
      name: 'delete',
    }],
  ]);

const SegmentUserActionCommonConfig: Pick<SegmentUserActionProps, 'customSize' | 'isSmall' | 'noHover'> = {
  customSize: 16,
  isSmall: true,
  noHover: true,
};

export const getConfig = (type: SegmentUserActionType): SegmentUserActionProps | null => {
  const config = SegmentUserActionConfig.get(type);
  if (config) {
    return {
      ...config,
      ...SegmentUserActionCommonConfig,
    };
  } else {
    return null;
  }
};
