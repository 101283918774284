// libs
import React, { SyntheticEvent, useCallback, useState, useRef } from 'react';
// interfaces / constants
// components
import InputField from 'src/components/forms/input_field/input_field';
// helpers
import { textResources } from 'src/lang/de';

interface IProps {
  onSubmit: (val: string) => void;
  defaultValue?: string;
}

const SearchField: React.FC<IProps> = ({ onSubmit, defaultValue }) => {
  const inputRef = useRef<InputField>(null);
  const [value, setValue] = useState(defaultValue || '');

  const onChange = useCallback((val) => {
    setValue(val);
  }, [setValue]);

  const onFormSubmit = useCallback((e: SyntheticEvent) => {
    e.preventDefault();

    onSubmit(value);
    inputRef.current && inputRef.current.blurInput();
  }, [onSubmit, value]);

  const onRightIconClick = useCallback((e: SyntheticEvent) => {
    // to prevent the input of getting refocused after icon click
    e.stopPropagation();
    setValue('');
    onSubmit('');
  }, [setValue, onSubmit]);

  return (
    <form onSubmit={onFormSubmit}>
      <InputField
        value={value}
        type={'search'}
        name={'search'}
        label={textResources.profileSearch.searchAndDiscover}
        iconRight={value ? 'x-circle' : 'search'}
        onChange={onChange}
        onRightIconClick={onRightIconClick}
        ref={inputRef}
      />
    </form>
  );
};

SearchField.displayName = 'SearchField';

export default SearchField;
