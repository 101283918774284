import { useParams } from 'react-router-dom';

// helpers
import { getProfileDataFromUrlParams, IProfileParams, IGroupsParams } from 'src/utils/url/url';

export const useGetProfileIdWithTypeFromUrlParams = () => getProfileDataFromUrlParams(useParams<IProfileParams>());

export const useGetGroupIdFromUrlParams = () => {
  const id: string = useParams<IGroupsParams>().id;
  return id;
};
