import {
  ICategorizedEventfeedRequestParams,
  IEventfeedRequestParams,
  INewsfeedRequestParams,
  IParticipatingFeedRequestParams,
  IGroupFeedRequestParams,
} from 'src/actions/feed/feed_interfaces';
import {
  IAddressLookupParams,
  IEventUpdateData,
  IFeedbackCreate,
  IFollowable,
  IFollowByFollowableParams,
  IFollowByProfilesParams,
  IFollower,
  IFollowSettings,
  IFollowUpdateNotificationsByProfilesParams,
  IGeoLookupParams,
  IIntermediateProfileUpdateParam,
  IGetProfilesParams,
  ILookupParam,
  INotificationUpdateParams,
  INotificationsSetTraitParams,
  INotificationsParams,
  INotificationsUnseenCountsParams,
  IOAuth2LoginParams,
  IOAuth2LogoutParams,
  IOAuth2RefreshParams,
  IPaginationParams,
  IParticipationUpdateParam,
  IPasswordChangeParams,
  IPasswordResetConfirmParams,
  IPasswordResetParams,
  IPlaceLookupParam,
  IPostGetParams,
  IPostUpdateData,
  IPrivateProfileUpdateParam,
  IRegistrationFixedParams,
  IRelatedContentParams,
  IShareCreate,
  ISharedPublicProfileUpdateParam,
  ISharedProfileUpdateParam,
  IValidateAddressParam,
  IGetCategorizedProfilesParams,
  IGetFollowListParams,
} from 'src/api/';
import * as IRequests from 'src/api/interfaces/requests';
import { EVENT_FEED_DEFAULT_PER_CATEGORY, FEED_DEFAULT_PER_PAGE, FEED_POST_TYPE_SNAPSHOT } from 'src/constants/feed';
import { PROFILES_LIST_PER_PAGE } from 'src/constants/profile_list';
import { REDIRECT_EMAIL_CHANGE } from 'src/constants/urls';
import { IWindowJsEnv } from 'src/interfaces/';
import { IFlagCreateData } from 'src/interfaces/flag';
import { IBoundingBox, IPosition } from 'src/interfaces/location';
import { IEventCreateData, IPostCreateData, IPostSurveyCreateData, ISnapshotCreateData } from 'src/interfaces/posts';
import { ReactionName } from 'src/interfaces/reactions';

import { stringToImage, toAttachmentsUpdate } from 'src/utils/attachment/attachment';
import { idFromGlobalFriendlyIdentifier } from 'src/utils/profile';
import { addSchemaIfNotPresent } from 'src/utils/url/url';

const _window = window as unknown as IWindowJsEnv;

export const $pagination = (params: IPaginationParams): IRequests.IPaginationParams => ({
  page: params.page ? params.page.toString() : undefined,
  per_page: params.perPage ? params.perPage.toString() : undefined,
});

export const $feedPagination = ({
  page,
  perPage = FEED_DEFAULT_PER_PAGE,
}: IPaginationParams): IRequests.IPaginationParams => $pagination({ page, perPage });

export const $profileListPagination = ({
  page,
  perPage = PROFILES_LIST_PER_PAGE,
}: IPaginationParams): IRequests.IPaginationParams => $pagination({ page, perPage });

export const $position = (p: IPosition): string => `${p.latitude},${p.longitude}`;

export const $boundingBox = (bb: IBoundingBox): IRequests.IBoundingBox => ({
  ne_latitude: bb.neLatitude,
  ne_longitude: bb.neLongitude,
  sw_latitude: bb.swLatitude,
  sw_longitude: bb.swLongitude,
});

export const $addressLookup = (param: IAddressLookupParams): IRequests.IAddressLookup => {
  if ((param as IGeoLookupParams).position) {
    return $geoLookup((param as IGeoLookupParams));
  } else {
    return $placeLookup((param as IPlaceLookupParam));
  }
};

const $geoLookup = (param: IGeoLookupParams): IRequests.IGeoLookUp => ({
  coordinates: $position(param.position),
  ...$lookUp(param),
});

const $placeLookup = (param: IPlaceLookupParam): IRequests.IPlaceLookUp => ({
  google_place_id: param.placeID,
  ...$lookUp(param),
});

export const $lookUp = (param: ILookupParam): IRequests.ILookUp => ({
  only_released: param.onlyReleased,
  street_required: param.streetRequired,
});

export const $url = (url: string): IRequests.IUrl => ({
  url: encodeURIComponent(addSchemaIfNotPresent(url)),
});

export const $categories = (categorizable: IRequests.ICategoryType): IRequests.ICategory => ({
  categorizable,
});

export const $conversationCreate =
  (profileType: string, profileId: string): IRequests.IConversationCreateParams => ({
    participants: [
      `${profileType}#${profileId}`,
    ],
  });

export const $messageCreate =
  (body?: string, images?: string[], linkPreviewUrl?: string): IRequests.IMessageCreateParams => ({
    message: {
      attachments_attributes: buildAttachments(images),
      body,
      link_preview_url: linkPreviewUrl,
    },
  });

export const $conversations = (params: IPaginationParams): IRequests.IPaginationParams =>
  $pagination(params);

export const $conversationMessage = (params: IPaginationParams): IRequests.IPaginationParams =>
  $pagination(params);

export const $newsFeed = (params: INewsfeedRequestParams): IRequests.INewsfeedParams => ({
  ...$feedPagination(params),
  bbox: params.boundingBox && $boundingBox(params.boundingBox),
  contexts: 'groups',
});

const eventfeedFilters = (params: IEventfeedRequestParams): IRequests.IEventfeedFilters => ({
  category: params.categories,
});

export const $eventCategorizedFeed =
  (params: ICategorizedEventfeedRequestParams): IRequests.IEventCategorizedFeedParams => ({
    bbox: params.boundingBox && $boundingBox(params.boundingBox),
    per_category: EVENT_FEED_DEFAULT_PER_CATEGORY,
    ranges: params.ranges,
  });

export const $eventFeed = (params: IEventfeedRequestParams): IRequests.IEventfeedParams => ({
  ...$feedPagination(params),
  bbox: params.boundingBox && $boundingBox(params.boundingBox),
  filters: eventfeedFilters(params),
  ranges: params.ranges,
});

export const $participatingFeed = (params: IParticipatingFeedRequestParams): IRequests.IParticipatingFeedParams =>
  $feedPagination(params);

export const $reactionUpdate = (bookmarkType: ReactionName): IRequests.IBookmarkUpdate => ({
  bookmark: {
    bookmark_type: bookmarkType,
  },
});

export const $commentCreate = (message: string, images?: string[], oglinkId?: string): IRequests.ICommentCreate => {
  return {
    comment: {
      attachments_attributes: buildAttachments(images),
      message,
      oglink_id: oglinkId,
    },
  };
};

export const $commentUpdate =
(message: string, addedImages?: string[], deletedImagesIds?: string[], oglinkId?: string): IRequests.ICommentUpdate => {
  return {
    comment: {
      attachments_attributes:
        toAttachmentsUpdate(addedImages && addedImages.map(stringToImage), deletedImagesIds),
      message,
      oglink_id: oglinkId || '',
    },
  };
};

export const $comments = () => ({
  per_page: 1000,
});

export const $postCreate = ({
  googlePlaceId,
  body,
  linkPreviewId,
  images,
  contentItemType,
  title,
  commentable,
  survey,
  surveyDetails,
  expires_at,
}: IPostSurveyCreateData | IPostCreateData | ISnapshotCreateData):
IRequests.IPostCreate | IRequests.IPostSnapshotCreate => {
  const attachments = images ? images.map((image: string) => ({ file: image })) : undefined;

  return {
    post: {
      address_attributes: googlePlaceId === undefined ? undefined : { google_place_id: googlePlaceId },
      attachments_attributes: attachments,
      body,
      commentable,
      content_item_type: contentItemType === FEED_POST_TYPE_SNAPSHOT ? contentItemType : 'default',
      expires_at,
      oglink_id: linkPreviewId,
      survey,
      surveyDetails,
      title,
    },
  };
};

export const $postUpdate =
  ({
    body,
    title,
    addedImages,
    deletedImagesIds,
    linkPreviewId,
    changedImages,
  }: IPostUpdateData): IRequests.IPostUpdate => {
    return {
      post: {
        attachments_attributes: toAttachmentsUpdate(addedImages, deletedImagesIds, changedImages),
        body,
        oglink_id: linkPreviewId,
        title,
      },
    };
  };

export const $eventUpdate = (params: IEventUpdateData): IRequests.IEventUpdate => {
  const { categoryId, title, body, addedImages, deletedImagesIds,
    startTime, endTime, linkPreviewId, changedImages } = params;
  return {
    event: {
      attachments_attributes: toAttachmentsUpdate(addedImages, deletedImagesIds, changedImages),
      body,
      category_ids: categoryId ? [categoryId] : undefined,
      end_time: endTime,
      oglink_id: linkPreviewId,
      start_time: startTime,
      title,
    },
  };
};

export const $eventCreate = (params: IEventCreateData): IRequests.IEventCreate => {
  const { body, googlePlaceId, categoryId, title, images, startTime, endTime, linkPreviewId } = params;
  const attachments = images ? images.map((image: string) => ({ file: image })) : undefined;

  return {
    event: {
      address_attributes: {
        google_place_id: googlePlaceId,
      },
      attachments_attributes: attachments,
      body,
      category_ids: [categoryId],
      end_time: endTime,
      oglink_id: linkPreviewId,
      start_time: startTime,
      title,
    },
  };
};

export const $relatedContent = (params: IRelatedContentParams): IRequests.IRelatedContent => ({
  ...$pagination(params),
  author_types: params.authorTypes && params.authorTypes.join(','),
});

export const $flagCreate = ({ message, reason }: IFlagCreateData): IRequests.IFlagCreate => ({
  flag: {
    message,
    reason,
  },
});

export const $blacklistGet = (params: IPaginationParams): IRequests.IPaginationParams =>
  $pagination(params);

export const $blacklistUpdate = (): IRequests.IBlacklist => ({});

export const $graylistGet = (params: IPaginationParams): IRequests.IPaginationParams =>
  $pagination(params);

export const $graylistUpdate = (expiresOn?: Date): IRequests.IGraylist => ({
  graylist_entry: {
    expires_on: expiresOn,
  },
});

export const $postGet = (params: IPostGetParams): IRequests.IPostGet => ({
  pt: params.previewToken,
});

export const $validateAddress = (param: IValidateAddressParam): IRequests.IValidateAddress => {
  if (typeof param.data === 'string') {
    return {
      google_place_id: param.data,
    };
  }

  return {
    coordinates: `${param.data.latitude},${param.data.longitude}`,
  };
};

// Helper to build the 'attachments_attributes'-param.
const buildAttachments = (images?: string[]): IRequests.IAttachmentAttribute[] | undefined => {
  return images ? images.map((image: string) => ({ file: image })) : undefined;
};

export const $oauth2Login = (params: IOAuth2LoginParams): IRequests.IOAuth2ResourceOwnerPasswordCredentials => ({
  client_id: _window.js_env.oauth_client_id,
  grant_type: 'password',
  password: params.password,
  username: params.email,
});

export const $oauth2Logout = (params: IOAuth2LogoutParams): IRequests.IOAuth2Revocation => ({
  client_id: _window.js_env.oauth_client_id,
  token: params.token,
});

export const $oauth2Refresh = (params: IOAuth2RefreshParams): IRequests.IOAuth2Refresh => ({
  client_id: _window.js_env.oauth_client_id,
  grant_type: 'refresh_token',
  refresh_token: params.refreshToken,
});

export const $registration = (params: IRegistrationFixedParams): IRequests.IRegistration => ({
  age_check_accepted: params.ageCheckAccepted,
  email: params.email,
  google_place_id: params.googlePlaceId,
  password: params.password,
  pre_registration_token: params.preRegistrationToken,
  privacy_conditions_accepted: params.privacyConditionsAccepted,
  redirect_url: params.redirectUrl,
  terms_of_use_accepted: params.termsOfUseAccepted,
});

export const $resendConfirmationEmail = (redirectUrl: string): IRequests.IResendConfirmationEmail => ({
  redirect_url: redirectUrl,
});

export const $updateEmail = (email: string, password: string, redirectUrl?: string): IRequests.IUpdateEmail => ({
  email,
  password,
  redirect_url: redirectUrl || REDIRECT_EMAIL_CHANGE,
});

export const $passwordChange = (params: IPasswordChangeParams): IRequests.IPasswordChange => ({
  current_password: params.currentPassword,
  password: params.password,
  password_confirmation: params.passwordConfirmation,
});

export const $passwordReset = (params: IPasswordResetParams): IRequests.IPasswordReset => ({
  email: params.email,
  redirect_url: params.redirectUrl,
});

export const $passwordResetConfirm = (params: IPasswordResetConfirmParams): IRequests.IPasswordResetConfirm => ({
  password: params.password,
  password_confirmation: params.passwordConfirmation,
  reset_password_token: params.resetPasswordToken,
});

export const $intermediateProfileUpdate =
  (params: IIntermediateProfileUpdateParam): IRequests.IIntermediateProfileUpdate => ({
    intermediate_profile: {
      ...$sharedUpdateParams(params),
      category_ids: params.categoryIds,
      contact_attributes: {
        first_name: params.firstName,
        last_name: params.lastName,
        phone: params.phone,
      },
      name: params.name,
      settings: {
        update_by_daily_email: params.allowReceiveDailyEmails,
      },
      site_notice: params.siteNotice,
      target_model: params.targetModel,
      website: params.website,
    },
  });

export const $notificationUpdateParams = (params: INotificationUpdateParams): IRequests.INotificationSettings => {
  const notifications = params.notifications || {};

  return Object.keys(notifications).reduce<IRequests.INotificationSettings>((o, code) => {
    const notificationMedium = notifications[code];

    if (!notificationMedium) {
      return o;
    }

    Object.keys(notificationMedium).forEach(medium => {
      const notificationSetting = notificationMedium[medium];
      if (!notificationSetting) {
        return;
      }

      o[notificationSetting.attribute] = notificationSetting.value;
    });

    return o;
  }, {});
};

const $sharedUpdateParams = (params: ISharedProfileUpdateParam): IRequests.ISharedAttributes => ({
  address_attributes: {
    google_place_id: params.googlePlaceId,
  },
  avatar: params.avatar,
  description: params.description,
});

export const $privateProfileUpdate = (params: IPrivateProfileUpdateParam): IRequests.IPrivateProfileUpdate => ({
  private_profile: {
    ...$sharedUpdateParams(params),
    contact_attributes: {
      first_name: params.firstName,
      last_name: params.lastName,
    },
    day_of_birth: params.dayOfBirth,
    notifications: $notificationUpdateParams(params),
    receive_daily_update_email: params.receiveDailyUpdateEmail,
  },
});

const $publicProfile = (params: ISharedPublicProfileUpdateParam) => ({
  ...$sharedUpdateParams(params),
  category_ids: params.categoryIds,
  contact_attributes: {
    first_name: params.firstName,
    last_name: params.lastName,
    phone: params.phone,
  },
  name: params.name,
  site_notice: params.siteNotice,
  website: params.website,
});

export const $blogProfileUpdate = (params: ISharedPublicProfileUpdateParam): IRequests.IBlogProfileUpdate => ({
  blog_profile: {
    ...$publicProfile(params),
  },
});

export const $pressProfileUpdate = (params: ISharedPublicProfileUpdateParam): IRequests.IPressProfileUpdate => ({
  press_profile: {
    ...$publicProfile(params),
  },
});
export const $authorityProfileUpdate =
  (params: ISharedPublicProfileUpdateParam): IRequests.IAuthorityProfileUpdate => ({
    authority_profile: {
      ...$publicProfile(params),
    },
  });

export const $profileVerify = (token: string) => ({ profile: { token } });

export const $profileUpdateReceiveNewsletter = (receiveNewsletter: boolean) =>
  ({ profile: { receive_newsletter: receiveNewsletter } });

export const $updateParticipations = (params: IParticipationUpdateParam) => ({
  participation: {
    notifications: $notificationUpdateParams(params),
  },
});

export const $feedback = (params: IFeedbackCreate): IRequests.IFeedbackCreate => ({
  email: params.email,
  message: params.message,
});

export const $share = (params: IShareCreate): IRequests.IShareCreate => ({
  emails: params.emails,
  personal_message: params.personalMessage,
  sender: params.sender,
});

// NB: we add some event codes here which are NYI in the frontend
const defaultExcludedEventCodes: IRequests.NotificationEventCode[] = ['view_count'];

const extendExcludedEventCodes = (codes?: IRequests.NotificationEventCode[]): IRequests.NotificationEventCode[] =>
  (codes || []).concat(defaultExcludedEventCodes);

export const $notifications = (params: INotificationsParams): IRequests.INotificationsParams => ({
  ...$pagination(params),
  excluded_event_codes: extendExcludedEventCodes(params.withoutEventCodes),
  padding: params.padding,
});

export const $notificationsSetTrait =
  ({ trait, notificationIds }: INotificationsSetTraitParams): IRequests.INotificationsSetTraitParams => ({
    notification_ids: notificationIds,
    trait,
  });

export const $notificationsUnseenCounts =
  (params: INotificationsUnseenCountsParams): IRequests.INotificationsUnseenCountsParams => ({
    trait: 'unseen',
    without_event_codes: extendExcludedEventCodes(params.withoutEventCodes),
  });

export const $profiles = (params: IGetProfilesParams): IRequests.IProfilesGet => ({
  ...$profileListPagination(params),
  bbox: params.boundingBox && $boundingBox(params.boundingBox),
  filters: {
    item_type: params.authorType ? [params.authorType] : undefined,
  },
  query: params.query,
  order_by: params.orderBy,
  order_direction: params.orderDirection || 'asc',
});

export const $categorizedProfiles = (params: IGetCategorizedProfilesParams): IRequests.ICategorizedProfilesGet => ({
  ...$pagination(params),
  bbox: params.boundingBox && $boundingBox(params.boundingBox),
  query: params.query,
  per_type: params.perType,
  order_by: 'created_at',
  order_direction: 'desc',
});

export const $eventParticipationList = (params: IPaginationParams) => $pagination(params);

export const $groups = (params: IPaginationParams): IRequests.IGroups =>
  $pagination(params);

export const $groupSettings = (enabled: boolean) => ({
  notification_settings: enabled,
});

export const $myGroups = (params: IPaginationParams): IRequests.IMyGroups =>
  $pagination(params);

export const $groupMembers = (params: IPaginationParams): IRequests.IGroupMembers => ({
  ...$pagination(params),
});

export const $groupFeed = (params: IGroupFeedRequestParams): IRequests.IGroupFeedParams =>
  $feedPagination(params);

const followable = (params: IFollowable): IRequests.IFollowable => ({
  followable_id: idFromGlobalFriendlyIdentifier(params.followableId),
  followable_type: params.followableType,
});

const follower = ({ followerType, followerId }: Partial<IFollower>): Partial<IRequests.IFollower> => ({
  follower_id: followerId && idFromGlobalFriendlyIdentifier(followerId),
  follower_type: followerType,
});

const followSettings = (params: IFollowSettings): IRequests.IFollowSettings => ({
  notification: params.notification,
});

export const $followByFollowable = (
  params: IFollowByFollowableParams,
): IRequests.IFollowCreateByFollowableParams => ({
  follow: {
    ...followable(params),
    ...followSettings(params),
  },
});

export const $followByProfiles = (
  params: IFollowByProfilesParams,
): IRequests.IFollowCreateByProfilesParams => ({
  follow: {
    ...followable(params),
    ...follower(params),
    ...followSettings(params),
  },
});

export const $unFollowByProfiles = (
  params: IFollowByProfilesParams,
): IRequests.IFollowDestroyByProfilesParams => ({
  follow: {
    ...followable(params),
    ...follower(params),
  },
});

export const $followUpdateNotificationsByProfiles = (
  params: IFollowUpdateNotificationsByProfilesParams,
): IRequests.IFollowUpdateByProfilesParams => ({
  follow: {
    ...followable(params),
    ...follower(params),
    ...followSettings(params),
  },
});

export const $followers = (params: IGetFollowListParams): IRequests.IGetFollowListParams => ({
  ...$profileListPagination(params),
  order_by: params.orderBy,
  order_direction: params.orderDirection,
});

export const $addTag = (id: string): IRequests.ICreateTag => ({
  tagging: { tag_id: id },
});
