import { $profile } from 'src/api/decoders/profile';
import * as Response from 'src/api/interfaces/responses/reaction';
import { IProfilesListCategory } from 'src/interfaces/profiles_list';
import { IReaction, IReactions } from 'src/interfaces/reactions';

export const $reactionUpdate = (json: Response.IReactionUpdateResponse): IReactions => $reactions(json.data);

export const $reactions = (json: Response.IReactions): IReactions => ({
  lastReactedProfileName: json.last_bookmarker_name || undefined,
  meta: {
    urls: {
      update: json.meta.urls.update,
    },
  },
  reactions: json.data.map($reaction),
});

export const $reaction = (json: Response.IReaction): IReaction => ({
  name: json.name,
  number: json.count,
  own: json.own,
});

export const $reactedProfiles = (json: Response.IBookmarks): IProfilesListCategory => {
  const profiles = json.data.map(({ bookmarker }) => $profile(bookmarker));
  return {
    groups: [{ ids: profiles.map(({ id }) => id) }],
    profiles,
  };
};
