import { Id, IIdentifiable } from 'src/interfaces/';
import { IPost, IPostResponse } from 'src/interfaces/posts';
import { IProfile } from 'src/interfaces/profile';
import { IReactionList } from 'src/interfaces/reactions';

const normalizer = <T extends IIdentifiable>(acc: { ids: Id[]; map: Record<Id, T> }, item: T) => {
  acc.map[item.id] = item;
  acc.ids.push(item.id);
  return acc;
};

interface INormalizedFeed {
  ids: Id[];
  map: Record<Id, IPost>;
  reactionsList: IReactionList;
  authors: IProfile[];
}

export const normalizeFeed = (items: IPostResponse[]): INormalizedFeed =>
  items.reduce((acc: INormalizedFeed, item) => {
    normalizer(acc, item.post);
    acc.reactionsList[item.post.id] = item.reactions;
    const author = item.profile;
    acc.authors.push(author);
    return acc;
  }, { authors: [], ids: [], map: {}, reactionsList: {} });
