// libs
import classNames from 'classnames';
import React from 'react';

// constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import { THUMBNAIL_SIZE_TYPE_MEDIUM } from 'src/components/thumbnail/thumbnail';

// helpers
import './segment_image.scss';

export interface IProps {
  src: string;
  alt: string;
  width?: typeof THUMBNAIL_SIZE_TYPE_MEDIUM;
}

const cls = itemSegmentCls + 'image';

const SegmentImage: React.FC<IProps> = ({ alt, src, width }) => {
  const classes = {
    [cls]: true,
    [cls + `--${THUMBNAIL_SIZE_TYPE_MEDIUM}`]: width === THUMBNAIL_SIZE_TYPE_MEDIUM,
  };
  return (
    <img className={classNames(classes)} src={src} alt={alt} />
  );
};

export default SegmentImage;
