// lib
import classNames from 'classnames';
import { throttle } from 'lodash';
import * as React from 'react';

// components
import HeaderMain from 'src/components/header/main/header_main';
import HeaderNavigation, { ActivableNavigationItems }
  from 'src/components/header/navigation/header_navigation';

// constants
import { CLASS_PREFIX } from 'src/constants/';
import { IPermissions } from 'src/interfaces/user';

import './header.scss';

const cls: string = CLASS_PREFIX + 'header';
const clsAppSubmenu: string = cls + '__subheader';
const MINIMUM_SCROLL = window.innerHeight * 0.5;

interface IProps {
  activeNavigationItem?: ActivableNavigationItems;
  enableChatToggler?: boolean;
  enableMapToggler: boolean;
  hideMap: () => void;
  isMapShown: boolean;
  loggedIn: boolean;
  loggedInUserEmail?: string;
  pageTitle?: JSX.Element;
  selectedProfilePermissions: IPermissions;
  selectedProfileUnreadNotificationsCount: number;
  showLogInModal: () => void;
  showRegistrationModal?: () => void;
  subHeader?: JSX.Element;
}

interface IState {
  hide: boolean;
}

export default class Header extends React.PureComponent<IProps, IState> {
  private lastY: number;

  constructor(props: IProps) {
    super(props);
    this.handleOnBodyScroll = throttle(this.handleOnBodyScroll.bind(this), 250);
    this.state = {
      hide: false,
    };
  }

  public componentDidMount() {
    window.addEventListener('scroll', this.handleOnBodyScroll);
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.handleOnBodyScroll);
  }

  public render() {
    const {
      activeNavigationItem,
      enableChatToggler,
      enableMapToggler,
      hideMap,
      isMapShown,
      loggedIn,
      loggedInUserEmail,
      pageTitle,
      selectedProfilePermissions,
      selectedProfileUnreadNotificationsCount,
      showLogInModal,
      showRegistrationModal,
      subHeader,
    } = this.props;
    const { hide } = this.state;
    const clsHeader = classNames({
      [cls]: true,
      [`${cls}--hide`]: hide,
      [`${cls}--with-subheader`]: subHeader !== undefined,
    });

    return (
      <>
        <header className={clsHeader}>
          <HeaderMain
            enableChatToggler={!!enableChatToggler}
            enableMapToggler={enableMapToggler}
            loggedIn={loggedIn}
            loggedInUserEmail={loggedInUserEmail}
            pageTitle={pageTitle}
            showLogInModal={showLogInModal}
            showRegistrationModal={showRegistrationModal}
          />
          <HeaderNavigation
            activeNavigationItem={activeNavigationItem}
            notificationsCount={selectedProfileUnreadNotificationsCount}
            onClick={isMapShown ? hideMap : undefined}
            selectedProfilePermission={selectedProfilePermissions}
            withSubheader={!!subHeader}
          />
        </header>
        {subHeader &&
        <div className={clsAppSubmenu}>
          {subHeader}
        </div>
        }
      </>
    );
  }

  private handleOnBodyScroll() {
    const Y = window.pageYOffset || window.scrollY;

    this.setState({
      hide: Y > MINIMUM_SCROLL && this.lastY < Y,
    });
    this.lastY = Y;
  }
}
