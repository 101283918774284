// libs
import React from 'react';
import { useLocation } from 'react-router';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants';
import { FRONTEND_CHAT_PATH } from 'src/constants/urls';
import { IChatTogglerProps } from 'src/containers/topic_chat/chat_toggler';
import { COLOR_TYPE_GRAY_TINT_6, COLOR_TYPE_PRIMARY } from 'src/utils/color';

// components
import Badge from 'src/components/badge/badge';
import Icon from 'src/components/forms/icon/icon';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import { useChatTopicTargetsShape } from 'src/components/chat_page/utils';
import { textResources } from 'src/lang/de';
import initTracker, { TOPIC_CHAT } from 'src/utils/reporting/events_tracking/events_tracking';
import './chat_toggler.scss';

const cls = CLASS_PREFIX + 'chat-toggler';
const labels = textResources.screenReaderText;

const tracker = initTracker(TOPIC_CHAT);

const ChatToggler: React.FC<IChatTogglerProps> = ({ onlineUsersCount }) => {
  const chatTopicForCurrentShape = useChatTopicTargetsShape();

  const { pathname } = useLocation();

  const currentChatTopicUrl = chatTopicForCurrentShape
    ? `${FRONTEND_CHAT_PATH}/${chatTopicForCurrentShape.name}` : undefined;

  const isActive = pathname === currentChatTopicUrl;
  const color = isActive
    ? COLOR_TYPE_PRIMARY
    : COLOR_TYPE_GRAY_TINT_6;
  const description = isActive ? labels.closeChat : labels.openChat;

  const onClick = () => {
    tracker(
      TOPIC_CHAT.ACTIONS.TOGGLE,
      isActive ? TOPIC_CHAT.LABELS.FINISH : TOPIC_CHAT.LABELS.START,
    );
  };

  return (
    <NavigationItem
      url={currentChatTopicUrl}
      className={cls + '__button'}
      onClick={onClick}
    >
      <Icon
        className={cls}
        name='neighbor-chat'
        size={24}
        color={color}
        aria-label={'chat'}
        description={description}
      />
      {!!onlineUsersCount &&
        <div className={cls + '__badge'}>
          <Badge size='medium' value={onlineUsersCount} type='message' />
        </div>
      }
    </NavigationItem>
  );
};

export default ChatToggler;
