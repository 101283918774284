import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { CLASS_PREFIX } from '../../../constants';
import { useMessageListener } from '../../../hooks_shared/use_event_listener';
import { setIframeHeightFromItsContent, STRAWPOLL_EVENT_MESSAGES } from './iframe_helper';
import './survey_preview.scss';

interface IProps {
  url: string;
  id: string;
}

const cls = CLASS_PREFIX + 'survey__preview';

const SurveyPreview: FunctionComponent<IProps> = ({ url, id }) => {
  const pollId = useMemo(() => url.split('https://strawpoll.com/embed/polls/').pop(), [url]);
  const listener = useCallback((event: MessageEvent) => {
    if (STRAWPOLL_EVENT_MESSAGES.includes(event.data.type) && pollId === event.data.id) {
      setIframeHeightFromItsContent(event.data.value, id);
    }
  }, [id, pollId]);

  useMessageListener(listener, { passive: true });

  return <div className={cls}>
    <iframe id={id} src={url}/>
  </div>;
};

export default SurveyPreview;
