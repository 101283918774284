// libs
import * as React from 'react';

// components
import BackbuttonHeader from 'src/components/header/common/backbutton_header/backbutton_header';
import MoreButton from 'src/containers/post/more_button';
import { URLConsumer } from 'src/containers/url_provider/url_provider';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IPost } from 'src/interfaces/posts';
import initTracker, { HEADER_DETAILS } from 'src/utils/reporting/events_tracking/events_tracking';

// helpers

import './post_header_details.scss';

const cls = CLASS_PREFIX + 'post-header-details';
const clsMoreWrapper = `${cls}__more-wrapper`;

const tracker = initTracker(HEADER_DETAILS);

export interface IProps {
  handleBackButton?: () => void;
  title?: string;
  post?: IPost;
  postUpdateOpen: boolean;
}

/**
 * details header
 */
const PostHeaderDetails: React.FC<IProps> = ({ handleBackButton, title, post, postUpdateOpen }) => (
  <div className={cls}>
    <BackbuttonHeader
      handleBackButton={handleBackButton}
      title={title}
    >
      {post &&
        <URLConsumer>
          {({ getNewsfeedRoute }) => (
            <div className={clsMoreWrapper} >
              <MoreButton
                post={post}
                postUpdateOpen={postUpdateOpen}
                getNewsfeedRoute={getNewsfeedRoute}
                tracking={{ tracker: tracker, trackingObj: HEADER_DETAILS }}
              />
            </div>
          )}
        </URLConsumer>
      }
    </BackbuttonHeader>
  </div>
);

/* set displayname to not have a component without a name */
PostHeaderDetails.displayName = 'PostHeaderDetails';

export default PostHeaderDetails;
