// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IEventShortTime, IIngredient } from 'src/interfaces/post_ingredients';
import { mapDisplayModeToConst } from 'src/interfaces/posts';

// classes
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import DateFormatter from 'src/utils/date_formatter/date_formatter';

// styles
import './event_short_date.scss';

type IProps = IIngredient<IEventShortTime>;

const cls = `${CLASS_PREFIX}event-short-date__`;

const EventShortDate: React.FC<IProps> = ({
  data: { startTime, endTime },
  postDetailsURL,
  displayMode,
}) => {
  return (
    <NavigationItem className={`${cls}${mapDisplayModeToConst[displayMode]}`} target='_self' url={postDetailsURL} >
      {DateFormatter.formatEventShortDate(startTime, endTime)}
    </NavigationItem>
  );
};

export default EventShortDate;
