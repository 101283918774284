// libs
import classNames from 'classnames';
import React from 'react';

// components
import SideBar from 'src/components/sidebar/sidebar';
import DeferUtilMeFetched from 'src/containers/defer_until_me_fetched';
import Header from 'src/containers/header/main/header';

// constants / interfaces
import { ActivableNavigationItems } from 'src/components/header/navigation/header_navigation';
import { THREE_COLUMNS, TWO_COLUMNS } from 'src/constants/layout';

// helpers
import { useChatTopicTargetsShape } from 'src/components/chat_page/utils';
import { useGetNavSource } from 'src/reducers/app-state/app-state_hooks';
import { isNavSourceFromApp } from 'src/utils/nav_source';

const cls = 'app-';
const APP_WRAPPER_CLASS = cls + 'wrapper';
const APP_CONTAINER_CLASS = cls + 'container';
const APP_CONTENT_CLASS = cls + 'content';
const APP_BODY_CLASS = cls + 'body';

interface IProps {
  subHeader?: JSX.Element;
  activeNavigationItem?: ActivableNavigationItems;
  contentWidth?: typeof TWO_COLUMNS | typeof THREE_COLUMNS; // default is full width - 3 columns
  withHeader?: boolean;
  withHeaderMargin?: boolean;
  withSidebar?: boolean;
  enableMapToggler: boolean;
  isMapShown?: boolean;
  showBanner?: boolean;
  chatOpen?: boolean;
  pageTitle?: JSX.Element;
  sideBarContent?: JSX.Element;
  sideBarOnlyOnDesktop?: boolean;
  showStaticPagesFooter?: boolean;
}

const LayoutBase: React.FC<IProps> = ({
  activeNavigationItem,
  contentWidth,
  children,
  subHeader,
  isMapShown,
  enableMapToggler,
  withSidebar = true,
  withHeader = true,
  withHeaderMargin = true,
  pageTitle,
  sideBarContent,
  sideBarOnlyOnDesktop = false,
  showStaticPagesFooter = true,
}) => {
  const navSource = useGetNavSource();
  const showHeader = !isNavSourceFromApp(navSource) && withHeader;
  const targetedChatTopic = useChatTopicTargetsShape();
  const clsContainer = classNames({
    [APP_CONTAINER_CLASS]: true,
    [`${APP_CONTAINER_CLASS}--no-subheader`]: showHeader && !subHeader,
    [`${APP_CONTAINER_CLASS}--no-header`]: !showHeader,
    [`${APP_CONTAINER_CLASS}--no-sidebar`]: !withSidebar,
    [`${APP_CONTAINER_CLASS}--no-header-margin`]: !withHeaderMargin,
    [`${APP_CONTAINER_CLASS}--header-margin`]: withHeaderMargin,
    [`${APP_CONTAINER_CLASS}--sidebar-only-on-desktop`]: sideBarOnlyOnDesktop,
  });

  const contentClass = classNames({
    [APP_CONTENT_CLASS]: true,
    [`${APP_CONTENT_CLASS}--${contentWidth}`]: !!contentWidth && !withSidebar,
    [`${APP_CONTENT_CLASS}--${THREE_COLUMNS}`]: !contentWidth && !withSidebar,
  });

  return (
    <div className={APP_WRAPPER_CLASS}>
      {showHeader && <Header
        activeNavigationItem={activeNavigationItem}
        enableMapToggler={enableMapToggler}
        enableChatToggler={targetedChatTopic && targetedChatTopic.open}
        pageTitle={pageTitle}
        subHeader={subHeader}
      />}
      <div className={clsContainer}>
        <main className={APP_BODY_CLASS}>
          <div className={contentClass}>
            <DeferUtilMeFetched>
              {children}
            </DeferUtilMeFetched>
          </div>
          {
            withSidebar &&
              <SideBar
                withHeader={showHeader}
                withSubHeader={!!subHeader && showHeader}
                isShownOnMobile={!!isMapShown}
                isOnlyVisibleOnDesktop={sideBarOnlyOnDesktop}
                showStaticPagesFooter={showStaticPagesFooter}
              >
                {sideBarContent}
              </SideBar>
          }
        </main>
      </div>
    </div>
  );
};

export default LayoutBase;
