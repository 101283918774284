// libs
import classNames from 'classnames';
import * as React from 'react';
import { CLASS_PREFIX } from 'src/constants/';

// components
import withPortal from 'src/high_order_components/with_portal';

// helpers
import './modal.scss';

export interface IModalProps {
  children?: JSX.Element;
  onClose?: () => void;
  hasCloseButton?: boolean;
  noBorderRadius?: boolean;
}

const cls = `${CLASS_PREFIX}modal`;
const clsBackdrop = `${cls}__backdrop`;
const clsBody = `${cls}__body`;
const clsBodyNoBorderRadius = `${cls}__body--no-border-radius`;
const clsButton = `${cls}__close`;
const clsButtonIcon = `${CLASS_PREFIX}icon__x-circle`;
const BODY_CLASS = `${CLASS_PREFIX}body--modal-open`;
const bodyElement: HTMLElement = document.body;

class Modal extends React.Component<IModalProps, {}> {
  public componentDidMount() {
    bodyElement.classList.add(BODY_CLASS);
  }

  public componentWillUnmount() {
    bodyElement.classList.remove(BODY_CLASS);
  }

  public render() {
    const { children, hasCloseButton, onClose, noBorderRadius } = this.props;
    const bodyCls = {
      [clsBody]: true,
      [clsBodyNoBorderRadius]: noBorderRadius,
    };
    const buttonClasses = [
      clsButton,
      clsButtonIcon,
    ];

    return (
      <div className={cls}>
        <div className={clsBackdrop} onClick={onClose} />
        <div className={classNames(bodyCls)}>
          {hasCloseButton && <button className={classNames(buttonClasses)} onClick={onClose}/>}
          {children}
        </div>
      </div>
    );
  }
}

export default withPortal(Modal);
