import { IPopularPlacesToggle } from 'src/interfaces/popular_places_toggle';
// the default location shapes for the location changer's 'popular places' feature
// which will be used if no Lopo Admin toggle with the name `popular_places_v2` is
// found or active

export const LOCATION_PICKER_DEFAULT_POPULAR_PLACES: IPopularPlacesToggle = {
  'places': [
    {
      'bounding_box': {
        'ne_latitude': 51.7587909818776,
        'ne_longitude': 8.83548810644331,
        'sw_latitude': 51.6793368526627,
        'sw_longitude': 8.71048410371986,
      },
      'gid': 'location_shape#11423',
      'global_friendly_identifier': 'location_shape#paderborn-kernstadt',
      'global_stable_identifier': 'location_shape#11423',
      'identifier': '11423',
      'latitude': 51.717338,
      'longitude': 8.753997,
      'name': 'Paderborn',
      'released': true,
      'slug': 'paderborn',
      'type': 'location_shape',
    },
    {
      'bounding_box': {
        'ne_latitude': 52.25879976156482,
        'ne_longitude': 8.635369323713462,
        'sw_latitude': 52.16337076885306,
        'sw_longitude': 8.472290709200012,
      },
      'gid': 'location_shape#3418',
      'global_friendly_identifier': 'location_shape#herford-bunde',
      'global_stable_identifier': 'location_shape#3418',
      'identifier': '3418',
      'latitude': 52.2035,
      'longitude': 8.56339,
      'name': 'Bünde',
      'released': true,
      'slug': 'buende',
      'type': 'location_shape',
    },
    {
      'bounding_box': {
        'ne_latitude': 51.19350723949069,
        'ne_longitude': 13.54167780863569,
        'sw_latitude': 51.1291317149537,
        'sw_longitude': 13.42473027772226,
      },
      'gid': 'location_shape#3586',
      'global_friendly_identifier': 'location_shape#meissen',
      'global_stable_identifier': 'location_shape#3586',
      'identifier': '3586',
      'latitude': 51.1601033,
      'longitude': 13.4744756,
      'name': 'Meißen',
      'released': true,
      'slug': 'meissen',
      'type': 'location_shape',
    },
    {
      'bounding_box': {
        'ne_latitude': 52.26092395054857,
        'ne_longitude': 8.683930590893114,
        'sw_latitude': 52.17370512615244,
        'sw_longitude': 8.587370403741911,
      },
      'gid': 'location_shape#4814',
      'global_friendly_identifier': 'location_shape#kirchlengern',
      'global_stable_identifier': 'location_shape#4814',
      'identifier': '4814',
      'latitude': 52.1987,
      'longitude': 8.64269,
      'name': 'Kirchlengern',
      'released': true,
      'slug': 'kirchlengern',
      'type': 'location_shape',
    },
    {
      'bounding_box': {
        'ne_latitude': 52.26761383142456,
        'ne_longitude': 8.563067908738425,
        'sw_latitude': 52.19328646589323,
        'sw_longitude': 8.442206854575167,
      },
      'gid': 'location_shape#7816',
      'global_friendly_identifier': 'location_shape#rodinghausen',
      'global_stable_identifier': 'location_shape#7816',
      'identifier': '7816',
      'latitude': 52.2258,
      'longitude': 8.48852,
      'name': 'Rödinghausen',
      'released': true,
      'slug': 'rodinghausen',
      'type': 'location_shape',
    },
  ],
};

export const LOCATION_PICKER_INPUT_DEBOUNCE = 250; // ms

// minimum characters a user has to enter to be able to start a location search.
// this corresponds to the Solr configuration which can be found in
// solr/conf/schema.xml, look for 'solr.length_filter_factory'!
export const LOCATION_PICKER_MIN_QUERY_LENGTH = 3;
