// libs
import { debounce } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

// interfaces
import { IAction } from 'src/actions/';
import { Notification } from 'src/interfaces/notification';
import { IPagination } from 'src/interfaces/pagination';
import { IResourceLink } from 'src/interfaces/resource_links';
import { IPermissions, IUser } from 'src/interfaces/user';
import { IRootState } from 'src/reducers/interface';

// components
import Notifications from 'src/components/notifications/notifications';

// actions
import {
  fetchNotifications as fetchNotificationsAction,
  fetchNewNotifications as fetchNewNotificationsAction,
  getUnseenCounts,
  markNotificationRead,
  markNotificationsSeen,
} from 'src/actions/notification/notification';
import { callToAction } from 'src/actions/onboarding_cta/onboarding_cta';

// helpers
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import { INotifications } from 'src/reducers/notifications/notifications';

const getNotificationsForProfile = (notifications: INotifications, user: IUser) => {
  return notifications.forProfile[user.selectedProfileId]
    ? notifications.forProfile[user.selectedProfileId]!.items.map(
      (notificationIdentifier) => notifications.byId[notificationIdentifier]!,
    )
    : [];
};

interface IOwnProps extends RouteComponentProps {
  getResourceLinkRoute: (resourceLink: IResourceLink) => string;
}

type IProps = IMapStateToProps & IDispatchFromProps & IOwnProps;

interface IMapStateToProps {
  isLoading: boolean;
  notifications: Notification[];
  padding: number;
  pagination: IPagination;
  selectedProfileId: string;
  selectedProfilePermissions?: IPermissions;
  selectedProfileUnreadNotificationsCount: number;
}

interface IDispatchFromProps {
  dispatchCallToAction: () => void;
  fetchNewNotifications: (selectedProfileId: string, count: number) => void;
  fetchNotifications: (selectedProfileId: string, padding: number, page?: number) => void;
  fetchNotificationUnseenCounts: () => void;
  markNotificationAsRead: (notificationId: string, selectedProfileId: string) => void;
  markNotificationsAsSeen: (notificationIds: string[], selectedProfileId: string) => void;
}

const mapStateToProps = ({ notifications, user }: IRootState): IMapStateToProps => ({
  isLoading: notifications.isLoading,
  notifications: getNotificationsForProfile(notifications, user),
  padding: notifications.padding,
  pagination: notifications.pagination,
  selectedProfileId: user.selectedProfileId,
  selectedProfilePermissions: user.profiles[user.selectedProfileId]
    ? user.profiles[user.selectedProfileId].permissions
    : undefined,
  selectedProfileUnreadNotificationsCount: notifications.forProfile[user.selectedProfileId]
    ? notifications.forProfile[user.selectedProfileId]!.unseenCount || 0
    : 0,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, IAction>): IDispatchFromProps => ({
  dispatchCallToAction: () => dispatch(callToAction()),
  fetchNewNotifications: (selectedProfileId, count) => dispatch(fetchNewNotificationsAction(selectedProfileId, count)),
  fetchNotificationUnseenCounts: () => dispatch(getUnseenCounts()),
  fetchNotifications: (selectedProfileId, padding, page) =>
    dispatch(fetchNotificationsAction(selectedProfileId, padding, page)),
  markNotificationAsRead: (notification, selectedProfileId) =>
    dispatch(markNotificationRead(notification, selectedProfileId)),
  markNotificationsAsSeen: (notifications, selectedProfileId) =>
    dispatch(markNotificationsSeen(notifications, selectedProfileId)),
});

class NotificationsContainer extends React.PureComponent<IProps, {}> {
  constructor(props: IProps) {
    super(props);
    this.onNotificationRead = this.onNotificationRead.bind(this);
    this.fetchNotificationsNextPage = this.fetchNotificationsNextPage.bind(this);
    this.debouncedFetchNotifications = debounce(this.debouncedFetchNotifications.bind(this), 500);
    this.debouncedFetchNewNotifications = debounce(this.debouncedFetchNewNotifications.bind(this), 500);
  }

  public componentDidMount() {
    const { selectedProfilePermissions } = this.props;
    this.markAllNotificationsAsSeen();
    if (selectedProfilePermissions?.readNotifications) {
      this.debouncedFetchNotifications();
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const {
      isLoading,
      selectedProfileId,
      selectedProfilePermissions,
      selectedProfileUnreadNotificationsCount,
    } = this.props;

    if (selectedProfileId !== prevProps.selectedProfileId) {
      this.markAllNotificationsAsSeen();
    }

    if (isLoading || !selectedProfilePermissions?.readNotifications) {
      return;
    }

    if (selectedProfileUnreadNotificationsCount !== prevProps.selectedProfileUnreadNotificationsCount) {
      // prepend new notifications
      this.debouncedFetchNewNotifications();
    }
  }

  public render() {
    const {
      dispatchCallToAction,
      notifications,
      selectedProfileUnreadNotificationsCount,
      selectedProfilePermissions,
      isLoading,
    } = this.props;

    const canReadNotifications = selectedProfilePermissions && selectedProfilePermissions.readNotifications || false;
    return (
      <LoadingSpinnerWrapper isLoading={isLoading}>
        <Notifications
          canReadNotifications={canReadNotifications}
          fetchMoreNotifications={this.fetchNotificationsNextPage}
          notifications={notifications}
          onNotificationClick={this.onNotificationRead}
          unreadNotificationsCount={selectedProfileUnreadNotificationsCount}
          callToAction={dispatchCallToAction}
        />
      </LoadingSpinnerWrapper>
    );
  }

  private debouncedFetchNotifications() {
    const { fetchNotifications, padding, selectedProfileId } = this.props;
    fetchNotifications(selectedProfileId, padding);
  }

  private debouncedFetchNewNotifications() {
    const { fetchNewNotifications, selectedProfileId, selectedProfileUnreadNotificationsCount } = this.props;
    fetchNewNotifications(selectedProfileId, selectedProfileUnreadNotificationsCount);
  }

  private markAllNotificationsAsSeen() {
    const { markNotificationsAsSeen, notifications, selectedProfileId } = this.props;
    markNotificationsAsSeen(notifications.map(notification => notification.id), selectedProfileId);
  }

  private onNotificationRead(notification: Notification) {
    const { markNotificationAsRead, history, selectedProfileId, getResourceLinkRoute } = this.props;
    return () => {
      markNotificationAsRead(notification.id, selectedProfileId);
      if (notification.resourceLink) {
        history.push(getResourceLinkRoute(notification.resourceLink));
      }
    };
  }

  private fetchNotificationsNextPage() {
    const {
      fetchNotifications,
      isLoading,
      padding,
      pagination: { currentPage, lastPage },
      selectedProfileId,
    } = this.props;

    !isLoading && !lastPage && fetchNotifications(selectedProfileId, padding, currentPage + 1);
  }
}

export default withRouter(connect<IMapStateToProps, IDispatchFromProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(NotificationsContainer));
