import {
  IUserListActionConfirmation,
  IUserListActionDetails,
} from 'src/components/item_segment/layouts/profiles_list/model';
import { GroupListProfileAvailableAction } from 'src/containers/smart_components/group_member_list/model';
import { textResources } from 'src/lang/de';

const GroupMemberListConfirmations = new Map<GroupListProfileAvailableAction, IUserListActionConfirmation>([
  [
    GroupListProfileAvailableAction.ACCEPT, {
      buttons: [
        {
          label: textResources.groups.confirmations.accept.buttonConfirm,
          type: 'PRIMARY',
        },
        {
          type: 'CANCEL',
        },
      ],
      children: textResources.groups.confirmations.accept.body,
      title: textResources.groups.confirmations.accept.title,
    },
  ],
  [
    GroupListProfileAvailableAction.REJECT, {
      buttons: [
        {
          label: textResources.groups.confirmations.reject.buttonConfirm,
          type: 'DANGER',
        },
        {
          type: 'CANCEL',
        },
      ],
      children: textResources.groups.confirmations.reject.body,
      title: textResources.groups.confirmations.reject.title,
    },
  ],
  [
    GroupListProfileAvailableAction.REMOVE, {
      buttons: [
        {
          label: textResources.groups.confirmations.remove.buttonConfirm,
          type: 'DANGER',
        },
        {
          type: 'CANCEL',
        },
      ],
      children: textResources.groups.confirmations.remove.body,
      title: textResources.groups.confirmations.remove.title,
    },
  ],
]);

export const getGroupMemberListAction = (type: GroupListProfileAvailableAction, action: () => void):
IUserListActionDetails => {
  const confirmation = GroupMemberListConfirmations.get(type);
  if (confirmation) {
    return {
      action,
      confirmation: {
        ...confirmation,
        buttons: confirmation.buttons?.map(button => {
          return button.type !== 'CANCEL' ? {
            ...button,
            onClick: action,
          } : button;
        }),
      },
    };
  }

  return {
    action,
  };
};
