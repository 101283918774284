// libs
import * as React from 'react';

// interfaces / constants

// classes / components
import MessageForm from 'src/components/message_form/message_form';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import { FRONTEND_DATA_FAQ_PATH } from 'src/constants/urls';
import { textResources } from 'src/lang/de';

import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import './feedback.scss';

const cls = createBemBlock('feedback');

const labels = textResources.feedback;

interface IFeedbackProps {
  close: () => void;
  loggedInUserEmail?: string;
}

export const ThanksBody: React.FC = () => (
  <p>
    {labels.thanksMessage}
    <NavigationItem
      className={cls('custom-text', 'highlighted')}
      target={'_blank'}
      url={FRONTEND_DATA_FAQ_PATH}>
      {labels.thanksMessageLink}
    </NavigationItem>
    {textResources.shared.period}
  </p>
);

const Feedback: React.FC<IFeedbackProps> = ({
  close,
  loggedInUserEmail,
}) => {
  return (
    <MessageForm
      successImage='feedback-success'
      successTitle={labels.thanksTitle}
      successBody={<ThanksBody />}
      body={labels.message}
      close={close}
      loggedInUserEmail={loggedInUserEmail}
      title={labels.title}
    />
  );
};

export default Feedback;
