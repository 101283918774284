import { throttle } from 'lodash';
import React, { useState, useCallback } from 'react';

// components
import Icon from 'src/components/forms/icon/icon';
import Popover from 'src/components/popover/popover';

// constants
import { CLASS_PREFIX } from 'src/constants/';
import { COLOR_TYPE_GRAY_TINT_1 } from 'src/utils/color';

// helpers
import { useResizeListener } from 'src/hooks_shared/use_event_listener';
import { textResources } from 'src/lang/de';
import { readColumnCountFromDOM } from 'src/utils/column_count';

import './more_menu.scss';
const cls = CLASS_PREFIX + 'subheader_more_menu';

interface IProps {
  onHide?: () => void;
}

const MoreMenu: React.FC<IProps> = ({ onHide = () => {}, children }) => {
  const [columnCount, setColumnCount] = useState(() => readColumnCountFromDOM());
  const listener = useCallback(
    throttle(() => {
      setColumnCount(readColumnCountFromDOM());
    }, 100),
    [],
  );

  useResizeListener(listener, { passive: true });

  return (
    <Popover
      position={'bottom'}
      align={columnCount === 1 ? 'relative' : 'relative-right'}
      layout={'plain'}
      onHide={onHide}
      trigger={
        <Icon name={'dots'} color={COLOR_TYPE_GRAY_TINT_1} description={textResources.screenReaderText.moreOptions} />
      }
    >
      <div className={cls}>
        {children}
      </div>
    </Popover>
  );
};

export default MoreMenu;
