// libs
import * as H from 'history';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

// interfaces / constants
import { IAction } from 'src/actions/';
import { IUserProfileType } from 'src/interfaces/user';
import { IRootState } from 'src/reducers/interface';

// classes / components
import PremiumContentBlocker from 'src/components/premium_content/premium_content';

// helpers
import { RouteComponentProps } from 'react-router';
import {
  showRegistrationModal,
  showLoginModal,
} from 'src/actions/app-state/app-state';
import { withRouterProps } from 'src/high_order_components/connect_route_props';

interface IMapStateToProps {
  loggedInProfileType: IUserProfileType;
  state: IRootState;
  userEmail?: string;
}

interface IDispatchFromProps {
  dispatchShowLoginModal: () => void;
  dispatchShowRegistrationModal: () => void;
}

interface IOwnProps {
  fencedContent: boolean;
  premiumContent: boolean;
  postUrl: string;
}

interface IMapRouteToProps {
  history: H.History;
}

export type IPremiumContentBlockerProps = IMapStateToProps & IOwnProps & IDispatchFromProps & IMapRouteToProps;

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  loggedInProfileType: state.user.profiles[state.user.selectedProfileId].type,
  state: state,
  userEmail: state.user.email,
});

const mapRouteToProps = (history: RouteComponentProps): IMapRouteToProps => {
  return history;
};

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, IAction>): IDispatchFromProps => ({
  dispatchShowLoginModal: () => dispatch(showLoginModal()),
  dispatchShowRegistrationModal: () => dispatch(showRegistrationModal()),
});

export default withRouterProps<IMapRouteToProps, IOwnProps>(mapRouteToProps)(
  connect<IMapStateToProps, IDispatchFromProps, IOwnProps>(
    mapStateToProps, mapDispatchToProps)(PremiumContentBlocker));
