// libs
import * as React from 'react';

// components
import AuthUI from 'src/containers/auth_ui';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { MODAL_TYPE_LOGIN } from 'src/interfaces/app-state';

import './login_page.scss';

const cls = CLASS_PREFIX + 'login-page';

const LoginPage: React.FC = () => (
  <div className={cls}>
    <AuthUI layout={MODAL_TYPE_LOGIN} hasStaticLinks hasTabs />
  </div>
);

export default LoginPage;
