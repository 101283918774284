export const RESOURCE_LINK_PATH_CONVERSATION = 'conversation';
export const RESOURCE_LINK_PATH_EVENT = 'event';
export const RESOURCE_LINK_PATH_EVENTS = 'events';
export const RESOURCE_LINK_PATH_EVENTS_TOP_PER_CATEGORY = 'events_top_per_category';
export const RESOURCE_LINK_PATH_NEWSFEED = 'newsfeed';
export const RESOURCE_LINK_PATH_POST = 'post';
export const RESOURCE_LINK_PATH_GROUP = 'group';

export const RESOURCE_LINK_PATH_PRIVATE_PROFILES = 'private_profiles';
export const RESOURCE_LINK_PATH_BLOG_PROFILES = 'blog_profiles';
export const RESOURCE_LINK_PATH_AUTHORITY_PROFILES = 'authority_profiles';
export const RESOURCE_LINK_PATH_PRESS_PROFILES = 'press_profiles';
