// libs
import * as React from 'react';

// components
import ContentPlaceholder from 'src/components/content_placeholder/content_placeholder';
import Icon from 'src/components/forms/icon/icon';

// helpers
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import './post_preview.scss';

const cls = createBemBlock('post-preview-placeholder');

const PostPreviewPlaceholder: React.FC = () => (
  <div className={cls()}>
    <ContentPlaceholder className={cls('image')} />
    <div className={cls('block-content')}>
      <ContentPlaceholder className={cls('inclusion-reason')} />
      <ContentPlaceholder className={cls('title')} />
      <ContentPlaceholder className={cls('description')} />
      <ContentPlaceholder className={cls('description')} />
      <div className={cls('block-author')}>
        <ContentPlaceholder className={cls('avatar')} />
        <div>
          <ContentPlaceholder className={cls('author')} />
          <ContentPlaceholder className={cls('date')} />
        </div>
      </div>
      <div className={cls('block-buttons')}>
        <div className={cls('button')}>
          <Icon name='heart-outline' />
          <ContentPlaceholder className={cls('button-text')} />
        </div>
        <div className={cls('button')}>
          <Icon name='comment' />
          <ContentPlaceholder className={cls('button-text')} />
        </div>
        <div className={cls('button')}>
          <Icon name='share-thick' />
          <ContentPlaceholder className={cls('button-text')} />
        </div>
      </div>
    </div>
  </div>
);

export default PostPreviewPlaceholder;
