// interfaces
import {
  FetchFeedThunkAction,
  IGroupFeedMergeableParams,
  IGroupFeedRequestParams,
} from 'src/actions/feed/feed_interfaces';
// constants
import { FEED_TYPE_GROUP } from 'src/constants/feed';
// actions
import { fetchFeed, setFeedHash } from 'src/actions/feed/feed';
// helpers
import api from 'src/api/';

export const fetchGroupFeedPage = (page: number, params?: IGroupFeedMergeableParams): FetchFeedThunkAction => {
  return async(dispatch, getState): Promise<void> => {
    const fetchGroupsFeed = fetchFeed<IGroupFeedRequestParams>(
      FEED_TYPE_GROUP,
      api.group.feed,
    );

    const state = getState();

    if (!params?.urls.initialTopic || !params?.isMember) {
      if (state.feed.feeds[FEED_TYPE_GROUP].feedHash !== '') {
        // TO clean previosly seen posts from other joined group so they wont be
        // shown in an unjoined group
        dispatch(setFeedHash(FEED_TYPE_GROUP, ''));
      }
      return;
    }

    dispatch(fetchGroupsFeed({
      page,
      path: params.urls.initialTopic,
    }));
  };
};
