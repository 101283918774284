import produce from 'immer';

import {
  Actions,
  PROFILES_LIST_CLEAR_ALL,
  PROFILES_LIST_CLEAN,
  PROFILES_LIST_GET_SPONSORS_SUCCESS,
} from 'src/actions/profiles_list/profiles_list';
import { IShapeSponsorList } from 'src/interfaces/shape_sponsors';

const INITIAL_STATE: IShapeSponsorList = {
  sponsors: [],
};

const shapeSponsorsReducer = (state = INITIAL_STATE, action: Actions): IShapeSponsorList =>
  produce(state, (draft) => {
    switch (action.type) {
      case PROFILES_LIST_CLEAN:
      case PROFILES_LIST_CLEAR_ALL:
        draft = INITIAL_STATE;
        break;

      case PROFILES_LIST_GET_SPONSORS_SUCCESS:
        if (action.payload) {
          draft.sponsors = action.payload.sponsors;
        }
        break;
    }
  });

export default shapeSponsorsReducer;
