// libs
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

// components
import FloatingButtonMenu from 'src/components/floating_button_menu/floating_button_menu';

// interfaces & constants
import { IOption } from 'src/components/floating_button_menu/option/option';
import {
  FEED_POST_TYPE_EVENT,
  FEED_POST_TYPE_PRIVATE_POST,
  FEED_POST_TYPE_PUBLIC_POST,
  FEED_POST_TYPE_SNAPSHOT_POST,
  FEED_POST_TYPE_SURVEY,
} from 'src/constants/feed';
import { IContentItemType } from 'src/interfaces/posts';
import { textResources } from 'src/lang/de';
import {
  COLOR_TYPE_PRIMARY,
  COLOR_TYPE_PRIMARY_2,
  COLOR_TYPE_SECONDARY6,
  COLOR_TYPE_SECONDARY3,
  COLOR_TYPE_SECONDARY5,
} from 'src/utils/color';

// helpers
import { useChatTopicTargetsShape } from 'src/components/chat_page/utils';
import { createWritePath } from 'src/utils/url/url';

// assets
import { useSurvey } from '../../../plugins/survey/hook/useSurvey';
import addImagePrimary from './add.svg';
import addImageSecondary6 from './add_event.svg';
import addImagePrimary2 from './add_neighbour.svg';
import closeImage from './close.svg';

type IconColors = typeof COLOR_TYPE_PRIMARY
  | typeof COLOR_TYPE_PRIMARY_2
  | typeof COLOR_TYPE_SECONDARY6;

interface IProps {
  postTypes?: IContentItemType | IContentItemType[];
  iconColor?: IconColors;
  remAboveBottom?: number;
  featureBlocked: boolean;
  initiallyExpanded?: boolean;
  openFilePicker: () => void;
}

const labels = textResources.postCreate;

const optionCreatorMap: {[key in IContentItemType]: (url: string, onClick?: () => void) => IOption} = {
  [FEED_POST_TYPE_EVENT]: (url) => ({
    backgroundColor: COLOR_TYPE_SECONDARY6,
    icon: 'event',
    text: textResources.postTypes.event,
    url: createWritePath(url, 'event'),
  }),
  [FEED_POST_TYPE_PRIVATE_POST]: (url) => ({
    backgroundColor: COLOR_TYPE_PRIMARY,
    icon: 'pencil-filled',
    text: textResources.postTypes.publicPost,
    url: createWritePath(url, FEED_POST_TYPE_PRIVATE_POST),
  }),
  [FEED_POST_TYPE_PUBLIC_POST]: (url) => ({
    backgroundColor: COLOR_TYPE_PRIMARY,
    icon: 'pencil-filled',
    text: textResources.postTypes.publicPost,
    url: createWritePath(url, FEED_POST_TYPE_PUBLIC_POST),
  }),
  [FEED_POST_TYPE_SNAPSHOT_POST]: (_url, onClick) => ({
    backgroundColor: COLOR_TYPE_SECONDARY5,
    icon: 'camera-filled',
    onClick,
    text: textResources.postTypes.snapshot,
  }),
  [FEED_POST_TYPE_SURVEY]: (url) => ({
    backgroundColor: COLOR_TYPE_SECONDARY3,
    icon: 'checkmark',
    text: textResources.postTypes.survey,
    url: createWritePath(url, FEED_POST_TYPE_SURVEY),
  }),
};

const prepareAvailablePostTypes = (postTypes: IContentItemType[], isSurveyEnabled: boolean) =>
  postTypes.filter(postType => {
    if (!isSurveyEnabled) {
      return postType !== FEED_POST_TYPE_SURVEY;
    }
    return postType;
  });

const AddIconMap: { [key in IconColors]: string } = {
  [COLOR_TYPE_PRIMARY]: addImagePrimary,
  [COLOR_TYPE_PRIMARY_2]: addImagePrimary2,
  [COLOR_TYPE_SECONDARY6]: addImageSecondary6,
};

const FloatingPostCreate: React.FC<IProps> = ({
  postTypes = [FEED_POST_TYPE_PUBLIC_POST, FEED_POST_TYPE_EVENT, FEED_POST_TYPE_SNAPSHOT_POST, FEED_POST_TYPE_SURVEY],
  iconColor = COLOR_TYPE_PRIMARY,
  remAboveBottom,
  featureBlocked,
  initiallyExpanded,
  openFilePicker,
}) => {
  const match = useRouteMatch();
  const { isEnabled: isSurveyEnabled } = useSurvey();
  let options: IOption[] = [];
  if (!featureBlocked && match && postTypes) {
    const handleSnapshot = () => {
      openFilePicker();
    };
    const postTypesArray = Array.isArray(postTypes) ? postTypes : [postTypes];
    options = prepareAvailablePostTypes(postTypesArray, isSurveyEnabled).map(postType =>
      optionCreatorMap[postType](
        match.url,
        postType === FEED_POST_TYPE_SNAPSHOT_POST ? handleSnapshot : undefined
      )
    );
  }

  // part of topic-chat POC test
  // checks if there is currently a chatTopic running for the current Shape
  const chatTopicForCurrentShape = useChatTopicTargetsShape();
  const remAboveBottomWithChatConsidered =
    chatTopicForCurrentShape && chatTopicForCurrentShape.open ? 4 : remAboveBottom;

  return (
    <FloatingButtonMenu
      image={AddIconMap[iconColor]}
      expandedImage={closeImage}
      title={labels.createPostTitle}
      options={options}
      remAboveBottom={remAboveBottomWithChatConsidered}
      initiallyExpanded={initiallyExpanded}
      featureBlocked={featureBlocked}
    />
  );
};

export default FloatingPostCreate;
