// libs
import React, { useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';

// actions
import { useBlacklistProfile, useGraylistProfile } from 'src/actions/profile/profile_hooks';

// components
import AvatarName from 'src/components/avatar/avatar_name';
import BackbuttonHeader from 'src/components/header/common/backbutton_header/backbutton_header';
import MoreMenu from 'src/components/header/subheaders/shared_contents/more_menu/more_menu';
import MenuItemSegment from 'src/components/item_segment/layouts/menu/menu';
import {
  MSGBOX_BUTTON_TYPE_DANGER,
  MSGBOX_BUTTON_TYPE_CANCEL,
  MSGBOX_BUTTON_TYPE_PRIMARY,
} from 'src/components/message_box/message_box';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';
import SnackBar from 'src/components/snack_bar/snack_bar';
import { THUMBNAIL_SIZE_TYPE_XXSMALL } from 'src/components/thumbnail/thumbnail';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IMoreOption, MORE_OPTIONS } from 'src/constants/more_button';
import { GRAYLIST_PROFILE_DEFAULT_EXPIRATION } from 'src/constants/user';

// helpers
import { useGoToNewsFeed } from 'src/hooks_shared/use_navigation';
import { textResources } from 'src/lang/de';
import { useSelectUrlParamProfile } from 'src/reducers/profiles/profiles_hooks';
import { useSelectCurrentProfile, useSelectIsUserLoggedIn } from 'src/reducers/user/user_hooks';
import { COLOR_TYPE_BLACK } from 'src/utils/color';
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import DateHelper from 'src/utils/date_helper/date';
import initTracker, { HEADER_PROFILE } from 'src/utils/reporting/events_tracking/events_tracking';
import { UrlUtils } from 'src/utils/url/url';

import './header_profile.scss';

const cls: string = CLASS_PREFIX + 'header_profile';
const clsMoreWrapper = `${cls}__more-wrapper`;

const tracker = initTracker(HEADER_PROFILE);
const labels = textResources.blacklist;

/**
 * profile header
 */
const HeaderProfile: React.FC = () => {
  const isNotLoggedIn = !useSelectIsUserLoggedIn();
  const selectedProfile = useSelectCurrentProfile();
  const history = useHistory();
  const goToNewsFeed = useGoToNewsFeed();
  const [showBlackList, setShowBlackList] = useState(false);
  const [showRevertBlackList, setShowRevertBlackList] = useState(false);
  const [graylistSnackbarMessage, setGraylistSnackbarMessage] = useState('');
  const blacklistProfile = useBlacklistProfile();
  const graylistProfile = useGraylistProfile();
  const profileDetail = useSelectUrlParamProfile();

  const closeBlackListMessageBox = () => setShowBlackList(false);
  const closeRevertBlackListMessageBox = () => setShowRevertBlackList(false);
  const hideGraylistMessage = () => setGraylistSnackbarMessage('');

  const isCurrentUserProfile = profileDetail?.name === selectedProfile.name;
  const options: IMoreOption[] = useMemo((): IMoreOption[] => {
    const options: IMoreOption[] = [];

    if (profileDetail) {
      const { permissions, meta } = profileDetail;

      if (permissions.canMessage) {
        options.push(MORE_OPTIONS.MESSAGE);
      }
      if (permissions?.canGraylist && !meta?.isGraylisted) {
        options.push(MORE_OPTIONS.GRAYLIST);
      }
      if (permissions?.canBlacklist && !meta.isBlacklisted) {
        options.push(MORE_OPTIONS.BLACKLIST);
      }
      if (permissions?.canBlacklist && meta.isBlacklisted) {
        options.push(MORE_OPTIONS.REVERT_BLACKLIST);
      }
    }
    return options;
  }, [profileDetail]);

  const handleBackButton = useCallback(() => UrlUtils.goBack(history), [history]);

  const moreButtonAction = useCallback((option: IMoreOption) => {
    if (option.value === MORE_OPTIONS.GRAYLIST.value && profileDetail?.urls.graylist) {
      tracker(HEADER_PROFILE.ACTIONS.GRAY_LIST, HEADER_PROFILE.LABELS.FINISH);
      graylistProfile(
        profileDetail.urls.graylist,
        profileDetail.id,
        DateHelper.addTime(GRAYLIST_PROFILE_DEFAULT_EXPIRATION));
      const graylistedFor = DateFormatter.durationToDays(GRAYLIST_PROFILE_DEFAULT_EXPIRATION);
      setGraylistSnackbarMessage(textResources.graylist.successMessage(profileDetail.name, graylistedFor));
    }
    if (option.value === MORE_OPTIONS.BLACKLIST.value) {
      setShowBlackList(true);
    }
    if (option.value === MORE_OPTIONS.REVERT_BLACKLIST.value) {
      setShowRevertBlackList(true);
    }
    if (option.value === MORE_OPTIONS.MESSAGE.value) {
      const type = profileDetail?.type || '';
      const id = profileDetail?.id || '';
      history.push(UrlUtils.sendMessageConversationPage(type, id));
    }
  }, [history, profileDetail, graylistProfile]);

  const handleBlacklist = useCallback(() => {
    closeBlackListMessageBox();
    if (profileDetail?.urls.blacklist) {
      tracker(HEADER_PROFILE.ACTIONS.BLACK_LIST, HEADER_PROFILE.LABELS.FINISH);
      blacklistProfile(profileDetail.urls.blacklist);
      goToNewsFeed();
    }
  }, [profileDetail, goToNewsFeed, blacklistProfile]);

  const handleRevertBlacklist = useCallback(() => {
    closeRevertBlackListMessageBox();
    if (profileDetail?.urls.blacklist) {
      tracker(HEADER_PROFILE.ACTIONS.REVERT_BLACK_LIST, HEADER_PROFILE.LABELS.FINISH);
      blacklistProfile(profileDetail.urls.blacklist);
      window.location.reload();
    }
  }, [profileDetail, blacklistProfile]);

  return (
    <BackbuttonHeader
      handleBackButton={handleBackButton}
      title={
        <AvatarName
          size={THUMBNAIL_SIZE_TYPE_XXSMALL}
          name={profileDetail?.name}
          image={profileDetail?.imageUrl}
        />
      }
    >
      {profileDetail && !isCurrentUserProfile && (
        <UserOnboardingCTA active={isNotLoggedIn} gtmSourceName='profileHeader--more' className={clsMoreWrapper}>
          <div className={clsMoreWrapper}>
            <MoreMenu>
              {options.map(option => (
                <MenuItemSegment
                  key={option.value}
                  label={option.text}
                  navigation={{ onClick: () => { moreButtonAction(option); } }}
                  icon={{ color: COLOR_TYPE_BLACK, name: option.icon }}
                />
              ))}
            </MoreMenu>
          </div>
        </UserOnboardingCTA>)
      }
      {showBlackList && (
        <MessageBoxWithModal
          buttons={[
            {
              label: labels.blacklist,
              onClick: handleBlacklist,
              type: MSGBOX_BUTTON_TYPE_DANGER,
            },
            {
              onClick: closeBlackListMessageBox,
              type: MSGBOX_BUTTON_TYPE_CANCEL,
            },
          ]}
          onClose={closeBlackListMessageBox}
          title={labels.modalTitle}
        >
          <p>{labels.modalContent}</p>
        </MessageBoxWithModal>
      )}
      {showRevertBlackList && (
        <MessageBoxWithModal
          buttons={[
            {
              label: labels.revertBlacklist,
              onClick: handleRevertBlacklist,
              type: MSGBOX_BUTTON_TYPE_PRIMARY,
            },
            {
              onClick: closeRevertBlackListMessageBox,
              type: MSGBOX_BUTTON_TYPE_CANCEL,
            },
          ]}
          onClose={closeRevertBlackListMessageBox}
          title={labels.modalTitleRemove}
        />
      )}
      {graylistSnackbarMessage && (
        <SnackBar onClose={hideGraylistMessage}>
          {graylistSnackbarMessage}
        </SnackBar>
      )}
    </BackbuttonHeader>
  );
};

/* set displayname to not have a component without a name */
HeaderProfile.displayName = 'HeaderProfile';

export default HeaderProfile;
