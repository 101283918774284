// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import './expandable_textarea.scss';

const INITIAL_ROW_COUNT = 1;
export interface IProps {
  autoFocus?: boolean;
  className?: string;
  expandable?: boolean;
  defaultValue?: string;
  disabled?: boolean;
  form?: string;
  required?: boolean;
  id?: string;
  min?: string;
  max?: string;
  maxLength?: number;
  maxHeight?: number;
  name: string;
  onFocus?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onBlur?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onChange?: (value: string, name: string) => void;
  onInput?: (event: React.SyntheticEvent<HTMLElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  initialRows?: number;
  value?: string;
  textAreaRef?: (ref: HTMLTextAreaElement) => void;
}

interface IState {
  height?: number;
  width?: number;
}
const cls: string = CLASS_PREFIX + 'expandable-textarea';

export default class ExpandableTextArea extends React.PureComponent<IProps, IState> {
  private textAreaElement: HTMLTextAreaElement;
  private divElement: HTMLDivElement;
  constructor(props: IProps) {
    super(props);
    this.handleTextAreaRef = this.handleTextAreaRef.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateHeight = this.updateHeight.bind(this);
    this.state = { height: undefined, width: undefined };
  }

  public render() {
    const {
      autoFocus,
      className,
      defaultValue,
      disabled,
      expandable,
      id,
      initialRows,
      maxLength,
      form,
      name,
      required,
      value,
      placeholder,
      onBlur,
      onFocus,
      onInput,
      onKeyDown,
    } = this.props;
    const { width, height } = this.state;
    const splitedValue = value && value.replace(/\n/g, '\n\r');
    return (
      <div className={cls}>
        <textarea
          autoFocus={autoFocus}
          className={cls + '__txtArea' + ' ' + className}
          disabled={disabled}
          defaultValue={defaultValue}
          form={form}
          id={id}
          ref={this.handleTextAreaRef}
          placeholder={placeholder}
          name={name}
          required={required}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          onInput={onInput}
          onKeyDown={onKeyDown}
          onChange={this.onChange}
          style={expandable && height ? { 'minHeight': height } : undefined}
          rows={initialRows || INITIAL_ROW_COUNT}
          maxLength={maxLength}
        />
        {expandable && (
          <div
            ref={this.handleRef}
            className={cls + '__shadow' + ' ' + className}
            style={width ? { 'width': width } : undefined}
          >
            {splitedValue}
          </div>
        )}
      </div>
    );
  }

  public componentDidMount() {
    this.updateHeight();
  }

  public componentDidUpdate() {
    this.updateHeight();
  }

  private handleTextAreaRef(ref: HTMLTextAreaElement) {
    const { textAreaRef } = this.props;
    textAreaRef && textAreaRef(ref);
    this.textAreaElement = ref;
    this.updateWidth();
  }

  private updateWidth() {
    if (this.textAreaElement) {
      const width = this.textAreaElement.offsetWidth;
      this.setState({ width });
    }
  }

  private updateHeight() {
    const { maxHeight } = this.props;
    const divHeight = this.divElement && this.divElement.offsetHeight;
    const height = maxHeight ? Math.min(divHeight, maxHeight) : divHeight;
    if (this.state.height !== height) {
      this.setState({ height });
    }
  }

  private handleRef(ref: HTMLDivElement) {
    this.divElement = ref;
  }

  private onChange(event: React.SyntheticEvent<HTMLTextAreaElement>) {
    const { expandable, onChange } = this.props;
    const target = event.target as HTMLTextAreaElement;
    onChange && onChange(target.value, target.name);
    expandable && this.updateWidth(); // for IE
  }
}
