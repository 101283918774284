// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { STYLE_CONST_3_COLUMNS_REM } from 'src/constants/style';
import { IImage, IImageIngredientSettings, IIngredient } from 'src/interfaces/post_ingredients';
import { mapDisplayModeToConst } from 'src/interfaces/posts';

// classes
import { WithLightbox } from 'src/components/darkbox/darkbox';
import Icon from 'src/components/forms/icon/icon';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import { textResources } from 'src/lang/de';

// styles
import './image.scss';

export type IProps = IIngredient<IImage, IImageIngredientSettings>;

export const cls = `${CLASS_PREFIX}image__`;

export default class Image extends React.PureComponent<IProps, {}> {
  private cls: string;

  constructor(props: IProps) {
    super(props);
    this.cls = `${cls}${mapDisplayModeToConst[props.displayMode]}`;
    this.handleRef = this.handleRef.bind(this);
  }

  public render() {
    const { postDetailsURL } = this.props;

    return (
      <NavigationItem target='_self' url={postDetailsURL}>
        <div className={this.cls}>{this.renderComponent()}</div>
      </NavigationItem>
    );
  }

  private renderComponent() {
    const { data: { imageUrls, fixedAspectRatio = true, asPicture = false } } = this.props;
    const isGallery = imageUrls.length > 1;

    if (this.props.displayMode === 'detail') {
      return (
        <WithLightbox images={this.getLightboxImages()}>
          {this.renderImage(imageUrls[0], fixedAspectRatio, isGallery)}
        </WithLightbox>
      );
    }

    if (asPicture) {
      return this.renderAsPicture();
    }

    return this.renderImage(imageUrls[0], fixedAspectRatio, isGallery);
  }

  private renderImage(url: string, fixedAspectRatio: boolean, isGallery: boolean) {
    const { postId } = this.props;
    if (!fixedAspectRatio) {
      return (
        <>
          <img alt={postId} className={`${this.cls}__img`} src={url}/>
          {this.renderGalleryIcon(isGallery)}
        </>
      );
    }

    return (
      <div className={`${this.cls}__img-aspectratio-outer`}>
        <div className={`${this.cls}__img-aspectratio-inner`}>
          <img alt={postId} className={`${this.cls}__img`} src={url} />
        </div>
        {this.renderGalleryIcon(isGallery)}
      </div>
    );
  }

  private renderAsPicture() {
    const { data, postId } = this.props;
    const mobileSize = `(max-width: ${STYLE_CONST_3_COLUMNS_REM})`;

    return (
      <div className={`${this.cls}__img-aspectratio-outer`}>
        <div className={`${this.cls}__img-aspectratio-inner`}>
          <picture>
            { data.imageUrlsSquareSmall
              ? <source srcSet={data.imageUrlsSquareSmall[0]} media={mobileSize} />
              : null }
            <img alt={postId} className={`${this.cls}__img`} src={data.imageUrls[0]} />
          </picture>
        </div>
      </div>
    );
  }

  private handleRef(ref: HTMLDivElement) {
    if (!ref) {
      return;
    }

    ref.onclick = (ev: MouseEvent) => {
      ev.preventDefault();
    };
  }

  private getLightboxImages() {
    const { data: { imageUrls, originalImages }, settings } = this.props;
    const images = originalImages && originalImages.length ? originalImages : imageUrls;
    return settings.showGallery ? images : [images[0]];
  }

  private renderGalleryIcon(isGallery: boolean) {
    return isGallery ? (
      <Icon
        className={`${this.cls}__gallery-icon`}
        name={'slider'}
        description={textResources.screenReaderText.photoGallery}
      />) : null;
  }
}
