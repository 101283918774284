// lib
import * as React from 'react';

// components
import ItemSegmentBase, {
  itemSegmentCls,
  SMALL_ITEM_SEGMENT,
} from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink, { IProps as ILinkProps } from 'src/components/item_segment/base/item_segment_link';
import SegmentIcon, {
  IProps as IItemSegmentIconProps,
} from 'src/components/item_segment/parts/segment_icon/segment_icon';
import SegmentRightArrow from 'src/components/item_segment/parts/segment_right_arrow/segment_right_arrow';
import SegmentText from 'src/components/item_segment/parts/segment_text/segment_text';

interface IProps {
  label: string;
  description?: string;
  icon?: IItemSegmentIconProps;
  navigation: ILinkProps;
}

const cls = itemSegmentCls + 'location-picker';

/*
  A Wrapper for ItemSegment for Location Picker and Popular places
*/
const LocationPickerItemSegment: React.FC<IProps> = ({ description, label, navigation, icon }) => (
  <ItemSegmentBase className={cls} height={SMALL_ITEM_SEGMENT}>
    <ItemSegmentLink {...navigation}>
      {icon && <SegmentIcon name={icon.name} color={icon.color} marginRight={32} size={24}/>}
      <SegmentText label={label} labelSize='small' description={description} />
      <SegmentRightArrow />
    </ItemSegmentLink>
  </ItemSegmentBase>
);

export default LocationPickerItemSegment;
