// interfaces / constants
import { IAction } from 'src/actions/';
import { ICategoryType } from 'src/api/interfaces/requests';

// classes / components
import api from 'src/api/';

// helpers
export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_REQUEST_SUCCESS = 'CATEGORIES_REQUEST_SUCCESS';
export const CATEGORIES_SET = 'CATEGORIES_SET';

export const fetchCategories = (model: ICategoryType): IAction => ({
  payload: api.category.list(model),
  type: CATEGORIES_REQUEST,
});
