/* mpm module imports */
import classNames from 'classnames';
import * as React from 'react';

/* app specific imports */
import Icon from 'src/components/forms/icon/icon';
import { CLASS_PREFIX } from 'src/constants/';

/* styling */
import './native_select.scss';

const cls: string = CLASS_PREFIX + 'nativeselect';
const clsLabel = `${cls}--label`;
const clsSelect = classNames(cls, `${cls}--select`);
const clsOption = classNames(cls, `${cls}--option`);
const clsIcon = classNames(cls, `${cls}--icon`);

interface IOptions {
  /* label to show */
  label: string;
  /* value to return */
  value: string;
}

interface IProps {
  /* value that should be preselected */
  selected?: string;
  /* callback when selecting a value */
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  /* an array with options to render */
  options: IOptions[];
  /* additional className to at to the root (label) element */
  className?: string;
  name: string;
}

/**
 * a native select-box with minimal styling
 */
const NativeSelect: React.FC<IProps> = ({ selected, onChange, options, className, name, ...props }) => (
  <label className={classNames(className, cls, clsLabel)} {...props}>
    <select className={clsSelect} onChange={onChange} value={selected} name={name}>
      { options.map(({ label, value }) =>
        (<option key={value} value={value} className={clsOption}>{label}</option>),
      )}
    </select>
    <Icon className={clsIcon} name={'caret-down'} />
  </label>
);

/* set displayname to not have a component without a name */
NativeSelect.displayName = 'NativeSelect';

export default NativeSelect;
