import { IWindowJsEnv } from 'src/interfaces/index';
import { airbrakeClient } from 'src/utils/reporting/airbrake-client';

const _window = window as unknown as IWindowJsEnv;
// Use this function if you just want to report an error to Airbrake (see below if you have special needs).
// Parameters:
//   error: Should be an Error-object. Other values are either converted into errors or discarded (if null or undefined)
//   params: An object that contains additional information about the error (optional)
//   context: An object that contains additional information about the context the error occured in (optional)

export const reportError = (
  error: any,
  params?: object,
  context: object = { component: 'client' },
): void => {
  reportErrorWithPromise(error, params, context).then().catch((_err) => { /* nothing */ });
  logToDevConsole('%c Airbrake reported:', 'background: #c0392b; color: black', error, params, context);
};

// Use this function if you want filter out non-error values and only report actual Error-objects to Airbrake.
// For example: when catching the result of a API-Wrapper function.
// Return value: true, if the error has been reported, or false, if nothing was reported.

export const reportOnlyErrorObject = (
  error: any,
  params?: object,
  context: object = { component: 'client' },
): boolean => {
  if (!(error instanceof Error)) {
    // Activate the following line to show filtered errors in the console
    // console.log(error);
    return false;
  }
  reportError(error, params, context);
  return true;
};

// Use this function when you want a guarantee that airbrake finished reporting an error
// For example: before you unload a page.
// Return value: Airbrake-object, if the error has been reported, or false, if nothing was reported.

export const reportErrorWithPromise = (
  error: any, // This can actually be anything
  params?: object,
  context: object = { component: 'client' },
): Promise<any | false> => {
  // Don't report errors that have been reported already
  // Don't report null or undefined errors (might come from the global error handler, see #3988)
  if (error === null || error === undefined || error.alreadyReported) {
    return Promise.reject(false);
  }
  // Ensure that we always report a real Error object to get a stack-trace
  if (!(error instanceof Error)) {
    if (typeof error === 'string') {
      // Strings are converted into an error-message
      error = new Error(error);
    } else {
      // Other non-error errors are converted into an unspecified error
      params = { ...params, value: error };
      error = new Error('unspecified error');
    }
  }
  // Mark the error as already reported and report it
  error.alreadyReported = true;

  return airbrakeClient.notify({
    context,
    error,
    params,
  });
};

interface IBrowserUpdateObject {
  api?: number;
  insecure?: boolean;
  mobile?: boolean;
  notified?: boolean;
  unsupported?: boolean;
}

// type guard for browser-update.org global variable
const isBrowserUpdateObject = (buoop: any): buoop is IBrowserUpdateObject => (
  !!buoop &&
  typeof buoop === 'object' &&
  // we do NOT check for 'notified' here, as this property is added only after the browser-update.org script ran
  'api' in buoop &&
  'required' in buoop
);

// Use this function, if you need to inform the developers about something.

export const logToDevConsole = (...args: any): void => {
  const w = (window as unknown as IWindowJsEnv);
  if (w.js_env && w.js_env.env === 'development') {
    // eslint-disable-next-line no-console
    console.log(...args);
  }
};

// Our central error reporting function does some extra stuff, before it reports an error to Airbrake.
// Therefore we pass any error that reaches the global handler to reportError().
window.addEventListener('error', (evt) => {
  try {
    reportError(evt.error);
  } catch (exception) {
    // If anything goes wrong in the global error handler we try to notify Airbrake directly as a last resort.
    airbrakeClient.notify({
      context: { component: 'client' },
      error: new Error('Exception in global error handler'),
      params: { error: evt.error, exception },
    }).catch((_err) => { /* nothing yet */ });
  }
}, false);

// Don't report to Airbrake if the browser is unsupported
//
// Instead of implementing our own browser-detection, we use the results from
// the browser-update.org script to detect whether a browser is supported or
// not.

if (_window.$buoop && isBrowserUpdateObject(_window.$buoop)) {
  let browserUpdate = _window.$buoop;
  // TODO: the real type of this is `Notice[]` (`Notice` being an interface imported from 'airbrake-js' package),
  //       but we cannot use this as long as we cannot import 'airbrake-js', see top of  file
  const postponedNotices: any[] = [];

  // The unsupported-browser-filter might postpone notices that occur before the
  // browser-update.org script has been loaded completely. This event-handler
  // takes this postpones notices and tries to send them again after the
  // browser-update.org script has been loaded.
  window.addEventListener('browserUpdateLoaded', () => {
    // If browserUpdate.notified is still undefined here, then browser-update.org
    // has changed its interface.
    if (browserUpdate.notified === undefined) {
      reportError('browser-update.org interface changed ($buoop.notified)', browserUpdate);
      // We need browserUpdate.notified to be true to be able to report the
      // interface-change via AirBrake, so we set it here explicitly. Re-defining
      // the whole browserUpdate variable avoids changes in the global
      // window.$buoop object, which might cause unwanted side-effect otherwise.
      browserUpdate = { notified: true };
    }
    postponedNotices.map((notice) => {
      airbrakeClient.notify({
        context: notice.context,
        error: notice.errors[0],
        params: notice.params,
      }).catch();
    });
  });

  airbrakeClient.addFilter((notice) => {
    // The browser-update.org script waits until the page is loaded before it
    // detects whether a browser is supported or not. Therefore we have to
    // postpone notices that occur before the page has been loaded. See line 96
    // for details.
    if (browserUpdate.notified === undefined) {
      postponedNotices.push(notice);
      return null;
    }
    // browserUpdate.notified equals true if the browser-update.org script is showing
    // or has shown an outdated-browser-message to the user. This is the most reliable
    // way to determine whether a browser is supported or not. For details see:
    // https://gitlab.naymspace.de/lokalportal/lokalportal/merge_requests/3054#note_164595
    if (browserUpdate.notified) {
      return null;
    }
    return notice;
  });
// If the browserUpdate is undefined, then browser-update.org has changed its
// interface.
} else {
  // `reportError` might not be available in certain environments (e.g. Jest),
  // so we have to check wheter it is available before we call it.
  reportError && reportError('browser-update.org interface changed ($buoop)');
}
