import { PromiseMiddlewareActions } from 'src/actions/index';
import api from 'src/api';

export const NEWS_FEED_GET_HIGHLIGHTS = 'NEWS_FEED_GET_HIGHLIGHTS';
export const NEWS_FEED_GET_HIGHLIGHTS_PENDING = 'NEWS_FEED_GET_HIGHLIGHTS_PENDING';
export const NEWS_FEED_GET_HIGHLIGHTS_SUCCESS = 'NEWS_FEED_GET_HIGHLIGHTS_SUCCESS';
export const NEWS_FEED_GET_HIGHLIGHTS_ERROR = 'NEWS_FEED_GET_HIGHLIGHTS_ERROR';

const PROFILES_LIST_NEWS_FEED_HIGHLIGHTS = 'profilesListNewsFeedHighlights';

export const getNewsFeedHighlights = () => ({
  meta: {
    storeKey: PROFILES_LIST_NEWS_FEED_HIGHLIGHTS,
  },
  payload: api.newsFeedHighlights.get(),
  type: NEWS_FEED_GET_HIGHLIGHTS,
} as const);

export type NewsFeedHighlightsAction = PromiseMiddlewareActions<
  typeof NEWS_FEED_GET_HIGHLIGHTS_PENDING,
  typeof NEWS_FEED_GET_HIGHLIGHTS_ERROR,
  typeof NEWS_FEED_GET_HIGHLIGHTS_SUCCESS,
ReturnType<typeof getNewsFeedHighlights>
>;
