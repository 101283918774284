// interfaces
import * as Response from 'src/api/interfaces/responses/follow';
import { IFollow } from 'src/interfaces/follow';

// decoders
import { $profile } from 'src/api/decoders/profile';

const follow = (json: Response.IFollow): IFollow => ({
  followable: $profile(json.followable),
  follower: $profile(json.follower),
  id: json.identifier,
  notification: json.notification,
});

export const $follow = (json: Response.IFollowResponse): IFollow =>
  follow(json.data);
