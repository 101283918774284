// interfaces / constants
import { IAction, PromiseMiddlewareActions } from 'src/actions/';
import { REACTION_NAME_OLD_HEART } from 'src/constants/reactions';
import { Id } from 'src/interfaces';
import { ReactionName, IReactions } from 'src/interfaces/reactions';

// classes / components
import api, { IReactionUpdateConfig } from 'src/api/';

// const
export const COMMENT_UPDATE_REACTION = 'COMMENT_UPDATE_REACTION';
export const COMMENT_UPDATE_REACTION_SUCCESS = 'COMMENT_UPDATE_REACTION_SUCCESS';
export const COMMENT_UPDATE_REACTION_PENDING = 'COMMENT_UPDATE_REACTION_PENDING';
export const COMMENT_UPDATE_REACTION_ERROR = 'COMMENT_UPDATE_REACTION_ERROR';

export const FEED_UPDATE_REACTION = 'FEED_UPDATE_REACTION';
export const FEED_UPDATE_REACTION_SUCCESS = 'FEED_UPDATE_REACTION_SUCCESS';
export const FEED_UPDATE_REACTION_PENDING = 'FEED_UPDATE_REACTION_PENDING';
export const FEED_UPDATE_REACTION_ERROR = 'FEED_UPDATE_REACTION_ERROR';

interface IUpdateConfig extends IReactionUpdateConfig {
  commentId?: Id;
  oldType?: ReactionName;
  postId: Id;
}

interface IUpdateReactionsCommon {
  meta: IUpdateConfig;
}

export interface IUpdatePostReactionPending extends IUpdateReactionsCommon {
  type: typeof FEED_UPDATE_REACTION_PENDING;
}

export interface IUpdatePostReactionError extends IUpdateReactionsCommon {
  type: typeof FEED_UPDATE_REACTION_ERROR;
}

export interface IUpdatePostReactionSuccess extends IUpdateReactionsCommon {
  payload: IReactions;
  type: typeof FEED_UPDATE_REACTION_SUCCESS;
}

type UpdateActionType = typeof FEED_UPDATE_REACTION | typeof COMMENT_UPDATE_REACTION;
const update = ({ commentId, postId, path, bookmarkType, oldType }: IUpdateConfig, type: UpdateActionType) => {
  return {
    meta: {
      bookmarkType: bookmarkType,
      commentId,
      oldType,
      postId,
    },
    payload: api.reaction.update({ bookmarkType, path }),
    type,
  } as const;
};

export type FeedReactionAction = PromiseMiddlewareActions<
  typeof FEED_UPDATE_REACTION_PENDING,
  typeof FEED_UPDATE_REACTION_ERROR,
  typeof FEED_UPDATE_REACTION_SUCCESS,
ReturnType<typeof update>
>

export const updatePostReaction = (
  reaction: ReactionName,
  postId: string,
  updateUrl: string,
  oldReaction?: ReactionName,
): IAction => {
  const config: IUpdateConfig = {
    bookmarkType: reaction,
    oldType: oldReaction,
    path: updateUrl,
    postId,
  };

  return update(config, FEED_UPDATE_REACTION);
};

interface IUpdateReactionsCommon {
  meta: IUpdateConfig;
}

export interface IUpdateCommentReactionSuccess extends IUpdateReactionsCommon{
  payload: IReactions;
  type: typeof COMMENT_UPDATE_REACTION_SUCCESS;
}

export interface IUpdateCommentReactionPending extends IUpdateReactionsCommon {
  type: typeof COMMENT_UPDATE_REACTION_PENDING;
}

export interface IUpdateCommentReactionError extends IUpdateReactionsCommon {
  type: typeof COMMENT_UPDATE_REACTION_ERROR;
}

export const updateCommentReaction = (
  postId: string,
  commentId: string,
  updateUrl: string,
): IAction => {
  const config: IUpdateConfig = {
    bookmarkType: REACTION_NAME_OLD_HEART,
    commentId,
    path: updateUrl,
    postId,
  };

  return update(config, COMMENT_UPDATE_REACTION);
};
