// libs
import {
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  Store,
} from 'redux';
import { createPromise } from 'redux-promise-middleware';
import thunk from 'redux-thunk';

// interfaces / constants
import { IRootState } from 'src/reducers/interface';

// classes / components
import { setStore } from 'src/store/store-holder';
import errorMiddleware from 'src/utils/error_middleware';

// helpers
import getRootReducer from 'src/reducers/';
import { setAirbrakeStore } from 'src/utils/reporting/airbrake-client';

let store: Store<IRootState>;
const environment: any = window;

const composeEnhancers = environment.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// configure redux-promise-middleware with the suffixes we use for our
// actions, like 'FEED_REQUEST_PENDING', 'FEED_REQUEST_SUCCESS' and
// 'FEED_REQUEST_ERROR'
function _promiseMiddleware(): Middleware {
  return createPromise({
    promiseTypeSuffixes: ['PENDING', 'SUCCESS', 'ERROR'],
  });
}

function _getMiddleware() {
  const middleware = [
    errorMiddleware,
    _promiseMiddleware(),
    thunk,
  ];

  return middleware;
}

export function configureStore(initialState?: object): Store<IRootState> {
  const store: Store<IRootState> = createStore(
    getRootReducer(),
    initialState,
    composeEnhancers(
      applyMiddleware(..._getMiddleware())),
  );

  setAirbrakeStore(store);

  return store;
}

export function getStore(initialState?: object): Store<IRootState> {
  if (!store) {
    store = configureStore(initialState);
    setStore(store);
  }

  return store;
}

export default getStore;
