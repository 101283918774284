// libs
import React from 'react';
import { createPortal } from 'react-dom';

function withPortal<T extends object>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  return class ComponentWithPortal extends React.Component<T> {
    public static displayName = `withPortal(${displayName})`;

    public render() {
      const portalRoot = document.getElementById('lopo-react-portal');
      if (!portalRoot) {
        return null;
      }

      return createPortal((
        <WrappedComponent {...this.props} />
      ), portalRoot);
    }
  };
}

export default withPortal;
