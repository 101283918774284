// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import './item_segment_base.scss';

export const SMALL_ITEM_SEGMENT = 'small';
export const BIG_ITEM_SEGMENT = 'big';

export type ItemSegmentHeight = typeof SMALL_ITEM_SEGMENT | typeof BIG_ITEM_SEGMENT;

interface IProps {
  height: ItemSegmentHeight;
  className: string;
  noBorder?: boolean;
  title?: string;
}

export const itemSegmentCls = CLASS_PREFIX + 'item-segment-';
const cls: string = itemSegmentCls + 'base';

/*
  This component should only be used in an Item Segment Layout file
  And contain one or several Item Segment Links as children
 */

const ItemSegmentBase: React.FC<IProps> = ({ children, height, className, noBorder, title }) => {
  const classes = {
    [className]: true,
    [cls]: true,
    [cls + '--' + height]: true,
    [cls + '--no-border']: noBorder,
  };

  return <div className={classNames(classes)} title={title}>{children}</div>;
};

export default ItemSegmentBase;
