// libs
import * as React from 'react';

// interfaces / constants
import { IGroupNotification } from 'src/interfaces/notification';

// classes / components
import Notification, { clsText, clsHighlightedText } from 'src/components/notifications/notification';

// helpers
import { textResources } from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';

// images
import * as overlayInactive from 'src/components/notifications/badges/group@2x.png';
import * as overlayActive from 'src/components/notifications/badges/group_active@2x.png';

const labels = textResources.notification;

export interface IProps extends IGroupNotification {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const GroupNotification: React.FC<IProps> = ({
  contextTeaser,
  createdAt,
  groupName,
  initiator,
  isRead,
  onClick,
}) => {
  return (
    <Notification
      avatarImage={initiator?.image}
      badgeImageRead={overlayInactive}
      badgeImageNotRead={overlayActive}
      footer={DateFormatter.asTimeAgo(createdAt)}
      initiatorName={initiator?.name}
      isRead={isRead}
      onClick={onClick}
    >
      <span className={clsText}>{' ' + labels.notificationGroupPostStart}</span>
      <strong className={clsHighlightedText}>{`„${groupName}“`}</strong>
      <span className={clsText}>{labels.notificationGroupPostEnd}</span>
      <strong className={clsHighlightedText}>{`„${contextTeaser}“`}</strong>
    </Notification>
  );
};

export default GroupNotification;
