import React, { FunctionComponent } from 'react';

import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import './segment_user_actions.scss';

const cls = itemSegmentCls + 'user-actions';

const SegmentUserActions: FunctionComponent = ({ children }) => {
  return <div className={cls}>{ children }</div>;
};

export default SegmentUserActions;
