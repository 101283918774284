// Type guards for INotification objects
// see https://www.typescriptlang.org/docs/handbook/advanced-types.html#user-defined-type-guards

import api from 'src/api/';
import { ICommentItem, IDeletedComment } from 'src/interfaces/comments';
import { ILinkPreview } from 'src/interfaces/post_ingredients';
import { REGEXP_URL } from 'src/utils/pattern/pattern'; ;

export const isDeletedComment = (comment: ICommentItem): comment is IDeletedComment => {
  return comment.deletedAt !== null;
};

export const detectLink = (text: string): PromiseLike<ILinkPreview | undefined> => {
  return new Promise((resolve, reject) => {
    const urlsFoundInText = text.match(REGEXP_URL);
    if (urlsFoundInText) {
      api.oglink.get(urlsFoundInText[0]).then((res) => resolve(res || undefined), reject);
    } else {
      resolve(undefined);
    }
  });
};
