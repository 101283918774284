// libs
import * as React from 'react';
import { StaticPagesConfig } from 'src/components/static_pages/static_pages_feature_flag/model';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import {
  FRONTEND_DATA_AGB_PATH,
  FRONTEND_DATA_BLOG_PATH,
  FRONTEND_DATA_FAQ_PATH,
  FRONTEND_DATA_IMPRESSUM_PATH,
  FRONTEND_DATA_LEARN_MORE_PATH,
  FRONTEND_DATA_PROTECTION_PATH,
} from 'src/constants/urls';
import { getConfigValue } from 'src/utils/config/config';

// classes / components
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import { textResources } from 'src/lang/de';
import './static_pages.scss';

const cls = CLASS_PREFIX + 'static-pages';
const labels = textResources.shared;

export default class StaticPages extends React.PureComponent {
  public render() {
    const { learnMore, blog, faq, imprint, privacy, terms } = labels;
    const staticPagesConfig = getConfigValue<StaticPagesConfig>('staticPages');
    return (
      <div className={cls}>
        {staticPagesConfig?.enableAboutUs && <NavigationItem
          className={cls + '__link'}
          url={FRONTEND_DATA_LEARN_MORE_PATH}
          target='_blank'> {learnMore} </NavigationItem>}
        {staticPagesConfig?.enableBlog && <NavigationItem
          className={cls + '__link'}
          url={FRONTEND_DATA_BLOG_PATH}
          target='_blank'> {blog} </NavigationItem>}
        {staticPagesConfig?.enableFaq && <NavigationItem
          className={cls + '__link'}
          url={FRONTEND_DATA_FAQ_PATH}
          target='_blank'> {faq} </NavigationItem>}
        <NavigationItem
          className={cls + '__link'}
          url={FRONTEND_DATA_PROTECTION_PATH}
          target='_blank'> {privacy} </NavigationItem>
        <NavigationItem
          className={cls + '__link'}
          url={FRONTEND_DATA_AGB_PATH}
          target='_blank'> {terms} </NavigationItem>
        <NavigationItem
          className={cls + '__link'}
          url={FRONTEND_DATA_IMPRESSUM_PATH}
          target='_blank'> {imprint} </NavigationItem>
      </div>
    );
  }
}
