// libs
import * as React from 'react';

import { CLASS_PREFIX } from 'src/constants/';
import './title.scss';

const cls: string = CLASS_PREFIX + 'title';

/**
 * (header) back button component
 */
const Title: React.FC = ({ children, ...props }) => (
  <div className={cls} {...props}>
    {children}
  </div>
);

/* set displayname to not have a component without a name */
Title.displayName = 'Title';

export default Title;
