// libs
import { connect } from 'react-redux';

// interfaces / constants
import { IComment } from 'src/interfaces/comments';
import { IProfile } from 'src/interfaces/profile';
import { IRootState } from 'src/reducers/interface';

// classes / components
import Comment from 'src/components/post/shared/comments/comment';

// helpers

interface IOwnProps extends IComment {
  deleteComment: (commentId: string, updateUrl: string) => void;
  editComment: (comment: IComment) => void;
  likeToggle: (commentId: string, updateUrl: string) => void;
}

interface IMapStateToProps {
  author?: IProfile;
}

export type IProps = IMapStateToProps & IOwnProps;

const mapStateToProps = ({ profiles }: IRootState, { authorId }: IOwnProps): IMapStateToProps => {
  return {
    author: profiles[authorId]?.profile,
  };
};

export default connect(mapStateToProps)(Comment);
