// interfaces
import {
  FetchFeedThunkAction,
  INewsfeedRequestParams,
} from 'src/actions/feed/feed_interfaces';

// constants
import { FEED_TYPE_NEWS, FEED_WITH_GROUPED_POSTS_DEFAULT_PER_PAGE } from 'src/constants/feed';

// actions
import { fetchFeed, setFeedHash } from 'src/actions/feed/feed';

// helpers
import {
  addBoundingBox,
  hasBoundingBox,
} from 'src/actions/feed/feed_utils';
import api from 'src/api/';

const fetchNewsFeed = fetchFeed<INewsfeedRequestParams>(
  FEED_TYPE_NEWS,
  api.newsFeed.get,
);

export const fetchNewsFeedPage = (page: number = 1): FetchFeedThunkAction => {
  return async(dispatch, getState): Promise<void> => {
    const state = getState();

    if (!hasBoundingBox(state)) {
      return;
    }

    dispatch(fetchNewsFeed({
      perPage: FEED_WITH_GROUPED_POSTS_DEFAULT_PER_PAGE,
      page,
      ...addBoundingBox(state),
    }));
  };
};

export const resetNewsfeed = () => setFeedHash(FEED_TYPE_NEWS, '');
