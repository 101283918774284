// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import './feature_blocker.scss';

const cls: string = CLASS_PREFIX + 'feature-blocker';

interface IProps {
  active: boolean;
  onClick: (e: React.SyntheticEvent<HTMLDivElement>) => void;
  className?: string;
}

const FeatureBlocker: React.FC<IProps> = ({ active, children, onClick, className }) => {
  if (active) {
    return (
      <div className={classNames(className, cls)} onClick={onClick}>
        {children}
      </div>
    );
  }
  return (
    <>{children}</>
  );
};

export default FeatureBlocker;
