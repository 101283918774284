import { Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';

// interfaces
import { IAction } from 'src/actions';
import { ProfileType, IProfilePayload } from 'src/interfaces/profile';
import { IRootState } from 'src/reducers/interface';

// constants
import {
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  INTERMEDIATE_PROFILE_TYPE,
  NO_USER_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  PRIVATE_PROFILE_TYPE,
  REPORTER_PROFILE_TYPE,
} from 'src/constants/profile';

// helpers
import api from 'src/api/';
import { Id } from 'src/interfaces';
import { idFromGlobalFriendlyIdentifier } from 'src/utils/profile';
import { reportError } from 'src/utils/reporting/report-errors';

const PROFILE_BLACKLIST_REQUEST = 'PROFILE_BLACKLIST_REQUEST';

const PROFILE_DETAILS_REQUEST = 'PROFILE_DETAILS_REQUEST';
export const PROFILE_DETAILS_REQUEST_PENDING = 'PROFILE_DETAILS_REQUEST_PENDING';
export const PROFILE_DETAILS_REQUEST_SUCCESS = 'PROFILE_DETAILS_REQUEST_SUCCESS';
export const PROFILE_GRAYLIST_REQUEST_SUCCESS = 'PROFILE_GRAYLIST_REQUEST_SUCCESS';

export interface IProfileDetailsParams {
  profileType: ProfileType;
  profileId: string;
}

export interface IProfileDetailsActionPending {
  type: typeof PROFILE_DETAILS_REQUEST_PENDING;
}

export interface IProfileGrayListSuccess {
  type: typeof PROFILE_GRAYLIST_REQUEST_SUCCESS;
  meta: {
    graylistPath?: string;
    id: Id;
  };
}

export interface IProfileDetailsActionSuccess {
  type: typeof PROFILE_DETAILS_REQUEST_SUCCESS;
  payload: IProfilePayload;
}

export const fetchProfile =
  (profileType: ProfileType, profileId: string): ThunkAction<void, IRootState, {}, IAction> => {
    return (dispatch) => {
      dispatch({
        payload: getProfileDetails({ profileId, profileType }),
        type: PROFILE_DETAILS_REQUEST,
      });
    };
  };

const getProfileDetails = ({ profileType, profileId }: IProfileDetailsParams): any => {
  const endpoint = mapAuthorTypeToEndpoint(profileType);
  if (!endpoint) { return Promise.reject('invalid profile type'); }

  return endpoint.get(idFromGlobalFriendlyIdentifier(profileId));
};

interface IProfileEndpint {
  get: (id: string) => Promise<IProfilePayload | null>;
}

const mapAuthorTypeToEndpoint = (authorType: ProfileType): IProfileEndpint | undefined => {
  switch (authorType) {
    case AUTHORITY_PROFILE_TYPE:
      return api.authorityProfile;
    case BLOG_PROFILE_TYPE:
      return api.blogProfile;
    case INTERMEDIATE_PROFILE_TYPE:
      return undefined;
    case NO_USER_PROFILE_TYPE:
      return undefined;
    case PRESS_PROFILE_TYPE:
      return api.pressProfile;
    case PRIVATE_PROFILE_TYPE:
      return api.privateProfile;
    case REPORTER_PROFILE_TYPE:
      return api.reporterProfile;
    default:
      reportError('unknown authorType in mapAuthorTypeToEndpoint!',
        {
          authorType,
        },
      );
      return undefined;
  }
};

export const blacklistProfile = (path: string): IAction => ({
  payload: api.blacklist.update(path),
  type: PROFILE_BLACKLIST_REQUEST,
});

export const graylistProfile = (path: string, id: Id, expiresOn: Date) =>
  (dispatch: Dispatch<IAction>) => {
    return api.graylist.update(path, expiresOn)
      .then(() => dispatch({
        meta: {
          graylistPath: path,
          id,
        },
        type: PROFILE_GRAYLIST_REQUEST_SUCCESS,
      }));
  };
