// libs
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps, match } from 'react-router-dom';
import { IAppState } from 'src/interfaces/app-state';

// interfaces
import { ILocationShape, IBoundingBox, IPosition } from 'src/interfaces/location';
import { IRootState } from 'src/reducers/interface';

// constants
import {
  RESOURCE_LINK_PATH_CONVERSATION,
  RESOURCE_LINK_PATH_EVENT,
  RESOURCE_LINK_PATH_EVENTS,
  RESOURCE_LINK_PATH_EVENTS_TOP_PER_CATEGORY,
  RESOURCE_LINK_PATH_NEWSFEED,
  RESOURCE_LINK_PATH_POST,
  RESOURCE_LINK_PATH_AUTHORITY_PROFILES,
  RESOURCE_LINK_PATH_BLOG_PROFILES,
  RESOURCE_LINK_PATH_PRESS_PROFILES,
  RESOURCE_LINK_PATH_PRIVATE_PROFILES, RESOURCE_LINK_PATH_GROUP,
} from 'src/constants/resource_links';
import {
  NEWS_FEED_PARTIAL,
  EVENTS_FEED_PARTIAL,
  FRONTEND_EVENT_PATH,
  FRONTEND_POST_PATH,
  FRONTEND_CONVERSATION_PATH,
  FRONTEND_PROFILES_DIRECTORY,
  SHAPE_SPONSORS, FRONTEND_GROUP_OVERVIEW_PATH,
} from 'src/constants/urls';
import { ProfileType } from 'src/interfaces/profile';
import { IResourceLink } from 'src/interfaces/resource_links';

// helpers
import { textResources } from 'src/lang/de';
import { getConfigValue } from 'src/utils/config/config';
import { reportError } from 'src/utils/reporting/report-errors';
import {
  replaceLocationSlugFromPath,
  navigateToPositionURL,
  UrlUtils,
} from 'src/utils/url/url';

interface IMapStateToProps {
  appStateLocationSlug?: string;
  userLocationSlug?: string;
}

type IProps = IMapStateToProps & RouteComponentProps;

const getSlug = (appState: IAppState) => getConfigValue('featureFlags.location.customLocationSlug') ||
  appState.locationShape?.slug;

const mapStateToProps = ({ user, appState }: IRootState): IMapStateToProps => {
  const slug = getSlug(appState);
  return ({
    appStateLocationSlug: slug,
    userLocationSlug:
      user.profiles[user.selectedProfileId].address &&
      user.profiles[user.selectedProfileId].address!.locationShape.slug,
  });
};

export interface IURLContext {
  getEventsfeedRoute: (filter?: string) => string;
  getNewsfeedRoute: (filter?: string) => string;
  getProfilesDirectoryRoute: () => string;
  getResourceLinkRoute: (resourceLink: IResourceLink, filterName?: string) => string;
  getShapeSponsorsListRoute: () => string;
  navigateToShape: (match: match, locationShape: ILocationShape) => void;
  navigateToPositionURL: (match: match, position: IPosition, boundingBox?: IBoundingBox) => Promise<Object>;
}

export const URLContext = React.createContext<IURLContext>({
  getEventsfeedRoute: () => '/',
  getNewsfeedRoute: () => '/',
  getProfilesDirectoryRoute: () => '/',
  getResourceLinkRoute: () => '/',
  getShapeSponsorsListRoute: () => '/',
  navigateToPositionURL: () => new Promise(() => {}),
  navigateToShape: () => { /* will be populated with corect behavior */ },
});

export const URLConsumer = URLContext.Consumer;

const URLProvider: React.FC<IProps> = ({ children, appStateLocationSlug, history }) => {
  const getNewsfeedRoute = (filter?: string) => {
    if (appStateLocationSlug) {
      return `/${appStateLocationSlug}${NEWS_FEED_PARTIAL}${filter ? `/${filter.toLocaleLowerCase()}` : ''}`;
    }
    return '/';
  };
  const getEventsfeedRoute = (filter?: string) => {
    if (appStateLocationSlug) {
      return `/${appStateLocationSlug}${EVENTS_FEED_PARTIAL}${filter ? `/${filter.toLocaleLowerCase()}` : ''}`;
    }
    return '/';
  };
  const getProfilesDirectoryRoute = () => {
    if (appStateLocationSlug) {
      return `/${appStateLocationSlug}${FRONTEND_PROFILES_DIRECTORY}`;
    }
    return '/';
  };
  const getShapeSponsorsListRoute = () => {
    if (appStateLocationSlug) {
      return `/${appStateLocationSlug}${SHAPE_SPONSORS}`;
    }
    return '/';
  };

  const getResourceLinkRoute = (resourceLink: IResourceLink, filterName?: string): string => {
    const { path, params } = resourceLink;

    switch (path) {
      case RESOURCE_LINK_PATH_EVENTS_TOP_PER_CATEGORY:
        return getEventsfeedRoute();

      case RESOURCE_LINK_PATH_EVENTS:
        if (filterName) {
          if (filterName === textResources.shared.forMe) {
            return getEventsfeedRoute();
          }
          return getEventsfeedRoute(filterName.toLocaleLowerCase());
        }
        reportError(
          'an events path was given to the resourcelink translator, but no filterName added.',
          { filterName, resourceLink });
        return getEventsfeedRoute();

      case RESOURCE_LINK_PATH_NEWSFEED:
        return getNewsfeedRoute();

      case RESOURCE_LINK_PATH_POST:
        if (params.id) {
          return `${FRONTEND_POST_PATH}/${params.id}`;
        }
        return getNewsfeedRoute();

      case RESOURCE_LINK_PATH_EVENT:
        if (params.id) {
          return `${FRONTEND_EVENT_PATH}/${params.id}`;
        }
        return getNewsfeedRoute();

      case RESOURCE_LINK_PATH_CONVERSATION:
        if (params.id) {
          return `${FRONTEND_CONVERSATION_PATH}${params.id}`;
        }
        return FRONTEND_CONVERSATION_PATH;
      case RESOURCE_LINK_PATH_AUTHORITY_PROFILES:
      case RESOURCE_LINK_PATH_BLOG_PROFILES:
      case RESOURCE_LINK_PATH_PRESS_PROFILES:
      case RESOURCE_LINK_PATH_PRIVATE_PROFILES:
        const profileType = path.slice(0, -1) as ProfileType;
        if (params.id) {
          return UrlUtils.getProfileFrontendPath(params.id, profileType);
        }
        return getNewsfeedRoute();
      case RESOURCE_LINK_PATH_GROUP: {
        if (params.id) {
          return UrlUtils.groupMembersPage(params.id);
        }
        return FRONTEND_GROUP_OVERVIEW_PATH;
      }
      default:
        reportError('not supported resouce link path, returning default to newsfeed', resourceLink);
        return getNewsfeedRoute();
    }
  };

  return (
    <URLContext.Provider value={{
      getEventsfeedRoute,
      getNewsfeedRoute,
      getProfilesDirectoryRoute,
      getResourceLinkRoute,
      getShapeSponsorsListRoute,
      navigateToPositionURL: (match, position, boundingBox): Promise<any> => {
        return navigateToPositionURL({
          boundingBox, history, match, position,
        });
      },
      navigateToShape: (match, locationShape) => {
        history.push(
          replaceLocationSlugFromPath(match, locationShape.slug),
          { locationShape },
        );
      },
    }} >
      {children}
    </URLContext.Provider>
  );
};

export default withRouter(connect<IMapStateToProps, never, RouteComponentProps>(
  mapStateToProps,
)(URLProvider));
