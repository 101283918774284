import { IWindowJsEnv } from 'src/interfaces';
import { getConfigValue } from 'src/utils/config/config';
const _window = window as unknown as IWindowJsEnv;

export const getPageTitle = (title: string, defaultTitle: string) => {
  const defaultAddress = getConfigValue('featureFlags.location.defaultAddress');
  return defaultAddress ? title : defaultTitle;
};

export const shouldShowLocationForInstance = (defaultValue?: boolean) => {
  const defaultAddress = getConfigValue('featureFlags.location.defaultAddress');
  return defaultAddress ? false : defaultValue;
};

export const getPageHeaderTitle = (
  pageHeaderTitleResolver: (...args: Array<string | number>) => string,
  pageHeaderTitleInResolver: (...args: Array<string | number>) => string,
  ...args: Array<string | number>
) => {
  const defaultAddress = getConfigValue('featureFlags.location.defaultAddress');
  if (defaultAddress) {
    const appName = _window.js_env.appName;
    return pageHeaderTitleInResolver(appName, ...args);
  } else {
    return pageHeaderTitleResolver(...args);
  }
};
