// lib
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import LandingPageContainer from 'src/containers/landing_page';

// constants & interfaces

// helpers
import { URLContext } from 'src/containers/url_provider/url_provider';
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';

const LandingPage: React.FC<RouteComponentProps> = () => {
  const user = useSelectUser();

  const { getNewsfeedRoute } = useContext(URLContext);

  usePageTracking({ user });

  return (
    <>
      <MetaTags />
      <LandingPageContainer getDefaultRedirectPath={getNewsfeedRoute} />
    </>
  );
};

export default LandingPage;
