import React, { useContext } from 'react';
import { useHistory } from 'react-router';

// components
import {
  canNotJoinGroups,
  canJoinGroup,
  groupsBlocked,
} from 'src/components/cta/cta_intermediate_options';

// constants & interfaces
import { FRONTEND_GROUP_OVERVIEW_PATH } from 'src/constants/urls';

// helpers
import { NO_PROFILE } from 'src/constants/user';
import ContentBlockerInfo from 'src/containers/smart_components/content_blocker_info/content_blocker_info';
import { URLContext } from 'src/containers/url_provider/url_provider';
import { IGroup } from 'src/interfaces/group';
import { textResources } from 'src/lang/de';

const labels = textResources.groups;

interface IProps {
  canJoinThisGroup?: boolean;
  canJoinGroups?: boolean;
  dispatchShowRegistrationModal: () => void;
  dispatchShowLoginModal: () => void;
  group: IGroup;
  joinGroup: (group: IGroup) => void;
}

const NotAllowedToSeePostsCTA: React.FC<IProps> = ({
  canJoinGroups,
  canJoinThisGroup,
  group,
  joinGroup,
}) => {
  const { getNewsfeedRoute } = useContext(URLContext);
  const history = useHistory();

  return (
    <ContentBlockerInfo
      customReasons={[
        {
          condition: !!canJoinThisGroup,
          content: canJoinGroup(joinGroup, group),
        },
        {
          condition: !canJoinGroups,
          content: groupsBlocked(() => history.push(getNewsfeedRoute())),
        },
        {
          condition: !canJoinThisGroup,
          content: canNotJoinGroups(() => history.push(FRONTEND_GROUP_OVERVIEW_PATH), group.name),
        },
      ]}
      customContents={{
        [NO_PROFILE]: {
          body: labels.ctaNotJoinedFeed.infoLoggedOut,
          bodyTitle: labels.ctaNotJoinedFeed.title,
          title: group.name,
        },
      }}
    />);
};

export default NotAllowedToSeePostsCTA;
