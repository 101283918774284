import { throttle } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
// interfaces
import { IMarkerContainingRecord } from 'src/interfaces/marker';
// helpers
import { IFeedType } from 'src//interfaces/feed';
import { useDispatchSetMarkers } from 'src/actions/app-state/app-state_hooks';
import { getMarkersFromMap, getIndexInViewportCenter } from 'src/actions/app-state/utils';
import { openGroup } from 'src/actions/feed/feed';
import { useEffectSkipFirstCycle } from 'src/hooks_shared/use_effect_on_update';
import { useScrollListener } from 'src/hooks_shared/use_event_listener';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import GoogleTagManager from 'src/utils/reporting/google-tag-manager';

export const useNextPageTracking = (currentPage: number): void => {
  const user = useSelectUser();
  const { pathname } = useLocation();

  const callback = useCallback(() => {
    if (currentPage > 1) {
      GoogleTagManager.pushPageNavigation(pathname, { user });
    }
  }, [currentPage, pathname, user]);

  useEffectSkipFirstCycle(callback, [currentPage]);
};

export const useMarkersInViewport = (
  ids: string[],
  record: IMarkerContainingRecord,
): void => {
  const setMarkers = useDispatchSetMarkers();
  const [prevIndex, setPrevIndex] = useState<number>(-1);

  const listener = useCallback(() => {
    const newIndex = getIndexInViewportCenter(ids);

    if (newIndex !== prevIndex) {
      setPrevIndex(newIndex);
      setMarkers(getMarkersFromMap(newIndex, ids, record));
    }
  }, [ids, prevIndex, setMarkers, record]);

  useEffectSkipFirstCycle(listener, [prevIndex, ids, record]);
  useScrollListener(throttle(listener, 50), { passive: true });
};

export const useOpenGroup = (feedType: IFeedType) => {
  const dispatch = useDispatch();

  return useCallback((index: number) => {
    dispatch(openGroup(feedType, index));
  }, [dispatch, feedType]);
};
