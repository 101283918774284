// lib
import * as React from 'react';

// constants/interfaces
import { IProps as IItemSegmentIconProps } from 'src/components/item_segment/parts/segment_icon/segment_icon';

// components
import ItemSegmentBase, { BIG_ITEM_SEGMENT, itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink, { IProps as ILinkProps } from 'src/components/item_segment/base/item_segment_link';
import SegmentAvatar, {
  IProps as IItemSegmentAvatarProps } from 'src/components/item_segment/parts/segment_avatar/segment_avatar';
import SegmentRoundIcon from 'src/components/item_segment/parts/segment_round_icon/segment_round_icon';
import SegmentText from 'src/components/item_segment/parts/segment_text/segment_text';

// helpers
import './post_create.scss';

interface IProps {
  label: string;
  description?: string;
  avatar?: IItemSegmentAvatarProps;
  icon?: IItemSegmentIconProps;
  navigation?: ILinkProps;
}

const cls = itemSegmentCls + 'post-create';

/*
  A Wrapper for ItemSegment for Create post
*/
const PostCreateItemSegment: React.FC<IProps> = ({ description, label, navigation, avatar, icon }) => (
  <ItemSegmentBase className={cls} height={BIG_ITEM_SEGMENT} noBorder>
    <ItemSegmentLink {...navigation}>
      {avatar && <SegmentAvatar imageUrl={avatar.imageUrl} />}
      {icon && <SegmentRoundIcon {...icon} isInverted />}
      <SegmentText label={label} labelSize='big' description={description} />
    </ItemSegmentLink>
  </ItemSegmentBase>
);

export default PostCreateItemSegment;
