// libs
import produce from 'immer';

// interfaces
import { IRelatedContentState } from 'src/interfaces/related_content';

// constants
import {
  IRelatedContentActionError,
  IRelatedContentActionPending,
  IRelatedContentActionSuccess,
  RELATED_CONTENT_REQUEST_ERROR,
  RELATED_CONTENT_REQUEST_PENDING,
  RELATED_CONTENT_REQUEST_SUCCESS,
} from 'src/actions/related_content';

export const INITIAL_STATE: IRelatedContentState = {
  hasError: false,
  isLoading: false,
  postIds: [],
  posts: {},
};

type Actions = IRelatedContentActionSuccess | IRelatedContentActionPending | IRelatedContentActionError;

const relatedContentReducer = (state = INITIAL_STATE, action: Actions): IRelatedContentState =>
  produce(state, (draft) => {
    switch (action.type) {
      case RELATED_CONTENT_REQUEST_PENDING:
        draft.hasError = false;
        draft.isLoading = true;
        draft.postIds = [];
        draft.posts = {};
        break;

      case RELATED_CONTENT_REQUEST_ERROR:
        draft.hasError = true;
        draft.isLoading = false;
        draft.postIds = [];
        draft.posts = {};
        break;

      case RELATED_CONTENT_REQUEST_SUCCESS:
        draft.hasError = false;
        draft.isLoading = false;
        draft.postIds = [];
        draft.posts = {};
        (action.payload.items).map((item) => {
          draft.posts[item.id] = item;
          draft.postIds.push(item.id);
        });
        break;
    }
  });

export default relatedContentReducer;
