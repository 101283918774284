// libs
import { flattenDeep } from 'lodash';
// interfaces / constants
import { IAction } from 'src/actions';
import { Id } from 'src/interfaces';
import { IFeeds, IFeedType, GroupedPosts, IPostsRecord, IGroupedPosts } from 'src/interfaces/feed';
import { IReaction, ReactionName } from 'src/interfaces/reactions';
import { INITIAL_FEEDS } from 'src/reducers/feed/feed';

// helpers
import { notUndefined } from 'src/utils';
import { logToDevConsole } from 'src/utils/reporting/report-errors';

export const flattenOpenGroup = (groups: GroupedPosts, groupIndex: number) => {
  return [
    ...groups.slice(0, groupIndex),
    ...groups[groupIndex].ids.map((id, i) => ({
      ids: [id],
      label: groups[groupIndex].label,
      slug: groups[groupIndex].slug && `${groups[groupIndex].slug}-${i}`,
    })),
    ...groups.slice(groupIndex + 1),
  ];
};

export const reportReducerError = (state: IFeeds, { type, payload }: IAction) => {
  // if we will call this function not async, it will break the reducer's flow of updating the app
  setTimeout(() =>
    logToDevConsole('%c Feed Reducer Error:', 'background: #c0392b; color: black', type, { payload, state }),
  0);
};

export const mergeDuplicatedGroups = (
  payload: GroupedPosts,
  groupedPosts: GroupedPosts,
): GroupedPosts => {
  if (!payload?.length) {
    return groupedPosts;
  }

  return payload.reduce((acc: GroupedPosts, { slug }, index) => {
    const duplicatedIndex = acc.findIndex(group => !!group.slug && group.slug === slug);

    if (duplicatedIndex >= 0) {
      acc[duplicatedIndex].ids.push(...payload[index].ids);

      return acc;
    }

    acc.push(payload[index]);

    return acc;
  }, [...groupedPosts]);
};

export const updateReactions = (reactions: IReaction[], typeToCompare?: ReactionName) => {
  return reactions.map((reaction: IReaction) => {
    if (reaction.own) {
      reaction.number -= 1;
      reaction.own = false;
    } else if (reaction.name === typeToCompare) {
      reaction.number += 1;
      reaction.own = true;
    }

    return reaction;
  });
};

// mutates draft
export const removeUnusedPosts = (draft: IFeeds) => {
  draft.posts = flattenDeep(Object
    .keys(draft.feeds)
    .map((key: IFeedType) => {
      const groups = [
        ...draft.feeds[key].groupedPosts,
        getNewsFeedHighlightsPosts(draft),
      ];
      return groups.map(({ ids }) => ids);
    }))
    .reduce((acc: IPostsRecord, id: Id) => {
      if (draft.posts[id]) {
        acc[id] = draft.posts[id];
      }
      return acc;
    }, {});
};

export const cleanAllFeedsButMe = (draft: IFeeds, me: IFeedType) => {
  const rememberMe = Object.assign({}, draft.feeds[me]);

  draft.feeds = { ...INITIAL_FEEDS };
  draft.feeds[me] = rememberMe;

  removeUnusedPosts(draft);
};

export const getNewsFeedHighlightsPosts = (draft: IFeeds): IGroupedPosts => {
  const { mostInteractedPost, mostViewedPost, recentFolloweePost, recentGroupPost } = draft.newsFeedHighlights;

  // As highlighted posts can be undefined, we need to filter it
  const highlightedPosts: Id[] = [
    mostInteractedPost,
    mostViewedPost,
    recentFolloweePost,
    recentGroupPost,
  ].filter(notUndefined);

  return {
    ids: highlightedPosts,
  };
};
