// libs
import * as React from 'react';

// classes
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';

/*
 * This is a "High Order Component" wrapper, see https://facebook.github.io/react/docs/higher-order-components.html
 */
const withLoadingSpinner = <T extends {}>(WrappedComponent: React.ComponentClass<T>) =>
  (props: T & {isLoading?: boolean}) => (
      <>
        <WrappedComponent {...props} />
        <LoadingSpinner shown={!!props.isLoading} />
      </>
  );

export default withLoadingSpinner;
