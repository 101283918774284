// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { IShareLink, IShareCompProps } from 'src/components/share/lib/share';
import { CLASS_PREFIX } from 'src/constants/';
import {
  COLOR_TYPE_PRIMARY,
  ColorType,
} from 'src/utils/color';

// components
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import { textResources } from 'src/lang/de';

import './share_segment_configurable.scss';

const cls = CLASS_PREFIX + 'share-segment-configurable';
const linkCls = cls + '__link';

const lang = textResources.share;

interface IShareElementProps {
  caption: string;
  iconName: string;
  iconColor: ColorType;
}

interface IShareSegmentProps extends IShareCompProps {
  component: React.ComponentType<IShareElementProps>;
  componentCls?: string;
}

// this is a composed presentational share component it takes an component as prop
// which it renders horizontal next to each other wrapped by the coresponding wrappers
// to achieve shearing. Hides whatsApp share on desktop
const ShareSegmentConfigurable: React.FC<IShareSegmentProps> = (props) => {
  return (
    <div
      className={classNames(cls, props.className)}
    >
      {props.shareLinks.map((link: IShareLink) => (
        <NavigationItem
          key={link.name}
          target='_blank'
          url={link.shareUrl}
          className={classNames(linkCls, `${linkCls}-${link.name}`, props.componentCls)}
          onClick={props.track(link.trackingAction)}
        >
          <props.component
            iconName={link.iconName}
            iconColor={link.iconColor}
            caption={link.caption}
          />
        </NavigationItem>
      ))}
      <button onClick={props.shareToClipboard} className={classNames(linkCls, props.componentCls)}>
        <props.component
          iconName='clip'
          iconColor={COLOR_TYPE_PRIMARY}
          caption={lang.link}
        />
      </button>
    </div>
  );
};

export default ShareSegmentConfigurable;
