// libs
import * as React from 'react';

// interfaces / constants
import { IMessageNotification } from 'src/interfaces/notification';

// classes / components
import Notification, { clsText } from 'src/components/notifications/notification';

// helpers
import { textResources } from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';

// images
import * as overlayInactive from 'src/components/notifications/badges/Message@2x.png';
import * as overlayActive from 'src/components/notifications/badges/MessageActive@2x.png';

const labels = textResources.notification;

export interface IProps extends IMessageNotification {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const MessageNotification: React.FC<IProps> = ({
  createdAt,
  initiator,
  isRead,
  onClick,
}) => {
  return (
    <Notification
      avatarImage={initiator?.image}
      badgeImageRead={overlayInactive}
      badgeImageNotRead={overlayActive}
      footer={DateFormatter.asTimeAgo(createdAt)}
      initiatorName={initiator?.name}
      isRead={isRead}
      onClick={onClick}
    >
      <span className={clsText} >{` ${labels.notificationMessage}`}</span>
    </Notification>
  );
};

export default MessageNotification;
