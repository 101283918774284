import React from 'react';

// interfaces / constants
import { IFeedType } from 'src//interfaces/feed';

// classes
import ListWrapper, { IProps as IListWrapperIProps } from './list_wrapper';

// helpers
import { useNextPageTracking } from 'src/actions/feed/feed_hooks';
import { useCallToActionWithFlag } from 'src/actions/onboarding_cta/onboarding_cta_hooks';
import { useFeedPaginationInfo } from 'src/reducers/feed/feed_hooks';

interface IProps extends IListWrapperIProps {
  feedType: IFeedType;
}

export const FeedWrapper: React.FC<IProps> = ({ children, getNextPage, feedType, initialLoad }) => {
  const { currentPage } = useFeedPaginationInfo(feedType);
  useNextPageTracking(currentPage);
  useCallToActionWithFlag(feedType);

  return (
    <ListWrapper
      getNextPage={getNextPage}
      initialLoad={initialLoad}
    >
      {children}
    </ListWrapper>
  );
};

export default FeedWrapper;
