// libs
import classNames from 'classnames';
import * as React from 'react';

// helpers
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { scrollTop } from 'src/utils/wizard';
import './wizard_step.scss';

const cls = createBemBlock('wizard-step');

export interface IProps {
  headerText?: string;
  leftButton?: JSX.Element;
  rightButton?: JSX.Element;
  contentCls?: string;
  layout: WizardLayout;
}

export const WIZARD_LAYOUT_MODAL = 'modal';
export const WIZARD_LAYOUT_PAGE = 'page';
type WizardLayout = typeof WIZARD_LAYOUT_MODAL | typeof WIZARD_LAYOUT_PAGE;

export default class WizardStep extends React.PureComponent<IProps, {}> {
  componentDidMount() {
    if (this.props.layout === WIZARD_LAYOUT_PAGE) {
      scrollTop();
    }
  }

  public render() {
    const { leftButton, rightButton, children, headerText, contentCls } = this.props;
    return (
      <div className={cls()}>
        <div className={cls('header')}>
          <div className={classNames(cls('button'), cls('button', 'left'))}>
            { leftButton }
          </div>
          <h2 className={cls('header-text')}>{headerText}</h2>
          <div className={classNames(cls('button'), cls('button', 'right'))}>
            { rightButton }
          </div>
        </div>
        <div className={classNames(cls('content-wrapper'), contentCls)}>
          <div className={cls('content')}>{children}</div>
        </div>
      </div>
    );
  }
}
