import { IAddress } from 'src/interfaces/address';
import { IBoundingBox, ILegacyBoundingBox, ILocationShape } from 'src/interfaces/location';

export const convertLegacyToBoundingbox = (value: ILegacyBoundingBox): IBoundingBox => ({
  neLatitude: value.northEast.latitude,
  neLongitude: value.northEast.longitude,
  swLatitude: value.southWest.latitude,
  swLongitude: value.southWest.longitude,
});

export const cleanShapeObject = (value: any): ILocationShape => {
  // clean unneeded values
  return {
    boundingBox: value.boundingBox,
    identifier: value.identifier,
    latitude: value.latitude,
    longitude: value.longitude,
    name: value.name,
    released: value.released,
    slug: value.slug,
    type: value.type,
  };
};

export const createDateAddress = (street?: string, cityWithDistrict?: string): string => {
  return street && cityWithDistrict ? `${street}, ${cityWithDistrict}` : cityWithDistrict || '';
};

export const formatAddress = (address: IAddress) => {
  return createDateAddress(address.street, address.locationShape.name);
};
