import { throttle } from 'lodash';
import { useCallback, useEffect } from 'react';

// helpers
import { hasEnoughToShow } from 'src/actions/app-state/utils';
import { useScrollListener } from 'src/hooks_shared/use_event_listener';

const useScrollInvoker = (callback: () => void, initialLoad: Boolean = true) => {
  // If the page already is in a state where there is not enough content
  // This is relevant for people with large screens
  useEffect(() => {
    if (initialLoad && !hasEnoughToShow()) {
      callback();
    }
  });

  const listener = useCallback(
    throttle(() => {
      if (!hasEnoughToShow()) {
        callback();
      }
    }, 200),
    [callback],
  );

  useScrollListener(listener, { passive: true });
};

export default useScrollInvoker;
