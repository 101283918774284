import { REGEXP_EMAIL, REGEXP_EMAILS, REGEXP_WEBSITE } from 'src/utils/pattern/pattern';

export const validateEmail = (email: string, allowMultiple = false) =>
  allowMultiple ? REGEXP_EMAILS.test(email) : REGEXP_EMAIL.test(email);

export const validatePassword = (password: string) => password.length >= 5;

export const validateRepeatedPassword = (password: string, repeatedPassword: string) => password === repeatedPassword;

export const validateName = (name: string) => name.trim().length >= 2;

export const validateGroupName = (name: string) => name.trim().length >= 5;

export const validateGroupDescription = (description: string) => description.trim().length >= 5;

export const isNotEmpty = (value?: string): boolean =>
  !!(value && value.trim());

export const validateWebsite = (url: string): boolean => REGEXP_WEBSITE.test(url);

export const validateFlagMessage = (text: string): boolean => text.trim().length >= 5;
