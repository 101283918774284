// libs
import * as React from 'react';

// classes
import ExpandableTextArea,
{ IProps as IExtendableAreaProps } from 'src/components/forms/expandable_textarea/expandable_textarea';
import { ILinkPreview } from 'src/interfaces/post_ingredients';

import EmojiPopup from 'src/components/emoji_popup/emoji_popup';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import { detectLink } from 'src/utils/comment';

import './text_input.scss';

const THROTTLE_DELAY_MS = 1000;
const cls: string = CLASS_PREFIX + 'text-input';

interface IProps extends IExtendableAreaProps {
  invalidState?: boolean;
  onDetectLink?: (link: ILinkPreview | undefined) => void;
  showEmoji?: boolean;
}

export default class TextInput extends React.PureComponent<IProps, {}> {
  private timeoutId: number;
  private ref: HTMLTextAreaElement | null;

  constructor(props: IProps) {
    super(props);
    this.emojiSelected = this.emojiSelected.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.onChange = this.onChange.bind(this);
    this.startDetectLinkTimeout = this.startDetectLinkTimeout.bind(this);
  }

  public componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  public render() {
    const { onChange, className, showEmoji, invalidState, ...rest } = this.props;
    return (
      <div className={cls}>
        <ExpandableTextArea
          textAreaRef={this.handleRef}
          onChange={this.onChange}
          className={showEmoji ? cls + '--withEmoji ' + className : className}
          {...rest}
        />
        {showEmoji &&
          <div className={`${cls}--emoji`}>
            <EmojiPopup onEmojiSelected={this.emojiSelected} isInputInvalid={invalidState} />
          </div>
        }
      </div>
    );
  }

  public focus() {
    this.ref && this.ref.focus();
  }

  public blur() {
    this.ref && this.ref.blur();
  }

  private onChange(value: string) {
    const { name, onDetectLink } = this.props;
    const { onChange } = this.props;
    onChange && onChange(value, name);
    if (!onDetectLink) return;
    this.startDetectLinkTimeout(value);
  }

  private startDetectLinkTimeout(text: string) {
    const { onDetectLink } = this.props;
    if (!onDetectLink) return;
    this.timeoutId && clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => detectLink(text).then(onDetectLink), THROTTLE_DELAY_MS);
  }

  private emojiSelected(native: string) {
    const ref = this.ref;
    if (ref === null) {
      return;
    }
    let value = ref.value;
    if (ref.selectionStart && ref.selectionEnd) {
      value = value.substr(0, ref.selectionStart) + native + value.substr(ref.selectionEnd);
    } else {
      value += native;
    }
    this.onChange(value);
  }

  private handleRef(ref: HTMLTextAreaElement) {
    this.ref = ref;
  }
}
