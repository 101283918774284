// libs
import * as React from 'react';

// classes
import MessageSegment from 'src/components/message_segment/message_segment';
import WizardButton, {
  WIZARD_BUTTON_TYPE_COMPLETED,
} from 'src/components/wizard/wizard_button/wizard_button';
import WizardStep, {
  WIZARD_LAYOUT_MODAL,
} from 'src/components/wizard/wizard_step/wizard_step';

// interfaces / constants
import { IFlagCreateData } from 'src/interfaces/flag';

// helpers
import { textResources } from 'src/lang/de';

const labels = textResources.createFlag;

interface IProps {
  onEnd: () => void;
  data?: IFlagCreateData;
  onSubmit: (data: IFlagCreateData) => void;
}

export default class Submit extends React.PureComponent<IProps> {
  public constructor(props: IProps) {
    super(props);
    this.onRightClick = this.onRightClick.bind(this);

    const { data, onSubmit } = this.props;
    data && onSubmit(data);
  }

  public render() {
    const rightButton = <WizardButton onClick={this.onRightClick} textType={WIZARD_BUTTON_TYPE_COMPLETED} />;

    return (
      <WizardStep
        headerText={labels.title}
        rightButton={rightButton}
        layout={WIZARD_LAYOUT_MODAL}
      >
        <MessageSegment
          title={labels.submitTitle}
          message={labels.successText}
          headerIcon={'report'}
        />
      </WizardStep>
    );
  }

  private onRightClick() {
    const { onEnd } = this.props;
    onEnd && onEnd();
  }
}
