// interfaces
import {
  FetchFeedThunkAction,
  IFeedMergeableParams,
} from 'src/actions/feed/feed_interfaces';
import { IFeedType } from 'src/interfaces/feed';

// helpers
import { getFeedPaginationInfo } from 'src/actions/feed/feed_utils';

export const fetchNextFeedPage = (
  feedType: IFeedType,
  fetchFeedPage: (page: number, params?: IFeedMergeableParams) => FetchFeedThunkAction,
  params?: IFeedMergeableParams,
): FetchFeedThunkAction => {
  return async(dispatch, getState): Promise<void> => {
    const state = getState();
    const pagination = getFeedPaginationInfo(state, feedType);
    if (!pagination.lastPage) {
      const fetchFeed = dispatch(fetchFeedPage(pagination.currentPage + 1, params));
      return fetchFeed;
    }
  };
};
