import { CLASS_PREFIX } from 'src/constants';

type IBemIt = (element?: string, modifier?: string) => string;

export const createBemBlock = (block: string): IBemIt =>
  (element?: string, modifier?: string) => {
    const _block = CLASS_PREFIX + block;
    const _element = element ? `__${element}` : '';
    const _modifier = modifier ? `--${modifier}` : '';
    return _block + _element + _modifier;
  };
