// libs
import classNames from 'classnames';
import React, { useCallback } from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// classes
import Icon from 'src/components/forms/icon/icon';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { textResources } from 'src/lang/de';
import './like.scss';

interface IProps {
  active: boolean;
  canBookmark: boolean;
  likes: number;
  onClick?: () => void;
}

const cls = `${CLASS_PREFIX}like`;
const clsActive = `${cls}--active`;
const clsText = `${cls}__text`;
const clsTextActive = `${cls}__text--active`;
const activeIconName = `heart-filled`;
const inactiveIconName = `heart-outline`;

const Like: React.FC<IProps> = ({
  active,
  canBookmark,
  likes,
  onClick,
}) => {
  const handleClick = useCallback(() => {
    if (!canBookmark && onClick) {
      onClick();
    }
  }, [canBookmark, onClick]);

  const classes = {
    [cls]: true,
    [clsActive]: active,
  };

  const likeText = likes === 0 ? textResources.reactions.like : likes.toString();
  const likeTextClasses = {
    [clsText]: true,
    [clsTextActive]: active,
  };

  return (
    <UserOnboardingCTA active={canBookmark} gtmSourceName='like-comment'>
      <div className={classNames(classes)} onClick={handleClick}>
        <Icon aria-hidden='true' name={active ? activeIconName : inactiveIconName} />
        <div className={classNames(likeTextClasses)}>
          {likeText}
        </div>
      </div>
    </UserOnboardingCTA>
  );
};

export default Like;
