// libs
import produce from 'immer';

// interfaces / constants
import { IAction } from 'src/actions/';
import {
  CATEGORIES_REQUEST,
  CATEGORIES_REQUEST_SUCCESS,
  CATEGORIES_SET,
} from 'src/actions/categories/categories';
import { IEventCategory } from 'src/interfaces/categories';

// helpers
import { textResources } from 'src/lang/de';

export const INITIAL_STATE: IEventCategory[] =
  [{ id: '-1', name: textResources.shared.forMe, parentId: null, slug: 'all' }];

const categoriesReducer = (state = INITIAL_STATE, action: IAction): IEventCategory[] =>
  produce(state, (draft) => {
    switch (action.type) {
      case CATEGORIES_SET:
      case CATEGORIES_REQUEST:
      case CATEGORIES_REQUEST_SUCCESS:
        return [...INITIAL_STATE, ...action.payload];
      default:
        return draft;
    }
  });

export default categoriesReducer;
