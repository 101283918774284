// TODO: we should probably rename and move this file and all exports, as these do no longer
//       only concern the settings page, but all handling of redirect URLs
export const SETTINGS_ACTION_ADD_EMAIL = 'add-email';
export const SETTINGS_ACTION_CHANGE = 'change';
export const SETTINGS_ACTION_LOGIN = 'login';
export const SETTINGS_ACTION_REGISTRATION = 'registration';
export type SettingsAction = 'add-email' | 'change' | 'login' | 'registration';

export const SETTINGS_ERROR_CODE_ALREADY_CONFIRMED = 'already_confirmed';
export const SETTINGS_ERROR_CODE_ALREADY_TAKEN = 'already_taken';
export const SETTINGS_ERROR_CODE_AREA_NOT_RELEASED = 'area_not_released';
export const SETTINGS_ERROR_CODE_EMAIL_ALREADY_IN_USE = 'email_already_in_use';
export const SETTINGS_ERROR_CODE_INVALID_GOOGLE_PLACE_ID = 'invalid_google_place_id';
export const SETTINGS_ERROR_CODE_INVALID_INTENT = 'invalid_intent';
export const SETTINGS_ERROR_CODE_INVALID_TOKEN = 'invalid_token';
export const SETTINGS_ERROR_CODE_MISSING_EMAIL = 'missing_email';
export const SETTINGS_ERROR_CODE_TOKEN_EXPIRED = 'token_expired';
export const SETTINGS_ERROR_CODE_UNPROCESSABLE_DATA = 'unprocessable_data';
export const SETTINGS_ERROR_CODE_USER_ALREADY_REGISTERED = 'user_already_registered';
export const SETTINGS_ERROR_CODE_USER_DENIED_AUTHORIZATION = 'user_denied_authorization';
export const SETTINGS_ERROR_CODE_USER_NOT_REGISTERED = 'user_not_registered';
export type SettingsErrorCode = 'already_confirmed'
| 'already_taken'
| 'area_not_released'
| 'email_already_in_use'
| 'invalid_google_place_id'
| 'invalid_intent'
| 'invalid_token'
| 'missing_email'
| 'token_expired'
| 'unprocessable_data'
| 'user_already_registered'
| 'user_denied_authorization'
| 'user_not_registered'
  ;

export const SETTINGS_REFERRAL_EMAIL = 'email';
export const SETTINGS_REFERRAL_APPLE = 'apple';
export const SETTINGS_REFERRAL_APPS = 'apps';
export const SETTINGS_REFERRAL_FACEBOOK = 'facebook';
export type SettingsReferral = typeof SETTINGS_REFERRAL_EMAIL
  | typeof SETTINGS_REFERRAL_APPLE
  | typeof SETTINGS_REFERRAL_APPS
  | typeof SETTINGS_REFERRAL_FACEBOOK;

export const SETTINGS_STATUS_ERROR = 'error';
export const SETTINGS_STATUS_SUCCESS = 'success';
export type SettingsStatusError = typeof SETTINGS_STATUS_ERROR;
export type SettingsStatusSuccess = typeof SETTINGS_STATUS_SUCCESS;
export type SettingsStatus = SettingsStatusError | SettingsStatusSuccess;

export const SETTINGS_NAME_MIN_QUERY_LENGTH = 2;
