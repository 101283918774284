// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';

// constants & interfaces
import ChatPage from 'src/components/chat_page/chat_page';
import { TWO_COLUMNS } from 'src/constants/layout';
import { TOPIC_CHAT_TITLE } from 'src/constants/meta_tags';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import { textResources } from 'src/lang/de';
import LayoutNoMap from 'src/layout/layout_no_map';

// helpers
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';

const TopicChatPage: React.FC<RouteComponentProps> = () => {
  const user = useSelectUser();

  usePageTracking({ user });

  return (
    <>
      <MetaTags
        title={TOPIC_CHAT_TITLE}
      />
      <LayoutNoMap
        contentWidth={TWO_COLUMNS}
        pageTitle={<StandardPageTitle text={textResources.neighborChat.messagesTitle} />}
      >
        <ChatPage />
      </LayoutNoMap>
    </>
  );
};

export default TopicChatPage;
