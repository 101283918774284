// libs
import classNames from 'classnames';
import * as React from 'react';

import PopoverLib, {
  IProps as IPopoverlibProps,
  POPOVER_ALIGN_LEFT,
  POPOVER_ALIGN_RIGHT,
  POPOVER_POSITION_BOTTOM,
  POPOVER_POSITION_LEFT,
  POPOVER_POSITION_TOP,
  renderPropsElementType,
} from 'src/components/_libs/popover_lib/popover_lib';

import { CLASS_PREFIX } from 'src/constants/';
import './popover.scss';

// helpers
const cls = CLASS_PREFIX + 'popover';

const POPOVER_POSITION_FULL = 'full';

const POPOVER_ALIGN_RELATIVE = 'relative';
const POPOVER_ALIGN_RELATIVE_LEFT = 'relative-left';
const POPOVER_ALIGN_RELATIVE_RIGHT = 'relative-right';

export const POPOVER_LAYOUT_DEFAULT = 'default';
export const POPOVER_LAYOUT_PLAIN = 'plain';
export const POPOVER_LAYOUT_ROUND = 'round';

type libAlignType = IPopoverlibProps['align'];
type libPositionType = IPopoverlibProps['position'];
type IPropsFromLib = Pick<
IPopoverlibProps,
'isOpen' | 'onHide' | 'onShow' | 'children' | 'isRenderedAsInlineHtml'
>;

export type PositionType = typeof POPOVER_POSITION_TOP
  | typeof POPOVER_POSITION_BOTTOM
  | typeof POPOVER_POSITION_FULL;

export interface IProps extends IPropsFromLib {
  /** class to add to the whole popover */
  className?: string;
  /** position of the popover */
  position: PositionType;
  /** alignment of the popover */
  align: typeof POPOVER_ALIGN_LEFT
  | typeof POPOVER_ALIGN_RIGHT
  | typeof POPOVER_ALIGN_RELATIVE
  | typeof POPOVER_ALIGN_RELATIVE_LEFT
  | typeof POPOVER_ALIGN_RELATIVE_RIGHT;

  /** layout to render */
  layout?: typeof POPOVER_LAYOUT_DEFAULT
  | typeof POPOVER_LAYOUT_PLAIN
  | typeof POPOVER_LAYOUT_ROUND ;
  /** we can pass just JSX or a React Component here */
  trigger: renderPropsElementType;
}

/**
 * transforms popoverAlignType to libAlignType
 */
const transformAlign = (align: IProps['align']): libAlignType => {
  switch (align) {
    case POPOVER_ALIGN_RELATIVE:
    case POPOVER_ALIGN_RELATIVE_LEFT:
      return POPOVER_ALIGN_LEFT;
    case POPOVER_ALIGN_RELATIVE_RIGHT:
      return POPOVER_ALIGN_RIGHT;
  }

  return align;
};

/**
 * transforms popoverPositionType to libPositionType
 */
const transformPosition = (align: IProps['position']): libPositionType => {
  if (align === POPOVER_POSITION_FULL) {
    return POPOVER_POSITION_LEFT;
  }

  return align;
};

/**
 * a popop component
 */
export default class PopoverTile extends React.Component<IProps, {}> {
  public render() {
    const {
      layout = POPOVER_LAYOUT_DEFAULT,
      position = POPOVER_POSITION_TOP,
      align = POPOVER_ALIGN_RIGHT,
      className,
      trigger,
      children,
      ...props } = this.props;

    const libPosition = transformPosition(position);
    const libAlign = transformAlign(align);
    const useWrapper = align.includes('relative') && position !== POPOVER_POSITION_FULL;

    const clsLayout = classNames({
      [`${cls}__layout--${layout}`]: true, // apply layout
      [`${cls}__layout--${layout}-${position}`]: true, // let layout know the position for styling
      [`${cls}__alignment--${align}`]: true, // set alignment
      [`${cls}__position--${position}`]: true, // set position
    });

    const clsWrapper = classNames({
      [`${cls}`]: useWrapper,
      [`${cls}__position--full-wrapper`]: position === POPOVER_POSITION_FULL,
      [`${className}`]: !!className,
    });

    return (
      <PopoverLib
        {...props}
        className={clsWrapper}
        closeOnClickOutside
        position={libPosition}
        align={libAlign}
      >
        {({ isOpen, getTogglerProps, getPopoverProps, renderProps }) => (
          <>
            {(position !== POPOVER_POSITION_TOP) && (
              <div {...getTogglerProps()}>{renderProps(trigger)}</div>
            )}
            {isOpen && (
              <div className={useWrapper ? `${cls}__wrapper` : `${cls}__noWrapper`}>
                <div {...getPopoverProps({ className: clsLayout })}>
                  {renderProps(children)}
                </div>
              </div>
            )}
            {position === POPOVER_POSITION_TOP && (
              <div {...getTogglerProps()}>{renderProps(trigger)}</div>
            )}
          </>
        )}
      </PopoverLib>
    );
  }
}
