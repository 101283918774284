import * as React from 'react';
import { PageTitlePossibleTags } from '../../../../containers/smart_components/page_title/page_title';
import Icon from '../../../forms/icon/icon';
import Logo from '../../../logo/logo';

interface IProps {
  customTag?: PageTitlePossibleTags;
  isHome?: boolean;
  location?: string;
  showLocation?: boolean;
  text: string;
  cls: string;
}

export const PageTitleContent: React.FunctionComponent<IProps> = ({
  customTag,
  location,
  text,
  isHome,
  showLocation,
  cls,
}) => {
  const Tag = customTag || 'h1';
  return (
    <Tag className={cls}>
      <span className={cls + '__page-name'}>
        {text}
      </span>
      {showLocation &&
        <span className={cls + '__location'}>
          <span className={cls + '__icon'}>
            {isHome
              ? <span className={cls + '__logo'}><Logo type={'NO_TEXT'} /></span>
              : <Icon name='nav-pin-filled' size={8} />}
          </span>
          {location}
        </span>
      }
    </Tag>
  );
};
