import { IProfileCommon } from 'src/components/settings/common/components/profile_common/profile_common';

import { PRIVATE_PROFILE_TYPE } from 'src/constants/profile';
import { IAddress } from 'src/interfaces/address';
import { IUserProfile, IUserProfileType } from 'src/interfaces/user';
import { getConfigValue } from 'src/utils/config/config';

export const validateProfile = (params: IProfileCommon, targetModel?: IUserProfileType): boolean => {
  const { firstName, lastName, profileName, address } = params;
  const validCommon: boolean = !!(firstName && lastName && isAddressValid(address));
  const validBlog: boolean = !!(validCommon && profileName);
  return targetModel === PRIVATE_PROFILE_TYPE ? validCommon : validBlog;
};

const isAddressValid = (address?: IAddress) => {
  const shouldValidateAddress = getConfigValue<boolean>('featureFlags.map.enabled') &&
    !getConfigValue<IAddress>('featureFlags.location.defaultAddress');
  return shouldValidateAddress ? !!(address && address.street) : true;
};

// Reorganize data coming from IUserProfile to use validateProfile()
export const checkIfProfileIsComplete = (userProfile: IUserProfile): boolean => {
  const { address, contact, name, targetModel } = userProfile;
  const profileDetails = {
    address,
    firstName: contact && contact.firstName,
    lastName: contact && contact.lastName,
    profileName: name,
  };
  return validateProfile(profileDetails, targetModel);
};
