import * as omnivore from '@mapbox/leaflet-omnivore';
import { LatLng, LatLngBounds, GeoJSON } from 'leaflet';

import { distance } from 'src/utils/position';
import { UrlUtils } from 'src/utils/url/url';

import { MAP_DISTANCE_THRESHOLD } from 'src/constants/map';
import { API_LOCATION_SHAPES_KML_PATH } from 'src/constants/urls';

import { IBoundingBox, IPosition, ILocationShape } from 'src/interfaces/location';
import { ShapeData, IMapShape } from 'src/interfaces/map';

export const isLockedPath = (pathname: string): boolean => {
  return UrlUtils.isLockMapUrl(pathname) !== null;
};

export const getPositionFromLeafletCenter = (coord: LatLng): IPosition => ({
  latitude: coord.lat,
  longitude: coord.lng,
});

export const getBoundingBoxFromLeafletBounds = (bb: LatLngBounds): IBoundingBox => {
  const ne = bb.getNorthEast();
  const sw = bb.getSouthWest();

  return {
    neLatitude: ne.lat,
    neLongitude: ne.lng,
    swLatitude: sw.lat,
    swLongitude: sw.lng,
  };
};

export const boundingBoxToLeafletBounds = (bb: IBoundingBox): LatLngBounds => {
  return new LatLngBounds(
    [bb.neLatitude, bb.neLongitude],
    [bb.swLatitude, bb.swLongitude],
  );
};

export const hasMapMovedEnough = (bb1?: IBoundingBox, bb2?: IBoundingBox): boolean => {
  if (!bb1 || !bb2) {
    return false;
  }

  return distance(
    {
      latitude: bb1.neLatitude,
      longitude: bb1.neLongitude,
    },
    {
      latitude: bb2.neLatitude,
      longitude: bb2.neLongitude,
    }) > MAP_DISTANCE_THRESHOLD ||
    distance(
      {
        latitude: bb1.swLatitude,
        longitude: bb1.swLongitude,
      },
      {
        latitude: bb2.swLatitude,
        longitude: bb2.swLongitude,
      }) > MAP_DISTANCE_THRESHOLD;
};

const shapeCache: { path: string; data?: ShapeData } = {
  data: undefined,
  path: '',
};

const fetchShapeData = (path: string): Promise<ShapeData> => {
  return new Promise((resolve) => {
    const geoJson: GeoJSON = omnivore.kml(path);

    geoJson.on('ready', () => resolve(geoJson.toGeoJSON() as ShapeData));
  });
};

export const getShape = async({ identifier }: ILocationShape, inverse: boolean): Promise<IMapShape> => {
  const path = `${API_LOCATION_SHAPES_KML_PATH}?ids=${identifier}${inverse ? '&inverse=1' : ''}`;

  if (path === shapeCache.path && shapeCache.data) {
    return {
      data: shapeCache.data,
      id: identifier,
    };
  }

  const data = await fetchShapeData(path);

  shapeCache.data = data;
  shapeCache.path = path;

  return {
    data,
    id: identifier,
  };
};
