// libs
import React from 'react';

// interfaces
import { IProps } from 'src/containers/header/subheader/header_group';

// helpers
import { nativeShare } from 'src/components/share/lib/utils';

// components
import BackbuttonHeader from 'src/components/header/common/backbutton_header/backbutton_header';
import MoreMenu from 'src/components/header/subheaders/shared_contents/more_menu/more_menu';
import ShareHeader from 'src/components/header/subheaders/shared_contents/share/share_header';
import MenuItemSegment from 'src/components/item_segment/layouts/menu/menu';
import ToggleItemSegment from 'src/components/item_segment/layouts/toggle/toggle';
import MessageBoxModal from 'src/components/message_box/message_box_with_modal';
import ShareItemListSmall from 'src/components/share/share_item_list_small/share_item_list_small';
import SnackBar from 'src/components/snack_bar/snack_bar';

// constants
import {
  MSGBOX_BUTTON_TYPE_CANCEL,
  MSGBOX_BUTTON_TYPE_DANGER,
} from 'src/components/message_box/message_box';
import { CLASS_PREFIX } from 'src/constants';

// helpers
import api from 'src/api';
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_BLACK } from 'src/utils/color';
import { UrlUtils } from 'src/utils/url/url';

import './header_group.scss';

const labels = textResources.groups;

const cls: string = CLASS_PREFIX + 'header-group';
const clsMoreWrapper = `${cls}__more-wrapper`;

interface IState {
  showLeaveModal: boolean;
  showSnackbar: boolean;
  showShareList: boolean;
  groupNotificationsEnabled: boolean;
  snackbarMessage?: string;
}

export default class HeaderGroup extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.leaveGroup = this.leaveGroup.bind(this);
    this.closeSnackbar = this.closeSnackbar.bind(this);
    this.closeLeaveModal = this.closeLeaveModal.bind(this);
    this.showLeaveModal = this.showLeaveModal.bind(this);
    this.closeShareList = this.closeShareList.bind(this);
    this.onShare = this.onShare.bind(this);
    this.getShareURL = this.getShareURL.bind(this);
    this.onNotificationSettingsChange = this.onNotificationSettingsChange.bind(this);

    this.state = {
      groupNotificationsEnabled: false,
      showLeaveModal: false,
      showShareList: false,
      showSnackbar: false,
    };
  }

  componentDidMount() {
    this.setState({
      groupNotificationsEnabled: !!this.props.group?.notification,
    });
  }

  componentDidUpdate(prevProps: IProps) {
    if (this.props.group?.id !== prevProps.group?.id) {
      this.setState({
        groupNotificationsEnabled: !!this.props.group?.notification,
      });
    }
  }

  private getShareURL() {
    const { group } = this.props;
    return group ? UrlUtils.groupPage(group.id) : '';
  }

  private closeShareList() {
    this.setState({
      showShareList: false,
    });
  }

  private onShare() {
    // @ts-ignore
    if (navigator.share) {
      nativeShare(this.getShareURL(), 'group')();
    } else {
      this.setState({
        showShareList: true,
      });
    }
  }

  private onNotificationSettingsChange() {
    this.setState(prevState => ({
      groupNotificationsEnabled: !prevState.groupNotificationsEnabled,
    }), this.changeGroupNotificationSettings);
  }

  public render() {
    const { group, handleBackButton, showMemberCount, showMoreButton } = this.props;
    const { showLeaveModal, showSnackbar, snackbarMessage, showShareList, groupNotificationsEnabled } = this.state;
    const shareUrl = this.getShareURL();

    return (
      <div className={cls}>
        {group && <BackbuttonHeader
          title={showMemberCount ? labels.memberCountHeader(group.memberCount) : group.name }
          handleBackButton={handleBackButton}
        >
          {showMoreButton && (
            <div className={clsMoreWrapper}>
              {(!group.isMember || group.isAdmin) &&
                <ShareHeader utmSource={'group'} url={shareUrl} />
              }
              {group.isMember && !group.isAdmin &&
                <MoreMenu
                  onHide={this.closeShareList}
                >
                  {showShareList && <ShareItemListSmall url={shareUrl} utmSource={'group'} />}
                  <ToggleItemSegment
                    label={labels.notifications.label}
                    isChecked={groupNotificationsEnabled}
                    onChange={this.onNotificationSettingsChange}
                    icon={{
                      color: COLOR_TYPE_BLACK,
                      name: 'bell-new',
                    }}
                  />
                  <MenuItemSegment
                    label={labels.shareGroup}
                    icon={{ name: 'share-thick' }}
                    navigation={{ onClick: this.onShare }}
                    hidden={showShareList}
                  />
                  <MenuItemSegment
                    label={labels.leaveGroup}
                    icon={{ name: 'close-circled' }}
                    navigation={{ onClick: this.showLeaveModal }}
                    hidden={showShareList}
                  />
                </MoreMenu>
              }
            </div>
          )}
        </BackbuttonHeader>}

        {group && showLeaveModal && (
          <MessageBoxModal
            onClose={this.closeLeaveModal}
            buttons={[
              {
                label: labels.leaveGroupModal.confirm,
                onClick: this.leaveGroup,
                type: MSGBOX_BUTTON_TYPE_DANGER,
              },
              { onClick: this.closeLeaveModal, type: MSGBOX_BUTTON_TYPE_CANCEL },
            ]}
            title={labels.leaveGroupModal.title_WEB(group.name)}
          >
            {labels.leaveGroupModal.message}
          </MessageBoxModal>
        )}
        {showSnackbar && (
          <SnackBar showClose={true} message={snackbarMessage} onClose={this.closeSnackbar} />
        )}
      </div>
    );
  }

  private leaveGroup() {
    const { group, goToGroupsPage, resetNewsfeed } = this.props;

    if (!group) {
      return;
    }
    api.group.leave(group.urls.participate)
      .then(() => {
        goToGroupsPage();
        resetNewsfeed();
      })
      .catch(() => {
        this.setState({ showSnackbar: true, snackbarMessage: labels.joinGroupError(group.name) });
      });
  }

  private changeGroupNotificationSettings() {
    const { group } = this.props;

    if (!group) {
      return;
    }
    this.closeSnackbar();

    api.group.notifications(this.state.groupNotificationsEnabled, group.id)
      .then(() => {
        this.setState(
          {
            showSnackbar: true,
            snackbarMessage: labels.notifications.changeSuccess(group.name, this.state.groupNotificationsEnabled),
          });
      }).catch(() => {
        this.setState({ showSnackbar: true, snackbarMessage: labels.notifications.changeError });
      });
  }

  private showLeaveModal() {
    this.setState({ showLeaveModal: true });
  }

  private closeLeaveModal() {
    this.setState({ showLeaveModal: false });
  }

  private closeSnackbar() {
    this.setState({ showSnackbar: false });
  }
}
