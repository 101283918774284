// libs
import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import SnackBar from 'src/components/snack_bar/snack_bar';
import { GroupListProfileAvailableAction } from 'src/containers/smart_components/group_member_list/model';

// interfaces / constants
import { Id } from 'src/interfaces';

// components
import ItemGroupHeader from 'src/components/item_group_header/item_group_header';
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import ProfilesList from 'src/components/profiles_list/profiles_list';
import ProfileItem from 'src/containers/smart_components/group_member_list/profile_item';
import ProfileListWrapper from 'src/containers/smart_components/list_wrapper/profile_list_wrapper';

// helper
import {
  useGetGroup, useGetGroupMembers,
  useGetGroupMembersNextPage,
} from 'src/actions/feed/group_feed/group_feed_hooks';
import { useCleanProfilesListEffect } from 'src/actions/profiles_list/profiles_list_hooks';
import { IGroupListProfile } from 'src/interfaces/profile';
import { textResources } from 'src/lang/de';
import { useGetGroupById } from 'src/reducers/groups/groups_hooks';
import {
  useSelectProfilesIds,
  useProfilesList,
  useProfilesFromIdsGroupedByConfirmation,
} from 'src/reducers/profiles_list/profiles_list_hooks';
import GroupMemberListItem from './group_member_list_item';

const GroupMembersList: React.FC = () => {
  const { id } = useParams<{ id: Id }>();
  const group = useGetGroupById(id);
  const { isLoading } = useProfilesList();
  const { confirmed, unconfirmed } = useProfilesFromIdsGroupedByConfirmation(useSelectProfilesIds());
  const url = group?.urls.categorized_participations;

  const [snackBarMessage, setSnackBarMessage] = useState('');

  const getGroup = useGetGroup();
  const getMembersNextPage = useGetGroupMembersNextPage(id, url);
  const getGroupMembers = useGetGroupMembers(id, url);
  const hideSnackbar = () => {
    setSnackBarMessage('');
  };

  useCleanProfilesListEffect();
  useEffect(() => {
    if (!group) {
      getGroup(id);
    }
  }, [getGroup, group, id]);

  const getActions = useCallback((profile: IGroupListProfile) => {
    const actions = {
      current: {},
      pending: {},
    };
    if (profile.canRemove) {
      actions.current = {
        ...actions.current,
        [GroupListProfileAvailableAction.REMOVE]: {
          error: (username: string) => {
            setSnackBarMessage(textResources.groups.eliminateMemberFail(username));
          },
          success: (username: string) => {
            setSnackBarMessage(textResources.groups.eliminateMemberSuccess(username));
            getGroupMembers();
          },
        },
      };
    }

    if (profile.canAccept) {
      actions.pending = {
        ...actions.pending,
        [GroupListProfileAvailableAction.ACCEPT]: {
          error: (username: string) => {
            setSnackBarMessage(textResources.groups.confirmMemberFail(username));
          },
          success: (username: string) => {
            setSnackBarMessage(textResources.groups.confirmMemberSuccess(username));
            getGroupMembers();
          },
        },
      };
    }

    if (profile.canReject) {
      actions.pending = {
        ...actions.pending,
        [GroupListProfileAvailableAction.REJECT]: {
          error: (username: string) => {
            setSnackBarMessage(textResources.groups.eliminateMemberFail(username));
          },
          success: (username: string) => {
            setSnackBarMessage(textResources.groups.eliminateMemberSuccess(username));
            getGroupMembers();
          },
        },
      };
    }
    return actions;
  }, [getGroupMembers]);

  return (
    <LoadingSpinnerWrapper isLoading={isLoading}>
      <ProfileListWrapper
        getNextPage={getMembersNextPage}>
        <>
          {unconfirmed && !!unconfirmed.length &&
            <>
              <ItemGroupHeader title={textResources.groups.pendingUsers} />
              <ProfilesList<IGroupListProfile> profiles={unconfirmed}>
                {profile =>
                  <GroupMemberListItem profile={profile} key={profile.id} actions={getActions(profile).pending} />
                }
              </ProfilesList>
            </>
          }
          {confirmed && !!confirmed.length &&
            <>
              <ItemGroupHeader title={textResources.groups.confirmedUsers} />
              <ProfilesList<IGroupListProfile> profiles={confirmed}>
                {profile => <ProfileItem key={profile.id} profile={profile} actions={getActions(profile).current} />}
              </ProfilesList>
            </>
          }
        </>
      </ProfileListWrapper>
      {snackBarMessage && <SnackBar message={snackBarMessage} onClose={hideSnackbar} showClose />}
    </LoadingSpinnerWrapper>
  );
};

export default GroupMembersList;
