// libs
import * as H from 'history';
import React, { useState } from 'react';

// interfaces / constants
import { FRONTEND_SETTINGS_PATH } from 'src/constants/urls';
import {
  PROFILE_STEP_PROFILE_COMPLETE,
  PROFILE_STEP_EMAIL_CONFIRMED,
  PROFILE_STEP_VERIFIED,
  NO_PROFILE,
} from 'src/constants/user';

// classes / components
import {
  renderCompleteProfile,
  renderEmailConfirm,
  renderNewUserInfo,
  renderVerify,
  trackClose,
  trackContinueRegistration,
  trackDoItLater,
  ICTAModalContent,
} from 'src/components/cta/cta_intermediate_options';
import MessageSegmentWithModal from 'src/components/message_segment/message_segment_with_modal';
import SnackBar from 'src/components/snack_bar/snack_bar';
import { sendEmailAgain } from 'src/containers/smart_components/content_blocker_info/content_blocker_info';

// helpers
import { useSelectProfileStatus } from 'src/reducers/user/user_hooks';
import initTracker, {
  CTA_NEW_USER,
} from 'src/utils/reporting/events_tracking/events_tracking';
import './cta_generator.scss';

const trackerNewUserInfo = initTracker(CTA_NEW_USER);

interface IProps {
  dispatchHideModal: () => void;
  history: H.History;
  showLogin: () => void;
  showRegistration: () => void;
  userEmailAddress?: string;
}

const CTAGenerator: React.FC<IProps> = ({
  dispatchHideModal,
  history,
  showLogin,
  showRegistration,
  userEmailAddress,
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const profileStatus = useSelectProfileStatus();

  const closeModal = () => {
    trackClose(profileStatus);
    dispatchHideModal();
  };

  const closeSnackbar = () => {
    setSnackbarMessage('');
  };

  const doItLaterButton = () => {
    trackDoItLater(profileStatus);
    dispatchHideModal();
  };

  const goToContinueRegistration = () => {
    trackContinueRegistration(profileStatus);
    history.push(FRONTEND_SETTINGS_PATH);
    dispatchHideModal();
  };

  const trackLogin = () => {
    trackerNewUserInfo(CTA_NEW_USER.ACTIONS.LOGIN, CTA_NEW_USER.LABELS.START);
    showLogin();
  };

  const trackRegister = () => {
    trackerNewUserInfo(CTA_NEW_USER.ACTIONS.REGISTER, CTA_NEW_USER.LABELS.START);
    showRegistration();
  };

  const getOptions = (): ICTAModalContent | undefined => {
    switch (profileStatus) {
      case PROFILE_STEP_PROFILE_COMPLETE: {
        return renderCompleteProfile(goToContinueRegistration, doItLaterButton, closeModal);
      }
      case PROFILE_STEP_EMAIL_CONFIRMED: {
        return renderEmailConfirm(
          () => sendEmailAgain(setSnackbarMessage), doItLaterButton, closeModal, userEmailAddress);
      }
      case PROFILE_STEP_VERIFIED: {
        return renderVerify(goToContinueRegistration, doItLaterButton, closeModal);
      }
      case NO_PROFILE: {
        return renderNewUserInfo(trackRegister, trackLogin, doItLaterButton, closeModal);
      }
      default:
        return undefined;
    }
  };

  const options = getOptions();

  return (
      <>
      {options &&
        <MessageSegmentWithModal
          buttons={options.buttons}
          description={options.description}
          image={options.image}
          title={options.title}
          onClose={options.onClose}
        >{options.children}
        </MessageSegmentWithModal>
      }
        {
          snackbarMessage &&
          <SnackBar
            message={snackbarMessage}
            onClose={closeSnackbar}
          />
        }
      </>
  );
};

export default CTAGenerator;
