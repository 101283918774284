import { $addressGeoLookup, $validateAddress } from 'src/api/decoders/address';
import { $blacklistGet } from 'src/api/decoders/blacklist';
import { $categories } from 'src/api/decoders/category';
import { $commentCreate, $comments, $commentSingle } from 'src/api/decoders/comment';
import { $feed, $feedGroupedByDate } from 'src/api/decoders/feed';
import { $follow } from 'src/api/decoders/follow';
import { $graylistGet } from 'src/api/decoders/graylist';
import { $groupParticipation, $groupParticipations, $group, $groups, $moreGroups } from 'src/api/decoders/group';
import { $locationShape, $locationShapeResponse } from 'src/api/decoders/location_shape';
import {
  $conversationCreate,
  $conversationMessages,
  $conversation,
  $conversationResponse,
  $conversations,
  $conversationUnreadCount,
  $messageCreate,
} from 'src/api/decoders/mailbox';
import { $newsFeedHighlights } from 'src/api/decoders/news_feed_highlights';
import { $notifications, $notificationsUnseenCounts } from 'src/api/decoders/notification';
import { $oauth2Credentials } from 'src/api/decoders/oauth2';
import { $oglink } from 'src/api/decoders/oglink';
import { $eventEditView, $postEditView, $postResponse } from 'src/api/decoders/post';
import { $profile, $profileResponse, $profiles, $categorizedProfiles, $shapeSponsors } from 'src/api/decoders/profile';
import { $reactions, $reactionUpdate, $reactedProfiles } from 'src/api/decoders/reaction';
import { $relatedContent, $relatedContents } from 'src/api/decoders/related_content';
import { $resourceLink } from 'src/api/decoders/resource_link';
import { $addTag, $profileTags, $tagsList } from 'src/api/decoders/tags';
import {
  $authorityProfileResponse,
  $blogProfileResponse,
  $intermediateProfileResponse,
  $notificationSettings,
  $pressProfileResponse,
  $privateProfileResponse,
  $receiveNewsletter,
  $upgradeProfileResponse,
  $user,
  $updatePhone,
} from 'src/api/decoders/user';

const $noop = <Model>(a: Model) => a;

// To avoid duplications in decoders
export {
  $addTag,
  $addressGeoLookup,
  $authorityProfileResponse,
  $blacklistGet,
  $blogProfileResponse,
  $categories,
  $categorizedProfiles,
  $commentCreate,
  $commentSingle,
  $comments,
  $conversationCreate,
  $conversationMessages,
  $conversationUnreadCount,
  $conversation,
  $conversationResponse,
  $conversations,
  $eventEditView,
  $feed,
  $feedGroupedByDate,
  $follow,
  $graylistGet,
  $group,
  $groupParticipation,
  $groupParticipations,
  $groups,
  $intermediateProfileResponse,
  $locationShape,
  $locationShapeResponse,
  $messageCreate,
  $moreGroups,
  $newsFeedHighlights,
  $noop,
  $notificationSettings,
  $notifications,
  $notificationsUnseenCounts,
  $oauth2Credentials,
  $oglink,
  $postEditView,
  $postResponse,
  $pressProfileResponse,
  $privateProfileResponse,
  $profile,
  $profileResponse,
  $profileTags,
  $profiles,
  $reactedProfiles,
  $reactionUpdate,
  $reactions,
  $receiveNewsletter,
  $relatedContent,
  $relatedContents,
  $resourceLink,
  $shapeSponsors,
  $tagsList,
  $updatePhone,
  $upgradeProfileResponse,
  $user,
  $validateAddress,
};
