// libs
import * as React from 'react';

// components
import ContentPlaceholder from 'src/components/content_placeholder/content_placeholder';
import Icon from 'src/components/forms/icon/icon';

// helpers
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import './post_highlight.scss';

const cls = createBemBlock('post-highlight-placeholder');

interface IProps {
  showMoreButton?: boolean;
}

const PostHighlightPlaceholder: React.FC<IProps> = ({ showMoreButton }) => (
  <div className={cls()}>
    <div className={cls('item-segment')}>
      <ContentPlaceholder className={cls('image')} />
      <div className={cls('block-content')}>
        <ContentPlaceholder className={cls('date')} />
        <ContentPlaceholder className={cls('title')} />
      </div>
      <Icon className={cls('icon')} name='arrow-right' />
    </div>
    {showMoreButton &&
    <div className={cls('more-button')}>
      <ContentPlaceholder className={cls('more-button-text')} />
    </div>}
  </div>
);

export default PostHighlightPlaceholder;
