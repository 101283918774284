import React from 'react';

import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';

import './segment_button.scss';

interface IProps {
  onClick?: () => void;
  featureBlocked?: boolean;
}

const cls = itemSegmentCls + 'button';

const ItemSegmentButton: React.FC<IProps> = ({ children, featureBlocked, onClick }) => {
  const handleClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClick && onClick();
  };

  const element = (
    <button onClick={handleClick} >
      {children}
    </button>);

  if (featureBlocked) {
    return (
      <UserOnboardingCTA className={cls} active={true} >
        {element}
      </UserOnboardingCTA>
    );
  }
  return (
    <div className={cls}>
      {element}
    </div>);
};

export default ItemSegmentButton;
