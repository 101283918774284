// lib
import * as React from 'react';

// components
import ItemSegmentBase, {
  itemSegmentCls,
  SMALL_ITEM_SEGMENT,
} from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink, { IProps as ILinkProps } from 'src/components/item_segment/base/item_segment_link';
import SegmentText from 'src/components/item_segment/parts/segment_text/segment_text';

interface IProps {
  label: string;
  navigation: ILinkProps;
}

const cls = itemSegmentCls + 'select-field';

/*
  A Wrapper for ItemSegment for Select Field
*/
const SelectFieldItemSegment: React.FC<IProps> = ({ label, navigation }) => (
  <ItemSegmentBase className={cls} height={SMALL_ITEM_SEGMENT}>
    <ItemSegmentLink {...navigation}>
      <SegmentText label={label} labelSize='small' />
    </ItemSegmentLink>
  </ItemSegmentBase>
);

export default SelectFieldItemSegment;
