// libs
import classNames from 'classnames';
import React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { ColorType } from 'src/utils/color';

// components
import RoundIcon from 'src/components/forms/round_icon/round_icon';
import NavigationItem from 'src/components/navigation_item/navigation_item';

import './option.scss';

export interface IOption {
  text: string;
  icon: string;
  backgroundColor: ColorType;
  url?: string;
  onClick?: () => void;
}

interface IProps extends IOption{
  shown: boolean;
}

const cls = CLASS_PREFIX + 'floating-button-option';

const Option: React.FC<IProps> = ({ text, icon, backgroundColor, shown, url, onClick }) => {
  const wrapperCls = {
    [cls]: true,
    [cls + '--shown']: shown,
  };
  const optionJSX = (
    <>
      <p className={cls + '-text'}>{text}</p>
      <div className={cls + '-icon'}>
        <RoundIcon name={icon} backgroundColor={backgroundColor} backgroundSize={40} size={18} />
      </div>
    </>
  );
  if (url) {
    return (
      <NavigationItem target='_self' url={url} className={classNames(wrapperCls)}>
        {optionJSX}
      </NavigationItem>
    );
  }
  return (
    <div onClick={onClick} className={classNames(wrapperCls)}>
      {optionJSX}
    </div>
  );
};

export default Option;
