import { Draft } from 'immer';
import { IGroupsData } from '../../interfaces/group';
import { IGroups, INITIAL_PAGINATION } from './groups';
import { isSuccess, Success } from './types/remote_data';

export const addToJoinedGroups = (draft: Draft<IGroups>, newGroupId: string) => {
  if (isSuccess(draft.joinedGroupsRequest)) {
    draft.joinedGroupsRequest = Success<IGroupsData>({
      ...draft.joinedGroupsRequest.data,
      joinedGroupIds: draft.joinedGroupsRequest.data.joinedGroupIds.concat(newGroupId),
    });
  } else {
    draft.joinedGroupsRequest = Success<IGroupsData>({
      joinedGroupIds: [newGroupId],
      pagination: INITIAL_PAGINATION,
      pendingGroupIds: [],
    });
  }
};

export const addToPendingGroups = (draft: Draft<IGroups>, newGroupId: string) => {
  if (isSuccess(draft.joinedGroupsRequest)) {
    draft.joinedGroupsRequest = Success<IGroupsData>({
      ...draft.joinedGroupsRequest.data,
      pendingGroupIds: draft.joinedGroupsRequest.data.pendingGroupIds.concat(newGroupId),
    });
  } else {
    draft.joinedGroupsRequest = Success<IGroupsData>({
      joinedGroupIds: [],
      pagination: INITIAL_PAGINATION,
      pendingGroupIds: [newGroupId],
    });
  }
};
