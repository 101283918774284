// interfaces
import * as Response from 'src/api/interfaces/responses/related_content';
import { IRelatedContentItem, IRelatedContentItems } from 'src/interfaces/related_content';

// decoders
import { $publicAddress } from 'src/api/decoders/address';
import { $ingredients } from 'src/api/decoders/post_ingredients';

// utils
import { UrlUtils } from 'src/utils/url/url';

export const $relatedContents = (json: Response.IRelatedContentResponse): IRelatedContentItems => ({
  items: json.data.map($relatedContent),
});

export const $relatedContent = (json: Response.IRelatedContentItem): IRelatedContentItem => ({
  ...$ingredients(json.ingredients),
  address: $publicAddress(json.public_address),
  categories: json.data.categories,
  detailUrl: UrlUtils.detailPage(json.data.identifier, json.data.type),
  id: json.data.identifier,
  publishedAt: json.data.published_at,
});
