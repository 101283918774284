import { useSelectUser } from '../../../reducers/user/user_hooks';
import { canCreateSurvey } from '../helper/survey.config';

export const useSurvey = () => {
  const user = useSelectUser();

  return {
    isEnabled: canCreateSurvey(user),
  };
};
