// libs
import React, { FunctionComponent } from 'react';

// components
import AuthUI from 'src/containers/auth_ui';

// interfaces / constants
import { AuthModalType } from 'src/interfaces/app-state';

// helpers
interface IOwnProps {
  layout: AuthModalType;
}
const Registration: FunctionComponent<IOwnProps> = ({ layout }) => (
  <AuthUI layout={layout} hasStaticLinks />
);

export default Registration;
