import { History } from 'history';
import * as React from 'react';

import {
  CreatePasswordField,
  PrimaryButton,
  RepeatedPasswordField,
} from 'src/components/authentication/auth_form_elements';
import FieldSet from 'src/components/forms/field_set/field_set';
import MessageSegmentWithModal from 'src/components/message_segment/message_segment_with_modal';

// intefaces & constants
import { IErrorResponse } from 'src/api/interfaces/errors';
import { IAuthProps } from 'src/components/authentication/auth_ui';
import { AUTH_UI_CLASS } from 'src/constants/';
import { PASSWORD_INVALID } from 'src/constants/api_error_codes';
import { SETTINGS_STATUS_ERROR } from 'src/constants/settings';
import { PASSWORD_RESET_SUCCESS_TIMEOUT } from 'src/constants/timeouts';

// helpers
import { textResources } from 'src/lang/de';
import { formatApiErrorFullMessages } from 'src/utils/api_error_message/api_error_message';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { confirmResetPassword } from 'src/utils/profile';
import { reportOnlyErrorObject } from 'src/utils/reporting/report-errors';
import { validatePassword, validateRepeatedPassword } from 'src/utils/validation';

const cls = createBemBlock(AUTH_UI_CLASS);

const labels = textResources.authentication;

interface IState {
  password: string;
  repeatedPassword: string;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  validPassword: boolean;
  validRepeatedPassword: boolean;
}

interface IProps extends IAuthProps {
  history: History;
}

class ResetPassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    const showErrorMessage = props.passwordResetParams!.statusCode === SETTINGS_STATUS_ERROR;
    this.state = {
      password: '',
      repeatedPassword: '',
      showErrorMessage,
      showSuccessMessage: false,
      validPassword: true,
      validRepeatedPassword: true,
    };
    this.submit = this.submit.bind(this);
    this.changePassword = this.changePassword.bind(this);
    this.changeRepeatedPassword = this.changeRepeatedPassword.bind(this);
  }

  public render() {
    const { showErrorMessage, showSuccessMessage } = this.state;

    return showErrorMessage
      ? this.renderErrorMessage()
      : showSuccessMessage ? this.renderSuccessMessage() : this.renderForm();
  }

  public renderErrorMessage() {
    const {
      goToLogin,
      goToForgotPassword,
    } = this.props;
    return (
      <div className={cls('error')}>
        <PrimaryButton onClick={goToLogin} label='Anmelden'/>
        <a onClick={goToForgotPassword} >{labels.passwordForgot}</a>
      </div>
    );
  }

  public renderSuccessMessage() {
    return (
      <MessageSegmentWithModal
        title={labels.passwordChanged}
        description={labels.passwordChangedLongText}
        image='password'
      />
    );
  }

  public renderForm() {
    const { password, validPassword, repeatedPassword, validRepeatedPassword } = this.state;
    return (
      <form>
        <FieldSet>
          <CreatePasswordField
            name='password'
            label={labels.newPassword}
            value={password}
            onChange={this.changePassword}
            valid={validPassword}
          />
          <RepeatedPasswordField
            name='repeatedPassword'
            label={labels.newPasswordRepeat}
            value={repeatedPassword}
            onChange={this.changeRepeatedPassword}
            valid={validRepeatedPassword}
          />
        </FieldSet>
        <PrimaryButton onClick={this.submit} label={labels.confirmPasswordReset} />
      </form>
    );
  }

  private pushError(msg: string) {
    const { pushError } = this.props;
    pushError && pushError(msg);
  }

  private changePassword(value: string) {
    this.setState({ password: value, validPassword: true });
  }

  private changeRepeatedPassword(value: string) {
    this.setState({ repeatedPassword: value, validRepeatedPassword: true });
  }

  private submit(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();

    const { password, repeatedPassword } = this.state;
    const validPassword = validatePassword(password);
    const validRepeatedPassword = validateRepeatedPassword(password, repeatedPassword);

    if (validPassword && validRepeatedPassword) {
      const { history, passwordResetParams } = this.props;

      if (!passwordResetParams || passwordResetParams.statusCode === SETTINGS_STATUS_ERROR) {
        this.pushError(textResources.shared.errorUnknown);
      } else {
        confirmResetPassword({
          password,
          passwordConfirmation: repeatedPassword,
          resetPasswordToken: passwordResetParams.token,
        })
          .then((_r: Response) => {
            const stateChangeCallback = () => setTimeout(() =>
              history.push('/'),
            PASSWORD_RESET_SUCCESS_TIMEOUT,
            );

            this.setState({ showSuccessMessage: true }, stateChangeCallback);
          })
          .catch((error: IErrorResponse) => {
            switch (error.code) {
              case PASSWORD_INVALID:
                this.pushError(formatApiErrorFullMessages(error));
                this.setState({ repeatedPassword: '', validPassword: false });
                break;
              default:
                reportOnlyErrorObject(error);
                this.pushError(textResources.shared.errorUnknown);
            }
          });
      }
    } else {
      this.setState({ validPassword, validRepeatedPassword });
    }
  }
}

export default ResetPassword;
