// libs
import React, { useEffect, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';

// constants
import { FEED_TYPE_PROFILE } from 'src/constants/feed';
import { IProfile } from 'src/interfaces/profile';

// classes / components
import MasonryFeed from 'src/components/feed/layouts/masonry_feed/masonry_feed';
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import ProfileInfo from 'src/components/profile/profile_info';
import FeedWrapper from 'src/containers/smart_components/list_wrapper/feed_wrapper';
import ContentBlockerInfo from '../content_blocker_info/content_blocker_info';

// helpers
import { useChangeAppLocation } from 'src/actions/app-state/app-state_hooks';
import {
  useFetchProfileFeedPage,
  useFetchProfileFeedNextPage,
} from 'src/actions/feed/profile_feed/profile_feed_hooks';
import { useFetchProfile } from 'src/actions/profile/profile_hooks';
import { useGetProfileIdWithTypeFromUrlParams } from 'src/hooks_shared/use_url_params';
import { textResources } from 'src/lang/de';
import { useGetAppPosition } from 'src/reducers/app-state/app-state_hooks';
import {
  useSelectFeedGroups,
  useSelectFeedIsLoading,
  useSelectPosts,
  useSelectFeedHash,
} from 'src/reducers/feed/feed_hooks';
import {
  useSelectUrlParamProfile,
} from 'src/reducers/profiles/profiles_hooks';
import { useSelectCurrentProfile } from 'src/reducers/user/user_hooks';

interface IProps {
  getNewsfeedRoute: () => string;
}

const useGetRenderProfileInfo = (profileDetails: IProfile | undefined) => {
  const changeAppLocation = useChangeAppLocation();
  const history = useHistory();
  const user = useSelectCurrentProfile();
  const position = useGetAppPosition();

  return (): JSX.Element => {
    if (!profileDetails) {
      return <div key='-1'></div>;
    }

    return (
      <div key='profile-info'>
        <ProfileInfo
          history={history}
          position={position}
          changePosition={changeAppLocation}
          profile={profileDetails}
          userType={user.type}
        />
      </div>
    );
  };
};

const ProfileFeed: React.FC<IProps> = () => {
  const { profileType, profileId } = useGetProfileIdWithTypeFromUrlParams();
  const feedHash = useSelectFeedHash(FEED_TYPE_PROFILE);
  const groups = useSelectFeedGroups(FEED_TYPE_PROFILE);
  const isLoading = useSelectFeedIsLoading(FEED_TYPE_PROFILE);
  const posts = useSelectPosts();
  const profileDetails = useSelectUrlParamProfile();
  const fetchProfile = useFetchProfile();
  const fetchPage = useFetchProfileFeedPage();
  const fetchNextPage = useFetchProfileFeedNextPage();

  const isBlurred = !!profileDetails?.meta.fenced;
  const profileName = profileDetails?.name;

  const getPage = useCallback(() => {
    if (profileType && profileId) {
      fetchPage({ profileId, profileType });
    } if (isBlurred) {

    }
  }, [fetchPage, profileId, profileType, isBlurred]);

  const getNextPage = () => {
    if (profileType && profileId) {
      fetchNextPage({ profileId, profileType });
    }
  };

  useEffect(() => {
    profileType && profileId && fetchProfile(profileType, profileId);
  }, [profileType, profileId, fetchProfile]);

  useEffect(() => {
    getPage();
  }, [getPage, feedHash]);

  const extraElements = useMemo(() =>
    isBlurred ? [
      <ContentBlockerInfo
        key='content-blocker-info'
        customContents={{
          noUserProfile: {
            body: textResources.profile.blurredLoggedOutBody,
            bodyTitle: profileName && textResources.profile.blurredLoggedOutBodyTile(profileName),
            title: profileName,
          } }}
      />,
    ] : undefined,
  [isBlurred, profileName]);

  const renderProfileInfo = useGetRenderProfileInfo(profileDetails);

  return (
    <LoadingSpinnerWrapper isLoading={isLoading}>
      <FeedWrapper
        getNextPage={getNextPage}
        feedType={FEED_TYPE_PROFILE}
        initialLoad={false}
      >
        <MasonryFeed
          groups={groups}
          posts={posts}
          renderPostCreate={renderProfileInfo}
          isLoading={isLoading}
          showEmptyFeed={!isBlurred}
          extraElements={extraElements}
        />
      </FeedWrapper>
    </LoadingSpinnerWrapper>
  );
};

ProfileFeed.displayName = 'ProfileFeed';

export default ProfileFeed;
