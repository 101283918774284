import produce from 'immer';

import { IUser } from 'src/interfaces/user';
import { reportError } from 'src/utils/reporting/report-errors';

import {
  IAddProfileAction,
  IChangeEmailAction,
  IUserHasTokenAction,
  IRemoveProfileAction,
  ISetIsHomeAction,
  ISetSelectedProfileAction,
  IUpdateSelectedProfileAction,
  IUserMeRequestErrorAction,
  IUserMeRequestSuccessAction,
  IUserUpdateAction,
  IUpdateProfileAction,
  IUserRemoveTagSuccessAction,
  IUserAddTagSuccessAction,
  USER_ADD_PROFILE,
  USER_CHANGE_EMAIL,
  USER_HAS_TOKEN,
  USER_ME_REQUEST_ERROR,
  USER_ME_REQUEST_SUCCESS,
  USER_REMOVE_PROFILE,
  USER_SET_IS_HOME,
  USER_SET_SELECTED_PROFILE,
  USER_UPDATE,
  USER_UPDATE_SELECTED_PROFILE,
  USER_UPDATE_PROFILE,
  USER_REMOVE_TAG_REQUEST_SUCCESS,
  USER_ADD_TAG_REQUEST_SUCCESS,
} from 'src/actions/user/user';
import { ANONYMOUS_PROFILE_ID, ANONYMOUS_USER_TYPE } from 'src/constants/user';

export const INITIAL_STATE: IUser = {
  confirmed: false,
  hasToken: false,
  identities: [],
  loggedIn: false,
  loginRequestPending: false,
  profiles: {
    no_user_profile: {
      gid: ANONYMOUS_PROFILE_ID,
      longIdentifier: ANONYMOUS_PROFILE_ID,
      meta: {
        isHome: true,
      },
      permissions: {},
      type: ANONYMOUS_USER_TYPE,
    },
  },
  receiveNewsletter: undefined,
  selectedProfileId: ANONYMOUS_PROFILE_ID,
};

type Actions = IChangeEmailAction
| IUserHasTokenAction
| ISetIsHomeAction
| ISetSelectedProfileAction
| IUpdateSelectedProfileAction
| IUserMeRequestErrorAction
| IUserMeRequestSuccessAction
| IRemoveProfileAction
| IAddProfileAction
| IUserUpdateAction
| IUpdateProfileAction
| IUserAddTagSuccessAction
| IUserRemoveTagSuccessAction
;

const userReducer = (state = INITIAL_STATE, action: Actions): IUser =>
  // this ignore line is here since typescript doesent understand the return statement
  // in case of USER_ME_REQUEST_SUCCESS
  // even though its valid for immer https://github.com/mweststrate/immer#returning-data-from-producers
  // @ts-ignore
  produce(state, (draft) => {
    switch (action.type) {
      case USER_HAS_TOKEN:
        draft.hasToken = true;
        draft.loginRequestPending = true;
        break;

      case USER_UPDATE:
      case USER_ME_REQUEST_SUCCESS:
        return {
          ...state,
          loginRequestPending: false,
          ...action.payload,
        };

      case USER_ME_REQUEST_ERROR:
        draft.hasToken = false;
        draft.loginRequestPending = false;
        draft.loggedIn = false;
        break;

      case USER_UPDATE_PROFILE:
      case USER_ADD_PROFILE:
        draft.profiles[action.payload.longIdentifier] = action.payload;
        break;

      case USER_UPDATE_SELECTED_PROFILE:
        draft.profiles[state.selectedProfileId] = { ...draft.profiles[state.selectedProfileId], ...action.payload };
        break;
      case USER_REMOVE_PROFILE:
        delete draft.profiles[action.payload];
        break;

      case USER_SET_SELECTED_PROFILE:
        draft.selectedProfileId = action.payload;
        break;

      case USER_CHANGE_EMAIL:
        draft.email = action.payload;
        if (draft.meta) {
          draft.meta.pendingEmailReconfirmation = true;
        }
        break;

      case USER_SET_IS_HOME:
        if (stateHasSelectedProfile(draft)) {
          draft.profiles[state.selectedProfileId].meta.isHome = action.payload;
        }
        break;

      case USER_REMOVE_TAG_REQUEST_SUCCESS:
        const selectedProfile = draft.profiles[state.selectedProfileId];
        if (action.meta && stateHasSelectedProfile(draft) && selectedProfile.tags) {
          const tagsWithoutOneToDestroy = selectedProfile.tags.filter(tag => tag.profileTagId !== action.meta);
          selectedProfile.tags = tagsWithoutOneToDestroy;
        }
        break;

      case USER_ADD_TAG_REQUEST_SUCCESS:
        if (action.payload && stateHasSelectedProfile(draft)) {
          const selectedProfile = draft.profiles[state.selectedProfileId];
          if (selectedProfile.tags) {
            selectedProfile.tags = [...selectedProfile.tags, action.payload];
          } else {
            selectedProfile.tags = [action.payload];
          }
        }
        break;
    }
  });

const stateHasSelectedProfile = (state: IUser): boolean => {
  const selectedProfileId = state.selectedProfileId;

  if (!state.profiles[selectedProfileId]) {
    reportError('Selected Profile Id does not exist in user profiles',
      {
        profiles: Object.keys(state.profiles).toString(),
        selectedProfileId,
      },
    );
    return false;
  }

  return true;
};

export default userReducer;
