// libs
import { groupBy } from 'lodash';

// interfaces
import {
  IEventsCategorizedResponse,
  IEventsWithCategory,
  IGroupedEventsResponse,
  IGroupedEvents,
} from 'src/api/interfaces/responses/categorized_events';
import { IFeedResponse, IPostGroup } from 'src/api/interfaces/responses/feed';
import { IPost } from 'src/api/interfaces/responses/post';
import {
  IFeedDecoderResults,
  IPostsRecord,
  GroupedPosts,
} from 'src/interfaces/feed';
import { IProfile } from 'src/interfaces/profile';
import { IReactionList } from 'src/interfaces/reactions';

// decoders
import { $pagination } from 'src/api/decoders/pagination';
import { $post } from 'src/api/decoders/post';

// helpers
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import { normalizeFeed } from 'src/utils/normalization';

type FeedReponse = IEventsCategorizedResponse | IFeedResponse | IGroupedEventsResponse;
export const $feed = (json: FeedReponse): IFeedDecoderResults => {
  const posts: IPostsRecord = {};
  let profiles: IProfile[] = [];
  const reactions: IReactionList = {};
  const groupedPosts: GroupedPosts = [];
  const canonicalShapeSlug: string | undefined = json.meta?.cluster?.identifier || json.meta?.canonical_shape?.slug;
  json.data.forEach((group: IPostGroup | IEventsWithCategory | IGroupedEvents) => {
    const { ids, map, reactionsList, authors } = normalizeFeed(group.items.map($post));

    Object.assign(posts, map);
    Object.assign(reactions, reactionsList);
    profiles = profiles.concat(authors);

    if ('category' in group) {
      groupedPosts.push({
        ids,
        label: group.category.name,
        slug: group.category.slug,
      });
    } else if ('label' in group) {
      groupedPosts.push({ ids, label: group.label, slug: group.slug });
    } else {
      groupedPosts.push({ ids });
    }
  });

  return {
    canonicalShapeSlug,
    groupedPosts,
    paginationInfo: json.meta && $pagination(json.meta),
    posts,
    profiles,
    reactions,
  };
};

export const $feedGroupedByDate = (json: IFeedResponse): IFeedDecoderResults => {
  const flattendPosts: IPost[] = [];
  for (const group of json.data) {
    for (const post of group.items) {
      flattendPosts.push(post);
    }
  }

  const groupedPosts: IGroupedEvents[] = Object.entries(groupBy(flattendPosts, (postResponse) =>
    postResponse.data.start_time && DateFormatter.calendarFromDayWithShortDate(postResponse.data.start_time))
  ).map(([date, items]) => ({ items, label: date, slug: encodeURIComponent(date) }));

  return $feed({ data: groupedPosts, meta: json.meta });
};
