import DateFormatter from './date_formatter/date_formatter';
import DateHelper from './date_helper/date';

// code in here with slight modification comes from
// https://github.com/CultureHQ/add-to-calendar wich saddly was not configurable
// enough for our puproses
// so if something breacks in her it might be already fixed/updated in that github repro

export interface IEvent {
  name: string;
  details: string;
  location?: string;
  startsAt: string;
  endsAt?: string;
  url: string;
}

const makeTime = (time: string) => new Date(time).toISOString().replace(/[-:]|\.\d{3}/g, '');

interface Query { [key: string]: undefined | boolean | number | string }

const makeUrl = (base: string, query: Query) => Object.keys(query).reduce(
  (accum, key, index) => {
    const value = query[key];

    if (value !== undefined) {
      return `${accum}${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(value)}`;
    }
    return accum;
  },
  base
);

const createDescription = (description: string, url?: string) => {
  let result = description;

  if (description.trim().length) {
    result += '\n\n';
  }

  return url
    ? result + url
    : result;
};

export const makeGoogleCalendarUrl = (event: IEvent) =>
  makeUrl('https://calendar.google.com/calendar/render', {
    action: 'TEMPLATE',
    dates: `${makeTime(event.startsAt)}/${
      event.endsAt
        ? makeTime(event.endsAt)
        : makeTime(DateFormatter.getEndTimeFromStartTime(event.startsAt, DateHelper.defaultEventDuration()))
    }`,
    details: createDescription(event.details, event.url),
    location: event.location,
    text: event.name,
  });

// replaces line breack, tabs and other escape charackters so a string can be embeded into another string
const escapeString = (str: string) =>
  JSON.stringify(str)
    .slice(1, -1); // removes the " quates generated by the JSON.stringify

interface IICSUrlConfig {
  urlAtEndOfDescription?: boolean;
}

export const makeICSCalendarUrl = (event: IEvent, config: IICSUrlConfig = {}) => {
  const components = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `URL:${event.url}`,
    `DTSTART:${makeTime(event.startsAt)}`,
    `DTEND:${event.endsAt ? makeTime(event.endsAt)
      : makeTime(DateFormatter.getEndTimeFromStartTime(event.startsAt, DateHelper.defaultEventDuration()))}`,
    `SUMMARY:${escapeString(event.name)}`,
    `DESCRIPTION:${escapeString(
      createDescription(event.details, config.urlAtEndOfDescription ? event.url : undefined)
    )}`,
    event.location && `LOCATION:${escapeString(event.location)}`,
    'END:VEVENT',
    'END:VCALENDAR',
  ].filter((val) => !!val);

  return encodeURI(`data:text/calendar;charset=utf8,${components.join('\n')}`);
};
