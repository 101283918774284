// interfaces
import {
  FetchFeedThunkAction,
  ICategorizedEventfeedRequestParams,
} from 'src/actions/feed/feed_interfaces';

// constants
import { FEED_TYPE_EVENT_CATEGORIZED, FEED_TYPE_EVENT_RELATED } from 'src/constants/feed';

// actions
import { fetchFeed, setFeedHash } from 'src/actions/feed/feed';

// helpers
import { fetchRelatedEventFeedPage } from 'src/actions/feed/events_feeds/related_events_feed';
import { addBoundingBox, addCategorizedRanges, hasBoundingBox } from 'src/actions/feed/feed_utils';
import api from 'src/api/';

const fetchCategorizedEventFeed = fetchFeed<ICategorizedEventfeedRequestParams>(
  FEED_TYPE_EVENT_CATEGORIZED,
  api.eventFeed.getCategorized,
);

export const fetchCategorizedEventFeedPage = (): FetchFeedThunkAction => {
  return async(dispatch, getState): Promise<any> => {
    const state = getState();

    if (!hasBoundingBox(state)) {
      return;
    }

    const action = dispatch(fetchCategorizedEventFeed({
      ...addBoundingBox(state),
      ...addCategorizedRanges(state),
    }));

    if (action) {
      // We want to fetch related feed after categorized
      // because of that we need to clean it first so we don't see it before categorized
      // after that we are waiting for categorized and then fetching related
      dispatch(setFeedHash(FEED_TYPE_EVENT_RELATED, ''));

      await action;

      dispatch(fetchRelatedEventFeedPage());
    }
  };
};
