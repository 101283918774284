// libs
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

// interfaces / constants
import { IPosition } from 'src/interfaces/location';
import { IRootState } from 'src/reducers/interface';

// classes / components
import LandingPage from 'src/components/landing_page/landing_page';

// helpers
import { showLoginModal, showRegistrationModal } from 'src/actions/app-state/app-state';

interface IDispatchFromProps {
  showLoginModal: () => void;
  showRegistrationModal: () => void;
}

interface IMapStateToProps {
  mapPosition?: IPosition;
}

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  mapPosition: state.appState.position,
});

const mapDispatchToProps: IDispatchFromProps = {
  showLoginModal,
  showRegistrationModal,
};

interface IOwnProps {
  page404?: boolean;
  getDefaultRedirectPath: () => string;
}

export default withRouter(
  connect<IMapStateToProps, IDispatchFromProps, IOwnProps & RouteComponentProps>(
    mapStateToProps,
    mapDispatchToProps,
  )(LandingPage));
