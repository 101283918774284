// libs
import * as React from 'react';

// interfaces / constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import { COLOR_TYPE_GRAY_TINT_1 } from 'src/utils/color';

// classes
import Icon from 'src/components/forms/icon/icon';

const cls = itemSegmentCls + 'right-arrow';

const SegmentRightArrow: React.FC = () => (
  <div className={cls}>
    <Icon name='arrow-right' size={16} color={COLOR_TYPE_GRAY_TINT_1} />
  </div>
);

export default SegmentRightArrow;
