import { IAvatarBadge } from 'src/components/avatar/avatar';
import { SponsorLevel } from 'src/interfaces/profile';
import { COLOR_TYPE_WHITE, COLOR_TYPE_SECONDARY4, COLOR_TYPE_PRIMARY } from 'src/utils/color';

const sponsorBadges: Record<SponsorLevel, IAvatarBadge> = {
  bronze: {
    backgroundColor: COLOR_TYPE_WHITE,
    borderColor: COLOR_TYPE_WHITE,
    icon: 'lopo-inverted-round',
    iconColor: COLOR_TYPE_PRIMARY,
  },
  silver: {
    backgroundColor: COLOR_TYPE_WHITE,
    borderColor: COLOR_TYPE_WHITE,
    icon: 'lopo-inverted-round',
    iconColor: COLOR_TYPE_SECONDARY4,
  },
};

export const getSponsorBadge = (sponsorLevel: SponsorLevel) => {
  return sponsorBadges[sponsorLevel];
};
