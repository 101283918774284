// libs
import * as React from 'react';

// components
import AuthUI from 'src/containers/auth_ui';

// interfaces / constants
import { MODAL_TYPE_RESET_PASSWORD } from 'src/interfaces/app-state';

// helpers

const ResetPassword: React.FC = () => (
  <AuthUI layout={MODAL_TYPE_RESET_PASSWORD} />
);

export default ResetPassword;
