// libs
import React, { useEffect } from 'react';

// components
import NewsFeedHighlightsPlaceholder
  from 'src/components/content_placeholder/layouts/news_feed_highlights_placeholder/news_feed_highlights_placeholder';
import HighlightItemSegment from 'src/components/item_segment/layouts/highlight/highlight';
import OpenGroupButton from 'src/components/post/open_group_button/open_group_button';

// constants
import { FRONTEND_FOLLOWEES_RECENT_FEED_PATH, FRONTEND_GROUP_FEED_PATH } from 'src/constants/urls';
import { IPost } from 'src/interfaces/posts';
import { textResources } from 'src/lang/de';

// helpers
import { useGetNewsFeedHighlights } from 'src/actions/feed/news_feed/highlights/highlights_hooks';
import { useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectNewsFeedHighlights, useSelectPostFromId } from 'src/reducers/feed/feed_hooks';
import { useSelectProfile } from 'src/reducers/profiles/profiles_hooks';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';

import './news_feed_highlights.scss';

const labels = textResources.newsfeed.highlights;
const icon = 'arrow-right';

const cls = createBemBlock('news-feed-highlights');

const Tile: React.FC = ({ children }) => (
  <div className={cls('tile')}>
    {children}
  </div>
);

const NewsFeedHighlightsTitle: React.FC = () => (
  <h2 className={cls('title')}>{labels.newsFromTheLast24Hours}</h2>
);

const NewsFeedHighlights: React.FC = () => {
  const getNewsFeedHighlights = useGetNewsFeedHighlights();
  const newsFeedHighlights = useSelectNewsFeedHighlights();
  const locationShape = useGetAppLocationShape();

  useEffect(() => {
    getNewsFeedHighlights();
  }, [getNewsFeedHighlights, locationShape]);

  const mostViewedPost = useSelectPostFromId(newsFeedHighlights?.mostViewedPost);
  const mostInteractedPost = useSelectPostFromId(newsFeedHighlights?.mostInteractedPost);
  const recentGroupPost = useSelectPostFromId(newsFeedHighlights?.recentGroupPost);
  const recentFolloweePost = useSelectPostFromId(newsFeedHighlights?.recentFolloweePost);
  const recentFolloweePostAuthor = useSelectProfile(recentFolloweePost?.authorId);

  if (newsFeedHighlights?.isLoading) {
    return (
      <>
        <NewsFeedHighlightsTitle />
        <NewsFeedHighlightsPlaceholder />
      </>
    );
  }

  const hasHighlights = (): boolean => {
    return !!mostViewedPost || !!mostInteractedPost || !!recentGroupPost || !!recentFolloweePost;
  };

  if (!newsFeedHighlights?.isLoading && !hasHighlights()) {
    return null;
  }

  const getPostTitle = (post: IPost): string => {
    if (post.survey) {
      return `${textResources.postTypes.survey}: ${post.surveyTitle}`;
    }
    return post.ingredients.title?.data.title || post.meta.title;
  };

  return (
    <div className={cls()}>
      <NewsFeedHighlightsTitle />
      {mostViewedPost &&
      <Tile>
        <HighlightItemSegment
          imageSrc={mostViewedPost.featuredImage}
          inclusionReason={labels.mostViewed(locationShape?.name || '')}
          navigation={{ url: mostViewedPost.urls.frontend }}
          title={getPostTitle(mostViewedPost)}
          inclusionReasonType={mostViewedPost.ingredients.inclusionReason?.data.type}
          post={mostViewedPost}
        />
      </Tile>
      }
      {mostInteractedPost &&
      <Tile>
        <HighlightItemSegment
          imageSrc={mostInteractedPost.featuredImage}
          inclusionReason={labels.mostInteracted(locationShape?.name || '')}
          navigation={{ url: mostInteractedPost.urls.frontend }}
          title={getPostTitle(mostInteractedPost)}
          inclusionReasonType={mostInteractedPost.ingredients.inclusionReason?.data.type}
          post={mostInteractedPost}
        />
      </Tile>
      }
      {recentGroupPost &&
      <Tile>
        <HighlightItemSegment
          imageSrc={recentGroupPost.featuredImage}
          inclusionReason={
            recentGroupPost?.ingredients.inclusionReason?.data.content || labels.recentGroupPostsTitleFallback}
          navigation={{ url: recentGroupPost.urls.frontend }}
          title={getPostTitle(recentGroupPost)}
          inclusionReasonType={recentGroupPost.ingredients.inclusionReason?.data.type}
          post={recentGroupPost}
        />
        <OpenGroupButton
          icon={icon}
          label={labels.moreGroupPosts}
          url={FRONTEND_GROUP_FEED_PATH}
        />
      </Tile>
      }
      {recentFolloweePost &&
      <Tile>
        <HighlightItemSegment
          imageSrc={recentFolloweePost.featuredImage}
          inclusionReason={labels.recentFolloweePostsTitle + recentFolloweePostAuthor?.name}
          navigation={{ url: recentFolloweePost.urls.frontend }}
          title={getPostTitle(recentFolloweePost)}
          inclusionReasonType={recentFolloweePost.ingredients.inclusionReason?.data.type}
          post={recentFolloweePost}
        />
        <OpenGroupButton
          icon={icon}
          label={labels.moreFolloweePosts}
          url={FRONTEND_FOLLOWEES_RECENT_FEED_PATH}
        />
      </Tile>
      }
    </div>
  );
};

export default NewsFeedHighlights;
