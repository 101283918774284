import { isString } from 'lodash';
import { Store } from 'redux';

import { PROFILE_HEADER } from 'src/constants/http-headers';
import { ANONYMOUS_PROFILE_ID } from 'src/constants/user';
import { IUser } from 'src/interfaces/user';

// pick only the property used by this concern from the IUser definition
type ProfileHeaderUserProps = 'selectedProfileId';
interface IPartialUserState extends Pick<IUser, ProfileHeaderUserProps> {}
export interface IPartialRootState {
  user: IPartialUserState;
}

export const loadCurrentProfile = (request: Request, store: Store<IPartialRootState>): Request => {
  const state = store.getState();
  const currentProfile = state.user.selectedProfileId;
  if (!isString(currentProfile) || currentProfile === ANONYMOUS_PROFILE_ID) {
    return request;
  }

  request.headers.set(PROFILE_HEADER, currentProfile);

  return request;
};
