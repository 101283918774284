// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants';
import { ITabItem } from './tab_item';

import './tabs.scss';

interface IProps {
  children: Array<React.ReactElement<ITabItem>>;
  className: string;
  onClick?: () => void;
}

const cls: string = CLASS_PREFIX + 'tabs';

const Tabs: React.FC<IProps> = ({ children, className, onClick }) => {
  const customCls = className;
  const classes = {
    [cls]: true,
    [customCls]: true,
  };
  return (
    <nav onClick={onClick} className={classNames(classes)}>
      {children}
    </nav>
  );
};

export default Tabs;
