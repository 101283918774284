// libs
import * as React from 'react';

// components
import AppStoreLinks from 'src/components/app_store_links/app_store_links';
import EmailConfirmMessage from 'src/components/email_confirm_message/email_confirm_message';
import Button, { BUTTON_TYPE_TEXT, BUTTON_TYPE_CONTAINED } from 'src/components/forms/button/button';

// constants / interfaces
import {
  MSGBOX_BUTTON_TYPE_PRIMARY,
  MSGBOX_BUTTON_TYPE_SECONDARY,
  IMessageBoxButton,
} from 'src/components/message_box/message_box';
import { ICTAButton, IconType } from 'src/components/message_segment/message_segment';
import {
  PROFILE_STEP_PROFILE_COMPLETE,
  PROFILE_STEP_EMAIL_CONFIRMED,
  PROFILE_STEP_VERIFIED,
  NO_PROFILE,
} from 'src/constants/user';
import { Reasons } from 'src/containers/smart_components/content_blocker_info/content_blocker_info';
import { IGroup } from 'src/interfaces/group';

// helpers
import { textResources } from 'src/lang/de';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { COLOR_TYPE_PRIMARY, COLOR_TYPE_PRIMARY_2 } from 'src/utils/color';
import initTracker, {
  CTA_COMPLETE_PROFILE,
  CTA_CONFIRM_EMAIL,
  CTA_VERIFY,
  CTA_NEW_USER,
} from 'src/utils/reporting/events_tracking/events_tracking';

const labels = textResources.intermediateProfile;
const { doItLater } = textResources.onboardingSettings;
const { genericErrorBody, login, register, registerNow } = textResources.shared;
const { infoCanJoin, infoCanNotJoin, infoLoggedOut, title } = textResources.groups.ctaNotJoinedFeed;
const cls = createBemBlock('cta-generator');
const clsPremium = createBemBlock('premium_content');
const clsListItem = clsPremium('list-item');

const trackerCompleteProfile = initTracker(CTA_COMPLETE_PROFILE);
const trackerConfirmEmail = initTracker(CTA_CONFIRM_EMAIL);
const trackerVerify = initTracker(CTA_VERIFY);
const trackerNewUserInfo = initTracker(CTA_NEW_USER);

export interface ICTAContent {
  buttons: IMessageBoxButton[];
  body: React.ReactChild;
  title?: string;
  bodyTitle: string;
  children?: JSX.Element;
}

export interface ICTAModalContent {
  image: IconType;
  title?: string;
  description: string | JSX.Element;
  buttons?: ICTAButton[];
  onClose?: () => void;
  children?: JSX.Element;
}

// Inline CTA templates
export const ctaCompleteProfile = (
  onClick: () => void,
  customContents?: Partial<Record<Reasons, Partial<ICTAContent> | undefined>> | undefined,
): ICTAContent => {
  const { body, bodyTitle, title } = labels.completeProfile;
  return {
    body: body,
    bodyTitle,
    buttons: [
      {
        label: title,
        onClick: onClick,
        type: MSGBOX_BUTTON_TYPE_PRIMARY,
      },
    ],
    title,
    ...(customContents?.[PROFILE_STEP_PROFILE_COMPLETE]),
  };
};

export const ctaConfirmEmail = (
  onClick: () => void,
  email?: string,
  customContents?: Partial<Record<Reasons, Partial<ICTAContent> | undefined>> | undefined,
): ICTAContent => {
  const { bodyTitle, buttonText, title } = labels.confirmEmail;
  return {
    body: (
      <div className={cls + '__body-content'}>
        <EmailConfirmMessage userEmailAddress={email} />
      </div>
    ),
    bodyTitle,
    buttons: [
      {
        label: buttonText,
        onClick,
        type: MSGBOX_BUTTON_TYPE_PRIMARY,
      },
    ],
    title,
    ...(customContents?.[PROFILE_STEP_EMAIL_CONFIRMED]),
  };
};

export const ctaVerifyProfile = (
  onClick: () => void,
  customContents?: Partial<Record<Reasons, Partial<ICTAContent> | undefined>> | undefined,
): ICTAContent => {
  const { body, bodyTitle, title } = labels.verify;
  return {
    body: body,
    bodyTitle,
    buttons: [
      {
        label: title,
        onClick,
        type: MSGBOX_BUTTON_TYPE_PRIMARY,
      },
    ],
    title,
    ...(customContents?.[PROFILE_STEP_VERIFIED]),
  };
};

export const canNotJoinGroups = (onClick: () => void, title: string): ICTAContent => {
  return {
    body: infoCanNotJoin,
    bodyTitle: title,
    buttons: [
      {
        label: textResources.groups.groupsInMyRegion,
        onClick: onClick,
        type: MSGBOX_BUTTON_TYPE_PRIMARY,
      },
    ],
    title,
  };
};

export const canJoinGroup = (joinGroup: (group: IGroup) => void, group: IGroup): ICTAContent => {
  return {
    body: infoCanJoin,
    bodyTitle: title,
    buttons: [
      {
        label: textResources.groups.joinGroup,
        onClick: () => {
          joinGroup(group);
        },
        type: MSGBOX_BUTTON_TYPE_PRIMARY,
      },
    ],
    title: group.name,
  };
};

export const groupsBlocked = (onClick: () => void): ICTAContent => {
  const { buttonLabel, title } = textResources.groups.ctaBlocked;
  return {
    body: genericErrorBody,
    bodyTitle: title,
    buttons: [
      {
        label: buttonLabel,
        onClick,
        type: MSGBOX_BUTTON_TYPE_PRIMARY,
      },
    ],
    title: textResources.groups.groups,
  };
};

export const groupsLoggedOut = (
  dispatchShowRegistrationModal: () => void,
  dispatchShowLoginModal: () => void
): ICTAContent => {
  return {
    body: infoLoggedOut,
    bodyTitle: title,
    buttons: [
      {
        label: register,
        onClick: dispatchShowRegistrationModal,
        type: MSGBOX_BUTTON_TYPE_PRIMARY,
      },
      {
        label: login,
        onClick: dispatchShowLoginModal,
        type: MSGBOX_BUTTON_TYPE_SECONDARY,
      },
    ],
  };
};

export const renderFencedRegistration = (
  showRegistrationModal: () => void,
  showLoginModal: () => void,
  customContents?: Partial<Record<Reasons, Partial<ICTAContent> | undefined>> | undefined
): ICTAContent => {
  const { body, bodyTitle, loginButton, registerButton, title } = labels.register;
  return {
    body,
    bodyTitle,
    buttons: [
      {
        label: registerButton,
        onClick: showRegistrationModal,
        type: MSGBOX_BUTTON_TYPE_PRIMARY,
      },
      {
        label: loginButton,
        onClick: showLoginModal,
        type: MSGBOX_BUTTON_TYPE_SECONDARY,
      },
    ],
    title,
    ...(customContents?.[NO_PROFILE]),
  };
};

export const renderPremiumRegistration = (
  dispatchShowRegistrationModal: () => void,
  dispatchShowLoginModal: () => void,
): ICTAContent => {
  const { body, bullet1, bullet2, bullet3, bodyTitle, title } = textResources.premiumContentInfo;
  return {
    body,
    bodyTitle,
    buttons: [
      {
        label: registerNow,
        onClick: dispatchShowRegistrationModal,
        type: MSGBOX_BUTTON_TYPE_PRIMARY,
      },
      {
        label: login,
        onClick: dispatchShowLoginModal,
        type: MSGBOX_BUTTON_TYPE_SECONDARY,
      },
    ],
    children: (
      <ul className={cls('body-list')}>
        <li className={clsListItem}>{bullet1}</li>
        <li className={clsListItem}>{bullet2}</li>
        <li className={clsListItem}>{bullet3}</li>
      </ul>
    ),
    title,
  };
};

// Modal CTA templates
export const renderCompleteProfile = (
  completeProfile: () => void,
  doItLaterButton: () => void,
  closeModal: () => void
): ICTAModalContent => {
  const { title, body } = labels.completeProfile;
  trackerCompleteProfile(CTA_COMPLETE_PROFILE.ACTIONS.OPEN, CTA_COMPLETE_PROFILE.LABELS.START);
  return {
    buttons: [
      {
        color: COLOR_TYPE_PRIMARY,
        label: title,
        onClick: completeProfile,
        variant: BUTTON_TYPE_CONTAINED,
      },
      {
        label: doItLater,
        onClick: doItLaterButton,
        variant: BUTTON_TYPE_TEXT,
      },
    ],
    description: body,
    image: 'complete-profile',
    onClose: closeModal,
    title,
  };
};

export const renderEmailConfirm = (
  sendEmailAgain: () => void,
  doItLaterButton: () => void,
  closeModal: () => void,
  userEmailAddress?: string,
): ICTAModalContent => {
  const { buttonText, title } = labels.confirmEmail;
  trackerConfirmEmail(CTA_CONFIRM_EMAIL.ACTIONS.OPEN, CTA_CONFIRM_EMAIL.LABELS.START);
  return {
    buttons: [
      {
        color: COLOR_TYPE_PRIMARY,
        label: buttonText,
        onClick: sendEmailAgain,
        variant: BUTTON_TYPE_CONTAINED,
      },
      {
        label: doItLater,
        onClick: doItLaterButton,
        variant: BUTTON_TYPE_TEXT,
      },
    ],
    description: <EmailConfirmMessage userEmailAddress={userEmailAddress} />,
    image: 'confirm-email',
    onClose: closeModal,
    title,
  };
};

export const renderNewUserInfo = (
  trackRegister: () => void,
  trackLogin: () => void,
  doItLaterButton: () => void,
  closeModal: () => void,
): ICTAModalContent => {
  const { body, title } = textResources.newUserInfoCTA;
  trackerNewUserInfo(CTA_NEW_USER.ACTIONS.OPEN, CTA_NEW_USER.LABELS.START);
  return {
    buttons: [
      {
        color: COLOR_TYPE_PRIMARY,
        label: registerNow,
        onClick: trackRegister,
        variant: BUTTON_TYPE_CONTAINED,
      },
      {
        color: COLOR_TYPE_PRIMARY_2,
        label: login,
        onClick: trackLogin,
        variant: BUTTON_TYPE_CONTAINED,
      },
    ],
    children:
      <>
        <div className={cls('store-links')}>
          <AppStoreLinks/>
        </div>
        <div className={cls('cancel-button')}>
          <Button
            label={doItLater}
            onClick={doItLaterButton}
            fullWidth
            lowerCase
          />
        </div>
      </>,
    description: body,
    image: 'new-user',
    onClose: closeModal,
    title,
  };
};

export const renderVerify = (
  goToContinueRegistration: () => void,
  doItLaterButton: () => void,
  closeModal: () => void,
): ICTAModalContent => {
  const { body, button, bodyTitle } = labels.verify;
  trackerVerify(CTA_VERIFY.ACTIONS.OPEN, CTA_VERIFY.LABELS.START);
  return {
    buttons: [
      {
        color: COLOR_TYPE_PRIMARY,
        label: button,
        onClick: goToContinueRegistration,
        variant: BUTTON_TYPE_CONTAINED,
      },
      {
        label: doItLater,
        onClick: doItLaterButton,
        variant: BUTTON_TYPE_TEXT,
      },
    ],
    description: body,
    image: 'complete-verification',
    onClose: closeModal,
    title: bodyTitle,
  };
};

// CTA tracking
export const trackClose = (profileStatus?: string) => {
  switch (profileStatus) {
    case PROFILE_STEP_PROFILE_COMPLETE:
      trackerCompleteProfile(CTA_COMPLETE_PROFILE.ACTIONS.CLOSE, CTA_COMPLETE_PROFILE.LABELS.START);
      break;
    case PROFILE_STEP_EMAIL_CONFIRMED:
      trackerConfirmEmail(CTA_CONFIRM_EMAIL.ACTIONS.CLOSE, CTA_CONFIRM_EMAIL.LABELS.START);
      break;
    case PROFILE_STEP_VERIFIED:
      trackerVerify(CTA_VERIFY.ACTIONS.CLOSE, CTA_VERIFY.LABELS.START);
      break;
    case NO_PROFILE:
      trackerNewUserInfo(CTA_NEW_USER.ACTIONS.CLOSE, CTA_NEW_USER.LABELS.START);
      break;
  }
};

export const trackContinueRegistration = (profileStatus?: string) => {
  switch (profileStatus) {
    case PROFILE_STEP_PROFILE_COMPLETE:
      trackerCompleteProfile(CTA_COMPLETE_PROFILE.ACTIONS.CONTINUE_REGISTRATION, CTA_COMPLETE_PROFILE.LABELS.START);
      break;
    case PROFILE_STEP_VERIFIED:
      trackerVerify(CTA_VERIFY.ACTIONS.CONTINUE_REGISTRATION, CTA_VERIFY.LABELS.START);
      break;
  }
};

export const trackDoItLater = (profileStatus?: string) => {
  switch (profileStatus) {
    case PROFILE_STEP_PROFILE_COMPLETE:
      trackerCompleteProfile(CTA_COMPLETE_PROFILE.ACTIONS.DO_IT_LATER, CTA_COMPLETE_PROFILE.LABELS.START);
      break;
    case PROFILE_STEP_EMAIL_CONFIRMED:
      trackerConfirmEmail(CTA_CONFIRM_EMAIL.ACTIONS.DO_IT_LATER, CTA_CONFIRM_EMAIL.LABELS.START);
      break;
    case NO_PROFILE:
      trackerNewUserInfo(CTA_NEW_USER.ACTIONS.DO_IT_LATER, CTA_NEW_USER.LABELS.START);
      break;
    case PROFILE_STEP_VERIFIED:
      trackerVerify(CTA_VERIFY.ACTIONS.DO_IT_LATER, CTA_VERIFY.LABELS.START);
      break;
  }
};
