// libs
import * as React from 'react';

// components
import EventFeedCalendar from 'src/containers/header/subheader/header_event_calendar';

const EventsFeedCalendarSubheader: React.FC = () => (
  <EventFeedCalendar />
);

/* set displayname to not have a component without a name */
EventsFeedCalendarSubheader.displayName = 'EventsFeedCalendarSubheader';

export default EventsFeedCalendarSubheader;
