// polyfills
import 'url-polyfill';
import 'whatwg-fetch';
// should be after the fetch polyfill
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';

// This code helps us to work around the 'illegal invocation' error. Remove once
// the problem has been resolved upstream.
import 'src/utils/ios_workaround';

// libs
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, RouteComponentProps } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

// classes
import UserTagmanagerData from 'src/containers/analytics/tag_manager';
import GlobalSnackBar from 'src/containers/global_snack_bar';
import RevisionCheck from 'src/containers/revision_check';
import UnseenNotificationsPolling from 'src/containers/unseen_notifications_polling';
import URLProvider from 'src/containers/url_provider/url_provider';
import UserAuth from 'src/containers/user_auth';
import { IAddress } from 'src/interfaces/address';
import { Routes } from 'src/routes';

// utils
import { AuthenticationHelper } from 'src//utils/authentication/authentication';
import { changeAppLocation, setNavSource } from 'src/actions/app-state/app-state';
import { getStore } from 'src/store/configure-store';
import { getConfigValue } from 'src/utils/config/config';
import initTracker, { LOGIN } from 'src/utils/reporting/events_tracking/events_tracking';
import { parseQueryString } from 'src/utils/url/url';

// interfaces / constants
import { IAction } from 'src/actions/';
import { logIn } from 'src/actions/user/user';
import {
  SettingsReferral,
  SETTINGS_ACTION_LOGIN,
  SETTINGS_ACTION_REGISTRATION,
  SETTINGS_STATUS_SUCCESS,
  SETTINGS_REFERRAL_APPLE,
  SETTINGS_REFERRAL_APPS,
  SETTINGS_REFERRAL_FACEBOOK,
} from 'src/constants/settings';
import { IOAuth2Credentials } from 'src/interfaces/oauth2';
import { IRootState } from 'src/reducers/interface';

import { MailboxWebSocketChannel } from '@lokalportal/frontends-shared';
import TagManager from 'react-gtm-module';
import 'src/fonts/fonts.scss';
import 'src/index.scss';
import { WebSocketProviderWrapper } from './containers/smart_components/WebSocketProviderWrapper';

const gtmId = getConfigValue('gtm.id');
gtmId && TagManager.initialize({ gtmId });

const loginTracker = initTracker(LOGIN);
const store = getStore();

const checkCredentials = ({ accessToken, createdAt, expiresIn, refreshToken }: IOAuth2Credentials) => {
  if (!accessToken || !createdAt || isNaN(createdAt) || !expiresIn || isNaN(expiresIn) || !refreshToken) {
    return undefined;
  }

  if (isNaN(createdAt) || isNaN(expiresIn)) {
    return undefined;
  }

  return { accessToken, createdAt, expiresIn, refreshToken };
};

const initializeLocation = () => {
  const defaultAddress = getConfigValue<IAddress>('featureFlags.location.defaultAddress');
  if (defaultAddress) {
    (store.dispatch as ThunkDispatch<IRootState, {}, IAction>)(changeAppLocation(
      defaultAddress.locationShape,
      {
        latitude: defaultAddress.latitude,
        longitude: defaultAddress.longitude,
      },
      undefined,
      undefined,
      defaultAddress.locationShape.boundingBox
    ));
  }
};

const params = parseQueryString<string, {
  as: string;
  action: string;
  ref: SettingsReferral;
  access_token: string;
  created_at: string;
  expires_in: string;
  'nav-source': string;
  refresh_token: string;
  status: string;
}>(window.location.search, [
  'as',
  'action',
  'ref',
  'access_token',
  'created_at',
  'expires_in',
  'nav-source',
  'refresh_token',
  'status',
]);

const navSource = params['nav-source'];

const fetchUserData = () => {
  (store.dispatch as ThunkDispatch<IRootState, {}, IAction>)(logIn(params.as, false));
};

const isAppleAuth = params.ref === SETTINGS_REFERRAL_APPLE;
const isAppsAuth = params.ref === SETTINGS_REFERRAL_APPS;
const isFacebookAuth = params.ref === SETTINGS_REFERRAL_FACEBOOK;

const credentials =
  (params.action === SETTINGS_ACTION_LOGIN || params.action === SETTINGS_ACTION_REGISTRATION) &&
    (isAppleAuth || isAppsAuth || isFacebookAuth) &&
    params.status === SETTINGS_STATUS_SUCCESS
    ? {
      accessToken: params.access_token,
      createdAt: parseInt(params.created_at, 10),
      expiresIn: parseInt(params.expires_in, 10),
      refreshToken: params.refresh_token,
    } : undefined;

if (credentials && checkCredentials(credentials)) {
  AuthenticationHelper.storeOAuth2Credentials(credentials);
}
if (AuthenticationHelper.checkTokenValidity()) {
  const trackingAction =
    isAppleAuth ? LOGIN.ACTIONS.APPLE : isAppsAuth ? LOGIN.ACTIONS.APPS : isFacebookAuth && LOGIN.ACTIONS.FACEBOOK;
  if (trackingAction) {
    loginTracker(trackingAction, LOGIN.LABELS.FINISH, fetchUserData);
  } else {
    fetchUserData();
  }
}

initializeLocation();

navSource && (store.dispatch as ThunkDispatch<IRootState, {}, IAction>)(setNavSource(navSource));

const renderUserAuth = (route: RouteComponentProps) => {
  return <UserAuth history={route.history} search={route.location.search} />;
};

const App = () => (
  <Provider store={store}>
    <WebSocketProviderWrapper>
      <Router>
        <URLProvider>
        <>
          <UserTagmanagerData />
          <Route render={renderUserAuth} />
          {/* !isNavSourceFromApp(navSource) && <CookieConsent /> */}
          <Route component={Routes} />
          <RevisionCheck />
          <UnseenNotificationsPolling />
          <MailboxWebSocketChannel />
          <GlobalSnackBar />
        </>
        </URLProvider>
      </Router>
    </WebSocketProviderWrapper>
  </Provider>
);

ReactDOM.render(
  <App />,
  document.getElementById('lopo-react-root'),
);
