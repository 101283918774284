// libs
import * as React from 'react';
import { RouteComponentProps } from 'react-router';

// interfaces / constants
import { ProfileType } from 'src/interfaces/profile';

// classes
import { IMailboxProps, Mailbox } from 'src/components/mailbox/mailbox';

// helpers
import { withRouterProps } from 'src/high_order_components/connect_route_props';

class MailBoxContainer extends React.PureComponent<IMailboxProps, {}> {
  public render(): JSX.Element {
    return <Mailbox {...this.props}/>;
  }
}

interface IConversationParams {
  id: string;
  type: ProfileType;
  thread: string;
}

const mapRouteToProps = ({ history, match }: RouteComponentProps<IConversationParams>): IMailboxProps => (
  {
    conversationId: match.params.thread,
    history,
    userId: match.params.id,
    userType: match.params.type,
  }
);

export default withRouterProps<IMailboxProps, {}>(mapRouteToProps)(MailBoxContainer);
