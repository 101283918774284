// libs
import produce from 'immer';
// interfaces / constants
import {
  EVENT_PARTICIPATION_CREATE_REQUEST_ERROR,
  EVENT_PARTICIPATION_CREATE_REQUEST_PENDING,
  EVENT_PARTICIPATION_CREATE_REQUEST_SUCCESS,
  EVENT_PARTICIPATION_DELETE_REQUEST_ERROR,
  EVENT_PARTICIPATION_DELETE_REQUEST_PENDING,
  EVENT_PARTICIPATION_DELETE_REQUEST_SUCCESS,
  EventParticipationAction,
} from 'src/actions/event_participation/event_participation';
import {
  FEED_CLEAR_ALL,
  FEED_NEXT_PAGE_REQUEST_ERROR,
  FEED_NEXT_PAGE_REQUEST_PENDING,
  FEED_NEXT_PAGE_REQUEST_SUCCESS,
  FEED_OPEN_GROUP,
  FEED_SET_FEED_HASH,
  FeedAction,
} from 'src/actions/feed/feed';
import {
  NewsFeedHighlightsAction,
  NEWS_FEED_GET_HIGHLIGHTS_ERROR,
  NEWS_FEED_GET_HIGHLIGHTS_PENDING,
  NEWS_FEED_GET_HIGHLIGHTS_SUCCESS,
} from 'src/actions/feed/news_feed/highlights/highlights';
import {
  POST_CREATE_REQUEST_SUCCESS,
  POST_DESTROY_REQUEST_SUCCESS,
  POST_REQUEST_ERROR,
  POST_REQUEST_PENDING,
  POST_REQUEST_SUCCESS,
  POST_UPDATE_REQUEST_SUCCESS,
  PostAction,
} from 'src/actions/post/post';
import {
  FEED_UPDATE_REACTION_ERROR,
  FEED_UPDATE_REACTION_PENDING,
  FEED_UPDATE_REACTION_SUCCESS,
  FeedReactionAction,
} from 'src/actions/reaction';
import {
  FEED_TYPE_DETAILS_PAGE,
  FEED_TYPE_EVENT,
  FEED_TYPE_EVENT_CATEGORIZED,
  FEED_TYPE_EVENT_PARTICIPATING,
  FEED_TYPE_EVENT_RELATED,
  FEED_TYPE_GROUP,
  FEED_TYPE_LOCAL,
  FEED_TYPE_NEWS,
  FEED_TYPE_PROFILE,
  FEED_TYPE_GROUPS_RECENT,
  FEED_TYPE_FOLLOWEEES_RECENT,
} from 'src/constants/feed';
import { Id } from 'src/interfaces';
import {
  IFeed,
  IFeeds,
  IFeedsGroups,
  IFeedType,
} from 'src/interfaces/feed';
import { INewsFeedHighlights } from 'src/interfaces/news_feed_highlights';
import { IPagination } from 'src/interfaces/pagination';
// helpers
import {
  cleanAllFeedsButMe,
  flattenOpenGroup,
  mergeDuplicatedGroups,
  removeUnusedPosts,
  reportReducerError,
  updateReactions,
} from 'src/reducers/feed/feed_utils';

const PARTICIPANTS = 'participants';

export const INITIAL_PAGINATION_INFO: Readonly<IPagination> = {
  currentPage: 0,
  lastPage: false,
  total: 0,
  totalPages: 0,
};

export const INITIAL_FEED: Readonly<IFeed> = {
  canonicalShapeSlug: '',
  feedHash: '',
  groupedPosts: [],
  hasError: false,
  isLoading: false,
  paginationInfo: Object.assign({}, INITIAL_PAGINATION_INFO),
  totalPagesViewed: 0,
};

export const INITIAL_FEEDS: Readonly<IFeedsGroups> = {
  [FEED_TYPE_DETAILS_PAGE]: INITIAL_FEED,
  [FEED_TYPE_EVENT]: INITIAL_FEED,
  [FEED_TYPE_EVENT_CATEGORIZED]: INITIAL_FEED,
  [FEED_TYPE_EVENT_PARTICIPATING]: INITIAL_FEED,
  [FEED_TYPE_EVENT_RELATED]: INITIAL_FEED,
  [FEED_TYPE_FOLLOWEEES_RECENT]: INITIAL_FEED,
  [FEED_TYPE_GROUP]: INITIAL_FEED,
  [FEED_TYPE_GROUPS_RECENT]: INITIAL_FEED,
  [FEED_TYPE_LOCAL]: INITIAL_FEED,
  [FEED_TYPE_NEWS]: INITIAL_FEED,
  [FEED_TYPE_PROFILE]: INITIAL_FEED,
};

const INITIAL_NEWS_FEED_HIGHLIGHTS: INewsFeedHighlights = {
  isLoading: false,
};

export const INITIAL_FEEDS_REDUCER: Readonly<IFeeds> = {
  feeds: INITIAL_FEEDS,
  newsFeedHighlights: INITIAL_NEWS_FEED_HIGHLIGHTS,
  posts: {},
  reactions: {},
};

type Actions = FeedAction | PostAction | FeedReactionAction | EventParticipationAction | NewsFeedHighlightsAction;

const feedReducer = (state = INITIAL_FEEDS_REDUCER, action: Actions): IFeeds =>
  produce(state, (draft) => {
    switch (action.type) {
      case FEED_NEXT_PAGE_REQUEST_SUCCESS: {
        if (!action.payload) {
          break;
        }
        const { feedType } = action.meta;
        draft.feeds[feedType].isLoading = false;
        draft.feeds[feedType].hasError = false;
        draft.feeds[feedType].paginationInfo =
          action.payload.paginationInfo || Object.assign({}, INITIAL_PAGINATION_INFO);
        draft.feeds[feedType].canonicalShapeSlug =
          action.payload.canonicalShapeSlug && action.payload.canonicalShapeSlug;

        if (!action.payload.groupedPosts.length) {
          break;
        }

        draft.feeds[feedType].totalPagesViewed = draft.feeds[feedType].totalPagesViewed + 1;

        draft.feeds[feedType].groupedPosts =
          mergeDuplicatedGroups(action.payload.groupedPosts, draft.feeds[feedType].groupedPosts);

        // Add new results (can be done before, check if mutate)
        draft.posts = Object.assign(draft.posts, action.payload.posts);
        draft.reactions = Object.assign(draft.reactions, action.payload.reactions);
        removeUnusedPosts(draft);
        break;
      }

      case FEED_NEXT_PAGE_REQUEST_PENDING: {
        const { feedType } = action.meta;
        draft.feeds[feedType].isLoading = true;
        draft.feeds[feedType].hasError = false;
        break;
      }
      case FEED_NEXT_PAGE_REQUEST_ERROR: {
        const { feedType } = action.meta;
        draft.feeds[feedType].isLoading = false;
        draft.feeds[feedType].hasError = true;
        reportReducerError(state, action);
        break;
      }

      case POST_REQUEST_PENDING:
        draft.feeds[FEED_TYPE_DETAILS_PAGE].isLoading = true;
        draft.feeds[FEED_TYPE_DETAILS_PAGE].hasError = false;
        break;

      case POST_REQUEST_ERROR:
        draft.feeds[FEED_TYPE_DETAILS_PAGE].isLoading = false;
        draft.feeds[FEED_TYPE_DETAILS_PAGE].hasError = true;
        reportReducerError(state, action);
        break;

      case FEED_OPEN_GROUP:
        draft.feeds[action.meta.feedType].groupedPosts =
          flattenOpenGroup(draft.feeds[action.meta.feedType].groupedPosts, action.payload);
        break;

      case POST_REQUEST_SUCCESS: {
        const { post, reactions } = action.payload;
        draft.feeds[FEED_TYPE_DETAILS_PAGE].groupedPosts = [{ ids: [post.id] }];
        draft.feeds[FEED_TYPE_DETAILS_PAGE].isLoading = false;
        draft.feeds[FEED_TYPE_DETAILS_PAGE].hasError = false;
        draft.posts[post.id] = post;
        draft.reactions[post.id] = reactions;
        break;
      }

      case FEED_SET_FEED_HASH:
        draft.feeds[action.meta.feedType] = { ...INITIAL_FEED, feedHash: action.payload };
        removeUnusedPosts(draft);
        break;

      case POST_UPDATE_REQUEST_SUCCESS: {
        if (!action.payload || !action.meta) {
          break;
        }
        const { post, reactions } = action.payload;
        const { postId } = action.meta;

        if (!postId) {
          break;
        }
        // if the post if updated, we need to do some cleaning
        if (postId !== post.id && draft.posts[postId]) {
          delete draft.posts[postId];
          delete draft.reactions[postId];

          // Clean all others
          cleanAllFeedsButMe(draft, FEED_TYPE_DETAILS_PAGE);
        }

        draft.feeds[FEED_TYPE_DETAILS_PAGE].groupedPosts = [{ ids: [post.id] }];
        draft.reactions[postId] = reactions;
        draft.posts[post.id] = post;
        break;
      }

      case FEED_CLEAR_ALL:
        draft.feeds = INITIAL_FEEDS;
        draft.posts = {};
        draft.reactions = {};
        break;

      case POST_CREATE_REQUEST_SUCCESS: {
        if (!action.payload) {
          break;
        }
        const { reactions, post } = action.payload;
        draft.feeds[FEED_TYPE_DETAILS_PAGE].groupedPosts = [{ ids: [post.id] }];
        draft.feeds[FEED_TYPE_DETAILS_PAGE].hasError = false;
        draft.posts[post.id] = post;
        draft.reactions[post.id] = reactions;
        cleanAllFeedsButMe(draft, FEED_TYPE_DETAILS_PAGE);
        break;
      }

      case POST_DESTROY_REQUEST_SUCCESS: {
        const { postId } = action.meta;
        if (!postId) {
          break;
        }

        Object.keys(draft.feeds).map((feedId: IFeedType) => {
          draft.feeds[feedId].groupedPosts = draft.feeds[feedId].groupedPosts
            .map((group) => {
              return { ...group,
                ids: group.ids.filter((value: Id) => {
                  return value !== postId;
                }) };
            }).filter((group) => group.ids.length);
        });

        delete draft.posts[postId];
        delete draft.reactions[postId];
        break;
      }
      case FEED_UPDATE_REACTION_PENDING: {
        const { postId } = action.meta;
        if (!postId || !draft.posts[postId]) {
          reportReducerError(state, action);
          break;
        }

        draft.reactions[postId].reactions =
          updateReactions(draft.reactions[postId].reactions, action.meta.bookmarkType);
        break;
      }
      case FEED_UPDATE_REACTION_ERROR: {
        const { postId } = action.meta;
        if (!postId || !draft.posts[postId]) {
          reportReducerError(state, action);
          break;
        }
        draft.reactions[postId].reactions =
          updateReactions(draft.reactions[postId].reactions, action.meta.oldType);
        break;
      }
      case FEED_UPDATE_REACTION_SUCCESS: {
        const { postId } = action.meta;
        if (!postId || !draft.posts[postId]) {
          reportReducerError(state, action);
          break;
        }
        if (action.payload) {
          draft.reactions[postId] = action.payload;
        }

        break;
      }
      case EVENT_PARTICIPATION_CREATE_REQUEST_ERROR:
      case EVENT_PARTICIPATION_DELETE_REQUEST_ERROR: {
        const { postId } = action.meta;
        if (!postId || !draft.posts[postId]) {
          reportReducerError(state, action);
          break;
        }
        draft.posts[postId].ingredients[PARTICIPANTS]!.data.isLoading = false;
        break;
      }
      case EVENT_PARTICIPATION_CREATE_REQUEST_PENDING:
      case EVENT_PARTICIPATION_DELETE_REQUEST_PENDING: {
        const { postId } = action.meta;
        if (!postId || !draft.posts[postId]) {
          reportReducerError(state, action);
          break;
        }
        draft.posts[postId].ingredients[PARTICIPANTS]!.data.isLoading = false;
        break;
      }
      case EVENT_PARTICIPATION_CREATE_REQUEST_SUCCESS:
      case EVENT_PARTICIPATION_DELETE_REQUEST_SUCCESS: {
        const { postId } = action.meta;
        if (!postId || !draft.posts[postId]) {
          reportReducerError(state, action);
          break;
        }
        const data = draft.posts[postId].ingredients[PARTICIPANTS]!.data;
        data.isParticipating = !data.isParticipating;
        data.isLoading = false;
        data.participantsCount += data.isParticipating ? 1 : -1;
        draft.feeds[FEED_TYPE_EVENT_PARTICIPATING] = Object.assign({}, INITIAL_FEED);
        break;
      }

      case NEWS_FEED_GET_HIGHLIGHTS_PENDING:
        draft.newsFeedHighlights.isLoading = true;
        break;

      case NEWS_FEED_GET_HIGHLIGHTS_SUCCESS:
        draft.newsFeedHighlights.isLoading = false;

        if (action.payload) {
          const { mostInteractedPost, mostViewedPost, recentFolloweePost, recentGroupPost } = action.payload;
          draft.newsFeedHighlights.mostInteractedPost = mostInteractedPost;
          draft.newsFeedHighlights.mostViewedPost = mostViewedPost;
          draft.newsFeedHighlights.recentFolloweePost = recentFolloweePost;
          draft.newsFeedHighlights.recentGroupPost = recentGroupPost;

          draft.posts = Object.assign(draft.posts, action.payload.posts);
          draft.reactions = Object.assign(draft.reactions, action.payload.reactions);
        }
        break;

      case NEWS_FEED_GET_HIGHLIGHTS_ERROR:
        draft.newsFeedHighlights.isLoading = false;
        break;
    }
  });

export default feedReducer;
