// libs
import { useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

// helpers
import { parseQueryString } from 'src/utils/url/url';

export const useGetQueryParam = (key: string) => {
  const { search } = useLocation();

  return useMemo(() => {
    return parseQueryString(search, [key])[key];
  }, [search, key]);
};

export const useSetQueryParam = (key: string) => {
  const history = useHistory();
  const { search } = useLocation();

  return useCallback((query: string) => {
    const cleanQuery = query.trim();
    const searchParams = new URLSearchParams(search);

    if (!cleanQuery) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, cleanQuery);
    }

    history.replace({ search: searchParams.toString() });
  }, [history, key, search]);
};
