// libs
import * as React from 'react';
import Button, { BUTTON_TYPE_CONTAINED } from 'src/components/forms/button/button';
import Icon from 'src/components/forms/icon/icon';
import { CLASS_PREFIX } from 'src/constants/';

// constants / interfaces
import { DEFAULT_SNACKBAR_TIMEOUT } from 'src/constants/timeouts';
import { textResources } from 'src/lang/de';

// classes
import withPortal from 'src/high_order_components/with_portal';

// helpers
import './snack_bar.scss';

export interface IProps {
  message?: string;
  showButton?: boolean;
  showClose?: boolean;
  onClose?: (id?: number) => void;
  id?: number;
}

const cls: string = CLASS_PREFIX + 'snack-bar';

class SnackBar extends React.PureComponent<IProps> {
  private timeoutId: number; // for automatic closing set by closeTimeOut prop

  constructor(props: IProps) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
  }

  public componentDidMount() {
    const { showClose, showButton } = this.props;
    if (!showClose && !showButton) {
      this.closeOnTimeOut(DEFAULT_SNACKBAR_TIMEOUT);
    }
  }

  public componentWillUnmount() {
    this.clearTimeout();
  }

  public render() {
    const { children, showButton, showClose, message } = this.props;

    return (
      <aside className={cls}>
        <div className={cls + '__container'}>
          <div className={cls + '__message'}>
            {message && message}
            {children}
          </div>
          {showButton &&
          <div className={cls + '__button'}>
            <Button
              variant={BUTTON_TYPE_CONTAINED}
              label={textResources.shared.understood}
              onClick={this.closeModal}
            />
          </div>
          }
        </div>
        {showClose &&
        <div className={cls + '__close'} onClick={this.closeModal}>
          <Icon name='thin-x' description={textResources.screenReaderText.close} />
        </div>
        }
      </aside>
    );
  }

  private closeModal() {
    this.clearTimeout();
    this.close();
  }

  private close() {
    this.props.onClose && this.props.onClose(this.props.id);
  }

  private closeOnTimeOut(closeTimeOut: number) {
    this.timeoutId = window.setTimeout(() => this.close(), closeTimeOut);
  }

  private clearTimeout() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}

export default withPortal(SnackBar);
