// libs
import * as H from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

// interfaces / constants
import { IAction } from 'src/actions/';
import {
  OnboardingCTAType,
} from 'src/constants/cta';
import {
  SETTINGS_ACTION_ADD_EMAIL,
  SETTINGS_ACTION_CHANGE,
  SETTINGS_ACTION_REGISTRATION,
  SETTINGS_ERROR_CODE_USER_ALREADY_REGISTERED,
  SETTINGS_REFERRAL_APPLE,
  SETTINGS_REFERRAL_EMAIL,
  SETTINGS_REFERRAL_FACEBOOK,
  SETTINGS_STATUS_ERROR,
  SETTINGS_STATUS_SUCCESS,
  SettingsErrorCode,
  SettingsReferral,
} from 'src/constants/settings';
import {
  ModalType,
  AuthModalType,
  possibleAuthModals,
  possibleCTA,
} from 'src/interfaces/app-state';
import { IUser } from 'src/interfaces/user';
import { IRootState } from 'src/reducers/interface';

// components
import AuthModal from 'src/components/authentication/auth_modal/auth_modal';
import CTAGenerator from 'src/components/cta/cta_generator';
import SnackBar from 'src/components/snack_bar/snack_bar';

// helpers
import { hideModal, showRegistrationModal, showLoginModal } from 'src/actions/app-state/app-state';
import Translate, { textResources } from 'src/lang/de';
import { loginWithFacebook, loginWithApple } from 'src/utils/authentication/authentication';
import initTracker, {
  REGISTRATION,
} from 'src/utils/reporting/events_tracking/events_tracking';
import { parseQueryString } from 'src/utils/url/url';

interface IOwnProps {
  identifier?: string;
  history: H.History;
  search: string;
}

interface IMapStateToProps {
  profile: IUser;
  profileLoaded: boolean;
  shown: ModalType;
  userIsLoggedIn: boolean;
}

interface IDispatchFromProps {
  dispatchHideModal: () => void;
  dispatchShowLoginModal: () => void;
  dispatchShowRegistrationModal: () => void;
}

const isOnBoardingCTAType = (shown: ModalType): shown is OnboardingCTAType => {
  return possibleCTA.includes(shown);
};

const isAuthCTAType = (shown: ModalType): shown is AuthModalType => {
  return possibleAuthModals.includes(shown);
};

type IUserAuthProps = IOwnProps & IMapStateToProps & IDispatchFromProps;

const registerTracker = initTracker(REGISTRATION);

const mapStateToProps = (state: IRootState): IMapStateToProps => ({
  profile: state.user,
  profileLoaded: !state.user.profiles.no_user_profile,
  shown: state.appState.modal,
  userIsLoggedIn: state.user.loggedIn,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<IRootState, {}, IAction>): IDispatchFromProps => ({
  dispatchHideModal: () => dispatch(hideModal()),
  dispatchShowLoginModal: () => dispatch(showLoginModal()),
  dispatchShowRegistrationModal: () => dispatch(showRegistrationModal()),
});

interface IState {
  showSnackBar: boolean;
  snackBarMessage: string | undefined;
}

class UserAuth extends React.Component<IUserAuthProps, IState> {
  constructor(props: IUserAuthProps) {
    super(props);

    const { search } = props;
    const params = parseQueryString<string, {
      action: string;
      error_code: SettingsErrorCode | undefined;
      ref: SettingsReferral;
      status: string;
    }>(search, [
      'action',
      'error_code',
      'ref',
      'status',
    ]);

    const isAppleAuth = params.ref === SETTINGS_REFERRAL_APPLE;
    const isFacebookAuth = params.ref === SETTINGS_REFERRAL_FACEBOOK;
    const isFacebookRegistrationWithAlreadyRegisteredUser =
      isFacebookAuth && params.error_code === SETTINGS_ERROR_CODE_USER_ALREADY_REGISTERED;
    const isAppleRegistrationWithAlreadyRegisteredUser =
      isAppleAuth && params.error_code === SETTINGS_ERROR_CODE_USER_ALREADY_REGISTERED;

    let showSnackBar = (params.status === 'error' &&
      params.error_code !== undefined &&
      !isFacebookRegistrationWithAlreadyRegisteredUser && !isAppleRegistrationWithAlreadyRegisteredUser) ||
      params.ref === SETTINGS_REFERRAL_EMAIL;

    const errorMessage =
      this.validateProps(params.action, params.ref, params.status) ||
      params.error_code && Translate.settingsPageError[params.error_code] ||
      textResources.shared.errorUnknown;

    // track Facebook or Apple registration
    if (params.action === SETTINGS_ACTION_REGISTRATION && (isFacebookAuth || isAppleAuth)) {
      if (params.status === SETTINGS_STATUS_SUCCESS) {
        isAppleAuth && registerTracker(REGISTRATION.ACTIONS.APPLE, REGISTRATION.LABELS.FINISH);
        isFacebookAuth && registerTracker(REGISTRATION.ACTIONS.FACEBOOK, REGISTRATION.LABELS.FINISH);
      } else if (params.error_code === SETTINGS_ERROR_CODE_USER_ALREADY_REGISTERED) {
        isAppleAuth && registerTracker(REGISTRATION.ACTIONS.APPLE, REGISTRATION.LABELS.FAIL);
        isFacebookAuth && registerTracker(REGISTRATION.ACTIONS.FACEBOOK, REGISTRATION.LABELS.FAIL);
      } else {
        isAppleAuth && registerTracker(REGISTRATION.ACTIONS.APPLE, REGISTRATION.LABELS.FAIL);
        isFacebookAuth && registerTracker(REGISTRATION.ACTIONS.FACEBOOK, REGISTRATION.LABELS.FAIL);
        showSnackBar = true;
      }
    }

    this.state = {
      showSnackBar,
      snackBarMessage: errorMessage || this.validateProps(params.action, params.ref, params.status),
    };

    this.closeSnackbar = this.closeSnackbar.bind(this);

    if (isFacebookRegistrationWithAlreadyRegisteredUser) {
      loginWithFacebook();
      return;
    }

    if (isAppleRegistrationWithAlreadyRegisteredUser) {
      loginWithApple();
    }
  }
  public render() {
    const { dispatchHideModal, history, profile, shown, dispatchShowLoginModal,
      dispatchShowRegistrationModal } = this.props;

    return (
      <>
        {
          this.state.showSnackBar &&
          <SnackBar
            showClose={false}
            message={this.state.snackBarMessage}
            onClose={this.closeSnackbar}
          />
        }
        {
          isAuthCTAType(shown) &&
            <AuthModal layout={shown} onClose={dispatchHideModal} />
        }
        {
          isOnBoardingCTAType(shown) &&
          <CTAGenerator
            history={history}
            dispatchHideModal={dispatchHideModal}
            userEmailAddress={profile.email}
            showRegistration={dispatchShowRegistrationModal}
            showLogin={dispatchShowLoginModal}
          />
        }
      </>
    );
  }

  private validateProps(action: string, referral: string, status: string): string | undefined {
    const labels = textResources.onboardingSettings;
    if (action === SETTINGS_ACTION_CHANGE && referral === SETTINGS_REFERRAL_EMAIL) {
      if (status === SETTINGS_STATUS_ERROR) {
        return labels.emailChangeError;
      }

      if (status === SETTINGS_STATUS_SUCCESS) {
        return labels.emailChangeSuccess;
      }
    }

    if (action === SETTINGS_ACTION_REGISTRATION && referral === SETTINGS_REFERRAL_EMAIL) {
      if (status === SETTINGS_STATUS_ERROR) {
        return labels.emailRegistrationError;
      }

      if (status === SETTINGS_STATUS_SUCCESS) {
        registerTracker(REGISTRATION.ACTIONS.EMAIL_VERIFICATION, REGISTRATION.LABELS.FINISH);
        return labels.emailRegistrationSuccess;
      }
    }

    if (action === SETTINGS_ACTION_ADD_EMAIL && status === SETTINGS_STATUS_ERROR &&
      referral === SETTINGS_REFERRAL_FACEBOOK) {
      return labels.facebookRegistrationError;
    }
    return undefined;
  }

  private closeSnackbar() {
    this.setState({ showSnackBar: false });
  }
}

export default connect<IMapStateToProps, IDispatchFromProps, IOwnProps>(mapStateToProps, mapDispatchToProps)(UserAuth);
