// libs
import { isEqual } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

// components

// interfaces
import { ILocationShape } from 'src/interfaces/location';
import { IUserProfile } from 'src/interfaces/user';
import { IRootState } from 'src/reducers/interface';

// helpers
import GoogleTagManager from 'src/utils/reporting/google-tag-manager';

interface IUserTagmanagerDataProps {
  homeLocationShape?: ILocationShape;
  profile?: IUserProfile;
  appLocationShape?: ILocationShape;
}

const mapStateToProps = (state: IRootState): IUserTagmanagerDataProps => {
  const address = state.user.profiles[state.user.selectedProfileId].address;

  return {
    appLocationShape: state.appState.locationShape,
    homeLocationShape: address && address.locationShape,
    profile: state.user.profiles[state.user.selectedProfileId],
  };
};

class UserTagmanagerData extends React.PureComponent<IUserTagmanagerDataProps, {}> {
  public componentDidUpdate(prevProps: IUserTagmanagerDataProps) {
    const { homeLocationShape, profile, appLocationShape } = this.props;

    if (homeLocationShape !== undefined && !isEqual(prevProps.homeLocationShape, homeLocationShape)) {
      GoogleTagManager.pushHomeLocationShape(homeLocationShape);
    }

    if (profile !== undefined && !isEqual(prevProps.profile, profile)) {
      GoogleTagManager.pushProfileExtension(profile);
    }

    if (appLocationShape !== undefined && !isEqual(prevProps.appLocationShape, appLocationShape)) {
      GoogleTagManager.pushViewLocationShape(appLocationShape);
    }
  }

  public render() {
    return null;
  }
}

export default connect<IUserTagmanagerDataProps, {}, {}>(
  mapStateToProps,
)(UserTagmanagerData);
