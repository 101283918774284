// libs
import * as React from 'react';

// components
import ShareMenuOnMobile from 'src/components/share/share_popup_vertical/share_popup_vertical';
import ShareSegmentSmallOnDesktop from 'src/components/share/share_segment_small/share_segment_small';

// interfaces / constants
import { ITrackingType } from 'src/high_order_components/with_tracker_props';
import { HeaderDetailsObjType } from 'src/utils/reporting/events_tracking/events_tracking';

interface IShareHeaderProps {
  url: string;
  utmSource: string;
  tracking?: ITrackingType<HeaderDetailsObjType>;
}

const ShareHeader: React.FC<IShareHeaderProps> = ({ url, utmSource, tracking }) => {
  return (
    <>
      <ShareSegmentSmallOnDesktop
        url={url}
        utmSource={utmSource}
        tracking={tracking}
      />
      <ShareMenuOnMobile
        url={url}
        utmSource={utmSource}
        tracking={tracking}
      />
    </>
  );
};

export default ShareHeader;
