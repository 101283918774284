// libs
import { Store } from 'redux';

// interfaces / constants
import { IAction } from 'src/actions/';
import {
  HEADER_LOCATION_SHAPE_IDS,
  HEADER_POSITION_LATITUDE,
  HEADER_POSITION_LONGITUDE,
  HEADER_USER_IS_HOME,
} from 'src/constants/http-headers';
import { IKeyValue } from 'src/interfaces/';
import { IAppState } from 'src/interfaces/app-state';

// pick only the properties used by this concern from the IAppState definition
type PositionHeaderAppStateProps = 'locationShapeIds' | 'position';
interface IPartialAppState extends Pick<IAppState, PositionHeaderAppStateProps> {}
export interface IPartialRootState {
  appState: IPartialAppState;
}

const validateNumeric = (id: string) => !isNaN(parseInt(id, 10));

export function setLocationAndPositionHeadersFromStore(store: Store<IPartialRootState>): IKeyValue {
  const result: IKeyValue = {};

  const state = store.getState();

  const locationShapeIds = state.appState.locationShapeIds;
  if (locationShapeIds !== undefined) {
    result[HEADER_LOCATION_SHAPE_IDS] = locationShapeIds.join(',');
  }

  const position = state.appState.position;
  if (position !== undefined) {
    result[HEADER_POSITION_LATITUDE] = position.latitude.toString();
    result[HEADER_POSITION_LONGITUDE] = position.longitude.toString();
  }

  return result;
}

export function dispatchLocationToStore(
  response: Response,
  dispatchFn: (ids: string[]) => IAction,
  store: Store<IPartialRootState>,
): void {
  if (!response.ok) { return; }
  if (!response.headers.has(HEADER_LOCATION_SHAPE_IDS)) { return; }

  const header: string | null = response.headers.get(HEADER_LOCATION_SHAPE_IDS);
  if (header === null) { return; }

  const ids: string[] = header.replace(/\s/g, '').split(',').filter(validateNumeric);

  if (ids.length === 0) { return; }

  store.dispatch(dispatchFn(ids));
}

export function dispatchUserIsHomeToStore(
  response: Response,
  dispatchFn: (home: boolean) => IAction,
  store: Store<IPartialRootState>,
): void {
  const header: string | null = response.headers.get(HEADER_USER_IS_HOME);
  if (header === null) {
    store.dispatch(dispatchFn(false));
  } else {
    store.dispatch(dispatchFn(header.toLowerCase() === 'true'));
  }
}
