import moment from 'moment';

import { API_ERROR } from 'src/constants/api';
import { SHOULD_NOT_SEE_LANDING_PAGE } from 'src/constants/landing_page_cookie';
import { IWindowJsEnv } from 'src/interfaces/index';
import { ILocationShape } from 'src/interfaces/location';

import { cleanShapeObject, convertLegacyToBoundingbox } from 'src/utils/location';
import GoogleTagManager from 'src/utils/reporting/google-tag-manager';
import { reportError } from 'src/utils/reporting/report-errors';
import * as storageWrapper from 'src/utils/storage_wrapper/storage_wrapper';

const _window = window as unknown as IWindowJsEnv;
const jsEnv = _window.js_env;
const USER_ANNOYED_AT = 'userAnnoyedAt';

export const isReadyToAnnoyUser = (): boolean => {
  const userAnnoyedAt = storageWrapper.getItem(USER_ANNOYED_AT);
  if (userAnnoyedAt === null) {
    return true;
  }

  const dayDifference = moment().diff(userAnnoyedAt, 'seconds');
  return dayDifference > jsEnv.userAnnoyer.timeBetweenModals;
};

export const setUserAnnoyedAt = (): void => {
  storageWrapper.setItem(USER_ANNOYED_AT, moment().format());
};

// for legacy reason, we keep it as "address" since this was the name in the past
// and we see no reason to migrte a string name. Address, in this case = location.
export const LAST_ADDRESS_STORAGE_KEY = 'lastAddress';

export class StoredLocationHelper {
  public static getLastLocation(): ILocationShape | undefined {
    return this.parseStoredLastLocation();
  }

  // stores a location as last location
  public static storeLastLocation(locationShape: ILocationShape): void {
    const jsoShape = JSON.stringify(locationShape);
    storageWrapper.setItem(LAST_ADDRESS_STORAGE_KEY, jsoShape);
    // Setting a flag-cookie that indicates, that there is a location in the local storage
    // is important for our backend to know to redirect a user to the landing page or not.
    // DO NOT REMOVE TIS LINE. see !4028
    storageWrapper.setCookieItem(SHOULD_NOT_SEE_LANDING_PAGE, true);
  }

  // removes any stored last location
  public static removeLastLocation(): void {
    storageWrapper.removeItem(LAST_ADDRESS_STORAGE_KEY);
    // Removing a flag-cookie that indicates, that there is a location in the local storage
    // is important for our backend to know to redirect a user to the landing page or not.
    // DO NOT REMOVE TIS LINE. see !4028
    storageWrapper.removeCookieItem(SHOULD_NOT_SEE_LANDING_PAGE);
  }

  private static getStoredLastLocation(): string | null {
    return storageWrapper.getItem(LAST_ADDRESS_STORAGE_KEY);
  }

  private static parseStoredLastLocation(): ILocationShape | undefined {
    const location = this.getStoredLastLocation();

    if (!location) {
      return undefined;
    }

    let parsedLocation;

    try {
      parsedLocation = JSON.parse(location);
    } catch (error) {
      this.removeLastLocation();
      reportError('Unable to JSON.parse stored last location - deleted storage now', {
        error,
        location,
      });
      return undefined;
    }

    /*
    What do we convert here?
    We had several cases of old and weird locations in the locations int he local storage:

      1. it could look like IPopularPlacesToggle (angular times)
      2. it could look like IMigrationPopularPlacesToggle (migration from angular times)
      3. it could look like the IAddress interface, which we migrate from
      4. the above are the known types, but we've seen sometimes also only IBoundingBox for some reason

      Many users, in their whole lifetime, won't change their location, so an old local storage,
      will not be re-written.
      We now migrate from IAddress to ILocationShape, and while doing so, we try to fix all the old ways,
      and catch some things we did not know about.

      In the following case, we assume, that this is a normal migration,
      so if it is not `ILocationShape` (as checked in the `isLocation` function above(
      we assume that it is `IAdress`, typings wise, but we do aware, that for a while **anything** can come pass here.
      we aim to catch it!
*/

    // strip old key wrapping, see migration comments
    if (parsedLocation.locationShape) {
      GoogleTagManager.pushEventWithValue(API_ERROR, 'strip old key wrapping', location);
      parsedLocation = parsedLocation.locationShape;
    }

    try {
      // we have an old bounding box inside our shape - convert that Angular!
      // if there is not bounding box, this shall also explode.
      if (parsedLocation.boundingBox.northEast) {
        GoogleTagManager.pushEventWithValue(API_ERROR, 'needed to convert legacyBoundingBox', location);
        parsedLocation.boundingBox = convertLegacyToBoundingbox(parsedLocation.boundingBox);
      }
      parsedLocation = cleanShapeObject(parsedLocation);
    } catch (error) {
      // ok, something went wrong here, remove and report:
      // PLEASE READ #4144 !!
      this.removeLastLocation();
      reportError('Unable to convert stored last location - deleted storage now', {
        error,
        location,
        parsedLocation,
      });
      return undefined;
    }

    return parsedLocation;
  }
}
