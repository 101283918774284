/**
  This component is being used for wrapping a component and show it fullscreen, centered on the screen.
  The Overlay is not clickable and is blocking other clicks.
  For interaction (onClicks, trigger) please use PopoverLib.
*/

// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import './overlay.scss';

const cls = CLASS_PREFIX + 'overlay';

const Overlay: React.FC = ({ children }) => {
  return (
    <div className={cls}>
      {children}
    </div>
  );
};

export default Overlay;
