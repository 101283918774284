// libs
import produce from 'immer';

// interfaces / constants
import { IAction } from 'src/actions/';
import {
  FILTER_SET_ACTIVE_FILTER,
  FILTER_SET_DATE_RANGE_FILTER,
} from 'src/actions/filters/filters';
import { IFilter, IFilterReducer } from 'src/interfaces/filters';

// helpers
import DateFormatter from 'src/utils/date_formatter/date_formatter';

const INITIAL_FILTERS: IFilter[] = [];

export const INITIAL_STATE: IFilterReducer = {
  activeFilter: { id: 'default', label: 'default' },
  currentFilters: INITIAL_FILTERS,
  dateFilterRange: { end: undefined, start: DateFormatter.getToday() },
};

const filterReducer = (state = INITIAL_STATE, action: IAction): IFilterReducer =>
  produce(state, (draft) => {
    switch (action.type) {
      case FILTER_SET_DATE_RANGE_FILTER:
        draft.dateFilterRange = action.payload;
        break;
      case FILTER_SET_ACTIVE_FILTER:
        draft.activeFilter = action.payload;
        break;
    }
  });

export default filterReducer;
