// lib
import React from 'react';

// components
import PageTitle from 'src/components/header/main/page_title/page_title';

// helpers
import { useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectIsUserHome } from 'src/reducers/user/user_hooks';
import { shouldShowLocationForInstance } from 'src/utils/page_title/page_title';

export type PageTitlePossibleTags = 'p';

interface IProps {
  customTag?: PageTitlePossibleTags;
  showLocation?: boolean;
  text: string;
}

const StandardPageTitle: React.FC<IProps> = ({
  customTag,
  showLocation,
  text,
}) => {
  const isHome = useSelectIsUserHome();
  const appLocationShape = useGetAppLocationShape();
  const locationShown = shouldShowLocationForInstance(showLocation);
  return (
    <PageTitle
      customTag={customTag}
      isHome={isHome}
      text={text}
      location={appLocationShape?.name}
      showLocation={locationShown}
    />
  );
};

export default StandardPageTitle;
