// lib
import * as React from 'react';

// components
import Toggle from 'src/components/forms/toggle/toggle';
import ItemSegmentBase, {
  BIG_ITEM_SEGMENT,
  itemSegmentCls, ItemSegmentHeight,
  SMALL_ITEM_SEGMENT,
} from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink from 'src/components/item_segment/base/item_segment_link';
import SegmentIcon, {
  IProps as IItemSegmentIconProps,
} from 'src/components/item_segment/parts/segment_icon/segment_icon';
import { SegmentTextLabelSize } from 'src/components/item_segment/parts/segment_text/model';
import SegmentText from 'src/components/item_segment/parts/segment_text/segment_text';

interface IProps {
  isChecked: boolean;
  icon?: IItemSegmentIconProps;
  label: string;
  labelSize?: SegmentTextLabelSize;
  description?: string;
  onChange: (checked: boolean) => void;
}

const cls = itemSegmentCls + 'toggle';

const ToggleItemSegment: React.FC<IProps> = ({ label, isChecked, onChange, icon, description, labelSize }) => {
  const height = new Map<SegmentTextLabelSize, ItemSegmentHeight>([
    ['small', SMALL_ITEM_SEGMENT],
    ['big', BIG_ITEM_SEGMENT],
  ]);
  const labelSizeLocal = labelSize || 'small';

  return (
    <ItemSegmentBase className={cls} height={height.get(labelSizeLocal) || SMALL_ITEM_SEGMENT}>
      <ItemSegmentLink>
        <>
          {icon && <SegmentIcon name={icon.name} color={icon.color} marginRight={32} size={24} />}
          <SegmentText label={label} labelSize={labelSizeLocal} description={description} />
          <Toggle
            checked={isChecked}
            onChange={onChange} />
        </>
      </ItemSegmentLink>
    </ItemSegmentBase>
  );
};

export default ToggleItemSegment;
