// lib
import React from 'react';

// components
import PageTitle from 'src/components/header/main/page_title/page_title';

// helpers
import { textResources } from 'src/lang/de';
import { useGetCurrentPostLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectUserLocationShape } from 'src/reducers/user/user_hooks';

interface IProps {
  isEvent?: boolean;
}

const { eventfeed, newsfeed } = textResources.pageTitles;

const PageTitleDetailsPage: React.FC<IProps> = ({ isEvent }) => {
  const postLocationShape = useGetCurrentPostLocationShape();
  const userLocationShape = useSelectUserLocationShape();
  const titleText = isEvent ? eventfeed : newsfeed;
  return (
    <PageTitle
      isHome={postLocationShape?.name === userLocationShape?.name}
      text={titleText}
      location={postLocationShape?.name}
      showLocation={true}
      customTag='p'
    />
  );
};

export default PageTitleDetailsPage;
