// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// classes
import Button, { IProps as ButtonProps } from 'src/components/forms/button/button';

// helpers
import './customized_button.scss';

const cls = CLASS_PREFIX + 'customized-button__';
const ITEM_SEGMENT_BUTTON = 'item-segment-button';
export const FLOATING_BUTTON = 'floating-button';
export const MAILBOX_BUTTON = 'mailbox-button';
export const MAP_BUTTON = 'map-button';

type CustomType = typeof FLOATING_BUTTON
  | typeof ITEM_SEGMENT_BUTTON
  | typeof MAILBOX_BUTTON
  | typeof MAP_BUTTON;

interface IProps extends ButtonProps {
  customType: CustomType;
}

export default class CustomizedButton extends React.PureComponent<IProps, {}> {
  public render() {
    const { customType, ...rest } = this.props;

    return (
      <div className={cls + customType}>
        <Button {...rest} />
      </div>
    );
  }
}
