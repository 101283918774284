// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import ResetPassword from 'src/components/reset_password_page/reset_password_page';
import LayoutNoHeader from 'src/layout/layout_no_header';

// constants & interfaces
import { TWO_COLUMNS } from 'src/constants/layout';
import { RESET_PASSWORD_TITLE } from 'src/constants/meta_tags';

// helpers
import { usePageTracking } from 'src/pages/hooks/hooks';

const ResetPasswordPage: React.FC<RouteComponentProps> = () => {
  usePageTracking();

  return (
    <>
      <MetaTags
        title={RESET_PASSWORD_TITLE}
      />
      <LayoutNoHeader contentWidth={TWO_COLUMNS}>
        <ResetPassword />
      </LayoutNoHeader>
    </>
  );
};

export default ResetPasswordPage;
