import { getConfigValue } from 'src/utils/config/config';
import { getSurveyConfig } from '../../../plugins/survey/helper/survey.config';
import { NavigationFeatureFlags } from './model';

const surveyConfig = getSurveyConfig();

export const navigationFeatureFlags = new Map<NavigationFeatureFlags, boolean>([
  [NavigationFeatureFlags.NEWS, !!getConfigValue<boolean>('tabs.showNewsFeed')],
  [NavigationFeatureFlags.EVENT, !!getConfigValue<boolean>('tabs.showEventFeed')],
  [NavigationFeatureFlags.GROUP, !!getConfigValue<boolean>('tabs.showGroups')],
  [NavigationFeatureFlags.USER_DIRECTORY, !!getConfigValue<boolean>('tabs.showUserDirectory')],
  [NavigationFeatureFlags.NOTIFICATIONS, !!getConfigValue<boolean>('tabs.showNotifications')],
  [NavigationFeatureFlags.CREATE_PROFILE, !!getConfigValue<boolean>('featureFlags.startMenu.createProfile')],
  [NavigationFeatureFlags.SURVEYS, surveyConfig.isEnabled],
]);
