// lib
import React, { useContext } from 'react';

// components
import { TabItemLink } from 'src/components/tabs/tab_item';
import Tabs from 'src/components/tabs/tabs';
import { NavigationFeatureFlags } from '../../feature_flag/navigation_feature_flag/model';
import { NavigationFeatureFlag } from '../../feature_flag/navigation_feature_flag/navigation_feature_flag';

// constants/interfaces
import { CLASS_PREFIX } from 'src/constants';
import {
  FEED_TYPE_NEWS,
  FEED_TYPE_EVENT,
  FEED_TYPE_LOCAL,
} from 'src/constants/feed';
import {
  GROUP_OVERVIEW,
  NOTIFICATIONS,
  PROFILES_DIRECTORY,
} from 'src/constants/navigation';
import { FRONTEND_NOTIFICATIONS_PATH, FRONTEND_GROUP_OVERVIEW_PATH } from 'src/constants/urls';
import { URLContext } from 'src/containers/url_provider/url_provider';
import { IPermissions } from 'src/interfaces/user';
import { textResources } from 'src/lang/de';

// helpers
import { useResetEventsFeeds } from 'src/actions/feed/events_feeds/events_feed_hooks';
import { useResetNewsFeed } from 'src/actions/feed/news_feed/news_feed_hooks';
import initTracker, { NAVIGATION } from 'src/utils/reporting/events_tracking/events_tracking';

import './header_navigation.scss';

const cls: string = CLASS_PREFIX + 'header-navigation';

interface IProps {
  activeNavigationItem?: ActivableNavigationItems;
  markAllNotificationsAsSeen?: () => void;
  notificationsCount?: number;
  onClick?: () => void;
  selectedProfilePermission: IPermissions;
  withSubheader?: boolean;
}

export type ActivableNavigationItems =
  typeof FEED_TYPE_EVENT
  | typeof FEED_TYPE_LOCAL
  | typeof FEED_TYPE_NEWS
  | typeof NOTIFICATIONS
  | typeof GROUP_OVERVIEW
  | typeof PROFILES_DIRECTORY;

const tracker = initTracker(NAVIGATION);

const clickEvent = () => {
  if (window.scrollY !== 0) {
    window.scroll({
      behavior: 'smooth',
      top: 0,
    });
  }

  tracker(NAVIGATION.ACTIONS.NAVIGATE_TO, NAVIGATION.LABELS.START);
};

const HeaderNavigation: React.FC<IProps> = ({
  activeNavigationItem,
  notificationsCount,
  onClick,
  selectedProfilePermission,
  withSubheader,
}) => {
  const canReadNotifications = selectedProfilePermission && selectedProfilePermission.readNotifications || false;

  const resetNewsfeed = useResetNewsFeed();
  const resetEventsFeeds = useResetEventsFeeds();

  const { getEventsfeedRoute, getNewsfeedRoute, getProfilesDirectoryRoute } = useContext(URLContext);

  const { mainPage, events, groups, notifications, profilesDirectory } = textResources.header;

  return (
    <div className={cls + '__wrapper'} onClick={onClick}>
      <hr className={cls + '__separation-line'}/>
      <Tabs onClick={clickEvent} className={cls}>
        <NavigationFeatureFlag flag={NavigationFeatureFlags.NEWS}>
          <TabItemLink
            icon={'newsfeed'}
            isActive={activeNavigationItem === FEED_TYPE_NEWS}
            textLabel={mainPage}
            url={getNewsfeedRoute()}
            onActiveClick={resetNewsfeed}
          />
        </NavigationFeatureFlag>
        <NavigationFeatureFlag flag={NavigationFeatureFlags.EVENT}>
          <TabItemLink
            icon={'event'}
            isActive={activeNavigationItem === FEED_TYPE_EVENT}
            textLabel={events}
            url={getEventsfeedRoute()}
            onActiveClick={resetEventsFeeds}
          />
        </NavigationFeatureFlag>
        <NavigationFeatureFlag flag={NavigationFeatureFlags.GROUP}>
          <TabItemLink
            icon={'groups'}
            isActive={activeNavigationItem === GROUP_OVERVIEW}
            textLabel={groups}
            url={FRONTEND_GROUP_OVERVIEW_PATH}
          />
        </NavigationFeatureFlag>
        <NavigationFeatureFlag flag={NavigationFeatureFlags.USER_DIRECTORY}>
          <TabItemLink
            icon={'search-profile'}
            isActive={activeNavigationItem === PROFILES_DIRECTORY}
            textLabel={profilesDirectory}
            url={getProfilesDirectoryRoute()}
          />
        </NavigationFeatureFlag>
        <NavigationFeatureFlag flag={NavigationFeatureFlags.NOTIFICATIONS}>
          <TabItemLink
            badge
            icon={'notification'}
            isActive={activeNavigationItem === NOTIFICATIONS}
            isLocked={!canReadNotifications}
            badgeNumber={notificationsCount}
            textLabel={notifications}
            url={FRONTEND_NOTIFICATIONS_PATH}
          />
        </NavigationFeatureFlag>
      </Tabs>
      {withSubheader && <hr className={cls + '__separation-line'}/>}
    </div>
  );
};

/* set displayname to not have a component without a name */
HeaderNavigation.displayName = 'HeaderNavigation';

export default HeaderNavigation;
