// libs
import { isEmpty } from 'lodash';
import React, { useState, useEffect, useCallback, useRef } from 'react';

// interfaces
import { IGroup } from 'src/interfaces/group';
import { IUserProfileType } from 'src/interfaces/user';

// constants
import { CLASS_PREFIX } from 'src/constants';

// components
import GroupList from 'src/components/groups/group_overview/group_list/group_list';
import GroupEmptyItem from 'src/components/groups/group_overview/group_list_items/group_empty_item/group_empty_item';
import GroupRecommendation from 'src/components/groups/group_overview/group_recommendation/group_recommendation';
import ItemGroupHeader from 'src/components/item_group_header/item_group_header';
import PostCreateItemSegment from 'src/components/item_segment/layouts/post_create/post_create';
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';
import SnackBar from 'src/components/snack_bar/snack_bar';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { hasEnoughToShow } from 'src/actions/app-state/utils';
import { useJoinGroup, useFetchMoreGroups, useFetchJoinedGroups } from 'src/actions/feed/group_feed/group_feed_hooks';
import { useResetNewsFeed } from 'src/actions/feed/news_feed/news_feed_hooks';
import api from 'src/api';
import { useScrollListener } from 'src/hooks_shared/use_event_listener';
import { textResources } from 'src/lang/de';
import { useGetGroupMap, useGetJoinedGroupsRequest, useGetMoreGroupsRequest } from 'src/reducers/groups/groups_hooks';
import { isLoading, hasData, isAppending } from 'src/reducers/groups/types/remote_data';
import {
  useSelectIsUserLoggedIn,
  useSelectCurrentProfile,
  useSelectProfilePermissions,
} from 'src/reducers/user/user_hooks';
import { COLOR_TYPE_PRIMARY } from 'src/utils/color';
import { isFullProfile } from 'src/utils/profile';

import './group_overview.scss';

const cls = CLASS_PREFIX + 'group-overview';

const labels = textResources.groups;

interface IProps {
  showModal: () => void;
  profileType: IUserProfileType;
}

const GroupRecommendationButton: React.FC<IProps> = ({ showModal, profileType }) => {
  return (
    <div className={cls + '__create-group'}>
      <UserOnboardingCTA active={!isFullProfile(profileType)}>
        <PostCreateItemSegment
          description={labels.recommendationSubtitle}
          icon={{
            color: COLOR_TYPE_PRIMARY,
            name: 'group-create',
          }}
          label={labels.recommendationTitle}
          navigation={{ onClick: showModal }}
        />
      </UserOnboardingCTA>
    </div>
  );
};

const GroupsOverview: React.FC = () => {
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [showRecommendModal, setShowRecommendModal] = useState(false);
  const loggedIn = useSelectIsUserLoggedIn();
  const joinGroupAction = useJoinGroup();
  const fetchMoreGroups = useFetchMoreGroups();
  const fetchJoinedGroups = useFetchJoinedGroups();
  const profileType = useSelectCurrentProfile().type;
  const groupMap = useGetGroupMap();
  const joinedGroupsRequest = useGetJoinedGroupsRequest();
  const moreGroupsRequest = useGetMoreGroupsRequest();
  const canJoinGroups = useSelectProfilePermissions()?.joinGroups;

  const closeSnackBar = () => {
    setSnackBarMessage('');
  };

  const showModal = () => {
    setShowRecommendModal(true);
  };

  const hideModal = () => {
    setShowRecommendModal(false);
  };

  useEffect(() => {
    if (loggedIn && canJoinGroups) {
      fetchJoinedGroups();
    }
  }, [fetchJoinedGroups, loggedIn, canJoinGroups]);

  useEffect(() => {
    fetchMoreGroups();
  }, [fetchMoreGroups, canJoinGroups]);

  // needed since the loadingState in the store would be set to late
  // and the next throttled event-lister-callback might be called before
  // which would cause multiple repeated api-calls
  const savedIsAppending = useRef(false);
  useEffect(() => {
    savedIsAppending.current = isAppending(moreGroupsRequest);
  }, [moreGroupsRequest]);

  const listener = useCallback(() => {
    if (hasData(moreGroupsRequest)) {
      const { lastPage, currentPage } = moreGroupsRequest.data.pagination;
      if (!savedIsAppending.current && !hasEnoughToShow() && !lastPage) {
        fetchMoreGroups(currentPage + 1);
        savedIsAppending.current = true;
      }
    }
  }, [moreGroupsRequest, fetchMoreGroups]);

  useScrollListener(listener, { passive: true });

  const resetNewsfeed = useResetNewsFeed();

  const getJoinSuccessMessage = (group: IGroup) => {
    if (group.accessibility === 'closed') {
      return labels.joinClosedGroupSuccess(group.name);
    }

    return labels.joinGroupSuccess(group.name);
  };

  const joinGroup = useCallback((group: IGroup) =>
    api.group.join(group.urls.participate)
      .then((joinedGroup) => {
        if (joinedGroup) {
          // since groupPosts of joined groups are appearing on the news_feed
          resetNewsfeed();
          joinGroupAction(joinedGroup);
          setSnackBarMessage(getJoinSuccessMessage(group));
        } else {
          setSnackBarMessage(labels.joinGroupError(group.name));
        }
      }).catch((_error) => {
        setSnackBarMessage(labels.joinGroupError(group.name));
      }), [joinGroupAction, resetNewsfeed]);

  if (isLoading(joinedGroupsRequest) || isLoading(moreGroupsRequest)) {
    return <LoadingSpinner shown />;
  }

  if (isEmpty(groupMap)) {
    return (
      <div className={cls}>
        <ItemGroupHeader title={labels.moreGroups} />
        <GroupEmptyItem />
      </div>
    );
  }

  const joinedGroupIds = hasData(joinedGroupsRequest) ? joinedGroupsRequest.data.joinedGroupIds : [];
  const pendingGroupIds = hasData(joinedGroupsRequest) ? joinedGroupsRequest.data.pendingGroupIds : [];
  const moreGroupIds = hasData(moreGroupsRequest) ? moreGroupsRequest.data.groupIds : [];

  const restGroupIds = moreGroupIds
    .filter(groupId => !joinedGroupIds.includes(groupId) && !pendingGroupIds.includes(groupId));

  return (
    <div className={cls}>
      {<GroupRecommendationButton showModal={showModal} profileType={profileType}/>}
      {pendingGroupIds.length > 0 &&
        <GroupList
          groupIds={pendingGroupIds}
          groupMap={groupMap}
          isLoading={isLoading(joinedGroupsRequest)}
          label={labels.pendingGroups}
        />
      }
      {joinedGroupIds.length > 0 &&
        <GroupList
          groupIds={joinedGroupIds}
          groupMap={groupMap}
          isLoading={isLoading(joinedGroupsRequest)}
          label={labels.myGroups}
        />
      }
      <GroupList
        groupIds={restGroupIds}
        groupMap={groupMap}
        isLoading={isLoading(moreGroupsRequest)}
        joinGroup={joinGroup}
        label={
          restGroupIds.length > 0
            ? labels.moreGroups
            : undefined
        }
      />
      {snackBarMessage.length > 0 && (
        <SnackBar message={snackBarMessage} showClose onClose={closeSnackBar} />
      )}
      {showRecommendModal &&
        <GroupRecommendation hideModal={hideModal}/>
      }
    </div>
  );
};

export default GroupsOverview;
