// libs
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ThunkDispatch } from 'redux-thunk';

// interfaces
import { IAction } from 'src/actions/';
import { IFlagCreateData } from 'src/interfaces/flag';
import { IPost } from 'src/interfaces/posts';
import { IRootState } from 'src/reducers/interface';
import { IHeaderDetailsTracker } from 'src/utils/reporting/events_tracking/events_tracking';

// classes
import MoreButton from 'src/components/post/shared/more_button/more_button';

// helpers
import { clearAllFeeds } from 'src/actions/feed/feed';
import { createFlag } from 'src/actions/flag';
import { deletePost } from 'src/actions/post/post';
import { graylistProfile } from 'src/actions/profile/profile';
import { Id } from 'src/interfaces';
import { IProfile } from 'src/interfaces/profile';
import { IPermissions } from 'src/interfaces/user';

export interface IDispatchFromProps {
  deletePost: () => void;
  flagPost: (data: IFlagCreateData) => void;
  graylistProfile: (path: string, id: Id, expiresOn: Date) => Promise<{} | null>;
  reloadFeed: () => void;
}

interface IOwnProps extends RouteComponentProps {
  post: IPost;
  postUpdateOpen?: boolean;
  getNewsfeedRoute: () => string;
  tracking?: IHeaderDetailsTracker;
}

export type IProps = IOwnProps & IDispatchFromProps & IMapStateToProps;

interface IMapStateToProps {
  postAuthor?: IProfile;
  userPermission?: IPermissions;
}

const mapStateToProps = ({ profiles, user }: IRootState, { post }: IOwnProps) => ({
  postAuthor: profiles[post.authorId]?.profile,
  userPermission: user.profiles[user.selectedProfileId]?.permissions,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<IRootState, {}, IAction>,
  ownProps: IOwnProps,
): IDispatchFromProps => ({
  deletePost: () => dispatch(deletePost(ownProps.post, () => {
    ownProps.history.push(ownProps.getNewsfeedRoute());
  })),
  flagPost: (data) => dispatch(createFlag(ownProps.post, data)),
  graylistProfile: (path, id, expiresOn) => dispatch(graylistProfile(path, id, expiresOn)),
  reloadFeed: () => dispatch(clearAllFeeds()),
});

export default withRouter(connect<IMapStateToProps, IDispatchFromProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(MoreButton));
