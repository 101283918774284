// libs
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// interfaces / constants
import { IAction } from 'src/actions';
import { IFeeds } from 'src/interfaces/feed';
import { IReaction, IReactionsOwnProps, ReactionName } from 'src/interfaces/reactions';

// components
import PostDetailReactionWrapper from 'src/components/post/shared/reactions/post_detail_reaction_wrapper';
import ReactionsButton from 'src/components/post/shared/reactions/reactions_button';

// actions
import { updatePostReaction } from 'src/actions/reaction';

interface IStateToProps {
  reactions: IReaction[];
  updateUrl: string;
}

interface IReactionsDispatchProps {
  updateReaction: (reaction: ReactionName, updateUrl: string, oldReaction?: ReactionName) => void;
}

const mapStateToProps = ({ feed }: { feed: IFeeds }, ownProps: IReactionsOwnProps): IStateToProps => {
  if (!feed.reactions[ownProps.id]) {
    return {
      reactions: [],
      updateUrl: '',
    };
  }
  return {
    reactions: feed.reactions[ownProps.id].reactions,
    updateUrl: feed.reactions[ownProps.id].meta.urls.update,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<IAction>, ownProps: IReactionsOwnProps): IReactionsDispatchProps => ({
  updateReaction: (reaction, updateUrl, oldReaction) =>
    dispatch(updatePostReaction(reaction, ownProps.id, updateUrl, oldReaction)),
});

export const ReactionsButtonContainer = connect<IStateToProps, IReactionsDispatchProps, IReactionsOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ReactionsButton);

// see #4363, depending on reactions, the details-footer should display a string or hide it.
// To achieve this behaviour we moved the reaction-headline into this wrapper-component, rendered here:
export const PostDetailsReactionsContainer = connect<IStateToProps, IReactionsDispatchProps, IReactionsOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PostDetailReactionWrapper);
