// interfaces / constants
import { IAction } from 'src/actions/';
import { IActiveFilter, IDateRange } from 'src/interfaces/filters';

export const FILTER_SET_ACTIVE_FILTER = 'FILTER_SET_ACTIVE_FILTER';
export const FILTER_SET_DATE_RANGE_FILTER = 'FILTER_SET_DATE_RANGE_FILTER';

export const setActiveFilter = (activeFilter: IActiveFilter): IAction => ({
  payload: activeFilter,
  type: FILTER_SET_ACTIVE_FILTER,
});

export const setDateRangeFilter = (dateRangeFilter: IDateRange): IAction => ({
  payload: dateRangeFilter,
  type: FILTER_SET_DATE_RANGE_FILTER,
});
