// libs
import { History } from 'history';
import { flowRight } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

// interfaces & constants
import { MAP_TOKEN } from 'src/constants/map';
import { IBoundingBox, IPosition, ILocationShape } from 'src/interfaces/location';
import { IMapShape } from 'src/interfaces/map';
import { IMarker } from 'src/interfaces/marker';
import { IRootState } from 'src/reducers/interface';

// components
import MapComponent from 'src/components/map/map';
import withLoadingSpinner from 'src/high_order_components/with_loading_spinner';

// actions
import { setBoundingBox } from 'src/actions/app-state/app-state';

// helpers
import { withRouterProps } from 'src/high_order_components/connect_route_props';
import { isLockedPath, getShape } from 'src/utils/map';

export interface IMapSettings {
  showShape?: boolean;
  inverse?: boolean;
  interactive?: boolean;
}

interface IOwnProps {
  settings?: IMapSettings;
}

interface IStateToProps {
  boundingBox?: IBoundingBox;
  locationShape?: ILocationShape;
  position?: IPosition;
  markers: IMarker[];
}

interface IDispatchToProps {
  setBoundingBox: (boundingBox: IBoundingBox) => void;
}

interface IMapRouteToProps {
  history: History;
  isLocked: boolean;
  locationSlug?: string;
}

type IProps = IOwnProps & IStateToProps & IDispatchToProps & IMapRouteToProps;

interface IState {
  shape?: IMapShape;
}

const mapStateToProps = (state: IRootState): IStateToProps => ({
  boundingBox: state.appState.boundingBox,
  locationShape: state.appState.locationShape,
  markers: state.appState.markers,
  position: state.appState.position,
});

const mapDispatchToProps: IDispatchToProps = {
  setBoundingBox,
};

interface IMapsParams {
  locationSlug: string;
}

const mapRouteToProps = ({ history, location, match }: RouteComponentProps<IMapsParams>): IMapRouteToProps => ({
  history,
  isLocked: isLockedPath(location.pathname),
  locationSlug: match.params.locationSlug,
});

class MapContainer extends React.Component<IProps, IState> {
  private isMounted = false;
  constructor(props: IProps) {
    super(props);

    this.state = {};
  }

  public componentDidMount() {
    const { settings, locationShape, locationSlug } = this.props;

    this.isMounted = true;

    if (locationShape?.slug !== locationSlug) {
      return;
    }

    if (locationShape && settings?.showShape) {
      this.updateShape(locationShape, !!settings.inverse);
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { settings, locationShape, locationSlug } = this.props;

    if (locationShape?.slug !== locationSlug) {
      return;
    }

    if (prevProps.locationShape && this.props.locationShape &&
      prevProps.locationShape.identifier !== this.props.locationShape.identifier) {
      if (locationShape && settings?.showShape) {
        this.updateShape(locationShape, !!settings.inverse);
      }
    }
  }

  public componentWillUnmount() {
    this.isMounted = false;
  }

  public render(): JSX.Element {
    const {
      boundingBox,
      isLocked,
      position,
      markers,
      setBoundingBox,
      settings,
    } = this.props;

    return (
      <MapComponent
        markers={markers}
        mapToken={MAP_TOKEN}
        boundingBox={boundingBox}
        setBoundingBox={setBoundingBox}
        position={position}
        shape={this.state.shape}
        isLocked={isLocked || settings?.interactive === false}
      />
    );
  }

  // since updateShape is an async function it can happen, that setState gets called after
  // this component gets unmounted
  // this method fixed the resulting react warning about memory leaks
  private safeSetState(state: IState) {
    if (this.isMounted) {
      this.setState(state);
    }
  }

  private async updateShape(locationShape: ILocationShape, inverse: boolean): Promise<void> {
    const shape = await getShape(locationShape, inverse);
    this.safeSetState({ shape });
  }
}

export default flowRight(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withRouterProps(mapRouteToProps),
  withLoadingSpinner,
)(MapContainer);
