// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants';
import { IPostsRecord } from 'src/interfaces/feed';
import { POST_DISPLAY_MODE_BANNER } from 'src/interfaces/posts';

// components
import ItemGroupHeader from 'src/components/item_group_header/item_group_header';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import Ingredients from 'src/components/post/shared/ingredients/ingredients';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';
import { URLConsumer } from 'src/containers/url_provider/url_provider';
import { textResources } from 'src/lang/de';

// helpers
import './post_banner_group.scss';

export interface IPostBannerGroup {
  group: string[];
  posts: IPostsRecord;
  title?: string;
  showAllTopLink?: boolean;
  slug?: string;
}

const cls = CLASS_PREFIX + 'banner-group';

export default class PostBannerGroup extends React.PureComponent<IPostBannerGroup, {}> {
  constructor(props: IPostBannerGroup) {
    super(props);
  }

  public render() {
    const { group, title, posts } = this.props;

    return (
      <div key={title} className={cls}>
        {title && <ItemGroupHeader title={title}>{this.renderShowAll()}</ItemGroupHeader>}
        {group.map((postId: string) => this.renderPost(postId, posts))}
      </div>
    );
  }

  private renderShowAll() {
    if (!this.props.showAllTopLink) {
      return null;
    }

    const showAllClass = cls + '__show-all-link';

    return (
      <URLConsumer>
        {({ getEventsfeedRoute }) => (
          <NavigationItem target='_self' url={getEventsfeedRoute(this.props.slug)} className={showAllClass}>
            {textResources.shared.showAll}
          </NavigationItem>
        )}
      </URLConsumer>
    );
  }

  private renderPost(postId: string, posts: IPostsRecord) {
    const post = posts[postId];
    let ingredients;
    if (post) {
      ingredients = post.ingredientIds.map((ingredientId) => post.ingredients[ingredientId]!);
    } else {
      return null;
    }

    return (
      <article key={postId} className={cls + '__item'}>
        <UserOnboardingCTA active={post.meta.fenced} gtmSourceName='postBanner'>
          <Ingredients
            ingredients={ingredients}
            displayMode={POST_DISPLAY_MODE_BANNER}
            postDetailsURL={post.urls.frontend}
            postId={postId}
          />
        </UserOnboardingCTA>
      </article>
    );
  }
}
