// libs
import * as React from 'react';

// interfaces / constants
import { bookmarkableTypes, IBookmarkNotification } from 'src/interfaces/notification';
import { ReactionName } from 'src/interfaces/reactions';

// classes / components
import Notification, { clsHighlightedText, clsText } from 'src/components/notifications/notification';

// helpers
import Translate, { textResources } from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';

// images
import * as reactionCandle from 'src/components/notifications/badges/Candle@2x.png';
import * as reactionCandleActive from 'src/components/notifications/badges/CandleActive@2x.png';
import * as reactionHaha from 'src/components/notifications/badges/Haha@2x.png';
import * as reactionHahaActive from 'src/components/notifications/badges/HahaActive@2x.png';
import * as reactionInteresting from 'src/components/notifications/badges/Interessant@2x.png';
import * as reactionInterestingActive from 'src/components/notifications/badges/InteressantActive@2x.png';
import * as reactionLike from 'src/components/notifications/badges/Like@2x.png';
import * as reactionLikeActive from 'src/components/notifications/badges/LikeActive@2x.png';
import * as reactionOhNo from 'src/components/notifications/badges/OhNein@2x.png';
import * as reactionOhNoActive from 'src/components/notifications/badges/OhNeinActive@2x.png';
import * as reactionWow from 'src/components/notifications/badges/Wow@2x.png';
import * as reactionWowActive from 'src/components/notifications/badges/WowActive@2x.png';

const labels = textResources.notification;

export interface IProps extends IBookmarkNotification {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const badgeImages: Record<ReactionName, string> = {
  candle: reactionCandleActive,
  haha: reactionHahaActive,
  interesting: reactionInterestingActive,
  like: reactionLikeActive,
  oh_no: reactionOhNoActive,
  old_heart: reactionLikeActive,
  wow: reactionWowActive,
};

const readBadgeImages: Record<ReactionName, string> = {
  candle: reactionCandle,
  haha: reactionHaha,
  interesting: reactionInteresting,
  like: reactionLike,
  oh_no: reactionOhNo,
  old_heart: reactionLike,
  wow: reactionWow,
};

const BookmarkNotification: React.FC<IProps> = ({
  bookmarkType,
  bookmarkableType,
  contextTeaser,
  createdAt,
  initiator,
  isRead,
  onClick,
}) => {
  const bookmarkable = Translate.notifications.bookmarkBookmarkableType[bookmarkableType];

  return (
    <Notification
      avatarImage={initiator?.image}
      badgeImageRead={readBadgeImages[bookmarkType]}
      badgeImageNotRead={badgeImages[bookmarkType]}
      footer={DateFormatter.asTimeAgo(createdAt)}
      initiatorName={initiator?.name}
      isRead={isRead}
      onClick={onClick}
    >
      <span className={clsText} >{` ${labels.notificationBookmark} ${bookmarkable}`}</span>
      {bookmarkableType !== bookmarkableTypes.comment && contextTeaser && (
        <strong className={clsHighlightedText} > „{contextTeaser}“</strong>
      )}
      <span className={clsText} >{` ${labels.notificationReacted}`}</span>
    </Notification>
  );
};

export default BookmarkNotification;
