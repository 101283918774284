// lib
import React from 'react';

// components
import ItemSegmentBase, { BIG_ITEM_SEGMENT, itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink, { IProps as ILinkProps } from 'src/components/item_segment/base/item_segment_link';
import { IUserListAction, IUserListAdminAction } from 'src/components/item_segment/layouts/profiles_list/model';
import SegmentAvatar, {
  IProps as IItemSegmentAvatarProps,
} from 'src/components/item_segment/parts/segment_avatar/segment_avatar';
import SegmentFollowButton from 'src/components/item_segment/parts/segment_follow_button/segment_follow_button';
import SegmentIcon from 'src/components/item_segment/parts/segment_icon/segment_icon';
import SegmentRoundIcon from 'src/components/item_segment/parts/segment_round_icon/segment_round_icon';
import SegmentText from 'src/components/item_segment/parts/segment_text/segment_text';
import { SegmentUserActionType } from 'src/components/item_segment/parts/segment_user_action_button/model';
import SegmentUserActionButton
  from 'src/components/item_segment/parts/segment_user_action_button/segment_user_action_button';

// helpers
import { textResources } from 'src/lang/de';

// constants/interfaces
import { COLOR_TYPE_GRAY_TINT_1 } from 'src/utils/color';
import SegmentUserActions from '../../parts/segment_user_actions/segment_user_actions';
import './profiles_list.scss';

interface IProps {
  label: string;
  description?: string;
  avatar: IItemSegmentAvatarProps;
  action?: IUserListAction & IUserListAdminAction;
  navigation?: ILinkProps;
}

const cls = itemSegmentCls + 'profiles-list';

/*
  A Wrapper for ItemSegment for Lists of profiles
*/
const ProfilesListItemSegment: React.FC<IProps> =
  ({ label, description, navigation, avatar, action }) => (
    <ItemSegmentBase className={cls} height={BIG_ITEM_SEGMENT}>
      <ItemSegmentLink {...navigation}>
        <SegmentAvatar imageUrl={avatar.imageUrl} badge={avatar.badge} />
        <SegmentText label={label} description={description} labelSize='big' />
        {action?.unblock && <SegmentIcon name='ban' color={COLOR_TYPE_GRAY_TINT_1} size={16} />}
        {action?.unhide && <SegmentIcon name='mute' color={COLOR_TYPE_GRAY_TINT_1} size={16} />}
      </ItemSegmentLink>
      {action?.sendMessage?.onClick &&
      <ItemSegmentLink onClick={action.sendMessage.onClick} noGrow>
        <SegmentRoundIcon
          name='paper-plane'
          color={COLOR_TYPE_GRAY_TINT_1}
          isSmall
          description={textResources.shared.writeMessage}
        />
      </ItemSegmentLink>
      }
      {action?.follow &&
      <SegmentFollowButton {...action.follow} />
      }
      {action?.reject && action?.accept &&
      <SegmentUserActions>
        <SegmentUserActionButton
          type={SegmentUserActionType.REJECT}
          action={action.reject.action}
          confirmation={action.reject.confirmation} />
        <SegmentUserActionButton
          type={SegmentUserActionType.ACCEPT}
          action={action.accept.action}
          confirmation={action.accept.confirmation} />
      </SegmentUserActions>
      }
      {action?.remove &&
      <SegmentUserActions>
        <SegmentUserActionButton
          type={SegmentUserActionType.REMOVE}
          action={action.remove.action}
          confirmation={action.remove.confirmation} />
      </SegmentUserActions>
      }
    </ItemSegmentBase>
  );

export default ProfilesListItemSegment;
