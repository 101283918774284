// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IIngredient, IInclusionReason } from 'src/interfaces/post_ingredients';
import { mapDisplayModeToConst } from 'src/interfaces/posts';

// classes

// styles
import { getConfigValue } from 'src/utils/config/config';
import './inclusion_reason.scss';

type IProps = IIngredient<IInclusionReason>;

const cls = `${CLASS_PREFIX}inclusion-reason__`;

export default class InclusionReason extends React.PureComponent<IProps> {
  private cls = '';

  constructor(props: IProps) {
    super(props);
    this.cls = `${cls}${mapDisplayModeToConst[props.displayMode]}`;
  }

  public render() {
    const { data: { content, type } } = this.props;
    const showInclusionReason = getConfigValue<string[]>('featureFlags.showInclusionReason');
    if (showInclusionReason) {
      if (showInclusionReason.includes(type)) {
        return <p className={this.cls}>{content}</p>;
      } else {
        return null;
      }
    }
    return (
      <p className={this.cls}>{content}</p>
    );
  }
}
