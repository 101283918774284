// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IMoreOption } from 'src/constants/more_button';
import { IKeyValue } from 'src/interfaces/';

// classes
import Icon from 'src/components/forms/icon/icon';
import MenuItemSegment from 'src/components/item_segment/layouts/menu/menu';
import Popover, { PositionType } from 'src/components/popover/popover';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_BLACK, COLOR_TYPE_GRAY_TINT_1 } from 'src/utils/color';
import './more.scss';

const clsBlock = classNames(CLASS_PREFIX + 'more');
const moreOptionsText = textResources.screenReaderText.moreOptions;

interface IProps {
  disabled: boolean;
  onChange: (selected: IMoreOption) => void;
  options: IMoreOption[];
  position?: PositionType;
}

interface IButton {
  className: string;
  icon: string;
  disabled?: boolean;
  onClick?: React.EventHandler<React.MouseEvent<HTMLButtonElement>>;
}

const Button: React.FC<IButton> = ({ icon, disabled, onClick, className }) => (
  <button className={className} disabled={disabled || false} onClick={onClick}>
    <Icon aria-hidden='true' color={COLOR_TYPE_GRAY_TINT_1} name={icon} description={moreOptionsText} />
  </button>
);

export default class More extends React.PureComponent<IProps, {}> {
  public render() {
    const { disabled, options, position } = this.props;
    const clsButton: IKeyValue = {};
    clsButton[`${clsBlock}__button`] = true;
    clsButton[`${clsBlock}__button--disabled`] = disabled;

    return (
      <UserOnboardingCTA active={disabled} gtmSourceName='more'>
        <div className={clsBlock}>
          <Popover
            position={position || 'top'}
            align={'relative'}
            trigger={<Button icon={'dots'} className={classNames(clsButton)} disabled={disabled} />}
          >{({ hide, callAll }) => (
              <ul className={`${clsBlock}__list`}>
                {options.map((option: IMoreOption, index: number) => (
                  <li key={index}>
                    <MenuItemSegment
                      label={option.text}
                      navigation={{ onClick: callAll(this.handleItemOnClick(index), hide) }}
                      icon={{ color: COLOR_TYPE_BLACK, name: option.icon }}
                    />
                  </li>
                ))}
              </ul>
            )}
          </Popover>
        </div>
      </UserOnboardingCTA>
    );
  }

  private handleItemOnClick = (index: number) => {
    return () => {
      const { onChange, options } = this.props;
      onChange && onChange(options[index]);
    };
  }
}
