// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import BackbuttonHeader from 'src/components/header/common/backbutton_header/backbutton_header';
import MetaTags from 'src/components/meta_tags/meta_tags';
import EventParticipants from 'src/containers/smart_components/event_participants/event_participants';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { FEED_TYPE_EVENT } from 'src/constants/feed';
import { TWO_COLUMNS } from 'src/constants/layout';
import { EVENT_PARTICIPATIONS_TITLE } from 'src/constants/meta_tags';
import { FRONTEND_EVENT_PATH } from 'src/constants/urls';
import { textResources } from 'src/lang/de';

// helpers
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { UrlUtils } from 'src/utils/url/url';

const EventParticipationsPage: React.FC<RouteComponentProps> = ({ history }) => {
  const user = useSelectUser();

  usePageTracking({ user });

  const handleBackButton = () => {
    UrlUtils.goBack(history, FRONTEND_EVENT_PATH);
  };

  return (
    <>
      <MetaTags
        title={EVENT_PARTICIPATIONS_TITLE}
      />
      <LayoutNoMap
        subHeader={<BackbuttonHeader
          title={textResources.pageTitles.eventParticipants}
          handleBackButton={handleBackButton}
        />}
        activeNavigationItem={FEED_TYPE_EVENT}
        contentWidth={TWO_COLUMNS}
        pageTitle={<StandardPageTitle text={textResources.pageTitles.eventParticipants} />}
      >
        <EventParticipants/>
      </LayoutNoMap>
    </>
  );
};

export default EventParticipationsPage;
