// libs
import * as React from 'react';

// interfaces / constants
import { textResources } from 'src/lang/de';
import { COLOR_TYPE_BLACK } from 'src/utils/color';

// classes
import LocationPickerItemSegment from 'src/components/item_segment/layouts/location_picker/location_picker';

interface IProps {
  preFilledFormatedAddress?: string;
  showTakeMeHome: boolean;
  locateMe: () => void;
  takePrefilled: () => void;
  takeMeHome: () => void;
}

export default ({
  locateMe,
  preFilledFormatedAddress,
  takePrefilled,
  takeMeHome,
  showTakeMeHome,
}: IProps) => {
  return (
    <>
      {preFilledFormatedAddress &&
        <LocationPickerItemSegment
          navigation={{ onClick: takePrefilled }}
          label={textResources.item.keepAddress}
          description={preFilledFormatedAddress}
          icon={{ color: COLOR_TYPE_BLACK, name: 'copy' }}
        />
      }
      <LocationPickerItemSegment
        navigation={{ onClick: locateMe }}
        label={textResources.locationChanger.currentLocation}
        icon={{ color: COLOR_TYPE_BLACK, name: 'locate-me-dot' }}
      />
      {showTakeMeHome &&
      <LocationPickerItemSegment
        navigation={{ onClick: takeMeHome }}
        label={textResources.locationChanger.homeLocation}
        icon={{ color: COLOR_TYPE_BLACK, name: 'house-filled' }}
      />}
    </>
  );
};
