// libs
import React, { useCallback } from 'react';

// classes / components
import FeatureBlocker from 'src/components/feature_blocker/feature_blocker';

// constants
import {
  GTM_ACTION_FEATURE_BLOCKER,
  GTM_CATEGORY_FEATURE_BLOCKER,
} from 'src/constants/google_tag_manager';

// helpers
import { useCallToAction } from 'src/actions/onboarding_cta/onboarding_cta_hooks';
import GoogleTagManager from 'src/utils/reporting/google-tag-manager';

interface IProps {
  active: boolean;
  gtmSourceName?: string;
  className?: string;
}

const UserOnboardingCTA: React.FC<IProps> = ({ active, gtmSourceName = '', children, className }) => {
  const callToAction = useCallToAction();
  const handleClick = useCallback((e: React.SyntheticEvent<HTMLDivElement>) => {
    e.preventDefault();
    GoogleTagManager.pushEventVariable(GTM_CATEGORY_FEATURE_BLOCKER, GTM_ACTION_FEATURE_BLOCKER, gtmSourceName);
    callToAction();
  }, [callToAction, gtmSourceName]);
  return (
    <FeatureBlocker
      active={active}
      onClick={handleClick}
      className={className}
    >
      {children}
    </FeatureBlocker>
  );
};

export default UserOnboardingCTA;
