import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import { getParticipationList } from 'src/actions/event_participation/event_participation';
import { Id } from 'src/interfaces';
import { useProfilesList } from 'src/reducers/profiles_list/profiles_list_hooks';

const PARTICIPANTS_PER_PAGE = 30;

export const useGetParticipantsNextPage = (id: Id) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { pagination: { currentPage, lastPage }, isLoading } = useProfilesList();

  return useCallback(
    () => {
      if (isLoading || lastPage) {
        return;
      }

      dispatch(getParticipationList({ id, page: currentPage + 1, perPage: PARTICIPANTS_PER_PAGE }, history));
    },
    [isLoading, lastPage, dispatch, id, currentPage, history],
  );
};
