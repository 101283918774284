// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// classes
import { WithLightbox } from 'src/components/darkbox/darkbox';
import Thumbnail, {
  ThumbnailSizeType,
  THUMBNAIL_SIZE_TYPE_LARGE,
} from 'src/components/thumbnail/thumbnail';

// styles
import './thumbnail_gallery.scss';

const cls = `${CLASS_PREFIX}thumbnail-gallery`;

interface IProps {
  fold?: boolean;
  imageUrls: string[];
  size?: ThumbnailSizeType;
}

interface IState {
  fold?: boolean;
  galleryWidth: number;
  thumbnailWidth: number;
}

export default class ThumbnailGallery extends React.PureComponent<IProps, IState> {
  private divElement: HTMLDivElement;
  constructor(props: IProps) {
    super(props);
    this.handleExpand = this.handleExpand.bind(this);
    this.handleGalleryRef = this.handleGalleryRef.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.renderThumbnails = this.renderThumbnails.bind(this);
    this.state = { fold: props.fold, galleryWidth: 0, thumbnailWidth: 0 };
  }

  public render() {
    const { galleryWidth } = this.state;
    return (
      <div className={cls} ref={this.handleGalleryRef} >
        {galleryWidth && this.renderThumbnails()}
      </div>
    );
  }

  public componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  public componentDidUpdate() {
    this.handleWindowResize();
  }

  private renderThumbnails(): JSX.Element[] {
    const { imageUrls, size } = this.props;
    const { fold, galleryWidth, thumbnailWidth } = this.state;
    let targetIndex: number;
    let maxNumber: number = 0;
    let notEnoughSpace: boolean = false;
    if (thumbnailWidth === 0) {
      targetIndex = 1;
    } else {
      maxNumber = this.getMaxRendableItemNumber(galleryWidth, thumbnailWidth);
      notEnoughSpace = maxNumber < imageUrls.length;
      targetIndex = fold && notEnoughSpace ? maxNumber - 1 : imageUrls.length;
    }
    const list = imageUrls.filter((_url: string, index: number) => index < targetIndex);

    const thumbnailSize = size || THUMBNAIL_SIZE_TYPE_LARGE;
    const thumbnailList = list.map((url: string, index: number) => (
      <WithLightbox key={index} images={[url]}>
        <Thumbnail
          clickable={true}
          imageUrl={url}
          index={index}
          size={thumbnailSize}
        />
      </WithLightbox>
    ));

    if (fold && notEnoughSpace) {
      thumbnailList.push(
        <Thumbnail
          clickable={true}
          index={maxNumber}
          key={maxNumber}
          label={`+${imageUrls.length - maxNumber + 1}`}
          onClick={this.handleExpand}
          size={thumbnailSize}
        />,
      );
    }
    return thumbnailList;
  }

  private getMaxRendableItemNumber(containerWidth: number, childWidth: number): number {
    return Math.floor(containerWidth / childWidth);
  }

  private handleExpand() {
    this.setState({ fold: false });
  }

  private handleGalleryRef(ref: HTMLDivElement) {
    this.divElement = ref;
  }

  private handleWindowResize() {
    const width = this.divElement.clientWidth;
    if (this.state.galleryWidth !== width) {
      this.setState({ galleryWidth: width });
    }
    const childWidth = this.divElement.firstChild && (this.divElement.firstChild as HTMLDivElement).offsetWidth;
    if (childWidth && (this.state.thumbnailWidth !== childWidth)) {
      this.setState({ thumbnailWidth: childWidth });
    }
  }
}
