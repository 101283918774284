// libs
import { History } from 'history';
import { kebabCase } from 'lodash';
import { match as MatchType } from 'react-router-dom';

// interfaces / constants
import { PostResponseType } from 'src/api/interfaces/responses/post';
import { FEED_POST_TYPE_SNAPSHOT_POST } from 'src/constants/feed';
import {
  ADMIN_PROFILE_TYPE,
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  INTERMEDIATE_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  PRIVATE_PROFILE_TYPE,
  REPORTER_PROFILE_TYPE,
} from 'src/constants/profile';
import { WRITE_PATH, SNAPSHOT_CROPPER_PATH, SELECT_PATH } from 'src/constants/route_constants';
import * as URLS from 'src/constants/urls';
import { ILopoCore, Id } from 'src/interfaces';
import { IPosition, IBoundingBox } from 'src/interfaces/location';
import { IContentItemType } from 'src/interfaces/posts';
import { ProfileType, ProfilesListProfileType } from 'src/interfaces/profile';
import { ProfilesListURI } from 'src/interfaces/profiles_list';
import { getConfigValue } from 'src/utils/config/config';
import {
  REGEXP_LOCK_MAP_PAGE,
  REGEXP_DETAIL_PAGE,
  REGEXP_POST_DETAIL_PAGE,
} from 'src/utils/pattern/pattern';

// helpers
import { PROFILE_LIST_AUTHOR_TYPE_TO_URIS, PROFILE_LIST_URIS_TO_AUTHOR_TYPE } from 'src/constants/profile_list';
import { getShapeFromPosition } from 'src/utils/position';
import { reportError } from 'src/utils/reporting/report-errors';
import { idFromGlobalFriendlyIdentifier } from '../profile';

export interface IProfileParams {
  profileType?: string;
  profileId?: string;
}

export interface IProfileData {
  profileType?: ProfileType;
  profileId?: Id;
}

export interface IGroupsParams {
  id: string;
}

export const getProfileDataFromUrlParams = (params: IProfileParams): IProfileData => {
  const profileType = params.profileType?.toLowerCase() + '_profile' as ProfileType;
  const profileId = params.profileId?.toLowerCase();
  return {
    profileId: `${profileType}#${profileId}`,
    profileType,
  };
};

export const createWritePath = (baseUrl: string, postType: IContentItemType) => {
  if (postType === FEED_POST_TYPE_SNAPSHOT_POST) {
    return `${baseUrl}${URLS.CREATE_POST}/${kebabCase(postType)}${SNAPSHOT_CROPPER_PATH}`;
  }
  return `${baseUrl}${URLS.CREATE_POST}/${kebabCase(postType)}${WRITE_PATH}`;
};

export const getNewsFeedPathWithSlug = (locationSlug: string) => `/${locationSlug}${URLS.NEWS_FEED_PARTIAL}`;

const replaceAtIndex = (array: any[], value: any, index: number) =>
  [...array.slice(0, index), value, ...array.slice(index + 1)];

// to replace the locationSlug no matter where in the url
// for example loaklportal.de/nachbarschaft/[shape_slug] or
// loaklportal.de/[shape_slug]/nachbarschaft or any other
export const replaceLocationSlugFromPath = (match: MatchType | null, locationSlug: string) => {
  const customLocationSlug = getConfigValue<string>('featureFlags.location.customLocationSlug');
  if (customLocationSlug && match) {
    return match.url;
  }
  const path = match?.path || '';
  const url = match?.url || '';
  // The path pattern used to match this route (the one passed to the Route that matches)
  // can look like /:locationSlug/aktuelles
  const pathArray = path.split('/');
  //  The matched portion of the URL. looks the like
  //  /kiel-ravensberg/aktuelles
  const urlArray = url.split('/');
  const index = pathArray.indexOf(URLS.LOCATION_SLUG_PATTERN);

  if (index < 0) {
    // defauts to neswsfeed if no slug could be replaced
    return getNewsFeedPathWithSlug(locationSlug);
  }

  return replaceAtIndex(urlArray, locationSlug, index).join('/');
};

interface INavigateToPositionURL {
  history: History;
  match: MatchType;
  position: IPosition;
  boundingBox?: IBoundingBox;
}

// navigates the app to the current url with the location-slug of the browser geo-position
export const navigateToPositionURL = ({ history, match, position, boundingBox }: INavigateToPositionURL) =>
  getShapeFromPosition(position).then((locationShape) => {
    // TODO check if there is a better fallback
    if (!locationShape) {
      throw (new Error(`getLocation didn't return a locationShape for:
        { lat: ${position.latitude}, long: ${position.longitude} }`)
      );
    }
    history.push(
      replaceLocationSlugFromPath(match, locationShape.slug),
      { boundingBox, locationShape: locationShape, position },
    );
  });
;

/*
   Use of parseQueryString
   const myObj = parseQueryString('ksks?a=2', ['a', 'b']);
   myObj.c; // error
   myObj.b; // works!
  */
export const parseQueryString = <T extends string, R = {[key in T]?: string}>(search: string, wishedKeys: T[]): R => {
  const querystring = new URLSearchParams(search);

  return wishedKeys.reduce(
    (prevVal, currVal: T) => {
      const querystringVal = querystring.get(currVal);

      if (querystringVal === null) {
        return prevVal;
      }

      return { ...prevVal, [currVal]: querystringVal };
    }, {}) as R;
};

export const getPreviewPathFrom = (path: string) => path + '/preview';

export const addSchemaIfNotPresent = (urlLike: string) =>
  !urlLike.match('^https?:\/\/') ? `http://${urlLike}` : urlLike;

export const getCreatePathFrom = (path: string) =>
  path.includes(URLS.CREATE_POST) ? path : path + URLS.CREATE_POST;

export class UrlUtils {
  public static groupPage(identifier: string): string {
    return `${URLS.FRONTEND_GROUP_PATH}${identifier}`;
  }

  public static groupMembersPage(identifier: string): string {
    return `${URLS.FRONTEND_GROUP_PATH}${identifier}/members`;
  }

  public static detailPage(identifier: string, type: PostResponseType): string {
    switch (type) {
      case 'post':
        return `${URLS.FRONTEND_POST_PATH}/${identifier}`;
      case 'event':
        return `${URLS.FRONTEND_EVENT_PATH}/${identifier}`;
    }
  }

  public static getProfileFrontendPath(identifier: string, profileType: ProfileType): string {
    return this.getProfilePath(identifier, profileType, 'FRONTEND');
  }

  public static getProfileApiPath(identifier: string, profileType: ProfileType): string {
    return this.getProfilePath(identifier.substring(identifier.indexOf('#') + 1), profileType, 'API');
  }

  public static getProfilePath(identifier: string, profileType: ProfileType, pathType: 'API' | 'FRONTEND'): string {
    if (!identifier || !profileType) {
      return '';
    }

    const id = idFromGlobalFriendlyIdentifier(identifier);
    const urls = URLS as any;
    switch (profileType) {
      // intermediate profiles got no profile detail page
      case INTERMEDIATE_PROFILE_TYPE:
      case ADMIN_PROFILE_TYPE:
        return '';

      case PRIVATE_PROFILE_TYPE:
        return urls[pathType + '_PRIVATE_PROFILE_FEED_PATH'] + id;

      case BLOG_PROFILE_TYPE:
        return urls[pathType + '_BLOG_PROFILE_FEED_PATH'] + id;

      case PRESS_PROFILE_TYPE:
        return urls[pathType + '_PRESS_PROFILE_FEED_PATH'] + id;

      case AUTHORITY_PROFILE_TYPE:
        return urls[pathType + '_AUTHORITY_PROFILE_FEED_PATH'] + id;

      case REPORTER_PROFILE_TYPE:
        return urls[pathType + '_REPORTER_PROFILE_FEED_PATH'] + id;

      default:
        reportError('unknown profileType in UrlUtils.getProfilePath!',
          {
            identifier,
            pathType,
            profileType,
          },
        );
        return '';
    }
  }

  public static mapAuthorTypeToApiUrl(profileType: ProfileType): string {
    switch (profileType) {
      // intermediate profiles got no profile path
      case INTERMEDIATE_PROFILE_TYPE:
        return '';

      case PRIVATE_PROFILE_TYPE:
        return URLS.API_PRIVATE_PROFILE_PATH;

      case BLOG_PROFILE_TYPE:
        return URLS.API_BLOG_PROFILE_PATH;

      case PRESS_PROFILE_TYPE:
        return URLS.API_PRESS_PROFILE_PATH;

      case AUTHORITY_PROFILE_TYPE:
        return URLS.API_AUTHORITY_PROFILE_PATH;

      case REPORTER_PROFILE_TYPE:
        return URLS.API_REPORTER_PROFILE_PATH;

      default:
        reportError('unknown profileType in UrlUtils.mapAuthorTypeToApiUrl!',
          {
            profileType,
          },
        );
        return '';
    }
  }

  public static mapAuthorTypeToUrlParam(type: ProfilesListProfileType): ProfilesListURI {
    return PROFILE_LIST_AUTHOR_TYPE_TO_URIS[type];
  }

  public static mapUrlParamToAuthorType(uri: ProfilesListURI): ProfilesListProfileType {
    return PROFILE_LIST_URIS_TO_AUTHOR_TYPE[uri] as ProfilesListProfileType;
  }

  public static conversationsPage(): string {
    return `${URLS.FRONTEND_CONVERSATION_PATH}`;
  }

  public static conversationPage(id: string): string {
    return `${URLS.FRONTEND_CONVERSATION_PATH}${id}/`;
  }

  public static sendMessageConversationPage(type: string, id: string): string {
    return `${URLS.FRONTEND_SEND_MESSAGE_CONVERSATION_PATH}${type}/${idFromGlobalFriendlyIdentifier(id)}`;
  }

  public static apiShowPath(identifier: string, type: string): string {
    switch (type) {
      case 'post':
        return `${URLS.API_POST_PATH}${identifier}`;

      case 'event':
        return `${URLS.API_EVENT_SHOW_PATH}${identifier}`;

      default:
        throw new Error(`unknown type "${type}" in UrlUtils.apiShowPath!`);
    }
  }

  public static isLockMapUrl(pathname: string) {
    return pathname.match(REGEXP_LOCK_MAP_PAGE);
  }

  // returns the current url with only host and pathname.
  public static currentUrlWithHostAndPath(): string {
    return this.canonicalUrl(window.location.href);
  }

  // returns the current url with host and pathname as well as whitelisted parameters
  public static currentUrlWithHostAndPathAndFilteredParams(): string {
    return this.filterSearchParams(window.location.href);
  }

  // returns an URL with only origin and pathname
  public static canonicalUrl(url: string): string {
    const urlObject = new URL(url);
    return urlObject.origin + urlObject.pathname;
  }

  // returns the value of the current URL search params for the given key, if present
  public static getCurrentUrlSearchParam(key: string): string | null {
    return new URLSearchParams(window.location.search).get(key);
  }

  public static getCurrentUrlPath(): string {
    return window.location.pathname;
  }

  public static getCurrentUrlOrigin(): string {
    if (!window.location.origin) {
      const port = window.location.port ? ':' + window.location.port : '';
      return `${window.location.protocol}//${window.location.hostname}${port}`;
    } else {
      return window.location.origin;
    }
  }

  /**
   * universal goBack logic
   */
  public static goBack(history: History, goto?: string) {
    const currentURL = window.location.href;
    if (!(window as unknown as ILopoCore).lopo_core.isFirstNavigationPage) {
      history.goBack();
    }

    setTimeout(() => {
      if (window.location.href === currentURL) {
        if (goto) {
          history.push(goto);
          return;
        }
        history.push('/');
      }
    }, 100);
  }

  public static setLocationHref(href: string): void {
    window.location.href = href;
  }

  public static isInPostDetailPage(path: string): boolean {
    return REGEXP_POST_DETAIL_PAGE.test(path);
  }

  public static isInDetailPage(path: string): boolean {
    return REGEXP_DETAIL_PAGE.test(path);
  }

  public static emailConfirmationRedirectUrl(): string {
    return this.getCurrentUrlOrigin() + URLS.REDIRECT_URL_REGISTRATION_EMAIL_CONFIRMATION;
  }

  private static WHITELISTED_URL_PARAMS = [
    'pt', // preview token, we need to keep this to allow previewing an unpublished post on Facebook
  ];

  private static filterSearchParams(url: string): string {
    const urlObject = new URL(url);
    const filteredParams = new URLSearchParams();

    this.WHITELISTED_URL_PARAMS.map((whitelistedParam) => {
      if (urlObject.searchParams.has(whitelistedParam)) {
        filteredParams.append(whitelistedParam, urlObject.searchParams.get(whitelistedParam) as string);
      }
    });
    urlObject.search = filteredParams.toString();

    return urlObject.origin + urlObject.pathname + urlObject.search;
  }

  public static getPostCreateURL(matchUrl: string, search: string, forceWizard: boolean, postType?: IContentItemType) {
    if (postType) {
      if (forceWizard) {
        return getCreatePathFrom(matchUrl) + `/${kebabCase(postType)}${SELECT_PATH}${search}`;
      }
      return getCreatePathFrom(matchUrl) + `/${kebabCase(postType)}${WRITE_PATH}${search}`;
    }
    return getCreatePathFrom(matchUrl) + SELECT_PATH + search;
  }
}
