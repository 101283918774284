import * as React from 'react';

// classes / components
import NavigationItem from '../navigation_item/navigation_item';

// constants
import { GOOGLE_PLAY_PATH, APP_STORE_PATH } from 'src/constants/urls';

// helpers
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import * as appStoreButton from './app_store_button.svg';
import * as googlePlayButton from './google_play_button.svg';

import './app_store_links.scss';

const cls = createBemBlock('app-store-links');

const AppStoreLinks: React.FC = () => {
  return (
    <div className={cls()}>
      <NavigationItem className={cls('google-play')} url={GOOGLE_PLAY_PATH} target='_blank'>
        <img alt='Google Play Store' src={googlePlayButton} />
      </NavigationItem>
      <NavigationItem url={APP_STORE_PATH} target='_blank'>
        <img alt='App Store' src={appStoreButton}/>
      </NavigationItem>
    </div>
  );
};

export default AppStoreLinks;
