// libs
import { combineReducers } from 'redux';

// interfaces / constants
import { MailboxReducer } from '@lokalportal/frontends-shared';
import { IRootState } from './interface';

// helpers
import appState from 'src/reducers/app-state/app-state';
import campaign from 'src/reducers/campaign/campaign';
import categories from 'src/reducers/categories/categories';
import comments from 'src/reducers/comments/comments';
import feed from 'src/reducers/feed/feed';
import filters from 'src/reducers/filters/filters';
import groups from 'src/reducers/groups/groups';
import notifications from 'src/reducers/notifications/notifications';
import post from 'src/reducers/post/post';
import profiles from 'src/reducers/profiles/profiles';
import profilesList from 'src/reducers/profiles_list/profiles_list';
import relatedContent from 'src/reducers/related_content/related_content';
import shapeSponsors from 'src/reducers/shape_sponsors/shape_sponsors';
import user from 'src/reducers/user/user';

const getRootReducer = () => combineReducers<IRootState>({
  appState,
  campaign,
  categories,
  comments,
  feed,
  filters,
  groups,
  mailbox: MailboxReducer,
  notifications,
  post,
  profiles,
  profilesList,
  relatedContent,
  shapeSponsors,
  user,
});

export default getRootReducer;
