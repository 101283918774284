// libs
import * as React from 'react';

// components
import GroupHeaderTitle from 'src/components/content_placeholder/components/group_header_title/group_header_title';
import PostBannerPlaceholder from 'src/components/content_placeholder/components/post_banner/post_banner';

const BannerFeedPlaceholder: React.FC = () => (
  <>
    <GroupHeaderTitle />
    <PostBannerPlaceholder />
    <PostBannerPlaceholder />
    <PostBannerPlaceholder />
    <PostBannerPlaceholder />
    <PostBannerPlaceholder />
  </>
);

export default BannerFeedPlaceholder;
