// libs
import * as React from 'react';

// interfaces / constants
import { IEventParticipationNotification } from 'src/interfaces/notification';

// classes / components
import Notification, { clsHighlightedText, clsText } from 'src/components/notifications/notification';

// helpers
import { textResources } from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';

// images
import * as overlayInactive from 'src/components/notifications/badges/participant@2x.png';
import * as overlayActive from 'src/components/notifications/badges/participant_active@2x.png';

const labels = textResources.notification;

export interface IProps extends IEventParticipationNotification {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
}

const EventParticipationNotification: React.FC<IProps> = ({
  contextTeaser,
  createdAt,
  initiator,
  isRead,
  onClick,
}) => {
  return (
    <Notification
      avatarImage={initiator?.image}
      badgeImageRead={overlayInactive}
      badgeImageNotRead={overlayActive}
      footer={DateFormatter.asTimeAgo(createdAt)}
      initiatorName={initiator?.name}
      isRead={isRead}
      onClick={onClick}
    >
      <span className={clsText}>{` ${labels.eventParticipationStart}`}</span>
      {contextTeaser && <strong className={clsHighlightedText}> „{contextTeaser}“</strong>}
      <span className={clsText}>{` ${labels.eventParticipationEnd}`}</span>
    </Notification>
  );
};

export default EventParticipationNotification;
