// libs
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// constants
import { IProfileDetailsParams } from 'src/actions/profile/profile';
import { FEED_TYPE_PROFILE } from 'src/constants/feed';

// helpers
import { fetchNextFeedPage } from 'src/actions/feed/next_page';
import { fetchProfileFeedPage } from 'src/actions/feed/profile_feed/profile_feed';

export const useFetchProfileFeedPage = () => {
  const dispatch = useDispatch();
  return useCallback(({ profileId, profileType }: IProfileDetailsParams) => {
    dispatch(fetchProfileFeedPage(1, { authorType: profileType, id: profileId }));
  }, [dispatch]);
};

export const useFetchProfileFeedNextPage = () => {
  const dispatch = useDispatch();
  return useCallback(({ profileId, profileType }: IProfileDetailsParams) => {
    dispatch(fetchNextFeedPage(FEED_TYPE_PROFILE, fetchProfileFeedPage, { authorType: profileType, id: profileId }));
  }, [dispatch]);
};
