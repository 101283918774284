// libs
import * as React from 'react';

// classes
import Button, { IProps as IButtonProps } from 'src/components/forms/button/button';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { mapDisplayModeToConst, PostDisplayMode } from 'src/interfaces/posts';

// helpers
import './event_participation_button.scss';

const cls: string = CLASS_PREFIX + 'event-participation-button__';

interface IProps extends IButtonProps {
  displayMode: PostDisplayMode;
}

export default class EventParticipationButton extends React.PureComponent<IProps, any> {
  private cls = '';

  constructor(props: IProps) {
    super(props);
    this.cls = `${cls}${mapDisplayModeToConst[props.displayMode]}`;
  }

  public render() {
    return (
      <Button {...this.props} className={this.cls} />
    );
  }
}
