import React, { FunctionComponent } from 'react';
import { FeatureFlag } from 'src/components/feature_flag/feature_flag';
import { getConfigValue } from 'src/utils/config/config';
import Map, { IMapProps } from './map';

export const MapFeaturedContainer: FunctionComponent<IMapProps> = props => {
  let showMap = getConfigValue<boolean>('featureFlags.map.enabled');
  if (getConfigValue('featureFlags.location.defaultAddress')) {
    showMap = false;
  }
  return <FeatureFlag active={showMap}>
    <Map {...props} />
  </FeatureFlag>;
};
