// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IEventStartEnd, IIngredient } from 'src/interfaces/post_ingredients';
import { mapDisplayModeToConst, POST_DISPLAY_MODE_DETAIL } from 'src/interfaces/posts';

// components
import Icon from 'src/components/forms/icon/icon';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import AddToCalendar from 'src/containers/add_to_calender';

// helpers
import DateFormatter from 'src/utils/date_formatter/date_formatter';

// styles
import './event_start_end.scss';

export type IProps = IIngredient<IEventStartEnd>;

export const cls = `${CLASS_PREFIX}event-start-end__`;

export default class EventStartEnd extends React.PureComponent<IProps, {}> {
  private cls = '';

  constructor(props: IProps) {
    super(props);
    this.cls = `${cls}${mapDisplayModeToConst[props.displayMode]}`;
  }

  public render() {
    const { data: { endTime, startTime }, displayMode, postDetailsURL, postId } = this.props;
    const [firstLine, secondLine] =
      renderFormattedFromTo(displayMode !== POST_DISPLAY_MODE_DETAIL, startTime, endTime);
    return (
      <NavigationItem target='_self' url={postDetailsURL} >
        <div className={this.cls}>
          <div className={this.cls + '__column'}>
            <div className={this.cls + '__icon'}>
              <Icon aria-hidden='true' name={'calendar'}/>
            </div>
          </div>
          <div className={this.cls + '__column'}>
            <p>{firstLine}</p>
            { secondLine
              ? <p>{secondLine}</p>
              : null
            }
            <AddToCalendar
              postId={postId}
              startsAt={startTime}
              endsAt={endTime}
            />
          </div>
        </div>
      </NavigationItem>
    );
  }
}

function renderFormattedFromTo(previewMode: boolean, startTime: string, endTime?: string): string[] {
  if (previewMode) {
    return [DateFormatter.parseFrom(startTime)];
  }
  return DateFormatter.parseFromTo(startTime, endTime);
}
