// library imports
import classNames from 'classnames';
import * as React from 'react';

// utils
import { textResources } from 'src/lang/de';

// constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import { BUTTON_TYPE_ROUND } from 'src/components/forms/button/button';
import CustomizedButton, { MAP_BUTTON } from 'src/components/forms/button/customized_button';
import { COLOR_TYPE_PRIMARY, COLOR_TYPE_PRIMARY_2, COLOR_TYPE_WHITE, ColorType } from 'src/utils/color';

// styles
import './map_button.scss';

interface IButtonParams {
  color: ColorType;
  icon: string;
  tooltip: string;
}

const labels = textResources.map;

const knownTypes: Record<string, IButtonParams> = {
  expand: {
    color: COLOR_TYPE_WHITE,
    icon: '',
    tooltip: labels.expandButtonTooltip,
  },
  home: {
    color: COLOR_TYPE_PRIMARY_2,
    icon: 'logo-lokalportal',
    tooltip: labels.homeButtonTooltip,
  },
  locate_me: {
    color: COLOR_TYPE_WHITE,
    icon: 'locate-me-dot',
    tooltip: labels.locateMeButtonTooltip,
  },
  search: {
    color: COLOR_TYPE_PRIMARY,
    icon: 'nav-pin-filled',
    tooltip: labels.locationChangerButtonTooltip,
  },
};

interface IProps {
  hideOnDesktop?: boolean;
  hideOnMobile?: boolean;
  type: string;
  onClick?: () => void;
}

const MapButton: React.FC<IProps> =
  ({ hideOnMobile = false, hideOnDesktop = false, onClick, type }) => {
    const { color, icon, tooltip } = knownTypes[type];
    const cls = {
      [`${CLASS_PREFIX}icon_button`]: true,
      [`${CLASS_PREFIX}icon_button--hidden-on-desktop`]: hideOnDesktop,
      [`${CLASS_PREFIX}icon_button--hidden-on-mobile`]: hideOnMobile,
    };

    return (
      <div className={classNames(cls)}>
        <CustomizedButton
          variant={BUTTON_TYPE_ROUND}
          onClick={onClick}
          title={tooltip}
          icon={icon}
          color={color}
          customType={MAP_BUTTON}
        />
      </div>
    );
  };

export default MapButton;
