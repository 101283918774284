// libs

// interfaces / constants
import {
  DEFAULT_AUTHOR,
  PAYWALL_CLASS,
  POST_DEFAULT_DESCRIPTION,
} from 'src/constants/meta_tags';
import { STRUCTURED_DATA_LOGO_PATH } from 'src/constants/urls';
import {
  IEventLocation,
} from 'src/interfaces/post_ingredients';
import { IPost } from 'src/interfaces/posts';
import { IProfile } from 'src/interfaces/profile';

// helper
import { UrlUtils } from 'src/utils/url/url';

interface IIngredientData {
  description?: string;
  endTime?: string;
  eventLocation?: IEventLocation;
  startTime?: string;
  title?: string;
}

export class JsonLdSchemaHelper {
  public static detailPagePost(post: IPost, author: IProfile): string {
    const { meta, publishedAt, updatedAt } = post;
    const ingredientData = this.getIngredientData(post);
    const currentUrl = UrlUtils.currentUrlWithHostAndPath();
    const premiumPart = meta.premiumContent
      ? `,"isAccessibleForFree": "False",
        "hasPart": {
          "@type": "WebPageElement",
          "isAccessibleForFree": "False",
          "cssSelector" : "${PAYWALL_CLASS}"
        }`
      : '';
    return (
      `{
        "@context": "http://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
          "@type": "WebPage",
          "@id": "${currentUrl}"
        },
        "headline": ${ingredientData.title ? JSON.stringify(ingredientData.title) : '""'},
        "image": [
          "${post.featuredImage}"
         ],
        "datePublished": "${publishedAt}",
        "dateModified": "${updatedAt}",
        "author": {
          "@type": "Person",
          "name": ${JSON.stringify(author.name)}
        },
        "publisher": {
          "@type": "Organization",
          "name": "${DEFAULT_AUTHOR}",
          "logo": {
            "@type": "ImageObject",
            "url": "${STRUCTURED_DATA_LOGO_PATH}"
          }
        },
        "description": ${ingredientData.description ? JSON.stringify(ingredientData.description) : '""'}
        ${premiumPart}
      }`
    );
  }

  public static detailPageEvent(post: IPost): string {
    const ingredientData = this.getIngredientData(post);
    const endDate = ingredientData.endTime
      ? `,"endDate": "${ingredientData.endTime}"`
      : '';
    const locationDescription = ingredientData.eventLocation && ingredientData.eventLocation.description
      ? JSON.stringify(ingredientData.eventLocation.description)
      : '""';

    return (
      `{
        "@context": "http://schema.org",
        "@type": "Event",
        "name": ${ingredientData.title ? JSON.stringify(ingredientData.title) : '""'},
        "startDate": "${ingredientData.startTime}",
        "location": {
          "@type": "Place",
          "name": ${locationDescription},
          "address": {
            "@type": "PostalAddress",
            "streetAddress": ${ingredientData.eventLocation && JSON.stringify(ingredientData.eventLocation.street)},
            "addressLocality": ${ingredientData.eventLocation && JSON.stringify(ingredientData.eventLocation.city)},
            "postalCode": ${ingredientData.eventLocation && JSON.stringify(ingredientData.eventLocation.zip)},
            "addressCountry": "DE"
          }
        },
        "image": [
          "${post.featuredImage}"
         ],
        "description": ${ingredientData.description ? JSON.stringify(ingredientData.description) : '""'}
        ${endDate}
      }`
    );
  }

  // this function can get a better place to stay
  public static getIngredientData(post: IPost): IIngredientData {
    const ingredientData: IIngredientData = {
      description: POST_DEFAULT_DESCRIPTION,
    };

    post.ingredientIds.forEach((ingredientId) => {
      const ingredient = post.ingredients[ingredientId]!;
      switch (ingredient.type) {
        case 'title':
          ingredientData.title = ingredient.data.title;
          break;
        case 'bodyMarkdown':
          ingredientData.description = ingredient.data.teaserContent;
          break;
        case 'bodyText':
          // do not overwrite a description we already got from a `'bodyMarkdown'` ingredient
          if (ingredientData.description === POST_DEFAULT_DESCRIPTION) {
            ingredientData.description = ingredient.data.teaserContent;
          }
          break;
        case 'eventStartEnd':
          ingredientData.startTime = ingredient.data.startTime;
          ingredientData.endTime = ingredient.data.endTime || undefined;
          break;
        case 'eventLocation':
          ingredientData.eventLocation = ingredient.data;
          break;
      }
    });
    return ingredientData;
  }
}
