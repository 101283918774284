// libs
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// constants/ interfaces
import { ICategoryType } from 'src/api/interfaces/requests';

// helpers
import { fetchCategories } from 'src/actions/categories/categories';

export const useFetchCategories = (model: ICategoryType) => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchCategories(model));
  }, [dispatch, model]);
};
