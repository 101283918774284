// lib
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// components
import Header from 'src/components/header/header';

// interfaces / constants
import { IAction } from 'src/actions/';
import { ActivableNavigationItems } from 'src/components/header/navigation/header_navigation';
import { POPUP_TYPE_MAP, POPUP_TYPE_NONE } from 'src/interfaces/app-state';
import { IPermissions } from 'src/interfaces/user';
import { IRootState } from 'src/reducers/interface';

// helpers
import { showLoginModal, showRegistrationModal, toggleTopbarPopupMenu } from 'src/actions/app-state/app-state';

interface IMapStateToProp {
  isMapShown: boolean;
  loggedIn: boolean;
  loggedInUserEmail?: string;
  selectedProfilePermissions?: IPermissions;
  selectedProfileUnreadNotificationsCount?: number;
}

interface IDispatchFromProps {
  hideMap: () => void;
  showLogInModal: () => void;
  showRegistrationModal?: () => void;
}

interface IOwnProps {
  activeNavigationItem?: ActivableNavigationItems;
  enableMapToggler: boolean;
  subHeader?: JSX.Element;
}

const mapDispatchToProps = (dispatch: Dispatch<IAction>): IDispatchFromProps => ({
  // when converting to FC use hook useHideMap in actions/app-state/app-state_hooks.ts
  hideMap: () => dispatch(toggleTopbarPopupMenu(POPUP_TYPE_NONE)),
  showLogInModal: () => dispatch(showLoginModal()),
  showRegistrationModal: () => dispatch(showRegistrationModal()),
});

const mapStateToProps = ({ user, notifications, appState }: IRootState): IMapStateToProp => ({
  isMapShown: appState.activePopupMenu === POPUP_TYPE_MAP,
  loggedIn: user.loggedIn,
  loggedInUserEmail: user.email,
  selectedProfilePermissions: user.profiles[user.selectedProfileId]
    ? user.profiles[user.selectedProfileId].permissions
    : undefined,
  selectedProfileUnreadNotificationsCount: notifications.forProfile[user.selectedProfileId]
    ? notifications.forProfile[user.selectedProfileId]!.unseenCount || 0
    : 0,
});

export default connect<IMapStateToProp, IDispatchFromProps, IOwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Header);
