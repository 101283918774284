import { useEffect, useMemo, useRef } from 'react';

const useMemoOptions = ({ passive, capture, once }: AddEventListenerOptions = {}) => {
  return useMemo(() => ({ capture, once, passive }), [passive, capture, once]);
};

// https://usehooks.com/useEventListener/
const useEventListener = <K extends keyof WindowEventMap, E extends WindowEventMap[K]>(
  name: K,
  handler: (e: E) => void,
  options?: AddEventListenerOptions,
  element = window,
) => {
  const savedHandler = useRef<typeof handler>(handler);
  const memoOptions = useMemoOptions(options);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    const eventListener = (event: E) => savedHandler.current(event);

    if (!isSupported) return;

    element.addEventListener(name, eventListener, memoOptions);

    return () => {
      element.removeEventListener(name, eventListener);
    };
  }, [name, element, memoOptions]);
};

const createEventListener = <K extends keyof WindowEventMap>(name: K) => (
  handler: (event: WindowEventMap[K]) => void,
  options?: AddEventListenerOptions,
  element = window,
) => {
  return useEventListener(name, handler, options, element);
};

export const useScrollListener = createEventListener('scroll');
export const useClickListener = createEventListener('click');
export const useResizeListener = createEventListener('resize');
export const useMessageListener = createEventListener('message');
