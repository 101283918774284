// libs
import * as React from 'react';

// components
import MessageSegment from 'src/components/message_segment/message_segment';
import Modal from 'src/components/modal/modal';

// interfaces / constants
import { ICTAModalContent } from 'src/components/cta/cta_intermediate_options';
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import './message_segment.scss';

const cls: string = CLASS_PREFIX + 'cta';

export default class MessageSegmentWithModal extends React.PureComponent<ICTAModalContent, {}> {
  public render() {
    const { buttons, children, description, image, onClose, title } = this.props;
    return (
      <Modal hasCloseButton={!!onClose} onClose={onClose}>
        <div className={cls}>
          <MessageSegment
            headerIcon={image}
            title={title}
            message={description}
            buttons={buttons}
          />
          {children}
        </div>
      </Modal>
    );
  }
}
