import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// components
import GroupMembersLink from 'src/components/group_members_link/group_members_link';
import MessageBoxModal from 'src/components/message_box/message_box_with_modal';

// constants & interfaces
import {
  MSGBOX_BUTTON_TYPE_CANCEL,
  MSGBOX_BUTTON_TYPE_PRIMARY,
} from 'src/components/message_box/message_box';
import { CLASS_PREFIX } from 'src/constants/';
import { FRONTEND_GROUP_OVERVIEW_PATH } from 'src/constants/urls';
import { IGroup } from 'src/interfaces/group';

// helpers
import { textResources } from 'src/lang/de';

import './group_info.scss';

const cls = `${CLASS_PREFIX}group-info`;
const labels = textResources.groups;

interface IProps {
  group: IGroup;
  joinGroup: () => void;
  intermediateOrNotLoggedIn: boolean;
}

const GroupInfo: React.FC<IProps> = ({ group, joinGroup, intermediateOrNotLoggedIn }) => {
  const [messageBoxShown, setMessageBoxShown] = useState(false);
  const history = useHistory();

  const showMessageBox = () => {
    setMessageBoxShown(true);
  };

  const hideMessageBox = () => {
    setMessageBoxShown(false);
  };

  const getOptions = () => {
    if (group.permissions.canJoin) {
      return {
        info: labels.ctaNotJoinedMembers.infoCanJoin_WEB,
        mainButton: {
          label: labels.joinGroup,
          onClick: () => {
            joinGroup();
            hideMessageBox();
          },
        },
      };
    }
    return {
      info: labels.ctaNotJoinedMembers.infoCanNotJoin,
      mainButton: {
        label: labels.groupsInMyRegion,
        onClick: () => {
          history.push(FRONTEND_GROUP_OVERVIEW_PATH);
        },
      },
    };
  };

  const options = getOptions();

  return (
    <div className={cls} >
      {messageBoxShown &&
        <MessageBoxModal
          title={labels.ctaNotJoinedMembers.title_WEB(group.name)}
          onClose={hideMessageBox}
          buttons={[{
            label: options.mainButton.label,
            onClick: options.mainButton.onClick,
            type: MSGBOX_BUTTON_TYPE_PRIMARY,
          }, {
            label: textResources.shared.cancel,
            onClick: hideMessageBox,
            type: MSGBOX_BUTTON_TYPE_CANCEL,
          }]}
        >
          {options.info}
        </MessageBoxModal>}
      <h3 className={cls + '__title'}>{group.name}</h3>
      <p className={cls + '__member-count'}>{labels.memberCount(group.memberCount)}</p>
      <p className={cls + '__description'}>{group.description}</p>
      <GroupMembersLink
        onNoReadPermissions={showMessageBox}
        intermediateOrNotLoggedIn={intermediateOrNotLoggedIn}
        canRead={group.permissions.canReadMemberList}
        groupIdentifier={group.id}
      />
    </div>);
};

export default GroupInfo;
