// libs
import React, { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
// interfaces / constants
// components
import BackbuttonHeader from 'src/components/header/common/backbutton_header/backbutton_header';
// helpers
import { useAuthorTypeFromURL } from 'src/actions/profiles_list/profiles_list_hooks';
import { URLContext } from 'src/containers/url_provider/url_provider';
import Translate from 'src/lang/de';
import { UrlUtils } from 'src/utils/url/url';

interface IProps {}

const HeaderProfilesDirectory: React.FC<IProps> = () => {
  const history = useHistory();
  const { getProfilesDirectoryRoute } = useContext(URLContext);
  const authorType = useAuthorTypeFromURL();
  const title = authorType ? Translate.authorTypeCategoryTitle[authorType] : '';
  const route = getProfilesDirectoryRoute();
  const handleBackButton = useCallback(() => UrlUtils.goBack(history, route), [history, route]);

  return (
    <BackbuttonHeader
      title={title}
      handleBackButton={handleBackButton}
    />
  );
};

HeaderProfilesDirectory.displayName = 'HeaderProfilesDirectory';

export default HeaderProfilesDirectory;
