import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// interfaces
import { FEED_TYPE_GROUP } from 'src/constants/feed';
import { Id } from 'src/interfaces';
import { IGroup } from 'src/interfaces/group';

// helpers
import { fetchGroupFeedPage } from 'src/actions/feed/group_feed/group_feed';
import { fetchNextFeedPage } from 'src/actions/feed/next_page';
import { fetchMembers, getGroup, joinGroup, fetchMoreGroups, fetchJoinedGroups } from 'src/actions/groups/groups';
import { useProfilesList } from 'src/reducers/profiles_list/profiles_list_hooks';

export const useGetGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  return useCallback(
    (id: Id) => {
      dispatch(getGroup(id, history));
    },
    [dispatch, history],
  );
};

export const useFetchGroupFeedPage = () => {
  const dispatch = useDispatch();
  return useCallback((group: IGroup) => {
    dispatch(fetchGroupFeedPage(1, group));
  }, [dispatch]);
};

export const useFetchGroupFeedNextPage = () => {
  const dispatch = useDispatch();
  return useCallback((group: IGroup) => {
    dispatch(fetchNextFeedPage(FEED_TYPE_GROUP, fetchGroupFeedPage, group));
  }, [dispatch]);
};

export const useGetGroupMembersNextPage = (id: Id, url?: string) => {
  const dispatch = useDispatch();
  const { pagination: { currentPage, lastPage } } = useProfilesList();

  return useCallback(
    () => {
      if (!url || lastPage) {
        return;
      }

      dispatch(fetchMembers({ groupIdentifier: id, page: currentPage + 1, path: url }));
    },
    [currentPage, dispatch, id, lastPage, url],
  );
};

export const useGetGroupMembers = (id: Id, url?: string) => {
  const dispatch = useDispatch();
  return useCallback(
    () => {
      if (!url) {
        return;
      }

      dispatch(fetchMembers({ groupIdentifier: id, page: 1, path: url, refresh: true }));
    },
    [dispatch, id, url],
  );
};

export const useJoinGroup = () => {
  const dispatch = useDispatch();
  return useCallback(
    (group: IGroup) => {
      dispatch(joinGroup(group));
    }, [dispatch]
  );
};

export const useFetchMoreGroups = () => {
  const dispatch = useDispatch();
  return useCallback(
    (page?: number) => {
      dispatch(fetchMoreGroups(page));
    }, [dispatch]
  );
};

export const useFetchJoinedGroups = () => {
  const dispatch = useDispatch();
  return useCallback(
    () => {
      dispatch(fetchJoinedGroups());
    }, [dispatch]
  );
};
