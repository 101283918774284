// libs
import * as React from 'react';

// interfaces / constants
import { COLOR_TYPE_PRIMARY } from 'src/utils/color';

// classes / components
import Button, { BUTTON_TYPE_CONTAINED, BUTTON_TYPE_TEXT } from 'src/components/forms/button/button';
import FieldSet from 'src/components/forms/field_set/field_set';
import InputField from 'src/components/forms/input_field/input_field';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';
import MessageSegmentWithModal from 'src/components/message_segment/message_segment_with_modal';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import api from 'src/api/';
import { textResources } from 'src/lang/de';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import initTracker, { BLOG_PROFILE_ADMIN } from 'src/utils/reporting/events_tracking/events_tracking';
import { validateEmail } from 'src/utils/validation';

import './blog_profile_admin_modal.scss';

const tracker = initTracker(BLOG_PROFILE_ADMIN);
const labels = textResources.manageBlogProfileAdmin;

interface IProps {
  onClose: () => void;
  profileName: string;
}

interface IState {
  emails: string;
  isValid: boolean;
  formSubmitted: boolean;
  isLoading: boolean;
}

const cls = createBemBlock('blog_profile_admin_modal');

export default class BlogProfileAdminModal extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.changeEmails = this.changeEmails.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.renderSuccess = this.renderSuccess.bind(this);

    this.state = {
      emails: '',
      formSubmitted: false,
      isLoading: false,
      isValid: true,
    };
  }

  public componentDidMount() {
    tracker(BLOG_PROFILE_ADMIN.ACTIONS.REQUEST, BLOG_PROFILE_ADMIN.LABELS.START);
  }

  public render() {
    const { formSubmitted } = this.state;

    return formSubmitted ? this.renderSuccess() : this.renderForm();
  }

  private renderForm() {
    const { onClose, profileName } = this.props;
    const { emails, isLoading, isValid } = this.state;

    return (
      <MessageBoxWithModal
        isLoading={isLoading}
        onClose={onClose}
        whiteBackground
        title={labels.menuName}
      >
        <div className={cls('body')}>
          {labels.formBodyStart}<strong>{profileName}</strong>{labels.formBodyEnd}
        </div>
        <div className={cls('input')}>
          <FieldSet>
            <InputField
              assistiveText={textResources.shared.formAssistiveText}
              name='email'
              label={textResources.spread.formFieldEmailsPlaceholder}
              onChange={this.changeEmails}
              value={emails}
              valid={isValid}
              errorText={textResources.shared.formErrorInvalid}
              outlined
              required
              type='email'
            />
          </FieldSet>
        </div>
        <Button
          variant={BUTTON_TYPE_CONTAINED}
          color={COLOR_TYPE_PRIMARY}
          label={labels.menuName}
          fullWidth
          onClick={this.submitForm}
          lowerCase
        />

        <div className={cls('footer')}>
          <Button
            variant={BUTTON_TYPE_TEXT}
            fullWidth
            onClick={onClose}
            label={textResources.shared.cancel}
            lowerCase
          />
        </div>
      </MessageBoxWithModal>
    );
  }

  private renderSuccess() {
    const { onClose } = this.props;
    const { emails } = this.state;

    return (
      <MessageSegmentWithModal
        image={'admin-success-screen'}
        title={labels.formSuccessTitle}
        description={(
          <>
            {labels.formSuccessBodyStart}<strong>{emails}</strong>{labels.formSuccessBodyEnd}
            <NavigationItem
              className={cls('info-link')}
              target={'_blank'}
              url={`mailto:${textResources.shared.helpEmail}`}
            >
              {textResources.shared.helpEmail}
            </NavigationItem>
          </>
        )}
        onClose={onClose}
      />
    );
  }

  private changeEmails(value: string) {
    this.setState({ emails: value, isValid: true });
  }

  private submitForm() {
    const { emails } = this.state;
    const validEmail = validateEmail(emails, true);

    if (!validEmail) {
      this.setState({ isValid: false });
      return;
    }

    this.setState({ isLoading: true });
    const param = {
      // It's promised parameter string with server
      // https://gitlab.naymspace.de/lokalportal/lokalportal/issues/5270#technical-details
      message: `Anfrage: Seiten-Administrator hinzufügen E-Mail-Addressen: ${emails}`,
    };

    api.feedback.create(param).then(() => {
      this.setState({ formSubmitted: true, isLoading: false });
      tracker(BLOG_PROFILE_ADMIN.ACTIONS.REQUEST, BLOG_PROFILE_ADMIN.LABELS.FINISH);
    });
  }
}
