// libs
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type MapRouteToPropsFn<P> = (routeProps: RouteComponentProps) => P;

export const withRouterProps = <P, O>(mapRouteToProps: MapRouteToPropsFn<P>) => {
  return (Comp: React.ComponentType<O & P>) => {
    const WrappedComp: React.FC<O & RouteComponentProps > = (mergedProps) => {
      const { history, location, match, staticContext, ...ownProps } = mergedProps;
      return <Comp {...mapRouteToProps({ history, location, match })} {...(ownProps as O)}/>;
    };
    return withRouter(WrappedComp);
  };
};
