// interfaces
import * as Response from 'src/api/interfaces/responses/post';
import { FEED_POST_TYPE_SNAPSHOT } from 'src/constants/feed';
import {
  IComments,
  IContentItemType,
  IEventEditView,
  IPostEditView,
  IPostMeta,
  IPostResponse,
} from 'src/interfaces/posts';
// helper & utils
import { textResources } from 'src/lang/de';
import { createDateAddress } from 'src/utils/location';
import { UrlUtils } from 'src/utils/url/url';
// decoders
import { $attachments } from 'src/api/decoders/attachment';
import { $category } from 'src/api/decoders/category';
import { $locationShape } from 'src/api/decoders/location_shape';
import { $postMarkers } from 'src/api/decoders/marker';
import { $oglink } from 'src/api/decoders/oglink';
import { $ingredients } from 'src/api/decoders/post_ingredients';
import { $profile } from 'src/api/decoders/profile';
import { $reactions } from 'src/api/decoders/reaction';
import { $address } from './address';

export const $postResponse = (json: Response.IPostResponse): IPostResponse => $post(json.data);

export const $post = (json: Response.IPost): IPostResponse => {
  const { city, street, city_with_district, longitude, latitude } = json.public_address;
  const { content_item_type } = json.meta;
  const { teaser, title } = $postTeaserTitle(json.data, content_item_type);

  const address = createDateAddress(street || undefined, city_with_district);
  const position = {
    latitude,
    longitude,
  };

  const author = $profile(json.author.data);

  const postResponse: IPostResponse = {
    post: {
      ...$ingredients(json.ingredients, content_item_type),
      address,
      authorId: author.id,
      city,
      commentable: json.data.commentable,
      comments: $postComments(json.comments),
      featuredImage: json.data.featured_image,
      id: json.data.identifier,
      locationShape: $locationShape(json.public_address.location_shape),
      markers: [],
      meta: $postMeta(json.meta, teaser, title),
      position,
      publishedAt: json.data.published_at,
      survey: json.data.survey,
      startTime: json.data.start_time === null ? undefined : json.data.start_time,
      surveyUrl: json.data.teaser_content,
      surveyTitle: json.data.survey ? json.data.title : undefined,
      updatedAt: json.data.updated_at,
      urls: {
        api: UrlUtils.apiShowPath(json.data.identifier, json.data.type),
        frontend: UrlUtils.detailPage(json.data.identifier, json.data.type),
      },
    },
    profile: author,
    reactions: $reactions(json.reactions),
  };

  postResponse.post.markers = $postMarkers(postResponse, json.markers);

  if (json.data.external_url) {
    postResponse.post.externalUrl = json.data.external_url;
  }

  return postResponse;
};

const $postComments = (json: Response.IComments): IComments => ({
  number: json.meta.count,
  urls: {
    create: json.meta.urls.create,
    get: json.meta.urls.get,
  },
});

const mapContentItemType = (type: Response.IContentItemType): IContentItemType => {
  switch (type) {
    case FEED_POST_TYPE_SNAPSHOT:
      return FEED_POST_TYPE_SNAPSHOT;
    case 'private_post':
      return 'privatePost';
    case 'event':
      return 'event';
    case 'public_post':
    default:
      return 'publicPost';
  }
};

const $postMeta = (json: Response.IMeta, teaser: string, title: string): IPostMeta => ({
  advertised: json.advertised,
  contentItemType: mapContentItemType(json.content_item_type),
  currently_advertised: json.currently_advertised,
  fenced: json.fenced,
  localAuthor: json.local_author,
  permissions: {
    canBookmark: json.permissions.can_bookmark,
    canComment: json.permissions.can_comment,
    canDelete: json.permissions.can_delete,
    canFlag: json.permissions.can_flag,
    canShare: json.permissions.can_share,
    canUpdate: json.permissions.can_update,
  },
  premiumContent: json.premium_content,
  schema: json.schema,
  teaser,
  title,
  urls: {
    addView: json.urls.add_view,
    edit: json.urls.edit,
    flag: json.urls.flag,
    similar: json.urls.similar,
    update: json.urls.update,
  },
});

export const $postEditView = (json: Response.IPostEditView): IPostEditView => ({
  attachments: $attachments(json.data.attachments),
  body: json.data.body,
  linkPreview: json.data.oglink ? $oglink(json.data.oglink) : undefined,
  title: json.data.title,
});

export const $eventEditView = (json: Response.IEventEditView): IEventEditView => ({
  address: $address(json.data.address),
  attachments: $attachments(json.data.attachments),
  body: json.data.body,
  categories: json.data.categories.map($category),
  endTime: json.data.end_time,
  linkPreview: json.data.oglink ? $oglink(json.data.oglink) : undefined,
  startTime: json.data.start_time,
  title: json.data.title,
});

interface ITeaserTitle {
  teaser: string;
  title: string;
}

const $postTeaserTitle = (data: Response.IPostData, type: string): ITeaserTitle => {
  if (type === FEED_POST_TYPE_SNAPSHOT) {
    const teaser = data.teaser_content || textResources.postTypes.snapshot;
    return {
      teaser,
      title: data.title || teaser,
    };
  }

  return {
    teaser: data.teaser_content || '',
    title: data.title || '',
  };
};
