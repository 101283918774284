// helpers
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getNewsFeedHighlights } from 'src/actions/feed/news_feed/highlights/highlights';

export const useGetNewsFeedHighlights = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(getNewsFeedHighlights());
  }, [dispatch]);
};
