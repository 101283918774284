import { IAutocompleteParams } from 'src/api/google/';
import * as Request from 'src/api/google/interfaces/requests';

export const DEAULT_RADIUS = 1000000;

export const $googleAutocompletion = (params: IAutocompleteParams): Request.IAutocomplete => {
  const result = {
    input: params.query,
    types: params.type ? [params.type] : [],
  };

  if (params.position) {
    const position = params.position;

    return { ...result,
      ...{
        location: {
          lat: () => position.latitude,
          lng: () => position.longitude,
        },
        // required when position is used, but its not really very relevant (strict)
        radius: params.radius || DEAULT_RADIUS,
      } };
  }
  return result;
};
