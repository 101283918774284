import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

// interfaces / constants
import { POPUP_TYPE_NONE } from 'src/interfaces/app-state';
import { ILocationShape } from 'src/interfaces/location';
import { IMarker } from 'src/interfaces/marker';

// helpers / actions
import {
  changeAppLocation,
  setMarkers,
  showRegistrationModal,
  showLoginModal,
  hideSnackBar,
  toggleTopbarPopupMenu,
} from 'src/actions/app-state/app-state';
import { logOut } from 'src/actions/user/user';

export const useDispatchSetMarkers = () => {
  const dispatch = useDispatch();

  // we want to cleanup markers after component unmount
  useEffect(() => {
    return () => {
      dispatch(setMarkers([]));
    };
  }, [dispatch]);

  return useCallback(
    (markers: IMarker[]) => dispatch(setMarkers(markers)),
    [dispatch],
  );
};

export const useHideSnackBar = () => {
  const dispatch = useDispatch();

  return useCallback(
    () => dispatch(hideSnackBar()),
    [dispatch]
  );
};

export const useShowLoginModal = () => {
  const dispatch = useDispatch();
  return useCallback(
    () => dispatch(showLoginModal()),
    [dispatch]
  );
};

export const useShowRegistrationModal = () => {
  const dispatch = useDispatch();
  return useCallback(
    () => dispatch(showRegistrationModal()),
    [dispatch]
  );
};

export const useChangeAppLocation = () => {
  const dispatch = useDispatch();
  return useCallback((locationShape: ILocationShape) => {
    dispatch(changeAppLocation(locationShape, undefined, undefined, true, locationShape.boundingBox));
  }, [dispatch]);
};

export const useLogOut = () => {
  const dispatch = useDispatch();
  return useCallback(
    () => dispatch(logOut()),
    [dispatch]);
};

export const useHideMap = () => {
  const dispatch = useDispatch();
  return useCallback(
    () => dispatch(toggleTopbarPopupMenu(POPUP_TYPE_NONE)),
    [dispatch]);
};
