import { difference, flatten, intersection, memoize } from 'lodash';
import { GroupedPosts } from 'src/interfaces/feed';
// interfaces

// Return a feed consisting of postIds not included in the mainFeed
export const removeDuplicateEventsInSecondaryFeed = memoize(
  (groups: GroupedPosts, secondaryGroups: GroupedPosts): GroupedPosts => {
    if (!groups.length || !secondaryGroups.length) {
      return secondaryGroups;
    }

    const duplicateIds =
      intersection(flatten(groups.map(({ ids }) => ids)), flatten(secondaryGroups.map(({ ids }) => ids)));

    return secondaryGroups
      .map(group => ({ ...group, ids: difference(group.ids, duplicateIds) }))
      .filter(group => group.ids.length > 0);
  }, (_, secondaryGroups) => secondaryGroups);
