// decoders
import { $attachments } from 'src/api/decoders/attachment';
import { $oglink } from 'src/api/decoders/oglink';
import { $profile } from 'src/api/decoders/profile';
import { $reactions } from 'src/api/decoders/reaction';

// interfaces
import * as Response from 'src/api/interfaces/responses/comment';
import {
  IDeletedCommentPayload,
  ICommentPayload,
  ICommentItemPayload,
  ICommentsPayload,
} from 'src/interfaces/comments';

export const $commentCreate = (json: Response.ICommentCreateResponse): ICommentPayload => activeComment(json.data);

export const $comments = (json: Response.ICommentsResponse): ICommentsPayload =>
  json.data.reduce((acc: ICommentsPayload, data) => {
    const decoded = $comment(data);
    acc.comments.push(decoded.comment);
    if ('profile' in decoded) {
      acc.profiles.push(decoded.profile);
    }
    return acc;
  }, { comments: [], profiles: [] });

export const $commentSingle = (json: {data: Response.ICommentItem}): ICommentItemPayload => $comment(json.data);

export const $comment = (json: Response.ICommentItem): ICommentItemPayload =>
  Response.isDeletedComment(json) ? deletedComment(json) : activeComment(json);

const activeComment = (json: Response.IComment): ICommentPayload => {
  const profile = $profile(json.author);
  return {
    comment: {
      attachments: $attachments(json.attachments),
      authorId: profile.id,
      createdAt: json.created_at,
      deletedAt: json.deleted_at,
      id: json.identifier,
      linkPreview: json.oglink ? $oglink(json.oglink) : undefined,
      message: json.message,
      meta: {
        canBookmark: json.meta.can_bookmark,
        canDelete: json.meta.can_delete,
        canFlag: json.meta.can_flag,
        canUpdate: json.meta.can_update,
        isOwn: json.meta.is_own,
      },
      reactions: $reactions(json.reactions),
      updatedAt: json.updated_at,
      urls: json.urls,
    },
    profile,
  };
};

const deletedComment = (json: Response.IDeletedComment): IDeletedCommentPayload => ({
  comment: {
    createdAt: json.created_at,
    deletedAt: json.deleted_at,
    id: json.identifier,
    meta: {
      canBookmark: json.meta.can_bookmark,
      canDelete: json.meta.can_delete,
      canFlag: json.meta.can_flag,
      canUpdate: json.meta.can_update,
      deletedBy: json.meta.deleted_by,
      isOwn: json.meta.is_own,
    },
    reactions: null, // See declaration of IDeletedComment for reasons why this has to be here
    updatedAt: json.updated_at,
    urls: json.urls,
  },
});
