import React from 'react';
import { RouteComponentProps } from 'react-router';

import PostDetailsPage from 'src/pages/post_details';

const EventDetailsPage: React.FC<RouteComponentProps<{ id: string }>> = (routeProps) => (
  <PostDetailsPage {...routeProps} isEvent />
);

export default EventDetailsPage;
