import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { FollowParams, IFollowUpdateNotificationsByProfilesParams, IUnFollowByProfilesParams } from 'src/api';

// helpers / actions
import {
  follow,
  updateFollow,
  unFollow,
} from 'src/actions/follow/follow';

export const useFollow = () => {
  const dispatch = useDispatch();

  return useCallback(
    (param: FollowParams) => dispatch(follow(param)),
    [dispatch]
  );
};

export const useUpdateFollow = () => {
  const dispatch = useDispatch();

  return useCallback(
    (param: IFollowUpdateNotificationsByProfilesParams) => dispatch(updateFollow(param)),
    [dispatch]
  );
};

export const useUnFollow = () => {
  const dispatch = useDispatch();

  return useCallback(
    (param: IUnFollowByProfilesParams) => dispatch(unFollow(param)),
    [dispatch]
  );
};
