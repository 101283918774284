import api, { IOAuth2LoginParams } from 'src/api/index';

import { IErrorResponse } from 'src/api/interfaces/errors';
import { SHOULD_NOT_SEE_LANDING_PAGE } from 'src/constants/landing_page_cookie';
import { IOAuth2Credentials } from 'src/interfaces/oauth2';

import {
  REDIRECT_PARAMS_LOGIN_FACEBOOK,
  OAUTH2_FACEBOOK_LOGIN_URL,
  OAUTH2_APPLE_LOGIN_URL,
  REDIRECT_PARAMS_LOGIN_APPLE,
} from 'src/constants/urls';
import { IDENTITY_PROVIDER_FACEBOOK, IDENTITY_PROVIDER_APPLE, IdentityProviderType } from 'src/constants/user';
import { IWindowJsEnv } from 'src/interfaces';
import { IIdentity } from 'src/interfaces/user';
import initTracker, { LOGIN } from 'src/utils/reporting/events_tracking/events_tracking';
import { reportError } from 'src/utils/reporting/report-errors';
import * as storageWrapper from 'src/utils/storage_wrapper/storage_wrapper';
import { UrlUtils } from 'src/utils/url/url';

export const STORAGE_KEY = 'oauth2';
export const AUTHORIZATION_HEADER_NAME = 'Authorization';
export const AUTHORIZATION_HEADER_VALUE_PREFIX = 'Bearer';

interface IAuthorizationHeader {
  [key: string]: string | undefined;
}

const _window = window as unknown as IWindowJsEnv;
const oauthClientId = _window.js_env.oauth_client_id;

const loginTracker = initTracker(LOGIN);

interface IThirdPartyLoginArguments {
  loginUrl: (oauthClientId: string, redirectUrl: string) => string;
  redirectParams: string;
  trackingAction: string;
}

const loginWithThirdParty = ({ loginUrl, redirectParams, trackingAction }: IThirdPartyLoginArguments) => () => {
  const redirectUrl = UrlUtils.currentUrlWithHostAndPath() + encodeURIComponent(`?${redirectParams}`);
  const callback = () => UrlUtils.setLocationHref(loginUrl(oauthClientId, redirectUrl));
  loginTracker(trackingAction, LOGIN.LABELS.START, callback);
};

export const loginWithFacebook = loginWithThirdParty({
  loginUrl: OAUTH2_FACEBOOK_LOGIN_URL,
  redirectParams: REDIRECT_PARAMS_LOGIN_FACEBOOK,
  trackingAction: LOGIN.ACTIONS.FACEBOOK,
});

export const loginWithApple = loginWithThirdParty({
  loginUrl: OAUTH2_APPLE_LOGIN_URL,
  redirectParams: REDIRECT_PARAMS_LOGIN_APPLE,
  trackingAction: LOGIN.ACTIONS.APPLE,
});

export class AuthenticationHelper {
  // checks if there are OAuth2 tokens stored
  public static hasOAuth2TokensStored(): boolean {
    return !!this.getStoredOAuth2Credentials();
  }

  // returns the remaining time (in seconds) until the OAuth2 access token expires;
  // returns `0` if there is no token, or it is expired
  // if no credentials are given, stored credentials are read
  public static getTokenExpiration(credentials?: IOAuth2Credentials): number {
    if (!credentials) {
      credentials = this.parseStoredOAuth2Credentials();
    }

    if (!credentials) {
      return 0;
    }

    return Math.max(0, credentials.createdAt + credentials.expiresIn - this.nowInSeconds());
  }

  // checks if the OAuth2 access token is present and not expired
  // if no credentials are given, stored credentials are read
  // the optional "timeLeft" parameter can be used to set the required minimum left expiration time (defaults to `0`)
  public static checkTokenValidity(credentials?: IOAuth2Credentials, timeLeft = 0): boolean {
    if (!credentials) {
      credentials = this.parseStoredOAuth2Credentials();
    }

    if (!credentials) {
      return false;
    }

    return this.getTokenExpiration(credentials) > timeLeft;
  }

  // returns the stored OAuth2 access token;
  // returns `undefined` if it is not present or expired
  public static getAccessToken(): string | undefined {
    const parsed = this.parseStoredOAuth2Credentials();

    if (!parsed || !this.checkTokenValidity(parsed)) {
      return undefined;
    }

    return parsed.accessToken;
  }

  // returns the stored OAuth2 credentials or `undefined` if they are not present
  public static getOAuth2Credentials(): IOAuth2Credentials | undefined {
    return this.parseStoredOAuth2Credentials();
  }

  // returns an object containing the OAuth2 authorization header usable
  // to extend the HTTP request headers
  //   example: `{ Authorization: 'Bearer <access token>' }`
  // returns `{}` if no token is present, or it is expired
  public static getAuthorizationHeader(): IAuthorizationHeader {
    const token = this.getAccessToken();

    if (!token) {
      return {};
    }

    return {
      [AUTHORIZATION_HEADER_NAME]: [
        AUTHORIZATION_HEADER_VALUE_PREFIX,
        token,
      ].join(' '),
    };
  }

  // sends a OAuth2 API login request, and stores the OAuth2 tokens on success;
  // empties the stored OAuth2 tokens on errors
  public static login(credentials: IOAuth2LoginParams): Promise<IOAuth2Credentials> {
    return new Promise((resolve, reject) => {
      api.oauth2.login(credentials)
        .then((response: IOAuth2Credentials) => {
          if (response) {
            this.storeOAuth2Credentials(response);
          }
          return resolve(response);
        })
        .catch((error: IErrorResponse) => {
          this.removeStoredOAuth2Credentials();
          return reject(error);
        });
    });
  }

  // sends a OAuth2 API refresh request, and stores the OAuth2 tokens on success
  // (possibly overwriting existing tokens); empties the stored OAuth2 tokens on errors
  public static refresh(usedRefreshToken?: string): Promise<IOAuth2Credentials> {
    return new Promise((resolve, reject) => {
      const refreshToken = usedRefreshToken || this.getRefreshToken();

      if (!refreshToken) {
        this.removeStoredOAuth2Credentials();
        return reject('no refresh token present');
      }

      api.oauth2.refresh({
        refreshToken,
      })
        .then((response: IOAuth2Credentials) => {
          if (response) {
            this.storeOAuth2Credentials(response);
          }
          return resolve(response);
        })
        .catch((error: IErrorResponse) => {
          this.removeStoredOAuth2Credentials();
          return reject(error);
        });
    });
  }

  // sends a OAuth2 API logout request, and empties the stored OAuth2 tokens afterwards
  public static logout(): Promise<{}> {
    return new Promise((resolve, reject) => {
      const token = this.getAccessToken();

      if (!token) {
        this.removeStoredOAuth2Credentials();
        // no token exist, agree that we are logged out
        return resolve();
      }

      api.oauth2.logout({
        token,
      })
        .then((response: {}) => {
          this.removeStoredOAuth2Credentials();
          return resolve(response);
        })
        .catch((error: IErrorResponse) => {
          this.removeStoredOAuth2Credentials();
          return reject(error);
        });
    });
  }

  public static storeOAuth2Credentials(credentials: IOAuth2Credentials): void {
    storageWrapper.setItem(STORAGE_KEY, JSON.stringify(credentials));
    // Setting a flag-cookie that indicates, that there is a location in the local storage
    // is important for our backend to know to redirect a user to the landing page or not.
    // When a user is authenticated, it is also an indication that he needs no landing page.
    // This means, that we assume that a location is about to come from the backend, and be set.
    // But this doesn't promise that in the future, any logged in user with location will crate a local storage.
    // DO NOT REMOVE TIS LINE. see !4028
    storageWrapper.setCookieItem(SHOULD_NOT_SEE_LANDING_PAGE, true);
  }

  public static identitiesContainFacebook(identities: IIdentity[]): boolean {
    return this.identitiesContainProvider(identities, IDENTITY_PROVIDER_FACEBOOK);
  }

  public static identitiesContainApple(identities: IIdentity[]): boolean {
    return this.identitiesContainProvider(identities, IDENTITY_PROVIDER_APPLE);
  }

  private static identitiesContainProvider(identities: IIdentity[], provider: IdentityProviderType): boolean {
    let isProviderIdentity = false;
    identities.forEach(identity => {
      if (identity.provider === provider) isProviderIdentity = true;
    });
    return isProviderIdentity;
  }

  private static nowInSeconds(): number {
    return Math.floor(new Date().getTime() / 1000);
  }

  private static getStoredOAuth2Credentials(): string | null {
    return storageWrapper.getItem(STORAGE_KEY);
  }

  private static parseStoredOAuth2Credentials(): IOAuth2Credentials | undefined {
    const credentials = this.getStoredOAuth2Credentials();

    if (!credentials) {
      return undefined;
    }

    let credentialsObject: IOAuth2Credentials | undefined;
    try {
      credentialsObject = JSON.parse(credentials);
    } catch (error) {
      this.removeStoredOAuth2Credentials();
      const message = 'broken credentials object';
      reportError(message, { error });
    }
    return credentialsObject;
  }

  private static removeStoredOAuth2Credentials(): void {
    storageWrapper.removeItem(STORAGE_KEY);
  }

  private static getRefreshToken(): string | undefined {
    const parsed = this.parseStoredOAuth2Credentials();

    if (!parsed) {
      return undefined;
    }

    return parsed.refreshToken;
  }
}
