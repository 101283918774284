import { IWindowJsEnv } from 'src/interfaces/';

import { reportError } from 'src/utils/reporting/report-errors';

export function getToggle<T>(key: string, fallback: T): T {
  let toggle;
  try {
    toggle = (window as unknown as IWindowJsEnv).js_env.toggles[key];
    if (!toggle.active) {
      return fallback;
    }
    try {
      return JSON.parse(toggle.config_string);
    } catch (error) {
      reportError('Unable to parse toggle config-string', {
        error,
        key,
        toggle,
      });
      throw error;
    }
  } catch (e) {
    return fallback;
  }
}
