import React from 'react';

// classes
import ListWrapper, { IProps as IListWrapperIProps } from './list_wrapper';

// helpers
import { useNextPageTracking } from 'src/actions/feed/feed_hooks';
import { useProfilePaginationInfo } from 'src/reducers/profiles_list/profiles_list_hooks';

interface ProfileListWrapperIProps extends IListWrapperIProps {
  isSecondary?: boolean;
}

const ProfileListWrapper: React.FC<ProfileListWrapperIProps> = ({
  children,
  getNextPage,
  isSecondary,
  initialLoad,
}) => {
  const { currentPage } = useProfilePaginationInfo(!!isSecondary);
  useNextPageTracking(currentPage);

  return (
    <ListWrapper
      getNextPage={getNextPage}
      initialLoad={initialLoad}
    >
      {children}
    </ListWrapper>
  );
};

export default ProfileListWrapper;
