// libs
import * as React from 'react';

// interfaces / constants
import { IIngredient, ILinkPreview } from 'src/interfaces/post_ingredients';
import { POST_DISPLAY_MODE_DETAIL } from 'src/interfaces/posts';

// components
import LinkPreview from 'src/components/link_preview/link_preview';

export interface IPropsData extends ILinkPreview {
  onRemove?: () => void;
  showRemoveIcon?: boolean;
}

export type IProps = IIngredient<IPropsData>;

const IngredientLinkPreview: React.FC<IProps> = ({
  data: {
    author = '',
    description = '',
    displayUrl = '',
    imageUrl,
    onRemove,
    showRemoveIcon = false,
    title,
    url = '',
  },
  postDetailsURL = '',
  displayMode,
}) => {
  const isDetailsPage = displayMode === POST_DISPLAY_MODE_DETAIL;

  return (
    <LinkPreview
      author={author}
      description={description}
      displayUrl={displayUrl}
      imageUrl={imageUrl}
      large={isDetailsPage}
      onRemove={onRemove}
      showRemoveIcon={showRemoveIcon}
      target={isDetailsPage ? '_blank' : '_self'}
      title={title}
      url={isDetailsPage ? url : postDetailsURL}
    />
  );
};
export default IngredientLinkPreview;
