// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IngredientType } from 'src/interfaces/post_ingredients';
import { POST_DISPLAY_MODE_COMPACT } from 'src/interfaces/posts';

// classes
import NavigationItem from 'src/components/navigation_item/navigation_item';
import Ingredients from 'src/components/post/shared/ingredients/ingredients';

// helpers
import { getDateTimeFormat } from 'src/components/post/utils';

import './post_compact.scss';

const cls = CLASS_PREFIX + 'post__compact';

interface IProps {
  ingredients: IngredientType[];
  publishedAt: string;
  detailUrl: string;
  id: string;
}

const PostCompact: React.FC<IProps> = ({
  publishedAt,
  ingredients,
  detailUrl,
  id,
}) => {
  return (
    <article className={cls}>
      <NavigationItem target='_self' url={detailUrl}>
        <div className={cls + '__tile'}>
          <Ingredients ingredients={ingredients}
            displayMode={POST_DISPLAY_MODE_COMPACT}
            postId={id}
          />
          <div className={cls + '__body'}>
            <div className={cls + '__where-and-when'}>
              <time dateTime={publishedAt}>
                {getDateTimeFormat(publishedAt)}
              </time>
            </div>
          </div>
        </div>
      </NavigationItem>
    </article>
  );
};

export default PostCompact;
