// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import Login from 'src/components/login_page/login_page';
import MetaTags from 'src/components/meta_tags/meta_tags';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import LayoutNoHeader from 'src/layout/layout_no_header';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { TWO_COLUMNS } from 'src/constants/layout';
import { LOGIN_TITLE } from 'src/constants/meta_tags';

// helpers
import { textResources } from 'src/lang/de';
import { usePageTracking } from 'src/pages/hooks/hooks';

const LoginPage: React.FC<RouteComponentProps> = () => {
  usePageTracking();

  return (
    <>
      <MetaTags
        title={LOGIN_TITLE}
      />
      <LayoutNoHeader contentWidth={TWO_COLUMNS}>
        <Login />
      </LayoutNoHeader>
    </>
  );
};

export const LoginPageWithHeader = () => {
  usePageTracking();

  return (
    <LayoutNoMap
      contentWidth={TWO_COLUMNS}
      pageTitle={<StandardPageTitle text={textResources.pageTitles.signIn} />}
    >
      <Login />
    </LayoutNoMap>
  );
};

export default LoginPage;
