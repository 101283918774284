import { reportError } from 'src/utils/reporting/report-errors';

export {
  Decoder,
  decode,
};

type Decoder<ResponseInterface, ExpectedResults> = (json: ResponseInterface) => ExpectedResults;

function decode<ResponseInterface, ExpectedResult>(
  decoder: Decoder<ResponseInterface, ExpectedResult>,
  json: ResponseInterface,
) {
  try {
    return decoder(json);
  } catch (e) {
    const error = new Error(`Frontend and backend have different contracts regarding data structures!`);
    reportError(error, { decoder: decoder.toString(), error: e, response: json }, { component: 'server' });
    throw error;
  }
}
