// libs
import * as React from 'react';

// interfaces / constants
import Share, { IShareCompProps, IShareProps } from 'src/components/share/lib/share';
import { CLASS_PREFIX } from 'src/constants/';
import {
  ColorType,
} from 'src/utils/color';

// components
import RoundIcon from 'src/components/forms/round_icon/round_icon';
import ShareSegmentConfigurable from 'src/components/share/lib/share_segment_configurable';

import './share_segment_small.scss';

const cls = CLASS_PREFIX + 'share-segment-small';

interface IShareElementProps {
  caption: string;
  iconName: string;
  iconColor: ColorType;
}

const ShareElementSmall: React.FC<IShareElementProps> = ({ iconName, iconColor }) => (
  <RoundIcon
    name={iconName}
    backgroundSize={24}
    size={10}
    backgroundColor={iconColor}
  />
);

const ShareSegmentSmallComponent: React.FC<IShareCompProps> = (props) => (
  <ShareSegmentConfigurable
    {...props}
    component={ShareElementSmall}
    componentCls={cls + '__icon'}
    className={cls + '__icons'}
  />
);

const ShareSegmentSmall = (props: IShareProps) => (
  <Share
    {...props}
    component={ShareSegmentSmallComponent}
  />
);

export default ShareSegmentSmall;
