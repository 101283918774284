// interfaces
import { FetchFeedThunkAction } from 'src/actions/feed/feed_interfaces';
// constants
import { FEED_TYPE_FOLLOWEEES_RECENT } from 'src/constants/feed';
// actions
import { fetchFeed } from 'src/actions/feed/feed';
// helpers
import api, { IPaginationParams } from 'src/api/';

const fetchFolloweeRecentFeed = fetchFeed<IPaginationParams>(
  FEED_TYPE_FOLLOWEEES_RECENT,
  api.follow.recentFeed,
);

export const fetchFolloweesRecentFeedPage = (page = 1): FetchFeedThunkAction => {
  return async(dispatch): Promise<void> => {
    dispatch(fetchFolloweeRecentFeed({
      page,
    }));
  };
};
