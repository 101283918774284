// libs
import React from 'react';
import { useParams } from 'react-router';

// classes / components
import LoadingSpinnerWrapper from 'src/components/loading_spinner/loading_spinner_wrapper';
import ProfilesList from 'src/components/profiles_list/profiles_list';
import FeedWrapper from 'src/containers/smart_components/list_wrapper/feed_wrapper';

// interfaces / constants
import { FEED_TYPE_EVENT_PARTICIPATING } from 'src/constants/feed';

// helpers
import {
  useGetParticipantsNextPage,
} from 'src/actions/event_participation/event_participation_hooks';
import { useCleanProfilesListEffect } from 'src/actions/profiles_list/profiles_list_hooks';
import {
  useSelectProfilesIds,
  useProfilesList,
  useProfilesFromIds,
} from 'src/reducers/profiles_list/profiles_list_hooks';

const EventParticipantsContainer: React.FC = () => {
  const ids = useSelectProfilesIds();
  const { id: eventId } = useParams<{ id: string }>();
  const { isLoading } = useProfilesList();
  const participants = useProfilesFromIds(ids);

  const getParticipantsNextPage = useGetParticipantsNextPage(eventId);

  useCleanProfilesListEffect();

  return (
    <LoadingSpinnerWrapper isLoading={isLoading}>
      <FeedWrapper
        getNextPage={getParticipantsNextPage}
        feedType={FEED_TYPE_EVENT_PARTICIPATING}
      >
        <ProfilesList
          profiles={participants}
        />
      </FeedWrapper>
    </LoadingSpinnerWrapper>
  );
};

export default EventParticipantsContainer;
