import { IProps as ISnackBarProps } from 'src/components/snack_bar/snack_bar';
import {
  CTA_TYPE_COMPLETE_PROFILE,
  CTA_TYPE_CONFIRM_EMAIL,
  CTA_TYPE_VERIFY,
  CTA_TYPE_NEW_USER_INFO,
} from 'src/constants/cta';
import { IBoundingBox, ILocationShape, IPosition } from 'src/interfaces/location';
import { IMarker } from 'src/interfaces/marker';

export const POPUP_TYPE_MAP = 'map';
export const POPUP_TYPE_NONE = 'none';

export type PopupMenu =
  | typeof POPUP_TYPE_MAP
  | typeof POPUP_TYPE_NONE;

const MODAL_TYPE_VERIFY = 'verify';
export const MODAL_TYPE_NONE = 'none';
export const MODAL_TYPE_FACEBOOK_REGISTRATION_EMAIL = 'facebookRegistrationEmail';
export const MODAL_TYPE_FORGOT_PASSWORD = 'forgotPassword';
export const MODAL_TYPE_LOGIN = 'login';
export const MODAL_TYPE_REGISTRATION = 'registration';
export const MODAL_TYPE_APP_REGISTRATION = 'appRegistration';
export const MODAL_TYPE_RESET_PASSWORD = 'resetPassword';
export const INITIAL_SNACK_BAR_STATE = {
  id: 0,
  message: '',
  showButton: false,
  showClose: false,
};

export type ModalType =
  typeof MODAL_TYPE_NONE
  | typeof MODAL_TYPE_FACEBOOK_REGISTRATION_EMAIL
  | typeof MODAL_TYPE_FORGOT_PASSWORD
  | typeof MODAL_TYPE_LOGIN
  | typeof MODAL_TYPE_REGISTRATION
  | typeof MODAL_TYPE_APP_REGISTRATION
  | typeof MODAL_TYPE_RESET_PASSWORD
  | typeof MODAL_TYPE_VERIFY
  | typeof CTA_TYPE_CONFIRM_EMAIL
  | typeof CTA_TYPE_COMPLETE_PROFILE
  | typeof CTA_TYPE_VERIFY
  | typeof CTA_TYPE_NEW_USER_INFO;

export const SET_OUTDATED_CLIENT_API_REVISION = 'set_outdated_client_api_revision';

export const possibleAuthModals = [
  MODAL_TYPE_FACEBOOK_REGISTRATION_EMAIL,
  MODAL_TYPE_FORGOT_PASSWORD,
  MODAL_TYPE_LOGIN,
  MODAL_TYPE_REGISTRATION,
  MODAL_TYPE_RESET_PASSWORD,
];

export type AuthModalType =
  typeof MODAL_TYPE_FACEBOOK_REGISTRATION_EMAIL
  | typeof MODAL_TYPE_FORGOT_PASSWORD
  | typeof MODAL_TYPE_LOGIN
  | typeof MODAL_TYPE_REGISTRATION
  | typeof MODAL_TYPE_RESET_PASSWORD
  | typeof MODAL_TYPE_APP_REGISTRATION;

export const possibleCTA = [
  CTA_TYPE_CONFIRM_EMAIL,
  CTA_TYPE_COMPLETE_PROFILE,
  CTA_TYPE_VERIFY,
  CTA_TYPE_NEW_USER_INFO,
];

export interface IAppState {
  activePopupMenu: PopupMenu;
  boundingBox?: IBoundingBox;
  modal: ModalType;
  locationShape?: ILocationShape;
  locationShapeIds?: string[];
  position?: IPosition;
  markers: IMarker[];
  isCacheOutdated: boolean;
  navSource?: string;
  chatUsersCount?: number;
  chatMessagesCount?: number;
  snackBar: Partial<ISnackBarProps>;
}
