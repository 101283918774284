// lib
import React from 'react';

// components
import PageTitle from 'src/components/header/main/page_title/page_title';

// helpers
import { textResources } from 'src/lang/de';
import { useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectActiveFilter } from 'src/reducers/filters/filters_hooks';
import { useSelectIsUserHome } from 'src/reducers/user/user_hooks';

const PageTitleEventCategory: React.FC = () => {
  const isHome = useSelectIsUserHome();
  const appLocationShape = useGetAppLocationShape();
  const activeFilter = useSelectActiveFilter();

  const category = activeFilter?.label;
  const { events, eventfeed, for_ } = textResources.pageTitles;

  // There should always be a category in this title, this is just a fallback in case of an undefined category
  const titleText = category
    ? events + category + for_
    : eventfeed;

  return (
    <PageTitle
      isHome={isHome}
      text={titleText}
      location={appLocationShape?.name}
      showLocation={true}
    />
  );
};

export default PageTitleEventCategory;
