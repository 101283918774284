// libs
import * as React from 'react';

// components
import Feedback from 'src/components/feedback/feedback';
import Icon from 'src/components/forms/icon/icon';
import LoggedOutMenu from 'src/components/header/main/logged_out_menu/logged_out_menu';
import MapToggler from 'src/containers/map_toggler';
import MainMenu from 'src/containers/smart_components/main_menu/main_menu';
import ChatToggler from 'src/containers/topic_chat/chat_toggler';

// interfaces & constants
import { CLASS_PREFIX } from 'src/constants';

// helpers
import { textResources } from 'src/lang/de';
import './header_main.scss';

const cls: string = CLASS_PREFIX + 'header-main';

interface IState {
  showFeedBackModal: boolean;
}

interface IProps {
  enableMapToggler: boolean;
  enableChatToggler?: boolean;
  loggedInUserEmail?: string;
  loggedIn: boolean;
  pageTitle?: JSX.Element;
  showLogInModal: () => void;
  showRegistrationModal?: () => void;
}

class HeaderMain extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showFeedBackModal: false,
    };
    this.showFeedback = this.showFeedback.bind(this);
    this.hideFeedback = this.hideFeedback.bind(this);
  }

  public render() {
    const {
      enableChatToggler,
      enableMapToggler,
      loggedIn,
      loggedInUserEmail,
      pageTitle,
      showLogInModal,
      showRegistrationModal,
    } = this.props;
    return (
      <>
        {this.state.showFeedBackModal &&
          <Feedback loggedInUserEmail={loggedInUserEmail} close={this.hideFeedback} />
        }
        <div className={cls}>
          {loggedIn
            ? <MainMenu popupPosition={'left'} />
            : <LoggedOutMenu onLogIn={showLogInModal} onRegistration={showRegistrationModal}/>
          }
          <div className={cls + '__title'}>{pageTitle}</div>
          <div className={cls + '__right-wrapper'}>
            {enableChatToggler && <ChatToggler />}
            <div className={cls + '__toggler'}>
              {enableMapToggler && <MapToggler />}
            </div>
            <div>
              <div className={cls + '__feedback'}>
                <button className={cls + '__feedback-button'} onClick={this.showFeedback}>
                  <Icon size={24} name='bullhorn' description={textResources.shared.feedback}/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  private showFeedback() {
    this.setState({
      showFeedBackModal: true,
    });
  }

  private hideFeedback() {
    this.setState({
      showFeedBackModal: false,
    });
  }
}

export default HeaderMain;
