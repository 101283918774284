// libs
import { connect } from 'react-redux';

// interfaces / constants
import { IRootState } from 'src/reducers/interface';

// components
import ChatToggler from 'src/components/header/main/toggler/chat_toggler/chat_toggler';

// helpers

interface IStateToProps {
  onlineUsersCount: number | undefined;
}

const mapStateToProps = (state: IRootState): IStateToProps => ({
  onlineUsersCount: state.appState.chatUsersCount,
});

export type IChatTogglerProps = IStateToProps;

export default connect<IStateToProps>(
  mapStateToProps,
)(ChatToggler);
