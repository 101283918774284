// libs
import React, { useState, useEffect } from 'react';

// interfaces / constants
import {
  ctaCompleteProfile,
  ctaConfirmEmail,
  ctaVerifyProfile,
  renderPremiumRegistration,
  renderFencedRegistration,
  ICTAContent,
} from 'src/components/cta/cta_intermediate_options';
import { ONBOARDING_POST_URL_KEY } from 'src/constants/local_storage';
import { NO_USER_PROFILE_TYPE } from 'src/constants/profile';
import { FRONTEND_SETTINGS_PATH } from 'src/constants/urls';
import {
  NO_PROFILE,
  PROFILE_STEP_PROFILE_COMPLETE,
  PROFILE_STEP_EMAIL_CONFIRMED,
  PROFILE_STEP_VERIFIED,
} from 'src/constants/user';
import { IPremiumContentBlockerProps } from 'src/containers/premium_content';

// classes / components
import MessageBox from 'src/components/message_box/message_box';
import SnackBar from 'src/components/snack_bar/snack_bar';
import { sendEmailAgain } from 'src/containers/smart_components/content_blocker_info/content_blocker_info';

// helpers
import { textResources } from 'src/lang/de';
import { useSelectProfileStatus } from 'src/reducers/user/user_hooks';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { setItem } from 'src/utils/storage_wrapper/storage_wrapper';

// styles
import './premium_content.scss';

// CAUTION: For our SEO we use the 'lp_premium_content'-class to mark our paywall.
// If you change this class, please change the constant in /constants/meta_tag also.
const cls = createBemBlock('premium_content');

const PremiumContentBlocker: React.FC<IPremiumContentBlockerProps> = ({
  dispatchShowLoginModal,
  dispatchShowRegistrationModal,
  loggedInProfileType,
  premiumContent,
  postUrl,
  fencedContent,
  history,
  userEmail,
}) => {
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const profileStatus = useSelectProfileStatus();

  const closeSnackbar = () => {
    setSnackbarMessage('');
  };

  useEffect(() => {
    if (premiumContent && loggedInProfileType === NO_USER_PROFILE_TYPE) {
      setItem(ONBOARDING_POST_URL_KEY, postUrl);
    }
  }, [loggedInProfileType, postUrl, premiumContent]);

  const getOptions = (): ICTAContent | null => {
    switch (profileStatus) {
      case NO_PROFILE: {
        if (premiumContent) {
          return renderPremiumRegistration(dispatchShowRegistrationModal, dispatchShowLoginModal);
        }
        return renderFencedRegistration(dispatchShowRegistrationModal, dispatchShowLoginModal);
      }
      case PROFILE_STEP_PROFILE_COMPLETE:
        return ctaCompleteProfile(() => history.push(FRONTEND_SETTINGS_PATH));
      case PROFILE_STEP_EMAIL_CONFIRMED:
        return ctaConfirmEmail(() => sendEmailAgain(setSnackbarMessage), userEmail);
      case PROFILE_STEP_VERIFIED:
        return ctaVerifyProfile(() => history.push(FRONTEND_SETTINGS_PATH));
      default:
        return null;
    };
  };

  const options = getOptions();
  return (
    <>
    {(premiumContent || fencedContent) && options &&
    <div className={cls()}>
      <MessageBox
        title={options.title || textResources.shared.errorUnknown}
        bodyTitle={options.bodyTitle}
        highlighted={false}
        buttons={options.buttons}
      >
        <p className={cls('body-content')}>
          {options.body}
        </p>
        {options.children}
      </MessageBox>
    </div>}
      {snackbarMessage &&
      <SnackBar message={snackbarMessage} onClose={closeSnackbar} />}
    </>
  );
};

export default PremiumContentBlocker;
