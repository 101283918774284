// libs
import { History } from 'history';
import * as React from 'react';

// interfaces / constants
import { THUMBNAIL_SIZE_TYPE_MEDIUM } from 'src/components/thumbnail/thumbnail';
import { CLASS_PREFIX } from 'src/constants';
import { ITrackingType } from 'src/high_order_components/with_tracker_props';
import { IPost, POST_DISPLAY_MODE_DETAIL } from 'src/interfaces/posts';
import { PostDetailsObjType } from 'src/utils/reporting/events_tracking/events_tracking';

// classes
import NavigationItem from 'src/components/navigation_item/navigation_item';
import Author from 'src/components/post/shared/author/author';
import Ingredients from 'src/components/post/shared/ingredients/ingredients';
import ShareSegment from 'src/components/share/share_segment/share_segment';
import StaticPages from 'src/components/static_pages/static_pages';
import CommentsContainer from 'src/containers/comments';
import PremiumContent from 'src/containers/premium_content';
import { PostDetailsReactionsContainer } from 'src/containers/reactions';
import RelatedContent from 'src/containers/related_content';
import FollowButton from 'src/containers/smart_components/follow_button/follow_button';

// helpers
import ShapeSponsorsInfo from 'src/containers/smart_components/shape_sponsors/shape_sponsors_info/shape_sponsors_info';
import { textResources } from 'src/lang/de';
import ReactionOverview from '../shared/reactions/reaction_overview/reaction_overview';
import './post_detail.scss';

interface IPostDetails {
  addViewCount: () => void;
  post: IPost;
  history: History;
  tracking?: ITrackingType<PostDetailsObjType>;
  canReadPremiumContent: boolean;
}

const cls = CLASS_PREFIX + 'post__detail';

export default class PostDetails extends React.Component<IPostDetails, {}> {
  public constructor(props: IPostDetails) {
    super(props);
    // call add view when this component initializes
    props.addViewCount();
  }

  public render() {
    const { post, tracking, canReadPremiumContent } = this.props;
    const {
      authorId,
      comments,
      externalUrl,
      id,
      ingredientIds,
      ingredients,
      meta,
      publishedAt,
      urls,
    } = post;
    const { fenced, premiumContent } = meta;
    const { canBookmark, canComment, canShare } = meta.permissions;
    const { similar } = meta.urls;

    const postIngredients = ingredientIds.map((ingredientId) => ingredients[ingredientId]!);
    const postType = this.props.post.meta.contentItemType;
    const label = textResources.postDetail;
    const blurType = fenced ? 'full' : !canReadPremiumContent && premiumContent ? 'gradient' : undefined;

    return (
      <div className={cls}>
        <article className={cls + '__tile'}>
          <Ingredients
            ingredients={postIngredients}
            displayMode={POST_DISPLAY_MODE_DETAIL}
            blurred={blurType}
            postId={id}
            postType={postType}
          />
          <PremiumContent postUrl={urls.frontend} fencedContent={fenced} premiumContent={premiumContent} />
          {externalUrl &&
          <div className={cls + '__source'}>
            <span className={cls + '__source__label'}>
              {postType === 'event'
                ? label.tickets
                : label.source}
            </span>
            <NavigationItem
              url={externalUrl}
              target='_blank'
              className={cls + '__source__link'}>
              {externalUrl}
            </NavigationItem>
          </div>}
          <div className={cls + '__tile__footer'}>
            <div className={cls + '__author-more-section'}>
              <Author
                displayMode={POST_DISPLAY_MODE_DETAIL}
                publishedAt={publishedAt}
                size={THUMBNAIL_SIZE_TYPE_MEDIUM}
                id={authorId}
              />
              <FollowButton followableId={authorId} />
            </div>
            <ReactionOverview
              postId={id}
              displayMode={POST_DISPLAY_MODE_DETAIL}
              basePath={post.urls.frontend}
              disabled={!canBookmark}
            />
            <PostDetailsReactionsContainer id={id} canBookmark={canBookmark}/>
            {canShare &&
              <div className={cls + '__share-wrapper'}>
                <div className={cls + '__share'}>
                  <ShareSegment tracking={tracking} utmSource='post' url={urls.frontend} />
                </div>
              </div>
            }
          </div>
        </article>
        <div className={cls + '__footer'}>
          {!fenced && <CommentsContainer
            id={id}
            disabled={!canComment}
            commentable={post.commentable !== false}
            {...comments}
          />}
        </div>
        <div className={`${cls + '__shape-sponsors-wrapper'}`}>
          <ShapeSponsorsInfo key='shape_sponsors_info' placements={{ detailPage: true }} />
        </div>
        {similar && <div className={cls + '__related-content-wrapper'}>
          <RelatedContent path={similar} />
        </div>}
        <div className={cls + '__static-pages'}>
          <StaticPages />
        </div>
      </div>
    );
  }
}
