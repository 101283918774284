// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { THUMBNAIL_SIZE_TYPE_MEDIUM } from 'src/components/thumbnail/thumbnail';
import { IWindowJsEnv } from 'src/interfaces/';

// classes / components
import Avatar from 'src/components/avatar/avatar';

// helpers
import { textResources } from 'src/lang/de';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import './notification.scss';

const cls = createBemBlock('notification');
const clsAvatarWrapper = cls(`avatar-wrapper`);
const clsAvatarOverlay = cls(`avatar-overlay`);
const clsContent = cls(`content`);
const clsFooter = cls(`footer`);
const clsInitiator = cls(`initiator`);
const clsSeparator = cls(`separator`);
export const clsText = cls(`text`);
export const clsHighlightedText = cls(`text`, 'highlighted');

const labels = textResources.notification;

const _window = window as unknown as IWindowJsEnv;

interface IProps {
  avatarImage?: string;
  badgeImageNotRead?: string;
  badgeImageRead?: string;
  footer: string;
  initiatorName?: string;
  isRead: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const Notification: React.FC<IProps> = ({
  avatarImage = _window.js_env.imagePaths.defaultAvatar,
  badgeImageNotRead,
  badgeImageRead,
  children,
  footer,
  initiatorName = labels.defaultInitiatorName,
  isRead,
  onClick,
}) => {
  const classes = {
    [cls()]: true,
    [cls('', 'unread')]: !isRead,
  };

  const badgeImage = isRead ? badgeImageRead : badgeImageNotRead;

  return (
    <li className={classNames(classes)} onClick={onClick}>
      <div className={clsSeparator}>
        <div className={clsAvatarWrapper}>
          <Avatar
            image={avatarImage}
            size={THUMBNAIL_SIZE_TYPE_MEDIUM}
          >
            {badgeImage && (
              <div
                className={clsAvatarOverlay}
                style={{ backgroundImage: `url(${badgeImage})` }}
              />
            )}
          </Avatar>
        </div>
        <div className={clsContent}>
          <div>
            {initiatorName && <span className={clsInitiator}>{initiatorName}</span>}
            {children}
          </div>
          <div className={clsFooter}>
            {footer}
          </div>
        </div>
      </div>
    </li>
  );
};

export default Notification;
