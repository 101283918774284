import React, { FunctionComponent } from 'react';
import { FeatureFlag } from 'src/components/feature_flag/feature_flag';
import { getConfigValue } from 'src/utils/config/config';
import EventLocation, { IEventLocationProps } from './event_location';

export const EventLocationContainer: FunctionComponent<IEventLocationProps> = props => {
  let showLocation = getConfigValue<boolean>('featureFlags.map.enabled');
  if (getConfigValue('featureFlags.location.defaultAddress')) {
    showLocation = false;
  }
  return <FeatureFlag active={showLocation}>
    <EventLocation {...props} />
  </FeatureFlag>;
};
