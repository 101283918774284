// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { FEED_POST_TYPE_EVENT } from 'src/constants/feed';
import { MORE_OPTIONS, IMoreOption } from 'src/constants/more_button';
import { WRITE_PATH } from 'src/constants/route_constants';
import { UPDATE_POST } from 'src/constants/urls';
import { GRAYLIST_PROFILE_DEFAULT_EXPIRATION } from 'src/constants/user';
import { IProps } from 'src/containers/post/more_button';
import { IFlagCreateData } from 'src/interfaces/flag';
import { COLOR_TYPE_SECONDARY6 } from 'src/utils/color';

// classes
import CreateFlagWizard from 'src/components/flag/create/create_flag_wizard';
import MoreMenu from 'src/components/header/subheaders/shared_contents/more_menu/more_menu';
import MenuItemSegment from 'src/components/item_segment/layouts/menu/menu';
import { MSGBOX_BUTTON_TYPE_DANGER, MSGBOX_BUTTON_TYPE_CANCEL } from 'src/components/message_box/message_box';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';
import Modal from 'src/components/modal/modal';
import ShareItemList from 'src/components/share/share_item_list/share_item_list';
import SnackBar from 'src/components/snack_bar/snack_bar';

// helpers
import { textResources } from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import DateHelper from 'src/utils/date_helper/date';
import { UrlUtils } from 'src/utils/url/url';

import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';
import PostCreate from 'src/containers/post/create';
import PostUpdate from 'src/containers/post/update';
import './more_button.scss';

const cls = CLASS_PREFIX + 'more-button';
const labels = textResources.item;

interface IState {
  deleteModalOpen: boolean;
  flagModalOpen: boolean;
  renderPostUpdate: boolean;
  renderPostDuplicate: boolean;
  shownGraylistMessage: string;
  showShareList: boolean;
}

export default class MoreButton extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.createFlag = this.createFlag.bind(this);
    this.clickDelete = this.clickDelete.bind(this);
    this.closeMessageBox = this.closeMessageBox.bind(this);
    this.hideFlagModal = this.hideFlagModal.bind(this);
    this.hideGraylistMessage = this.hideGraylistMessage.bind(this);
    this.hidePostUpdate = this.hidePostUpdate.bind(this);
    this.hidePostDuplicate = this.hidePostDuplicate.bind(this);
    this.showFlagModal = this.showFlagModal.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.onShare = this.onShare.bind(this);
    this.onGrayList = this.onGrayList.bind(this);
    this.showDeleteModal = this.showDeleteModal.bind(this);
    this.closeShareList = this.closeShareList.bind(this);
    this.dupldateEvent = this.dupldateEvent.bind(this);

    this.state = {
      deleteModalOpen: false,
      flagModalOpen: false,
      renderPostDuplicate: false,
      renderPostUpdate: !!props.postUpdateOpen,
      showShareList: false,
      shownGraylistMessage: '',
    };
  }

  public render() {
    const { deleteModalOpen, flagModalOpen, renderPostUpdate, renderPostDuplicate,
      shownGraylistMessage, showShareList } = this.state;
    const { post, history, match, location, postAuthor, userPermission, tracking } = this.props;
    const { advertised, contentItemType, permissions } = post.meta;
    const { canDelete, canFlag, canUpdate, canShare } = permissions;
    const { createEvents } = userPermission || {};
    const { canMessage, canGraylist } = postAuthor?.permissions || {};
    const isEventPost = contentItemType === FEED_POST_TYPE_EVENT;

    const options: IMoreOption[] = [];
    canShare && options.push(MORE_OPTIONS.SHARE);

    canFlag && options.push(MORE_OPTIONS.FLAG);
    canUpdate && options.push(MORE_OPTIONS.UPDATE);
    canMessage && options.push(MORE_OPTIONS.MESSAGE);
    canGraylist && options.push(MORE_OPTIONS.GRAYLIST);
    // check permission. Only user who created post can duplicate it.
    createEvents && canUpdate && isEventPost && options.push(MORE_OPTIONS.DUPLICATE_EVENT);
    canDelete && options.push(advertised ? MORE_OPTIONS.DELETE_CAMPAIGN : MORE_OPTIONS.DELETE);

    return (
      <div className={cls}>
        <MoreMenu
          onHide={this.closeShareList}
        >
          {showShareList &&
            <ShareItemList url={this.props.post.urls.frontend} utmSource={'post'} tracking={tracking} />
          }
          {options.map((option, index) =>
            <MenuItemSegment
              key={index}
              label={option.text}
              icon={{ name: option.icon }}
              navigation={{ onClick: this.handleMoreActionClick(option) }}
              hidden={showShareList}
              disabled={option.disabled}
              tooltip={option.tooltip}
            />
          )}
        </MoreMenu>
        {
          flagModalOpen && <Modal
            onClose={this.hideFlagModal}
          >
            <CreateFlagWizard
              onEnd={this.hideFlagModal}
              history={history}
              basePath={match.url}
              onSubmit={this.createFlag}
              search={location.search}
            />
          </Modal>
        }
        {renderPostUpdate
          ? <React.Suspense fallback={<LoadingSpinner shown />} >
            <PostUpdate
              post={post}
              onClose={this.hidePostUpdate}
            />
          </React.Suspense> : null
        }
        {renderPostDuplicate
          ? <React.Suspense fallback={<LoadingSpinner shown />} >
            <PostCreate
              open
              postType={FEED_POST_TYPE_EVENT}
              defaultIconType={COLOR_TYPE_SECONDARY6}
              showsBanner={false}
              featureBlocked={false}
              onClose={this.hidePostDuplicate}
            />
          </React.Suspense> : null
        }
        {deleteModalOpen && this.renderDeleteModal()}
        {shownGraylistMessage && (
          <SnackBar onClose={this.hideGraylistMessage}>
            {shownGraylistMessage}
          </SnackBar>
        )}
      </div>
    );
  }

  private renderDeleteModal() {
    return (
      <MessageBoxWithModal
        buttons={[
          {
            onClick: this.clickDelete,
            type: MSGBOX_BUTTON_TYPE_DANGER,
          },
          {
            onClick: this.closeMessageBox,
            type: MSGBOX_BUTTON_TYPE_CANCEL,
          },
        ]}
        onClose={this.closeMessageBox}
        title={labels.deletePost}
      >
        <p>{labels.deletePostConfirmationQuestion}</p>
      </MessageBoxWithModal>
    );
  }

  private clickDelete() {
    const { deletePost } = this.props;
    deletePost();
    this.closeMessageBox();
  }

  private createFlag(data: IFlagCreateData) {
    const { flagPost } = this.props;
    flagPost(data);
  }

  private handleMoreActionClick(selected: IMoreOption) {
    switch (selected.value) {
      case MORE_OPTIONS.DELETE.value:
        return this.showDeleteModal;
      case MORE_OPTIONS.DELETE_CAMPAIGN.value:
        return undefined;
      case MORE_OPTIONS.FLAG.value:
        return this.showFlagModal;
      case MORE_OPTIONS.UPDATE.value:
        return this.onUpdate;
      case MORE_OPTIONS.MESSAGE.value:
        return this.onMessage;
      case MORE_OPTIONS.GRAYLIST.value:
        return this.onGrayList;
      case MORE_OPTIONS.SHARE.value:
        return this.onShare;
      case MORE_OPTIONS.DUPLICATE_EVENT.value:
        return this.dupldateEvent;
      default:
        return undefined;
    }
  }

  private onUpdate() {
    const { history, post, match } = this.props;
    this.setState({ renderPostUpdate: true });
    history.push(post.urls.frontend + UPDATE_POST, { startPath: match.url });
  }

  private dupldateEvent() {
    const { history, match, location } = this.props;
    this.setState({ renderPostDuplicate: true });

    history.push(UrlUtils.getPostCreateURL(match.url, location.search, false, FEED_POST_TYPE_EVENT));
  }

  private closeMessageBox() {
    this.setState({ deleteModalOpen: false });
  }

  private hideFlagModal() {
    const { history, match, location } = this.props;
    this.setState({ flagModalOpen: false });
    history.replace(match.url, location.search);
  }

  private hidePostUpdate() {
    this.setState({ renderPostUpdate: false });
  }

  private hidePostDuplicate() {
    this.setState({ renderPostDuplicate: false });
  }

  private showFlagModal() {
    const { history, match, location } = this.props;
    this.setState({ flagModalOpen: true });
    history.push(match.url + WRITE_PATH, location.search);
  }

  private showDeleteModal() {
    this.setState({ deleteModalOpen: true });
  }

  private onMessage() {
    const { postAuthor } = this.props;
    if (postAuthor) {
      const { id, type } = postAuthor;
      const { history } = this.props;
      history.push(UrlUtils.sendMessageConversationPage(type, id));
    }
  }

  private onGrayList() {
    const { postAuthor, graylistProfile, reloadFeed, tracking } = this.props;
    const graylistUrl = postAuthor?.urls.graylist;
    if (postAuthor && graylistUrl) {
      const authorId = postAuthor?.id;
      if (tracking) {
        tracking.tracker(tracking.trackingObj.ACTIONS.GRAY_LIST, tracking.trackingObj.LABELS.FINISH);
      }
      graylistProfile(graylistUrl, authorId, DateHelper.addTime(GRAYLIST_PROFILE_DEFAULT_EXPIRATION))
        .then(reloadFeed)
        .catch();
      const graylistedFor = DateFormatter.durationToDays(GRAYLIST_PROFILE_DEFAULT_EXPIRATION);
      this.setState({
        shownGraylistMessage: textResources.graylist.successMessage(postAuthor.name, graylistedFor),
      });
    }
  }

  private hideGraylistMessage() {
    this.setState({ shownGraylistMessage: '' });
  }

  private onShare() {
    this.setState({ showShareList: true });
  }

  private closeShareList() {
    this.setState({ showShareList: false });
  }
}
