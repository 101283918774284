import * as React from 'react';

import {
  AGB,
  DefaultButton,
  EMailField,
  FacebookButton,
} from 'src/components/authentication/auth_form_elements';
import FieldSet, { FIELDSET_TYPE_SMALL_SPACE } from 'src/components/forms/field_set/field_set';

// intefaces & constants
import { IAuthProps } from 'src/components/authentication/auth_ui';
import { AUTH_UI_CLASS } from 'src/constants';

// helpers
import { textResources } from 'src/lang/de';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { RegistrationHelper } from 'src/utils/registration';
import { reportError } from 'src/utils/reporting/report-errors';
import { validateEmail } from 'src/utils/validation';

const cls = createBemBlock(AUTH_UI_CLASS);

const labels = textResources.authentication;

interface IState {
  validEmail: boolean;
}

class FacebookRegistrationEmail extends React.Component<IAuthProps, IState> {
  constructor(props: IAuthProps) {
    super(props);
    this.state = {
      validEmail: true,
    };
    this.submit = this.submit.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
  }

  public render() {
    const { email, goToLogin } = this.props;
    return (
      <>
        <p className={cls('text')}>{labels.facebookRegistrationEmailSubTitle}</p>
        <form>
          <FieldSet>
            <EMailField
              name='email'
              onChange={this.changeEmail}
              value={email}
              valid={this.state.validEmail}
            />
          </FieldSet>
          <FieldSet type={FIELDSET_TYPE_SMALL_SPACE}>
            <FacebookButton onClick={this.submit} label={labels.loginWithFacebook} />
            <DefaultButton onClick={goToLogin} label={labels.alreadyRegistered} />
          </FieldSet>
          <AGB />
        </form>
      </>
    );
  }

  private submit(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();

    const { email = '', googlePlaceId } = this.props;

    if (!googlePlaceId) {
      reportError(
        'Facebook registration attempted without googlePlaceId',
        {
          reason: 'AuthUI method "submitFacebookRegistrationWithEmail" called, but "googlePlaceId" is ' +
                  '"undefined", which means that the URL search parameter "gpid" got lost somehow',
        },
      );
      return;
    }

    const validEmail = validateEmail(email);
    if (validEmail) {
      RegistrationHelper.registerWithFacebookByPlaceIdAndEmail(googlePlaceId, email);
    } else {
      this.setState({
        validEmail,
      });
    }
  }

  private changeEmail(value: string) {
    const { changeEmail } = this.props;
    this.setState({ validEmail: true });
    changeEmail && changeEmail(value);
  }
}

export default FacebookRegistrationEmail;
