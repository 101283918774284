// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import EventsFeedCalendarSubheader from 'src/components/header/subheaders/pages/events_feed/calendar_subheader';
import MetaTags from 'src/components/meta_tags/meta_tags';
import CategorizedEventFeed from 'src/containers/smart_components/feeds/categorized_event_feed';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import { getPageTitle } from 'src/utils/page_title/page_title';
import { LayoutFeaturedContainer } from '../layout/layout_featured_container';

// constants & interfaces
import { FEED_TYPE_EVENT } from 'src/constants/feed';
import {
  EVENTSFEED_DESCRIPTION,
  EVENTSFEED_OG_IMAGE,
  EVENTSFEED_TITLE,
} from 'src/constants/meta_tags';
import { textResources } from 'src/lang/de';

// helpers
import { usePageTracking, useIsPostCreateOpen } from 'src/pages/hooks/hooks';
import { useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';

const CategorizedEventFeedPage: React.FC<RouteComponentProps> = ({ match }) => {
  const locationShape = useGetAppLocationShape();
  const user = useSelectUser();

  const isPostCreateOpen = useIsPostCreateOpen(match);

  const title = locationShape && EVENTSFEED_TITLE(locationShape.name);
  const description = locationShape && EVENTSFEED_DESCRIPTION(locationShape.name);

  usePageTracking({ pageTitle: title, user });

  return (
    <>
      <MetaTags
        description={description}
        geo_placename={locationShape?.name}
        geo_position={locationShape && `${locationShape.latitude};${locationShape.longitude}`}
        og_image={EVENTSFEED_OG_IMAGE}
        og_url={document.location.toString()}
        title={title}
      />
      <LayoutFeaturedContainer
        subHeader={<EventsFeedCalendarSubheader />}
        activeNavigationItem={FEED_TYPE_EVENT}
        pageTitle={
          <StandardPageTitle
            text={getPageTitle(textResources.pageTitles.eventfeedSimple, textResources.pageTitles.eventfeed)}
            showLocation />
        }
        withHeaderMargin>
        <CategorizedEventFeed
          isPostCreateOpen={isPostCreateOpen}
        />
      </LayoutFeaturedContainer>
    </>
  );
};

export default CategorizedEventFeedPage;
