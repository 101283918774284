import { getTextResource } from '@lokalportal/frontends-shared';
import { SupportedLanguages } from '@lokalportal/frontends-shared/lib/i18n/model';
import { AvailableLanguage } from 'i18n/model';
import { getConfigValue } from 'src/utils/config/config';

export const getCurrentLanguage = () => {
  const languages = getConfigValue<AvailableLanguage[]>('languages');
  return languages && languages.find(language => language.isEnabled && language.isDefault) as AvailableLanguage;
};

export const getTranslations = () => {
  const currentLanguage: AvailableLanguage | undefined = getCurrentLanguage();
  if (currentLanguage) {
    return getTextResource(currentLanguage.language as unknown as SupportedLanguages);
  } else {
    return getTextResource(SupportedLanguages.DE);
  }
};
