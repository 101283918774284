// libs
import * as React from 'react';

// components
import PostHighlightPlaceholder from 'src/components/content_placeholder/components/post_highlight/post_highlight';

const NewsFeedHighlightsPlaceholder: React.FC = () => (
  <>
    <PostHighlightPlaceholder />
    <PostHighlightPlaceholder />
    <PostHighlightPlaceholder showMoreButton />
    <PostHighlightPlaceholder showMoreButton />
  </>
);

export default NewsFeedHighlightsPlaceholder;
