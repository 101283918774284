// libs
import { useSelector } from 'react-redux';

// interfaces
import { ILocationShape } from 'src/interfaces/location';
import { IProfileTag } from 'src/interfaces/tags';
import { IPermissions, IUser } from 'src/interfaces/user';
import { IRootState } from 'src/reducers/interface';

// helpers
import { isIntermediateProfile, getProfileStatus } from 'src/utils/profile';

export const useSelectUser = () => useSelector<IRootState, IUser>(({ user }) => user);

export const useSelectUserMail = () => useSelector<IRootState, string | undefined>(({ user }) => user.email);

export const useSelectIsUserLoggedIn = () =>
  useSelector<IRootState, boolean>(({ user }) => user.loggedIn);

export const useSelectIsUserHome = () =>
  useSelector<IRootState, boolean | undefined>(({ user }) => user.profiles[user.selectedProfileId].meta.isHome);

export const useSelectProfilePermissions = () =>
  useSelector<IRootState, IPermissions | undefined>(({ user }) => user.profiles[user.selectedProfileId]?.permissions);

export const useSelectCurrentProfile = () =>
  useSelector(({ user }: IRootState) => user.profiles[user.selectedProfileId]);

export const useSelectUserLocationShape = () =>
  useSelector<IRootState, ILocationShape | undefined>(({ user }) =>
    user.profiles[user.selectedProfileId].address?.locationShape);

export const useSelectPrivateTopicUrl = () =>
  useSelector<IRootState, string | undefined>(({ user }) =>
    user.profiles[user.selectedProfileId]?.privatePostTopic?.urls.posts);

export const useSelectUserTags = () =>
  useSelector<IRootState, IProfileTag[] | undefined>(({ user }) =>
    user.profiles[user.selectedProfileId]?.tags);

export const useSelectProfileStatus = () => {
  return useSelector(getProfileStatus);
};

export const useIsIntermediateProfile = () => {
  const userType = useSelectCurrentProfile().type;
  return isIntermediateProfile(userType);
};
