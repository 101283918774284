import React from 'react';
import { CLASS_PREFIX } from 'src/constants';
import { FEED_TYPE_NEWS } from 'src/constants/feed';
import { useFeedWithPagination } from 'src/hooks_shared/use_feed_pagination';

import '../forms/button/button.scss';
import styles from './show_new_posts_button.module.scss';

const clsBlock: string = CLASS_PREFIX + 'button--contained';

interface IProps {
  onClick: () => void;
}

export const ShowNewPostsButton: React.FC<IProps> = () => {
  const { getPageUrl } = useFeedWithPagination(FEED_TYPE_NEWS);

  return (
    <div className={styles.buttonContainer}>
      <a
        href={getPageUrl(1)}
        title='Neuere Beiträge anzeigen'
        className={clsBlock}
      >
        <div className={styles.button}>
          Neuere Beiträge anzeigen
        </div>
      </a>
    </div>
  );
};
