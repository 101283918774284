// interfaces & constants
import { GTM_CUSTOM_EVENT } from 'src/constants/google_tag_manager';
import {
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  INTERMEDIATE_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  PRIVATE_PROFILE_TYPE,
  REPORTER_PROFILE_TYPE,
} from 'src/constants/profile';
import { ANONYMOUS_USER_TYPE } from 'src/constants/user';
import { ILocationShape } from 'src/interfaces/location';
import { IPost } from 'src/interfaces/posts';
import { IProfile } from 'src/interfaces/profile';
import { IUser, IUserProfile, IUserProfileType } from 'src/interfaces/user';

// helpers
import DateHelper from 'src/utils/date_helper/date';
import { UrlUtils } from 'src/utils/url/url';
import { idFromGlobalFriendlyIdentifier } from '../profile';

interface IWindowDataLayer extends Window {
  dataLayer: Array<{}>;
}

export interface IPageNavData {
  user?: IUser;
  post?: IPost;
  pageTitle?: string;
  sourceComponent?: string;
  author?: IProfile;
}

type IPageNavigationDataToReport = ICustomDimensions & {
  event: 'lp-navigation';
  url: string;
};

// Custom dimensions keys
const HOME_LOCATION_NAME = 'homeLocation.name';
const POST_LOCATION_NAME = 'postLocation.name';
const VIEW_LOCATION_NAME = 'viewLocation.name';
const PROFILE_EXTENSION_AGE = 'profileExtension.age';
const PROFILE_EXTENSION_STATE = 'profileExtension.state';
const PROFILE_EXTENSION_TYPE = 'profileExtension.type';

interface IProfileExtensionCustomDimensions {
  [PROFILE_EXTENSION_AGE]?: number;
  [PROFILE_EXTENSION_STATE]?: number;
  [PROFILE_EXTENSION_TYPE]?: IUserProfileType;
}

export type ICustomDimensions = IProfileExtensionCustomDimensions & {
  author?: string;
  [HOME_LOCATION_NAME]?: string;
  [POST_LOCATION_NAME]?: string;
  [VIEW_LOCATION_NAME]?: string;
  token?: string;
  shareUrl?: string;
  pageTitle?: string;
  sourceComponent?: string;
};

export default class GoogleTagManager {
  public static pushEvent(eventName: string): void {
    GoogleTagManager.dataLayer.push({ event: eventName });
  }

  public static pushCustomDimension(customDimension: ICustomDimensions): void {
    GoogleTagManager.dataLayer.push(customDimension);
  }

  public static pushEventWithValue(category: string, action: string, label?: string, callback?: () => void): void {
    GoogleTagManager.dataLayer.push({
      event: GTM_CUSTOM_EVENT,
      eventAction: action,
      eventCallback: callback,
      eventCategory: category,
      eventLabel: label,
      eventTimeout: 2000,
    });
  }

  public static pushEventWithValuePromise(category: string, action: string, label?: string): Promise<never> {
    return new Promise((resolve) =>
      this.pushEventWithValue(category, action, label, resolve));
  }

  public static pushPageNavigation(path: string, data: IPageNavData): void {
    const url = UrlUtils.getCurrentUrlOrigin() + path;
    const { post, user, pageTitle, sourceComponent, author } = data;
    const selectedProfile = user && user.profiles[user.selectedProfileId];
    const selectedProfileLocationShape =
      selectedProfile && selectedProfile.address && selectedProfile.address.locationShape;

    const dataToReport: IPageNavigationDataToReport = {
      event: 'lp-navigation',
      url,
    };

    dataToReport.pageTitle = pageTitle || document.title;

    if (sourceComponent) {
      dataToReport.sourceComponent = sourceComponent;
    }

    if (selectedProfileLocationShape) {
      dataToReport[HOME_LOCATION_NAME] = selectedProfileLocationShape.name;
    }

    if (post) {
      dataToReport[POST_LOCATION_NAME] = post.locationShape.name;
      if (author) {
        dataToReport.author = `${idFromGlobalFriendlyIdentifier(author.id)} (${author.type})`;
      }
    }

    if (selectedProfile) {
      Object.assign(dataToReport, GoogleTagManager.profileExtensionProperties(selectedProfile));
    }

    GoogleTagManager.dataLayer.push(dataToReport);
  }

  // Custom dimensions
  public static pushHomeLocationShape(locationShape: ILocationShape): void {
    this.pushCustomDimension({
      [HOME_LOCATION_NAME]: locationShape.name,
    });
  }

  public static pushViewLocationShape(locationShape: ILocationShape): void {
    this.pushCustomDimension({
      [VIEW_LOCATION_NAME]: locationShape.name,
    });
  }

  public static pushProfileExtension(extension: IUserProfile): void {
    this.pushCustomDimension(GoogleTagManager.profileExtensionProperties(extension));
  }

  // Custom events
  public static pushEventVariable(eventCategory: string, eventAction: string, eventLabel?: string): void {
    GoogleTagManager.dataLayer.push(
      { event: `${eventCategory}__${eventAction}${eventLabel ? `--${eventLabel}` : ''}`,
        eventAction,
        eventCategory,
        eventLabel });
  }

  private static dataLayer: Array<{}> = (window as unknown as IWindowDataLayer).dataLayer || [];

  // Helpers
  private static extensionState(extension: IUserProfile): number | undefined {
    switch (extension.type) {
      case ANONYMOUS_USER_TYPE:
        return 0;
      case INTERMEDIATE_PROFILE_TYPE:
        return 1;
      case PRIVATE_PROFILE_TYPE:
      case BLOG_PROFILE_TYPE:
      case REPORTER_PROFILE_TYPE:
      case PRESS_PROFILE_TYPE:
      case AUTHORITY_PROFILE_TYPE:
        return 2;
      default:
        return undefined;
    }
  }

  private static extensionAge(extension: IUserProfile): number | undefined {
    if (extension.dayOfBirth === undefined) {
      return undefined;
    }
    return DateHelper.differenceInYears(extension.dayOfBirth);
  }

  private static profileExtensionProperties(extension: IUserProfile): IProfileExtensionCustomDimensions {
    return {
      [PROFILE_EXTENSION_AGE]: GoogleTagManager.extensionAge(extension),
      [PROFILE_EXTENSION_STATE]: GoogleTagManager.extensionState(extension),
      [PROFILE_EXTENSION_TYPE]: extension.type,
    };
  }
}
