import {
  SETTINGS_ACTION_ADD_EMAIL,
  SETTINGS_ACTION_CHANGE,
  SETTINGS_ACTION_LOGIN,
  SETTINGS_ACTION_REGISTRATION,
  SETTINGS_REFERRAL_APPLE,
  SETTINGS_REFERRAL_EMAIL,
  SETTINGS_REFERRAL_FACEBOOK,
} from 'src/constants/settings';
import { Id } from 'src/interfaces';
import { ItemType } from 'src/interfaces/posts';
import { getConfigValue } from 'src/utils/config/config';
import { FEED_POST_TYPE_POST } from './feed';

// ---------- API URL paths -----------

const apiHostURL = (path: string) => {
  return process.env.API_HOST ? new URL(path, process.env.API_HOST).href : path;
};

// const API_BASE = '/api/';
const API_BASE = apiHostURL('/api/');

export const API = API_BASE + '';
export const API_NEWSFEED_PATH = API_BASE + 'newsfeed/';
export const API_EVENTSFEED_PATH = API_BASE + 'events/';
export const API_EVENTS_CATEGORIZED_FEED_PATH = API_BASE + 'events/categorized/';
export const API_EVENTS_PARTICIPATING_FEED_PATH = API_BASE + 'events/participating/';

export const API_AUTHORITY_PROFILE_FEED_PATH = API_BASE + 'newsfeed/author/AuthorityProfile/';
export const API_BLOG_PROFILE_FEED_PATH = API_BASE + 'newsfeed/author/BlogProfile/';
export const API_REPORTER_PROFILE_FEED_PATH = API_BASE + 'newsfeed/author/ReporterProfile/';
export const API_PRIVATE_PROFILE_FEED_PATH = API_BASE + 'newsfeed/author/PrivateProfile/';
export const API_PRESS_PROFILE_FEED_PATH = API_BASE + 'newsfeed/author/PressProfile/';

export const API_POST_PATH = API_BASE + 'posts/';
export const API_POST_PREVIEW_PATH = API_BASE + 'posts/preview';
export const API_POST_VALIDATE_ADDRESS_PATH = API_BASE + 'posts/validate_address';
export const API_EVENT_SHOW_PATH = API_BASE + 'events/';
export const API_EVENT_POST_PATH = API_BASE + 'events/';
export const API_EVENT_PREVIEW_PATH = API_BASE + 'events/preview';
export const API_CATEGORIES_PATH = API_BASE + 'categories/';
export const API_CONVERSATIONS_PATH = API_BASE + 'v2/conversations/';
export const API_CONVERSATIONS_UNREAD_PATH = API_BASE + 'conversations/unread';
export const API_CONVERSATIONS_UNREAD_COUNT_PATH = API_BASE + 'conversations/unread_count';
export const API_ADDRESS_LOOKUP_PATH = API_BASE + 'addresses/lookup';
export const API_LOCATION_SHAPES_PATH = API_BASE + 'locations/';
export const API_LOCATION_SHAPES_KML_PATH = API_BASE + 'location_shapes/kml.xml';
export const API_AUTHORITY_PROFILE_PATH = API_BASE + 'authority_profiles/';
export const API_AUTHORITY_PROFILE_UPDATE_PATH = API_BASE + 'authority/authority_profile/';
export const API_BLOG_PROFILE_PATH = API_BASE + 'blog_profiles/';
export const API_BLOG_PROFILE_UPDATE_PATH = API_BASE + 'blog/blog_profile/';
export const API_BLOG_PROFILE_PREVIEW_PATH = API_BASE + 'blog_profiles/preview';
export const API_REPORTER_PROFILE_PATH = API_BASE + 'reporter_profiles/';
export const API_PRIVATE_PROFILE_PATH = API_BASE + 'private_profiles/';
export const API_PRIVATE_PROFILE_UPDATE_PATH = API_BASE + 'private/private_profile/';
export const API_PRIVATE_PROFILE_PREVIEW_PATH = API_BASE + 'private_profiles/preview';
export const API_PRIVATE_PROFILE_CREATE_PATH = API_BASE + 'private/private_profiles';
export const API_PRESS_PROFILE_PATH = API_BASE + 'press_profiles/';
export const API_PRESS_PROFILE_UPDATE_PATH = API_BASE + 'press/press_profile/';
export const API_INTERMEDIATE_PROFILE_PATH = API_BASE + 'intermediate/intermediate_profile';
export const API_INTERMEDIATE_PROFILE_UPGRADE_PATH = API_BASE + 'intermediate/intermediate_profile/upgrade';
export const API_OGLINKS_PATH = API_BASE + 'oglinks/fetch';
export const API_ME_PATH = API_BASE + 'me';
export const API_PASSWORD_PATH = API_BASE + 'password';
export const API_REGISTRATION_PATH = API_BASE + 'sign_up';
export const API_USER_UPDATE_PASSWORD = API_BASE + 'profile/update_password';
export const API_USER_UPDATE_EMAIL = API_BASE + 'profile/update_email';
export const API_USER_VERIFY = API_BASE + 'profile/verify';
export const API_USER_UPDATE_PHONE = API_BASE + 'profile/update_phone';
export const API_USER_PHONE_VERIFY = API_BASE + 'profile/token_phone_verification';
export const API_USER_RESEND_CONFIRMATION_EMAIL = API_BASE + 'profile/send_confirmation_instructions';
export const API_BLACKLIST_PATH = API_BASE + 'blacklist';
export const API_GRAYLIST_PATH = API_BASE + 'graylist';
export const API_USER = API_BASE + 'profile';
export const API_PROFILE_PARTICIPATIONS = API_BASE + 'profile_extension_participation/';
export const API_FEEDBACK_PATH = API_BASE + 'feedback';
export const API_SHARE_PATH = API_BASE + 'share';
export const API_NOTIFICATION_FETCH_PATH = API_BASE + 'notifications/fetch';
export const API_NOTIFICATION_SET_TRAIT = API_BASE + 'notifications/set_trait';
export const API_NOTIFICATION_COUNTS_PATH = API_BASE + 'notifications/counts';
export const API_ALL_GROUPS_PATH = API_BASE + 'groups';
export const API_MY_GROUPS_PATH = API_BASE + 'groups/participating';
export const API_GROUP_PATH = (identifier: string) => API_BASE + `groups/${identifier}`;
export const API_PROFILES = API_BASE + 'profile_extensions';
export const API_PROFILES_CATEGORIZED = API_BASE + 'profile_extensions/categorized';
export const API_FOLLOWS_PATH = API_BASE + 'follows';
export const API_FOLLOWEES_PATH = API_BASE + 'followees';
export const API_FOLLOWERS_PATH = API_BASE + 'followers';
export const API_ADD_TAGS_PATH = API_BASE + 'taggings';
export const API_REMOVE_TAG_PATH = (identifier: string) => API_BASE + `taggings/${identifier}`;
export const API_GET_TAGS_PATH = (query: string) => API_BASE + `tags?query=${query}`;
export const API_SHAPE_SPONSORS = API_BASE + 'shape_sponsorships';
export const API_GROUPS_RECENT_FEED = API_BASE + 'group_feed';
export const API_FOLLOWEES_RECENT_FEED = API_BASE + 'followees_feed';
export const API_NEWSFEED_HIGHLIGHTS_PATH = API_BASE + 'newsfeed/highlights';
export const API_GROUP_NOTIFICATION_SETTINGS = (groupId: string) =>
  `${API_ALL_GROUPS_PATH}/${groupId}/group_participations/notification_settings`;

const API_BOOK_MARKERS_PATH = '/bookmarks/bookmarkers';
export const API_POST_REACTED_PROFILES_PATH = (itemType: ItemType, id: Id) => {
  const endpoint = itemType === FEED_POST_TYPE_POST ? 'posts' : 'events';
  return API_BASE + `${endpoint}/${id}${API_BOOK_MARKERS_PATH}`;
};

// ---------- OAuth2 URL paths ----------

export const OAUTH2_REVOKE_PATH = apiHostURL('/oauth/revoke');
export const OAUTH2_TOKEN_PATH = apiHostURL('/oauth/token');
export const OAUTH2_APPLE_PATH = apiHostURL('/omniauth/apple');
export const OAUTH2_FACEBOOK_PATH = apiHostURL('/omniauth/facebook');
export const OAUTH2_INTENTION_LOGIN = 'login';
export const OAUTH2_INTENTION_REGISTER = 'register';
export const OAUTH2_FACEBOOK_LOGIN_URL = (oauthClientId: string, redirectUrl: string) =>
  `${OAUTH2_FACEBOOK_PATH}?intent=${OAUTH2_INTENTION_LOGIN}` +
  `&client_id=${oauthClientId}&redirect_url=${redirectUrl}`;
export const OAUTH2_FACEBOOK_REGISTRATION_URL = (googlePlaceId: string, redirectUrl: string, email?: string) =>
  `${OAUTH2_FACEBOOK_PATH}?intent=${OAUTH2_INTENTION_REGISTER}` +
  `&google_place_id=${googlePlaceId}&redirect_url=${redirectUrl}` +
  (
    email ? `&email=${email}&redirect_url_email_confirmation=${REDIRECT_URL_REGISTRATION_FACEBOOK_WITH_EMAIL}`
      : ''
  );
export const OAUTH2_APPLE_LOGIN_URL = (oauthClientId: string, redirectUrl: string) =>
  `${OAUTH2_APPLE_PATH}?intent=${OAUTH2_INTENTION_LOGIN}` +
  `&client_id=${oauthClientId}&redirect_url=${redirectUrl}`;
export const OAUTH2_APPLE_REGISTRATION_URL = (googlePlaceId: string, redirectUrl: string) =>
  `${OAUTH2_APPLE_PATH}?intent=${OAUTH2_INTENTION_REGISTER}` +
  `&google_place_id=${googlePlaceId}&redirect_url=${redirectUrl}`;

// ---------- route partials ------------

export const NEWS_FEED_PARTIAL = '/aktuelles';
export const EVENTS_FEED_PARTIAL = '/veranstaltungen';
export const FOLLOWERS_PARTIAL = '/abonnenten';
export const SHAPE_SPONSORS = '/partner-des-ortes';

export const PROFILE_LIST_AUTHORITY_URI = '/oeffentlich';
export const PROFILE_LIST_BLOG_URI = '/seiten';
export const PROFILE_LIST_PRESS_URI = '/redaktion';
export const PROFILE_LIST_PRIVATE_URI = '/nachbarn';
export const PROFILE_LIST_URIS = [
  PROFILE_LIST_AUTHORITY_URI,
  PROFILE_LIST_BLOG_URI,
  PROFILE_LIST_PRESS_URI,
  PROFILE_LIST_PRIVATE_URI,
] as const;

const AUTHOR_TYPE_AUTHORITY_ENGLISH_URI = '/authority/';
const AUTHOR_TYPE_BLOG_ENGLISH_URI = '/blog/';
const AUTHOR_TYPE_REPORTER_ENGLISH_URI = '/reporter/';
const AUTHOR_TYPE_PRIVATE_ENGLISH_URI = '/private/';
const AUTHOR_TYPE_PRESS_ENGLISH_URI = '/press/';

// ---------- frontend URL paths ----------

export const FRONTEND_PROFILE_FEED_PATH = '/profiles';
export const FRONTEND_AUTHORITY_PROFILE_FEED_PATH = `${FRONTEND_PROFILE_FEED_PATH}${AUTHOR_TYPE_AUTHORITY_ENGLISH_URI}`;
export const FRONTEND_BLOG_PROFILE_FEED_PATH = `${FRONTEND_PROFILE_FEED_PATH}${AUTHOR_TYPE_BLOG_ENGLISH_URI}`;
export const FRONTEND_REPORTER_PROFILE_FEED_PATH = `${FRONTEND_PROFILE_FEED_PATH}${AUTHOR_TYPE_REPORTER_ENGLISH_URI}`;
export const FRONTEND_PRIVATE_PROFILE_FEED_PATH = `${FRONTEND_PROFILE_FEED_PATH}${AUTHOR_TYPE_PRIVATE_ENGLISH_URI}`;
export const FRONTEND_PRESS_PROFILE_FEED_PATH = `${FRONTEND_PROFILE_FEED_PATH}${AUTHOR_TYPE_PRESS_ENGLISH_URI}`;
export const FRONTEND_NEWSFEED_PATH = '/newsfeed';
export const FRONTEND_EVENT_PATH = '/events';
export const FRONTEND_NOTIFICATIONS_PATH = '/benachrichtigungen';
export const FRONTEND_LOCAL_FEED_PATH = '/neuigkeiten';
export const FRONTEND_EVENT_CATEGORY_PATH = '/event-category';
export const FRONTEND_EVENT_PARTICIPANTS_PATH = '/participants';
export const FRONTEND_POST_REACTIONS_PATH = '/reactions';
export const FRONTEND_POST_PATH = '/posts';
export const FRONTEND_CHAT_PATH = '/chat';
export const FRONTEND_POST_COMMENTS_ANCHOR = '#comments';
export const FRONTEND_CONVERSATION_PATH = '/conversations/';
export const FRONTEND_SEND_MESSAGE_CONVERSATION_PATH = '/conversations/open/';
export const FRONTEND_GROUP_OVERVIEW_PATH = '/groups/';
export const FRONTEND_GROUP_PATH = '/groups/';
export const FRONTEND_SETTINGS_PATH = '/settings';
export const FRONTEND_EDIT_DESCRIPTION_PATH = '/settings/description';
export const FRONTEND_EDIT_INTERNET_SITE_PATH = '/settings/internet-site';
export const FRONTEND_EDIT_TAGS_PATH = '/settings/tags';
export const FRONTEND_EDIT_IMPRESSUM_PATH = '/settings/site-notice';
export const FRONTEND_SETTINGS_VERIFICATION = '/settings/verification';
export const FRONTEND_CREATE_PROFILE = '/create-profile';
export const FRONTEND_LOCALIZED_NEWS = '/neuigkeiten';
export const FRONTEND_LOCALIZED_EVENTS = '/veranstaltungen';
export const FRONTEND_NEW_PASSWORD = '/new-password';
export const FRONTEND_REDIRECT = '/redirect';
export const FRONTEND_NOT_FOUND_PATH = '/not-found';
export const FRONTEND_REGISTRATION = '/registration';
export const FRONTEND_APP_REGISTRATION = '/app-registration';
export const FRONTEND_LOGIN = '/login';
export const FRONTEND_PROFILES_DIRECTORY = '/verzeichnis';
export const FRONTEND_GROUP_FEED_PATH = '/groups-feed';
export const FRONTEND_FOLLOWEES_RECENT_FEED_PATH = '/followees-feed';
export const FRONTEND_LOCATION_PICKER_PATH = '/ort';

// ---------- sub routes ------------

export const CREATE_POST = '/create';
export const UPDATE_POST = '/update';
export const cleanUpdatePath = (path: string) =>
  path.replace(new RegExp('\/' + UPDATE_POST.substr(1) + '$'), '');

// ----------- urls param patterns ----

export const LOCATION_SLUG_PATTERN = getConfigValue('featureFlags.location.customLocationSlug') || ':locationSlug';
export const FILTER_PATTERN = ':filter';
export const PROFILES_DIRECTORY_CATEGORY_PATTERN = `:category(${PROFILE_LIST_URIS.join('|')})`;
export const FRONTEND_EVENT_PARTICIPATING_PATH = 'teilgenommen';
export const CHAT_ROOM_PATTERN = ':roomId';
export const PROFILES_SEARCH_QUERY_KEY = 's';

// ---------- static pages ------------

export const FRONTEND_DATA_IMPRESSUM_PATH = getConfigValue('staticPages.urls.Legal');
export const FRONTEND_DATA_AGB_PATH = getConfigValue('staticPages.urls.TermsAndConditions');
export const FRONTEND_DATA_PROTECTION_PATH = getConfigValue('staticPages.urls.PrivacyPolicy');
export const FRONTEND_DATA_BLOG_PATH = getConfigValue('staticPages.urls.Blog');
export const FRONTEND_DATA_FAQ_PATH = getConfigValue('staticPages.urls.Faq');
export const FRONTEND_DATA_CONTACT_PATH = getConfigValue('staticPages.urls.Contact');
export const FRONTEND_DATA_LEARN_MORE_PATH = getConfigValue('staticPages.urls.AboutUs');

// ---------- redirect urls -----------

export const REDIRECT_PARAMS_LOGIN_FACEBOOK = `ref=${SETTINGS_REFERRAL_FACEBOOK}&action=${SETTINGS_ACTION_LOGIN}`;
export const REDIRECT_URL_PASSWORD_RESET = '/new-password';
export const REDIRECT_URL_REGISTRATION_EMAIL_CONFIRMATION =
  `${FRONTEND_SETTINGS_PATH}?ref=${SETTINGS_REFERRAL_EMAIL}&action=${SETTINGS_ACTION_REGISTRATION}`;
export const REDIRECT_URL_REGISTRATION_FACEBOOK = (googlePlaceId: string) =>
  `${FRONTEND_SETTINGS_PATH}?ref=${SETTINGS_REFERRAL_FACEBOOK}&action=${SETTINGS_ACTION_REGISTRATION}` +
  `&gpid=${googlePlaceId}`;
export const REDIRECT_URL_REGISTRATION_FACEBOOK_WITH_EMAIL =
  `${FRONTEND_SETTINGS_PATH}?ref=${SETTINGS_REFERRAL_FACEBOOK}&action=${SETTINGS_ACTION_ADD_EMAIL}`;
export const REDIRECT_EMAIL_CHANGE =
  `${FRONTEND_SETTINGS_PATH}?ref=${SETTINGS_REFERRAL_EMAIL}&action=${SETTINGS_ACTION_CHANGE}`;
export const REDIRECT_PARAMS_LOGIN_APPLE = `ref=${SETTINGS_REFERRAL_APPLE}&action=${SETTINGS_ACTION_LOGIN}`;
export const REDIRECT_URL_REGISTRATION_APPLE = (googlePlaceId: string) =>
  `${FRONTEND_SETTINGS_PATH}?ref=${SETTINGS_REFERRAL_APPLE}&action=${SETTINGS_ACTION_REGISTRATION}` +
  `&gpid=${googlePlaceId}`;

// ---------- static URL paths ----------

export const STRUCTURED_DATA_LOGO_PATH =
  'https://s3.eu-central-1.amazonaws.com/lopo-static/images/logo-structured-data.png';
export const GOOGLE_PLAY_PATH = getConfigValue('links.googlePlayStore');
export const APP_STORE_PATH = getConfigValue('links.appleStore');

// ---------- WebSocket URL paths ----------

const WEBSOCKET_PROTOCOL = window.location.protocol === 'http:' ? 'ws://' : 'wss://';
export const WEBSOCKET_URL = WEBSOCKET_PROTOCOL + window.location.host + '/websocket';
