export const PASSWORD_INVALID = 'not_updated';
export const PASSWORD_WRONG = 'wrong_password';

export const CANNOT_BE_USED = 'kann nicht verwendet werden.';

// Address look up api
export const ADDRESS_NOT_FOUND = 'address_not_found';
export const AREA_NOT_RELEASED = 'area_not_released';
export const INVALID_PARAMETERS = 'invalid_parameters';

export const RECORD_NOT_FOUND = 'record_not_found';

// group post details
export const GROUP_POST = 'group_post';
export const NETWORK_ERROR = 'network_error';
