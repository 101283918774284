import { AllowedShareTracking } from 'src/components/share/lib/share';

interface IUTMParams {
  source: string;
  medium: string;
}

export const withUTMParams = (url: string, { source, medium }: IUTMParams) =>
  `${url}?utm_source=${source}&utm_medium=${medium}&utm_campaign=share`;

export const nativeShare = (url: string, utmSource: string, tracking?: AllowedShareTracking) => {
  return () => {
    // TODO update lib we use in typescript to have typings for this
    // @ts-ignore
    window.navigator.share({
      url: withUTMParams(url, { medium: 'native-web', source: utmSource }),
    }).then(() => {
      if (tracking) {
        tracking.tracker(
          tracking.trackingObj.ACTIONS.SHARE_NATIVE,
          tracking.trackingObj.LABELS.FINISH,
          undefined,
          { shareUrl: url });
      }
    });
  };
};
