// interfaces
import {
  FetchFeedThunkAction,
  IProfileFeedMergeableParams,
  IProfileFeedRequestParams,
} from 'src/actions/feed/feed_interfaces';

// constants
import { FEED_TYPE_PROFILE } from 'src/constants/feed';

// actions
import { fetchFeed } from 'src/actions/feed/feed';

// helpers
import api from 'src/api/';

const fetchProfileFeed = fetchFeed<IProfileFeedRequestParams>(
  FEED_TYPE_PROFILE,
  api.authorFeed.get,
);

export const fetchProfileFeedPage = (page: number = 1, params: IProfileFeedMergeableParams): FetchFeedThunkAction => {
  return async(dispatch): Promise<void> => {
    dispatch(fetchProfileFeed({
      ...params,
      page,
    }));
  };
};
