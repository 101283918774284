import React from 'react';

// components
import GroupItemSegment, { GroupIconType } from 'src/components/item_segment/layouts/group/group';

// interfaces
import { IGroup } from 'src/interfaces/group';

// helpers
import { UrlUtils } from 'src/utils/url/url';

interface IGroupItemProps {
  group: IGroup;
  joinGroup?: (group: IGroup) => void;
}

const getGroupIconType = (group: IGroup): GroupIconType | undefined => {
  if (group.isAdmin) {
    return 'admin';
  }
  if (!group.isMember) {
    if (group.isParticipating) {
      return 'pending';
    }
    return 'join';
  }
  return undefined;
};

const GroupItem: React.FC<IGroupItemProps> = ({ group, joinGroup }) => {
  const join = () => {
    joinGroup && joinGroup(group);
  };

  return (
    <GroupItemSegment
      accessibility={group.accessibility}
      label={group.name}
      description={group.description}
      groupIconType={getGroupIconType(group)}
      navigation={{ target: '_self', url: UrlUtils.groupPage(group.id) }}
      canJoinGroup={group.permissions.canJoin}
      onJoin={join}
    />
  );
};

export default GroupItem;
