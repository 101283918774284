// libs
import * as React from 'react';

// constants
import { THREE_COLUMNS, TWO_COLUMNS } from 'src/constants/layout';
import LayoutBase from 'src/layout/base/layout_base';

interface IProps {
  contentWidth?: typeof TWO_COLUMNS | typeof THREE_COLUMNS; // default is full width - 3 columns
}

const LayoutNoHeader: React.FC<IProps> = ({ contentWidth, children }) => {
  return (
    <LayoutBase contentWidth={contentWidth} withHeader={false} withSidebar={false} enableMapToggler={false}>
      {children}
    </LayoutBase>
  );
};

export default LayoutNoHeader;
