import { ellipsis } from "../utils";

/**
 * A truncation feature where the ellipsis will be placed at the end of the URL.
 *
 * @param {String} anchorText
 * @param {Number} truncateLen The maximum length of the truncated output URL string.
 * @param {String} ellipsisChars The characters to place within the url, e.g. "..".
 * @return {String} The truncated URL.
 */
export function truncateEnd( anchorText: string, truncateLen: number, ellipsisChars?: string){
	return ellipsis( anchorText, truncateLen, ellipsisChars );
}
