// libs
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';

// classes / components
import LocationHandler from 'src/containers/location/location_handler/location_handler';
import LocationSlugHandler from 'src/containers/location/location_slug_handler/location_slug_handler';
import { LoginPageWithHeader } from 'src/pages/login';

// helpers
import { usePageTracking } from 'src/pages/hooks/hooks';

// interfaces / constants
import { IRootState } from 'src/reducers/interface';
import { NavigationFeatureFlags } from '../components/feature_flag/navigation_feature_flag/model';
import { RouteFeatureFlag } from '../components/feature_flag/route_feature_flag/route_feature_flag';
import { AuthModalType } from '../interfaces/app-state';

interface LogoutProps {
  layout?: AuthModalType;
}

interface IProtectedPropsFromState {
  loggedIn: boolean;
  hasToken?: boolean;
}

const RedirectHome = () => {
  usePageTracking();
  return <Redirect to='/' />;
};

const mapStateToPropsProtected = ({ user }: IRootState): IProtectedPropsFromState => ({
  hasToken: user.hasToken,
  loggedIn: user.loggedIn,
});

type ProtectedRouteProps = IProtectedPropsFromState & MainRouteProps & LogoutProps;

// only exported for testing use TopLevelRoutes for external use
export const LoginRequired =
  ({ loggedIn, path, exact, component, featureFlag }: ProtectedRouteProps) => (
    <TopLevelRoute
      path={path}
      exact={exact}
      featureFlag={featureFlag}
      component={loggedIn ? component : LoginPageWithHeader}
    />
  );

// only exported for testing use TopLevelRoutes for external use
export const LogoutRequired =
  ({ loggedIn, path, exact, component, layout }: ProtectedRouteProps) => (
    <TopLevelRoute
      path={path}
      exact={exact}
      component={loggedIn ? RedirectHome : component}
      layout={layout}
    />
  );

type MainRouteProps = RouteProps & {
  featureFlag?: NavigationFeatureFlags;
  layout?: AuthModalType;
}
// default Route component that sets location only use in src/routes.tsx
export const TopLevelRoute: React.FunctionComponent<MainRouteProps> =
  ({ path, exact, component: Component, render, featureFlag, layout }: MainRouteProps) => {
    const wrappedRender = (routeProps: RouteComponentProps<{ locationSlug?: string }>) => {
      const componentProps = { ...routeProps, layout };
      if (routeProps.match.params.locationSlug) {
        return (
          <LocationSlugHandler>
            {render && render(componentProps)}
            {Component && <Component {...componentProps} />}
          </LocationSlugHandler>
        );
      }
      return (
        <LocationHandler>
          {render && render(componentProps)}
          {Component && <Component {...componentProps} />}
        </LocationHandler>
      );
    };
    return (
      <RouteFeatureFlag flag={featureFlag}>
        <Route
          path={path}
          exact={exact}
          render={wrappedRender}
        />
      </RouteFeatureFlag>);
  };

// lets the content(post_details/profile_feed) set the appLocation when loggedOut
export const ContentLocationRoute =
  ({ hasToken, path, exact, render, featureFlag, component: Component }: ProtectedRouteProps) => {
    const wrappedRender = (routeProps: RouteComponentProps<{ locationSlug?: string }>) => {
      const sharedElement = (
        <>
          {render && render(routeProps)}
          {Component && <Component {...routeProps} />}
        </>);
      if (hasToken) {
        return (
          <LocationHandler>
            {sharedElement}
          </LocationHandler>
        );
      }
      return sharedElement;
    };
    return (
      <RouteFeatureFlag flag={featureFlag}>
        <Route
          path={path}
          exact={exact}
          render={wrappedRender}
        />
      </RouteFeatureFlag>);
  };

export const ContentLocationTopLevelRoute =
  connect<IProtectedPropsFromState, {}, RouteProps>(mapStateToPropsProtected)(ContentLocationRoute);

export const LoginRequiredRoute =
  connect<IProtectedPropsFromState, {}, RouteProps>(mapStateToPropsProtected)(LoginRequired);

export const LogoutRequiredRoute =
  connect<IProtectedPropsFromState, {}, RouteProps>(mapStateToPropsProtected)(LogoutRequired);
