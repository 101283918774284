// libs
import React from 'react';

// components
import ItemGroupHeader from 'src/components/item_group_header/item_group_header';

// helpers
import { textResources } from 'src/lang/de';

interface IProps {
  subtitle?: string;
}

const EmptySearchResults: React.FC<IProps> = ({ subtitle }) => (
  <ItemGroupHeader
    title=''
    subtitle={subtitle || textResources.shared.noResultFound}
  />
);

export default EmptySearchResults;
