import { flatten } from 'lodash';

import { FEED_NUMBER_OF_POSTS_IN_VIEWPORT, SCROLL_THRESHOLD_TO_FETCH_NEXT_PAGE } from 'src/constants/feed';
import { IMarker, IMarkerContainingRecord } from 'src/interfaces/marker';
import { notUndefined } from 'src/utils';

const getDocumentHeight = (): number => {
  const { body, documentElement: html } = document;

  return Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
};

export const getScrollBottom = (): number => {
  const scrollTop = window.pageYOffset || window.scrollY;

  return window.innerHeight + scrollTop;
};

export const getIndexInViewportCenter = (ids: string[]): number => {
  if (!ids.length) {
    return -1;
  }

  const docHeight = getDocumentHeight();
  const scrollBottom = getScrollBottom();

  // We allow to say: this is the top of the page, don't calculate.
  if (docHeight === 0) {
    return -1;
  }

  const ratio = scrollBottom / docHeight;
  const length = ids.length - 1; // subtract for createPost component
  const index = Math.max(1, Math.floor((length - 1) * ratio)); // from 1 to N-1

  return index;
};

const getIndexRange = (index: number, length: number): { leftIndex: number; rightIndex: number } => ({
  leftIndex: Math.max(index - Math.ceil(FEED_NUMBER_OF_POSTS_IN_VIEWPORT / 2), 0),
  rightIndex: Math.min(index + Math.floor(FEED_NUMBER_OF_POSTS_IN_VIEWPORT / 2), length),
});

export const getMarkersFromMap = (
  indexInCenter: number,
  ids: string[],
  record: IMarkerContainingRecord,
): IMarker[] => {
  if (!indexInCenter) {
    return [];
  }

  const { leftIndex, rightIndex } = getIndexRange(indexInCenter, ids.length);

  return flatten(ids
    .slice(leftIndex, rightIndex)
    .map(id => record[id]?.markers)
  ).filter(notUndefined);
};

export const hasEnoughToShow = (): boolean => {
  const remainingDistanceToEnd = getDocumentHeight() - getScrollBottom();
  return remainingDistanceToEnd > SCROLL_THRESHOLD_TO_FETCH_NEXT_PAGE;
};
