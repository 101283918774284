// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { INTERMEDIATE_PROFILE_TYPE } from 'src/constants/profile';
import { MESSAGE_BOX_TIMEOUT } from 'src/constants/timeouts';
import { ProfileType } from 'src/interfaces/profile';
import { COLOR_TYPE_PRIMARY } from 'src/utils/color';

// classes / components
import Button, { BUTTON_TYPE_CONTAINED, BUTTON_TYPE_TEXT } from 'src/components/forms/button/button';
import FieldSet from 'src/components/forms/field_set/field_set';
import InputField from 'src/components/forms/input_field/input_field';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';
import MessageSegmentWithModal from 'src/components/message_segment/message_segment_with_modal';
import ShareSegment from 'src/components/share/share_segment/share_segment';
import SnackBar from 'src/components/snack_bar/snack_bar';

// helpers
import api from 'src/api/';
import { textResources } from 'src/lang/de';
import { validateEmail, isNotEmpty } from 'src/utils/validation';

import './spread.scss';

const labels = textResources.spread;
const sharedLabels = textResources.shared;

interface ISpreadProps {
  close: () => void;
  loggedInUserName?: string;
  profileType: ProfileType;
}

interface IState {
  emails: string;
  emailsIsValid: boolean;
  formSubmitted: boolean;
  isLoading: boolean;
  message: string;
  sender: string;
  senderIsValid: boolean;
  showSnackbar: boolean;
  snackbarMessage: string;
}

const cls: string = CLASS_PREFIX + 'spread';
let successTimeout: number;

export default class Spread extends React.PureComponent<ISpreadProps, IState> {
  constructor(props: ISpreadProps) {
    super(props);

    this.changeEmails = this.changeEmails.bind(this);
    this.changeMessage = this.changeMessage.bind(this);
    this.changeSender = this.changeSender.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.hideSnackbar = this.hideSnackbar.bind(this);

    this.state = {
      emails: '',
      emailsIsValid: true,
      formSubmitted: false,
      isLoading: false,
      message: '',
      sender: props.loggedInUserName || '',
      senderIsValid: true,
      showSnackbar: false,
      snackbarMessage: '',
    };
  }

  public componentWillUnmount() {
    if (successTimeout) {
      clearTimeout(successTimeout);
    }
  }

  public render() {
    const { formSubmitted, showSnackbar, snackbarMessage } = this.state;

    return (
      <>
        {showSnackbar &&
          <SnackBar showClose={true} onClose={this.hideSnackbar}>
            {snackbarMessage}
          </SnackBar>
        }
        {!formSubmitted
          ? this.renderSpreadForm()
          : this.renderSpreadThanks()
        }
      </>
    );
  }

  private renderSpreadForm() {
    const {
      emails,
      emailsIsValid,
      isLoading,
      message,
      sender,
      senderIsValid,
    } = this.state;
    const { profileType, close } = this.props;

    return (
      <MessageBoxWithModal
        isLoading={isLoading}
        bodyTitle={labels.formTitle}
        onClose={close}
        title={textResources.mainMenu.spread}
        whiteBackground
      >
        {labels.formIntro}
        <div className={cls + '__input'}>
          <FieldSet>
            <InputField
              name='emails'
              label={labels.formFieldEmailsPlaceholder}
              onChange={this.changeEmails}
              value={emails}
              valid={emailsIsValid}
              errorText={sharedLabels.formErrorInvalid}
              assistiveText={sharedLabels.formAssistiveText}
              outlined
              required
              type='email'
            />
            {(profileType === INTERMEDIATE_PROFILE_TYPE) && (
              <InputField
                name='sender'
                label={labels.formFieldSenderPlaceholder}
                onChange={this.changeSender}
                value={sender}
                valid={senderIsValid}
                assistiveText={sharedLabels.required}
                outlined
                required
              />
            )}
            <InputField
              name='message'
              label={labels.formFieldMessagePlaceholder}
              onChange={this.changeMessage}
              value={message}
              assistiveText={labels.formOptional}
              outlined
              expandable
            />
          </FieldSet>
        </div>
        <div className={cls + '__button'}>
          <Button
            variant={BUTTON_TYPE_CONTAINED}
            color={COLOR_TYPE_PRIMARY}
            label={labels.formSubmitButton}
            fullWidth={false}
            onClick={this.submitForm}
            lowerCase
          />
        </div>
        <div className={cls + '__share'}>
          <p className={cls + '__share-intro'}>{labels.shareIntro}</p>
          <ShareSegment
            className={cls + '__share-comp'}
            utmSource='spread'
            url={'/'}
          />
        </div>
        <div className={cls + '__footer'}>
          <Button
            variant={BUTTON_TYPE_TEXT}
            fullWidth
            onClick={close}
            label={sharedLabels.cancel}
            lowerCase
          />
        </div>
      </MessageBoxWithModal>
    );
  }

  private renderSpreadThanks() {
    const { close } = this.props;
    const { thanksMessage, thanksTitle } = labels;

    return (
      <MessageSegmentWithModal
        image={'spread-success'}
        title={thanksTitle}
        description={thanksMessage}
        onClose={close}
      />
    );
  }

  private changeEmails(value: string) {
    this.setState({ emails: value, emailsIsValid: true });
  }

  private changeMessage(value: string) {
    this.setState({ message: value });
  }

  private changeSender(value: string) {
    this.setState({ sender: value, senderIsValid: true });
  }

  private submitForm() {
    const { emails, message, sender } = this.state;
    const validEmail = validateEmail(emails, true);
    const validSender = !!sender && isNotEmpty(sender);

    if (!validEmail) {
      this.setState({ emailsIsValid: false });
    }

    if (!validSender) {
      this.setState({ senderIsValid: false });
    }

    if (validEmail && validSender) {
      this.setState({ isLoading: true });
      api.share.create({ emails: emails.split(/,\s*/), personalMessage: message, sender })
        .then(() => {
          this.setState({ formSubmitted: true, isLoading: false });
          successTimeout = window.setTimeout(() => this.props.close(), MESSAGE_BOX_TIMEOUT);
        })
        .catch((error) => {
          const snackbarMessage = error && error.errors && error.errors.full_messages
            ? error.errors.full_messages.join(', ')
            : sharedLabels.genericErrorLong;
          this.setState({ isLoading: false, showSnackbar: true, snackbarMessage });
        });
    }
  }

  private hideSnackbar() {
    this.setState({ showSnackbar: false });
  }
}
