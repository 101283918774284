// interfaces
import { IAction } from 'src/actions/';
import { IRelatedContentItem } from 'src/interfaces/related_content';

// constants
import { RELATED_CONTENT_DEFAULT_PER_PAGE } from 'src/constants/related_content';

// classes
import api from 'src/api/';

const RELATED_CONTENT_REQUEST = 'RELATED_CONTENT_REQUEST';

export const RELATED_CONTENT_REQUEST_PENDING = 'RELATED_CONTENT_REQUEST_PENDING';
export const RELATED_CONTENT_REQUEST_SUCCESS = 'RELATED_CONTENT_REQUEST_SUCCESS';
export const RELATED_CONTENT_REQUEST_ERROR = 'RELATED_CONTENT_REQUEST_ERROR';

export interface IRelatedContentActionPending {
  type: typeof RELATED_CONTENT_REQUEST_PENDING;
}

export interface IRelatedContentActionError {
  type: typeof RELATED_CONTENT_REQUEST_ERROR;
}

export interface IRelatedContentActionSuccess {
  type: typeof RELATED_CONTENT_REQUEST_SUCCESS;
  payload: {
    items: IRelatedContentItem[];
  };
}

export const getRelatedContent = (path: string): IAction => ({
  payload: api.relatedContent.list(path, {
    perPage: RELATED_CONTENT_DEFAULT_PER_PAGE,
  }),
  type: RELATED_CONTENT_REQUEST,
});
