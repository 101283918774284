// libs
import api, { IPasswordResetConfirmParams, IPasswordResetParams } from 'src/api/';

// interfaces / constants
import {
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  INTERMEDIATE_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  PRIVATE_PROFILE_TYPE,
  REPORTER_PROFILE_TYPE,
} from 'src/constants/profile';
import {
  ANONYMOUS_USER_TYPE,
  NO_PROFILE,
  INTERMEDIATE_PROFILE,
  FULL_PROFILE,
  PROFILE_STEP_PROFILE_COMPLETE,
  PROFILE_STEP_EMAIL_CONFIRMED,
  PROFILE_STEP_VERIFIED,
} from 'src/constants/user';
import { Id } from 'src/interfaces';
import { ProfileType } from 'src/interfaces/profile';
import { IUserProfileType } from 'src/interfaces/user';
import { IRootState } from 'src/reducers/interface';

// helpers
import { checkIfProfileIsComplete } from 'src/components/settings/common/validation/profileValidation';

type ProfileStateType = typeof NO_PROFILE | typeof INTERMEDIATE_PROFILE | typeof FULL_PROFILE;

export const stateByProfileType = (type: IUserProfileType): ProfileStateType => {
  switch (type) {
    case AUTHORITY_PROFILE_TYPE:
    case BLOG_PROFILE_TYPE:
    case PRESS_PROFILE_TYPE:
    case PRIVATE_PROFILE_TYPE:
    case REPORTER_PROFILE_TYPE:
      return FULL_PROFILE;
    case INTERMEDIATE_PROFILE_TYPE:
      return INTERMEDIATE_PROFILE;
    case ANONYMOUS_USER_TYPE:
    default:
      return NO_PROFILE;
  }
};

export const isPrivateProfile = (type: ProfileType): boolean =>
  type === PRIVATE_PROFILE_TYPE;

export const isAuthorityProfile = (type: ProfileType): boolean =>
  type === AUTHORITY_PROFILE_TYPE;

export const isPressProfile = (type: ProfileType): boolean =>
  type === PRESS_PROFILE_TYPE;

export const isBlogProfile = (type: IUserProfileType): boolean => type === BLOG_PROFILE_TYPE;

export const isFullProfile = (type: IUserProfileType): boolean =>
  stateByProfileType(type) === FULL_PROFILE;

export const isIntermediateProfile = (type: IUserProfileType): boolean =>
  stateByProfileType(type) === INTERMEDIATE_PROFILE;

export const isIntermediateProfileState = (type: ProfileStateType): boolean =>
  type === INTERMEDIATE_PROFILE;

export const forgotPassword = (params: IPasswordResetParams) => api.me.resetPassword(params);

export const confirmResetPassword = (params: IPasswordResetConfirmParams) =>
  api.me.resetPasswordConfirm(params);

export const idFromGlobalFriendlyIdentifier = (globalFriendlyIdentifier: Id): Id =>
  globalFriendlyIdentifier.substring(globalFriendlyIdentifier.indexOf('#') + 1);

export const getProfileStatus = (state: IRootState) => {
  const user = state.user;
  const userData = user.profiles[user.selectedProfileId];
  const userState = stateByProfileType(userData.type);
  const isProfileComplete = checkIfProfileIsComplete(userData);
  const { confirmed, verified } = user;

  if (userState === NO_PROFILE) {
    return NO_PROFILE;
  }
  if (userState === INTERMEDIATE_PROFILE) {
    if (!isProfileComplete) {
      return PROFILE_STEP_PROFILE_COMPLETE;
    } else if (!confirmed) {
      return PROFILE_STEP_EMAIL_CONFIRMED;
    } else if (!verified) {
      return PROFILE_STEP_VERIFIED;
    }
  }
  if (isFullProfile(userData.type)) {
    return FULL_PROFILE;
  }
  return undefined;
};
