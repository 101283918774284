// libs
import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

// interfaces / constants
import { IAddress } from 'src/interfaces/address';
import {
  MapControlsTracker,
  IPage404Tracker,
  ILandingPageTracker,
  LocationPickerPageTracker,
} from 'src/utils/reporting/events_tracking/events_tracking';

// classes / components
import LocationPicker from 'src/components/location_picker/location_picker';

// helpers
import { useGetAppPosition } from 'src/reducers/app-state/app-state_hooks';
import { replaceLocationSlugFromPath } from 'src/utils/url/url';

interface IProps {
  tracking: MapControlsTracker | IPage404Tracker | ILandingPageTracker | LocationPickerPageTracker;
  showBackground?: boolean;
  onPick?: () => void;
  onClose?: () => void;
}

const AppLocationPicker: React.FC<IProps> = ({
  tracking: { trackingObj, tracker },
  showBackground = true,
  onPick,
  onClose,
}) => {
  const history = useHistory();
  const mapPosition = useGetAppPosition();
  const match = useRouteMatch();

  const getRedirectPath = (locationSlug: string) => replaceLocationSlugFromPath(match, locationSlug);

  const handleAddress = (address: IAddress) => {
    onPick && onPick();
    tracker(trackingObj.ACTIONS.CHANGE_LOCATION, trackingObj.LABELS.START);

    history.push(
      getRedirectPath(address.locationShape.slug),
      { locationShape: address.locationShape },
    );
  };

  const onPopularPlacesSelect = () => {
    onPick && onPick();
    tracker(trackingObj.ACTIONS.SELECT_POPULAR_PLACE, trackingObj.LABELS.START);
  };

  const trackLocateMe = () => {
    onPick && onPick();
    tracker(trackingObj.ACTIONS.LOCATE_ME, trackingObj.LABELS.START);
  };

  return (
    <LocationPicker
      mapPosition={mapPosition}
      onChange={handleAddress}
      showBackground={showBackground}
      autoFocusSearchInput={false}
      onLocateMe={trackLocateMe}
      getRedirectPath={getRedirectPath}
      onPopularPlacesSelect={onPopularPlacesSelect}
      close={onClose}
    />
  );
};

export default AppLocationPicker;
