// libs
import { ThunkAction } from 'redux-thunk';

// interfaces / constants
import { IAction } from 'src/actions/index';
import { IProps as ISnackBarProps } from 'src/components/snack_bar/snack_bar';
import { API_ERROR } from 'src/constants/api';
import { CTA_TYPE_COMPLETE_PROFILE, CTA_TYPE_CONFIRM_EMAIL, CTA_TYPE_VERIFY,
  CTA_TYPE_NEW_USER_INFO } from 'src/constants/cta';
import { FRONTEND_NOT_FOUND_PATH } from 'src/constants/urls';
import {
  MODAL_TYPE_LOGIN,
  MODAL_TYPE_NONE,
  MODAL_TYPE_REGISTRATION,
  ModalType,
  PopupMenu,
  SET_OUTDATED_CLIENT_API_REVISION,
} from 'src/interfaces/app-state';
import { IBoundingBox, ILocationShape, IPosition } from 'src/interfaces/location';
import { IMarker } from 'src/interfaces/marker';
import { IRootState } from 'src/reducers/interface';

// helpers
import api from 'src/api';
import {
  StoredLocationHelper,
} from 'src/utils/location_storage/location_storage';
import GoogleTagManager from 'src/utils/reporting/google-tag-manager';
import { reportError } from 'src/utils/reporting/report-errors';
import { UrlUtils } from 'src/utils/url/url';

export const APP_STATE_SET_LOCATION_SHAPE_IDS = 'APP_STATE_SET_LOCATION_SHAPE_IDS';
export const APP_STATE_SET_POSITION = 'APP_STATE_SET_POSITION';
export const APP_STATE_SET_BOUNDING_BOX = 'APP_STATE_SET_BOUNDING_BOX';
export const APP_STATE_SET_LOCATION_SHAPE = 'APP_STATE_SET_LOCATION_SHAPE';
export const APP_STATE_TOGGLE_TOPBAR_POPUP = 'APP_STATE_TOGGLE_TOPBAR_POPUP';
export const APP_STATE_SET_MARKERS = 'APP_STATE_SET_MARKERS';
export const APP_STATE_MODAL_SHOW = 'APP_STATE_MODAL_SHOW';
export const APP_STATE_CLOSE_TOPBAR_POPUP = 'APP_STATE_CLOSE_TOPBAR_POPUP';
export const APP_STATE_OPEN_TOPBAR_POPUP = 'APP_STATE_OPEN_TOPBAR_POPUP';
export const APP_STATE_SET_NAV_SOURCE = 'APP_STATE_SET_NAV_SOURCE';
export const APP_STATE_UPDATE_CHAT_USERS_COUNT = 'APP_STATE_UPDATE_CHAT_USERS_COUNT';
export const APP_STATE_UPDATE_CHAT_MESSAGES_COUNT = 'APP_STATE_UPDATE_CHAT_MESSAGES_COUNT';
export const APP_STATE_SHOW_SNACK_BAR = 'APP_STATE_SHOW_SNACK_BAR';
export const APP_STATE_HIDE_SNACK_BAR = 'APP_STATE_HIDE_SNACK_BAR';

export interface ISetPositionAction {
  payload: {
    position: IPosition;
    boundingBox?: IBoundingBox;
  };
  type: typeof APP_STATE_SET_POSITION;
}

export interface ISetBoundingBoxAction {
  payload: IBoundingBox;
  type: typeof APP_STATE_SET_BOUNDING_BOX;
}

export interface ISetLocationShapeAction {
  payload: ILocationShape;
  type: typeof APP_STATE_SET_LOCATION_SHAPE;
}

export interface ISetLocationShapeIdsAction {
  payload: string[];
  type: typeof APP_STATE_SET_LOCATION_SHAPE_IDS;
}

export interface ITopbarPopupMenuAction {
  payload: PopupMenu;
  type: typeof APP_STATE_TOGGLE_TOPBAR_POPUP | typeof APP_STATE_OPEN_TOPBAR_POPUP;
}

export interface IUpdateChatUsersCount {
  payload: number | undefined;
  type: typeof APP_STATE_UPDATE_CHAT_USERS_COUNT;
}

export interface IUpdateChatMessagesCount {
  payload: number | undefined;
  type: typeof APP_STATE_UPDATE_CHAT_MESSAGES_COUNT;
}

export interface ICloseTopbarPopupMenuAction {
  type: typeof APP_STATE_CLOSE_TOPBAR_POPUP;
}

export interface ISetMarkersActions {
  payload: IMarker[];
  type: typeof APP_STATE_SET_MARKERS;
}

export interface IShowModalAction {
  payload: ModalType;
  type: typeof APP_STATE_MODAL_SHOW;
}

export interface IHideModalAction {
  payload: typeof MODAL_TYPE_NONE;
  type: typeof APP_STATE_MODAL_SHOW;
}

export interface ICheckOutdatedRevisionAction {
  type: typeof SET_OUTDATED_CLIENT_API_REVISION;
}

export interface ISetNavSourceAction {
  payload: string;
  type: typeof APP_STATE_SET_NAV_SOURCE;
}

export interface IShowSnackBar {
  payload: ISnackBarProps;
  type: typeof APP_STATE_SHOW_SNACK_BAR;
}

export interface IHideSnackBar {
  type: typeof APP_STATE_HIDE_SNACK_BAR;
}

// Caution! Please do NOT use this action.
// Instead please only use changeAppLocation, which will call this action.
const setPositionAction = (position: IPosition, boundingBox?: IBoundingBox): ISetPositionAction => ({
  payload: {
    boundingBox,
    position,
  },
  type: APP_STATE_SET_POSITION,
});

const setLocationShape = (locationShape: ILocationShape): ISetLocationShapeAction => ({
  payload: locationShape,
  type: APP_STATE_SET_LOCATION_SHAPE,
});

export const setLocationShapeIds = (ids: string[]): ISetLocationShapeIdsAction => ({
  payload: ids,
  type: APP_STATE_SET_LOCATION_SHAPE_IDS,
});

export const toggleTopbarPopupMenu = (popupMenu: PopupMenu): ITopbarPopupMenuAction => ({
  payload: popupMenu,
  type: APP_STATE_TOGGLE_TOPBAR_POPUP,
});

export const closeTopbarPopupMenu = (): ICloseTopbarPopupMenuAction => ({
  type: APP_STATE_CLOSE_TOPBAR_POPUP,
});

export const setMarkers = (markers: IMarker[]): ISetMarkersActions => ({
  payload: markers,
  type: APP_STATE_SET_MARKERS,
});

export const showModal = (modalType: ModalType): IShowModalAction => ({
  payload: modalType,
  type: APP_STATE_MODAL_SHOW,
});

export const showLoginModal = () => showModal(MODAL_TYPE_LOGIN);
export const showRegistrationModal = () => showModal(MODAL_TYPE_REGISTRATION);
export const showCompleteProfileCTA = () => showModal(CTA_TYPE_COMPLETE_PROFILE);
export const showConfirmEmailCTA = () => showModal(CTA_TYPE_CONFIRM_EMAIL);
export const showVerifyCTA = () => showModal(CTA_TYPE_VERIFY);
export const showNewUserInfoCTA = () => showModal(CTA_TYPE_NEW_USER_INFO);

export const hideModal = (): IHideModalAction => ({
  payload: MODAL_TYPE_NONE,
  type: APP_STATE_MODAL_SHOW,
});

export const checkOutdatedRevision = (): ICheckOutdatedRevisionAction => ({
  type: SET_OUTDATED_CLIENT_API_REVISION,
});

export const changeAppLocation = (locationShape: ILocationShape,
                                  position?: IPosition,
                                  locationShapeIds?: string[],
                                  saveLocation = true,
                                  boundingBox?: IBoundingBox): ThunkAction<void, IRootState, {}, IAction> => {
  return (dispatch) => {
    if (!position) {
      position = {
        latitude: locationShape.latitude,
        longitude: locationShape.longitude,
      };
    }

    if (!locationShapeIds) {
      locationShapeIds = [locationShape.identifier];
    }

    dispatch(setPositionAction(position, boundingBox));
    dispatch(setLocationShape(locationShape));
    dispatch(setLocationShapeIds(locationShapeIds));

    // report, but don't fail, since we might have some
    // valid cases leftovers that will be migrated.
    if (!(locationShape.boundingBox && locationShape.boundingBox.neLatitude)) {
      reportError('trying to set a shape which wont match ILocationShape interface', {
        locationShape,
      });
    }
    saveLocation && StoredLocationHelper.storeLastLocation(locationShape);
  };
};

export const fetchLocationSlug = (locationSlug: string): ThunkAction<void, IRootState, {}, IAction> => {
  return (dispatch) => {
    return api.locationShape.get(locationSlug)
      .then((locationShape) => {
        if (locationShape) {
          dispatch(changeAppLocation(
            locationShape,
          ));
        }
      }).catch(() => {
        GoogleTagManager.pushEventWithValue(API_ERROR, 'unknown_slug_called', locationSlug);
        // we are hard refreshing, in this case, to let the me endpoint
        // set a valid location which was prevented for localized feeds before in the user_auth container
        UrlUtils.setLocationHref(FRONTEND_NOT_FOUND_PATH);
      });
  };
};

// this actions should not be used anywhere outside of checkToRecalculateBoundingBox()
// react-portal/src/components/map/map.tsx
export const setBoundingBox = (boundingBox: IBoundingBox): ISetBoundingBoxAction => ({
  payload: boundingBox,
  type: APP_STATE_SET_BOUNDING_BOX,
});

export const setNavSource = (navSource: string): ISetNavSourceAction => ({
  payload: navSource,
  type: APP_STATE_SET_NAV_SOURCE,
});

export const showSnackBar = (param: ISnackBarProps) => ({
  payload: param,
  type: APP_STATE_SHOW_SNACK_BAR,
});

export const hideSnackBar = () => ({
  type: APP_STATE_HIDE_SNACK_BAR,
});
