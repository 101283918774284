// lib
import React, { useContext } from 'react';

// components
import HeaderProfile from 'src/components/header/subheaders/pages/profile/header_profile';
import MetaTags from 'src/components/meta_tags/meta_tags';
import ProfileFeed from 'src/containers/smart_components/feeds/profile_feed';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { PROFILE_TITLE, PROFILE_OG_TYPE, PROFILE_PRIVATE_TITLE } from 'src/constants/meta_tags';
import { IProfile } from 'src/interfaces/profile';
import { textResources } from 'src/lang/de';

// helpers
import { URLContext } from 'src/containers/url_provider/url_provider';
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUrlParamProfile } from 'src/reducers/profiles/profiles_hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { isPrivateProfile } from 'src/utils/profile';
import { UrlUtils } from 'src/utils/url/url';

const getPageTitle = (profile: IProfile | undefined): string | undefined => {
  if (!profile) {
    return undefined;
  }
  if (isPrivateProfile(profile.type)) {
    return PROFILE_PRIVATE_TITLE;
  }
  if (profile.name) {
    return PROFILE_TITLE(profile.name);
  }
  return undefined;
};

const ProfileFeedPage: React.FC = () => {
  const user = useSelectUser();
  const profile = useSelectUrlParamProfile();

  const { locationShape, name, description, imageUrl } = profile || {};

  const title = getPageTitle(profile);

  const { getNewsfeedRoute } = useContext(URLContext);

  usePageTracking({ pageTitle: title, user });

  return (
    <>
      {profile && isPrivateProfile(profile.type)
        // we currently do not show any meta tag information for PrivateProfiles
        ? <MetaTags
          title={title}
        />
        : <MetaTags
          author={name}
          canonical={UrlUtils.currentUrlWithHostAndPath()}
          description={description}
          geo_placename={locationShape?.name}
          geo_position={locationShape && `${locationShape.latitude};${locationShape.longitude}`}
          og_image={imageUrl}
          og_url={UrlUtils.currentUrlWithHostAndPath()}
          og_longitude={locationShape?.longitude.toString()}
          og_latitude={locationShape?.latitude.toString()}
          og_type={PROFILE_OG_TYPE}
          profile_username={name}
          title={title}
        />
      }
      <LayoutNoMap
        pageTitle={<StandardPageTitle text={textResources.pageTitles.profile} customTag='p' />}
        subHeader={<HeaderProfile />}
        withHeaderMargin
      >
        <ProfileFeed getNewsfeedRoute={getNewsfeedRoute} />
      </LayoutNoMap>
    </>
  );
};

export default ProfileFeedPage;
