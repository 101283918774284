import { $pagination } from 'src/api/decoders/pagination';
import { $profile } from 'src/api/decoders/profile';

// interfaces & constants
import * as Response from 'src/api/interfaces/responses/mailbox';
import {
  IConversation,
  IConversationsUnreadCount as IUnreadCount,
  IMessage,
  IMessagesPayload,
  IConversationPayload,
} from 'src/interfaces/mailbox';
import { notUndefined } from 'src/utils';
import { $attachment } from './attachment';

// This Endpoint is used for creating a new Conversation between two users.
// If a Conversation already exists, that one is returned instead.
export const $conversationCreate = (json: Response.IConversationResponse): IConversation => {
  return $conversation(json.data);
};

export const $conversations = (json: Response.IConversationsResponse): IConversationPayload => ({
  conversations: json.data.map($conversation),
  meta: $pagination(json.meta),
});

export const $conversationResponse = (json: Response.IConversationResponse): IConversation => {
  return $conversation(json.data);
};

export const $conversation = (json: Response.IConversation): IConversation => {
  return {
    gid: json.gid,
    id: json.identifier,
    latestMessage: $conversationMessage(json.latest_message),
    meta: {
      unreadMessageCount: json.meta.unread_message_count,
    },
    participants: json.participants.map(participant => $profile(participant)),
    updatedAt: new Date(json.updated_at),
  };
};

export const $messageCreate = (json: Response.IMessageResponse): IMessage | undefined => {
  return $conversationMessage(json.data);
};

export const $conversationMessages = (json: Response.IMessagesResponse): IMessagesPayload => {
  return {
    messages: json.data.map($conversationMessage).filter(notUndefined),
    meta: $pagination(json.meta),
  };
};

export const $conversationMessage = (json: Response.IMessage): IMessage | undefined => {
  if (!json) return undefined;
  return {
    attachments: json.attachments.map($attachment),
    author: {
      gid: json.author.gid,
      globalStableIdentifier: json.author.global_stable_identifier,
      id: json.author.identifier,
      type: json.author.type,
    },
    body: json.body ?? undefined,
    conversation: {
      gid: json.conversation.gid,
      id: json.conversation.identifier,
    },
    createdAt: new Date(json.created_at),
    gid: json.gid,
    id: json.identifier,
    linkPreviewUrl: json.link_preview_url,
  };
};

export const $conversationUnreadCount = (json: Response.IConversationsUnreadCountResponse): IUnreadCount => ({
  unreadCount: json.data.unread_count,
});
