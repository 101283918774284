// interfaces / constants
import {
  IEventfeedMergeableParams,
  IEventfeedRequestParams,
} from 'src/actions/feed/feed_interfaces';
import { FILTER_EVENT_DEFAULT } from 'src/constants/feed';
import { IFeedType } from 'src/interfaces/feed';
import { IRootState } from 'src/reducers/interface';
// helpers
import DateFormatter from 'src/utils/date_formatter/date_formatter';

export const getFeedPaginationInfo =
  (state: IRootState, feedType: IFeedType) => state.feed.feeds[feedType].paginationInfo;

export const hasBoundingBox = (state: IRootState): boolean => {
  return !!state.appState.boundingBox;
};

export const hasActiveFilter = (state: IRootState): boolean => {
  return state.filters.activeFilter.id !== FILTER_EVENT_DEFAULT;
};

export const addBoundingBox = (state: IRootState) => ({
  boundingBox: state.appState.boundingBox,
});

export const addCategories = (state: IRootState): Omit<IEventfeedRequestParams, 'ranges'> => {
  const { id } = state.filters.activeFilter;
  // The API expects an empty array for displaying events from all categories
  const categories = id === FILTER_EVENT_DEFAULT ? [] : [id];

  return { categories };
};

export const addRanges = (state: IRootState, params?: IEventfeedMergeableParams) => {
  if (params?.ranges) {
    return { ranges: params.ranges };
  }

  const start = state.filters.dateFilterRange.start?.toString();
  const end = state.filters.dateFilterRange.end?.toString();

  return { ranges: [{ end, start }] };
};

export const addRelatedRanges = (state: IRootState) => {
  const start = state.filters.dateFilterRange.start || DateFormatter.getToday();

  return {
    ranges: [{
      end: undefined,
      start: DateFormatter.getNextDay(start),
    }],
  };
};

export const addCategorizedRanges = (state: IRootState) => {
  const range = addRanges(state).ranges[0];

  // If no end date is provided by user, we use the end of the day
  // Because we only want to show events happening on the selected date
  if (range.end) {
    return { ranges: [range] };
  }

  const endOfDay = range.start && DateFormatter.endOfDay(range.start).toString();

  return {
    ranges: [{ end: endOfDay, start: range.start }],
  };
};
