// libs
import * as React from 'react';
import { connect } from 'react-redux';

// interfaces / constants
import { IRootState } from 'src/reducers/interface';

// components
import LoadingSpinner from 'src/components/loading_spinner/loading_spinner';

interface IProps {
  loginRequestPending?: boolean;
}

const mapStateToProps = (state: IRootState): IProps => ({
  loginRequestPending: state.user.loginRequestPending,
});

const DeferUtilMeFetched: React.FC<IProps> = ({ children, loginRequestPending }) => (
  <>
   {loginRequestPending ? <LoadingSpinner shown={true}/> : children}
  </>
);

export default connect(
  mapStateToProps,
)(DeferUtilMeFetched);
