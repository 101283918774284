// libs
import React, { useState, useCallback, useContext, useEffect } from 'react';

// components
import Avatar from 'src/components/avatar/avatar';
import ShapeSponsorsPlaceholder
  from 'src/components/content_placeholder/layouts/shape_sponsors_placeholder/shape_sponsors_placeholder';
import Icon from 'src/components/forms/icon/icon';
import MessageForm from 'src/components/message_form/message_form';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// constants
import { THUMBNAIL_SIZE_TYPE_SMALL, THUMBNAIL_SIZE_TYPE_LARGE } from 'src/components/thumbnail/thumbnail';
import { COLOR_TYPE_GRAY_TINT_1 } from 'src/utils/color';

// helpers
import { URLContext } from 'src/containers/url_provider/url_provider';
import { textResources } from 'src/lang/de';
import { useGetBoundingBox, useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectProfilesListIsLoading } from 'src/reducers/profiles_list/profiles_list_hooks';
import { useSelectUserMail, useSelectIsUserLoggedIn } from 'src/reducers/user/user_hooks';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { UrlUtils } from 'src/utils/url/url';

import { useGetShapeSponsors } from 'src/actions/shape_sponsors/shape_sponsors_hooks';
import { IShapeSponsorPlacement } from 'src/interfaces/shape_sponsors';
import { useSelectShapeSponsors } from 'src/reducers/shape_sponsors/shape_sponsor_hooks';
import './shape_sponsors_info.scss';

const labels = textResources.shapeSponsors;

const cls = createBemBlock('shape-sponsors-info');

interface IPartnerProps {
  image: string;
  name: string;
  url: string;
}

const SilverPartner: React.FC<IPartnerProps> = ({ image, url, name }) => {
  return (
    <NavigationItem
      className={cls('silver-partner')}
      target='_self'
      url={url}
      title={name}
    >
      <Avatar
        size={THUMBNAIL_SIZE_TYPE_LARGE}
        image={image}
        withBorder
      />
      <p className={cls('silver-partner-name')}>{name}</p>
    </NavigationItem>
  );
};

const BronzePartner: React.FC<IPartnerProps> = ({ url, image, name }) => {
  return (
    <NavigationItem
      target='_self'
      url={url}
      className={cls('bronze-partner')}
      title={name}
    >
      <Avatar
        size={THUMBNAIL_SIZE_TYPE_SMALL}
        image={image}
        withBorder
      />
    </NavigationItem>
  );
};

const BecomeAPartnerButton: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  const isLoggedIn = useSelectIsUserLoggedIn();
  return (
    <UserOnboardingCTA active={!isLoggedIn} className={cls('become-partner-button', 'blocked')}>
      <button onClick={onClick}>
        <Icon
          name='plus-dashed-border'
          size={40}
          color={COLOR_TYPE_GRAY_TINT_1}
        />
      </button>
    </UserOnboardingCTA>
  );
};

const ShapeSponsorsHeader: React.FC<{showShowAllButton?: boolean}> = ({ showShowAllButton = true }) => {
  const { getShapeSponsorsListRoute } = useContext(URLContext);
  return (
    <div className={cls('headline')}>
      <h2 className={cls('headline-header')}>{labels.title}</h2>
      {
        showShowAllButton
          ? <NavigationItem
            url={getShapeSponsorsListRoute()}
            className={cls('headline-show-all')}
          >
            {textResources.shared.showAll}
          </NavigationItem>
          : null
      }
    </div>
  );
};

const ShapeSponsorsInfo = ({ placements }: {placements?: IShapeSponsorPlacement}) => {
  const userEmail = useSelectUserMail();
  const [isFormShown, setShowForm] = useState(false);
  const isLoading = useSelectProfilesListIsLoading();

  const boundingBox = useGetBoundingBox();
  const alternativeBoundingBox = useGetAppLocationShape()?.boundingBox;
  const getLocalPartners = useGetShapeSponsors();

  const { bronze: bronzeProfiles, silver: silverProfiles } = useSelectShapeSponsors(placements);

  useEffect(() => {
    getLocalPartners(boundingBox || alternativeBoundingBox);
  }, [boundingBox, alternativeBoundingBox, getLocalPartners]);

  const showForm = useCallback(() => {
    setShowForm(true);
  }, []);

  const hideForm = useCallback(() => {
    setShowForm(false);
  }, []);

  const showShowAllButton = !(placements && placements.detailPage);

  if (isLoading) {
    return (
      <div className={cls()}>
        <ShapeSponsorsHeader showShowAllButton={showShowAllButton}/>
        <ShapeSponsorsPlaceholder />
      </div>
    );
  }

  if (!bronzeProfiles?.length && !silverProfiles?.length) {
    return null;
  }

  return (
    <div className={cls()}>
      <ShapeSponsorsHeader showShowAllButton={showShowAllButton} />
      {silverProfiles.length > 0 && <div className={cls('silver-partners')}>
        {silverProfiles.slice(0, 3).map((profile) =>
          <SilverPartner
            key={profile.id}
            image={profile.imageUrl}
            name={profile.name}
            url={UrlUtils.getProfilePath(profile.id, profile.type, 'FRONTEND')}
          />)}
      </div>}
      {silverProfiles.length > 0 && bronzeProfiles.length > 0 && <div className={cls('separator')} />}
      {bronzeProfiles.length > 0 &&
      <div className={cls('bronze-partners')}>
        {bronzeProfiles.slice(0, 6).map((profile) =>
          <BronzePartner
            key={profile.id}
            image={profile.imageUrl}
            name={profile.name}
            url={UrlUtils.getProfilePath(profile.id, profile.type, 'FRONTEND')}
          />)}
        <BecomeAPartnerButton onClick={showForm} />
      </div>}
      {isFormShown &&
      <MessageForm
        body={labels.formBody}
        title={labels.formTitle}
        close={hideForm}
        loggedInUserEmail={userEmail}
        placeholder={labels.inputPlaceholder}
        successTitle={labels.formSuccessTitle}
        successBody={labels.formSuccessBody}
        successImage='lopo'
        // doesen't need to be in translation since its an internal string used by the operations team
        additionalParams=' Anfrage: Partner hinzufügen'
      />
      }
    </div>
  );
};

export default ShapeSponsorsInfo;
