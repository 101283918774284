// libs
import classnames from 'classnames';
import * as React from 'react';
import Lightbox from 'react-image-lightbox';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// styles
import './darkbox.scss';

interface IProps {
  className?: string;
  images: string[];
}

interface IState {
  isOpen: boolean;
  photoIndex: number;
}

const cls = CLASS_PREFIX + 'darkbox';

export class WithLightbox extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.showBox = this.showBox.bind(this);
    this.hideBox = this.hideBox.bind(this);
    this.onMoveNextRequest = this.onMoveNextRequest.bind(this);
    this.onMovePrevRequest = this.onMovePrevRequest.bind(this);

    this.state = {
      isOpen: false,
      photoIndex: 0,
    };
  }

  public render() {
    const { images, className, children } = this.props;
    const { isOpen, photoIndex } = this.state;
    const nextSrc = images.length > 1 ? images[(photoIndex + 1) % images.length] : undefined;
    const prevSrc = images.length > 1 ? images[(photoIndex + images.length - 1) % images.length] : undefined;

    return (
      <div
        className={classnames([className, cls])}
        onClick={this.showBox}
      >
        {children}
        {isOpen && (
          <Lightbox
            wrapperClassName={`${cls}`}
            mainSrc={images[photoIndex]}
            nextSrc={nextSrc}
            prevSrc={prevSrc}
            onMoveNextRequest={this.onMoveNextRequest}
            onMovePrevRequest={this.onMovePrevRequest}
            onCloseRequest={this.hideBox}
          />
        )}
      </div>
    );
  }

  public componentWillUnmount() {
    document.body.removeEventListener('touchmove', this.freeze);
  }

  private showBox() {
    this.setState({ isOpen: true });
    document.body.addEventListener('touchmove', this.freeze, { passive: false });
  }

  private hideBox() {
    this.setState({ isOpen: false, photoIndex: 0 });
    document.body.removeEventListener('touchmove', this.freeze);
  }

  private onMovePrevRequest() {
    const { images } = this.props;
    const { photoIndex } = this.state;
    this.setState({ photoIndex: (photoIndex + images.length - 1) % images.length });
  }

  private onMoveNextRequest() {
    const { images } = this.props;
    const { photoIndex } = this.state;
    this.setState({ photoIndex: (photoIndex + 1) % images.length });
  }

  private freeze(e: Event) {
    e.preventDefault();
  }
}
