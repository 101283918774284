import * as decoder from 'src/api/google/decoders';
import * as encoder from 'src/api/google/encoders';

import * as Request from 'src/api/google/interfaces/requests';
import * as Response from 'src/api/google/interfaces/responses';
import { IPosition } from 'src/interfaces/location';
import { IGooglePrediction } from 'src/interfaces/map';
import { reportError } from 'src/utils/reporting/report-errors';

// third party lib, not existing in our codebase
const google = (window as any).google;

interface IGoogleAutoCompleteService {
  getPlacePredictions: (
    query: Request.IAutocomplete,
    callback: (predictions: Response.ILocationPrediction[], status: string) => void) => any;
}

let autocompleteService: IGoogleAutoCompleteService;

const initAutocompleteService = () => {
  try {
    autocompleteService = new google.maps.places.AutocompleteService();

    return autocompleteService;
  } catch (exception) {
    reportError('Google autocomplete service is not available', { exception });
    return undefined;
  }
};

const isResponseOk = (status: string): boolean => {
  const { OK, ZERO_RESULTS, NOT_FOUND } = google.maps.places.PlacesServiceStatus;
  const okStatusList: string[] = [OK, ZERO_RESULTS, NOT_FOUND];

  if (okStatusList.indexOf(status) !== -1) {
    return true;
  }

  reportError('Google service responded with error.', { status });
  return false;
};

type PredictionsCallback = (predictions: IGooglePrediction[] | null) => any;

export interface IAutocompleteParams {
  query: string;
  position?: IPosition;
  radius?: number;
  isStrict?: boolean;
  type?: Request.IAutocompleteTypes;
}

export default {
  autocomplete: {
    list:
      (params: IAutocompleteParams, callback: PredictionsCallback) => {
        const service = autocompleteService || initAutocompleteService();
        return service.getPlacePredictions(
          encoder.$googleAutocompletion(params),
          (predictions: Response.ILocationPrediction[] | null, status: string) => {
            if (isResponseOk(status)) {
              if (!predictions) {
                return callback(null);
              }

              callback(predictions.map(decoder.$prediction));
            }
          },
        );
      },
  },
};
