// interfaces / constants
import { IAction } from 'src/actions/';

// api
import api, { ICampaignCreate } from 'src/api/';

export const CAMPAIGN_RESET = 'CAMPAIGN_RESET';
export const CAMPAIGN_CREATE_REQUEST = 'CAMPAIGN_CREATE_REQUEST';
export const CAMPAIGN_CREATE_REQUEST_PENDING = 'CAMPAIGN_CREATE_REQUEST_PENDING';
export const CAMPAIGN_CREATE_REQUEST_SUCCESS = 'CAMPAIGN_CREATE_REQUEST_SUCCESS';
export const CAMPAIGN_CREATE_REQUEST_ERROR = 'CAMPAIGN_CREATE_REQUEST_ERROR';

const create = (config: ICampaignCreate, postIdentifier: string): IAction => {
  return {
    meta: {
      identifier: postIdentifier,
    },
    payload: api.campaign.create(config),
    type: CAMPAIGN_CREATE_REQUEST,
  };
};

export const createCampaign = (campaignCreationUrl: string, amount: string, postIdentifier: string): IAction => {
  const config = {
    data: { ad_campaign: { amount } },
    path: `${campaignCreationUrl}/campaigns`,
  };
  return create(config, postIdentifier);
};

export const resetCampaign = (): IAction => {
  return {
    payload: {},
    type: CAMPAIGN_RESET,
  };
};
