import api, { IGeoLookupParams } from 'src/api/';
import { IAddress } from 'src/interfaces/address';
import { IPosition, ILocationShape } from 'src/interfaces/location';
import { reportOnlyErrorObject } from 'src/utils/reporting/report-errors';

const defaultOptions: PositionOptions = {
  enableHighAccuracy: true,
  maximumAge: 0,
  timeout: 5000,
};

export const getShapeFromPosition = async(position: IPosition): Promise<ILocationShape | null> => {
  const param: IGeoLookupParams = {
    position,
  };
  return api.address.getAddressBy(param)
    .then((addresses: IAddress[]) => {
      if (!addresses.length) {
        reportOnlyErrorObject({}, { description: 'failed to fetch Location' });
        return null;
      }

      return addresses[0].locationShape;
    })
    .catch((reason: Error) => {
      reportOnlyErrorObject(reason, { description: 'failed to fetch Location' });
      return null;
    });
};

export const getBrowserPosition = (
  success: PositionCallback,
  error?: (err: PositionError | Error) => void,
  options: PositionOptions = defaultOptions,
): void => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      success,
      error,
      options,
    );
  } else {
    error && error(new Error('Browser dont support navigator.geolocation'));
  }
};

// Calculation from leaflet.
export const distance = (position1: IPosition, position2: IPosition) => {
  const r = 6371000;
  const rad = Math.PI / 180;
  const lat1 = position1.latitude * rad;
  const lat2 = position2.latitude * rad;

  const a = Math.sin(lat1) * Math.sin(lat2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.cos((position2.longitude - position1.longitude) * rad);

  return r * Math.acos(Math.min(a, 1));
};
