// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

import './masonry.scss';

const cls: string = CLASS_PREFIX + 'masonry';
const rebounceTime: number = 200;
declare type MasonryColumnType = HTMLElement[];

interface IMasonryProps {
  firstElementOnMobileWithoutMargin?: boolean;
  children?: any;
}

export default class Masonry extends React.PureComponent<IMasonryProps, {}> {
  private masonry: HTMLElement;
  private timeoutId: number | null = null;
  private _debouncedResizeEvent: EventListenerObject;

  constructor(props: IMasonryProps) {
    super(props);
    this.refAssignment = this.refAssignment.bind(this);
  }

  public componentDidMount() {
    this.forceUpdate();
    this._debouncedResizeEvent = this.debouncedResize.bind(this);
    window.addEventListener('resize', this._debouncedResizeEvent);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this._debouncedResizeEvent);
    this.clearTimeoutIfExists();
  }

  public render() {
    return (
      <div className={cls} ref={this.refAssignment}>
        {this.masonry ? this.renderChildrenAsColumns() : null}
      </div>
    );
  }

  private refAssignment(ref: HTMLDivElement) {
    this.masonry = ref;
  }

  private renderChildrenAsColumns() {
    return this.mapChildrenToColumns().map(this.renderColumn);
  }

  private mapChildrenToColumns() {
    const col: MasonryColumnType[] = [];
    const computedStyles = getComputedStyle(this.masonry);
    let columnCount: number = parseInt(computedStyles.columnCount, 10);

    // sets default to still work
    if (isNaN(columnCount)) {
      columnCount = 1;
    }

    // create empty array for each column
    for (let i: number = 0; i < columnCount; i++) {
      col.push([]);
    }

    // group all children equally into columns
    return this.props.children.reduce((previous: MasonryColumnType[], current: HTMLElement, index: number) => {
      previous[index % columnCount].push(current);
      return previous;
    }, col);
  }

  private renderChild = (element: HTMLElement, childIndex: number) =>
    (<div className={`${cls}__tile`} key={childIndex}>{element}</div>)

  private renderColumn = (children: HTMLElement[], columnIndex: number) => {
    return (
      <div className={`${cls}__column`} key={columnIndex}>
        {children.map(this.renderChild)}
      </div>
    );
  }

  private debouncedResize() {
    if (this.timeoutId) {
      this.clearTimeoutIfExists();
    }

    this.timeoutId = window.setTimeout(() => {
      this.forceUpdate();
      this.clearTimeoutIfExists();
    }, rebounceTime);
  }

  private clearTimeoutIfExists() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
