// lib
import React from 'react';
import { useParams } from 'react-router';

// components
import PageTitle from 'src/components/header/main/page_title/page_title';

// helpers
import { textResources } from 'src/lang/de';
import { useGetGroupById } from 'src/reducers/groups/groups_hooks';

const PageTitleGroupFeed: React.FC = () => {
  const { id } = useParams();
  const group = useGetGroupById(id);

  return (
    <PageTitle
      text={group ? textResources.pageTitles.groupFeed(group.name) : ''}
    />
  );
};

export default PageTitleGroupFeed;
