export const REGEXP_EMAIL: RegExp = /^[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]+$/i;
export const REGEXP_EMAILS: RegExp =
  /^(?:[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]+,\s*)*[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]+$/i;

export const REGEXP_EMAIL_GLOBAL: RegExp = /[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]+/gi;
export const REGEXP_URL: RegExp =
  /((https?\:\/\/)|(www\.))[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,7}\b([-a-zA-Z0-9@:%_\+.~#?&//=,;]*)/gi;
/* REGEXP_WEBSITE validates the website of a profile.
 Contrary to REGEXP_URL, it doesn't require www or http://, because we automatically
 add http:// when the url provided doesn't contain it.
 */
export const REGEXP_WEBSITE: RegExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i;
export const REGEXP_LOCK_MAP_PAGE: RegExp = /^\/((posts|profiles|events)\/[a-z0-9\-]{2,})/;
export const REGEXP_DETAIL_PAGE: RegExp = /^\/posts|events\/[a-z0-9\-]{2,}/;
export const REGEXP_POST_DETAIL_PAGE: RegExp = /^\/posts\/[a-z0-9\-]{2,}/;

export const REGEXP_INVALID_PHONE: RegExp = /[^\d\+\s()-\/]/;
