// libs
import classNames from 'classnames';
import * as React from 'react';

// styles
import './checkbox.scss';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IKeyValue } from 'src/interfaces/';

interface IProps {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  label?: string;
  onClick?: () => void;
}

const clsBlock: string = CLASS_PREFIX + 'checkbox';
const Checkbox = ({ checked, label, className, disabled, onClick }: IProps) => {
  const cls: IKeyValue = {};

  cls[clsBlock] = true;
  cls[`${clsBlock}--checked`] = checked;
  cls[`${clsBlock}--disabled`] = disabled;

  return (
    <label className={classNames(cls)} onClick={onClick}>
      <span className={classNames(`${clsBlock}__wrapper`, className)}>
        <span className={`${clsBlock}__box-outline`}/>
        { label &&
          <span className={`${clsBlock}__label`}>{label}</span>
        }
      </span>
    </label>
  );
};

export default Checkbox;
