import { checkOutdatedRevision } from 'src/actions/app-state/app-state';
import { ILopoCore } from 'src/interfaces/index';
import { getStore } from 'src/store/store-holder';

const checkOutdatedRevisionMiddleware = (apiVersion: string | null) => {
  setTimeout(() => {
    const lopoCore = (window as unknown as ILopoCore).lopo_core;
    if (!apiVersion) {
      return;
    }
    if (!lopoCore.ver) {
      lopoCore.ver = apiVersion;
      return;
    }
    if (lopoCore.ver !== apiVersion) {
      // for reporting, keep both versions.
      lopoCore.oldVer = lopoCore.ver;
      lopoCore.ver = apiVersion;
      getStore().dispatch(checkOutdatedRevision());
    }
  }, 0);
};

export default checkOutdatedRevisionMiddleware;
