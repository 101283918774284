// libs
import React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// components
import { BUTTON_TYPE_ROUND } from 'src/components/forms/button/button';
import CustomizedButton, { FLOATING_BUTTON } from 'src/components/forms/button/customized_button';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import './floating_button.scss';

const cls: string = CLASS_PREFIX + 'floating-button';

export interface IProps {
  image: string;
  onClick: () => void;
  title: string;
  remAboveBottom?: number;
  ref?: React.RefObject<HTMLDivElement>;
  featureBlocked?: boolean;
}

const FloatingButton = React.forwardRef<HTMLDivElement, IProps>((
  {
    image,
    onClick,
    title,
    remAboveBottom,
    children,
    featureBlocked = false,
  }, ref: React.RefObject<HTMLDivElement>) => {
  const style = remAboveBottom ? { bottom: `${remAboveBottom}rem` } : {};
  return (
    <div ref={ref} style={style} className={cls}>
      <UserOnboardingCTA active={featureBlocked} >
        {children}
        <CustomizedButton
          backgroundImage={image}
          variant={BUTTON_TYPE_ROUND}
          onClick={onClick}
          title={title}
          customType={FLOATING_BUTTON}
        />
      </UserOnboardingCTA>
    </div>
  );
});

export default FloatingButton;
