// interfaces
import * as Response from 'src/api/interfaces/responses/news_feed_highlights';
import { Id } from 'src/interfaces';
import { IFeedDecoderResults } from 'src/interfaces/feed';
import { IPostResponse } from 'src/interfaces/posts';

// utils
import { $post } from 'src/api/decoders/post';
import { normalizeFeed } from 'src/utils/normalization';

type INewsFeedHighlightsPostsDecoderResults = Partial<Pick<IFeedDecoderResults, 'profiles' | 'posts' | 'reactions'>>;

export interface INewsFeedHighlightsDecodersResults extends INewsFeedHighlightsPostsDecoderResults {
  mostInteractedPost?: Id;
  mostViewedPost?: Id;
  recentFolloweePost?: Id;
  recentGroupPost?: Id;
};

export const $newsFeedHighlights = (json: Response.INewsFeedHighlights): INewsFeedHighlightsDecodersResults => {
  if (!json.data) {
    return {};
  }

  const { most_interacted, most_viewed, recent_followee_posts, recent_group_posts } = json.data;
  const mostInteractedPost = most_interacted && $post(most_interacted.data[0]);
  const mostViewedPost = most_viewed && $post(most_viewed.data[0]);
  const recentFolloweePost = recent_followee_posts && $post(recent_followee_posts.data[0]);
  const recentGroupPost = recent_group_posts && $post(recent_group_posts.data[0]);

  let postsList: IPostResponse[] = [];
  mostInteractedPost && postsList.push(mostInteractedPost);
  mostViewedPost && postsList.push(mostViewedPost);
  recentFolloweePost && postsList.push(recentFolloweePost);
  recentGroupPost && postsList.push(recentGroupPost);

  const { map, reactionsList, authors } = normalizeFeed(postsList);

  return {
    mostInteractedPost: mostInteractedPost?.post.id,
    mostViewedPost: mostViewedPost?.post.id,
    posts: map,
    profiles: authors,
    reactions: reactionsList,
    recentFolloweePost: recentFolloweePost?.post.id,
    recentGroupPost: recentGroupPost?.post.id,
  };
};
