import {
  FOLLOW,
  FOLLOWEE_POST,
  FOLLOWEE_EVENT,
  BOOKMARK,
  COMMENT,
  MESSAGE,
  UNKNOWN,
  GROUP_POST,
  EVENT_PARTICIPATION,
  EVENT,
  POST,
  RECOMMENT,
  GROUP_ACCESS_CONFIRMATION,
} from 'src/constants/notifications';
import {
  AUTHORITY_PROFILE_TYPE,
  BLOG_PROFILE_TYPE,
  PRESS_PROFILE_TYPE,
  REPORTER_PROFILE_TYPE,
} from 'src/constants/profile';
import { IIdentifiable } from 'src/interfaces/';
import { ReactionName } from 'src/interfaces/reactions';
import { IResourceLink } from 'src/interfaces/resource_links';

export type NotificationEventCode =
    | typeof BOOKMARK
    | typeof COMMENT
    | typeof RECOMMENT
    | typeof MESSAGE
    | typeof GROUP_POST
    | typeof EVENT_PARTICIPATION
    // | 'flag'                      // TODO: NYI by frontend
    // | 'group_access_confirmation' // TODO: NYI by frontend
    // | 'view_count'                // TODO: NYI by frontend
    // we use this event code to handle all unknown notifications
    | typeof UNKNOWN
    | typeof GROUP_ACCESS_CONFIRMATION
    | FollowEventCodes
  ;

export type Notification =
  IMessageNotification |
  ICommentNotification |
  IRecommentNotification |
  IBookmarkNotification |
  IGroupNotification |
  IFollowNotification |
  IEventParticipationNotification |
  IGroupAccessConfirmationNotification |
  IUnknownNotification;

export interface INotification extends IIdentifiable {
  body?: string;
  contextTeaser?: string;
  createdAt: string;
  eventCode: NotificationEventCode;
  initiator?: INotificationInitiator;
  isRead: boolean;
  resourceLink?: IResourceLink;
}

export interface INotificationInitiator {
  image: string;
  name?: string;
}

export const bookmarkableTypes = {
  authority_profile: AUTHORITY_PROFILE_TYPE,
  blog_profile: BLOG_PROFILE_TYPE,
  comment: COMMENT,
  event: EVENT,
  post: POST,
  press_profile: PRESS_PROFILE_TYPE,
  reporter_profile: REPORTER_PROFILE_TYPE,
} as const;

type BookmarkableType = keyof typeof bookmarkableTypes;

export interface IBookmarkNotification extends INotification {
  bookmarkableType: BookmarkableType;
  bookmarkType: ReactionName;
  eventCode: typeof BOOKMARK;
}

export const commentableTypes = {
  event: EVENT,
  post: POST,
} as const;

type CommentableType = keyof typeof commentableTypes;

export interface ICommentNotification extends INotification {
  commentableType: CommentableType;
  eventCode: typeof COMMENT | typeof RECOMMENT;
}

export interface IRecommentNotification extends INotification {
  commentableType: CommentableType;
  eventCode: typeof RECOMMENT;
}

export interface IMessageNotification extends INotification {
  eventCode: typeof MESSAGE;
}

export interface IGroupNotification extends INotification {
  eventCode: typeof GROUP_POST;
  groupName: string;
}

export interface IGroupAccessConfirmationNotification extends INotification {
  eventCode: typeof GROUP_ACCESS_CONFIRMATION;
}

export type FollowEventCodes = typeof FOLLOW | typeof FOLLOWEE_POST | typeof FOLLOWEE_EVENT;

export interface IFollowNotification extends INotification {
  eventCode: FollowEventCodes;
}

export interface IEventParticipationNotification extends INotification {
  eventCode: typeof EVENT_PARTICIPATION;
}

export interface IUnknownNotification extends INotification {
  eventCode: typeof UNKNOWN;
}

export type NotificationTraitType = 'seen' | 'read';
