import produce from 'immer';
import { uniqBy } from 'lodash';

import {
  IAppState,
  MODAL_TYPE_NONE,
  SET_OUTDATED_CLIENT_API_REVISION,
  POPUP_TYPE_NONE,
  INITIAL_SNACK_BAR_STATE,
} from 'src/interfaces/app-state';

import {
  ICheckOutdatedRevisionAction,
  ICloseTopbarPopupMenuAction,
  IHideModalAction,
  ISetMarkersActions,
  ISetBoundingBoxAction,
  ISetLocationShapeAction,
  ISetLocationShapeIdsAction,
  ISetPositionAction,
  IShowModalAction,
  ITopbarPopupMenuAction,
  ISetNavSourceAction,
  IUpdateChatUsersCount,
  IUpdateChatMessagesCount,
  IShowSnackBar,
  IHideSnackBar,
  APP_STATE_CLOSE_TOPBAR_POPUP,
  APP_STATE_MODAL_SHOW,
  APP_STATE_OPEN_TOPBAR_POPUP,
  APP_STATE_SET_LOCATION_SHAPE,
  APP_STATE_SET_LOCATION_SHAPE_IDS,
  APP_STATE_SET_POSITION,
  APP_STATE_SET_MARKERS,
  APP_STATE_TOGGLE_TOPBAR_POPUP,
  APP_STATE_SET_BOUNDING_BOX,
  APP_STATE_SET_NAV_SOURCE,
  APP_STATE_UPDATE_CHAT_USERS_COUNT,
  APP_STATE_UPDATE_CHAT_MESSAGES_COUNT,
  APP_STATE_SHOW_SNACK_BAR,
  APP_STATE_HIDE_SNACK_BAR,
} from 'src/actions/app-state/app-state';
import { FeedAction } from 'src/actions/feed/feed';

export const INITIAL_STATE: IAppState = {
  activePopupMenu: POPUP_TYPE_NONE,
  boundingBox: undefined,
  isCacheOutdated: false,
  locationShape: undefined,
  locationShapeIds: undefined,
  markers: [],
  modal: MODAL_TYPE_NONE,
  navSource: undefined,
  position: undefined,
  snackBar: { ...INITIAL_SNACK_BAR_STATE },
};

type Actions = ICheckOutdatedRevisionAction
| ICloseTopbarPopupMenuAction
| FeedAction
| IHideModalAction
| ISetMarkersActions
| ISetLocationShapeAction
| ISetLocationShapeIdsAction
| ISetPositionAction
| ISetBoundingBoxAction
| IShowModalAction
| ITopbarPopupMenuAction
| ISetNavSourceAction
| IUpdateChatUsersCount
| IUpdateChatMessagesCount
| IShowSnackBar
| IHideSnackBar
;

const appStateReducer = (state = INITIAL_STATE, action: Actions): IAppState =>
  produce(state, (draft) => {
    switch (action.type) {
      case APP_STATE_SET_LOCATION_SHAPE_IDS:
        draft.locationShapeIds = action.payload;
        break;

      case APP_STATE_SET_POSITION:
        draft.position = action.payload.position;
        draft.boundingBox = action.payload.boundingBox;
        break;

      case APP_STATE_SET_BOUNDING_BOX:
        draft.boundingBox = action.payload;
        break;

      case APP_STATE_SET_LOCATION_SHAPE:
        draft.locationShape = action.payload;
        break;

      case APP_STATE_SET_MARKERS:
        draft.markers = uniqBy(action.payload, (marker) => marker.id);
        break;

      case APP_STATE_TOGGLE_TOPBAR_POPUP:
        draft.activePopupMenu = action.payload === draft.activePopupMenu ? POPUP_TYPE_NONE : action.payload;
        break;

      case APP_STATE_OPEN_TOPBAR_POPUP:
        draft.activePopupMenu = action.payload;
        break;

      case APP_STATE_CLOSE_TOPBAR_POPUP:
        draft.activePopupMenu = POPUP_TYPE_NONE;
        break;

      case APP_STATE_MODAL_SHOW:
        draft.modal = action.payload;
        break;

      case SET_OUTDATED_CLIENT_API_REVISION:
        draft.isCacheOutdated = true;
        break;
      case APP_STATE_SET_NAV_SOURCE:
        draft.navSource = action.payload;
        break;

      case APP_STATE_UPDATE_CHAT_USERS_COUNT:
        draft.chatUsersCount = action.payload;
        break;
      case APP_STATE_UPDATE_CHAT_MESSAGES_COUNT:
        draft.chatMessagesCount = action.payload;
        break;
      case APP_STATE_SHOW_SNACK_BAR:
        draft.snackBar = { ...action.payload };
        break;
      case APP_STATE_HIDE_SNACK_BAR:
        draft.snackBar = { ...INITIAL_SNACK_BAR_STATE };
        break;
    }
  });

export default appStateReducer;
