// libs
import * as React from 'react';
import { connect } from 'react-redux';

// interfaces / constants
import { ActivableNavigationItems } from 'src/components/header/navigation/header_navigation';
import { POPUP_TYPE_MAP } from 'src/interfaces/app-state';
import { IRootState } from 'src/reducers/interface';

// components
import MapContainer, { IMapSettings } from 'src/containers/map';
import LayoutBase from 'src/layout/base/layout_base';

// helpers

export interface LayoutMapContainerProps {
  subHeader?: JSX.Element;
  activeNavigationItem?: ActivableNavigationItems;
  mapSettings?: IMapSettings;
  pageTitle: JSX.Element;
  withHeaderMargin?: boolean;
}

interface IStateToProps {
  isMapShown: boolean;
  loginRequestPending?: boolean;
  loggedIn: boolean;
  profileType: any;
  showBanner: boolean;
}

type IProps = LayoutMapContainerProps & IStateToProps;

const mapStateToProps = ({ appState, user }: IRootState): IStateToProps => {
  return {
    isMapShown: appState.activePopupMenu === POPUP_TYPE_MAP,
    loggedIn: user.loggedIn,
    loginRequestPending: user.loginRequestPending,
    profileType: user.profiles[user.selectedProfileId].type,
    showBanner: !user.loggedIn,
  };
};

const LayoutMap: React.FC<IProps> = ({
  activeNavigationItem,
  subHeader,
  isMapShown,
  children,
  showBanner,
  mapSettings,
  pageTitle,
  withHeaderMargin = false,
}) => {
  return (
    <LayoutBase
      subHeader={subHeader}
      activeNavigationItem={activeNavigationItem}
      isMapShown={isMapShown}
      showBanner={showBanner}
      pageTitle={pageTitle}
      withHeaderMargin={withHeaderMargin}
      sideBarContent={<MapContainer settings={mapSettings} /> }
      enableMapToggler={true}
    >
      {children}
    </LayoutBase>
  );
};
export default connect<IStateToProps, undefined, LayoutMapContainerProps>(
  mapStateToProps,
)(LayoutMap);
