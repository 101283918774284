// libs
import produce from 'immer';

// interfaces / constants
import {
  IFetchNotificationsActionError,
  IFetchNotificationsActionPending,
  IFetchNotificationsActionSuccess,
  IGetUnseenCountsActionSuccess,
  IMarkNotificationReadActionSuccess,
  IMarkNotificationsSeenActionSuccess,
  NOTIFICATIONS_FETCH_NOTIFICATIONS_ERROR,
  NOTIFICATIONS_FETCH_NOTIFICATIONS_PENDING,
  NOTIFICATIONS_FETCH_NOTIFICATIONS_SUCCESS,
  NOTIFICATIONS_GET_UNSEEN_COUNTS_SUCCESS,
  NOTIFICATIONS_MARK_READ_SUCCESS,
  NOTIFICATIONS_MARK_SEEN_SUCCESS,
} from 'src/actions/notification/notification';
import { Notification } from 'src/interfaces/notification';
import { IPagination } from 'src/interfaces/pagination';

interface INotificationMap {
  // NB: this string is a unique notification id we get from the API
  [notificationIdentifier: string]: Notification | undefined;
}

interface INotificationData {
  // NB: this string is the notificationIdentifier from INotificationMap;
  //     the array contains the notification ids in the desired order
  items: string[];
  unseenCount?: number;
}

export interface IProfileNotificationDataMap {
  // NB: this string is the longIdentifier from IUserProfile
  [profileIdentifier: string]: INotificationData | undefined;
}

export interface INotifications {
  byId: INotificationMap;
  forProfile: IProfileNotificationDataMap;
  isLoading: boolean;
  padding: number;
  pagination: IPagination;
}

export const INITIAL_STATE: INotifications = {
  byId: {},
  forProfile: {},
  isLoading: false,
  padding: 0,
  pagination: {
    currentPage: 0,
    lastPage: false,
    total: 0,
    totalPages: 0,
  },
};

type Actions =
    IFetchNotificationsActionError
    | IFetchNotificationsActionPending
    | IFetchNotificationsActionSuccess
    | IGetUnseenCountsActionSuccess
    | IMarkNotificationReadActionSuccess
    | IMarkNotificationsSeenActionSuccess
  ;

const notificationsReducer = (state = INITIAL_STATE, action: Actions): INotifications =>
  produce(state, (draft) => {
    switch (action.type) {
      case NOTIFICATIONS_FETCH_NOTIFICATIONS_PENDING:
        draft.isLoading = true;
        break;

      case NOTIFICATIONS_FETCH_NOTIFICATIONS_ERROR:
        draft.isLoading = false;
        break;

      case NOTIFICATIONS_FETCH_NOTIFICATIONS_SUCCESS:
        draft.isLoading = false;

        action.payload.notifications.forEach((notification) => {
          draft.byId[notification.id] = notification;
        });

        checkForProfileIdentifier(draft, action.meta.profileIdentifier);

        const currentNotifications = draft.forProfile[action.meta.profileIdentifier]!.items;
        const newNotifications =
          action.payload.notifications.map((notification) => notification.id)
            .filter((id) => currentNotifications.indexOf(id) === -1);

        if (action.meta.prepend) {
          draft.padding = draft.padding + action.payload.notifications.length;

          draft.forProfile[action.meta.profileIdentifier]!.items = [
            ...newNotifications,
            ...currentNotifications,
          ];
        } else {
          draft.pagination = action.payload.pagination;

          draft.forProfile[action.meta.profileIdentifier]!.items = [
            ...currentNotifications,
            ...newNotifications,
          ];
        }
        break;

      case NOTIFICATIONS_MARK_READ_SUCCESS:
        if (action.meta && action.meta.notificationId) {
          if (draft.byId[action.meta.notificationId]) {
            draft.byId[action.meta.notificationId]!.isRead = true;
          }
        }
        break;

      case NOTIFICATIONS_MARK_SEEN_SUCCESS:
        const profileID = action.meta.profileIdentifier;
        const numberOfNotifications = action.meta.notificationIds.length;
        const unseenCount = draft.forProfile[profileID]?.unseenCount!;
        if (draft.forProfile[profileID] && unseenCount) {
          const nextUnseenCount = unseenCount - numberOfNotifications;
          draft.forProfile[profileID]!.unseenCount = nextUnseenCount >= 0 ? nextUnseenCount : 0;
        }
        break;

      case NOTIFICATIONS_GET_UNSEEN_COUNTS_SUCCESS:
        Object.keys(action.payload).forEach((profileIdentifier) => {
          if (action.payload[profileIdentifier] !== undefined) {
            checkForProfileIdentifier(draft, profileIdentifier);
            draft.forProfile[profileIdentifier]!.unseenCount = action.payload[profileIdentifier];
          }
        });
        break;
    }
  });

const createDefaultNotificationData = (): INotificationData => ({
  items: [],
});

const checkForProfileIdentifier = (state: INotifications, id: string) => {
  if (!state.forProfile[id]) {
    state.forProfile[id] = createDefaultNotificationData();
  }
};

export default notificationsReducer;
