// interfaces
import { IOAuth2AccessTokenResponse } from 'src/api/interfaces/responses/oauth2';
import { IOAuth2Credentials } from 'src/interfaces/oauth2';

export const $oauth2Credentials = (json: IOAuth2AccessTokenResponse): IOAuth2Credentials => ({
  accessToken: json.access_token,
  createdAt: json.created_at,
  expiresIn: json.expires_in,
  refreshToken: json.refresh_token,
});
