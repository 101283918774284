// libs
import { History } from 'history';

// interfaces / constants
import { PromiseMiddlewareActions } from 'src/actions/';
import { PROFILES_LIST_GET_PROFILES } from 'src/actions/profiles_list/profiles_list';
import { IErrorResponse } from 'src/api/interfaces/errors';
import { RECORD_NOT_FOUND } from 'src/constants/api_error_codes';
import { FRONTEND_NOT_FOUND_PATH } from 'src/constants/urls';

// helpers
import api, { IEventParticipationListParams } from 'src/api/index';
import { Id } from 'src/interfaces';

const EVENT_PARTICIPATION_CREATE_REQUEST = 'EVENT_PARTICIPATION_CREATE_REQUEST';
const EVENT_PARTICIPATION_DELETE_REQUEST = 'EVENT_PARTICIPATION_DELETE_REQUEST';

export const EVENT_PARTICIPATION_CREATE_REQUEST_PENDING = 'EVENT_PARTICIPATION_CREATE_REQUEST_PENDING';
export const EVENT_PARTICIPATION_CREATE_REQUEST_SUCCESS = 'EVENT_PARTICIPATION_CREATE_REQUEST_SUCCESS';
export const EVENT_PARTICIPATION_CREATE_REQUEST_ERROR = 'EVENT_PARTICIPATION_CREATE_REQUEST_ERROR';
export const EVENT_PARTICIPATION_DELETE_REQUEST_PENDING = 'EVENT_PARTICIPATION_DELETE_REQUEST_PENDING';
export const EVENT_PARTICIPATION_DELETE_REQUEST_SUCCESS = 'EVENT_PARTICIPATION_DELETE_REQUEST_SUCCESS';
export const EVENT_PARTICIPATION_DELETE_REQUEST_ERROR = 'EVENT_PARTICIPATION_DELETE_REQUEST_ERROR';

export const createParticipation = (url: string, postId: Id) => ({
  meta: {
    postId,
  },
  payload: api.eventParticipation.create(url),
  type: EVENT_PARTICIPATION_CREATE_REQUEST,
} as const);

type CreateParticipationAction = PromiseMiddlewareActions<
  typeof EVENT_PARTICIPATION_CREATE_REQUEST_PENDING,
  typeof EVENT_PARTICIPATION_CREATE_REQUEST_ERROR,
  typeof EVENT_PARTICIPATION_CREATE_REQUEST_SUCCESS,
ReturnType<typeof createParticipation>
>;

export const deleteParticipation = (url: string, postId: Id) => ({
  meta: {
    postId,
  },
  payload: api.eventParticipation.delete(url),
  type: EVENT_PARTICIPATION_DELETE_REQUEST,
} as const);

type DeleteParticipationAction = PromiseMiddlewareActions<
  typeof EVENT_PARTICIPATION_DELETE_REQUEST_PENDING,
  typeof EVENT_PARTICIPATION_DELETE_REQUEST_ERROR,
  typeof EVENT_PARTICIPATION_DELETE_REQUEST_SUCCESS,
ReturnType<typeof createParticipation>
>;

export type EventParticipationAction = CreateParticipationAction | DeleteParticipationAction;

export const getParticipationList = (params: IEventParticipationListParams, history: History) => ({
  payload: api.eventParticipation.get(params).catch((error: IErrorResponse) => {
    if (error.code === RECORD_NOT_FOUND) {
      history.push(FRONTEND_NOT_FOUND_PATH);
    }
    throw error;
  }),
  type: PROFILES_LIST_GET_PROFILES,
});
