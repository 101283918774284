// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { Id } from 'src/interfaces';
import { IRelatedContentItem, IRelatedContentMap } from 'src/interfaces/related_content';

// classes
import PostCompact from 'src/components/post/compact/post_compact';

// helpers
import { textResources } from 'src/lang/de';
import './related_content.scss';

const cls = CLASS_PREFIX + 'related-content';
const labels = textResources.relatedContent;

interface IProps {
  postIds: Id[];
  posts: IRelatedContentMap;
}

export default class RelatedContent extends React.PureComponent<IProps, {}> {
  public render() {
    const { postIds } = this.props;
    if (!postIds.length) {
      return null;
    }

    return (
      <aside className={cls}>
        <h2 className={`${cls}__headline`}>{labels.headline}</h2>
        <p className={`${cls}__subheadline`}>{labels.subHeadline}</p>
        <div className={`${cls}__container`}>
          {this.renderRelatedContentItems()}
        </div>
      </aside>
    );
  }

  private renderRelatedContentItems() {
    const { postIds, posts } = this.props;

    return postIds.map((id) => this.renderRelatedContentItem(posts[id]));
  }

  private renderRelatedContentItem(item: IRelatedContentItem) {
    const ingredients = item.ingredientIds.map((ingredientId) => item.ingredients[ingredientId]!);

    return (
      <div className={`${cls}__item-container`} key={item.id}>
        <PostCompact
          ingredients={ingredients}
          publishedAt={item.publishedAt}
          detailUrl={item.detailUrl}
          id={item.id}
        />
      </div>
    );
  }
}
