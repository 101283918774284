// libs
import React, { FunctionComponent } from 'react';

// interfaces / constants
import { IEventLocation, IIngredient } from 'src/interfaces/post_ingredients';

// classes
import NavigationItem from 'src/components/navigation_item/navigation_item';
import IngredientRow from 'src/components/post/shared/ingredient_row/ingredient_row';

// styles
import './event_location.scss';

export type IEventLocationProps = IIngredient<IEventLocation>;

const EventLocation: FunctionComponent<IEventLocationProps> = ({
  data: { cityWithDistrict, street, description },
  postDetailsURL,
  displayMode,
}) => {
  const address = street ? `${street}, ${cityWithDistrict}` : description;

  return <NavigationItem target='_self' url={postDetailsURL} >
    <IngredientRow
      icon='pin-standard'
      title={address}
      displayMode={displayMode}
    />
  </NavigationItem>;
};

export default EventLocation;
