
import classNames from 'classnames';
import * as React from 'react';

import Tag from 'src/components/tag/tag';
import { CLASS_PREFIX } from 'src/constants/';
import { IQuickDate } from 'src/interfaces/filters';
import Translate from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';

import './quickpicker.scss';

const cls = CLASS_PREFIX + 'date-quickpicker';

interface IQuickpickerProps {
  dates?: IQuickDate[];
  onSelectDate: (date: IQuickDate) => (e: React.MouseEvent<HTMLElement>) => void;
  selected?: number;
  activeIndex?: number;
}

const getName = (quickdate: IQuickDate) => {
  const dateRange = quickdate.range && quickdate.range.start;
  let name = quickdate.name && Translate.datePickerQuickDate[quickdate.name];
  if (!name) {
    name = DateFormatter.formatDateReadable(dateRange || new Date());
  }
  return name;
};

export default ({ dates = [], onSelectDate, selected, activeIndex }: IQuickpickerProps): JSX.Element => (
  <ul>
    {dates && dates.map(
      (quickdate, index) => (
        <li
          key={index}
          onClick={onSelectDate && onSelectDate(quickdate)}
          className={`${cls}__item`}
        >
          <Tag
            className={classNames({
              [`${cls}__iteminner`]: true,
              [`${cls}__itemactive`]: index === activeIndex,
            })}
            text={getName(quickdate)}
          />
          {selected}
        </li>
      ),
    )}
  </ul>
);
