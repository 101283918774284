import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { IAction } from 'src/actions/index';
import api from 'src/api';
import {
  GroupListProfileActionCallbacks,
  GroupListProfileAvailableActions,
} from 'src/containers/smart_components/group_member_list/model';

const GROUP_REQUEST_CONFIRMATION = 'GROUP_REQUEST_CONFIRMATION';
const GROUP_REQUEST_REJECTION = 'GROUP_REQUEST_REJECTION';

export const GROUP_REQUEST_CONFIRMATION_REQUEST_PENDING = 'GROUP_REQUEST_CONFIRMATION_REQUEST_PENDING';
export const GROUP_REQUEST_CONFIRMATION_REQUEST_SUCCESS = 'GROUP_REQUEST_CONFIRMATION_REQUEST_SUCCESS';
export const GROUP_REQUEST_CONFIRMATION_REQUEST_ERROR = 'GROUP_REQUEST_CONFIRMATION_REQUEST_ERROR';

export const GROUP_REQUEST_REJECTION_REQUEST_PENDING = 'GROUP_REQUEST_REJECTION_REQUEST_PENDING';
export const GROUP_REQUEST_REJECTION_REQUEST_SUCCESS = 'GROUP_REQUEST_REJECTION_REQUEST_SUCCESS';
export const GROUP_REQUEST_REJECTION_REQUEST_ERROR = 'GROUP_REQUEST_REJECTION_REQUEST_ERROR';

export const GROUP_USER_REMOVAL_PENDING = 'GROUP_USER_REMOVAL_PENDING';
export const GROUP_USER_REMOVAL_SUCCESS = 'GROUP_USER_REMOVAL_SUCCESS';
export const GROUP_USER_REMOVAL_ERROR = 'GROUP_REQUEST_REJECTION_REQUEST_ERROR';

const acceptUser = (path: string, username: string, { success, error }: GroupListProfileActionCallbacks): IAction => ({
  payload: api.groupMember.confirm(path).then(() => success(username)).catch(() => error(username)),
  type: GROUP_REQUEST_CONFIRMATION,
});

const rejectUser = (path: string, username: string, { success, error }: GroupListProfileActionCallbacks): IAction => ({
  payload: api.groupMember.eliminate(path).then(() => success(username)).catch(() => error(username)),
  type: GROUP_REQUEST_REJECTION,
});

const removeUser = (path: string, username: string, { success, error }: GroupListProfileActionCallbacks): IAction => ({
  payload: api.groupMember.remove(path).then(() => success(username)).catch(() => error(username)),
  type: GROUP_REQUEST_REJECTION,
});

export const useUserRequestActions = (actions: GroupListProfileAvailableActions, username: string) => {
  const dispatch = useDispatch();
  return useMemo(() => {
    const { ACCEPT, REJECT, REMOVE } = actions;
    return ({
      accept: ACCEPT ? (path: string) => dispatch(acceptUser(path, username, ACCEPT)) : undefined,
      reject: REJECT ? (path: string) => dispatch(rejectUser(path, username, REJECT)) : undefined,
      remove: REMOVE ? (path: string) => dispatch(removeUser(path, username, REMOVE)) : undefined,
    });
  }, [actions, dispatch, username]);
};
