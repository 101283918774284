import { COOKIE_SAME_SITE_LAX } from 'src/constants/cookies';

export const LOCAL_STORAGE_TEST_KEY = 'LOCAL_STORAGE_TEST_KEY';
export const COOKIE_STORAGE_PREFIX = 'LOPO_';

const FUTURE_EXPIRATION_DATE = new Date('2030-01-01').toUTCString();
const clearPattern = new RegExp('(' + COOKIE_STORAGE_PREFIX + '[^=]*)=.*', 'g');
let isLocalStorageSupported = testLocalStorageSupport();

function clearCookieItems(): void {
  document.cookie.split(';').forEach((c) => {
    document.cookie = c.replace(clearPattern, '$1=; expires=Thu, 01 Jan 1970 00:00:00 UTC;');
  });
}

export function getCookieItem(key: string): string | null {
  const cookieKey = COOKIE_STORAGE_PREFIX + key;
  const pattern = new RegExp('(?:^|.*;\\s*)' + cookieKey + '\\s*\\=\\s*([^;]*)');
  const cookieValue: any = (document.cookie.match(pattern) || [])[1];
  return cookieValue ? decodeURIComponent(cookieValue) : null;
}

export function removeCookieItem(key: string): void {
  const cookieKey = COOKIE_STORAGE_PREFIX + key;
  document.cookie = `${cookieKey}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
}

export function setCookieItem(key: string, value: any): void {
  const cookieKey = COOKIE_STORAGE_PREFIX + key;
  const cookieItem = `${cookieKey}=${encodeURIComponent(`${value}`)}`;
  document.cookie = `${cookieItem}; expires=${FUTURE_EXPIRATION_DATE};path=/;${COOKIE_SAME_SITE_LAX}`;
}

export function testLocalStorageSupport() {
  try {
    localStorage.setItem(LOCAL_STORAGE_TEST_KEY, '1');
    localStorage.removeItem(LOCAL_STORAGE_TEST_KEY);
    return true;
  } catch (error) {
    return false;
  }
}

export function clear(): void {
  return isLocalStorageSupported ? localStorage.clear() : clearCookieItems();
}

export function getItem(key: string): string | null {
  return isLocalStorageSupported ? localStorage.getItem(key) : getCookieItem(key);
}

export function removeItem(key: string): void {
  return isLocalStorageSupported ? localStorage.removeItem(key) : removeCookieItem(key);
}

export function setItem(key: string, value: string): void {
  return isLocalStorageSupported ? localStorage.setItem(key, value) : setCookieItem(key, value);
}

// This function exists only for unit testing. DO NOT USE for anything else.
export function setLocalStorageSupported(value: boolean) {
  isLocalStorageSupported = value;
}
