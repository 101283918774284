// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import { COLOR_TYPE_PRIMARY, ColorType } from 'src/utils/color';

// components
import Icon from 'src/components/forms/icon/icon';

// helpers
import './segment_icon.scss';

export interface IProps {
  name: string;
  description?: string;
  color?: ColorType;
  marginRight?: 16 | 32;
  size?: 16 | 24 | 48;
}

const cls = itemSegmentCls + 'icon';

const SegmentIcon: React.FC<IProps> = ({ name, color, description, size, marginRight }) => {
  const classes = {
    [cls]: true,
    [cls + `--margin-right-${marginRight}`]: !!marginRight,
  };
  return (
    <div className={classNames(classes)}>
      <Icon name={name} color={color || COLOR_TYPE_PRIMARY} size={size || 24} description={description}/>
    </div>
  );
};

export default SegmentIcon;
