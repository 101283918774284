// libs
import * as React from 'react';
// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { ICategorizedProfiles } from 'src/reducers/profiles_list/model';
// components
import ItemGroupHeader from 'src/components/item_group_header/item_group_header';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import ProfilesList from 'src/components/profiles_list/profiles_list';
// helper
import { textResources } from 'src/lang/de';

import 'src/components/profiles_list/profiles_list.scss';

const cls: string = CLASS_PREFIX + 'profiles-list';

interface IProps {
  categorizedProfiles: ICategorizedProfiles[];
}

const ProfilesListCategorized: React.FC<IProps> = ({ categorizedProfiles }) => {
  return (
    <div className={cls}>
      {categorizedProfiles.map(({ title, url, profiles }) => {
        return (
          <div key={title}>
            <ItemGroupHeader
              title={title}
            >
              <NavigationItem
                target='_self'
                url={url}
                className={cls + '__show-all-link'}>
                {textResources.shared.showAll}
              </NavigationItem>
            </ItemGroupHeader>
            <div className={cls + '__profiles-wrapper'}>
              <ProfilesList profiles={profiles} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

ProfilesListCategorized.displayName = 'ProfilesListCategorized';

export default ProfilesListCategorized;
