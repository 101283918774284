// libs
import classNames from 'classnames';
import * as Leaflet from 'leaflet';
import React, { useCallback } from 'react';
import { Marker } from 'react-leaflet';
import { useHistory } from 'react-router';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IWindowJsEnv } from 'src/interfaces/';
import { IMarker } from 'src/interfaces/marker';
import { ColorType } from 'src/utils/color';

// helpers
import { textResources } from 'src/lang/de';
import initTracker, { MAP_MARKERS } from 'src/utils/reporting/events_tracking/events_tracking';

import 'src/components/map/marker/map_marker.scss';

const _window = window as unknown as IWindowJsEnv;

const cls = `${CLASS_PREFIX}map_marker`;
const clsImgContainer = `${cls}__image__container`;
const clsIconContainer = `${cls}__icon__container`;
const trackMapMarkers = initTracker(MAP_MARKERS);

const getMarkerImage = (imageUrl: string, color: ColorType): string => {
  const src = imageUrl || _window.js_env.imagePaths.defaultAvatar;

  const classes = classNames({
    [clsImgContainer]: true,
    [`${clsImgContainer}--${color}`]: true,
  });

  return `<div class='${classes}'><img alt='${textResources.imageAlt.markerImage}' src='${src}' /></div>`;
};

const MapMarker: React.FC<IMarker> = ({ color, id, image, position, url }) => {
  const history = useHistory();
  const handleClick = useCallback((event: Leaflet.LeafletMouseEvent) => {
    event.originalEvent.stopPropagation();
    trackMapMarkers(MAP_MARKERS.ACTIONS.OPEN, MAP_MARKERS.LABELS.START);
    url && history.push(url);
  }, [url, history]);

  const pos = new Leaflet.LatLng(position.latitude, position.longitude);

  const classes = {
    [clsIconContainer]: true,
    [`${clsIconContainer}--${color}`]: true,
  };

  const icon = new Leaflet.DivIcon({
    className: classNames(classes),
    html: getMarkerImage(image, color),
    iconAnchor: [15, 40],
    iconSize: [30, 40],
  });

  return (
    <Marker
      icon={icon}
      key={id}
      position={pos}
      onclick={handleClick}
    />
  );
};

export default MapMarker;
