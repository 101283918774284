import React, { FunctionComponent } from 'react';
import ProfileListItem from 'src/components/profiles_list/profile_list_item';
import GroupMemberListItem from 'src/containers/smart_components/group_member_list/group_member_list_item';
import { GroupListProfileAvailableActions } from 'src/containers/smart_components/group_member_list/model';
import { IGroupListProfile } from 'src/interfaces/profile';

interface IProps {
  profile: IGroupListProfile;
  actions: GroupListProfileAvailableActions;
}
const ProfileItem: FunctionComponent<IProps> = ({ profile, actions }) =>
  Object.keys(actions).length
    ? <GroupMemberListItem profile={profile} key={profile.id} actions={actions} />
    : <ProfileListItem profile={profile} key={profile.id} />;

export default ProfileItem;
