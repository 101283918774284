// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// styles
import './toggle.scss';

interface IProps {
  onChange?: (checked: boolean) => void;
  defaultChecked?: boolean;
  checked?: boolean;
}

const cls: string = CLASS_PREFIX + 'checkbox';

export default class Toggle extends React.PureComponent<IProps, any> {
  private input: HTMLInputElement;

  constructor(props: IProps) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleRef = this.handleRef.bind(this);
  }
  public componentDidMount() {
    const { checked, defaultChecked } = this.props;
    this.input.checked = !!(checked || defaultChecked);
  }

  public componentDidUpdate() {
    const { checked } = this.props;
    this.input.checked = !!checked;
  }

  public render() {
    const classes = classNames('react-toggle', {
      'react-toggle--checked': this.props.checked,
    });
    return (
      <div className={cls}>
        <div className={classes} onClick={this.handleClick}>
          <div className='react-toggle-track'/>
          <div className='react-toggle-thumb'>
            <input type='checkbox'
              className='react-toggle-input'
              ref={this.handleRef}
            />
          </div>
        </div>
      </div>
    );
  }

  public handleOnChange(checked: boolean) {
    const { onChange } = this.props;
    if (onChange) {
      onChange(checked);
    }
  }

  private handleClick(event: React.MouseEvent<HTMLElement>) {
    const checkbox = this.input;
    if (event.target !== checkbox) {
      event.preventDefault();
      checkbox.click();
      return;
    }

    const checked = checkbox.checked;
    this.handleOnChange(checked);
  }

  private handleRef(ref: HTMLInputElement) {
    this.input = ref;
  }
}
