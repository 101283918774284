import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

// interfaces
import { ILocation } from 'src/containers/location/location_interfaces';

// helpers
import { changeAppLocation } from 'src/actions/app-state/app-state';
import { isLocationDifferent } from 'src/containers/location/location_utils';

interface IProps {
  routeLocation?: ILocation;
  stateLocation?: ILocation;
}

export const useRouteStateLocation = ({ routeLocation, stateLocation }: IProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!routeLocation || !routeLocation.locationShape) {
      return;
    }

    if (isLocationDifferent(routeLocation, stateLocation)) {
      dispatch(changeAppLocation(
        routeLocation.locationShape,
        routeLocation.position,
        undefined,
        undefined,
        routeLocation.boundingBox,
      ));
    }
  }, [routeLocation, stateLocation, dispatch]);
};
