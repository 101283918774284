import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { callToActionWithFlag, callToAction } from 'src/actions/onboarding_cta/onboarding_cta';
import { useTotalPagesViewed } from 'src/reducers/feed/feed_hooks';
import { IFeedType } from '../../interfaces/feed';

// This hook is used for CTAs which should only occur once in 12 hours.
export const useCallToActionWithFlag = (feedType: IFeedType): void => {
  const dispatch = useDispatch();
  const totalPagesViewed = useTotalPagesViewed(feedType);

  dispatch(callToActionWithFlag(totalPagesViewed));
};

export const useCallToAction = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(callToAction());
  }, [dispatch]);
};
