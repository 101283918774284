// libs
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

// helper
import { reportError } from 'src/utils/reporting/report-errors';

let activeScrolllocks = 0;

const clsScrolllock = 'scrolllock';

const isOldIOSVersion = (): boolean => {
  // In mobile, when a scrollable element (Location Picker, Menu...) is in fixed position
  // we prevent the body to scroll by adding an overflow: hidden to the body
  // It works for all devices, except iOS < 13
  // For these old versions, we use body-scroll-lock library
  const ios: boolean | number = parseFloat(
    ('' + (/CPU.*OS ([0-9_]{1,5})|(CPU like).*AppleWebKit.*Mobile/i.exec(navigator.userAgent) || [0, ''])[1])
      .replace('undefined', '3_2').replace('_', '.').replace('_', ''),
  ) || false;

  return !!ios && ios < 13;
};

const disableBodyScrolllock = (target: HTMLElement | Element | null) => {
  activeScrolllocks = Math.max(activeScrolllocks - 1, 0);

  document.body.classList.remove(clsScrolllock);

  if (isOldIOSVersion()) {
    if (!activeScrolllocks) {
      clearAllBodyScrollLocks();
      return;
    }
    if (target) {
      enableBodyScroll(target);
    }
  }
};

const enableBodyScrolllock = (target: HTMLElement | Element | null) => {
  document.body.classList.add(clsScrolllock);

  if (isOldIOSVersion()) {
    if (target) {
      activeScrolllocks += 1;
      document.body.classList.add(clsScrolllock);
      disableBodyScroll(target);
      return;
    }
    reportError(`
      toggleBodyScrolllock was called with target of null to enableBodyScrolllock
  `);
  }
};

export const toggleBodyScrolllock = (enableScrolllock: boolean, target: HTMLElement | Element | null) => {
  enableScrolllock
    ? enableBodyScrolllock(target)
    : disableBodyScrolllock(target);
};
