import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

// helpers
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { reportError } from 'src/utils/reporting/report-errors';
import { parseQueryString } from 'src/utils/url/url';

interface IParsedQuery {
  context: string; //  a JSON-encoded object containing the context e.g. the id of a conversation for a message.
  as: string;
  code: 'message' | 'comment' | 'bookmark' | 'group_access_confirmation' | 'view_count';
  type: 'notification';
}

type ParsedQueryType = keyof IParsedQuery;

export const parseRedirectRoute = (search: string) => {
  const routeMap: {[k: string]: string} = {
    event: 'events',
    post: 'posts',
  };

  const profileTypeMap: {[key: string]: string} = {
    authority_profile: 'authority',
    blog_profile: 'blog',
    press_profile: 'press',
    private_profile: 'private',
    reporter_profile: 'reporter',
  };

  const parsedQuery = parseQueryString<ParsedQueryType, IParsedQuery>(search, [
    'context', 'as', 'code',
  ]);

  let context;
  try {
    if (parsedQuery.context) {
      context = JSON.parse(decodeURIComponent(parsedQuery.context));
    }
  } catch (error) {
    reportError('Unable to parse query-string context', {
      context: parsedQuery.context,
      error,
      search,
    });
    return '/';
  }
  if (!context || Object.keys(context).length === 0) {
    return '/';
  }
  let redirectRoute: string = '';
  let id: string = '';

  const as = parsedQuery.as ? `?as=${encodeURIComponent(parsedQuery.as)}` : '';
  if (parsedQuery.code === 'message') {
    // conversation
    redirectRoute = 'conversations';
    id = context.conversation_id;
  } else if (context.type === 'comment') {
    // bookmark comment
    if (context.context) {
      redirectRoute = routeMap[context.context.type];
      id = context.context.identifier;
    }
  } else if (profileTypeMap[context.type]) {
    // profiles
    const profileType = profileTypeMap[context.type];
    return `/profiles/${profileType}/${context.identifier}${as}`;
  } else {
    // post, event
    redirectRoute = routeMap[context.type];
    id = context.identifier;
  }
  return `/${redirectRoute}/${id}${as}`;
};

const RedirectPage: React.FC<RouteComponentProps> = ({ location }) => {
  const user = useSelectUser();

  usePageTracking({ user });

  const to = parseRedirectRoute(location.search);

  return <Redirect to={to} />;
};

export default RedirectPage;
