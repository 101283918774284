// libs
import * as React from 'react';

// interfaces / constants
import { IErrorResponse } from 'src/api/interfaces/errors';
import { CLASS_PREFIX } from 'src/constants/';
import { MESSAGE_BOX_TIMEOUT } from 'src/constants/timeouts';
import { COLOR_TYPE_PRIMARY } from 'src/utils/color';

// classes / components
import Button, { BUTTON_TYPE_CONTAINED, BUTTON_TYPE_TEXT } from 'src/components/forms/button/button';
import FieldSet from 'src/components/forms/field_set/field_set';
import InputField from 'src/components/forms/input_field/input_field';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';
import { IconType } from 'src/components/message_segment/message_segment';
import MessageSegmentWithModal from 'src/components/message_segment/message_segment_with_modal';
import SnackBar from 'src/components/snack_bar/snack_bar';

// helpers
import api from 'src/api/';
import { textResources } from 'src/lang/de';
import { formatApiErrorFullMessages } from 'src/utils/api_error_message/api_error_message';
import { validateEmail, isNotEmpty } from 'src/utils/validation';

import './message_form.scss';

const labels = textResources.feedback;

interface IProps {
  additionalParams?: string;
  body: string;
  close: () => void;
  title: string;
  loggedInUserEmail?: string;
  placeholder?: string;
  successImage: IconType;
  successTitle: string;
  successBody: string | JSX.Element;
}

interface IState {
  email: string;
  emailIsValid: boolean;
  formSubmitted: boolean;
  isLoading: boolean;
  message: string;
  messageIsValid: boolean;
  showSnackbar: boolean;
  snackbarMessage: string;
}

const cls: string = CLASS_PREFIX + 'message-form';
// MESSAGE_MAX_LENGTH must be equal or lower than our Backend-validation.
// To fit also the additional (hidden) params into the message, we need to leave a buffer.
// The additional params have up to approximately 100 characters.
const MESSAGE_MAX_LENGTH = 2000;
let successTimeout: number;

export default class MessageForm extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.changeEmail = this.changeEmail.bind(this);
    this.changeMessage = this.changeMessage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.showSnackbar = this.showSnackbar.bind(this);
    this.hideSnackbar = this.hideSnackbar.bind(this);
    this.renderFeedbackThanks = this.renderFeedbackThanks.bind(this);

    this.state = {
      email: props.loggedInUserEmail || '',
      emailIsValid: true,
      formSubmitted: false,
      isLoading: false,
      message: '',
      messageIsValid: true,
      showSnackbar: false,
      snackbarMessage: '',
    };
  }

  public componentWillUnmount() {
    if (successTimeout) {
      clearTimeout(successTimeout);
    }
  }

  public render() {
    const { formSubmitted, showSnackbar, snackbarMessage } = this.state;

    return (
      <>
        {showSnackbar &&
          <SnackBar showClose onClose={this.hideSnackbar}>
            {snackbarMessage}
          </SnackBar>
        }
        {!formSubmitted
          ? this.renderFeedbackForm()
          : this.renderFeedbackThanks()}
      </>
    );
  }

  private renderFeedbackForm() {
    const { additionalParams, body, close, loggedInUserEmail, title, placeholder } = this.props;
    const { email, emailIsValid, isLoading, message, messageIsValid } = this.state;
    const maxLength = additionalParams ? MESSAGE_MAX_LENGTH - additionalParams.length : MESSAGE_MAX_LENGTH;

    return (
      <MessageBoxWithModal
        isLoading={isLoading}
        onClose={close}
        title={title}
        whiteBackground
      >
        <p className={cls + '__body'}>{body}</p>
        <div className={cls + '__input'}>
          <FieldSet>
            {!loggedInUserEmail &&
              <InputField
                name='email'
                label={labels.emailPlaceholder}
                onChange={this.changeEmail}
                value={email}
                valid={emailIsValid}
                errorText={textResources.shared.formErrorInvalid}
                outlined
                required
                type='email'
              />
            }
            <InputField
              name='message'
              label={placeholder || labels.messagePlaceholder}
              onChange={this.changeMessage}
              value={message}
              valid={messageIsValid}
              outlined
              maxLength={maxLength}
              expandable
              required
            />
          </FieldSet>
        </div>
        <Button
          variant={BUTTON_TYPE_CONTAINED}
          color={COLOR_TYPE_PRIMARY}
          label={loggedInUserEmail ? textResources.wizard.send : labels.acceptAndSubmit}
          fullWidth
          onClick={this.submitForm}
          lowerCase
        />
        {!loggedInUserEmail &&
          <p className={cls + '__terms'}>
            {labels.terms}
          </p>
        }
        <div className={cls + '__footer'}>
          <Button
            variant={BUTTON_TYPE_TEXT}
            fullWidth
            onClick={close}
            label={textResources.shared.cancel}
            lowerCase
          />
        </div>
      </MessageBoxWithModal>
    );
  }

  private renderFeedbackThanks() {
    const { close, successImage, successTitle, successBody } = this.props;
    return (
      <MessageSegmentWithModal
        image={successImage}
        title={successTitle}
        description={successBody}
        onClose={close}
      />
    );
  }

  private changeEmail(value: string) {
    this.setState({ email: value, emailIsValid: true });
  }

  private changeMessage(value: string) {
    this.setState({ message: value, messageIsValid: true });
  }

  private submitForm() {
    const { email, message } = this.state;
    const { additionalParams } = this.props;

    const validEmail = validateEmail(email);
    const validMessage = !!message && isNotEmpty(message);

    if (!validEmail) {
      this.setState({ emailIsValid: false });
    }

    if (!validMessage) {
      this.setState({ messageIsValid: false });
    }

    if (validEmail && validMessage) {
      this.setState({ isLoading: true });
      // Add hidden message like params, context, etc
      const feedbackMessage = additionalParams ? message + additionalParams : message;
      api.feedback.create({ email, message: feedbackMessage })
        .then(() => {
          this.setState({ formSubmitted: true, isLoading: false });
          successTimeout = window.setTimeout(() => this.props.close(), MESSAGE_BOX_TIMEOUT);
        })
        .catch((error: IErrorResponse) => {
          const snackbarMessage = formatApiErrorFullMessages(error, undefined, labels.errorSending);
          this.setState({ isLoading: false, showSnackbar: true, snackbarMessage });
        });
    }
  }

  private showSnackbar() {
    this.setState({ showSnackbar: true });
  }
  private hideSnackbar() {
    this.setState({ showSnackbar: false });
  }
}
