// This method is or extreme cases. As rule of thumb, we have breakpoints logic (desktop, tablet, mobile) only in css.
// This js exists for cases which are critical. Please talk to the team several times before using this method, since it
// goes against the app architecture.
export const readColumnCountFromDOM = () => {
  const el = document.getElementsByClassName('app-content')[0];
  return el ? parseInt(
    getComputedStyle(el).getPropertyValue('--app-column-count'),
    10
  ) : 1;
};
