// libs
import produce from 'immer';

// interfaces / constants
import { IAction } from 'src/actions/';
import {
  CAMPAIGN_CREATE_REQUEST_ERROR,
  CAMPAIGN_CREATE_REQUEST_PENDING,
  CAMPAIGN_CREATE_REQUEST_SUCCESS,
  CAMPAIGN_RESET,
} from 'src/actions/campaign';

export interface ICampaign {
  createdPostIdentifier: string;
  hasError: boolean;
  isLoading: boolean;
}

export const INITIAL_STATE: ICampaign = {
  createdPostIdentifier: '',
  hasError: false,
  isLoading: false,
};

const campaignReducer = (state = INITIAL_STATE, action: IAction): ICampaign =>
  produce(state, (draft) => {
    switch (action.type) {
      case CAMPAIGN_RESET:
        draft.createdPostIdentifier = INITIAL_STATE.createdPostIdentifier;
        draft.hasError = INITIAL_STATE.hasError;
        draft.isLoading = INITIAL_STATE.isLoading;
        break;
      case CAMPAIGN_CREATE_REQUEST_PENDING:
        draft.createdPostIdentifier = INITIAL_STATE.createdPostIdentifier;
        draft.hasError = false;
        draft.isLoading = true;
        break;

      case CAMPAIGN_CREATE_REQUEST_SUCCESS:
        draft.createdPostIdentifier = action.meta.identifier;
        draft.hasError = false;
        draft.isLoading = false;
        break;

      case CAMPAIGN_CREATE_REQUEST_ERROR:
        draft.createdPostIdentifier = INITIAL_STATE.createdPostIdentifier;
        draft.hasError = true;
        draft.isLoading = false;
        break;
    }
  });

export default campaignReducer;
