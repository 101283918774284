import React, { useCallback, useState } from 'react';
import { IUserListActionConfirmation } from 'src/components/item_segment/layouts/profiles_list/model';
import MessageBoxModal from 'src/components/message_box/message_box_with_modal';
import { IGroup } from 'src/interfaces/group';
import { textResources } from 'src/lang/de';

export const useGroupJoinConfirmation = (joinGroup?: (group: IGroup) => Promise<void>, onConfirm?: () => void) => {
  const [messageBoxModalProps, setMessageBoxModalProps] = useState<IUserListActionConfirmation | null>();

  const onGroupJoin = useCallback((group: IGroup) => {
    if (joinGroup) {
      if (group.accessibility === 'closed') {
        setMessageBoxModalProps({
          buttons: [
            {
              label: textResources.groups.joinClosedGroupModal.confirm,
              onClick: () => {
                setMessageBoxModalProps(null);
                joinGroup(group).then(() => {
                  onConfirm && onConfirm();
                });
              },
              type: 'PRIMARY',
            },
            {
              label: textResources.shared.cancel,
              onClick: () => {
                setMessageBoxModalProps(null);
              },
              type: 'CANCEL',
            },
          ],
          children: textResources.groups.joinClosedGroupModal.message,
          title: textResources.groups.joinClosedGroupModal.title_WEB(group.name),
        });
      } else {
        setMessageBoxModalProps(null);
        joinGroup(group);
      }
    }
  }, [joinGroup, onConfirm]);

  return {
    ConfirmationModal: messageBoxModalProps
      ? () => <MessageBoxModal {...messageBoxModalProps}>{messageBoxModalProps.children}</MessageBoxModal> : null,
    onGroupJoin,
  };
};
