export const COLOR_TYPE_DEFAULT = 'default';
export const COLOR_TYPE_ACCENT = 'accent';
export const COLOR_TYPE_DANGER = 'danger';
export const COLOR_TYPE_DARKERWHITE = 'darker-white';
export const COLOR_TYPE_FACEBOOK = 'facebook';
export const COLOR_TYPE_APPLE = 'apple';
export const COLOR_TYPE_PRIMARY = 'primary';
export const COLOR_TYPE_PRIMARY_TINT1 = 'primary-tint-1';
export const COLOR_TYPE_PRIMARY_2 = 'primary-2';
export const COLOR_TYPE_PRIMARY_5 = 'primary-5';
export const COLOR_TYPE_SECONDARY1 = 'secondary-1';
export const COLOR_TYPE_SECONDARY2 = 'secondary-2';
export const COLOR_TYPE_SECONDARY3 = 'secondary-3';
export const COLOR_TYPE_SECONDARY4 = 'secondary-4';
export const COLOR_TYPE_SECONDARY5 = 'secondary-5';
export const COLOR_TYPE_SECONDARY6 = 'secondary-6';
export const COLOR_TYPE_WHITE = 'white';
export const COLOR_TYPE_GRAY_TINT_1 = 'gray-tint-1';
export const COLOR_TYPE_GRAY_TINT_4 = 'gray-tint-4';
export const COLOR_TYPE_GRAY_TINT_6 = 'gray-tint-6';
export const COLOR_TYPE_GRAY_TINT_8 = 'gray-tint-8';
export const COLOR_TYPE_BLACK = 'black';
export const COLOR_TYPE_WHATSAPP = 'whatsapp';
export const COLOR_TYPE_TWITTER = 'twitter';
export const COLOR_TYPE_NOTIFICATION = 'notification';

export type ColorType = typeof COLOR_TYPE_DEFAULT
| typeof COLOR_TYPE_ACCENT
| typeof COLOR_TYPE_DANGER
| typeof COLOR_TYPE_DARKERWHITE
| typeof COLOR_TYPE_FACEBOOK
| typeof COLOR_TYPE_APPLE
| typeof COLOR_TYPE_PRIMARY
| typeof COLOR_TYPE_PRIMARY_TINT1
| typeof COLOR_TYPE_PRIMARY_2
| typeof COLOR_TYPE_PRIMARY_5
| typeof COLOR_TYPE_SECONDARY1
| typeof COLOR_TYPE_SECONDARY2
| typeof COLOR_TYPE_SECONDARY3
| typeof COLOR_TYPE_SECONDARY4
| typeof COLOR_TYPE_SECONDARY5
| typeof COLOR_TYPE_SECONDARY6
| typeof COLOR_TYPE_WHITE
| typeof COLOR_TYPE_GRAY_TINT_1
| typeof COLOR_TYPE_GRAY_TINT_4
| typeof COLOR_TYPE_GRAY_TINT_6
| typeof COLOR_TYPE_GRAY_TINT_8
| typeof COLOR_TYPE_BLACK
| typeof COLOR_TYPE_WHATSAPP
| typeof COLOR_TYPE_TWITTER
| typeof COLOR_TYPE_NOTIFICATION;
