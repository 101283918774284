// libs
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

// constants
import {
  FEED_TYPE_EVENT_CATEGORIZED,
  FEED_TYPE_EVENT_RELATED,
  FEED_TYPE_EVENT,
  FEED_TYPE_EVENT_PARTICIPATING,
} from 'src/constants/feed';

// helpers
import { fetchCategorizedEventFeedPage } from 'src/actions/feed/events_feeds/categorized_events_feed';
import { fetchEventFeedPage } from 'src/actions/feed/events_feeds/events_feed';
import {
  fetchRelatedEventFeedPage,
} from 'src/actions/feed/events_feeds/related_events_feed';
import { setFeedHash } from 'src/actions/feed/feed';
import { fetchNextFeedPage } from 'src/actions/feed/next_page';
import { useSelectFeedIsLoading } from 'src/reducers/feed/feed_hooks';
import { fetchParticipatingEventFeedPage } from './participating_events_feed';

export const useResetEventsFeeds = () => {
  const dispatch = useDispatch();
  const isCategorizedLoading = useSelectFeedIsLoading(FEED_TYPE_EVENT_CATEGORIZED);
  const isRelatedLoading = useSelectFeedIsLoading(FEED_TYPE_EVENT_RELATED);
  return useCallback(() => {
    if (isCategorizedLoading || isRelatedLoading) {
      return;
    }
    dispatch(setFeedHash(FEED_TYPE_EVENT_CATEGORIZED, ''));
    dispatch(setFeedHash(FEED_TYPE_EVENT_RELATED, ''));
  }, [dispatch, isCategorizedLoading, isRelatedLoading]);
};

export const useFetchEventFeedPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchEventFeedPage());
  }, [dispatch]);
};

export const useFetchEventFeedNextPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchNextFeedPage(FEED_TYPE_EVENT, fetchEventFeedPage));
  }, [dispatch]);
};

export const useFetchCategorizedEventFeedPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchCategorizedEventFeedPage());
  }, [dispatch]);
};

export const useFetchRelatedEventFeedPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchRelatedEventFeedPage());
  }, [dispatch]);
};

export const useFetchRelatedEventFeedNextPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchNextFeedPage(FEED_TYPE_EVENT_RELATED, fetchRelatedEventFeedPage));
  }, [dispatch]);
};

export const useFetchParticipatingEventFeedPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchParticipatingEventFeedPage());
  }, [dispatch]);
};
export const useFetchParticipatingEventFeedNextPage = () => {
  const dispatch = useDispatch();
  return useCallback(() => {
    dispatch(fetchNextFeedPage(FEED_TYPE_EVENT_PARTICIPATING, fetchParticipatingEventFeedPage));
  }, [dispatch]);
};
