import produce from 'immer';

import { RECORD_NOT_FOUND } from 'src/constants/api_error_codes';

import {
  IProfileDetailsActionPending,
  IProfileDetailsActionSuccess,
  IProfileGrayListSuccess,
  PROFILE_DETAILS_REQUEST_SUCCESS,
  PROFILE_GRAYLIST_REQUEST_SUCCESS,
} from 'src/actions/profile/profile';

import {
  ICommentsFetchSuccess,
  ICommentsCreateSuccess,
  ICommentsUpdateSuccess,
  COMMENT_CREATE_REQUEST_SUCCESS,
  COMMENT_UPDATE_SUCCESS,
  COMMENTS_REQUEST_SUCCESS,
} from 'src/actions/comments';
import {
  FEED_NEXT_PAGE_REQUEST_SUCCESS,
  FEED_SET_FEED_HASH,
  FEED_CLEAR_ALL,
  FeedAction,
} from 'src/actions/feed/feed';

import {
  NewsFeedHighlightsAction,
  NEWS_FEED_GET_HIGHLIGHTS_SUCCESS,
} from 'src/actions/feed/news_feed/highlights/highlights';

import {
  ICreateFollowActionPending,
  IUpdateFollowActionPending,
  IDestroyFollowActionPending,
  ICreateFollowActionSuccess,
  IUpdateFollowActionSuccess,
  IDestroyFollowActionSuccess,
  ICreateFollowActionError,
  IUpdateFollowActionError,
  IDestroyFollowActionError,
  FOLLOW_REQUEST_PENDING,
  UNFOLLOW_REQUEST_PENDING,
  UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_PENDING,
  FOLLOW_REQUEST_SUCCESS,
  UNFOLLOW_REQUEST_SUCCESS,
  UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_SUCCESS,
  FOLLOW_REQUEST_ERROR,
  UNFOLLOW_REQUEST_ERROR,
  UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_ERROR,
} from 'src/actions/follow/follow';
import {
  PostAction,
  POST_CREATE_REQUEST_SUCCESS,
  POST_REQUEST_SUCCESS,
} from 'src/actions/post/post';
import {
  IGetProfilesListSecondaryListSuccessAction,
  IGetProfilesListSuccessAction,
  IGetSponsorsListSuccessAction,
  PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_SUCCESS,
  PROFILES_LIST_GET_PROFILES_SUCCESS,
  IProfilesListSetHash,
  IProfilesListSetSecondaryHash,
  PROFILES_LIST_SET_HASH,
  PROFILES_LIST_SET_SECONDARY_HASH,
  PROFILES_LIST_CLEAN,
  IProfilesListClean,
  PROFILES_LIST_GET_SPONSORS_SUCCESS,
} from 'src/actions/profiles_list/profiles_list';
import { IUpdateProfileAction, USER_UPDATE_PROFILE } from 'src/actions/user/user';
import { Id } from 'src/interfaces';
import { IProfile } from 'src/interfaces/profile';

export type ProfilesState = Partial<Record<Id, { profile: IProfile; references: Id[] }>>

export const INITIAL_STATE: ProfilesState = {};

type ProfileUpdateActions =
  IProfileDetailsActionPending |
  IProfileDetailsActionSuccess |
  IProfileGrayListSuccess |
  ICreateFollowActionSuccess |
  IUpdateFollowActionSuccess |
  IDestroyFollowActionSuccess |
  ICreateFollowActionPending |
  IUpdateFollowActionPending |
  IDestroyFollowActionPending |
  ICreateFollowActionError |
  IUpdateFollowActionError |
  IDestroyFollowActionError |
  IUpdateProfileAction
;

type AddProfileActions =
  IGetProfilesListSuccessAction |
  IGetProfilesListSecondaryListSuccessAction |
  IGetSponsorsListSuccessAction |
  PostAction |
  ICommentsFetchSuccess |
  ICommentsCreateSuccess |
  ICommentsUpdateSuccess
;

type RemoveProfilesActions =
  IProfilesListSetSecondaryHash |
  IProfilesListSetHash |
  IProfilesListClean
;

type Actions = ProfileUpdateActions | AddProfileActions | RemoveProfilesActions | FeedAction | NewsFeedHighlightsAction;

const getReferenceIDs = (action: Actions) => {
  if ('meta' in action) {
    if ('feedType' in action.meta) {
      return [action.meta.feedType];
    }
    if ('storeKeys' in action.meta) {
      return [...action.meta.storeKeys];
    }
    if ('storeKey' in action.meta) {
      return [action.meta.storeKey];
    }
  };
  return [action.type.substr(0, action.type.indexOf('_'))];
};

const profileReducer = (state = INITIAL_STATE, action: Actions): ProfilesState =>
  produce(state, (draft) => {
    const addProfile = (profile: IProfile, action: Actions) => {
      const referenceIds = getReferenceIDs(action);
      if (!draft[profile.id]) {
        draft[profile.id] = {
          profile,
          references: referenceIds,
        };
      } else {
        draft[profile.id]!.profile = profile;
        draft[profile.id]!.references =
            [...new Set([...draft[profile.id]!.references, ...referenceIds])];
      }
    };
    const removeReference = (itemId: Id, action: Actions) => {
      const referenceIds = getReferenceIDs(action);
      draft[itemId]!.references =
        draft[itemId]!.references.filter((reference) => referenceIds.indexOf(reference) === -1);
      if (draft[itemId]!.references.length === 0) {
        delete draft[itemId];
      }
    };
    switch (action.type) {
      // toggle isGraylisted; useful if graylisting someone while on the profile details page
      case PROFILE_GRAYLIST_REQUEST_SUCCESS: {
        const profile = draft[action.meta.id]?.profile;
        if (profile) {
          profile.meta.isGraylisted = !profile.meta.isGraylisted;
        }
        break;
      }

      case FOLLOW_REQUEST_PENDING:
      case FOLLOW_REQUEST_SUCCESS: {
        const profile = draft[action.meta.followableId]?.profile;
        if (profile) {
          profile.meta.isFollowed = true;
        }
        break;
      }

      case UNFOLLOW_REQUEST_PENDING:
      case UNFOLLOW_REQUEST_SUCCESS:
      case FOLLOW_REQUEST_ERROR: {
        const profile = draft[action.meta.followableId]?.profile;
        if (profile) {
          profile.meta.isFollowed = false;
        }
        break;
      }

      case UNFOLLOW_REQUEST_ERROR: {
        const profile = draft[action.meta.followableId]?.profile;
        if (profile) {
          const isDestroyed = action.payload.code === RECORD_NOT_FOUND;
          // It's already destroyed, (other device did or ...)
          profile.meta.isFollowed = !isDestroyed;
        }
        break;
      }

      case UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_PENDING: {
        const profile = draft[action.meta.followableId]?.profile;
        if (profile) {
          profile.meta.notifiesMe = action.meta.notification;
        }
        break;
      }

      case UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_SUCCESS: {
        const profile = draft[action.meta.followableId]?.profile;
        if (profile) {
          profile.meta.notifiesMe = action.payload.followable.meta.notifiesMe;
        }
        break;
      }

      case UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_ERROR: {
        const profile = draft[action.meta.followableId]?.profile;
        if (profile) {
          profile.meta.notifiesMe = !action.meta.notification;
        }
        break;
      }

      case USER_UPDATE_PROFILE: {
        const profile = action.payload.id && draft[action.payload.id]?.profile;
        if (action.payload.id && profile) {
          const { description, name, website, siteNotice, avatar } = action.payload;
          // since IProfile and IUserProfile diverge
          draft[action.payload.id]!.profile = {
            ...profile,
            description: description || '',
            name: name || '',
            website: website,
            siteNotice: siteNotice,
            imageUrl: avatar?.url || '',
          };
        }
        break;
      }

      // ------- keeping track of profiles
      case PROFILES_LIST_GET_SECONDARY_LIST_PROFILES_SUCCESS:
      case PROFILES_LIST_GET_PROFILES_SUCCESS:
      case PROFILES_LIST_GET_SPONSORS_SUCCESS:
      case FEED_NEXT_PAGE_REQUEST_SUCCESS:
      case NEWS_FEED_GET_HIGHLIGHTS_SUCCESS:
      case COMMENTS_REQUEST_SUCCESS:
      case PROFILE_DETAILS_REQUEST_SUCCESS:
      case COMMENT_UPDATE_SUCCESS:
      case COMMENT_CREATE_REQUEST_SUCCESS:
      case POST_REQUEST_SUCCESS:
      case POST_CREATE_REQUEST_SUCCESS:
        if (!action.payload) {
          break;
        }
        if ('profiles' in action.payload && action.payload.profiles) {
          action.payload.profiles.forEach((profile) => {
            addProfile(profile, action);
          });
        }
        if ('profile' in action.payload && action.payload.profile) {
          addProfile(action.payload.profile, action);
        }
        break;
      // --- removing not referenced profiles
      case PROFILES_LIST_SET_SECONDARY_HASH:
      case PROFILES_LIST_SET_HASH:
      case FEED_SET_FEED_HASH:
      case PROFILES_LIST_CLEAN:
      case FEED_CLEAR_ALL:
        for (const item of Object.values(draft)) {
          removeReference(item!.profile.id, action);
        }
        break;
    }
  });

export default profileReducer;
