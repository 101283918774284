// lib
import * as React from 'react';

// interfaces
import { IProps as IItemSegmentIconProps } from 'src/components/item_segment/parts/segment_icon/segment_icon';

// components
import ItemSegmentBase, { BIG_ITEM_SEGMENT, itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink, { IProps as ILinkProps } from 'src/components/item_segment/base/item_segment_link';
import SegmentRightArrow from 'src/components/item_segment/parts/segment_right_arrow/segment_right_arrow';
import SegmentRoundIcon from 'src/components/item_segment/parts/segment_round_icon/segment_round_icon';
import SegmentText from 'src/components/item_segment/parts/segment_text/segment_text';

interface IProps {
  icon: IItemSegmentIconProps;
  label: string;
  navigation: ILinkProps;
}

const cls = itemSegmentCls + 'share';

/*
  A Wrapper for ItemSegment for Share buttons
*/
const ShareItemSegment: React.FC<IProps> = ({ icon, label, navigation }) => (
  <ItemSegmentBase className={cls} height={BIG_ITEM_SEGMENT}>
    <ItemSegmentLink {...navigation}>
      <SegmentRoundIcon name={icon.name} color={icon.color} isInverted />
      <SegmentText label={label} labelSize='big' />
      <SegmentRightArrow />
    </ItemSegmentLink>
  </ItemSegmentBase>
);

export default ShareItemSegment;
