// lib
import React from 'react';

// components
import ItemSegmentBase, { BIG_ITEM_SEGMENT, itemSegmentCls } from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink, { IProps as ILinkProps } from 'src/components/item_segment/base/item_segment_link';
import ItemSegmentButton from 'src/components/item_segment/parts/segment_button/segment_button';
import ItemSegmentIcon from 'src/components/item_segment/parts/segment_icon/segment_icon';
import ItemSegmentRightArrow from 'src/components/item_segment/parts/segment_right_arrow/segment_right_arrow';
import ItemSegmentRoundIcon from 'src/components/item_segment/parts/segment_round_icon/segment_round_icon';
import ItemSegmentText from 'src/components/item_segment/parts/segment_text/segment_text';
import { textResources } from 'src/lang/de';

// constants/interfaces
import { COLOR_TYPE_GRAY_TINT_1, COLOR_TYPE_GRAY_TINT_8, COLOR_TYPE_PRIMARY } from 'src/utils/color';
import { GroupAccessibility } from '../../../../api/interfaces/responses/group';

export type GroupIconType = 'admin' | 'join' | 'pending';

interface IProps {
  accessibility: GroupAccessibility;
  label: string;
  description?: string;
  groupIconType?: GroupIconType;
  navigation?: ILinkProps;
  onJoin?: () => void;
  canJoinGroup?: boolean;
}

const getIconByAccessibility = (accessibility: GroupAccessibility) => {
  if (accessibility === 'closed') {
    return <ItemSegmentRoundIcon
      name={'padlock'}
      color={COLOR_TYPE_GRAY_TINT_1}
      customSize={18}
      isSmall
      noHover
    />;
  }

  if (accessibility === 'open') {
    return <ItemSegmentRoundIcon
      name={'plus'}
      color={COLOR_TYPE_PRIMARY}
      isInverted
      isSmall
    />;
  }

  return null;
};

/*
  A Wrapper for ItemSegment for the group-list
*/
const GroupItemSegment: React.FC<IProps> =
  ({
    description,
    accessibility,
    label,
    navigation,
    groupIconType,
    onJoin,
    canJoinGroup,
  }) => {
    if (groupIconType === 'pending') {
      return <ItemSegmentBase className={itemSegmentCls} height={BIG_ITEM_SEGMENT} >
        <ItemSegmentLink>
          <ItemSegmentText label={label} labelSize='big' description={description} />
          <ItemSegmentRoundIcon
            noHover
            color={COLOR_TYPE_GRAY_TINT_1}
            backgroundColor={COLOR_TYPE_GRAY_TINT_8}
            isSmall
            name='pending' />
        </ItemSegmentLink>
      </ItemSegmentBase>;
    }
    return (
      <ItemSegmentBase className={itemSegmentCls} height={BIG_ITEM_SEGMENT} >
        <ItemSegmentLink {...navigation} >
          <ItemSegmentText label={label} labelSize='big' description={description} />
          {groupIconType === 'admin' &&
            <ItemSegmentIcon
              marginRight={16}
              name='admin'
              description={textResources.screenReaderText.administrator} />
          }
          {groupIconType === 'join' &&
            <ItemSegmentButton onClick={onJoin} featureBlocked={!canJoinGroup}>
              {getIconByAccessibility(accessibility)}
            </ItemSegmentButton>}
          <ItemSegmentRightArrow />
        </ItemSegmentLink>
      </ItemSegmentBase>
    );
  };

export default GroupItemSegment;
