// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import { textResources } from 'src/lang/de';
import './empty_feed.scss';

const cls: string = CLASS_PREFIX + 'empty-feed';

const EmptyFeed: React.FC = () => {
  return (
    <div className={cls}>
      <p className={`${cls}__text`}>{textResources.shared.emptyFeedText}</p>
    </div>
  );
};

export default EmptyFeed;
