// libs
import * as React from 'react';

// interfaces / constants
import { THUMBNAIL_SIZE_TYPE_SMALL } from 'src/components/thumbnail/thumbnail';
import { CLASS_PREFIX } from 'src/constants/';
import { IDeletedComment, CommentDeletor } from 'src/interfaces/comments';

// components
import Avatar from 'src/components/avatar/avatar';

// helpers
import { textResources } from 'src/lang/de';
import DateFormatter from 'src/utils/date_formatter/date_formatter';
import * as avatarPath from './avatar-deleted-profile.svg';
import './deleted_comment.scss';

const labels = textResources.comments;
const cls = CLASS_PREFIX + 'deleted-comment';

const getDeletedByTranslation = (deletedBy: CommentDeletor) => {
  switch (deletedBy) {
    case 'administrator':
      return labels.removedByAdmin_WEB;
    case 'author':
      return labels.removedByAuthor;
    case 'commentable_author':
      return labels.removedByCommentableAuthor_WEB;
    default:
      return labels.removedByUnknown;
  }
};

const DeletedComment: React.FC<IDeletedComment> = ({ updatedAt, meta: { deletedBy } }) => {
  return (
    <div className={cls}>
      <div className={cls + '__avatar'}>
        <Avatar image={avatarPath} size={THUMBNAIL_SIZE_TYPE_SMALL} withBorder />
      </div>
      <div className={cls + '__body'}>
        <div className={cls + '__message'}>
          {getDeletedByTranslation(deletedBy)}
        </div>
        <div className={cls + '__footer'}>
          <div className={cls + '__reactions'} />
          <div className={cls + '__date'}>
            {DateFormatter.asTimeAgo(updatedAt)}
          </div>
          <div className={cls + '__more-button'} />
        </div>
      </div>
    </div>
  );
};

export default DeletedComment;
