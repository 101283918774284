// libs
import produce from 'immer';

// interfaces / constants
import {
  GROUPS_FETCH_MORE_GROUPS_ERROR,
  GROUPS_FETCH_MORE_GROUPS_PENDING,
  GROUPS_FETCH_MORE_GROUPS_SUCCESS,
  GROUPS_FETCH_GROUP_SUCCESS,
  GROUPS_FETCH_JOINED_GROUPS_ERROR,
  GROUPS_FETCH_JOINED_GROUPS_PENDING,
  GROUPS_FETCH_JOINED_GROUPS_SUCCESS,
  GROUP_ADD_JOINED,
  GROUP_CLEAR_ALL,
  IFetchMoreGroupsActionError,
  IFetchMoreGroupsActionPending,
  IFetchMoreGroupsActionSuccess,
  IFetchGroupActionError,
  IFetchGroupActionPending,
  IFetchGroupActionSuccess,
  IFetchJoinedGroupsActionError,
  IFetchJoinedGroupsActionPending,
  IFetchJoinedGroupsActionSuccess,
  IAddJoinedGroupAction,
  IClearAllGroupsAction,
} from 'src/actions/groups/groups';
import { IErrorResponse } from 'src/api/interfaces/errors';
import {
  IMoreGroupsData,
  GroupsRequest,
  IGroupMap,
  IGroupParticipation,
  IGroup,
  IGroupsData, MoreGroupsRequest,
} from 'src/interfaces/group';
import { IPagination } from 'src/interfaces/pagination';
import { addToJoinedGroups, addToPendingGroups } from './helpers';

// helper & types
import {
  Appending,
  AppendingFail,
  Failure,
  Loading,
  NotRequested,
  Success,
  isSuccess,
  hasData,
} from './types/remote_data';

export const INITIAL_PAGINATION: IPagination = {
  currentPage: 1,
  lastPage: true,
  total: 1,
  totalPages: 1,
};

export interface IGroups {
  joinedGroupsRequest: GroupsRequest;
  moreGroupsRequest: MoreGroupsRequest;
  groupMap: IGroupMap;
  members: IGroupParticipation[];
  membersIsLoading: boolean;
  membersPagination: IPagination;
}

export const INITIAL_STATE: IGroups = {
  groupMap: {},
  joinedGroupsRequest: NotRequested,
  members: [],
  membersIsLoading: false,
  membersPagination: INITIAL_PAGINATION,
  moreGroupsRequest: NotRequested,
};

type Actions =
  IFetchMoreGroupsActionError |
  IFetchMoreGroupsActionPending |
  IFetchMoreGroupsActionSuccess |
  IFetchGroupActionError |
  IFetchGroupActionPending |
  IFetchGroupActionSuccess |
  IFetchJoinedGroupsActionError |
  IFetchJoinedGroupsActionPending |
  IFetchJoinedGroupsActionSuccess |
  IAddJoinedGroupAction |
  IClearAllGroupsAction
;

const convertError = (error: IErrorResponse | Error): IErrorResponse => {
  if (error instanceof Error) {
    return {
      code: error.name,
      message: error.message,
      status: 'error',
    };
  }
  return error;
};

const groupsReducer = (state = INITIAL_STATE, action: Actions): IGroups =>
  produce(state, (draft) => {
    const addToGroupMap = (groups: IGroup[]) => {
      for (const group of groups) {
        draft.groupMap[group.id] = group;
      }
    };

    switch (action.type) {
      case GROUP_CLEAR_ALL:
        return INITIAL_STATE;
      case GROUPS_FETCH_MORE_GROUPS_PENDING:
        if (hasData(draft.moreGroupsRequest) && action.meta.page !== undefined) {
          draft.moreGroupsRequest = Appending(draft.moreGroupsRequest.data);
          return;
        }
        draft.moreGroupsRequest = Loading;
        break;

      case GROUPS_FETCH_MORE_GROUPS_ERROR:
        if (hasData(draft.moreGroupsRequest)) {
          draft.moreGroupsRequest = AppendingFail(draft.moreGroupsRequest.data, convertError(action.payload));
          return;
        }
        draft.moreGroupsRequest = Failure(convertError(action.payload));
        break;

      case GROUPS_FETCH_MORE_GROUPS_SUCCESS:
        const groupIds = action.payload.data.map((group) => group.id);
        const pagination = action.payload.meta;
        if (hasData(draft.moreGroupsRequest)) {
          draft.moreGroupsRequest = Success<IMoreGroupsData>({
            groupIds: draft.moreGroupsRequest.data.groupIds.concat(groupIds),
            pagination,
          });
        } else {
          draft.moreGroupsRequest = Success<IMoreGroupsData>({
            groupIds,
            pagination,
          });
        }
        addToGroupMap(action.payload.data);
        break;

      case GROUPS_FETCH_JOINED_GROUPS_PENDING:
        draft.joinedGroupsRequest = Loading;
        break;

      case GROUPS_FETCH_JOINED_GROUPS_ERROR:
        draft.joinedGroupsRequest = Failure(convertError(action.payload));
        break;

      case GROUPS_FETCH_JOINED_GROUPS_SUCCESS:
        const [joinedGroupIds, pendingGroupIds] = action.payload.data
          .reduce<[string[], string[]]>((acc, { id, isMember, isParticipating }) => {
          if (isMember) {
            acc[0].push(id);
          } else if (isParticipating) {
            acc[1].push(id);
          }
          return acc;
        }, [[], []]);

        draft.joinedGroupsRequest = Success<IGroupsData>({
          joinedGroupIds,
          pagination: action.payload.meta,
          pendingGroupIds,
        });
        addToGroupMap(action.payload.data);
        break;

      case GROUP_ADD_JOINED:
        if (isSuccess(draft.moreGroupsRequest)) {
          draft.moreGroupsRequest = Success<IMoreGroupsData>({
            ...draft.moreGroupsRequest.data,
            groupIds: draft.moreGroupsRequest.data.groupIds
              .filter((groupId) => groupId !== action.payload.id),
          });
        }

        if (action.payload.isMember) {
          addToJoinedGroups(draft, action.payload.id);
        } else if (action.payload.isParticipating) {
          addToPendingGroups(draft, action.payload.id);
        } else {
          // Should never occur
          const error = `Added group with id ${action.payload.id} is neither private or open`;
          // eslint-disable-next-line no-console
          console.error(error);
          throw new Error(error);
        }
        addToGroupMap([action.payload]);
        break;

      case GROUPS_FETCH_GROUP_SUCCESS:
        const successData = Success<IMoreGroupsData>({
          groupIds: [action.payload.id],
          pagination: INITIAL_PAGINATION,
        });

        draft.groupMap = { [action.payload.id]: action.payload };
        if (action.payload.isMember) {
          draft.moreGroupsRequest = successData;
          return;
        }
        draft.moreGroupsRequest = successData;
        break;
    }
    return draft;
  });

export default groupsReducer;
