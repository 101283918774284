// libs
import * as React from 'react';

// components
import Icon from 'src/components/forms/icon/icon';
import Popover from 'src/components/popover/popover';
import {
  IShareProps,
} from 'src/components/share/lib/share';
import { nativeShare } from 'src/components/share/lib/utils';
import ShareItemList from 'src/components/share/share_item_list/share_item_list';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import { textResources } from 'src/lang/de';
import './share_popup_vertical.scss';

const cls = CLASS_PREFIX + 'share-popup-vertical';

interface IShareMenuButtonProps {
  onClick?: () => void;
}

const ShareMenuButton: React.FC<IShareMenuButtonProps> = (props) => (
  <button onClick={props.onClick} className={cls} >
    <Icon size={24} name='share' description={textResources.share.share} />
  </button>
);

const SharePopupVertical: React.FC<IShareProps> = ({ url, utmSource, tracking, ...props }) => {
  // @ts-ignore
  if (navigator.share) {
    return <ShareMenuButton onClick={nativeShare(url, utmSource, tracking)} />;
  }
  return (
    <Popover
      position={'bottom'}
      align={'relative'}
      layout={'plain'}
      trigger={
        <ShareMenuButton />
      }
    >
      <ShareItemList
        url={url}
        utmSource={utmSource}
        tracking={tracking}
        {...props}
      />
    </Popover>
  );
};

export default SharePopupVertical;
