// lib
import React, { useCallback } from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import ReactedProfilesList from 'src/containers/smart_components/post/reacted_profiles_list/reacted_profiles_list';
import LayoutNoMap from 'src/layout/layout_no_map';

// constants & interfaces
import { TWO_COLUMNS } from 'src/constants/layout';

// helpers
import BackbuttonHeader from 'src/components/header/common/backbutton_header/backbutton_header';
import { textResources } from 'src/lang/de';
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { getPageTitle } from 'src/utils/page_title/page_title';
import { UrlUtils } from 'src/utils/url/url';

interface IProps extends RouteComponentProps<{ id: string}> {
  isEvent?: boolean;
}

const PostReactionsPage: React.FC<IProps> = ({ history }) => {
  const user = useSelectUser();

  usePageTracking({ user });

  const handleBackButton = useCallback(() => {
    UrlUtils.goBack(history);
  }, [history]);

  return (
    <>
      <MetaTags />
      <LayoutNoMap
        contentWidth={TWO_COLUMNS}
        pageTitle={
          <StandardPageTitle
            text={getPageTitle(textResources.pageTitles.newsfeedSimple, textResources.pageTitles.newsfeed)}
            showLocation />
        }
        subHeader={<BackbuttonHeader handleBackButton={handleBackButton} title={textResources.reactions.reactions} />}
      >
        <ReactedProfilesList />
      </LayoutNoMap>
    </>
  );
};

export default PostReactionsPage;
