// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IIngredient, ITitle } from 'src/interfaces/post_ingredients';
import {
  mapDisplayModeToConst,
  POST_DISPLAY_MODE_BANNER,
  POST_DISPLAY_MODE_COMPACT,
  POST_DISPLAY_MODE_DETAIL,
  POST_DISPLAY_MODE_PREVIEW,
} from 'src/interfaces/posts';

// classes
import NavigationItem from 'src/components/navigation_item/navigation_item';

// styles
import './title.scss';

export type IProps = IIngredient<ITitle>;

export const cls = `${CLASS_PREFIX}title__`;

const Title: React.FC<IProps> = ({ data, displayMode, postDetailsURL }) => {
  const clsWithDisplayMode = `${cls}${mapDisplayModeToConst[displayMode]}`;

  const renderHeading = (className: string): JSX.Element => {
    const { title } = data;

    switch (displayMode) {
      case POST_DISPLAY_MODE_DETAIL:
        return <h1 className={className}>{title}</h1>;
      case POST_DISPLAY_MODE_PREVIEW:
        return <h2 className={className}>{title}</h2>;
      case POST_DISPLAY_MODE_BANNER:
      case POST_DISPLAY_MODE_COMPACT:
        return <h3 className={className}>{title}</h3>;
      default:
        return <h2 className={className}>{title}</h2>;
    }
  };

  return (
    <NavigationItem target='_self' url={postDetailsURL} >
      <div className={clsWithDisplayMode}>
        {/* this ellipsis-container is used by the RelatedContent component, see there */}
        <div className={`${clsWithDisplayMode}__ellipsis-container`}>
          {renderHeading(`${clsWithDisplayMode}__text`)}
        </div>
      </div>
    </NavigationItem>
  );
};

export default Title;
