// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import NewsFeed from 'src/containers/smart_components/feeds/news_feed';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';

// constants & interfaces
import { FEED_TYPE_NEWS, FEED_WITH_GROUPED_POSTS_DEFAULT_PER_PAGE } from 'src/constants/feed';
import {
  DEFAULT_OG_URL,
  NEWSFEED_TITLE_ADDITIONAL_PAGE,
  NEWSFEED_TITLE_FIRST_PAGE,
  NEWSFEED_DESCRIPTION_ADDITIONAL_PAGE,
  NEWSFEED_DESCRIPTION_FIRST_PAGE,
  NEWSFEED_OG_IMAGE,
  DEFAULT_ROBOTS,
} from 'src/constants/meta_tags';
import { NEWS_FEED_PARTIAL } from 'src/constants/urls';
import { textResources } from 'src/lang/de';

// helpers
import { useFeedWithPagination } from 'src/hooks_shared/use_feed_pagination';
import { usePageTracking, useIsPostCreateOpen } from 'src/pages/hooks/hooks';
import { useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectCanonicalShapeSlug } from 'src/reducers/feed/feed_hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { getPageTitle } from 'src/utils/page_title/page_title';
import { LayoutFeaturedContainer } from '../layout/layout_featured_container';

const NewsfeedPage: React.FC<RouteComponentProps> = ({ match }) => {
  const locationShape = useGetAppLocationShape();
  const user = useSelectUser();
  const isPostCreateOpen = useIsPostCreateOpen(match);
  const canonicalShapeSlug = useSelectCanonicalShapeSlug(FEED_TYPE_NEWS);
  const { page, totalPages, getPageUrl } = useFeedWithPagination(FEED_TYPE_NEWS);

  const canonicalUrlPrefix = `${DEFAULT_OG_URL}${canonicalShapeSlug}${NEWS_FEED_PARTIAL}`;

  const startIndex = (page - 1) * FEED_WITH_GROUPED_POSTS_DEFAULT_PER_PAGE + 1;
  const endIndex = page * FEED_WITH_GROUPED_POSTS_DEFAULT_PER_PAGE;

  const canonicalUrl = page <= 1 ? canonicalUrlPrefix : canonicalUrlPrefix + '?page=' + page;
  const title =
    locationShape
      ? page <= 1
        ? NEWSFEED_TITLE_FIRST_PAGE(locationShape.name)
        : NEWSFEED_TITLE_ADDITIONAL_PAGE(locationShape.name, page, totalPages)
      : undefined;
  const description =
    locationShape
      ? page <= 1
        ? NEWSFEED_DESCRIPTION_FIRST_PAGE(locationShape.name)
        : NEWSFEED_DESCRIPTION_ADDITIONAL_PAGE(startIndex, endIndex, locationShape.name)
      : undefined;

  usePageTracking({ pageTitle: title, user });

  return (
    <>
      <MetaTags
        canonical={canonicalUrl}
        stripParamsFromCanonicalUrl={false}
        description={description}
        geo_placename={locationShape?.name}
        geo_position={locationShape && `${locationShape.latitude};${locationShape.longitude}`}
        robots={DEFAULT_ROBOTS}
        og_image={NEWSFEED_OG_IMAGE}
        og_url={document.location.toString()}
        title={title}
        first_page={getPageUrl(1)}
        last_page={totalPages ? getPageUrl(totalPages) : undefined}
        next_page={page && page < totalPages ? getPageUrl(page + 1) : undefined}
        prev_page={page && page > 1 ? getPageUrl(page - 1) : undefined}
      />
      <LayoutFeaturedContainer
        activeNavigationItem={FEED_TYPE_NEWS}
        pageTitle={
          <StandardPageTitle
            text={getPageTitle(textResources.pageTitles.newsfeedSimple, textResources.pageTitles.newsfeed)}
            showLocation />
        }
        withHeaderMargin>
        <NewsFeed isPostCreateOpen={isPostCreateOpen} initialPage={page} />
      </LayoutFeaturedContainer>
    </>
  );
};

export default NewsfeedPage;
