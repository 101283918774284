// lib
import React from 'react';
import { RouteComponentProps } from 'react-router';

// components
import MetaTags from 'src/components/meta_tags/meta_tags';
import HeaderProfilesDirectory from 'src/containers/smart_components/header/header_profiles_directory';
import StandardPageTitle from 'src/containers/smart_components/page_title/page_title';
import ProfilesDirectoryCategory from 'src/containers/smart_components/profiles_directory/profiles_directory_category';

// constants & interfaces
import { PROFILES_DIRECTORY } from 'src/constants/feed';
import {
  PROFILES_DIRECTORY_CATEGORY_DESCRIPTION_AUTHORITY_PROFILES,
  PROFILES_DIRECTORY_CATEGORY_DESCRIPTION_PRESS_PROFILES,
  PROFILES_DIRECTORY_CATEGORY_DESCRIPTION_PRIVATE_PROFILES,
  PROFILES_DIRECTORY_CATEGORY_TITLE_AUTHORITY_PROFILES,
  PROFILES_DIRECTORY_CATEGORY_TITLE_PRESS_PROFILES,
  PROFILES_DIRECTORY_CATEGORY_TITLE_PRIVATE_PROFILES,
} from 'src/constants/meta_tags';
import { textResources } from 'src/lang/de';

// helpers
import { useAuthorTypeFromURL } from 'src/actions/profiles_list/profiles_list_hooks';
import { usePageTracking } from 'src/pages/hooks/hooks';
import { useGetAppLocationShape } from 'src/reducers/app-state/app-state_hooks';
import { useSelectUser } from 'src/reducers/user/user_hooks';
import { getPageTitle } from 'src/utils/page_title/page_title';
import { LayoutFeaturedContainer } from '../layout/layout_featured_container';

const ProfilesDirectoryCategoryPage: React.FC<RouteComponentProps> = () => {
  const user = useSelectUser();
  const locationShape = useGetAppLocationShape();
  const authorType = useAuthorTypeFromURL();

  let title = '';
  let description = '';
  if (locationShape) {
    switch (authorType) {
      case 'press_profile': {
        title = PROFILES_DIRECTORY_CATEGORY_TITLE_PRESS_PROFILES(locationShape.name);
        description = PROFILES_DIRECTORY_CATEGORY_DESCRIPTION_PRESS_PROFILES(locationShape.name);
        break;
      }
      case 'private_profile': {
        title = PROFILES_DIRECTORY_CATEGORY_TITLE_PRIVATE_PROFILES(locationShape.name);
        description = PROFILES_DIRECTORY_CATEGORY_DESCRIPTION_PRIVATE_PROFILES(locationShape.name);
        break;
      }
      default: {
        title = PROFILES_DIRECTORY_CATEGORY_TITLE_AUTHORITY_PROFILES(locationShape.name);
        description = PROFILES_DIRECTORY_CATEGORY_DESCRIPTION_AUTHORITY_PROFILES(locationShape.name);
      }
    }
  }

  usePageTracking({ pageTitle: title, user });

  return (
    <>
      <MetaTags
        description={description}
        title={title}
      />
      <LayoutFeaturedContainer
        activeNavigationItem={PROFILES_DIRECTORY}
        pageTitle={
          <StandardPageTitle
            text={getPageTitle(textResources.pageTitles.profileSearchSimple, textResources.pageTitles.profileSearch)}
            showLocation
          />
        }
        subHeader={<HeaderProfilesDirectory />}
        withHeaderMargin>
        <ProfilesDirectoryCategory />
      </LayoutFeaturedContainer>
    </>
  );
};

export default ProfilesDirectoryCategoryPage;
