// libs
import { throttle } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

// interfaces / constants
import { GroupedPosts, IPostsRecord } from 'src/interfaces/feed';

// classes
import PostPreviewPlaceholder from 'src/components/content_placeholder/components/post_preview/post_preview';
import EmptyFeed from 'src/components/feed/empty_feed/empty_feed';
import Masonry from 'src/components/feed/layouts/masonry_feed/masonry/masonry';
import OpenGroupButton from 'src/components/post/open_group_button/open_group_button';
import PostPreview from 'src/components/post/preview/post_preview';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { useResizeListener } from 'src/hooks_shared/use_event_listener';
import { textResources } from 'src/lang/de';
import { readColumnCountFromDOM } from 'src/utils/column_count';
import { IPost } from '../../../../interfaces/posts';

export interface IFeedProps {
  groups: GroupedPosts;
  isLoading: boolean;
  posts: IPostsRecord;
  renderPostCreate?: () => JSX.Element;
  showEmptyFeed?: boolean;
}

interface IProps extends IFeedProps {
  extraElements?: Array<JSX.Element | undefined>;
  openGroup?: (index: number) => void;
}

const MasonryFeed: React.FC<IProps> = ({
  extraElements,
  groups,
  isLoading,
  openGroup,
  posts,
  renderPostCreate,
  showEmptyFeed = true,
}) => {
  const [columnCount, setColumnCount] = useState(() => readColumnCountFromDOM());
  const listener = useCallback(
    throttle(() => {
      setColumnCount(readColumnCountFromDOM());
    }, 100),
    [],
  );

  useEffect(() => {
    setColumnCount(readColumnCountFromDOM());
  }, []);

  useResizeListener(listener, { passive: true });

  const onHandleOpenGroup = useCallback((index: number) => () => openGroup?.(index), [openGroup]);

  const isOnMobileBreakpoint = columnCount === 1;

  const tilesList = useMemo(() => {
    let tiles = [];
    renderPostCreate && !isOnMobileBreakpoint && tiles.push(renderPostCreate());
    extraElements && tiles.push(...extraElements);

    if (groups.length) {
      tiles.push(...renderPosts(groups, posts, onHandleOpenGroup));
    } else {
      if (!isLoading && showEmptyFeed) {
        tiles.push(<EmptyFeed key='empty-feed' />);
      }
    }

    isLoading && tiles.push(...renderPostsPlaceholder);
    return tiles;
  },
  [extraElements, groups, isLoading, isOnMobileBreakpoint, onHandleOpenGroup, posts, renderPostCreate, showEmptyFeed]
  );

  return (
    <section>
      {renderPostCreate && isOnMobileBreakpoint && renderPostCreate()}
      <Masonry>
        {tilesList}
      </Masonry>
    </section>
  );
};

const renderPostsPlaceholder: JSX.Element[] = [
  <PostPreviewPlaceholder key='masonry-1'/>,
  <PostPreviewPlaceholder key='masonry-2'/>,
  <PostPreviewPlaceholder key='masonry-3'/>,
];

const renderPosts = (
  groupedPostsSlugs: GroupedPosts,
  posts: IPostsRecord,
  onHandleOpenGroup: (index: number) => () => void
): JSX.Element[] => {
  // If all posts in a group are surveys, then render them all
  if (groupedPostsSlugs.every((group) => group.ids.filter(id => posts[id].survey).length)) {
    const surveyPosts: IPost[] = [];
    groupedPostsSlugs.forEach(({ ids }) => {
      for (const postId in posts) {
        if (ids.includes(postId)) {
          surveyPosts.push(posts[postId]);
        }
      }
    });
    return renderSurveys(surveyPosts);
  }

  return groupedPostsSlugs.map(({ ids }, index) => (
    <React.Fragment key={'post' + index}>
      <UserOnboardingCTA active={posts[ids[0]].meta.fenced} gtmSourceName='feed'>
        <PostPreview {...posts[ids[0]]} />
        {ids.length > 1 &&
        <OpenGroupButton
          icon={'arrow-down'}
          label={textResources.newsfeed.moreItemsInPostGroup(ids.length - 1)}
          onClick={onHandleOpenGroup(index)}
        />
        }
      </UserOnboardingCTA>
    </React.Fragment>
  ));
};

const renderSurveys = (surveys: IPost[]) =>
  surveys.map(survey =>
    <React.Fragment key={'survey-' + survey.id}>
      <UserOnboardingCTA active={survey.meta.fenced} gtmSourceName='feed'>
        <PostPreview {...survey} />
      </UserOnboardingCTA>
    </React.Fragment>
  );

export default MasonryFeed;
