// libs
import React, { useMemo } from 'react';
import { IFeedProps } from 'src/components/feed/layouts/masonry_feed/masonry_feed';

// classes
import BannerFeedPlaceholder
  from 'src/components/content_placeholder/layouts/banner_feed_placeholder/banner_feed_placeholder';
import EmptyFeed from 'src/components/feed/empty_feed/empty_feed';
import PostBannerGroup from 'src/components/post/banner/post_banner_group';

interface IProps extends IFeedProps {
  renderFeedTitle?: () => JSX.Element;
  showAllTopLink?: boolean;
  showGroupLabels?: boolean;
}

const BannerFeed: React.FC<IProps> = ({
  groups,
  isLoading,
  posts,
  renderPostCreate,
  renderFeedTitle,
  showAllTopLink,
  showEmptyFeed = true,
  showGroupLabels,
}) => {
  const renderBannerFeed = useMemo(() => (
      <>
        {groups.map(({ ids, label, slug }, index) => (
          <PostBannerGroup
            key={index}
            group={ids}
            posts={posts}
            title={showGroupLabels ? label : undefined}
            slug={slug}
            showAllTopLink={showAllTopLink}
          />
        ))}
      </>
  ), [groups, posts, showAllTopLink, showGroupLabels]);

  const renderEmptyFeed = () => {
    if (isLoading) {
      return <BannerFeedPlaceholder />;
    }

    if (!showEmptyFeed) {
      return null;
    }

    return <EmptyFeed />;
  };

  const isEmpty = !groups.length;

  return (
    <section>
      {renderPostCreate && renderPostCreate()}
      {renderFeedTitle && renderFeedTitle()}
      {isEmpty
        ? renderEmptyFeed()
        : renderBannerFeed
      }
    </section>
  );
};

export default BannerFeed;
