/*
 * Usage:
 *
 * <Uploader onImageAdded={onImageAddedCallback}>
 *   <div className='YOUR_DROPZONE_BACKGROUND'>
 *     <p>YOUR_DROPZONE_TEXT</p>
 *   </div>
 * </Uploader>
 *
 * It is advised to use the UploadedImages component to display the uploaded
 * images, as this Uploader component does not render them any more.
 */

// libs
import classNames from 'classnames';
import * as React from 'react';
import Dropzone, { ImageFile } from 'react-dropzone';

// components
import LoadingSpinnerFullscreen from 'src/components/loading_spinner/loading_spinner_fullscreen';
import SnackBar from 'src/components/snack_bar/snack_bar';

// interfaces / constants
import { IErrorResponse } from 'src/api/interfaces/errors';
import { CLASS_PREFIX } from 'src/constants/';
import { IWindowJsEnv } from 'src/interfaces/';
import { textResources } from 'src/lang/de';

// utils
import { compressImage } from 'src/utils/image-compressor';

import './uploader.scss';

interface IProps {
  onError?: (error: IErrorResponse) => void;
  onImageAdded: (images: string[]) => void;
  activeClassName?: string;
  className?: string;
  disabled?: boolean;
  disabledClassName?: string;
  images?: string[];
  rejectClassName?: string;
  openOnStart?: boolean;
  multiple?: boolean;
  disableClick?: boolean;
  maxImageNumber?: number;
  maxImageNumberError?: string;
  onCancel?: () => void;
}

interface IState {
  errorMessage?: string;
  isImageUploading: boolean;
}

const cls = CLASS_PREFIX + 'uploader';

export default class Uploader extends React.PureComponent<IProps, IState> {
  private dropzone: Dropzone;
  constructor(props: IProps) {
    super(props);

    this.onDrop = this.onDrop.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.hideError = this.hideError.bind(this);

    this.state = {
      errorMessage: undefined,
      isImageUploading: false,
    };
  }

  public componentDidMount() {
    const { openOnStart } = this.props;
    openOnStart && this.dropzone && this.dropzone.open();
  }

  public onDrop(acceptedFiles?: ImageFile[]) {
    if (!acceptedFiles) {
      return;
    }

    const { onImageAdded, onError, maxImageNumber, maxImageNumberError } = this.props;
    const images: string[] = [];
    let pendingFileReadCounter = 0;

    this.setState({ isImageUploading: true });

    acceptedFiles.map((file: ImageFile, index: number) => {
      pendingFileReadCounter++;

      compressImage(file).then((compressedImage: Blob) => {
        const reader = new (window as unknown as IWindowJsEnv).FileReader();
        reader.readAsDataURL(compressedImage);
        reader.onloadend = () => {
          images[index] = reader.result;
          pendingFileReadCounter--;
          if (pendingFileReadCounter === 0) {
            if (maxImageNumber !== undefined && (images.length > maxImageNumber)) {
              this.setState({
                errorMessage: maxImageNumberError || textResources.postCreate.mediaMaxImagesGenericError,
                isImageUploading: false,
              });

              maxImageNumber > 0 && onImageAdded(images.slice(0, maxImageNumber));
            } else {
              this.setState({ isImageUploading: false });
              onImageAdded(images);
            }
          }
        };
      }).catch((reason: IErrorResponse) => {
        pendingFileReadCounter--;
        if (onError) {
          onError(reason);
        }
      });
    });
  }

  public open() {
    this.dropzone && this.dropzone.open();
  }

  public render() {
    const {
      activeClassName = `${cls}--active`,
      className,
      disabled = false,
      disabledClassName = `${cls}--disabled`,
      rejectClassName = `${cls}--reject`,
      multiple = true,
      disableClick = false,
      children,
      onCancel,
    } = this.props;

    const { errorMessage, isImageUploading } = this.state;

    return (
      <>
        {errorMessage && <SnackBar message={errorMessage} showClose onClose={this.hideError} />}
        <Dropzone
          multiple={multiple}
          ref={this.handleRef}
          onDrop={this.onDrop}
          onFileDialogCancel={onCancel}
          accept='image/png, image/jpg, image/jpeg, image/gif'
          activeClassName={activeClassName}
          className={classNames(cls, className)}
          disabledClassName={disabledClassName}
          rejectClassName={rejectClassName}
          disableClick={disableClick}
          disabled={disabled}
        >
          {children}
        </Dropzone>
        {isImageUploading && <LoadingSpinnerFullscreen shown />}
      </>
    );
  }

  private handleRef(ref: Dropzone) {
    this.dropzone = ref;
  }

  private hideError() {
    this.setState({
      errorMessage: undefined,
    });
  }
}
