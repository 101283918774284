// libs
import classNames from 'classnames';
import { History } from 'history';
import * as React from 'react';
// interfaces / constants
import { THUMBNAIL_SIZE_TYPE_XXXLARGE } from 'src/components/thumbnail/thumbnail';
import { PRIVATE_PROFILE_TYPE } from 'src/constants/profile';
import { BRONZE, SILVER } from 'src/constants/shape_sponsors';
import {
  FOLLOWERS_PARTIAL,
  FRONTEND_EDIT_DESCRIPTION_PATH,
  FRONTEND_EDIT_IMPRESSUM_PATH,
  FRONTEND_EDIT_INTERNET_SITE_PATH,
  FRONTEND_EDIT_TAGS_PATH,
  FRONTEND_SETTINGS_PATH,
} from 'src/constants/urls';
import { ILocationShape, IPosition } from 'src/interfaces/location';
import { IProfile, ProfileType } from 'src/interfaces/profile';
import { IUserProfileType } from 'src/interfaces/user';
import { COLOR_TYPE_PRIMARY } from 'src/utils/color';

// classes
import Avatar from 'src/components/avatar/avatar';
import Button, { BUTTON_TYPE_CONTAINED } from 'src/components/forms/button/button';
import Icon from 'src/components/forms/icon/icon';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import Tag from 'src/components/tag/tag';
import FollowButton from 'src/containers/smart_components/follow_button/follow_button';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import Translate, { textResources } from 'src/lang/de';
import { getSponsorBadge } from 'src/utils/badges';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { getConfigValue } from 'src/utils/config/config';
import { isAuthorityProfile, isBlogProfile, isPressProfile, isPrivateProfile } from 'src/utils/profile';
import { makeHTMLLinksFromText } from 'src/utils/string/string';
import { UrlUtils } from 'src/utils/url/url';

import 'src/components/profile/profile_info.scss';

interface IProps {
  position?: IPosition;
  changePosition: (locationShape: ILocationShape) => void;
  profile: IProfile;
  history: History;
  userType: IUserProfileType;
}

interface IState {
  profile?: IProfile;
  showImprint: boolean;
  showMenu: boolean;
}
const cls = createBemBlock('profile-info');
const labels = textResources.profile;
const sharedLabels = textResources.shared;

const getProfileLabel = (
  profile: IProfile,
  locationShapeName: string | undefined,
  showLocation: boolean | undefined,
): string | null => {
  const profileType = profile.type;

  if (profile.sponsorLevel) {
    switch (profile.sponsorLevel) {
      case BRONZE:
        return textResources.shapeSponsors.bronzePartner;
      case SILVER:
        return textResources.shapeSponsors.silverPartner;
    }
  }

  if (isAuthorityProfile(profileType)) {
    return labels.authorityProfile;
  }

  return getLabelWithLocation(profileType, locationShapeName, showLocation);
};

const getLabelWithLocation = (profileType: ProfileType, locationShapeName?: string, showLocation?: boolean) => {
  if (isPrivateProfile(profileType)) {
    return showLocation ? `${labels.neighborFromMale} ${locationShapeName}` : labels.neighborMale;
  }
  if (isPressProfile(profileType)) {
    return showLocation ? `${labels.pressProfile} ${locationShapeName}` : labels.press;
  }
  if (isBlogProfile(profileType)) {
    return showLocation ? `${labels.blogProfile} ${locationShapeName}` : labels.blog;
  }
  return Translate.authorType[profileType];
};

class ProfileInfo extends React.PureComponent<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      showImprint: false,
      showMenu: false,
    };

    this.onMessage = this.onMessage.bind(this);
    this.toggleImprint = this.toggleImprint.bind(this);
    this.renderEditIcon = this.renderEditIcon.bind(this);
    this.renderVerifiedIcon = this.renderVerifiedIcon.bind(this);
  }

  public componentDidMount() {
    this.setAppLocation();
  }

  public componentDidUpdate(prevProps: IProps) {
    const { profile } = this.props;
    if (prevProps.profile !== profile) {
      this.setState({ profile });
    }

    this.setAppLocation();
  }

  public render() {
    const { profile } = this.props;
    const { imageUrl, name, locationShape, sponsorLevel } = profile;
    const { name: locationShapeName, slug } = locationShape || {};

    return (
      <div className={cls()}>
        <div className={cls('tile')}>
          <div className={cls('header')}>
            <Avatar
              image={imageUrl}
              size={THUMBNAIL_SIZE_TYPE_XXXLARGE}
              badge={sponsorLevel && getSponsorBadge(sponsorLevel)}
            />
            <div className={cls('title')}>
              <h1 className={cls('name')}>{name}</h1>
              {slug && <p className={cls('description')}>
                {getProfileLabel(profile, locationShapeName, getConfigValue('featureFlags.map.enabled'))}
                {this.renderVerifiedIcon()}
              </p>}
            </div>
          </div>
          {this.renderContent()}
          {this.renderPublicInfo()}
          {this.renderImprint()}
          {this.renderTags()}
          <div className={cls('toolbar')}>
            {this.renderToolbar()}
          </div>
        </div>
      </div>
    );
  }

  private renderToolbar(): JSX.Element | null {
    const { profile } = this.props;

    // for the right aligned design we styled this in reversed order from right to left
    if (profile.meta.isOwn) {
      return this.renderOwnProfilesToolbar(profile);
    }

    return this.renderOtherProfilesToolbar(profile);
  }

  private renderOwnProfilesToolbar(profile: IProfile): JSX.Element | null {
    const { isFollowable } = profile.meta;

    return (
      <>
        <NavigationItem
          target={'_self'}
          url={FRONTEND_SETTINGS_PATH}
          className={cls('edit-button')}
        >
          {textResources.profile.editProfile}
        </NavigationItem>
        {isFollowable && <NavigationItem
          target={'_self'}
          url={UrlUtils.getProfileFrontendPath(profile.id, profile.type) + FOLLOWERS_PARTIAL}
        >
          {textResources.follow.showFollowers}
        </NavigationItem>}
      </>
    );
  }

  private renderOtherProfilesToolbar(profile: IProfile) {
    const { isMessagable, isFollowable } = profile.meta;
    const { canMessage } = profile.permissions;

    if (isFollowable) {
      return (
        <>
          {isMessagable && <UserOnboardingCTA active={!canMessage}>
            <NavigationItem target={'_self'} onClick={this.onMessage}>
              {sharedLabels.writeMessage}
            </NavigationItem>
          </UserOnboardingCTA>}
          <FollowButton
            followableId={profile.id}
          />
        </>
      );
    }

    if (isMessagable) {
      return (
        <UserOnboardingCTA active={!canMessage} className={cls('feature-blocker')}>
          <Button
            onClick={this.onMessage}
            label={sharedLabels.sayHello}
            variant={BUTTON_TYPE_CONTAINED}
            fullWidth
            color={COLOR_TYPE_PRIMARY}
            lowerCase
          />
        </UserOnboardingCTA>
      );
    }

    return null;
  }

  private renderContent(): JSX.Element | null {
    const { profile: { description, meta } } = this.props;

    if (!description && !meta.isOwn) {
      return null;
    }
    const message =
      makeHTMLLinksFromText(description) || textResources.onboardingSettings.profileDescriptionPlaceholder;
    return (
      <div className={cls('content')}>
        <p className={cls('content-title')}>{labels.aboutMe}</p>
        {meta.isOwn
          ? <p>
            <span className={cls('content-body')} dangerouslySetInnerHTML={{ __html: message }} />
            {this.renderEditIcon(FRONTEND_EDIT_DESCRIPTION_PATH)}
          </p>
          : <p className={cls('content-body')} dangerouslySetInnerHTML={{ __html: message }} />
        }
      </div>
    );
  }

  private renderVerifiedIcon(): JSX.Element | null {
    if (!isAuthorityProfile(this.props.profile.type)) {
      return null;
    }

    return <Icon
      className={cls('icon-verified')}
      name={'checkmark'}
      size={12}
      description={labels.authorityProfile}
      color={COLOR_TYPE_PRIMARY}
    />;
  }

  private renderEditIcon(url: string): JSX.Element | null {
    if (!this.props.profile.meta.isOwn) {
      return null;
    }
    return (
      <NavigationItem url={url} target='_self' className={cls('icon')}>
        <Icon
          className={cls('icon', 'right')}
          name={'pencil'}
          size={12}
          description={sharedLabels.edit}
        />
      </NavigationItem>
    );
  }

  private renderPublicInfo(): JSX.Element | null {
    const { profile: { type, meta, siteNotice, website } } = this.props;
    if (type === PRIVATE_PROFILE_TYPE) {
      return null;
    }

    const clsActive = {
      [cls('item', 'active')]: this.state.showImprint,
    };

    return (
      <ul className={cls('public')}>
        {(website || meta.isOwn) &&
        <li className={cls('item')}>
          <NavigationItem url={website} target={'_blank'} className={cls('navigation')}>
            <Icon name={'world'} className={cls('icon', 'left')} size={16} />
            {website || 'Webseite'}
          </NavigationItem>
          {this.renderEditIcon(FRONTEND_EDIT_INTERNET_SITE_PATH)}
        </li>
        }
        {(siteNotice || meta.isOwn) &&
        <li className={classNames(cls('item'), clsActive)}>
          <button onClick={this.toggleImprint} disabled={!siteNotice} className={cls('navigation')}>
            <Icon name={'imprint'} className={cls('icon', 'left')} size={16} />
            {sharedLabels.imprint}
          </button>
          {this.renderEditIcon(FRONTEND_EDIT_IMPRESSUM_PATH)}
        </li>
        }
      </ul>
    );
  }

  private renderImprint(): JSX.Element | null {
    const { profile } = this.props;
    if (!this.state.showImprint || !profile.siteNotice) {
      return null;
    }

    const siteNoticeWithClickableLinks = makeHTMLLinksFromText(profile.siteNotice);

    return (
      <div className={cls('imprint')}>
        <p dangerouslySetInnerHTML={{ __html: siteNoticeWithClickableLinks }} />
      </div>
    );
  }

  private renderTags(): JSX.Element | null {
    const { profile: { tags, meta } } = this.props;

    if (!tags || tags.length === 0 && !meta.isOwn) {
      return null;
    }

    return (
      <div className={cls('content')}>
        <p className={cls('content-title')}>{labels.myInterests}</p>
        {tags.length > 0 &&
        <div className={cls('tags')}>
          {tags.map(tag => (<Tag text={tag.name} key={tag.name} className={cls('tag')} isClickable />))}
        </div>
        }
        {tags.length === 0 && meta.isOwn &&
        <p className={cls('add-tags-cta')}>
          {labels.giveYourInterests}
        </p>
        }
        {meta.isOwn &&
        <NavigationItem url={FRONTEND_EDIT_TAGS_PATH}>
          <Icon
            name='plus-circle'
            description={sharedLabels.addInterests}
            size={20}
            color={COLOR_TYPE_PRIMARY}
            className={cls('add-tag-icon')}
          />
        </NavigationItem>
        }
      </div>
    );
  }

  private toggleImprint() {
    this.setState({
      showImprint: !this.state.showImprint,
    });
  }

  private onMessage() {
    const { profile, history } = this.props;
    const { id, type } = profile;
    history.push(UrlUtils.sendMessageConversationPage(type, id));
  }

  private setAppLocation() {
    const { changePosition, profile, position } = this.props;
    // only set the app-position to the seen profile in deeplinking when no position from other sources
    if (!position && profile.locationShape) {
      changePosition(profile.locationShape);
    }
  }
}

export default ProfileInfo;
