import { ThunkAction } from 'redux-thunk';

// interfaces
import { IAction } from 'src/actions/';
import { IErrorResponse } from 'src/api/interfaces/errors';
import { IFollow } from 'src/interfaces/follow';
import { IRootState } from 'src/reducers/interface';

// actions
import { showSnackBar } from '../app-state/app-state';

// helpers
import api, {
  FollowParams,
  IFollowUpdateNotificationsByProfilesParams,
  IUnFollowByProfilesParams,
} from 'src/api/';
import { textResources } from 'src/lang/de';
import { handleNetworkError } from 'src/utils/api_error_message/api_error_message';

export const FOLLOW_REQUEST = 'FOLLOW_REQUEST';
export const FOLLOW_REQUEST_SUCCESS = 'FOLLOW_REQUEST_SUCCESS';
export const FOLLOW_REQUEST_PENDING = 'FOLLOW_REQUEST_PENDING';
export const FOLLOW_REQUEST_ERROR = 'FOLLOW_REQUEST_ERROR';

export const UPDATE_FOLLOW_NOTIFICATIONS_REQUEST = 'UPDATE_FOLLOW_NOTIFICATIONS_REQUEST';
export const UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_SUCCESS = 'UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_SUCCESS';
export const UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_PENDING = 'UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_PENDING';
export const UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_ERROR = 'UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_ERROR';

export const UNFOLLOW_REQUEST = 'UNFOLLOW_REQUEST';
export const UNFOLLOW_REQUEST_SUCCESS = 'UNFOLLOW_REQUEST_SUCCESS';
export const UNFOLLOW_REQUEST_PENDING = 'UNFOLLOW_REQUEST_PENDING';
export const UNFOLLOW_REQUEST_ERROR = 'UNFOLLOW_REQUEST_ERROR';

interface IFollowActionSuccess {
  payload: IFollow;
}

export interface ICreateFollowAction {
  meta: FollowParams;
}

export interface ICreateFollowActionSuccess extends ICreateFollowAction, IFollowActionSuccess {
  type: typeof FOLLOW_REQUEST_SUCCESS;
}

export interface ICreateFollowActionPending extends ICreateFollowAction {
  type: typeof FOLLOW_REQUEST_PENDING;
}

export interface ICreateFollowActionError extends ICreateFollowAction {
  type: typeof FOLLOW_REQUEST_ERROR;
}

export interface IUpdateFollowAction {
  meta: FollowParams;
}

export interface IUpdateFollowActionSuccess extends IUpdateFollowAction, IFollowActionSuccess {
  type: typeof UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_SUCCESS;
}

export interface IUpdateFollowActionPending extends IUpdateFollowAction {
  type: typeof UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_PENDING;
}

export interface IUpdateFollowActionError extends IUpdateFollowAction {
  type: typeof UPDATE_FOLLOW_NOTIFICATIONS_REQUEST_ERROR;
}

export interface IDestroyFollowAction {
  meta: IUnFollowByProfilesParams;
}

export interface IDestroyFollowActionSuccess extends IFollowActionSuccess, IDestroyFollowAction {
  type: typeof UNFOLLOW_REQUEST_SUCCESS;
}

export interface IDestroyFollowActionPending extends IDestroyFollowAction {
  type: typeof UNFOLLOW_REQUEST_PENDING;
}

export interface IDestroyFollowActionError extends IDestroyFollowAction {
  type: typeof UNFOLLOW_REQUEST_ERROR;
  payload: IErrorResponse;
}

export const follow = (param: FollowParams): ThunkAction<void, IRootState, {}, IAction> => {
  return dispatch => {
    dispatch({
      meta: param,
      payload: api.follow.follow(param)
        .then((follow) => {
          if (follow) {
            dispatch(showSnackBar({
              message: textResources.follow.followAlertMessage(follow.followable.name),
            }));
          }
        }).catch(handleNetworkError),
      type: FOLLOW_REQUEST,
    });
  };
};

export const updateFollow =
  (param: IFollowUpdateNotificationsByProfilesParams): ThunkAction<void, IRootState, {}, IAction> => {
    return dispatch => {
      dispatch({
        meta: param,
        payload: api.follow.updateNotifications(param).catch(handleNetworkError),
        type: UPDATE_FOLLOW_NOTIFICATIONS_REQUEST,
      });
    };
  };

export const unFollow = (param: IUnFollowByProfilesParams): ThunkAction<void, IRootState, {}, IAction> => {
  return dispatch => {
    dispatch({
      meta: param,
      payload: api.follow.unFollow(param).catch(handleNetworkError),
      type: UNFOLLOW_REQUEST,
    });
  };
};
