import React, { FunctionComponent, useMemo } from 'react';
import { useUserRequestActions } from 'src/actions/groups/groups-management';
import { IUserListAdminAction } from 'src/components/item_segment/layouts/profiles_list/model';
import ProfilesListItemSegment from 'src/components/item_segment/layouts/profiles_list/profiles_list';
import { getGroupMemberListAction } from 'src/containers/smart_components/group_member_list/confirmations';
import {
  GroupListProfileAvailableAction,
  GroupListProfileAvailableActions,
} from 'src/containers/smart_components/group_member_list/model';
import { IGroupListProfile } from 'src/interfaces/profile';
import { getSponsorBadge } from 'src/utils/badges';
import { UrlUtils } from 'src/utils/url/url';

interface IProps {
  profile: IGroupListProfile;
  actions: GroupListProfileAvailableActions;
}
const GroupMemberListItem: FunctionComponent<IProps> = ({ profile, actions }) => {
  const { id, sponsorLevel, imageUrl, name, description, type } = profile;
  const { accept, reject, remove } = useUserRequestActions(actions, name);

  const action: IUserListAdminAction = useMemo(() => ({
    accept: accept && getGroupMemberListAction(
      GroupListProfileAvailableAction.ACCEPT,
      () => accept(profile.urls?.confirm)
    ),
    reject: reject && getGroupMemberListAction(
      GroupListProfileAvailableAction.REJECT,
      () => reject(profile.urls?.eliminate)
    ),
    remove: remove && getGroupMemberListAction(
      GroupListProfileAvailableAction.REMOVE,
      () => remove(profile.urls?.eliminate)
    ),
  }), [accept, profile.urls, reject, remove]);

  return <ProfilesListItemSegment
    key={id}
    avatar={{ badge: sponsorLevel && getSponsorBadge(sponsorLevel), imageUrl }}
    label={name}
    description={description}
    action={action}
    navigation={{ url: UrlUtils.getProfileFrontendPath(id, type) }}
  />;
};

export default GroupMemberListItem;
