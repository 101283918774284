// interfaces
import * as Response from 'src/api/interfaces/responses/address';
import { IPublicAddress as ResponsePublicAddress } from 'src/api/interfaces/responses/shared';
import { IAddress, IPublicAddress } from 'src/interfaces/address';

// decoders
import { $locationShape } from 'src/api/decoders/location_shape';

export const $addressGeoLookup = (json: Response.IAddressesResponse): IAddress[] => json.data.map($address);

export const $address = (json: Response.IAddressResponse): IAddress => ({
  additionalStreet: json.additional_street,
  city: json.city,
  cityWithDistrict: json.city_with_district,
  county: json.county,
  description: json.description,
  district: json.district,
  googlePlaceId: json.google_place_id,
  latitude: json.latitude,
  locationShape: $locationShape(json.location_shape),
  longitude: json.longitude,
  state: json.state,
  street: json.street,
  zip: json.zip,
});

export const $publicAddress = (json: ResponsePublicAddress): IPublicAddress => ({
  city: json.city,
  cityWithDistrict: json.city_with_district,
  description: json.description || undefined,
  district: json.district,
  street: json.street || undefined,
  zip: json.zip,
});

export const $validateAddress = (json: Response.IPostValidateAddress): IAddress => $address(json.data);
