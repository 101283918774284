export const SELECT_PATH = '/select';
export const WRITE_PATH = '/write';
export const MEDIA_PATH = '/media';
export const IMAGE_CROPPER_PATH = '/image-cropper';
export const EVENT_TIME_PATH = '/event-time';
export const EVENT_CATEGORY_PATH = '/event-category';
export const ADD_LOCATION_PATH = '/add-location';
export const PREVIEW_PATH = '/preview';
export const SUBMIT_PATH = '/submit';
export const SNAPSHOT_CROPPER_PATH = '/snapshot-cropper';
export const SNAPSHOT_WRITE_PATH = '/snapshot-write';
export const SNAPSHOT_LOCATION_PATH = '/snapshot-location';
export const CAMPAIGN_INTRODUCTION = '/broadcast-intro';
export const CAMPAIGN_WRITE = '/broadcast-write';
export const CAMPAIGN_PREVIEW = '/broadcast-preview';
export const CAMPAIGN_BILLING = '/broadcast-billing';
export const CAMPAIGN_SUCCESS = '/broadcast-success';
export const CAMPAIGN_WARNING = '/broadcast-warning';
export const CAMPAIGN_ERROR = '/broadcast-error';
