// interfaces
import * as Response from 'src/api/interfaces/responses/category';
import { ICategories, IEventCategory } from 'src/interfaces/categories';

// TODO: If the Backendguys updated the categories-response we need to update this decoder:
export const $categories = (json: Response.ICategoryResponse): ICategories => json.data.map($category);

export const $category = (json: Response.IEventCategory): IEventCategory => ({
  id: json.identifier,
  name: json.name,
  parentId: json.parent_id ? json.parent_id.toString() : null,
  slug: json.slug,
});
