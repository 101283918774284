// interfaces
import { $profile } from 'src/api/decoders/profile';
import * as Response from 'src/api/interfaces/responses/graylist';
import { IGraylistable } from 'src/interfaces/graylistable';

// CAUTION: We only decode the json.data and no json.meta (contains pagination information),
// because we currently do not use them.
// So please be aware if you want to implement pagination, you need to update this decoder.
export const $graylistGet = (json: Response.IGraylistGet): IGraylistable[] => json.data.map((data) => ({
  createdAt: data.create_at,
  expiresOn: data.expires_on,
  globalStableIdentifier: data.global_stable_identifier,
  id: data.identifier,
  identifier: data.identifier,
  profile: $profile(data.graylistable),
  type: data.type,
  // stats: IStats // We currently don't use the stats anywhere.
  // It contains data about post_counts, view_counts, etc.
}));
