import classNames from 'classnames';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

import './navigation_item.scss';

const cls = `${CLASS_PREFIX}navigation_item`;

export type TargetType = '_blank' | '_self';

// to get full react-router-dom interface of Link of the to prop
// https://reacttraining.com/react-router/web/api/Link
type LinkToType = LinkProps['to'];

export interface INavigationProps {
  url?: LinkToType;
  target?: TargetType;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>) => void;
  download?: string;
  noFollow?: boolean;
  title?: string;
}

export interface IProps extends INavigationProps {
  className?: string;
  children?: any;
}

const NavigationItem: React.FC<IProps> = ({
  url,
  target,
  className,
  children,
  onClick,
  download,
  noFollow = false,
  title,
}) => {
  if (!url && !onClick) {
    return children;
  }

  // For links that use only an onClick instead of a url (ex. forgot_password)
  if (!url) {
    return (
      <div
        onClick={onClick}
        className={classNames(cls, className)}
        draggable={false}
        title={title}
      >
        {children}
      </div>);
  }

  // For Nav Items that route to a new page, includes links to send emails
  if (typeof url === 'string' && target === '_blank') {
    const rel = noFollow ? 'noopener nofollow' : 'noopener';
    return (
      <a
        href={url}
        target={target}
        className={className}
        draggable={false}
        onClick={onClick}
        download={download}
        rel={rel}
        title={title}
      >
        {children}
      </a>
    );
  }
  // Default for routing within Lopo
  return (
    <Link
      to={url}
      target={target}
      className={className}
      draggable={false}
      onClick={onClick}
      title={title}
    >
      {children}
    </Link>
  );
};

export default NavigationItem;
