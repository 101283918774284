enum RemoteDataType {
  Appending = 'Appending',
  AppendingFail = 'AppendingFail',
  Failure = 'Failure',
  Loading = 'Loading',
  NotRequested = 'NotRequested',
  Success = 'Success',
}

// Special case (eg. pagination). Request state is loading,
// but previous data is also availble
interface Appending<T> {
  type: RemoteDataType.Appending;
  data: T;
}

// Special case (eg. pagination). Request state is failure,
// but previous data should not be removed (eg. network errors)
interface AppendingFail<T, E> {
  type: RemoteDataType.AppendingFail;
  data: T;
  error: E;
}

interface Failure<E> {
  type: RemoteDataType.Failure;
  error: E;
}

interface Loading {
  type: RemoteDataType.Loading;
}

interface NotRequested {
  type: RemoteDataType.NotRequested;
}

export interface Success<T> {
  type: RemoteDataType.Success;
  data: T;
}

export type RemoteData<T, E> =
  | Appending<T>
  | AppendingFail<T, E>
  | Failure<E>
  | Loading
  | NotRequested
  | Success<T>;

export const Appending = <T>(data: T): Appending<T> => ({
  data,
  type: RemoteDataType.Appending,
});

export const AppendingFail = <T, E>(
  data: T,
  error: E,
): AppendingFail<T, E> => ({
  data,
  error,
  type: RemoteDataType.AppendingFail,
});

export const Failure = <E>(error: E): Failure<E> => ({
  error,
  type: RemoteDataType.Failure,
});

export const Loading: Loading = { type: RemoteDataType.Loading };

export const NotRequested: NotRequested = { type: RemoteDataType.NotRequested };

export const Success = <T>(data: T): Success<T> => ({
  data,
  type: RemoteDataType.Success,
});

export const hasData = <T, E>(
  remoteData: RemoteData<T, E>,
): remoteData is Appending<T> | AppendingFail<T, E> | Success<T> => {
  return (
    remoteData.type === RemoteDataType.Appending ||
    remoteData.type === RemoteDataType.AppendingFail ||
    remoteData.type === RemoteDataType.Success
  );
};

export const isAppending = <T, E>(
  remoteData: RemoteData<T, E>,
): remoteData is Appending<T> => {
  return remoteData.type === RemoteDataType.Appending;
};

export const isAppendingFail = <T, E>(
  remoteData: RemoteData<T, E>,
): remoteData is AppendingFail<T, E> => {
  return remoteData.type === RemoteDataType.AppendingFail;
};

export const isFailure = <T, E>(
  remoteData: RemoteData<T, E>,
): remoteData is Failure<E> => {
  return remoteData.type === RemoteDataType.Failure;
};

export const isLoading = <T, E>(
  remoteData: RemoteData<T, E>,
): remoteData is Loading => {
  return remoteData.type === RemoteDataType.Loading;
};

export const isSuccess = <T, E>(
  remoteData: RemoteData<T, E>,
): remoteData is Success<T> => {
  return remoteData.type === RemoteDataType.Success;
};
