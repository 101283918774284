// libs
import classNames from 'classnames';
import * as React from 'react';

// interfaces & constants
import { CLASS_PREFIX } from 'src/constants/';

// components
import StaticPages from 'src/components/static_pages/static_pages';

// helpers
import { toggleBodyScrolllock } from 'src/utils/scrolllock/scrolllock';

import './sidebar.scss';

interface IProps {
  withSubHeader: boolean;
  withHeader: boolean;
  trigger?: JSX.Element;
  isShownOnMobile: boolean;
  isOnlyVisibleOnDesktop: boolean;
  showStaticPagesFooter: boolean;
}

const cls = CLASS_PREFIX + 'sidebar';
const clsFixedWrapper = cls + '__fixed-wrapper';

class SideBar extends React.Component<IProps> {
  private scrollRef = React.createRef<HTMLDivElement>();
  public componentDidMount() {
    this.scrollRef.current && toggleBodyScrolllock(this.props.isShownOnMobile, this.scrollRef.current);
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.isShownOnMobile !== this.props.isShownOnMobile) {
      this.scrollRef.current && toggleBodyScrolllock(this.props.isShownOnMobile, this.scrollRef.current);
    }
  }

  public componentWillUnmount() {
    this.scrollRef.current && toggleBodyScrolllock(false, this.scrollRef.current);
  }

  public render() {
    const { children,
      withSubHeader, trigger, isShownOnMobile, withHeader, isOnlyVisibleOnDesktop, showStaticPagesFooter } = this.props;
    const classes = {
      [cls]: true,
      [cls + '--shown-on-mobile']: isShownOnMobile,
      [cls + '--only-on-desktop']: isOnlyVisibleOnDesktop,
    };
    const classesFixedWrapper = {
      [clsFixedWrapper]: true,
      [clsFixedWrapper + '--no-subheader']: !withSubHeader,
      [clsFixedWrapper + '--no-header']: !withHeader,
    };

    return (
      <>
        {trigger}
        <aside className={classNames(classes)} >
          <div ref={this.scrollRef} className={classNames(classesFixedWrapper)}>
            {children}
            {showStaticPagesFooter
              ? <div className={cls + '__static-links'}>
                <StaticPages/>
              </div>
              : null
            }
          </div>
        </aside>
      </>
    );
  }
}

export default SideBar;
