// library imports
import { LatLng, LeafletEvent, Map as LeafletMap } from 'leaflet';
import * as React from 'react';
import {
  AttributionControl,
  Map,
  TileLayer,
} from 'react-leaflet';

// const
import { CLASS_PREFIX } from 'src/constants/';
import * as MAP from 'src/constants/map';

// interfaces
import { IMapStyleToggle, IMapZoomToggle } from 'src/interfaces/';
import { IBoundingBox, IPosition } from 'src/interfaces/location';

import { IMapComponent } from 'src/interfaces/map';

// components
import MapMarkerCollection from 'src/components/map/marker_collection/map_marker_collection';
import MapShape from 'src/components/map/shape/map_shape';
import MapControlsContainer from 'src/containers/map_controls';

// helpers
import {
  boundingBoxToLeafletBounds,
  getBoundingBoxFromLeafletBounds,
  getPositionFromLeafletCenter,
  hasMapMovedEnough,
} from 'src/utils/map';
import { reportError } from 'src/utils/reporting/report-errors';
import { getToggle } from 'src/utils/toggle';

import 'src/components/map/map.scss';

const cls = CLASS_PREFIX + 'map';

const defaultMapStyle: IMapStyleToggle = {
  styleURL: MAP.MAP_DEFAULT_MAPBOX_STYLE,
};

const mapStyleToggle = getToggle<IMapStyleToggle>('mapStyle', defaultMapStyle);

const defaultMapZoom: IMapZoomToggle = {
  default: MAP.MAP_DEFAULT_ZOOM,
  max: MAP.MAP_OPTION_MAX_ZOOM,
  min: MAP.MAP_OPTION_MIN_ZOOM,
};

const mapZoomToggle = getToggle<IMapZoomToggle>('mapZoom', defaultMapZoom);

interface IState {
  mapBoundingBox?: IBoundingBox;
  mapPosition?: IPosition;
  showSearchAreaButton: boolean;
  zoom: number;
}

class MapComponent extends React.PureComponent<IMapComponent, IState> {
  private map?: LeafletMap;

  public constructor(props: IMapComponent) {
    super(props);

    this.state = {
      mapBoundingBox: props.boundingBox,
      mapPosition: props.position,
      showSearchAreaButton: false,
      zoom: mapZoomToggle.default,
    };

    this.backToPrevious = this.backToPrevious.bind(this);
    this.handleRef = this.handleRef.bind(this);
    this.zoomIn = this.zoomIn.bind(this);
    this.zoomOut = this.zoomOut.bind(this);
    this.updateState = this.updateState.bind(this);
    this.handleMapChange = this.handleMapChange.bind(this);
  }

  // because events are not triggered (anymore?).
  public componentDidMount() {
    this.checkToRecalculateBoundingBox();
  }

  public componentDidUpdate(prevProps: IMapComponent) {
    const { boundingBox } = this.props;

    // updating the local component state from the map state
    if (this.map && hasMapMovedEnough(prevProps.boundingBox, boundingBox)) {
      this.updateState(this.map);
    }

    this.checkToRecalculateBoundingBox();
  }

  public render() {
    const {
      boundingBox,
      isLocked,
      position,
      shape,
      markers,
      mapToken,
    } = this.props;
    const { zoom, mapPosition, mapBoundingBox, showSearchAreaButton } = this.state;

    const initialPosition = position || MAP.CENTER_OF_GERMANY_POSITION;
    const center = new LatLng(initialPosition.latitude, initialPosition.longitude);
    const tileLayerUrl = '' +
      `https://{s}.tiles.mapbox.com/styles/v1/${mapStyleToggle.styleURL}` +
      `/tiles/{z}/{x}/{y}?access_token=${mapToken}`;

    if (!mapPosition && !boundingBox) {
      reportError('map called with no bb or position', this.props);
    }

    return (
      <div className={cls}>
        <Map
          // we disable the animation because it took too much time.
          // since we need the map to calculate bounding box for use,
          // we could not filre a feed request until the map was in place
          // to calculate it for us.
          animate={false}
          // Adding a key is a workaround created by Torben to enforce the reloading of the map when the
          // isLocked property has been changed. Without this workaround the map would always keep its initial
          // locked-state. See also https://gitlab.naymspace.de/lokalportal/lokalportal/merge_requests/2239#note_112117.
          key={isLocked ? 1 : 0}
          attributionControl={MAP.MAP_OPTION_ATTRIBUTION_CONTROL}
          bounds={boundingBox && boundingBoxToLeafletBounds(boundingBox)}
          boxZoom={!isLocked}
          className={cls + '__container'}
          center={center}
          doubleClickZoom={!isLocked}
          dragging={!isLocked}
          keyboard={!isLocked}
          maxZoom={isLocked ? undefined : mapZoomToggle.max}
          minZoom={isLocked ? undefined : mapZoomToggle.min}
          ondragend={this.handleMapChange}
          onzoomend={this.handleMapChange}
          ref={this.handleRef}
          scrollWheelZoom={!isLocked}
          touchZoom={!isLocked}
          zoomControl={!isLocked && MAP.MAP_OPTION_ZOOM_CONTROL}
          zoom={zoom}
        >
          <AttributionControl position={'topright'} prefix=''/>
          <TileLayer
            url={tileLayerUrl}
            attribution={MAP.MAP_ATTRIBUTION}
            tileSize={MAP.MAP_OPTION_TILE_SIZE}
            zoomOffset={MAP.MAP_OPTION_ZOOM_OFFSET}
          />

          <MapMarkerCollection markers={markers} />
          {shape && <MapShape {...shape}/>}
        </Map>
        <MapControlsContainer
          isLocked={isLocked}
          showSearchAreaButton={showSearchAreaButton}
          mapPosition={mapPosition}
          mapBoundingBox={mapBoundingBox}
          zoomActions={{
            zoomIn: this.zoomIn,
            zoomOut: this.zoomOut,
          }}
          backToPrevious={this.backToPrevious}
        />
      </div>
    );
  }

  private zoomOut() {
    const nextZoom = this.state.zoom - MAP.MAP_ZOOM_STEP_SIZE;
    if (nextZoom >= mapZoomToggle.min) {
      this.setState({ zoom: nextZoom });
    }
  }

  private zoomIn() {
    const nextZoom = this.state.zoom + MAP.MAP_ZOOM_STEP_SIZE;
    if (nextZoom <= mapZoomToggle.max) {
      this.setState({ zoom: nextZoom });
    }
  }

  private panTo(position: IPosition) {
    if (!this.map) {
      return;
    }
    const panOptions = {
      animate: true,
      duration: 0.5,
    };
    const offsetLat = parseFloat(position.latitude.toString()) - MAP.MAP_LAT_OFFSET;
    this.map.panTo([offsetLat, position.longitude], panOptions);
  }

  private backToPrevious(): void {
    if (this.props.position) {
      this.panTo(this.props.position);
      this.setState({ showSearchAreaButton: false });
    }
  }

  private updateState(map: LeafletMap): void {
    const { boundingBox } = this.props;
    const zoom = map.getZoom();
    const mapPosition = getPositionFromLeafletCenter(map.getCenter());
    const mapBoundingBox = getBoundingBoxFromLeafletBounds(map.getBounds());
    const showSearchAreaButton = hasMapMovedEnough(boundingBox, mapBoundingBox);

    this.setState({
      mapBoundingBox,
      mapPosition,
      showSearchAreaButton,
      zoom,
    });
  }

  private handleMapChange({ target }: LeafletEvent): void {
    this.updateState(target);
  }

  private handleRef(ref: Map | null) {
    if (!ref) {
      return null;
    }
    return this.map = ref.leafletElement;
  }

  private checkToRecalculateBoundingBox() {
    const { boundingBox, setBoundingBox, position } = this.props;

    if (this.map && position && !boundingBox) {
      setBoundingBox(
        getBoundingBoxFromLeafletBounds(this.map.getBounds()),
      );
    }
  }
}

export default MapComponent;
