// libs
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { FRONTEND_POST_COMMENTS_ANCHOR } from 'src/constants/urls';
import { IProps } from 'src/containers/comments';
import { IComment, ICommentItem } from 'src/interfaces/comments';
import { COLOR_TYPE_PRIMARY } from 'src/utils/color';

// classes
import Button, { BUTTON_TYPE_CONTAINED } from 'src/components/forms/button/button';
import Icon from 'src/components/forms/icon/icon';
import { MODE_TYPE_EDIT } from 'src/components/media_input/media_input';
import CommentInput from 'src/components/post/shared/comments/comment_input';
import DeletedComment from 'src/components/post/shared/comments/deleted_comment';
import SnackBar from 'src/components/snack_bar/snack_bar';
import TextInput from 'src/components/text_input/text_input';
import Comment from 'src/containers/comment';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';

// helpers
import { textResources } from 'src/lang/de';
import { getUpdatedCommentImages } from 'src/utils/attachment/attachment';
import { isDeletedComment } from 'src/utils/comment';
import './comments.scss';

const labels = textResources.comments;
const cls = CLASS_PREFIX + 'comments';

interface IState {
  editing: IComment | null;
  showErrorMessage: boolean;
}

export class Comments extends React.PureComponent<IProps, IState> {
  private commentInputRef: TextInput;
  private commentSectionRef: HTMLDivElement;
  private scrollDownInterval: number;

  public constructor(props: IProps) {
    super(props);
    this.handleCancelEditing = this.handleCancelEditing.bind(this);
    this.handleDeleteComment = this.handleDeleteComment.bind(this);
    this.handleEditComment = this.handleEditComment.bind(this);
    this.handleLikeClick = this.handleLikeClick.bind(this);
    this.renderComment = this.renderComment.bind(this);
    this.renderCommentCreate = this.renderCommentCreate.bind(this);
    this.renderCommentEdit = this.renderCommentEdit.bind(this);
    this.submitComment = this.submitComment.bind(this);
    this.updateComment = this.updateComment.bind(this);
    this.closeErrorMessage = this.closeErrorMessage.bind(this);
    this.updateCommentInputRef = this.updateCommentInputRef.bind(this);
    this.focusCommentInput = this.focusCommentInput.bind(this);
    this.setCommentSectionRef = this.setCommentSectionRef.bind(this);
    this.scrollToComments = this.scrollToComments.bind(this);
    this.renderEditMode = this.renderEditMode.bind(this);
    this.renderTopElementCommentable = this.renderTopElementCommentable.bind(this);

    this.state = {
      editing: null,
      showErrorMessage: false,
    };

    const { getComments, id, urls } = this.props;
    getComments(id, urls.get);
  }

  public componentDidMount() {
    if (window.location.hash === FRONTEND_POST_COMMENTS_ANCHOR) {
      this.scrollToComments();
    }
  }

  public componentWillUnmount() {
    clearInterval(this.scrollDownInterval);
  }

  public componentDidUpdate(prevProps: IProps) {
    const hasErrors = this.props.comments.some((comment) => !!comment.hasError);
    const prevHasErrors = prevProps.comments.some((comment) => !!comment.hasError);
    if ((prevHasErrors !== hasErrors) && hasErrors) {
      this.setState({ showErrorMessage: true });
    }
  }

  public render() {
    const { comments, commentable } = this.props;
    const { editing, showErrorMessage } = this.state;

    return (
      <div className={cls}>
        {showErrorMessage && (
          <SnackBar showClose={true} message={textResources.shared.errorUnknown} onClose={this.closeErrorMessage} />
        )}
        {this.renderTopElement(!!comments.length, commentable)}
        <div className={cls + '__list'} >
          {comments.map(this.renderComment)}
        </div>
        {editing ? this.renderEditMode(editing) : this.renderCommentCreate()}
      </div>
    );
  }

  private renderEditMode(editing: IComment) {
    return <div className={cls + '__edit-mobile'}>
      {this.renderCommentEdit(editing)}
    </div>;
  }

  private renderTopElement(hasComments: boolean, commentable: boolean): JSX.Element {
    return (
      <div className={cls + '__top'} ref={this.setCommentSectionRef}>
        <h2 className={cls + '__top__headline'}>
          {commentable ? this.renderTopElementCommentable(hasComments) : labels.disabled}
        </h2>
        {commentable ? this.renderCommentCount() : null}
        {commentable && !hasComments && this.renderWriteCommentButton()}
      </div>
    );
  }

  private renderTopElementCommentable(hasComments: boolean): JSX.Element {
    return <>
      { hasComments
        ? <>
          <Icon aria-hidden='true' name={'comment'} size={16} className={`${cls}__top__icon`}/>
          {labels.write}
        </>
        : labels.noneYet_WEB
      }
    </>;
  }

  private renderCommentCount(): JSX.Element {
    const { comments } = this.props;
    return (
      <div className={cls + '__top__subheadline'}>
        <span>
          {comments.length
            ? labels.commentCount(comments.length)
            : labels.nonYetMessage
          }
        </span>
      </div>
    );
  }

  private renderWriteCommentButton(): JSX.Element {
    const { disabled } = this.props;
    return (
      <div className={cls + '__focus_comment_button'}>
        <UserOnboardingCTA active={disabled}>
          <Button
            onClick={this.focusCommentInput}
            label={labels.write}
            fullWidth={true}
            variant={BUTTON_TYPE_CONTAINED}
            color={COLOR_TYPE_PRIMARY}
            icon={'comment-filled'}
            lowerCase={true}
          />
        </UserOnboardingCTA>
      </div>
    );
  }

  private focusCommentInput() {
    this.commentInputRef && this.commentInputRef.focus();
  }

  private renderCommentCreate(): JSX.Element | null {
    const { disabled, sponsorLevel, userImageUrl, commentable } = this.props;
    return commentable ? <CommentInput
      disabled={disabled}
      submit={this.submitComment}
      userImageUrl={userImageUrl}
      update={this.updateComment}
      refProp={this.updateCommentInputRef}
      sponsorLevel={sponsorLevel}
    /> : null;
  }

  private renderCommentEdit(editing: IComment): JSX.Element {
    const imageUrls = editing.attachments.map((attachment) => attachment.imageUrls.original);
    const { disabled, sponsorLevel, userImageUrl } = this.props;
    return (
      <CommentInput
        cancel={this.handleCancelEditing}
        commentId={editing.id}
        disabled={disabled}
        images={imageUrls}
        message={editing.message}
        mode={MODE_TYPE_EDIT}
        oglink={editing.linkPreview}
        sponsorLevel={sponsorLevel}
        update={this.updateComment}
        updateUrl={editing.urls.resource}
        userImageUrl={userImageUrl}
      />
    );
  }

  private renderComment(props: ICommentItem, index: number) {
    if (isDeletedComment(props)) {
      return <DeletedComment key={index} {...props} />;
    }
    const { editing } = this.state;
    if (editing && editing.id === props.id) {
      return (
        <div key={index} className={cls + '__edit-desktop'}>
          {this.renderCommentEdit(editing)}
        </div>
      );
    }
    return (
      <Comment
        deleteComment={this.handleDeleteComment}
        editComment={this.handleEditComment}
        key={index}
        likeToggle={this.handleLikeClick}
        {...props}
      />
    );
  }

  private handleCancelEditing() {
    const { tracking: { tracker, trackingObj } } = this.props;
    tracker(trackingObj.ACTIONS.UPDATE, trackingObj.LABELS.CANCEL);
    this.setState({ editing: null });
  }

  private handleDeleteComment(commentId: string, url: string) {
    const { deleteComment, id } = this.props;
    if (deleteComment) {
      deleteComment(commentId, id, url);
    }
  }

  private handleEditComment(comment: IComment) {
    this.setState({ editing: comment });
  }

  private handleLikeClick(commentId: string, updateUrl: string) {
    const { id, likeToggle } = this.props;
    if (likeToggle) {
      likeToggle(id, commentId, updateUrl);
    }
  }

  private submitComment(message: string, images?: string[], oglinkId?: string) {
    const { id, urls, submitComment } = this.props;
    submitComment(message, id, urls.create, images, oglinkId);
  }

  private updateComment(commentId: string,
                        updateUrl: string,
                        message: string,
                        images: string[] = [],
                        oglinkId?: string) {
    const { id } = this.props;
    const { editing } = this.state;
    if (!editing) { return; }
    const { addedImages, deletedImagesIds } = getUpdatedCommentImages(editing.attachments, images);
    const { updateComment } = this.props;
    updateComment(commentId, id, updateUrl, message, addedImages, deletedImagesIds, oglinkId);
    this.setState({ editing: null });
  }

  private closeErrorMessage() {
    this.setState({ showErrorMessage: false });
  }

  private updateCommentInputRef(ref: TextInput) {
    this.commentInputRef = ref;
  }

  private setCommentSectionRef(ref: HTMLDivElement): void {
    // The ref is on the comment headline in order for scrollIntoView to scroll
    // until a position where he will see both the title and the first comments
    this.commentSectionRef = ref;
  }

  private scrollToComments() {
    // Sometimes it doesn't scroll until the comments
    // because some elements were not yet loaded.
    // So we check two more times just in case
    let maxAttempts = 2;
    this.scrollDownInterval = window.setInterval(() => {
      if (this.commentSectionRef.getBoundingClientRect().top === 0 || maxAttempts === 0) {
        clearInterval(this.scrollDownInterval);
      } else {
        this.commentSectionRef.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      maxAttempts--;
    }, 1000);
  }
}
