// library imports
import React, { useState } from 'react';

// components
import Icon from 'src/components/forms/icon/icon';
import MessageBoxWithModal from 'src/components/message_box/message_box_with_modal';
import Popover, { POPOVER_LAYOUT_PLAIN } from 'src/components/popover/popover';
import UserOnboardingCTA from 'src/containers/smart_components/user_onboarding_cta/user_onboarding_cta';
import FollowButtonMenu from './follow_popover_menu';

// actions
import { useFollow, useUnFollow } from 'src/actions/follow/follow_hooks';

// constants
import { IRenderAPI } from 'src/components/_libs/popover_lib/popover_lib';
import { MSGBOX_BUTTON_TYPE_CANCEL, MSGBOX_BUTTON_TYPE_DANGER } from 'src/components/message_box/message_box';

// utils
import { textResources } from 'src/lang/de';
import { useSelectProfile } from 'src/reducers/profiles/profiles_hooks';
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import initTracker, { FOLLOW_BUTTON } from 'src/utils/reporting/events_tracking/events_tracking';

// styles
import './follow_button.scss';
const label = textResources.follow;

const tracker = initTracker(FOLLOW_BUTTON);

export interface IProps {
  followableId: string;
}
const cls = createBemBlock('follow_button');

const FollowButton: React.FC<IProps> = ({ followableId }) => {
  const profile = useSelectProfile(followableId);
  const [isVisibleCancelModal, showCancelModal] = useState(false);
  const followAction = useFollow();
  const unFollowAction = useUnFollow();
  if (!profile || !profile.meta.isFollowable || profile.meta.isOwn) {
    return null;
  }

  const { isFollowed, notifiesMe } = profile.meta;
  const { canBeFollowed } = profile.permissions;
  const paramToFollow = {
    followableId,
    followableType: profile.type,
    notification: true,
  };
  const follow = () => {
    tracker(FOLLOW_BUTTON.ACTIONS.FOLLOW, FOLLOW_BUTTON.LABELS.START);
    followAction(paramToFollow);
  };
  const unFollow = () => {
    tracker(FOLLOW_BUTTON.ACTIONS.UNFOLLOW, FOLLOW_BUTTON.LABELS.START);
    unFollowAction(paramToFollow);
    showCancelModal(false);
  };

  return (
    <UserOnboardingCTA active={!canBeFollowed} >
      {isFollowed
        ? <Popover
          isOpen={isVisibleCancelModal}
          position={'top'}
          align={'relative'}
          layout={POPOVER_LAYOUT_PLAIN}
          trigger={
            <button className={cls()}>
              <p>
                {label.following}{' '}<Icon className={cls('icon')} name='arrow-down' size={12} />
              </p>
            </button>
          }>{({ hide }: IRenderAPI) => (
            <FollowButtonMenu
              hide={hide}
              showCancelModal={showCancelModal}
              isNotifying={!!notifiesMe}
              paramToAction={paramToFollow}/>
          )}
        </Popover>
        : <button
          className={cls()}
          onClick={follow}>
          <p>{label.follow}</p>
        </button>}

      {isVisibleCancelModal &&
      <MessageBoxWithModal
        buttons={[
          {
            label: label.unfollow,
            onClick: unFollow,
            type: MSGBOX_BUTTON_TYPE_DANGER,
          },
          {
            label: label.following,
            onClick: () => { showCancelModal(false); },
            type: MSGBOX_BUTTON_TYPE_CANCEL,
          },
        ]}
        title={label.unfollowAlertTitle}
      >
        <p dangerouslySetInnerHTML={{
          __html: label.unfollowAlertBody(`<strong>${profile.name}</strong>`),
        }}
        />
      </MessageBoxWithModal>
      }
    </UserOnboardingCTA>
  );
};

export default FollowButton;
