// libs
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

// interfaces
import { Id } from 'src/interfaces';
import { IProfile } from 'src/interfaces/profile';
import { IProfilesRecord } from 'src/interfaces/profiles_list';
import { IRootState } from 'src/reducers/interface';

// helpers
import { useGetProfileIdWithTypeFromUrlParams } from 'src/hooks_shared/use_url_params';
import { useSelectCurrentProfile } from 'src/reducers/user/user_hooks';
import { ProfilesState } from './profiles';

export const useSelectProfile = (id?: Id) =>
  useSelector<IRootState, IProfile | undefined>(({ profiles }) => id ? profiles[id]?.profile : undefined);

export const useSelectUrlParamProfile = () => {
  const { profileId } = useGetProfileIdWithTypeFromUrlParams();
  return useSelectProfile(profileId);
};

export const useSelectProfiles = () => {
  const profiles = useSelector<IRootState, ProfilesState>(({ profiles }) => profiles);

  return useMemo(() => Object.entries(profiles).reduce((acc: IProfilesRecord, [key, referencedProfile]) => {
    if (referencedProfile) {
      acc[key] = referencedProfile.profile;
    }
    return acc;
  }, {}), [profiles]);
};

export const useGetSponsorLevelFromUser = () => {
  const user = useSelectCurrentProfile();
  return useSelectProfile(`${user.type}#${user.id}`)?.sponsorLevel;
};
