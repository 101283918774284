// libs
import replaceAsciiEmojis from 'emoji-shortcuts';
import * as React from 'react';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import { IBodyText, IIngredient } from 'src/interfaces/post_ingredients';
import { mapDisplayModeToConst, POST_DISPLAY_MODE_DETAIL } from 'src/interfaces/posts';

// classes
import BlurredContent from 'src/components/blurred_content/blurred_content';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// helpers
import { makeHTMLLinksFromText } from 'src/utils/string/string';

// styles
import './body_text.scss';

export type IProps = IIngredient<IBodyText>;

export const cls = `${CLASS_PREFIX}body-text__`;

export default class BodyText extends React.PureComponent<IProps, {}> {
  private cls = '';

  constructor(props: IProps) {
    super(props);
    this.cls = `${cls}${mapDisplayModeToConst[props.displayMode]}`;
  }

  public render() {
    const { blurred = false, data: { fullContent, teaserContent }, displayMode, postDetailsURL } = this.props;

    const parsedBody: string = displayMode === POST_DISPLAY_MODE_DETAIL
      ? this.parseContent(fullContent)
      : replaceAsciiEmojis(teaserContent);
    const bodyElement = <p dangerouslySetInnerHTML={{ __html: parsedBody }}/>;

    return (
      <NavigationItem target='_self' url={postDetailsURL} >
        <div className={this.cls}>
          {blurred ? (
            <BlurredContent>
              {bodyElement}
            </BlurredContent>
          ) : bodyElement}
        </div>
      </NavigationItem>
    );
  }

  private parseContent(content: string): string {
    const afterLinksParse: string = makeHTMLLinksFromText(replaceAsciiEmojis(content));

    return afterLinksParse;
  }
}
