// libs
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';
import {
  FRONTEND_DATA_BLOG_PATH,
  FRONTEND_DATA_IMPRESSUM_PATH,
  FRONTEND_DATA_LEARN_MORE_PATH,
} from 'src/constants/urls';
import { IPosition } from 'src/interfaces/location';
import { textResources } from 'src/lang/de';

// classes / components
import Button, { BUTTON_TYPE_CONTAINED, BUTTON_TYPE_TEXT } from 'src/components/forms/button/button';
import Logo from 'src/components/logo/logo';
import NavigationItem from 'src/components/navigation_item/navigation_item';
import AppLocationPicker from 'src/containers/smart_components/app_location_picker/app_location_picker';

// helpers
import { COLOR_TYPE_DARKERWHITE, COLOR_TYPE_PRIMARY } from 'src/utils/color';
import initTracker, {
  LANDING_PAGE,
  PAGE_404,
} from 'src/utils/reporting/events_tracking/events_tracking';
import {
  UrlUtils,
} from 'src/utils/url/url';

import * as landingPageDesktopPoster from './home-desktop-frame.jpg';
import * as landingPageDesktopVideoMp4 from './home-desktop.mp4';
import * as landingPageDesktopVideoWebm from './home-desktop.webm';
import * as landingPageMobilePoster from './home-mobile-frame.jpg';
import * as landingPageMobileVideoMp4 from './home-mobile.mp4';
import * as landingPageMobileVideoWebm from './home-mobile.webm';

import classNames from 'classnames';
import './landing_page.scss';
const labels = textResources.landingPage;

export enum LandingPageType {
  SLIM ='slim',
  NORMAL = 'normal'
}

interface ILandingPageProps extends RouteComponentProps {
  mapPosition: IPosition;
  page404?: boolean;
  showLoginModal: () => void;
  showRegistrationModal: () => void;
  getDefaultRedirectPath: () => string;
  type?: LandingPageType;
}

export const cls: string = CLASS_PREFIX + 'landing-page';

export default class LandingPage extends React.Component<ILandingPageProps> {
  constructor(props: ILandingPageProps) {
    super(props);

    this.onLearnMore = this.onLearnMore.bind(this);
    this.returnToLopo = this.returnToLopo.bind(this);

    if (!props.page404 && props.getDefaultRedirectPath() !== '/') {
      this.returnToLopo();
    }
  }

  public componentDidUpdate(prevProps: ILandingPageProps) {
    // when location got selected or the user logs in
    if (prevProps.getDefaultRedirectPath() !== this.props.getDefaultRedirectPath()) {
      this.returnToLopo();
    }
  }
  public render() {
    if (this.props.type === LandingPageType.SLIM) {
      return this.renderSlimMode();
    }
    return this.renderNormalMode();
  }

  private renderSlimMode() {
    const { page404 } = this.props;

    return <div className={classNames(cls, `${cls}--slim`)}>
      <div className={`${cls}__content-container`}>
        <div className={`${cls}__content-wrapper`}>
          {this.renderHeader()}
          <div className={`${cls}__logo-container`}>
            <Logo type={'NO_TEXT'} altText={textResources.shared.lokalportal} />
          </div>
          <div className={`${cls}__content`}>
            <h1 className={`${cls}__content-title`}>
              {page404 ? labels.notFound : labels.title}
            </h1>
          </div>
          {this.renderFooter()}
        </div>
      </div>
    </div>;
  }

  private renderFooter() {
    return <div className={`${cls}__content-footer`}>
      <p className={`${cls}__footer-info`}>{labels.info}</p>
      <Button
        variant={BUTTON_TYPE_CONTAINED}
        color={COLOR_TYPE_PRIMARY}
        label={labels.learnMore}
        onClick={this.onLearnMore}
        fullWidth
      />
      <div className={`${cls}__footer-link-container`}>
        <NavigationItem url={FRONTEND_DATA_IMPRESSUM_PATH} target='_blank'
          className={`${cls}__footer-link`}>{textResources.shared.imprint}</NavigationItem>
        <NavigationItem url={FRONTEND_DATA_BLOG_PATH} target='_blank'
          className={`${cls}__footer-link`}>{textResources.shared.blog}</NavigationItem>
      </div>
    </div>;
  }

  private renderNormalMode() {
    const { page404 } = this.props;

    const trackingObj = page404 ? PAGE_404 : LANDING_PAGE;

    return (
      <div className={cls}>
        <div className={`${cls}__video-container`}>
          <video
            loop={true}
            muted={true}
            autoPlay={true}
            poster={landingPageMobilePoster}
            className={`${cls}__video ${cls}__video--mobile`}
          >
            <source src={landingPageMobileVideoWebm} type={'video/webm'} />
            <source src={landingPageMobileVideoMp4} type={'video/mp4'} />
          </video>
          <video
            loop={true}
            muted={true}
            autoPlay={true}
            poster={landingPageDesktopPoster}
            className={`${cls}__video ${cls}__video--desktop`}
          >
            <source src={landingPageDesktopVideoWebm} type={'video/webm'} />
            <source src={landingPageDesktopVideoMp4} type={'video/mp4'} />
          </video>
        </div>
        <div className={`${cls}__content-container`}>
          <div className={`${cls}__content-wrapper`}>
            {this.renderHeader()}
            <div className={`${cls}__logo-container`}>
              <Logo type={'NO_TEXT'} altText={textResources.shared.lokalportal} />
            </div>
            <div className={`${cls}__content`}>
              <h1 className={`${cls}__content-title`}>
                {page404 ? labels.notFound : labels.title}
              </h1>
              <div className={`${cls}__location-picker-container`}>
                <AppLocationPicker
                  tracking={{
                    tracker: initTracker(trackingObj),
                    trackingObj,
                  }}
                  showBackground={false}
                />
              </div>
            </div>
            {this.renderFooter()}
          </div>
        </div>
      </div>
    );
  }

  private renderHeader() {
    if (this.props.page404) {
      return (
        <div className={`${cls}__content-header`}>
          <Button
            variant={BUTTON_TYPE_TEXT}
            color={COLOR_TYPE_DARKERWHITE}
            label={labels.return}
            onClick={this.returnToLopo}
          />
        </div>
      );
    } else {
      return (
        <div className={`${cls}__content-header`}>
          <Button
            variant={BUTTON_TYPE_TEXT}
            color={COLOR_TYPE_DARKERWHITE}
            label={textResources.shared.register}
            onClick={this.props.showRegistrationModal}
          />
          <Button
            variant={BUTTON_TYPE_TEXT}
            color={COLOR_TYPE_DARKERWHITE}
            label={textResources.shared.login}
            onClick={this.props.showLoginModal}
          />
        </div>
      );
    }
  }

  private returnToLopo() {
    const { history, getDefaultRedirectPath } = this.props;
    history.push(getDefaultRedirectPath());
  }

  private onLearnMore() {
    if (FRONTEND_DATA_LEARN_MORE_PATH) {
      UrlUtils.setLocationHref(FRONTEND_DATA_LEARN_MORE_PATH);
    }
  }
}
