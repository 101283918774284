import { IContentItemType } from 'src/api/interfaces/responses/post';
import * as Response from 'src/api/interfaces/responses/post_ingredients';
import { FEED_POST_TYPE_SNAPSHOT } from 'src/constants/feed';
import {
  IBBCodes,
  IIngredientMap,
  IRawIngredientMetaPurpose,
  IBodyMarkdown,
  IBodyText,
  IEventLocation,
  IEventStartEnd,
  IImage,
  IImageWithDate,
  ILinkPreview,
  IParticipants,
  ITitle,
  IVideo,
  IMap,
  IEventShortTime,
  IInclusionReason,
} from 'src/interfaces/post_ingredients';
import { IIngredientsState, IngredientId } from 'src/interfaces/posts';

// decoders

// helpers
import { $ingredientMarker } from 'src/api/decoders/marker';
import { reportError } from 'src/utils/reporting/report-errors';

export const $ingredients = (
  ingredientsData: Response.IIngredient[],
  contentItemType?: IContentItemType,
): IIngredientsState => {
  return ingredientsData
    .reduce((acc: { ingredientIds: IngredientId[]; ingredients: IIngredientMap }, ingredient) => {
      if (acc.ingredients[ingredient.name]) {
        reportError(
          `ingredient ${ingredient.name} got passed more than one time to the $ingredients decoder.`
        );
      }
      const decodedIngredient = $ingredient(ingredient, contentItemType);
      // filter out ingredients we could not decode
      if (!decodedIngredient || decodedIngredient.data === undefined) {
        return acc;
      }
      return {
        ingredientIds: acc.ingredientIds.concat(ingredient.name),
        ingredients: { ...acc.ingredients, [ingredient.name]: decodedIngredient },
      };
    }, { ingredientIds: [], ingredients: {} });
};
export const $ingredient = <T extends Response.IIngredient>(
  json: T,
  contentItemType?: IContentItemType,
): IIngredientMap[T['name']] =>
  // eslint-disable-next-line @typescript-eslint/no-object-literal-type-assertion
  ({
    data: $ingredientsData(json),
    meta: $meta(json),
    type: contentItemType === FEED_POST_TYPE_SNAPSHOT && json.name === 'image' ? 'imageSnapshot' : json.name,
  } as NonNullable<IIngredientMap[T['name']]>);

const $meta = (json: Response.IIngredient): IRawIngredientMetaPurpose => ({
  purpose: json.meta.purpose,
});

const $bbcode = (json: Response.IBBCodes = {}): IBBCodes => {
  return Object.keys(json).reduce((acc: IBBCodes, key): IBBCodes => {
    const { code, data, errors, identifier, index, type } = json[key];
    return {
      ...acc,
      [key]: {
        code,
        data,
        errors,
        id: identifier,
        index,
        type,
      },
    };
  }, {});
};

const $ingredientsData = (
  ingredient: Response.IIngredient,
): NonNullable<IIngredientMap[typeof ingredient['name']]>['data'] => {
  switch (ingredient.name) {
    case 'bodyMarkdown':
      const bodyMarkdownData: IBodyMarkdown = {
        bbcodes: $bbcode(ingredient.data.bbcodes),
        fullContent: ingredient.data.full_content,
        teaserContent: ingredient.data.teaser_content,
      };
      return bodyMarkdownData;
    case 'bodyText':
      const bodyTextData: IBodyText = {
        fullContent: ingredient.data.full_content,
        teaserContent: ingredient.data.teaser_content,
      };
      return bodyTextData;
    case 'eventLocation':
      const eventLocationData: IEventLocation = {
        city: ingredient.data.city,
        cityWithDistrict: ingredient.data.city_with_district,
        description: ingredient.data.description,
        street: ingredient.data.street,
        zip: ingredient.data.zip,
      };
      return eventLocationData;
    case 'eventShortTime':
      const eventShortTimeData: IEventShortTime = {
        endTime: ingredient.data.end_time,
        startTime: ingredient.data.start_time,
      };
      return eventShortTimeData;
    case 'eventStartEnd':
      const eventStartEndData: IEventStartEnd = {
        endTime: ingredient.data.end_time,
        startTime: ingredient.data.start_time,
      };
      return eventStartEndData;
    case 'inclusionReason':
      const inclusionReasonData: IInclusionReason = {
        content: ingredient.data.content,
        type: ingredient.data.type,
      };
      return inclusionReasonData;
    case 'image':
      const imageData: IImage = {
        imageUrls: ingredient.data.images.map((imageObject) => imageObject.wide_medium),
        imageUrlsSquareMedium: ingredient.data.images.map((imageObject) => imageObject.square_medium),
        imageUrlsSquareSmall: ingredient.data.images.map((imageObject) => imageObject.square_small),
        originalImages: ingredient.data.images.map((imageObject) => imageObject.original),
      };
      return imageData;
    case 'imageWithDate':
      const imageWithDateData: IImageWithDate = {
        date: ingredient.data.date,
        imageUrls: ingredient.data.images.map((imageObject) => imageObject.wide_medium),
        imageUrlsSquareMedium: ingredient.data.images.map((imageObject) => imageObject.square_medium),
        imageUrlsSquareSmall: ingredient.data.images.map((imageObject) => imageObject.square_small),
        originalImages: ingredient.data.images.map((imageObject) => imageObject.original),
      };
      return imageWithDateData;
    case 'linkPreview':
      const linkPreviewData: ILinkPreview = {
        author: ingredient.data.author,
        description: ingredient.data.description,
        displayUrl: ingredient.data.original_url,
        imageUrl: ingredient.data.image_urls && ingredient.data.image_urls.square_small,
        title: ingredient.data.title,
        url: ingredient.data.url,
      };
      return linkPreviewData;
    case 'map':
      const mapIngredientData: IMap = {
        markers: ingredient.data.markers.map($ingredientMarker),
      };
      return mapIngredientData;
    case 'participants':
      const participantsData: IParticipants = {
        canParticipate: ingredient.meta.permissions.can_participate,
        isLoading: false,
        isParticipating: ingredient.meta.is_participating,
        participantsCount: ingredient.data.participants_count,
        participantsListUrl: ingredient.meta.urls.participants_list,
        participateUrl: ingredient.meta.urls.participate,
      };
      return participantsData;
    case 'title':
      const titleData: ITitle = {
        title: ingredient.data.title,
      };
      return titleData;
    case 'video':
      const videoData: IVideo = {
        id: ingredient.data.identifier,
      };
      return videoData;
  }
};
