// lib
import classNames from 'classnames';
import * as React from 'react';

// constants/interfaces
import { IProps as IBadgeProps } from 'src/components/badge/badge';
import { COLOR_TYPE_BLACK } from 'src/utils/color';

// components
import ItemSegmentBase, {
  itemSegmentCls,
  SMALL_ITEM_SEGMENT,
} from 'src/components/item_segment/base/item_segment_base';
import ItemSegmentLink, { IProps as ILinkProps } from 'src/components/item_segment/base/item_segment_link';
import SegmentAvatar, {
  IProps as IItemSegmentAvatarProps } from 'src/components/item_segment/parts/segment_avatar/segment_avatar';
import SegmentBadge from 'src/components/item_segment/parts/segment_badge/segment_badge';
import SegmentIcon, {
  IProps as IItemSegmentIconProps,
} from 'src/components/item_segment/parts/segment_icon/segment_icon';
import SegmentRightArrow from 'src/components/item_segment/parts/segment_right_arrow/segment_right_arrow';
import SegmentText from 'src/components/item_segment/parts/segment_text/segment_text';

// helpers
import './menu.scss';

interface IProps {
  disabled?: boolean;
  icon?: IItemSegmentIconProps;
  badge?: IBadgeProps;
  label: string;
  navigation: ILinkProps;
  avatar?: IItemSegmentAvatarProps;
  hidden?: boolean;
  tooltip?: string;
  noBorder?: boolean;
}

const cls = itemSegmentCls + 'main-menu';

/*
  A Wrapper for ItemSegment for Main Menu and More button
*/
const MenuItemSegment: React.FC<IProps> =
  ({ avatar, badge, disabled, icon, label, hidden, navigation, tooltip, noBorder }) => {
    const classes = {
      [cls]: true,
      [cls + '--hover']: !disabled,
      [cls + '--hidden']: hidden,
      [cls + '--disabled']: disabled,
    };
    return (
      <ItemSegmentBase className={classNames(classes)} height={SMALL_ITEM_SEGMENT} noBorder={noBorder} title={tooltip}>
        <ItemSegmentLink {...navigation}>
          {avatar && <SegmentAvatar imageUrl={avatar.imageUrl} badge={avatar.badge} />}
          {icon && <SegmentIcon name={icon.name} color={icon.color || COLOR_TYPE_BLACK} marginRight={32} size={24}/>}
          <SegmentText label={label} labelSize='small' />
          {badge && badge.value && <SegmentBadge className={cls + '__badge'} value={badge.value} type={badge.type} />}
          <SegmentRightArrow />
        </ItemSegmentLink>
      </ItemSegmentBase>
    );
  };

export default MenuItemSegment;
