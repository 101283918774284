// libs
import { ThunkAction } from 'redux-thunk';

// interfaces / constants
import { IAction } from 'src/actions/';
import { IErrorResponse } from 'src/api/interfaces/errors';
import { IProfileTag } from 'src/interfaces/tags';
import {
  IUpdatableUser, IUpdatebleUserProfile, IUser, IUserProfile, IUserMeta,
} from 'src/interfaces/user';
import { IRootState } from 'src/reducers/interface';

// helpers
import { changeAppLocation } from 'src/actions/app-state/app-state';
import { clearAllFeeds } from 'src/actions/feed/feed';
import { clearAllGroups } from 'src/actions/groups/groups';
import { clearAllProfiles } from 'src/actions/profiles_list/profiles_list';
import api from 'src/api/';
import { AuthenticationHelper } from 'src/utils/authentication/authentication';
import { logToDevConsole } from 'src/utils/reporting/report-errors';
import { setItem, getItem, removeItem } from 'src/utils/storage_wrapper/storage_wrapper';
import { UrlUtils } from 'src/utils/url/url';

const USER_ME_REQUEST = 'USER_ME_REQUEST';

export const USER_HAS_TOKEN = 'USER_HAS_TOKEN';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_ME_REQUEST_SUCCESS = 'USER_ME_REQUEST_SUCCESS';
export const USER_ME_REQUEST_ERROR = 'USER_ME_REQUEST_ERROR';
export const USER_UPDATE_SELECTED_PROFILE = 'USER_UPDATE_SELECTED_PROFILE';
export const USER_ADD_PROFILE = 'USER_ADD_PROFILE';
export const USER_UPDATE_PROFILE = 'USER_UPDATE_PROFILE';
export const USER_REMOVE_PROFILE = 'USER_REMOVE_PROFILE';
export const USER_SET_SELECTED_PROFILE = 'USER_SET_SELECTED_PROFILE';
export const USER_SET_IS_HOME = 'USER_SET_IS_HOME';
export const USER_CHANGE_EMAIL = 'USER_CHANGE_EMAIL';
export const USER_ADD_TAG_REQUEST = 'USER_ADD_TAG_REQUEST';
export const USER_ADD_TAG_REQUEST_SUCCESS = 'USER_ADD_TAG_REQUEST_SUCCESS';
export const USER_REMOVE_TAG_REQUEST = 'USER_REMOVE_TAG_REQUEST';
export const USER_REMOVE_TAG_REQUEST_SUCCESS = 'USER_REMOVE_TAG_REQUEST_SUCCESS';

export interface IUserHasTokenAction {
  type: typeof USER_HAS_TOKEN;
}

export interface IUserUpdateAction {
  type: typeof USER_UPDATE;
  payload: IUpdatableUser;
}

export interface IUserMeRequestSuccessAction {
  type: typeof USER_ME_REQUEST_SUCCESS;
  payload: IUser;
}

export interface IUserMeRequestErrorAction {
  type: typeof USER_ME_REQUEST_ERROR;
}

export interface IUserAddTagSuccessAction {
  payload: IProfileTag;
  type: typeof USER_ADD_TAG_REQUEST_SUCCESS;
}

export interface IUserRemoveTagSuccessAction {
  meta: string;
  type: typeof USER_REMOVE_TAG_REQUEST_SUCCESS;
}

export interface IAddProfileAction {
  type: typeof USER_ADD_PROFILE;
  payload: IUserProfile;
}

export interface IUpdateProfileAction {
  type: typeof USER_UPDATE_PROFILE;
  payload: IUserProfile;
}

export interface IUpdateSelectedProfileAction {
  type: typeof USER_UPDATE_SELECTED_PROFILE;
  payload: IUpdatebleUserProfile;
}

export interface ISetSelectedProfileAction {
  type: typeof USER_SET_SELECTED_PROFILE;
  payload: string;
}

export interface IChangeEmailAction {
  type: typeof USER_CHANGE_EMAIL;
  payload: string;
  meta?: {
    pendingEmailReconfirmation: boolean;
  };
}

export interface ISetIsHomeAction {
  type: typeof USER_SET_IS_HOME;
  payload: boolean;
}

export interface IRemoveProfileAction {
  type: typeof USER_REMOVE_PROFILE;
  payload: string;
}

const setHasToken = (): IUserHasTokenAction => ({
  type: USER_HAS_TOKEN,
});

export const changeEmail = (email: string): IChangeEmailAction => ({
  payload: email,
  type: USER_CHANGE_EMAIL,
});

export const updateUser = (data: IUpdatableUser): IUserUpdateAction => {
  return ({
    payload: data,
    type: USER_UPDATE,
  });
};

export const LAST_SELECTED_PROFILE_ID_STORAGE_KEY = 'lastSelectedProfileId';

export const logOut = () => {
  return () => {
    AuthenticationHelper.logout()
      .then(() => {
        removeItem(LAST_SELECTED_PROFILE_ID_STORAGE_KEY);
        UrlUtils.setLocationHref('/');
      })
      .catch((error: IErrorResponse) => {
        logToDevConsole(`logout failed with '${error}'`);
      });
  };
};

export const logIn =
  (identifier?: string, shouldChangeLocation = true): ThunkAction<void, IRootState, {}, IAction> =>
    (dispatch) => {
      dispatch(setHasToken());
      dispatch(getMe(identifier, shouldChangeLocation));
    };

/*
 * @param {String} "identifier": this is the *longIdentifier* (i.e. `private_profile#1`); if given,
 *                               set this profile as the currently selected profile
 */
export const getMe =
  (identifier?: string, shouldChangeLocation = true): ThunkAction<void, IRootState, {}, IAction> =>
    (dispatch) => {
      dispatch(clearAllFeeds());
      dispatch(clearAllProfiles());
      dispatch(clearAllGroups());
      const lastIdFromLocalStorage = getItem(LAST_SELECTED_PROFILE_ID_STORAGE_KEY) || '';
      dispatch({
        payload: api.me.get(identifier || lastIdFromLocalStorage).then((response) => {
          if (!response) {
            return response;
          }

          setItem(LAST_SELECTED_PROFILE_ID_STORAGE_KEY, response.selectedProfileId);

          const profile = response.profiles[response.selectedProfileId];
          if (!profile) {
            return response;
          }

          if (shouldChangeLocation && profile.address) {
            dispatch(changeAppLocation(
              profile.address.locationShape,
              undefined,
              getLocationShapeIds(profile),
            ));
          }

          return response;
        }),
        type: USER_ME_REQUEST,
      });
    };

export const updateAttempts = (attempts: number): ThunkAction<void, IRootState, {}, IAction> => {
  return (dispatch, getState) => {
    const meta: IUserMeta | undefined = getState().user.meta;

    if (meta) {
      dispatch(updateUser({
        meta: {
          ...meta,
          remainingPhoneVerificationAttempts: attempts,
        },
      }));
    }
  };
};

export const updateSelectedProfile = (userProfile: IUpdatebleUserProfile): IUpdateSelectedProfileAction => ({
  payload: userProfile,
  type: USER_UPDATE_SELECTED_PROFILE,
});

export const addUserProfile = (userProfile: IUserProfile): IAddProfileAction => ({
  payload: userProfile,
  type: USER_ADD_PROFILE,
});

export const updateUserProfile = (userProfile: IUserProfile): IUpdateProfileAction => ({
  payload: userProfile,
  type: USER_UPDATE_PROFILE,
});

export const removeUserProfile = (profileId: string): IRemoveProfileAction => ({
  payload: profileId,
  type: USER_REMOVE_PROFILE,
});

export const setSelectedProfile = (longIdentifier: string): ThunkAction<void, IRootState, {}, IAction> => {
  return (dispatch, getState) => {
    dispatch({
      payload: longIdentifier,
      type: USER_SET_SELECTED_PROFILE,
    });

    // populate appState after switching profile
    const state = getState();
    const profile = state.user.profiles[state.user.selectedProfileId];
    if (profile && profile.address) {
      dispatch(changeAppLocation(
        profile.address.locationShape,
        {
          latitude: profile.address.latitude,
          longitude: profile.address.longitude,
        },
        getLocationShapeIds(profile),
      ));
    }
  };
};

export const setIsHome = (home: boolean): ISetIsHomeAction => ({
  payload: home,
  type: USER_SET_IS_HOME,
});

const getLocationShapeIds = (profile: IUserProfile) => {
  if (profile.locationShapes) {
    return profile.locationShapes.map((locationShape) => locationShape.identifier);
  } else if (profile.address) {
    return [profile.address.locationShape.identifier];
  }
  return undefined;
};

export const removeTag = (tagId: string): ThunkAction<void, IRootState, {}, IAction> => {
  return dispatch => {
    dispatch({
      meta: tagId,
      payload: api.tags.remove(tagId),
      type: USER_REMOVE_TAG_REQUEST,
    });
  };
};

export const addTag = (tagId: string): ThunkAction<void, IRootState, {}, IAction> => {
  return dispatch => {
    dispatch({
      meta: tagId,
      payload: api.tags.add(tagId),
      type: USER_ADD_TAG_REQUEST,
    });
  };
};
