import * as React from 'react';
import { useSurvey } from '../hook/useSurvey';
import { WithSurveyProps } from './model';

const withSurvey = <T extends {}>(WrappedComponent: React.ComponentClass<T>) =>
  (props: T & WithSurveyProps) => {
    const { isEnabled } = useSurvey();
    return (
      <>
        <WrappedComponent {...props} isSurveyEnabled={isEnabled} />
      </>
    );
  };

export default withSurvey;
