// libs
import * as React from 'react';

// components
import Icon from 'src/components/forms/icon/icon';
import NavigationItem from 'src/components/navigation_item/navigation_item';

// interfaces / constants
import { CLASS_PREFIX } from 'src/constants/';

// helpers
import './open_group_button.scss';

declare interface IProps {
  icon: string;
  label: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  url?: string;
}

const cls: string = CLASS_PREFIX + 'open-group-button';

const OpenGroupButton: React.FC<IProps> = ({ icon, label, onClick, url }) => {
  const element = (
    <>
      <span>{label}</span>
      <Icon name={icon} />
    </>
  );

  if (url) {
    return (
      <NavigationItem className={cls} url={url}>
        {element}
      </NavigationItem>
    );
  }

  return (
    <button className={cls} onClick={onClick}>
      {element}
    </button>
  );
};

export default OpenGroupButton;
