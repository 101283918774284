import { flatten } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { IFeedType, IPostsRecord, GroupedPosts } from 'src//interfaces/feed';
import { Id } from 'src/interfaces';
import { INewsFeedHighlights } from 'src/interfaces/news_feed_highlights';
import { IPagination } from 'src/interfaces/pagination';
import { IPost } from 'src/interfaces/posts';
import { IReactions } from 'src/interfaces/reactions';
import { IRootState } from 'src/reducers/interface';

export const useSelectPosts = () =>
  useSelector<IRootState, IPostsRecord>(({ feed }) => feed.posts);

export const usePostData = (id: string | undefined) =>
  useSelector<IRootState, IPost | undefined>(({ feed }) => id ? feed.posts[id] : undefined);

export const useTotalPagesViewed = (feedType: IFeedType) =>
  useSelector<IRootState, number>(({ feed }) => feed.feeds[feedType].totalPagesViewed);

export const useHasError = (feedType: IFeedType) =>
  useSelector<IRootState, boolean>(({ feed }) => feed.feeds[feedType].hasError);

export const useSelectFeedGroups = (feedType: IFeedType) =>
  useSelector<IRootState, GroupedPosts>(({ feed }) => feed.feeds[feedType].groupedPosts);

export const useSelectFeedIds = (feedType: IFeedType) => {
  const groups = useSelectFeedGroups(feedType);
  return useMemo(() => flatten(groups.map(({ ids }) => ids)), [groups]);
};

export const useSelectFeedIsLoading = (feedType: IFeedType) =>
  useSelector<IRootState, boolean>(({ feed }) => feed.feeds[feedType].isLoading);

export const useFeedPaginationInfo = (feedType: IFeedType) =>
  useSelector<IRootState, IPagination>(({ feed }) => feed.feeds[feedType].paginationInfo);

export const useSelectFeedHash = (feedType: IFeedType) =>
  useSelector<IRootState, string>(({ feed }) => feed.feeds[feedType].feedHash);

export const useSelectReactions = (postId: Id) =>
  useSelector<IRootState, IReactions | undefined>(({ feed: { reactions } }) => reactions[postId]);

export const useSelectNewsFeedHighlights = () =>
  useSelector<IRootState, INewsFeedHighlights | undefined>(({ feed }) => feed.newsFeedHighlights);

export const useSelectPostFromId = (id?: string) =>
  useSelector<IRootState, IPost | undefined>(({ feed }) => id && feed.posts[id] || undefined);

export const useSelectCanonicalShapeSlug = (feedType: IFeedType) =>
  useSelector<IRootState, string | undefined>(({ feed }) => feed.feeds[feedType].canonicalShapeSlug);
