// libs
import React from 'react';

// interfaces / constants
import { IconSize } from 'src/components/forms/icon/icon';
import { IWindowJsEnv } from 'src/interfaces/index';
import { ColorType } from 'src/utils/color';

// classes / components
import RoundIcon from 'src/components/forms/round_icon/round_icon';
import Thumbnail, {
  ThumbnailSizeType,
  THUMBNAIL_SIZE_TYPE_MEDIUM,
  THUMBNAIL_SIZE_TYPE_LARGE,
  THUMBNAIL_SIZE_TYPE_SMALL,
  THUMBNAIL_SIZE_TYPE_XLARGE,
  THUMBNAIL_SIZE_TYPE_XSMALL,
  THUMBNAIL_SIZE_TYPE_XXLARGE,
  THUMBNAIL_SIZE_TYPE_XXSMALL,
  THUMBNAIL_SIZE_TYPE_XXXLARGE,
} from 'src/components/thumbnail/thumbnail';

// helper
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';

import './avatar.scss';

export interface IAvatarBadge {
  iconColor: ColorType;
  backgroundColor: ColorType;
  borderColor?: ColorType;
  icon: string;
}

export interface IProps {
  withBorder?: boolean;
  image?: string;
  size?: ThumbnailSizeType;
  badge?: IAvatarBadge;
}

const getDefaultAvatarUrl = (): string => {
  const _window = window as unknown as IWindowJsEnv;
  return _window.js_env.imagePaths && _window.js_env.imagePaths.defaultAvatar;
};

const cls = createBemBlock('avatar');

const badgeSizes: Record<ThumbnailSizeType, IconSize> = {
  [THUMBNAIL_SIZE_TYPE_LARGE]: 16,
  [THUMBNAIL_SIZE_TYPE_MEDIUM]: 19,
  [THUMBNAIL_SIZE_TYPE_SMALL]: 16,
  [THUMBNAIL_SIZE_TYPE_XLARGE]: 16,
  [THUMBNAIL_SIZE_TYPE_XSMALL]: 16,
  [THUMBNAIL_SIZE_TYPE_XXLARGE]: 48,
  [THUMBNAIL_SIZE_TYPE_XXSMALL]: 8,
  [THUMBNAIL_SIZE_TYPE_XXXLARGE]: 44,
};

const Avatar: React.FC<IProps> = ({
  withBorder,
  children,
  image,
  size = THUMBNAIL_SIZE_TYPE_MEDIUM,
  badge,
}) => {
  if (!image) {
    image = getDefaultAvatarUrl();
  }

  return (
    <div className={cls()}>
      <Thumbnail
        withBorder={withBorder || !!badge?.iconColor}
        borderColor={badge?.iconColor}
        imageUrl={image}
        size={size}
        round
      >
        {children}
        {badge && <div className={cls('badge')}>
          <RoundIcon
            name={badge.icon}
            size={badgeSizes[size]}
            backgroundColor={badge.backgroundColor}
            borderColor={badge.borderColor}
            iconColor={badge.iconColor}
          />
        </div>}
      </Thumbnail>
    </div>
  );
};

/* set displayname to not have a component without a name */
Avatar.displayName = 'Avatar';

export default Avatar;
