import { ThunkDispatch } from 'redux-thunk';

import { IAction } from 'src/actions/';
import { AlterRequest } from 'src/api/middleware/interfaces';
import { HEADER_USER_LOGGED_IN } from 'src/constants/http-headers';
import { OAUTH2_REVOKE_PATH, OAUTH2_TOKEN_PATH } from 'src/constants/urls';
import { IOAuth2Credentials } from 'src/interfaces/oauth2';
import { IRootState } from 'src/reducers/interface';
import { getStore } from 'src/store/store-holder';
import {
  AuthenticationHelper,
  AUTHORIZATION_HEADER_NAME,
  AUTHORIZATION_HEADER_VALUE_PREFIX,
} from 'src/utils/authentication/authentication';

import { logOut } from 'src/actions/user/user';

export const ABORT = 'ABORT';
export const CONTINUE = 'CONTINUE';
export const RETRY = 'RETRY';

export interface ICheckAuthResponse {
  step: 'ABORT' | 'CONTINUE' | 'RETRY';
  reason?: string;
}

type Token = IOAuth2Credentials | undefined;

// add a buffer to the token expiration time, meaning that we handle the token as if it was expired even
// if it is still valid for 60 seconds
const TOKEN_EXPIRATION_BUFFER = 60;

/***
 * logoutUser: logout on the server (revoke tokens),
 * which also cleans the storage and redirects to the newsfeed.
 **/

const logoutUser = () => (getStore().dispatch as ThunkDispatch<IRootState, {}, IAction>)(logOut());

const isLoggedInInStore = (): boolean => getStore().getState().user.loggedIn;

/***
 * tryToRefreshToken: common logic for response state -we try to refresh, if we fail we kick the user and throw abort
 * if refresh was successful, we ask the api to try to go again to this endpoint
 * this time with the new token
 **/
const tryToRefreshToken = async(token: Token): Promise<ICheckAuthResponse> => {
  const refreshedToken = await AuthenticationHelper.refresh(token!.refreshToken);
  if (!refreshedToken) {
    logoutUser();
    return { reason: 'OAuth2 tokens failed to refresh - rejecting orig request', step: ABORT };
  }
  return { step: RETRY };
};

const isRefreshURL = (url: string): boolean => url.indexOf(OAUTH2_TOKEN_PATH) > 0;

const isRevokeURL = (url: string): boolean => url.indexOf(OAUTH2_REVOKE_PATH) > 0;

/***
 * alterAuthRequestHeaders: takes the request that about to be sent to the api,
 * checks that it has the updated credentials to be able to authenticate with the api
 * then return the same/updated credentials
 **/
export const alterAuthRequestHeaders: AlterRequest = async(request: Request) => {
  let token: Token = AuthenticationHelper.getOAuth2Credentials();

  if (!token) { // local storage knows nothing about logged in user
    if (isLoggedInInStore()) { // local storage thinks he is not logged in, but the store thinks he is
      logoutUser(); // kick him out
      return Promise.reject('user logged in in Redux store without OAuth2 credentials');
    }
    return request;
  }

  // if we're in the middle of refreshing the OAuth2 token, we do *not* do any further checks
  if (isRefreshURL(request.url)) {
    return request;
  }

  // ok, app thinks that the user is logged in; let's check that the token didn't expire
  const isTokenValid = AuthenticationHelper.checkTokenValidity(token, TOKEN_EXPIRATION_BUFFER);

  if (!isTokenValid) {
    const refreshedToken = await AuthenticationHelper.refresh(token.refreshToken); // get a new one!
    if (!refreshedToken) { // backend refused to refresh the token
      logoutUser(); // kick him out
      // The following line will never happen and it is here to make typescript happy
      return Promise.reject('OAuth2 tokens failed to refresh');
    }
    token = refreshedToken; // we got new token - replace with the old one
  }

  // set header token params
  request.headers.set(AUTHORIZATION_HEADER_NAME, `${AUTHORIZATION_HEADER_VALUE_PREFIX} ${token.accessToken}`);
  return request;
};

/***
 * checkAuthenticationState: gets the response from the backend,
 * checks if the backend rejected the api call and if so - try to update the credentials
 * to be able to try to fetch again, now with valid credentials to get a valid response from the backend
 **/
export const checkAuthenticationState = async(response: Response): Promise<ICheckAuthResponse> => {
  const token: Token = AuthenticationHelper.getOAuth2Credentials();

  // protected endpoint + backend did not approve the request for auth reasons;
  // this check has to be skipped when logging in (= have no tokens stored), as a
  // 401 status in the login response simply means that the credentials were wrong
  if (response.status === 401 && token) {
    // the backend rejected the protected endpoint.
    // we need to check that we are not already in a refresh request. in this case,
    // a 401 means - refresh failed! kick him out.
    if (!isRefreshURL(response.url)) {
      return tryToRefreshToken(token);
    }
    // user tried to go to a protected endpoint,
    // and both backend and client thinks he should not be login
    // local storage knows nothing about user login state
    logoutUser();
    return { reason: 'Unauthorized API response without OAuth2 credentials', step: ABORT };
  }

  // if there is no "user logged in" HTTP header set, we do not have to check if the user is logged in
  // in the client, but can simply continue with the response handling
  const loggedInHeader = response.headers.get(HEADER_USER_LOGGED_IN);
  if (!loggedInHeader) {
    return { step: CONTINUE };
  }

  const isLoggedInOnBackend = loggedInHeader.toLowerCase() === 'true';
  const isLoggedInOnClient = isLoggedInInStore() && !!token;

  // unless we are in the midle of a logout call, in which, it is ok to be
  // logged in on the frontend and out on the backend (after releasing this check, the logout
  // helper will remove the credentials from the client) - we try to sync and relogin
  // we also need to check that we are not already in a refresh request.
  // TODO - responsibilities and flows of logout! (redirect, store, storage...)
  if (isLoggedInOnClient && !isLoggedInOnBackend && !isRefreshURL(response.url) && !isRevokeURL(response.url)) {
    return tryToRefreshToken(token);
  }
  return { step: CONTINUE };
};
