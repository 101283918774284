import * as React from 'react';

// components
import {
  DefaultButton,
  EMailField,
  PrimaryButton,
} from 'src/components/authentication/auth_form_elements';
import FieldSet, { FIELDSET_TYPE_SMALL_SPACE } from 'src/components/forms/field_set/field_set';
import MessageSegmentWithModal from 'src/components/message_segment/message_segment_with_modal';

// constants & interfaces
import { IAuthProps } from 'src/components/authentication/auth_ui';
import { AUTH_UI_CLASS } from 'src/constants';
import { REDIRECT_URL_PASSWORD_RESET } from 'src/constants/urls';
import { textResources } from 'src/lang/de';

// helpers
import { createBemBlock } from 'src/utils/bem_helper/bem_helper';
import { forgotPassword } from 'src/utils/profile';
import initTracker, { FORGOT_PASSWORD } from 'src/utils/reporting/events_tracking/events_tracking';
import { UrlUtils } from 'src/utils/url/url';
import { validateEmail } from 'src/utils/validation';

const cls = createBemBlock(AUTH_UI_CLASS);

const labels = textResources.authentication;

const forgotPWTracker = initTracker(FORGOT_PASSWORD);

interface IProps extends IAuthProps {
}

interface IState {
  validEmail: boolean;
  showSuccessMessage: boolean;
}

class ForgotPassword extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      showSuccessMessage: false,
      validEmail: true,
    };
    this.submit = this.submit.bind(this);
    this.changeEmail = this.changeEmail.bind(this);
  }

  public render() {
    return this.state.showSuccessMessage ? this.renderSuccessMessage() : this.renderForm();
  }

  public renderSuccessMessage() {
    return (
      <MessageSegmentWithModal
        image='confirm-email'
        title={labels.resetConfirmationTitle}
        description={labels.resetConfirmationBody}
        onClose={this.props.onClose}
      />
    );
  }

  public renderForm() {
    const { email, goToRegister, loggedIn } = this.props;
    const bodyText = !loggedIn ? labels.passwordForgotBody : textResources.onboardingSettings.changePasswordBodyText;
    return (
      <>
        <p className={cls('text')}>{bodyText}</p>
        <form>
          {!loggedIn &&
          <FieldSet>
            <EMailField
              name='email'
              onChange={this.changeEmail}
              value={email}
              valid={this.state.validEmail}
              autoComplete='username'
            />
          </FieldSet>}
          <FieldSet type={FIELDSET_TYPE_SMALL_SPACE}>
            <PrimaryButton onClick={this.submit} label={labels.sendEmail} />
            {!loggedIn && <DefaultButton onClick={goToRegister} label={labels.wantRegister} />}
          </FieldSet>
        </form>
      </>
    );
  }

  private pushError(msg: string) {
    const { pushError } = this.props;
    pushError && pushError(msg);
  }

  private changeEmail(value: string) {
    const { changeEmail } = this.props;
    this.setState({ validEmail: true });
    changeEmail && changeEmail(value);
  }

  private submit(e: React.SyntheticEvent<HTMLElement>) {
    e.preventDefault();
    const { email = '' } = this.props;
    const validEmail = validateEmail(email);
    if (validEmail) {
      const redirectUrl = UrlUtils.getCurrentUrlOrigin() + REDIRECT_URL_PASSWORD_RESET;

      forgotPWTracker(FORGOT_PASSWORD.ACTIONS.EMAIL, FORGOT_PASSWORD.LABELS.START);
      forgotPassword({ email, redirectUrl })
        .then(() => {
          forgotPWTracker(FORGOT_PASSWORD.ACTIONS.EMAIL, FORGOT_PASSWORD.LABELS.FINISH);
          this.setState({ showSuccessMessage: true });
        })
        .catch(() => {
          this.setState({ validEmail: false });
          this.pushError(textResources.shared.errorUnknown);
        });
    } else {
      this.setState({
        validEmail,
      });
    }
  }
}

export default ForgotPassword;
