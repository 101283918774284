// libs
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// actions
import { IAction } from 'src/actions/';
import { showLoginModal, showRegistrationModal } from 'src/actions/app-state/app-state';

// interfaces / types
import CollapsedBanner from 'src/components/collapsed_banner/collapsed_banner';

export interface IDispatchFromProps {
  showLoginModal: () => void;
  showRegistrationModal: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch<IAction>): IDispatchFromProps => ({
  showLoginModal: () => dispatch(showLoginModal()),
  showRegistrationModal: () => dispatch(showRegistrationModal()),
});

export default connect(undefined, mapDispatchToProps)(CollapsedBanner);
